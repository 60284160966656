export default {
  id: '1-02-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Einhorn',
    en: 'Unicorn',
  },
  subtitle: {
    de: 'B12 x H40 x T33 cm',
    en: 'W12 x H40 x D33 cm',
  },
  meta_title: {
    de: 'Einhorn Papiertier',
    en: 'Unicorn Paper Animal',
  },
  meta_description_google: {
    de: 'Der Einhorn Kopf zum Aufhängen verzaubert als Wanddeko jeden Raum. Die magische Einhorn Trophäe lässt sich einfach falten und stecken. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The unicorn head enchants each room with lots of space for creativity. This magical unicorn trophy is very easy to assemble thanks to the new no-glue technique. 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: '3D Einhorn Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape ',
    en: '3d unicorn trophy from paper - diy papersculpture as unique wall decor #papershape  ',
  },
  description: {
    de: ` Diese Tiertrophäe verspricht Einzigartigkeit! Schneiden, falten, kleben! Diese 3 Schritte und weitere nützliche Tipps erfahrt ihr von mir im Videotutorial. Anschauen lohnt sich, damit euer 3D Origami genauso schön wie auf den Fotos wird.

DETAILS : Altersempfehlung ab 14 Jahren

KOMPLETTES SET BEINHALTET

9 DIN A4 Seiten Fotokarton Origami Einhorn
Doppelseitige Klebepads
Detaillierte schrittweise Anleitung

BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser

    `,
    en: ` 
    
This animal trophy promises uniqueness! 
Cut, fold, glue! These 3 steps and additional useful tips are shared in the video tutorial. It is worth watching, so that your 3D origami looks just as beautiful as in the photos.

DETAILS: Age recommendation from 14 years

COMPLETE KIT INCLUDES
9 DIN A4 cardboard sheets Origami Unicorn
Double-sided adhesive pads
Detailed step-by-step instructions

REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife`,
  },

  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 7 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 7 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 7 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 7 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkeinhorn/einhorn-3d-tierskulptur-papier.jpg',
        en: 'products/sfkeinhorn/einhorn-3d-tierskulptur-papier.jpg',
      },
      alt_text: {
        de: 'DIY 3d Einhorn aus Papier als Wanddeko #papercraft #3dpapermodel #origami',
        en: 'Papercraft unicorn for wall decor #diypapercraft #origami #3dpapermodel #origami',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },

  colorinspirations: [
    {
      caption: {
        de: 'pink-steingrau',
        en: 'pink-rosa',
      },
      image: {
        filename: {
          de: 'products/sfkeinhorn/einhorn-3d-tierskulptur-papier.jpg',
          en: 'products/sfkeinhorn/einhorn-pink-rosa.jpg',
        },
        alt_text: {
          de: '3D Einhorn Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
          en: '3d unicorn trophy from paper - diy papersculpture as unique wall decor #papershape ',
        },
      },
    },
    {
      caption: {
        de: 'himmelblau-türkis',
        en: 'himmelblau-türkis',
      },
      image: {
        filename: {
          de: 'products/sfkeinhorn/einhorn-himmelblau-tuerkis.jpg',
          en: 'products/sfkeinhorn/einhorn-himmelblau-tuerkis.jpg',
        },
        alt_text: {
          de: '3D Einhorn Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
          en: '3d unicorn trophy from paper - diy papersculpture as unique wall decor #papershape',
        },
      },
    },
    {
      caption: {
        de: 'steingrau-pink',
        en: 'steingrau-pink',
      },
      image: {
        filename: {
          de: 'products/sfkeinhorn/einhorn-steingrau-pink.jpg',
          en: 'products/sfkeinhorn/einhorn-steingrau-pink.jpg',
        },
        alt_text: {
          de: '3D Einhorn Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
          en: '3d unicorn trophy from paper - diy papersculpture as unique wall decor #papershape ',
        },
      },
    },
    {
      caption: {
        de: 'türkis-weiß',
        en: 'türkis-weiß',
      },
      image: {
        filename: {
          de: 'products/sfkeinhorn/einhorn-tuerkis-weiss.jpg',
          en: 'products/sfkeinhorn/einhorn-tuerkis-weiss.jpg',
        },
        alt_text: {
          de: '3D Einhorn Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
          en: '3d unicorn trophy from paper - diy papersculpture as unique wall decor #papershape ',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkeinhorn/einhorn-uni-weiss.jpg',
        en: 'products/sfkeinhorn/einhorn-uni-weiss.jpg',
      },
      alt_text: {
        de: '3D Einhorn Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
        en: '3d unicorn trophy from paper - diy papersculpture as unique wall decor #papershape ',
      },
    },
    colors: {
      color1: {
        de: 'Kopf & Ohren',
        en: 'Head & ears',
      },
      color2: {
        de: 'Horn & Mähne',
        en: 'Horn & mane',
      },
    },
    disableColors: {
      color2: ['bunt', 'schwarz'],
      color1: ['schwarz'],
    },
    price: 29.9,
    priceThirdCountry: 23.5,
  },
};
