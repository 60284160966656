export default {
  id: '1-10-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'XXL Pferdeskulptur',
    en: 'XXL Horse sculpture',
  },
  subtitle: {
    de: 'B22 x H76 x T41 cm',
    en: 'W22 x H76 x D41 cm',
  },
  meta_title: {
    de: 'DIY XXL Pferd aus Papier - jetzt downloaden',
    en: 'Papercraft horse for your wall decor - download now',
  },
  meta_description_google: {
    de: 'Du hast Pferdeliebhaber im Haus? Dieses DIY XXL Pferd aus Papier ist in den eigenen vier Wänden genauso imposant wie ein echtes Pferd in Stall.',
    en: 'Make this DIY horse papercraft for your unique style at home. Try our templates and create affordable wall art.',
  },
  meta_description_social: {
    de: 'DIY 3d Pferd aus Papier als Wanddeko #papercraft #3dpapermodel #origami',
    en: 'Huge papercraft horse for your wall decor #diypapercraft #origami #3dpapermodel #origami',
  },
  description: {
    de: `Was ist drin im DIY Kit?    
    - 19 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -19 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 19 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 19 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 19 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 19 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkpferdtorso/papercraft-horse-big.jpg',
        en: 'products/sfkpferdtorso/papercraft-horse-big.jpg',
      },
      alt_text: {
        de: 'DIY 3d Pferd aus Papier als Wanddeko #papercraft #3dpapermodel #origami',
        en: 'Huge papercraft horse for wall decor #diypapercraft #origami #3dpapermodel #origami',
      },
    },
    price: 14.9,
    priceThirdCountry: 14.9,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkpferdtorso/papercraft-horse-big.jpg',
        en: 'products/sfkpferdtorso/papercraft-horse-big.jpg',
      },
      alt_text: {
        de: '3d Pferdeskulptur aus Papier als DIY Papiermodel oder zum Download #papershape #diy #3dpapermodel #papercraft',
        en: 'Huge papercraft horse for wall decor #diypapercraft #origami #3dpapermodel #origami',
      },
    },
    colors: {
      color1: {
        de: 'Kopf, Ohren, Körper',
        en: 'Head & ears',
      },
      color2: {
        de: 'Mähne',
        en: 'mane',
      },
    },
    disableColors: {
      color2: ['schwarz'],
      color1: ['schwarz'],
    },
    price: 39.9,
    priceThirdCountry: 33.9,
  },
};
