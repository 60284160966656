import React from 'react';
import { connect } from 'react-redux';
import { getBlogPosts } from '../state/blog/blogSelectors';
import Lang from './Lang';
import PsBlogPostView from './PsBlogPostView';
import PsListMore from './PsListMore';

function PsBlogPostList({ blogPosts, open }) {
  return (
    <PsListMore
      items={blogPosts}
      open={open}
      renderItem={(blogPost) => <PsBlogPostView blogPost={blogPost} />}
      moreText={<Lang de="weitere Anleitungen" en="more instructions" />}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    blogPosts: getBlogPosts(state),
  };
};

export default connect(mapStateToProps)(PsBlogPostList);
