export default {
  id: '2-18-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Elefant',
    en: 'Elephant',
  },
  subtitle: {
    de: 'B24 x H20 x T34 cm',
    en: 'W24 x H20 x D34 cm',
  },
  meta_title: {
    de: '3D Elefant Papierfigur',
    en: 'Geometric 3D elephant paper sculpture ',
  },
  meta_description_google: {
    de: 'Als Kundenanfrage für Amarula ist diese Papierskulptur in Form eines Elefanten entstanden. Cooles DIY Kit aus Papier zum selber basteln und verschenken.',
    en: 'The elephant enchants each room with lots of space for creativity.  100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: '3D Elefant Papierfigur basteln mit DIY Kit #papershape',
    en: '3d paper elephant decor diy #papershape ',
  },
  description: {
    de: `Als Deko, Geburtstagsgeschenk oder zur Babyshower - dieser süße Papierelefant ist mit seinen Facetten genau passend für alle, die nicht super lange basteln wollen, aber gleichzeitig seinen Liebsten etwas Selbstgemachtes schenken möchten.
    
    ANLEITUNG 
    
    In einem kurzen Video erkläre ich euch live, wie ihr in 3 Schritten zu eurer einzigartigen 3D Origami Kuh gelangt. Praktische Tipps inklusive.
    
    Schneide alle Papierteile mit einer Schere oder einem Cutter aus. Alle durchgezogenen Linien müssen geschnitten werden. Schneide auch dort ein, wo eine kleine Schere angegeben ist.
    
    Um die Papierteile zu falten, musst du sie vorerst mit einem Lineal und einem Buttermesser oder einer Ahle vorfalzen. Lege dazu das Lineal an die gestrichelten Markierungen an und fahre mit dem Buttermesser und viel Druck am Lineal auf den Linien entlang. Anschließend knickst du die Linien wie in der Anleitung angegeben entweder nach außen oder innen.
    
    Jetzt gilt es alle Papierteile des 3D Origami miteinander zu verkleben. Verwende dazu die Klebepads, die im Set mitgeliefert sind. Das Verkleben erfolgt in nummerischer Reihenfolge bei 1, 2, 3, etc. Klebe die Pads von außen auf die Lasche und halte einen Sicherheitsabstand von ca. 2mm ein. Beim Verkleben unbedingt auf das Modell von außen schauen.
    
    DETAILS
    
    ab 14 Jahren empfohlen
    
    KOMPLETTES SET BEINHALTET
    
    6 DIN A4 Seiten Fotokarton
    Doppelseitige Klebepads
    Detaillierte schrittweise Anleitung
    
    BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser

    `,
    en: `   
    
    STEP BY STEP
    
    Cut the paper parts.
    To fold the paper parts, you need to pre-fold them first with a ruler and a bread knife or an awl. Set the ruler to the dashed line and press with the butter knife along. Then fold the parts as specified in the instructions: either outwardly or inwardly.
    Now glue all the paper parts of the 3D origami together. Use the pads, which are included in the set. The folding takes place in numerical order in 1, 2, 3, etc. Set the adhesive pads on the outer sides of the tabs and keep a safe distance of about 2mm. When gluing necessarily watch the model from the outside.
    
    DETAILS
    
     Age recommendation 14+
  
    COMPLETE KIT INCLUDES
    
    6 DIN A4 cardboard sheets 
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife`,
  },
  colorinspirations: [
    {
      caption: {
        de: 'gelb',
        en: 'gelb',
      },
      image: {
        filename: {
          de: 'products/sfkamarulaelefant/gelb-elefant-3d-basteln.jpg',
          en: 'products/sfkamarulaelefant/gelb-elefant-3d-basteln.jpg',
        },
        alt_text: {
          de: '3D Elefant Papierskulptur, DIY Kit zum Falten #papershape',
          en: '3d paper elephant diy #papershape #papercrafting',
        },
      },
    },
    {
      caption: {
        de: 'rosa',
        en: 'rosa',
      },
      image: {
        filename: {
          de: 'products/sfkamarulaelefant/rosa-3d-elefant-paper-tropyhy.jpg',
          en: 'products/sfkamarulaelefant/rosa-3d-elefant-paper-tropyhy.jpg',
        },
        alt_text: {
          de: 'Geometrischer Papierelefant als Babyshower Geschenk, DIY Papierskulptur zum Basteln #papershape',
          en: '3d paper elephant diy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'steingrau',
        en: 'steingrau',
      },
      image: {
        filename: {
          de: 'products/sfkamarulaelefant/grau-elefant-papiertier-3d.jpg',
          en: 'products/sfkamarulaelefant/grau-elefant-papiertier-3d.jpg',
        },
        alt_text: {
          de: '3D Elefant aus Papier Deko Papiertier zum selber Basteln  #papershape',
          en: '3d paper elephant diy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'rot',
        en: 'rot',
      },
      image: {
        filename: {
          de: 'products/sfkamarulaelefant/roter-elefant-papierfigur.jpg',
          en: 'products/sfkamarulaelefant/roter-elefant-papierfigur.jpg',
        },
        alt_text: {
          de: 'Low poly Elefant aus Papier zum Falten #papershape',
          en: 'Geometric 3d paper elephant diy #papershape',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkamarulaelefant/blau-elefant-3d-origami.jpg',
        en: 'products/sfkamarulaelefant/blau-elefant-3d-origami.jpg',
      },
      alt_text: {
        de: '3D Papiertier Elefant zum Hinstellen als DIY Kit zum selbermachen #papershape ',
        en: '3d paper elephant diy #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Elefant',
        en: 'elephant',
      },
    },
    disableColors: {
      color2: ['schwarz'],
      color1: ['schwarz'],
    },

    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
