import React, { Component } from 'react';
import anastasiabaron from '../styles/images/papershape-anastasia-baron.jpg';
import Lang from '../common/Lang';
import { getTextBySelectedLang } from '../data/lang';
import Newsletter from '../common/Newsletter';
import PsMeta from '../common/PsMeta';

export default class AboutMe extends Component {
  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang('Über mich', 'about me')}
          description={getTextBySelectedLang(
            'Hier erfahrt ihr mehr über mich und meine Arbeit.',
            'Here you can find out more about me and my work.'
          )}
        />

        <div>
          <div className="container">
            <div className="row" style={{ marginTop: 60 }}>
              <div className="col-sm-6">
                <img
                  src={anastasiabaron}
                  alt="Anastasia Baron"
                  className="img-responsive"
                />
              </div>
              <div className="col-sm-6">
                <div style={{ margin: '50px 0 0' }} className="hidden-xs" />
                <h1 style={{ marginBottom: 30 }}>
                  <Lang de="Über mich" en="about me" />
                </h1>
                <blockquote style={{ borderRight: 'none' }}>
                  <p>
                    <Lang
                      de="Im Walde zwei Wege boten sich mir dar, ich nahm den, der weniger betreten war, und das veränderte mein Leben."
                      en="Two roads diverged in a wood, and I took the one less traveled by, and that has made all the difference."
                    />
                  </p>
                  <footer>Robert Frost</footer>
                </blockquote>
                <p style={{ margin: '40px 0 15px' }}>
                  <Lang
                    de="Mein Name ist Anastasia Baron."
                    en="My name is Anastasia Baron."
                  />
                </p>
                <p style={{ marginBottom: 15 }}>
                  <Lang
                    de="Ich habe aus Versehen BWL studiert."
                    en="I studied Business Administration by mistake."
                  />
                </p>
                <p>
                  <Lang
                    de="2014 ist Papershape aus einem DIY Blogpost entstanden."
                    en="In 2014 I founded Papershape that originated from a DIY blog post."
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="section grey-section">
            <div className="container">
              <div className="row text-center">
                <div className="row">
                  <div className="col-md-offset-2 col-md-8">
                    <Newsletter galabel="aboutme" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row" style={{ marginBottom: 20, marginTop: 0 }}>
              <div className="col-xs-12">
                <div className="text-center">
                  <h2>
                    <Lang de="Philosophie" en="Philosophy" />
                  </h2>
                  <p>
                    <Lang
                      de="Papershape ist keine Marke. Sie ist mein Traum."
                      en="Papershape is not a brand. It's my dream"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <h3 style={{ marginTop: 30 }}>
                  <Lang de="Minimalismus" en="Minimalism" />
                </h3>
                <p>
                  <Lang
                    de="Im Leben sind die einfachsten Dinge am schwersten zu finden."
                    en="The easiest things are the hardest to find."
                  />
                </p>
                <p>
                  <Lang
                    de="Das minimalistische Aussehen meiner Tiertrophäen ist das Ergebnis eines andauernden Designprozesses. Jede Polygone wird von Hand reduziert oder erweitert bis die Balance zwischen Design und Form da ist."
                    en="The clean and minimal look of the paper trophies is a result of an ongoing design process. Each polygone is reduced or extended manually until the balance of  design and shape is attained."
                  />
                </p>
                <Lang de="" en="" />
                <h3 style={{ marginTop: 30 }}>
                  <Lang de="Veränderung" en="Freshness" />
                </h3>
                <p>
                  <Lang
                    de="Ich weiß nicht, ob es besser wird, wenn ich die Dinge ändere. Nur weiß ich, dass ich sie ändern muss, damit sie besser werden können."
                    en="I cannot say whether things will get better if we change; what I can say is that they must change if they are to get better."
                  />
                  Georg Christoph Lichtenberg
                </p>
                <p>
                  <Lang
                    de="Seit Gründung habe ich die Papershapes mehrfach verändert. Von der Umstellung der Klebeart über ein neues Zahlen- bis hin zum innovativen Stecksystem, das komplett ohne Kleber auskommt."
                    en="Since Papershape was founded our Papershapes has been revised several times. From changing the type of glue to a new number system and finally to our innovative plug system that needs no glue at all."
                  />
                </p>
                <p>
                  <Lang
                    de="Du entscheidest, wie es noch besser geht und ich bin mehr als happy Dinge zu verändern."
                    en="You decide how it can be better and I'm more than happy to renew things for the better."
                  />
                </p>
              </div>
              <div className="col-sm-6">
                <h3 style={{ marginTop: 30 }}>
                  <Lang de="Ehrlichkeit" en="Honesty" />
                </h3>
                <p>
                  <Lang
                    de="Du sollst ein Produkt kaufen, dass funktioniert und dich happy macht."
                    en="You should buy a product that works and makes you happy."
                  />
                </p>
                <p>
                  <Lang
                    de="Deshalb ist es mir unglaublich wichtig, dass du die Geling-Garantie zu meinen 3D Tieren aus Papier kennst."
                    en="That's why it is very important to me that you know about our success-guarantee."
                  />
                </p>
                <p>
                  <Lang
                    de="Du baust deine Tiertrophäe zusammen und wenn du mit dem Ergebnis wider Erwarten nicht zufrieden sein solltest, dann bekommst du dein Geld zurück."
                    en="You assemble your animal trophy from paper and if you are not satisfied with the end result of your paper trophy you will get your money refunded."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://vg07.met.vgwort.de/na/a252c82984d94d3fbad0468506c3087d"
          width="1"
          height="1"
          alt=""
        />
      </div>
    );
  }
}
