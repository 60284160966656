import materials from './materials';

export default {
  url: 'billard-adventskalender-basteln',
  countermark: 'vg07.met.vgwort.de/na/bba442372ad841468f42b9e9494d6aaa',
  imageFolder: 'adventskalender-billard',

  landingPage: ['diy-geschenke'],

  name: {
    de: 'Billiard-Adventskalender',
    en: 'Billiard Advent Calendar',
  },
  id: '3-25-blog',
  pdf: {
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'content/adventskalender-billiard/adventskalender-basteln-billard-vorlage.jpg',
        en: 'content/adventskalender-billiard/adventskalender-basteln-billard-vorlage.jpg',
      },
      alt_text: {
        de: 'Billiard Adventskalender basteln aus Papier',
        en: 'How to papercraft a billiard advent calendar?',
      },
    },
  },

  meta_title: {
    de: 'Coolen Billard Adventskalender basteln mit Papier (für den Mann) ',
    en: 'How to make a billard Advent calendar for your boyfriend',
  },
  meta_description_google: {
    de: `Mit diesem Billard-Adventskalender bastelst du endlich eine Männer-freundliche Variante. Mit der kostenlosen Anleitung und Vorlage von 
    PaperShape sind die 24 Billard-Kugeln schnell selber gemacht. Und dann geht es ans Befüllen. `,
    en: 'Make this super cool billard advent calendar for your boyfriend. Download the free template for the billard balls and cue from paper and enjoy filling them. ',
  },
  meta_description_social: {
    de: 'Adventskalender basteln für den Mann mit Billardkugeln aus Papier ',
    en: 'diy billard advent calendar for your boyfriend',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: 'adventskalender-basteln-billard-vorlage.jpg',
      en: 'advent-calendar-billard-men-papercraft.jpg',
    },
    alt_text: {
      de: 'DIY Adventskalender basteln für den Mann zu Weihnachten mit kostenloser Vorlage von ',
      en: 'diy billard advent calendar for your boyfriend  ',
    },
  },
  h1: {
    de: 'Coolen Billard Adventskalender basteln für den Mann ',
    en: 'How to make a billard Advent calendar for your boyfriend ',
  },
  description: {
    de: `
     <p>
       Gar nicht so einfach Männern etwas zu schenken, geschweige denn ihnen etwas selbst zu machen.
     </p>
     <p> 
       Mit dieser DIY Geschenk Idee eines Billard-Adventskalenders solltet ihr aber auf der sicheren Seite sein. Die geometrischen Billardkugeln lassen sich ganz einfach befüllen und 
      sind groß genug für Kleinigkeiten aller Art. Selbst einen Queue aus Papier gibt es als Vorlage für euch.
     </p>
      
      Eine andere geometrische Variante des Adventskalenders, die sich an alle fleißigen Bienchen wendet, findet ihr in 
      diesem <a href="/de/blog/bienenwaben-adventskalender-basteln/">Honigwanben Adventskalender Post</a>.
    `,
    en: `
      <p>
      It is pretty difficult to find nice presents for the boyfriend. But crafting something for him seems to be nearly impossible.
      </p>
          <p>
     With this billard Advent calendar you are on the safe side! Make these geometric billard balls and fill them with nice things you know he will love.
      </p>

      <p>If you are searching for an alternative, how about making this  <a href="/en/blog/bienenwaben-adventskalender-basteln/">3d honeycomb Advent calender? </a></p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'billard-weihnachtskalender-junge-mann-papier.jpg',
          en: 'men-christmas-calendar-paper-papercraft.jpg',
        },
        alt_text: {
          de: 'Adventskalender basteln für den Mann oder Freund, kostenlose Vorlage von #papershape #adventskalenderbasteln',
          en: 'diy billard advent calendar for your boyfriend ',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: 'weihnachtskalender-basteln-mann-papierr-vorlage.jpg',
          en: 'advent-calendar-men-papercraft.jpg',
        },
        alt_text: {
          de: 'Weihnachtskalender selber machen aus Papier für den Mann oder Freund zu Weihnachten mit kostenloser Anleitung und Vorlage von #papershape',
          en: 'diy billard advent calendar for your boyfriend - free template from #papershape',
        },
      },
    },
  ],

  stepsTitle: {
    de: 'Wie bastel ich einen Billard Adventskalender - Anleitung',
    en: 'How to make a billard advent calendar - instructions',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '01_schneiden.mp4',
      text: {
        de: `Ihr benötigt für eure Billardkugeln unterschiedliche Farben an Papier. Ich habe insgesamt 8 unterschiedliche Papiere in 270g-300g/m² gewählt. Ihr benötigt 12 Billardkugeln in weiß, die 
        anschließend mit einer farbigen Bordüre versehen werden. Auch hier gilt wieder
        lieber etwas stärkeres Papier verwenden, wenn ihr sie mit Kleinigkeiten befüllen wollt, die etwas wiegen. Druckt euch dann die Vorlage für die Billardkugeln aus und schneidet sie
        mit einer Papierschere entlang der durchgezogenen Linien aus. Auf der Seite 2 findet ihr 3 Billardkugeln aufgedruckt. Jede Billiardkugel ist ca 6cm groß.
        
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '02_falzen.jpg',
          en: '02_falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_falzen.mp4',
      text: {
        de: `Legt an die gestrichelten Linien der Billardkugeln ein Lineal an und fahrt mit einer Ahle oder Buttermesser mit Druck diese nach. Das gibt schöne Knickkanten. Verfahrt genauso beim
        Queue. `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '03_knicken.jpg',
          en: '03_knicken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '03_knicken.mp4',
      text: {
        de: `Legt eine ausgeschnittene Billardkugel so vor euch, dass die gestrichelten Linien zu euch zeigen. Faltet diese nun nach oben, d.h. zu euch hin. Das gleiche macht ihr beim Queue.
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '04_kleben.jpg',
          en: '04_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '04_kleben.mp4',
      text: {
        de: `Klebt auf die Laschen nun einen Klebepad und verklebt diesen. Startet bei der 1, anschließend verklebt ihr die anliegenden Laschen.
        
       
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '05_borduere.jpg',
          en: '05_borduere.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '05_borduere.mp4',
      text: {
        de: ` Nun klebt ihr um die weißen Billardkugeln die farbigen Bordüren und verseht am Ende diese mit einer Billard-Adventstagszahl. Auf die restlichen farbigen Billardkugeln
        klebt ihr die Zahlen nach Wunsch. Um die Kugeln zu schließen, einfach mit einer Lochzange oder einem spitzen Gegenstand ein Loch in die oberen Zacken stanzen, einen Faden oder Band 
        durchziehen und mit einem Knoten verbinden.
        
       `,
        en: `.`,
      },
    },

    {
      image: {
        filename: {
          de: '06_lochen.jpg',
          en: '06_lochen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '06_lochen.mp4',
      text: {
        de: 'Die Löcher bekommt ihr am besten mit einer Lochzange in das Papier gestanzt oder falls ihr mit einem Schneideplotter arbeitet, sind diese bereits ausgeschnitten.',
        en: `.`,
      },
    },

    {
      image: {
        filename: {
          de: '07_spitze.jpg',
          en: '07_spitze.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '07_spitze.mp4',
      text: {
        de: `Nun zum Queue: verklebt zuerst je die Teile 1,2 und 3. Das "sternenförmige" Papierteil wird ganz vorne einfach 
          reingesteckt und nicht verklebt. Das kleinste Papierteil Nr. 4 wird von außen auf den Stab 1 geklebt.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '08_ziehen.jpg',
          en: '08_ziehen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '08_ziehen.mp4',
      text: {
        de: 'Um den Queue problemlos zusammenzubauen, werden die dünnen Kreisenden nicht verklebt, sondern ganz einfach ineinandergesteckt.',
        en: `.`,
      },
    },
  ],

  downloadName: 'billarld-advent-calendar_papershape.pdf',
  materials: [
    materials.paper(8),
    materials.glue,
    materials.scissors,
    materials.ruler,
    materials.awl,
    materials.mat,
    {
      type: 'tool',
      name: {
        de: 'Bänder zum Zubinden der Billardkugeln',
        en: 'Ribbons ',
      },
    },
    {
      de: 'Lochzange',
      en: 'hole pliers',
    },
  ],
};
