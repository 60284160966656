import React from 'react';
import { addFolder } from '../pages/blog/BlogPost';
import PsImage from './PsImage';
import { PsLink } from './PsLink';

export default function PsBlogPostThumbnail({ blogPost }) {
  const imageFolder = 'content/' + blogPost.imageFolder + '/';
  const addImageFolder = addFolder(imageFolder);
  return (
    <PsLink to={'/blog/' + blogPost.url} disableStyles>
      <div
        className="thumbnails-image"
        style={{
          width: '100px',
          margin: '5px 5px 0',
        }}>
        <PsImage
          image={addImageFolder(blogPost.image_main)}
          style={{ marginBottom: '0' }}
          data-pin-nopin="true"
        />
      </div>
    </PsLink>
  );
}
