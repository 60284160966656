import materials from './materials';

export default {
  url: 'pokal-basteln',
  countermark: 'vg07.met.vgwort.de/na/43eff9f89be4489ea85a9062ee6c46a8',
  imageFolder: 'pokal',

  name: {
    de: 'Pokal',
    en: 'Trophy',
  },
  id: '3-14-blog',
  pdf: {
    price: 6.9,
    priceThirdCountry: 0.9,
    image: {
      filename: {
        de: 'content/pokal/pokal-basteln-anleitung.jpg',
        en: 'content/pokal/pokal-basteln-anleitung.jpg',
      },
      alt_text: {
        de:
          'Pokal aus Papier basteln mit Anleitung und Vorlage #bastelnmitpapier #papershape #diy #papercraft #trophyfrompaper #pokalbasteln',
        en: 'How to make a paper trophy',
      },
    },
  },
  meta_title: {
    de: 'Pokal basteln mit Papier (mit Vorlage zum Herunterladen)',
    en: 'How To Make A Paper Trophy (with downloadable template)',
  },
  meta_description_google: {
    de:
      'Den eigenen Pokal basteln aus Papier, egal ob zum Muttertag oder als Siegertrophäe für die Fußballspiele der eigenen Kinder. Mit der PaperShape Vorlage und Anleitung bastelst du in 3 einfachen Schritten deinen eigenen Pokal.',
    en: 'Make your paper trophy in just 3 easy steps with this template.',
  },
  meta_description_social: {},

  image_main: {
    filename: {
      de: 'pokal-basteln-anleitung.jpg',
      en: 'how-to-make-paper-trophy.jpg',
    },
    alt_text: {
      de:
        'Pokal basteln aus Papier / #papershape #bastelnmitpapier #pokalbasteln #papercraft',
      en:
        'Paper Trophy DIY out of paper / PaperShape #diy #papertrophy #papercraft #3dpaper',
    },
  },
  h1: {
    de: 'Pokal basteln mit Papier (inklusive Bastelvorlage)',
    en: 'How to make a trophy out of paper (with template)',
  },
  description: {
    de: `
      <p>
        Hat in unserem Umfeld nicht jeder mal den einen oder anderen Pokal für eine "Helden"tat verdient? Mit unserer Vorlage
        kannst du ganz einfach deinen eigenen Pokal basteln. Egal, ob du zum Muttertag der "besten Mama" huldigen möchtest oder bei einem 
        selbst organisierten Fussball-Wettbewerb einen Gewinnerpokal übergeben möchtest, dieser Pokal ist perfekt dafür.
       </p>
       <p>
     Du kannst die obere Hälfte des Pokals abnehmen und darin Süßigkeiten verstecken oder du schiebst die kleine Box unter dem Pokal auf
     und legst einen Gutschein als Gewinn hinein.
      </p>
      <p>  
        Der Pokal ist 32cm hoch und 10cm breit.
      </p>
    `,
    en: `
      <p>
        Don't you think that everyone deserves from time to time one or two trophies for a "hero" act? With our template
        you can easily make your own paper trophy. 
       </p>
       <p>
     You can remove the top half of the trophy and hide candy in it, or you can slide the small box open under the paper trophy
     and put in a voucher as a prize.
      </p>
      <p>
        The height of the paper trophy is 32cm and the width is 10cm.
      </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [],

  stepsTitle: {
    de: 'Pokal basteln: Anleitung in 3 einfachen Schritten',
    en: '3 step tutorial for your paper trophy',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'anleitungsschritt-pokal-bastelvorlage.jpg',
          en: 'anleitungsschritt-pokal-bastelvorlage.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Pokal Vorlage herunterladen',
          en: 'Step: Download paper trophy template',
        },
      },
      text: {
        de: `Druckt die PDF-Vorlage für den Pokal auf DIN A4 Fotokarton aus. Ihr benötigt 6 Bögen. In der Vorlage findet ihr diverse
        Motive wie "Beste Mama", "Bester Papa", "Mein Held", einen Fußball und natürlich einen Freitext für eure indivuellen Texte.
        `,
        en: `Print the paper trophy template (US letter or DIN A4) on paper of your choice. You'll need 6 sheets of paper.
         Each printer should easily print cardstock up to 230gsm (80lb). In the template you will find different topics like "best mom", "best dad", a football/ soccer and space for your own text.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-schneiden.jpg',
          en: 'anleitungsschritt-schneiden.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ausschneiden',
          en: 'Step: Cutting',
        },
      },
      text: {
        de: `Schneide mit einer Schere oder einem Cutter alle Elemente entlang der durchgezogenen Linie aus.`,
        en: `Cut out the paper parts (A, B, C, ...) by cutting along the solid lines.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-falzen.jpg',
          en: 'anleitungsschritt-falzen.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Falzen',
          en: 'Step: Folding',
        },
      },
      text: {
        de: `Legt ein Lineal an alle gestrichelten Linien an und fahrt mit einem
                      stumpfen dünnen Gegenstand die Linien mit Druck ab.`,
        en: `Place a ruler on the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines. 
                    `,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-knicken.jpg',
          en: 'anleitungsschritt-knicken.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Knicken',
          en: 'Step: folding paper parts',
        },
      },
      text: {
        de: `Legt das Papierteil A so vor euch hin, dass ihr die Zahlen und
              Buchstaben seht. Knickt die kurz gestrichelten Linien nach innen,
              d.h. zu euch hin (Talfalte). Faltet alle lang gestrichelten Linien
              nach außen, d.h. von euch weg (Bergfalte).`,
        en: `Start with the paper piece A and place it in front of you. 
              The printed side should be facing you. Subsequently fold all the dotted lines 
              either inwards, which means to fold up (short dotted lines) or outwards, 
              which means to fold down (long dotted lines), according to the markings.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-kleben.jpg',
          en: 'anleitungsschritt-kleben.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together',
        },
      },
      text: {
        de: `Bringt auf die Laschen Kleber auf und verklebt jeweils die
              passenden Zahlenpaare. Ich verwende gerne Klebepads. Beginnt mit
              der Nr. 1 auf A und B und bastelt in nummerischer Reihenfolge
              (2,3,4, etc.) weiter. Wenn manchmal die Teile etwas größer sind, könnt ihr auch ein Lineal nehmen und damit von innen gegen
              die Klebepads drücken.`,
        en: `Use double-sided tape or any glue onto the unprinted side of 
              the tabs.Each number exists twice. Stick all the same numbers together 
              in ascending order (1, 2, 3, ...). Start with section 1 and stick it to
              the section marked 1 on paper A, pressing firmly enough to make them stick together.`,
      },
    },

    {
      image: {
        filename: {
          de: 'diy-papier-pokal-oberer-teil.jpg',
          en: 'diy-papier-pokal-oberer-teil.jpg',
        },
        alt_text: {
          de: 'diy-papier-pokal-oberer-teil',
          en: 'diy-papier-pokal-oberer-teil',
        },
      },
      text: {
        de: `Der Pokal besteht aus zwei Elementen, dem oberen und unteren Teil. Auf dem Bild seht ihr den oberen Teil des Papierpokals.
        Dieser kann ganz einfach abgenommen werden, wenn man z.B. Süßigkeiten in den Pokal legen möchte.`,
        en: `The paper goblet consists of two elements, the upper and lower part. On the picture you can see the upper part of the paper trophy.
        You can easily take it off, if you want to put sweets into the paper trophy.`,
      },
    },

    {
      image: {
        filename: {
          de: 'papier-pokal-basteln-zusammenbau.jpg',
          en: 'papier-pokal-basteln-zusammenbau.jpg',
        },
        alt_text: {
          de: ' papier-pokal-basteln-zusammenbau',
          en: ' papier-pokal-basteln-zusammenbau',
        },
      },
      text: {
        de: `Der untere Teil der Papiersieger-Trophäe besteht aus einer Schachtel, die ihr wie eine Geschenkschachtel öffnen könnt.
        In ihr könnt ihr wunderbar einen Geschenkgutschein verschenken.`,
        en: `The lower part of the paper winner trophy consists of a box, which you can open like a gift box.
        In it you can give away a voucher wonderfully`,
      },
    },
  ],

  materials: [
    materials.paper(6),
    materials.glue,
    materials.scissors,
    materials.ruler,
    materials.awl,
    materials.mat,
  ],
};
