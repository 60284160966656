import materials from './materials';

export default {
  url: 'noppenfolie-adventskalender-basteln',
  countermark: 'vg07.met.vgwort.de/na/afe2eb8dd50c41659b0251ab5c6b8823',
  imageFolder: 'adventskalender-noppenfolie',

  landingPage: ['diy-geschenke'],
  meta_title: {
    de:
      'Einfachen Adventskalender aus Noppenfolie selber machen (kostenlose Vorlage)',
    en: 'How to make an easy Advent calendar with bubble wrap (free template)',
  },
  meta_description_google: {
    de: `Ihr seid auf der Suche nach besonderen Adventskalendern zum selber machen? Dann ist dieser Adventskalender aus Noppenfolie genau das Richtige für euch. Einfach die Vorlage kostenlos
    herunterladen und losbasteln.  `,
    en:
      'If you are searching for a calender for gyus this billard Advent calendar is the solution. All you need is the free template and paper to get started.',
  },
  meta_description_social: {
    de:
      'Süßer Adventskalender aus Luftpolsterfolie. Für Kinder ganz einfach zu basteln #bastelnmitpapier #papershape #adventskalenderbasteln',
    en: 'DIY Advent calendar for Christmas #papercraft #diy',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: 'einfachen-adventskalender-luftpolsterfolie-selber-machen.jpg',
      en: 'simple-advent-calendar-bubble-wrap.jpg',
    },
    alt_text: {
      de:
        'Einfachen Adventskalender basteln aus Luftpolsterfolie mit kostenloser Vorlage von #papershape',
      en:
        'diy advent calender from bubble wrap - free template from #papershape ',
    },
  },
  h1: {
    de:
      'Einfachen Adventskalender aus Noppenfolie selber machen (kostenlose Vorlage)',
    en: 'How to make an easy Advent calendar with bubble wrap (free template)',
  },
  description: {
    de: `
      
     <p>Einen Adventskalender schon mal mit Luft anstatt Süßem gefüllt?</p>
      <p>Wer hat nicht als Kind die Luftpolster der Verpackungsfolie eingedrückt und zwar solange, bis alle Pölsterchen zerplatzt waren. Dieser besondere und gleichzeitig schnelle
      Adventskalender macht einfach Laune. Vielleicht versüßt ihr euch dieses Jahr also die Wartezeit mit Luft und Liebe?</<p>

      <p>Und hier findet ihr weitere Adventskalender Ideen aus oder in Kombination mit Papier:</p> 
      <p><li> <a href="/de/blog/billard-adventskalender-basteln/">Billiard Adventskalender für den Mann</a></li>
      <li><a href="/de/blog/bienenwaben-adventskalender-basteln/">Adventskalender für fleißige Bienchen</a></li> 
      <li><a href="/de/blog/watteschaf-adventskalender-basteln/">Schäfchen Adventskalender für Kinder</a></li> 
      </p>
    
    
    `,
    en: `
     <p>
     Ever filled an Advent calendar with air instead with sweets?
     </p>
     <p>
     As children we loved to burst each air pocket until no one left. In this DIY tutorial I'll show you how to make a bubble wrap Advent calender super easy and fast.
     </p>
     
     <p>
     Here you'll find more Advent calendar ideas with paper: </p>
     <p>
     <li> <a href="/en/blog/billard-adventskalender-basteln/">Billard Advent calender for men</a></li>
     <li><a href="/en/blog/bienenwaben-adventskalender-basteln/">Advent calendar for busy bees</a></li> 
     <li><a href="/en/blog/watteschaf-adventskalender-basteln/">Cute sheep Advent calendar for children</a></li> 
     </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'adventskalender-noppenfolie-selber-machen.jpg',
          en: 'advent-calender-bubble-wap-how-to.jpg',
        },
        alt_text: {
          de:
            'DIY Adventskalender selber machen aus Luftpolsterfolie mit kostenloser Vorlage von #papershape',
          en:
            'diy Advent calender from bubble wrap - free template from #papershape',
        },
      },
    },
    {
      text: {},
    },
  ],

  stepsTitle: {
    de: 'Wie bastel ich einen Adventskalender mit Luftpolsterfolie - Anleitung',
    en: 'How to make a bubble wrap Advent calendar - instructions',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_noppenfolie-ausschneiden.jpg',
          en: '01_noppenfolie-ausschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Einzelne  Luftpölsterchen rund ausschneiden. Vorsicht hier, dass die Luft nicht schon vorzeitig entweicht. Ihr benötigt für meine Vorlage 31 Luftpölsterchen. Wem die Zeit
        dafür fehlt, der klebt die komplette Luftpolsterfolie einfach auf das DINA4 Papier auf.
        
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '03_klebepunkte.jpg',
          en: '03_klebepunkte.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Die Vorlage herunterladen und ausdrucken. Alle Kreise mit Nummern werden mit doppelseitigem transparenten Klebeband beklebt. `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '04_luftpolsterfolie-aufkleben.jpg',
          en: '04_luftpolsterfolie-aufkleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Die einzelnen Luftpölsterchen nun auf die Vorlage kleben und vor Freude platzen!
        `,
        en: ``,
      },
    },
  ],
  downloadName: 'adventskalender-noppenfolie_papershape.pdf',
  materials: [
    {
      de: '1 DINA4 Papier (220-300g/m²)',
      en: '1 sheet of paper (8.5in x 11in) (weight: 65lb-110lb)',
    },
    materials.glue,
    materials.scissors,

    {
      type: 'tool',
      name: {
        de:
          'Luftpolsterfolie (Durchmesser der Polsterchen sollte ca. 3cm sein)',
        en: 'Bubble wrap (average of air pockets should be around 3cm) ',
      },
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'einfachen-adventskalender-selber-machen-luftpolsterfolie.jpg',
          en: 'advent-calendar-bubble-wrap.jpg',
        },
        alt_text: {
          de:
            'Einfachen Adventskalender selber basteln aus Luftpolsterfolie  mit kostenloser Vorlage von #papershape',
          en:
            'diy Christmas Advent calender from bubble wrap - free printable from #papershape',
        },
      },
    ],
  },
};
