export default {
  id: '1-01-sfk',
  category: ['animal_sculpture'],
  name: {
    de: 'Hirsch',
    en: 'Deer',
  },
  subtitle: {
    de: 'B39 x H47 x T25 cm',
    en: 'W39 x H47 x D25 cm',
  },
  meta_title: {
    de: 'Hirsch Papiertier',
    en: 'Deer Paper Animal',
  },
  meta_description_google: {
    de: 'Der Hirsch Kopf zum Aufhängen verzaubert als Wanddeko jeden Raum. ',
    en: 'The deer head enchants each room with lots of space for creativity. ',
  },
  meta_description_social: {
    de: 'Der Hirsch Kopf zum Aufhängen verzaubert als Wanddeko jeden Raum. Die magische Einhorn Trophäe lässt sich einfach falten und stecken. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The deer head enchants each room with lots of space for creativity. This magical unicorn trophy is very easy to assemble thanks to the new no-glue technique. 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  description: {
    de: `    Der Klassiker unter den Tiertrophäen: der Hirsch! Gerade für die Stadtwohnung ist er die stilvolle Lösung - ob im Kinderzimmer, in der Lounge, an der Bar oder im gemütlichen Wohnzimmer. Bastelt euren eigenen 3D Origami Hirsch in euren Wunschfarben und holt euch einbisschen Natur in eure vier Wände.

    ANLEITUNG
    
    Schneide alle Papierteile entlang der durchgezogenen Linien mit einer Schere aus.
    Um das qualitativ hochwertige Papier einfach falten zu können, muss es vorerst vorgefalzt werden. Am einfachsten gelingt dies mit einem Buttermesser, einer Ahle oder Stricknadel und einem Lineal. Das Lineal an die gestrichelten Linien anlegen und mit dem Buttermesser und viel Druck am Lineal auf den Linien entlang fahren. 
    Anschließend werden die gestrichelten Linien je nach Markierung nach innen oder außen geknickt.
    Die Verklebung der Papierteile ist dank der Klebepads eine einfache und saubere Lösung. Die Pads einfach auf die Außenseite jeder Lasche kleben und anschließend in nummerischer Reihenfolge(1,2,3 etc.) verkleben. 
    Achtet darauf die Klebepads mit einem Abstand von ca. 2 mm zur Kante aufzukleben, damit sie später von außen nicht sichtbar sind. Schaue beim Verkleben immer von außen auf das Modell.
    
    DETAILS
    
    Altersempfehlung ab 14 Jahren
    
    KOMPLETTES SET BEINHALTET
    
    11 DIN A4 Seiten Fotokarton
    Doppelseitige Klebepads
    Detaillierte schrittweise Anleitung

    `,
    en: ` 
    It’s classic among the trophies: the deer! Even for the city apartment it is a stylish solution – whether it is in the children’s room, in the lounge, at the bar or in the cosy living room. Craft your own 3D origami deer in your desired colours and it will bring a little nature into your four walls.

    Details:
    Age recommendation from 14 years
    
    Complete kit includes:
    9 DIN A4 cardboard sheets Origami Deer
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    Required materials (not included in the kit):
    Scissors
    Ruler
    Bread knife`,
  },

  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 9 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 9 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 9 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 9 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkhirsch/hirsch-weiss.jpg',
        en: 'products/sfkhirsch/hirsch-weiss.jpg',
      },
      alt_text: {
        de: 'DIY 3d Hirsch aus Papier als Wanddeko',
        en: 'Papercraft deer for wall decor ',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },
  colorinspirations: [
    {
      caption: {
        de: 'steingrau-weiß',
        en: 'schokobraun-perlweiß',
      },
      image: {
        filename: {
          de: 'products/sfkhirsch/3d-hirsch-paper-tierkopf.jpg',
          en: 'products/sfkhirsch/hirsch-braun-perlweiss.jpg',
        },
        alt_text: {
          de: '3d Hirsch aus Papier - Wanddeko aus Papiertier #papershape',
          en: '3d Deer head DIY with template #papershape',
        },
      },
    },
    {
      caption: {
        de: 'himmelblau-türkis',
        en: 'himmelblau-türkis',
      },
      image: {
        filename: {
          de: 'products/sfkhirsch/hirsch-himmelblau-tuerkis.jpg',
          en: 'products/sfkhirsch/hirsch-himmelblau-tuerkis.jpg',
        },
        alt_text: {
          de: '3d Hirsch aus Papier - Wanddeko aus Papiertier #papershape',
          en: '3d Deer head DIY with template #papershape',
        },
      },
    },
    {
      caption: {
        de: 'schokobraun-perlweiß',
        en: 'steingrau-weiß',
      },
      image: {
        filename: {
          de: 'products/sfkhirsch/hirsch-braun-perlweiss.jpg',
          en: 'products/sfkhirsch/hirsch-steingrau-weiss.jpg',
        },
        alt_text: {
          de: '3d Hirsch aus Papier - Wanddeko aus Papiertier #papershape',
          en: '3d Deer head DIY with template #papershape',
        },
      },
    },
    {
      caption: {
        de: 'rot-weiß',
        en: 'rot-weiß',
      },
      image: {
        filename: {
          de: 'products/sfkhirsch/hirsch-rot-weiss.jpg',
          en: 'products/sfkhirsch/hirsch-rot-weiss.jpg',
        },
        alt_text: {
          de: '3d Hirsch aus Papier - Wanddeko aus Papiertier #papershape',
          en: '3d Deer head DIY with template #papershape',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkhirsch/hirsch-weiss.jpg',
        en: 'products/sfkhirsch/hirsch-weiss.jpg',
      },
      alt_text: {
        de: '3d Hirsch aus Papier - Wanddeko aus Papiertier #papershape',
        en: '3d Deer head DIY with template #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Kopf & Ohren',
        en: 'Head & ears',
      },
      color2: {
        de: 'Geweih',
        en: 'Antlers',
      },
    },
    disableColors: {
      color2: ['schwarz', 'chamois'],
      color1: ['schwarz', 'chamois'],
    },
    price: 29.9,
    priceThirdCountry: 23.5,
  },
};
