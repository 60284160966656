export default {
  id: '3-01-blog',
  category: ['easter'],

  name: {
    de: 'Osterhase Pablo',
    en: 'Easter bunny Pablo',
  },
  subtitle: {
    de: 'B15 x H18 x T20 cm',
    en: 'W15 x H18 x D20 cm',
  },
  meta_title: {
    de: 'Osterhase Pablo aus Papier Vorlage',
    en: 'Easer bunny Pablo from paper Template',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für den Osterhasen Pablo kannst du deine schöne Osterdeko ganz leicht aufhübschen. ',
    en: 'With this template for Easter bunny Pablo you can beautify your Easter decoration. ',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  pdf: {
    description: {
      de: `Mit dieser digitalen Vorlage für den Osterhasen Pablo kannst du deiner schönen Osterdeko einen neuen Augenfang verleihen. <a href="/de/blog/3d-papier-osterhasen-basteln/">In diesem Blogpost</a> zeige ich dir, wie einfach das geht und welche Ideen andere Kunden bereits umgesetzt haben. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² Tonpapier zu verwenden.
      Damit die Gänseblümchen gelingen, benötigst du einen Drucker, 7 Bögen Tonpapier, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this digital template for our spring daisies you can realize a lot of creative ideas. <a href="/en/blog/3d-papier-osterhasen-basteln/">In this blogpost</a> I show you how easy it is and what other customers realized with the template.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use 130g/m² paper.
      To make the daisies succeed, you'll need a printer, 7 sheets of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 9.9,
    priceThirdCountry: 9.9,
    image: {
      filename: {
        de: 'products/sfkosterhaseshop/osterhase-pablo-papershape.jpg',
        en: 'products/sfkosterhaseshop/osterhase-pablo-papershape.jpg',
      },
      alt_text: {
        de: 'Selbst gebastelter gepunkteter 3D Osterhase aus Papier in türkis',
        en: 'Handcrafted 3d Easter bunny from paper in turquois with dots',
      },
    },
  },
};
