export default {
  id: '1-15-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Koi Fisch',
    en: 'Koi Fish',
  },
  subtitle: {
    de: 'B45 x H17x T14 cm',
    en: 'W45 x H17 x D14 cm',
  },
  meta_title: {
    de: 'Koi Fische aus Papier für alle Wasserfans - Gestalte deine Wandkunst selbst ',
    en: 'Papercraft koi fish - Create your unique wall art',
  },
  meta_description_google: {
    de: 'Du liebst Meerestiere? Dann haben wir jetzt die perfekte Wanddeko aus Papier für dich: Gestalte deine Origami Koi Fische für dein zuhause.',
    en: 'You love seafishes and coastal decor? Then you should make this papercraft koi fishes! Get the template here.',
  },
  meta_description_social: {
    de: 'DIY Papierskulptur Koi Fische für deine eigenen vier Wände - Wandkunst für maritime Dekofans',
    en: 'Papercraft koi fishes for your wall art',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?    
    - 5 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -5 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF für den Fisch mit 5 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- 3 Seiten Koi Fisch Muster zum Bedrucken 
- Koi Fisch Prüfmuster zum Nachprüfen, ob alles richtig gedruckt wurde
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 5 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m², max. 230g/m²)
- Drucker 
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 5 pages PDF template for downloading and printing the fish - directly into your mail box after payment
- 3 PDF pages of the koi fish pattern for printing
- Test pattern (3 pages) for checking if you printed everything correctly
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 5 DIN A4 or US letter colored paper (min. 160g/m², max. 230g/m²)
- printer
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkkoifisch/koi-fish-papercraft.jpg',
        en: 'products/sfkkoifisch/koi-fish-papercraft.jpg',
      },
      alt_text: {
        de: 'DIY 3d Koi Fische aus Papier als Wanddeko #papercraft #3dpapermodel #origami',
        en: 'Papercraft koi fish for wall decor #diy #papercraft #origami #3dpapermodel #origami',
      },
    },
    price: 14.9,
    priceThirdCountry: 14.9,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkkoifisch/koi-fish-papercraft.jpg',
        en: 'products/sfkkoifisch/koi-fish-papercraft.jpg',
      },
      alt_text: {
        de: 'DIY Koi Fische aus Papier - Vorlage downloaden #origami #wanddeko #bastelnmitpapier #papershape',
        en: 'Papercraft koi fishes Make your own wall art #papercraft #diy #wallart #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Farbe Fisch, rotes Muster',
        en: 'Fish Color, red pattern',
      },
    },
    disableColors: {
      color1: [
        'schwarz',
        'koenigsblau',
        'pink',
        'mint',
        'schokobraun',
        'goldmatt',
        'nudebraun',
        'hochrot',
        'steingrau',
        'himmelblau',
        'pazifik',
        'lila',
        'moosgruen',
        'orange',
        'gelb',
        'bunt',
        'rosa',
        'tannengruen',
        'maigruen',
        'bordeaux',
        'flieder',
      ],
    },
    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
