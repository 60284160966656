export default {
  url: 'ostereier-basteln',
  countermark: 'vg07.met.vgwort.de/na/cd55aea58cc14623957944e444ff45d2',
  imageFolder: 'osterei',

  meta_title: {
    de: 'Ostereier basteln mit Papier (mit Vorlage)',
    en: 'How To Make A Paper Egg (Very Easy With Free Template)',
  },
  meta_description_google: {
    de:
      'Moderne Ostereier basteln aus Papier mit der kostenlosen Vorlage, die ihr aus dem ARD-Buffet beim SWR kennt. Jetzt mit neuen Osterei-Ideen, die ihr bemalen und befüllen könnt. Hier klicken um die Vorlage herunterzuladen.',
    en:
      'Learn how to make a paper egg in 3d for Easter decoration. Just donwload the free template, choose your favorite paper color and start paper crafting.',
  },
  meta_description_social: {
    de:
      'Moderne Ostereier basteln aus Papier mit der kostenlosen Vorlage, die ihr aus dem ARD-Buffet beim SWR kennt. Jetzt mit neuen Osterei-Ideen, die ihr bemalen und befüllen könnt. Hier klicken um die Vorlage herunterzuladen.',
    en:
      'Learn how to make a paper egg in 3d for Easter decoration. Just donwload the free template, choose your favorite paper color and start paper crafting.',
  },
  meta_images_social: [
    {
      de: 'papier-ostereier-basteln.jpg',
      en: 'paper-easter-eggs-basket.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'ostereier-basteln-aus-papier.jpg',
      en: 'how-to-make-3d-paper-easter-egg.jpg',
    },
    alt_text: {
      de:
        'Ostereier basteln mit Papier als Dekoration/ PaperShape #osterhase #osterdeko #ostereier  #diy #papercraft',
      en:
        'Modern Easter egg paper craft with free template / PaperShape #eastereggs #easter #papercraft #diy',
    },
  },
  h1: {
    de: 'Moderne Ostereier basteln mit Papier (mit Vorlage)',
    en: 'How to make a 3d paper egg (for Easter)',
  },
  description: {
    de: `
      <p> Vielleicht kennt ihr dieses Ritual: </p>
      <p> In meiner Familie werden am Ostermorgen die gefärbten Eier geradezu im Duell 
      aneinandergeschlagen. Da wird um jede Ecke gefeilt, wer wann zuschlagen darf. 
      Wer es schafft alle anderen Eier anzudellen, gewinnt.
      </p>
     
      <p> Wie auch schon beim <a href="/de/blog/3d-papier-osterhasen-basteln/"> DIY Osterhasen aus Papier</a> 
      gibt es auch bei diesem Osterei reichlich Platz um kleine süße Geschenke
      zu verstauen. Als Alternative zu sonst typischen Osternestern eigentlich ganz cool, oder?
      </p>
      <p>Eine weite DIY Idee für dieses Osterei findet ihr beim <a href="/de/blog/wimpern-hase/">süßen Wimpern Häschen</a>. </p>
      <p>
      Die Vorlage ist simple umzusetzen und ihr könnt echt coole DIY Ideen damit umsetzen.
      Diese 3D-Eier habe ich auch beim 
      <a href="https://www.swr.de/buffet/kreativ/3d-eier-aus-papier/-/id=257204/did=16932124/nid=257204/w7l04l/index.html" target="_blank">ARD-Buffet vom SWR</a> hier vorgestellt.
      </p>
    `,
    en: `
      <p>
      Searching for a paper egg in 3d for Easter that you can also use as a giftbox? Welcome, this tutorial is for you! I love hiding small sweets and this Easter egg is so cute
      as a decoration. No one would expect something to be inside - so the perfect hideout.
      </p>
      <p>
      The template is easy. You go in circles and the possibilities with these paper eggs are endless. You can paint on it or just leave it plain. 
      </p>
     
    `,
  },
  inspirationTitle: {
    de: 'Wie du aus einem Blatt Papier diese Ostereier basteln kannst',
    en: 'How to make paper Easter eggs with just one sheet of paper?',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'ostereier-basteln-papier-vorlage-zum-ausdrucken.jpg',
          en: 'paper-easter-eggs-basket.jpg',
        },
        alt_text: {
          de:
            'Ostereier gestalten aus Papier / PaperShape  #osterdeko #ostereier #ostern #diy #papercraft',
          en:
            'Easter egg paper craft with free template / PaperShape #eastereggs #easter #papercraft #diy',
        },
      },
    },
    {
      text: {
        de:
          'In so einem Flecht- oder Rattankorb arrangiert, passt diese Art der Ostereier Deko super in den Hauseingang oder im Flur. Ihr könnt sie später einfach mit Süßigkeiten füllen und verschenken.',
        en:
          'This basket full of colorful Easter eggs is so adorable. I love putting them at the hallway.',
      },
    },
    {
      image: {
        filename: {
          de: 'ostereier-basteln-aus-papier-gruen.jpg',
          en: 'how-to-make-paper-egg-green.jpg',
        },
        alt_text: {
          de:
            'Papier Ostereier basteln als Osterdeko / PaperShape #osterhase #osterdeko #ostereier #ostern #diy #papercraft',
          en:
            'Easter egg paper craft tutorial with free template - choose your color / PaperShape #eastereggs #easter #papercraft #diy',
        },
      },
    },
    {
      text: {
        de: `Die Ostereier sehen richtig modern aus, wenn ihr sie zur Deko mit einem Band verziert. Gerade auch, wenn man sie verschenken möchte.`,
        en: `You may want to tie some cord around the egg in order to give it a cute look.`,
      },
    },
    {
      image: {
        filename: {
          de: 'ostereier-basteln-geometrische-muster.jpg',
          en: 'how-to-make-easter-egg-paper-geometric-pattern.jpg',
        },
        alt_text: {
          de:
            'Ostereier basteln aus Papier / PaperShape #osterhase #osterdeko #ostereier #ostern #diy #papercraft',
          en:
            'Easter egg paper craft with geometric pattern in black and white / PaperShape #eastereggs #easter #papercraft #diy',
        },
      },
    },
    {
      text: {
        de: `Wenn ihr die Ostereier bastelt, dann müsst ihr nicht zwangsläufig uni Farben verwenden. Dieses Ei mit geometrischen Mustern finde ich persönlich als Deko in einer
        eher minimalistisch gehaltenen Wohnung super cool.`,
        en: `Uni colors are cool, but you can also use patterned cardboard and craft these adorable eggs and add a more interesting look to them.`,
      },
    },
  ],

  stepsTitle: {
    de: 'Wie bastel ich Ostereier aus Papier - Anleitung',
    en: 'Tutorial: follow these steps for crafting paper eggs',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'anleitungsschritt-vorlage-herunterladen.jpg',
          en: 'template-step-1.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ostereier Vorlage herunterladen',
          en: 'Step: Download Easter Egg template',
        },
      },
      text: {
        de: `Die Vorlage für die Ostereier einfach auf ein DIN A4 Ton- oder Fotokarton in eurer Wunschfarbe oder Muster ausdrucken und ran an die Schere.`,
        en: `Print the paper egg template (US letter) on paper of your choice.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-schneiden.jpg',
          en: 'tutorial-step-2.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ausschneiden',
          en: 'Step: Cutting',
        },
      },
      text: {
        de: `Wie immer bei meinen 3D Vorlagen, geht es mit dem Schneiden los. Mit einer Schere einfach alle Elemente entlang der durchgezogenen Linie der Osterei Vorlage ausschneiden.`,
        en: `Cut out the paper parts (A, B, C, ...) by cutting along the solid lines of the Easter egg template.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-falzen.jpg',
          en: 'tutorial-step-3.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Falzen',
          en: 'Step: Folding',
        },
      },
      text: {
        de: `Legt nun ein Lineal an alle gestrichelten Linien (egal wie sie aussehen) an und fahrt mit einer Ahle oder Falzbein die Linien mit Druck ab. Unabhängig davon, 
        ob ihr Fotokarton oder sogar nur Kopierpapier verwendet, lasst diesen Schritt nicht weg. So entstehen wirklich schöne Kanten und ihr habt mehr Spaß am Endergebnis.`,
        en: `Place a ruler on the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines. 
                    Don't skip this step - no matter what paper you use. The result will look so much better.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-knicken.jpg',
          en: 'tutorial-step-4.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Knicken',
          en: 'Step: Folding paper parts',
        },
      },
      text: {
        de: `Legt das Papierteil A so vor euch hin, dass ihr die Zahlen und Buchstaben seht. Knickt die kurz gestrichelten Linien nach innen,
        d.h. zu euch hin oder nach oben (Talfalte). Faltet alle lang gestrichelten Linien nach außen, d.h. von euch weg bzw. nach unten (Bergfalte).`,
        en: `Start with the paper piece A and place it in front of you. 
              The printed side should be facing you. Subsequently fold all the dotted lines 
              either inwards, which means to fold up (short dotted lines) or outwards, 
              which means to fold down (long dotted lines), according to the markings.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-kleben.jpg',
          en: 'tutorial-step-5.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together',
        },
      },
      text: {
        de: `Bringt auf die Laschen Kleber auf und verklebt jeweils die passenden Zahlenpaare. 
        Ich verwende gerne Klebepads, weil bei flüssigem Kleber einfach zu oft aus Versehen doch 
        ein Klecks über den Rand läuft. Außerdem sind eure Hände klebefrei und ihr müsste euch keinen Kopf machen, dass ihr das Osterei von der schönen Seite beschmutzt. Achtet darauf den Kleber nicht direkt an der Kante anzulegen, 
        weil sonst etwas rausschauen oder rauslaufen könnte. Beginnt mit der Nr. 1 auf A und B und bastelt in nummerischer Reihenfolge (2,3,4, etc.) weiter.`,
        en: `Use double-sided tape or any glue onto the unprinted side of 
              the tabs. Each number exists twice. Stick all the same numbers together 
              in ascending order (1, 2, 3, ...). Start with section 1 and stick it to
              the section marked 1 on paper A, pressing firmly enough to make them stick together.`,
      },
    },
  ],
  downloadName: 'Ostereier-basteln.pdf',
  materials: [
    {
      de: '1 DINA4 Papier uni oder gemustert (160-300g/m²)',
      en:
        '1 cardstock paper uni or with pattern (8.5in x 11in) (weight: 65lb-110lb / 176gsm-300gsm)',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
    {
      de: 'Süßigkeiten zum Befüllen',
      en: 'Sweets for filling',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert die DIY Idee für euer nächstes Projekt auf Pinterest',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'ostereier-basteln-papier.jpg',
          en: 'how-to-make-paper-eggs.jpg',
        },
        alt_text: {
          de:
            'Ostereier basteln aus Papier als moderne Dekoration/ PaperShape #osterdeko #ostereier #ostern #diy #papercraft',
          en:
            'Easter egg paper craft with free template / PaperShape #eastereggs #easter #papercraft #diy',
        },
      },
    ],
  },
};
