export default {
  url: 'papier-apfel-basteln',
  countermark: 'vg07.met.vgwort.de/na/d686cd39b1f34a509b9be2211e05b76c',
  imageFolder: 'apfel',

  name: {
    de: 'Papier Apfel',
    en: 'Papercraft apple',
  },
  id: '3-26-blog',
  pdf: {
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'content/apfel/3d-papier-apfel-basteln.jpg',
        en: 'content/apfel/3d-papier-apfel-basteln.jpg',
      },
      alt_text: {
        de: 'Obst aus Papier: 3D Apfel basteln ',
        en: 'Learn how to papercraft a 3d apple',
      },
    },
  },

  meta_title: {
    de: 'DIY Papier Äpfel basteln (Anleitung + Vorlage)',
    en: 'How to make 3d apples from paper',
  },
  meta_description_google: {
    de: 'Zur Herbstdeko dürfen es mal andere Materialien als Eicheln, Kastanien und Ahornblätter sein? Wie wäre es mit diesem schlichten 3d Apfel aus Papier zum selber basteln. Mit der kostenlosen Vorlage von PaperShape geht das ganz schnell. ',
    en: 'Decorate your flat with these cute apples from paper. With the template from papershape it is super easy and a lovely fall decor. ',
  },
  meta_description_social: {
    de: 'Zur Herbstdeko dürfen es mal andere Materialien als Eicheln, Kastanien und Ahornblätter sein? Wie wäre es mit diesem schlichten 3d Apfel aus Papier zum selber basteln. Mit der kostenlosen Vorlage von PaperShape geht das ganz schnell. #bastelnmitpapier',
    en: 'Decorate your flat with these cute apples from paper. With the template from papershape it is super easy and a lovely fall decor. ',
  },
  meta_images_social: [
    {
      de: '3d-papier-apfel-basteln.jpg',
      en: 'fall-crafts-paper-apple.jpg',
    },
  ],
  image_main: {
    filename: {
      de: '3d-papier-apfel-basteln.jpg',
      en: 'fall-crafts-paper-apple.jpg',
    },
    alt_text: {
      de: 'Apfel Herbstdeko basteln aus Papier in 3d mit  Vorlage. PaperShape ',
      en: 'Apple paper craft for your fall decor (with template from PaperShape) ',
    },
  },
  h1: {
    de: 'DIY Äpfel basteln mit Papier als Herbstdeko',
    en: 'How to make 3d apples from paper ',
  },
  description: {
    de: `
      <p> Leckere Äpfel kennen wir in natura zu genüge, denn wir essen sie das ganze Jahr über. Klar, dass sie als originelle Herbstdeko deswegen nicht unbedingt die erste Wahl sind.</p>
      <p> Aus Papier allerdings haben sie einen ganz anderen Touch und eignen sich super als Ergänzung zu einer Herbstdeko, die vielleicht eher auf Naturmaterialien konzentriert ist. </p>
      <p>Wenn ihr nicht nur diesen dreidimensionalen Apfel falten möchtet, sondern weitere Herbstdeko, empfehl ich euch diesen 
      <a href="/de/blog/3d-kuerbis-basteln/">3D Kürbis aus Papier</a> basteln. </p>
     
     
      
    
    `,
    en: `
      <p>
      You have to try this fall decor idea:
      </p>
          <p>
     These lovely 3d apples are an elegant table decor for fall. You can even garnish them with your fall wreath. If you are into paper as I am then you have to 
     make this <a href="/en/blog/3d-kuerbis-basteln/">3d pumpkin from paper</a>, too. How about decorate your flat for fall with paper diys this year?
      </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'apfel-basteln-papier.jpg',
          en: 'paper-apple-fall-crafts.jpg',
        },
        alt_text: {
          de: 'Äpfel Herbstdeko basteln aus Papier in 3d mit Vorlage. ',
          en: 'Apple paper craft for your fall decor (with template from PaperShape)',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'papier-apfel-basteln.jpg',
          en: 'fall-crafts-paper-apple.jpg',
        },
        alt_text: {
          de: 'Apfel Herbstdeko basteln aus Papier in 3d mit Vorlage. ',
          en: 'Apple paper craft for your fall decor (with template from PaperShape)',
        },
      },
    },
  ],

  stepsTitle: {
    de: 'Wie bastel ich 3D Äpfel aus Papier - Anleitung',
    en: 'How do I make 3d apples from paper',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '01_schneiden.mp4',
      text: {
        de: `Ihr benötigt pro Papierapfel 2 DINA4 Seiten in einem satten Rotton. Verwendet am besten Tonpapier mit 
        einer Grammatur von bis zu 130g/m². Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer
        Schere aus. Beim Apfel gibt es einige Stellen, die ihr vielleicht besser/ leichter mit einem Cutter ausschneidet.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '02_falzen.jpg',
          en: '02_falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_falzen.mp4',
      text: {
        de: `Falzt mit einer Ahle und einem Lineal alle gestrichelten Linien (egal ob lang oder kurz gestrichelt) vor. Dazu das Lineal an die Linie anlegen und mit der Ahle mit Druck
        nachfahren.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '03_falten.jpg',
          en: '03_falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '03_falten.mp4',
      text: {
        de: ` Faltet die lang gestrichelten Linien von euch weg, d.h. nach unten. Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin.
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '04_kleben.jpg',
          en: '04_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '04_kleben.mp4',
      text: {
        de: `Klebt auf die Laschen der einezelnen Papierteile Klebepads. In dieser Vorlage sind die Anzahl der Klebepads angegeben. Wichtig ist, dass ihr das Pad auf die schöne Seite und damit die unbedruckte Seite der Lasche klebt. 
        Ihr beginnt in nummerischer Reihenfolge anschließend die einzelnen Teile zu verkleben, d.h. ihr startet bei 1 auf A und B und verklebt diese. Danach verklebt ihr Nr. 2 und so weiter. Eine Zahl existiert immer doppelt, sodass die Zahlenpaare
        immer verklebt werden müssen.
  `,
        en: ``,
      },
    },
  ],

  downloadName: 'apfel-apple_papershape.pdf',
  materials: [
    {
      de: '2 DINA4 Papier in rot (bis zu 130g/m²)',
      en: '2 colored papers in red (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
};
