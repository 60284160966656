export default {
  id: '2-25-sfk',
  category: ['easter'],
  name: {
    de: 'Hahn',
    en: 'Rooster',
  },
  subtitle: {
    de: 'B8 x H16 x T19 cm',
    en: 'W8 x H16 x D19 cm',
  },
  meta_title: {
    de: 'Papier Hahn basteln zu Ostern (mit Plotterdatei)',
    en: 'Papercraft rooster for Easter (with template) ',
  },
  meta_description_google: {
    de: 'Bastelt euch ein wenig Dorfleben in eure vier Wände mit diesem prächtigen Hahn aus Papier. Er ist garantiert pflegeleicht und weckt euch sicher nicht durch sein frühes Kikerie.',
    en: 'Make this gorgeous papercraft rooster for Easter with our easy downloadable template.',
  },
  meta_description_social: {
    de: 'Bastelt euch ein wenig Dorfleben in eure vier Wände mit diesem prächtigen Hahn aus Papier. Er ist garantiert pflegeleicht und weckt euch sicher nicht durch sein frühes Kikerie. Dieser Papercraft Hahn gesellt sich gerne zu anderen Hühnern im eurem Stall. Einfach die Vorlage herunterladen und in euren Lieblingsfarben selber basteln. Ein tolles DIY-Projekt für die Feiertage vor Ostern.',
    en: 'Make this gorgeous papercraft rooster for Easter with our easy downloadable template',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?    
    - 3 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -3 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 3 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 3 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 3 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 3 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkhahn/papier-hahn-basteln.jpg',
        en: 'products/sfkhahn/papier-hahn-basteln.jpg',
      },
      alt_text: {
        de: 'Hahn basteln aus Papier zu Ostern #papershape #papercraft #3dpapermodel #hahnbasteln #osterhuhn #origami',
        en: 'Printable papercraft rooster for Easter #papershape #papercraft #diypapercraft #paperfarmanimals #3dpapermodel #origami',
      },
    },
    price: 6.9,
    priceThirdCountry: 6.9,
  },
  colorinspirations: [
    {
      caption: {
        de: 'Orange & Bordeauxrot',
        en: 'Orange & bordeaux red ',
      },
      image: {
        filename: {
          de: 'products/sfkhahn/papier-hahn-basteln.jpg',
          en: 'products/sfkhahn/papier-hahn-basteln.jpg',
        },
        alt_text: {
          de: 'Hahn basteln aus Papier zu Ostern #papershape #papercraft #3dpapermodel #hahnbasteln #osterhuhn #origami',
          en: 'Printable papercraft rooster for Easter #papershape #papercraft #diypapercraft #paperfarmanimals #3dpapermodel #origami',
        },
      },
    },

    {
      caption: {
        de: 'Hell lila & Gelb',
        en: 'Pale lilac & yellow',
      },
      image: {
        filename: {
          de: 'products/sfkhahn/3d-papercraft-hahn-papier-basteln.jpg',
          en: 'products/sfkhahn/3d-papercraft-hahn-papier-basteln.jpg',
        },
        alt_text: {
          de: 'Hahn basteln aus Papier zu Ostern #papershape #papercraft #3dpapermodel #hahnbasteln #osterhuhn #origami',
          en: 'Printable papercraft rooster for Easter #papershape #papercraft #diypapercraft #paperfarmanimals #3dpapermodel #origami',
        },
      },
    },

    {
      caption: {
        de: 'Rot & Gelb',
        en: 'Red & yellow',
      },
      image: {
        filename: {
          de: 'products/sfkhahn/papercraft-rooster.jpg',
          en: 'products/sfkhahn/papercraft-rooster.jpg',
        },
        alt_text: {
          de: 'Hahn basteln aus Papier zu Ostern #papershape #papercraft #3dpapermodel #hahnbasteln #osterhuhn #origami',
          en: 'Printable papercraft rooster for Easter #papershape #papercraft #diypapercraft #paperfarmanimals #3dpapermodel #origami',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkhahn/papier-hahn-basteln.jpg',
        en: 'products/sfkhahn/papier-hahn-basteln.jpg',
      },
      alt_text: {
        de: 'Hahn basteln aus Papier zu Ostern #papershape #papercraft #3dpapermodel #hahnbasteln #osterhuhn #origami',
        en: 'Printable papercraft rooster for Easter #papershape #papercraft #diypapercraft #paperfarmanimals #3dpapermodel #origami',
      },
    },
    colors: {
      color1: {
        de: 'Körper',
        en: 'Body & feathers',
      },
      color2: {
        de: 'Federn, Kamm & Füße',
        en: 'Feathers, comb & feet',
      },
    },
    disableColors: {
      color2: ['bunt', 'schwarz'],
      color1: ['schwarz'],
    },
    price: 16.9,
    priceThirdCountry: 15.9,
  },
};
