import React from 'react';
import { connect } from 'react-redux';
import countries from '../data/countries';
import { getShippingCountryKey } from '../state/order/orderSelectors';

const ShippingCountry = ({ countryKey }) => {
  if (!countryKey) {
    return <span />;
  }
  return <span>{countries[countryKey].bezeichnung}</span>;
};

const mapStateToProps = (state) => {
  return {
    countryKey: getShippingCountryKey(state),
  };
};

export default connect(mapStateToProps)(ShippingCountry);
