import React from 'react';
import Lang from '../common/Lang';
import { selectedLang } from '../data/lang';
import { addFolder } from '../pages/blog/BlogPost';
import PsImage from './PsImage';
import { PsLink } from './PsLink';

const removeParenthesisText = (text) =>
  text == null || text.indexOf('(') < 0
    ? text
    : text.substring(0, text.indexOf('('));

const Title = ({ withDescription, children, ...props }) =>
  withDescription ? (
    <h2 {...props}>{children}</h2>
  ) : (
    <div {...props}>{children}</div>
  );

export default function PsBlogPostView({ blogPost, withDescription }) {
  const imageFolder = 'content/' + blogPost.imageFolder + '/';
  const addImageFolder = addFolder(imageFolder);
  return (
    <PsLink to={'/blog/' + blogPost.url} disableStyles>
      <PsImage
        image={addImageFolder(blogPost.image_main)}
        style={{ marginBottom: '10px' }}
        data-pin-nopin="true"
        format="600x900"
      />
      <Title
        withDescription={withDescription}
        style={{
          fontFamily: 'Montserrat',
          fontWeight: 300,
          fontSize: withDescription ? '24px' : '19px',
          minHeight: '68px',
          lineHeight: 1.2,
        }}
        className="text-center">
        {removeParenthesisText(blogPost.h1[selectedLang])}
      </Title>
      {withDescription && (
        <p
          className="hidden-xs"
          style={{ marginTop: '10px', fontSize: '14px', minHeight: '100px' }}>
          {blogPost.meta_description_google[selectedLang]
            .substr(0, 180)
            .split(' ')
            .slice(0, -1)
            .join(' ') + '...'}
        </p>
      )}
      <div
        style={{
          textAlign: 'center',
          marginBottom: '20px',
          marginTop: '20px',
        }}>
        <div className="btn btn-default">
          <Lang de="zur Anleitung" en="see instructions" />
        </div>
      </div>
    </PsLink>
  );
}
