import React from 'react';
import ReactDOM from 'react-dom';
// import 'intersection-observer'; polyfill for impression tracker
import { Provider } from 'react-redux';
import Raven from 'raven-js';
import { compose, createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import thunk from 'redux-thunk';
import createRavenMiddleware from 'raven-for-redux';
import App from './App';
import { fetchReducer } from './state/fetch/fetchReducer';
import { orderReducer } from './state/order/orderReducer';
import { fetchError, fetchStart } from './state/fetch/fetchActions';
import { paymentReducer } from './state/payment/paymentReducer';
import { customFormReducer } from './state/customFormReducer';
import env from './env';
// import registerServiceWorker from "./registerServiceWorker";

// First, checks if it isn't implemented yet.
// if (!String.prototype.format) {
//   String.prototype.format = function() {
//     var args = arguments;
//     return this.replace(/{(\d+)}/g, function(match, number) {
//       return typeof args[number] !== 'undefined' ? args[number] : match;
//     });
//   };
// }

if (process.env.NODE_ENV === 'production') {
  Raven.config('https://5bc4d6b8d8df4e82a851529f116915b0@sentry.io/264307', {
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_STAGE,
    autoBreadcrumbs: true,
  }).install();
}

/**
 * Logs all actions and states after they are dispatched.
 */
const logger = store => next => action => {
  console.group(action.type);
  console.info(
    'dispatching',
    action.type,
    action,
    JSON.stringify(action, null, 2)
  );
  let result = next(action);
  console.log('next state', store.getState());
  console.groupEnd(action.type);
  return result;
};

const fetchMiddleware = store => next => action => {
  if (!action.fetch) {
    return next(action);
  }

  const fetchConfig = action.fetch;
  store.dispatch(fetchStart());
  return fetch(env.BACKEND_URL + '/command/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'PS-Client-Version': process.env.REACT_APP_VERSION,
    },
    body: JSON.stringify(fetchConfig.body),
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      console.warn(
        `http error during the execution of action [${fetchConfig.body
          .type}]. status [${response.status}] statusText [${response.statusText}] url [${response.url}] localtime [${new Date()}]`
      );
      throw new Error(
        `http error during the execution of action [${fetchConfig.body
          .type}]. status [${response.status}] statusText [${response.statusText}] url [${response.url}]`,
        response
      );
    })
    .catch(error => {
      store.dispatch(
        fetchError(
          'error during http call ' + JSON.stringify(fetchConfig.body),
          error.message
        )
      );
      Raven.captureException(error);
      throw error;
    });
};

const rootReducer = combineReducers({
  order: orderReducer,
  form: customFormReducer(),
  fetch: fetchReducer,
  payment: paymentReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
}
middleware = [
  ...middleware,
  fetchMiddleware,
  // Middlewares, like `redux-thunk` that intercept or emit actions should precede `raven-for-redux`.
  createRavenMiddleware(Raven),
];

let store = createStore(
  rootReducer,
  undefined,
  composeEnhancers(applyMiddleware(...middleware), autoRehydrate())
);

persistStore(store, { blacklist: ['fetch', 'payment'] });

const AppWithProvider = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

// modification see https://github.com/stereobooster/react-snap
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<AppWithProvider />, rootElement);
} else {
  ReactDOM.render(<AppWithProvider />, rootElement);
}

// ReactDOM.render(<AppWithProvider />, document.getElementById('root'));
// registerServiceWorker();
