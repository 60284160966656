import React from 'react';
import { connect } from 'react-redux';
import Currency from './Currency';
import {
  getOrderSubtotalSum,
  getOrderSubtotalSumWithVoucher,
} from '../state/order/orderSelectors';

function OrderSubtotalSumGross({
  orderSubtotalSum,
  orderSubtotalSumWithVoucher,
}) {
  return orderSubtotalSum !== orderSubtotalSumWithVoucher ? (
    <div>
      <div>
        <small style={{ textDecoration: 'line-through' }}>
          <Currency value={orderSubtotalSum} />
        </small>
      </div>
      <div>
        <Currency value={orderSubtotalSumWithVoucher} />
      </div>
    </div>
  ) : (
    <Currency value={orderSubtotalSum} />
  );
}

const mapStateToProps = (state) => {
  return {
    orderSubtotalSum: getOrderSubtotalSum(state),
    orderSubtotalSumWithVoucher: getOrderSubtotalSumWithVoucher(state),
  };
};

export default connect(mapStateToProps)(OrderSubtotalSumGross);
