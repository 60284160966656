export default {
  id: '2-35-sfk',
  category: ['gift_wrapping'],

  name: {
    de: ' Geldgeschenk zur Hochzeit: Heißluftballon mit Geldschein-Säckchen ',
    en: ' Money gift for a wedding: Hot air balloon with money bags',
  },
  subtitle: {
    de: 'B17 x H32 x T18 cm',
    en: 'W17 x H32 x D18 cm',
  },
  meta_title: {
    de: '3D Heißluftballon als kreatives Hochzeitsgeschenk basteln',
    en: 'Money gift for a wedding: Hot air balloon with money bags',
  },
  meta_description_google: {
    de: 'Bastel mit unserer Vorlage einen Heißluftballon aus Papier als kreatives Geldgeschenk zur Hochzeit.',
    en: 'Use our template to make a low poly hot air balloon out of paper as a creative wedding gift.',
  },
  meta_description_social: {
    de: 'Bastel mit unserer Vorlage einen Papercraft Heißluftballon aus Papier als kreatives Geldgeschenk zur Hochzeit',
    en: 'Use our template to make a papercraft hot air balloon out of paper as a creative money gift for a wedding',
  },

  description: {
    de: `Über den Wolken! So fühlt sich ein frisch vermähltes Brautpaar. Passend zu diesem Höhenflug bastelt ihr einen Papier Heißluftballon als kreatives Geldgeschenk zur Hochzeit.
      
      Das Besondere an diesem Heißluftballon sind die Sandsäcke: sie sind gefaltete Geldscheine. Im Korb selbst dekoriert ihr viele kleine saftige Kleeblätter als Zeichen für das zukünftige Glück des Brautpaares. 
      
      In der Vorlage findet ihr süße Dekoelemente für den Heißluftballon wie kleine Herzen, Wimpelketten und eine Wolke mit der Aufschrift "Just Married".  
   
    Was ist drin im Bastelset?    
    - 7 vorgedruckte DIN A4 Bögen Tonkarton  
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das Bastelset für Kinder ab 14 Jahren.
    
    `,
    en: `Above the clouds! This is how a newlywed couple feels. In keeping with this high-altitude flight, you can make a paper hot air balloon as a creative money gift for the wedding.
      
      The special thing about this hot air balloon is the sandbags: they are folded banknotes. In the basket itself, you can decorate lots of little juicy shamrocks as a sign of the bride and groom's future happiness. 
      
      The template contains cute decorative elements for the hot air balloon, such as little hearts, bunting and a cloud with the words "Just Married".  
   
    What's in the craft kit?    
    - 10 pre-printed A4 sheets of cardboard for one cat 
    - 500 double-sided, strong adhesive pads for clean and quick gluing
    - Detailed step-by-step instructions in German and English for easy assembly
   
    What else do you need?
    A little patience, scissors, a ruler and a folding tool (e.g. awl, butter knife)
`,
  },

  pdf: {
    description: {
      de: `Über den Wolken! So fühlt sich ein frisch vermähltes Brautpaar. Passend zu diesem Höhenflug bastelt ihr einen Papier Heißluftballon als kreatives Geldgeschenk zur Hochzeit.
      
      Das Besondere an diesem Heißluftballon sind die Sandsäcke: sie sind gefaltete Geldscheine. Im Korb selbst dekoriert ihr viele kleine saftige Kleeblätter als Zeichen für das zukünftige Glück des Brautpaares. 
      
      In der Vorlage findet ihr süße Dekoelemente für den Heißluftballon wie kleine Herzen, Wimpelketten und eine Wolke mit der Aufschrift "Just Married". <a href="/de/blog/heissluftballon-geldgeschenk-hochzeit">Die Anleitung zum Hochzeitsballon findet ihr hier.</a> 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du mit deinem kreativen Geldgeschenk direkt loslegen. Ich empfehle dir 230g/m² Tonkarton zu verwenden.
    Damit dieser low poly Heißluftballon gelingt, benötigst du  DIN A4 Bögen Tonkarton, einen Drucker, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `Above the clouds! This is how a newlywed couple feels. In keeping with this high-altitude flight, you can make a paper hot air balloon as a creative money gift for the wedding.
      
      The special thing about this hot air balloon is the sandbags: they are folded banknotes. In the basket itself, you can decorate lots of little juicy shamrocks as a sign of the bride and groom's future happiness. 
      
      The template contains cute decorative elements for the hot air balloon, such as little hearts, bunting and a cloud with the words "Just Married".
      
      With the PDF template in A4 format and Letter size to print out yourself, you can get started right away with your creative money gift. I recommend using 230g/m² cardboard.
    To make this low poly hot air balloon, you will need A4 sheets of cardboard, a printer, scissors and glue.

      This is a digital product. You will receive the download link by email immediately after receipt of payment. This will take place within one hour.


`,
    },
    price: 9.9,
    priceThirdCountry: 9.9,

    image: {
      filename: {
        de: 'products/heissluftballonhochzeit/heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
        en: 'products/heissluftballonhochzeit/heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
      },
      alt_text: {
        de: 'Heißluftballon als Hochzeits-Geldgeschenk in 3D aus Papier',
        en: 'Wedding gift: hot air balloon as a 3D papercraft',
      },
    },
  },

  colorinspirations: [
    {
      caption: {
        de: 'Heißluftballon in rot (Ballonstreifen 1) und perlweiß (Ballonstreifen 2) von vorne',
        en: 'Hot air ballon in red (colour 1) and pearl white (colour 2) tones',
      },
      image: {
        filename: {
          de: 'products/heissluftballonhochzeit/heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
          en: 'products/heissluftballonhochzeit/heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
        },
        alt_text: {
          de: 'Heißluftballon in rot perlweiß mit Geldscheinen als Geldsäcke ',
          en: 'Hot air balloon in red and pearl white with banknotes as money bags ',
        },
      },
    },

    {
      caption: {
        de: 'Heißluftballon in chamois und perlweiß von vorne',
        en: 'Hot air ballon in chamois and pearl white tones',
      },
      image: {
        filename: {
          de: 'products/heissluftballonhochzeit/hochzeitsgeschenk-heissluftballon-papercraft.jpg',
          en: 'products/heissluftballonhochzeit/hochzeitsgeschenk-heissluftballon-papercraft.jpg',
        },
        alt_text: {
          de: 'Heißluftballon in beige-Tönen mit Geldscheinen als Geldsäcke ',
          en: 'Hot air balloon in beige tones with banknotes as money bags ',
        },
      },
    },

    {
      caption: {
        de: 'Vorschau auf die Bastelvorlage',
        en: 'Preview of the template',
      },
      image: {
        filename: {
          de: 'products/heissluftballonhochzeit/heissluftballon-hochzeit-vorlage-vorschau.jpg',
          en: 'products/heissluftballonhochzeit/heissluftballon-hochzeit-vorlage-vorschau.jpg',
        },
        alt_text: {
          de: '3D Heißluftballon Bastelvorlage Vorschau PDF',
          en: '3D hot air balloon craft template preview PDF',
        },
      },
    },
  ],

  choose: {
    image: {
      filename: {
        de: 'products/heissluftballonhochzeit/heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
        en: 'products/heissluftballonhochzeit/heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
      },
      alt_text: {
        de: 'Heißluftballon als Hochzeitsgeldgeschenk von PaperShape',
        en: 'Hot air balloon as a wedding gift from PaperShape',
      },
    },
    colors: {
      color1: {
        de: 'Ballonstreifen 1',
        en: 'Balloon stripes 1',
      },

      color2: {
        de: 'Ballonstreifen 2 + Korb',
        en: 'Balloon stripes 2 + basket',
      },
    },
    disableColors: {
      color1: ['schwarz'],
      color2: ['schwarz'],
    },
    price: 19.9,
    priceThirdCountry: 19.9,
  },
};
