export default {
  id: '1-12-sfk',
  category: ['animal_sculpture'],
  name: {
    de: 'Seepferdchen',
    en: 'Sea horse',
  },
  subtitle: {
    de: 'B11 x H62 x T23 cm',
    en: 'W11 x H62 x D23 cm',
  },
  meta_title: {
    de: 'Seepferdchen aus Papier für alle Wasserfans - Gestalte deine Wandkunst selbst ',
    en: 'Papercraft sea horse - Create your unique wall art',
  },
  meta_description_google: {
    de: 'Du liebst Meerestiere? Dann haben wir jetzt die perfekte Wanddeko aus Papier für dich: Gestalte dein eigenes Origami Seepferdchen für dein zuhause.',
    en: 'You love seafishes and coastal decor? Then you should make this papercraft sea horse! Get the template here.',
  },
  meta_description_social: {
    de: 'DIY Papierskulptur Seepferdchen für deine eigenen vier Wände - Wandkunst für maritime Dekofans',
    en: 'Papercraft sea horse for your wall art - be creative and decorate it with embellishments',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?    
    - 8 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -8 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 8 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 8 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 8 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 8 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkseepferdchen/papercraft-seepferdchen-papier.jpg',
        en: 'products/sfkseepferdchen/papercraft-seepferdchen-papier.jpg',
      },
      alt_text: {
        de: 'DIY 3d Seepferdchen aus Papier als Wanddeko #papercraft #3dpapermodel #origami',
        en: 'Papercraft sea horse for wall decor #diy #papercraft #origami #3dpapermodel #origami',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkseepferdchen/papercraft-seepferdchen-papier.jpg',
        en: 'products/sfkseepferdchen/papercraft-seepferdchen-papier.jpg',
      },
      alt_text: {
        de: 'DIY Wandtrophäe Seepferdchen aus Papier - Vorlage downloaden #origami #wanddeko #bastelnmitpapier #papershape',
        en: 'Papercraft sea horse Make your own wall art #papercraft #diy #wallart #papershape ',
      },
    },
    colors: {
      color1: {
        de: 'Seepferdchen',
        en: 'sea horse',
      },
    },
    disableColors: {
      color1: ['schwarz'],
    },
    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
