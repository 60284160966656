import { getTextBySelectedLang } from '../../data/lang';
import { NEWSLETTER_SIGNIN_FORM_SEND_SUCCESSFUL } from '../newsletter/newsletterFormActions';
import { FETCH_ERROR, FETCH_START, VALIDATION_ERROR } from './fetchActions';
import { ORDER_SUCCESSFUL } from '../order/orderActions';
import {
  CREATE_PAYMENT,
  EXECUTE_PAYMENT,
  PAYMENT_DONE,
} from '../payment/paymentActions';
import { CONTACT_FORM_SEND_SUCCESSFUL } from '../contact/contactFormActions';
import { CLEAR_VOUCHER, VOUCHER_VALIDATED } from '../voucher/voucherActions';
import { actionTypes } from 'redux-form';
import { REHYDRATE } from 'redux-persist/constants';
import uniqBy from 'lodash/fp/uniqBy';

const uniqByMessageKey = uniqBy((message) => message.key);

export function fetchReducer(
  state = {
    rehydrated: false,
    loading: false,
    messages: [],
    voucherMessage: null,
  },
  { type, payload, error } // error => actionTypes.SET_SUBMIT_FAILED
) {
  switch (type) {
    case REHYDRATE:
      return {
        ...state,
        rehydrated: true,
      };
    case FETCH_START:
      return {
        ...state,
        loading: true,
        messages: [],
        voucherMessage: null,
      };
    case CREATE_PAYMENT:
    case PAYMENT_DONE:
    case ORDER_SUCCESSFUL:
    case EXECUTE_PAYMENT:
      return {
        ...state,
        loading: false,
        messages: [],
        voucherMessage: null,
      };
    case CLEAR_VOUCHER:
      return {
        ...state,
        voucherMessage: null,
      };
    case VOUCHER_VALIDATED:
      return {
        ...state,
        loading: false,
        messages: [],
        voucherMessage: payload.valid
          ? {
              success: getTextBySelectedLang(
                'Der Gutschein ist gültig.',
                'The voucher is valid.'
              ),
            }
          : {
              error: getTextBySelectedLang(
                'Der Gutschein ist ungültig.',
                'The voucher is invalid.'
              ),
            },
      };
    case CONTACT_FORM_SEND_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        messages: [
          {
            key: 'CONTACT_FORM_SEND_SUCCESSFUL',
            success: getTextBySelectedLang(
              'Vielen Dank. Ich melde mich in den nächsten Tagen bei dir.',
              'Thank you. I will get in contact with you within the following days.'
            ),
          },
        ],
        voucherMessage: null,
      };
    case NEWSLETTER_SIGNIN_FORM_SEND_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        messages: [
          {
            key: 'NEWSLETTER_SIGNIN_FORM_SEND_SUCCESSFUL',
            success: getTextBySelectedLang(
              'Vielen Dank. Ich habe dir eine E-Mail zur Bestätigung der Anmeldung geschickt.',
              'Thank you. I have sent you an email to confirm the registration.'
            ),
          },
        ],
        voucherMessage: null,
      };
    case actionTypes.SET_SUBMIT_FAILED:
      return {
        ...state,
        messages: error
          ? uniqByMessageKey(
              state.messages.concat({
                key: 'SET_SUBMIT_FAILED',
                error: getTextBySelectedLang(
                  'Bitte überprüfe die Meldungen an den Eingabefeldern.',
                  'Please check the messages at the input fields.'
                ),
              })
            )
          : [],
      };
    case VALIDATION_ERROR:
      return {
        ...state,
        messages: uniqByMessageKey(
          state.messages.concat({
            key: 'VALIDATION_ERROR',
            error: payload.errorText,
          })
        ),
      };
    case FETCH_ERROR:
      return {
        ...state,
        loading: false,
        messages: [
          {
            key: 'FETCH_ERROR',
            error: getTextBySelectedLang(
              'Leider ist ein Fehler aufgetreten. Bitte versuche es erneut.',
              'An error has occurred. Please try again.'
            ),
          },
        ],
        voucherMessage: null,
      };
    case actionTypes.REGISTER_FIELD:
      // reset messages on initialize form
      return {
        ...state,
        messages: [],
      };
    default:
      return state;
  }
}
