export default {
  url: 'heissluftballon-geldgeschenk-hochzeit',
  countermark: 'vg07.met.vgwort.de/na/132d963276d4403887a7496d2a37afbb',
  imageFolder: 'heissluftballonhochzeitblog',

  name: {
    de: 'Heißluftballon als Hochzeitsgeldgeschenk',
    en: 'Hot air balloon as a wedding gift',
  },
  id: '2-35-sfk',
  pdf: {
    price: 9.9,
    priceThirdCountry: 9.9,
    image: {
      filename: {
        de: 'content/heissluftballonhochzeit/heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
        en: 'content/heissluftballonhochzeit/heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
      },
      alt_text: {
        de: 'Heißluftballon basteln aus Papier als Geldgeschenk zur Hochzeit',
        en: 'Papercraft hot air balloon as a wedding gift',
      },
    },
  },

  meta_title: {
    de: 'Geldgeschenk zur Hochzeit: DIY Heißluftballon aus Papier ',
    en: 'Papercraft hot air balloon as wedding gift',
  },
  meta_description_google: {
    de: 'Als Geschenkidee oder als Deko: ich zeige dir, wie du einen Heißluftballon aus Papier basteln kannst samt Vorlage',
    en: 'As a gift idea or decoration: I will show you how to make a hot air balloon out of paper, including the template',
  },
  meta_description_social: {
    de: 'Bastel mit unserer Vorlage einen Heißluftballon aus Papier als kreatives Geldgeschenk zur Hochzeit',
    en: 'Use our template to make a hot air balloon out of paper as a creative money gift for a wedding',
  },
  meta_images_social: [
    {
      de: 'heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
      en: 'heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
      en: 'heissluftballon-basteln-hochzeit-geldgeschenk.jpg',
    },
    alt_text: {
      de: 'Selbstgemachter rot-weißer Heißluftballon aus Papier',
      en: 'Homemade red and white hot air balloon made of paper',
    },
  },
  h1: {
    de: 'Love is in the air! Bastel einen Heißluftballon aus Papier als originelles Geldgeschenk zur Hochzeit ',
    en: 'Love is in the air! Make a papercraft hot air balloon as an original wedding gift',
  },
  description: {
    de: `<p> Love is in the air! Möchtest du ein einzigartiges und originelles Geldgeschenk zur Hochzeit basteln? </p>
 <p> Viele von euch kennen die DIY-Idee einen Heißluftballon mit der Hängeleuchte von Ikea zu realiseren. Aber wie wäre es mit einem selbstgemachten Heißluftballon aus Papier?  </p>
 <p>Dieses kreative DIY-Projekt ist nicht nur einfach, sondern auch ein wahrer Blickfang auf jeder Hochzeitsfeier. 
 Der Heißluftballon dekoriert nicht nur den Hochzeitstisch, sondern ist auch gleich eine originelle Geldverpackung. </p>

<p>Du brauchst nur wenige Materialien wie Tonkarton, Kleber und eine Schere um deinen ganz eigenen Heißluftballon gestalten zu können. 
Das Beste daran ist, dass du deiner Kreativität freien Lauf lassen kannst und den Ballon ganz nach deinem eigenen Geschmack anpassen kannst. </p>

<p>Ob du dich für romantische Farben oder bunte Verzierungen entscheidest, bleibt ganz dir überlassen.  </p>`,
    en: `
     



    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },

  stepsTitle: {
    de: 'Anleitung: Heißluftballon als Geldgeschenk basteln',
    en: '',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'heissluftballon-vorlage-ausschneiden.jpg',
          en: '',
        },
        alt_text: {
          de: 'Vorlage ausschneiden',
          en: '',
        },
      },

      text: {
        de: `Druckt eure Vorlage auf einem Drucker aus. Ihr benötigt 7 Blatt DINA4 Tonkarton in euren Wunschfarben. Verwendet am besten Tonkarton mit einer Grammatur von bis zu 230g/m². 
        Möchtet ihr euren Heißluftballon zweifarbig gestalten, so achtet auf die Beschriftung "Farbe 1" und "Farbe 2" in der Vorlage. 
           Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer Schere aus.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'heissluftballon-papierteile-falten.jpg',
          en: '',
        },
        alt_text: {
          de: 'Papierteile vom Ballon falten',
          en: '',
        },
      },

      text: {
        de: `Falzt mit einer Ahle und einem Lineal alle gestrichelten Linien (egal ob lang oder kurz gestrichelt) vor. Dazu das Lineal an die Linie anlegen und mit der Ahle mit Druck
        nachfahren.Faltet die lang gestrichelten Linien von euch weg, d.h. nach unten. Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'heissluftballon-kleben.jpg',
          en: '',
        },
        alt_text: {
          de: 'Papierteile verkleben',
          en: '',
        },
      },

      text: {
        de: `Klebt auf die Laschen der einzelnen Papierteile Klebepads. Wichtig ist, dass ihr das Pad auf die schöne Seite und damit die unbedruckte Seite der Lasche klebt. 
        Ihr beginnt in nummerischer Reihenfolge anschließend die einzelnen Teile zu verkleben, d.h. ihr startet bei 1 auf Teil A und verklebt diese. Danach verklebt ihr Nr. 2 und so weiter. Eine Zahl existiert immer doppelt, sodass die Zahlenpaare
        immer verklebt werden müssen.
  `,
        en: `


`,
      },
    },

    {
      image: {
        filename: {
          de: 'baender-ankleben.jpg',
          en: '',
        },
        alt_text: {
          de: 'Schnüre vom Heißluftballon werden angeklebt',
          en: '',
        },
      },

      text: {
        de: `Nachdem ihr den Papercraft Heißluftballon nach der Vorlage fertig gebastelt habt, kommen wir nun zur Verzierung. In der Vorlage findet ihr 4x das Teil Q. Das sind die Schnüre des Heißluftballons. Diese habe ich außen auf den Korb drangeklebt. Ich fand den farblichen Kontrast ganz schön, wenn ihr zwei Farben verwendet. Wer das nicht mag, kann die Papierschnur gerne auch in den Korb kleben. Für das Verkleben würde ich einen Klebestift empfehlen und weniger die Klebepads.
  `,
        en: `


`,
      },
    },

    {
      image: {
        filename: {
          de: 'schnur-ankleben.jpg',
          en: '',
        },
        alt_text: {
          de: 'Schnur ankleben',
          en: '',
        },
      },

      text: {
        de: `Nachdem ihr das eine Ende der Schnur am Korb festgeklebt habt, klebt ihr das andere Ende im 4. Rechteck vom Ballon fest. Zum besseren Verständnis, schaut euch das Bild oben an. Hier würde ich einen Klecks Sekundenkleber verwenden. Anschließend klebt ihr ein Herz auf die Schnur, um mögliche Klebeflecken zu überdecken. Das seht ihr auf dem nächsten Bild.
  `,
        en: `
  `,
      },
    },

    {
      image: {
        filename: {
          de: 'herz-just-married-logo.jpg',
          en: '',
        },
        alt_text: {
          de: 'Just Married Wolke aufkleben',
          en: '',
        },
      },

      text: {
        de: `In der Vorlage findet ihr eine Wolke mit der Aufschrift "Just Married". Diese könnt ihr auf eines der Schnüre mit einem Klebestift fixieren.
  `,
        en: `
  `,
      },
    },

    {
      text: {
        de: `Zum Schluss befestigt ihr die Wimpelkette (2 Stück - Teile R) zwischen jeweils zwei Schnüren. Hier dürft ihr wählen, welche Schnüre ihr verwendet und ob ihr sie eher gerade oder schräg an den Papierschnüren anklebt. Je nachdem, wie ihr sie anklebt und wo, kann es sein, dass ihr die Wimpelkette kürzen müsst.
        
  `,
        en: ` 
  `,
      },
    },

    {
      image: {
        filename: {
          de: 'geldscheine-falten.jpg',
          en: '',
        },
        alt_text: {
          de: 'Geldscheine falten für das Geldgeschenk',
          en: '',
        },
      },
      text: {
        de: `Faltet nun eure Geldscheine zu kleinen Rechtecken. Diese sind die Sandsäcke zum Beschweren des Heißluftballons. In der Vorlage findet ihr die Teile S. Es sind drei dünne Papierstreifen und ein Herz pro Geldschein vorgesehen. Papierstreifen 1 und 2 sind die "Geschenkbänder" für den Geldschein. Diese könnt ihr euch so zuschneiden, wie ihr es benötigt. Verklebt die Schnur und klebt am Ende ein Herz in die Mitte.
        
        Wem es hier mit Papier zu funzelig wird, der steigt einfach auf farblich passende Gummibänder oder Geschenkbänder um.
  `,
        en: `


`,
      },
    },

    {
      text: {
        de: `Die Geldscheine hängt ihr nun mit dem Papierstreifen Nr. 3 von den Teilen S auf. Dazu habe ich den Papierstreifen in der Mitte gefaltet und die eine Hälfte durch das "Geschenkband" des Geldscheins durchgezogen. Nun hängt der Geldschein in der Mitte des gefalteten Papierstreifens Nr. 3. Anschließend habe ich den oberen Teil des Papierstreifens erneut umgeknickt und im Inneren des Korbs verklebt. Auch hier könnt ihr anstelle des Papiers gerne Geschenkbänder oder dünne Juteseile verwenden. 
     
     In den Korb selbst könnt ihr nun eine Mini-Karte reinlegen, saftige Kleeblätter befüllen oder mit Papierblumen und Konfetti dekorieren.
Ich wünsche euch ganz viel Freude beim Verschenken.
`,
        en: `


`,
      },
    },
  ],

  materials: [
    {
      de: '7 DINA4 Papier Tonkarton (230g/m²)',
      en: '7 colored construction paper (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    ],
  },
};
