export default {
  id: '1-08-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Panda',
    en: 'Panda',
  },
  subtitle: {
    de: 'B29 x H25 x T28 cm',
    en: 'W29 x H25 x D28 cm',
  },
  meta_title: {
    de: 'Panda Papiertier',
    en: 'Panda Animal',
  },
  meta_description_google: {
    de: 'Cooler 3d Panda aus Papier zum basteln mit Vorlage von PaperShape',
    en: 'Lovely 3d panda from paper with template from PaperShape ',
  },
  meta_description_social: {
    de: '3d Panda aus Papier zum basteln - diy Papierskulptur #papershape',
    en: '3d panda from paper - diy paper sculpture #papershape',
  },
  description: {
    de: `     KOMPLETTES SET BEINHALTET
    
    8 DIN A4 Seiten Fotokarton
    Doppelseitige Klebepads
    Detaillierte Anweisungen schrittweise

    DETAILS: Altersempfehlung ab 14 Jahren
    
    BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser`,

    en: `DETAILS: Age recommendation 14+
    
    COMPLETE KIT INCLUDES
    
    8 DIN A4 cardboard sheets
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife
    
    STEP BY STEP
    
    Cut the paper parts.
    To fold the paper parts, you need to pre-fold them first with a ruler and a bread knife or an awl. Set the ruler to the dashed line and press with the butter knife along. Then fold the parts as specified in the instructions: either outwardly or inwardly.
    Now glue all the paper parts of the 3D origami together. Use the pads, which are included in the set. The folding takes place in numerical order in 1, 2, 3, etc. Set the adhesive pads on the outer sides of the tabs and keep a safe distance of about 2mm. When gluing necessarily watch the model from the outside.`,
  },

  choose: {
    image: {
      filename: {
        de: 'products/sfkpanda/panda-papier-basteln-diy-wanddeko-kinder.jpg',
        en: 'products/sfkpanda/panda-papier-basteln-diy-wanddeko-kinder.jpg',
      },
      alt_text: {
        de: '3d Panda aus Papier zum basteln - diy Papierskulptur #papershape',
        en: '3d panda from paper - diy paper sculpture #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Kopf',
        en: 'Head',
      },
      color2: {
        de: 'Augen & Ohren',
        en: 'Eyes & ears',
      },
    },
    disableColors: {
      color1: ['schwarz'],
    },

    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
