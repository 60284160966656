export default {
  id: '2-04-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Chamäleon',
    en: 'Chameleon',
  },
  subtitle: {
    de: 'B46 x H17 x T11 cm',
    en: 'W46 x H17 x D11 cm',
  },
  meta_title: {
    de: 'Chamäleon Papiertier',
    en: 'Chameleon Animal',
  },
  meta_description_google: {
    de: 'Das 3d Chamäleon aus Papier verzaubert als Deko jeden Raum. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The 3d chameleon from paper enchants each room with lots of space for creativity. 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: 'Das 3d Chamäleon aus Papier verzaubert als Deko jeden Raum. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The 3d chameleon from paper enchants each room with lots of space for creativity. 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  description: {
    de: `   KOMPLETTES SET BEINHALTET
    
    6 DIN A4 Seiten Fotokarton
    Doppelseitige Klebepads
    Detaillierte Anweisungen schrittweise

    DETAILS: Altersempfehlung ab 14 Jahren
    
    BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser`,

    en: `    Set contains:
    6 DIN A4 photo cardboard origami chameleon
    double-sided tape
    detailed instructions

    Informations: age recommendation 14+
    
    required materials (not included):
    scissors
    ruler
    butter knife`,
  },

  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 9 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 6 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m², max. 230g/m²)
- Drucker 
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 6 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 6 DIN A4 or US letter colored paper (min. 160g/m², max. 230g/m²)
- printer
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkchamaeleon/chamaeleon.jpg',
        en: 'products/sfkchamaeleon/chamaeleon.jpg',
      },
      alt_text: {
        de: 'DIY 3d Chamäleon aus Papier #bastelnmitpapier #papercraft #3dpapermodel #origami',
        en: '3d chameleon paper sculpture DIY #papershape #papercraft #diypaper',
      },
    },
    price: 9.9,
    priceThirdCountry: 9.9,
  },

  colorinspirations: [
    {
      caption: {
        de: 'olivgrün - Farbe vergriffen',
        en: 'grasgrün',
      },
      image: {
        filename: {
          de: 'products/sfkchamaeleon/chamaeleon.jpg',
          en: 'products/sfkchamaeleon/chamaeleon.jpg',
        },
        alt_text: {
          de: '3d Chamäleon aus Papier zum Basteln. Schönes Papiertier / Papierskulptur als Deko für das eigene Reservoir #papershape',
          en: '3d chameleon paper sculpture DIY #papershape ',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkchamaeleon/chamaeleon.jpg',
        en: 'products/sfkchamaeleon/chamaeleon.jpg',
      },
      alt_text: {
        de: '3d Chamäleon aus Papier zum Basteln. Schönes Papiertier / Papierskulptur als Deko für das eigene Reservoir #papershape',
        en: '3d chameleon paper sculpture DIY #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Chamäleon',
        en: 'Chameleon',
      },
    },
    disableColors: {
      color1: ['schwarz', 'tuerkis', 'anthrazit'],
    },

    price: 19.9,
    priceThirdCountry: 16.9,
  },
};
