import { reducer as formReducer } from 'redux-form';
import { contactFormName } from './contact/contactFormSelectors';
import { NEWSLETTER_SIGNIN_FORM_SEND_SUCCESSFUL } from './newsletter/newsletterFormActions';
import { newsletterFormName } from './newsletter/newsletterFormSelectors';
import { CONTACT_FORM_SEND_SUCCESSFUL } from './contact/contactFormActions';

export function customFormReducer() {
  return formReducer.plugin({
    [contactFormName]: (state, action) => {
      switch (action.type) {
        case CONTACT_FORM_SEND_SUCCESSFUL:
          return undefined;
        default:
          return state;
      }
    },
    [newsletterFormName]: (state, action) => {
      switch (action.type) {
        case NEWSLETTER_SIGNIN_FORM_SEND_SUCCESSFUL:
          return undefined;
        default:
          return state;
      }
    },
  });
}
