import vase from './blog/vase';
import osterei from './blog/osterei';
import osterhase from './blog/osterhase';
import wmPokal from './blog/wm-pokal';
import ostereihase from './blog/ostereihase';
import schmetterling from './blog/schmetterling';
import osterkarte from './blog/osterkarte';
import tangram from './blog/tangram';
import bilderrahmen from './blog/bilderrahmen';
import libelle from './blog/libelle';
import loopWeihnachtskranz from './blog/loopweihnachtskranz';
import pumpkin from './blog/pumpkin';
import nikolauskracker from './blog/nikolauskracker';
import herzbox from './blog/herzbox';
import lebkuchen from './blog/lebkuchen';
// import gastgeschenkweltkugel from './blog/gastgeschenkweltkugel';
import eicheln from './blog/eicheln';
import herbstkranz from './blog/herbstkranz';
import adventskalenderWaben from './blog/adventskalender-waben';
import adventskalenderBillard from './blog/adventskalender-billard';
import ahornblatt from './blog/ahornblatt';
import apfel from './blog/apfel';
import candywrapperbag from './blog/candywrapperbag';
import panther from './blog/panther';
import bascettastern from './blog/bascettastern';
import adventskalenderNoppenfolie from './blog/adventskalender-noppenfolie';
import tieresticken from './blog/tieresticken';
import papierring from './blog/papierring';
import adventskalenderSchaf from './blog/adventskalender-schaf';
import schneeflocke from './blog/schneeflocke';
import uhrSchallplatte from './blog/uhr-schallplatte';
import loveBuchstaben from './blog/love-buchstaben';
import tulpen from './blog/tulpen';
import osternestDonut from './blog/osternest-donut';
import schmetterlingTonpapier from './blog/schmetterling-tonpapier';
import geschenktueteRegenbogen from './blog/geschenktuete-regenbogen';
import hyazinthen from './blog/hyazinthen';
import gaensebluemchen from './blog/gaensebluemchen';
import rose from './blog/rose';
import windlichtherz from './blog/windlichtherz';
import geschenkbox from './blog/geschenkbox';
import zitrone from './blog/zitrone';
import retrobushochzeit from './blog/retrobushochzeit';
import augustchallenge from './blog/augustchallenge';
import geschenkboxPraline from './blog/geschenkbox-praline';
import tannenbaum from './blog/tannenbaum';
import eichel2 from './blog/eichel2';
import diamant from './blog/diamant';
import babyflasche from './blog/babyflasche';
import gerbera from './blog/gerbera';
import pokal from './blog/pokal';
import baustelle from './blog/baustelle';
import schiff from './blog/schiff';
import avocadoeierhalter from './blog/avocadoeierhalter';
import osterkorb from './blog/osterkorb';
import ackermannkalender from './blog/ackermannkalender';
import heissluftballonhochzeit from './blog/heissluftballonhochzeit';

export default [
  heissluftballonhochzeit,
  ackermannkalender,

  bascettastern,
  schneeflocke,
  adventskalenderBillard,
  nikolauskracker,
  loopWeihnachtskranz,
  adventskalenderNoppenfolie,
  lebkuchen,
  adventskalenderSchaf,
  adventskalenderWaben,

  osterhase,
  gaensebluemchen,
  tulpen,
  gerbera,

  rose,
  schmetterling,
  schmetterlingTonpapier,
  libelle,
  loveBuchstaben,
  windlichtherz,
  vase,
  retrobushochzeit,

  osterei,
  ostereihase,
  avocadoeierhalter,
  osterkorb,
  herzbox,

  pokal,

  geschenkboxPraline,
  osternestDonut,
  schiff,
  baustelle,
  babyflasche,
  zitrone,
  geschenkbox,
  herbstkranz,

  hyazinthen,
  geschenktueteRegenbogen,
  ahornblatt,
  apfel,
  pumpkin,
  eicheln,
  eichel2,

  diamant,
  augustchallenge,
  tieresticken,
  bilderrahmen,
  wmPokal,
  osterkarte,
  tangram,
  papierring,
  uhrSchallplatte,
  panther,
  candywrapperbag,
  tannenbaum,

  //gastgeschenkweltkugel,
];
