import { selectedLang } from '../../data/lang';

export const CONTACT_FORM_SEND_SUCCESSFUL = 'CONTACT_FORM_SEND_SUCCESSFUL';

export function contactFormSendSuccessful() {
  return {
    type: CONTACT_FORM_SEND_SUCCESSFUL,
  };
}

export function sendContactForm(formValues) {
  return (dispatch) => {
    console.info('submitted:, ', formValues);

    return dispatch({
      fetch: {
        body: {
          type: 'SendContactFormular',
          payload: {
            message: formValues.message,
            email: formValues.email,
            language: selectedLang,
          },
        },
      },
    }).then(() => {
      dispatch(contactFormSendSuccessful());
    });
  };
}
