import materials from './materials';

export default {
  url: 'diy-vase',
  countermark: 'vg07.met.vgwort.de/na/ec78b8079e0149e19e5779bb977bbc58',
  imageFolder: 'vase',
  landingPage: ['diy-geschenke'],

  name: {
    de: 'DIY Vase',
    en: 'DIY Vase',
  },
  id: '3-03-blog',
  pdf: {
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'content/vase/diy-vase-papier-deko-idee-selber-machen.jpg',
        en: 'content/vase/how-to-make-flower-paper-vase-easy-step-by-step.jpg',
      },
      alt_text: {
        de: 'DIY Vase basteln aus Papier #bastelnmitpapier #papercraft #diy',
        en: 'Learn how to make a paper vase #papercraft #diy #papervase',
      },
    },
  },

  meta_title: {
    de: 'DIY Vase aus Papier im Origami-Look [Vorlage]',
    en: 'How To Make A Paper Flower Vase Sleeve (Very Easy with Template)',
  },
  meta_description_google: {
    de: 'Falte eine moderne DIY Vase aus Papier im Origami-Design. Mit der PaperShape Vorlage bastelst du ganz einfach in nur 3 Schritten eine geometrische Vase.',
    en: 'This diy paper flower vase tutorial is very easy to make and comes with a template. You can slip the vase sleeve over a glass for a fancy look.',
  },
  meta_description_social: {
    de: 'Falte eine moderne DIY Vase aus Papier im Origami-Design. Mit der kostenlosen PaperShape Vorlage bastelst du ganz einfach in nur 3 Schritten eine geometrische Vase.',
    en: 'This diy paper flower vase tutorial is very easy to make and comes with a template. You can slip the vase sleeve over a glass for a fancy look.',
  },
  meta_images_social: [
    {
      de: '3d-origami-vase.jpg',
      en: '3d-vase-paper-craft.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'diy-vase-papier-deko-idee-selber-machen.jpg',
      en: 'how-to-make-flower-paper-vase-easy-step-by-step.jpg',
    },
    alt_text: {
      de: 'DIY Papier Vase im coolen Origami-Design mit kostenloser Vorlage. Ihr müsste einfach die Papiere schneiden, falten, kleben. Finde die Farben mega - lavendel, rose, weinrot. PaperShape #vases #papercraft #diy',
      en: 'DIY Paper Flower Vase Sleeve with template. It looks so lovely and is pretty easy to make (with pictures and how to video) / PaperShape #vases #paper #diy',
    },
  },
  h1: {
    de: 'DIY Vase aus Papier (Vorlage)',
    en: 'How to make a paper flower vase (step by step with template)',
  },
  description: {
    de: `
      
      <p> Ihr fragt euch gerade bestimmt: </p>

        <p>Wie lassen sich in einer DIY Vase aus Papier auch echte Blumen halten? 
        Yep! Die Papiervase wird ganz einfach über ein Wasserglas gestülpt und 
        anschließend mit deinen Lieblingsblumen bestückt.
        </p>
        <p>Der Frühling steht in den Startlöchern und es wird endlich Zeit für duftende Wohnideen. Neben der 
        selbstgemachten Vase könnt ihr weitere DIY Frühlingsideen nachbasteln wie z.B.  
        </p>
        <p>
        <li><a href="/de/blog/libelle-basteln-papier/">3D Libellen aus Papier</a></li>
        <li><a href="/de/blog/3d-papier-osterhasen-basteln/">3D Osterhase aus Papier</a></li>
      </p>
      
      Die Größe der Vase: 
        <p>25cm hoch / 15cm an der breitesten Stelle /9,5cm Durchmesser der Öffnung (unten) / 5cm Durchmesser der Öffnung (oben)</p>

    `,
    en: `
      <p>
        Spring is coming and it's time for homemade modern flower vase ideas.
        If you like geometric and straight lines 
        you will absolutely love this paper craft project.
      </p>
      <p>
        It looks like an origami vase though it
        is not one piece of paper. It is easy to make 
        and you will love the result. 
      </p>
      <p>
        Size: 25cm (9.8 inch) high / 15cm (5.9 inch) at the widest point / 9.5cm (3.7 inch) 
        diameter of the bottom opening / 5cm (1.9 inch) diameter of the top opening
      </p>
    `,
  },
  inspirationTitle: {
    de: 'Wie du in 3 einfachen Schritten deine DIY Vase aus Papier für echte Blumen selber machst',
    en: 'How to make a homemade paper vase sleeve',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'diy-papier-vase-cover-basteln.jpg',
          en: 'how-to-make-paper-flower-vase.jpg',
        },
        alt_text: {
          de: 'DIY Vase aus Papier einfach selber machen und über ein Glas als Cover stülpen. PaperShape #vases #papercraft #diy',
          en: 'DIY Paper Flower Vase Sleeve with template. The origami design looks so lovely and it is so easy to make (with pictures and how to video) / PaperShape #vases #paper #diy',
        },
      },
    },
    {
      text: {
        de: 'Ich liebe diese Farbkombi und kann mir das Blumen-Arrangement total gut als Tischedeko für eine Hochzeit im Frühling vorstellen. Warum auch nicht die Tischnummer auf die Vase kleben oder mit Handlettering verzieren.',
        en: 'I love this color combination and I wondered if the flower-arrangement may be a lovely table deco at a wedding in spring. You could also add the table number on the vase.',
      },
    },
    {
      image: {
        filename: {
          de: 'blumenvase-origami-falten.jpg',
          en: 'diy-paper-vase.jpg',
        },
        alt_text: {
          de: 'DIY Papier Vase im puristischen Origami-Look. Kann ich mir total gut als Geschenk zum Muttertag vorstellen. Die Vorlage kann man herunterladen und die Anleitung ist super einfach. / PaperShape #muttertag #papercraft #papier',
          en: 'DIY Paper Flower Vase Sleeve with template. The origami design looks so lovely and it is so easy to make (with pictures and how to video) / PaperShape #vases #paper #diyhomedecor',
        },
      },
    },
    {
      text: {
        de: `Zugegeben, rot ist knallig - aber wie cool wäre diese Vase als Geschenk zum Muttertag mit selbst gepflückten Blumen aus dem Garten.`,
        en: `I agree: red is a vibrant color - but how cool would that diy paper vase be as a present for Mother's Day?`,
      },
    },
    {
      image: {
        filename: {
          de: 'origami-vase-papier.jpg',
          en: 'origami-vase-paper.jpg',
        },
        alt_text: {
          de: 'DIY Papier Vase im puristischen Origami-Look. Kann ich mir total gut als Geschenk zum Muttertag vorstellen. Die Vorlage kann man herunterladen und die Anleitung ist super einfach. / PaperShape #muttertag #papercraft #papier',
          en: 'DIY Paper Flower Vase Sleeve with template. The origami design looks so lovely and it is so easy to make (with pictures and how to video) / PaperShape #vases #paper #diyhomedecor',
        },
      },
    },
  ],

  stepsTitle: {
    de: 'Anleitung: 3 einfache Schritte zur DIY Blumenvase im Origamidesign',
    en: 'Paper Craft instructions: easy 3d paper vase in just 3 steps',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'anleitungsschritt-vase-vorlage.jpg',
          en: 'anleitungsschritt-vase-vorlage.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Vase Vorlage herunterladen',
          en: 'Step: Download vase template',
        },
      },
      text: {
        de: `Druckt die PDF-Vorlage für die Papiervase auf DIN A4
                      Tonkarton (bis zu 230g sollte jeder Drucker eigentlich
                      problemlose drucken) in eurer Wunschfarbe aus. Ich habe bei den
                      drei Blumen Lavendel, Weinrot und Authentic Rose verwendet.`,
        en: `Print the vase template (US letter or DIN A4) on paper of your choice. I used the colors lavender, ruby and authentic rose. 
                  Each printer should easily print cardstock up to 230gsm (80lb).`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-schneiden.jpg',
          en: 'anleitungsschritt-schneiden.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ausschneiden',
          en: 'Step: Cutting',
        },
      },
      text: {
        de: `Schneide mit einer Schere oder einem Cutter alle Elemente entlang der durchgezogenen Linie aus.`,
        en: `Cut out the paper parts (A, B, C, ...) by cutting along the solid lines.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-vorfalzen.jpg',
          en: 'anleitungsschritt-vorfalzen.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Falzen',
          en: 'Step: Folding',
        },
      },
      text: {
        de: `Unabhängig von der Papierstärke empfehle ich die Falzlinien
                      mit Druck nachzufahren, bevor ihr das Papier faltet. So
                      entstehen schöne Knicke und das Papier bricht nicht. Legt dazu
                      ein Lineal an alle gestrichelten Linien an und fahrt mit einem
                      stumpfen dünnen Gegenstand die Linien mit Druck ab.`,
        en: `Place a ruler on the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines. 
                    Don't skip this step - no matter what paper you use. The result will look so much better.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-knicken.jpg',
          en: 'anleitungsschritt-knicken.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Knicken',
          en: 'Step: folding paper parts',
        },
      },
      text: {
        de: `Legt das Papierteil A so vor euch hin, dass ihr die Zahlen und
              Buchstaben seht. Knickt die kurz gestrichelten Linien nach innen,
              d.h. zu euch hin (Talfalte). Faltet alle lang gestrichelten Linien
              nach außen, d.h. von euch weg (Bergfalte).`,
        en: `Start with the paper piece A and place it in front of you. 
              The printed side should be facing you. Subsequently fold all the dotted lines 
              either inwards, which means to fold up (short dotted lines) or outwards, 
              which means to fold down (long dotted lines), according to the markings.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-kleben.jpg',
          en: 'anleitungsschritt-kleben.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together',
        },
      },
      text: {
        de: `Bringt auf die Laschen Kleber auf und verklebt jeweils die
              passenden Zahlenpaare. Ich verwende gerne Klebepads. Beginnt mit
              der Nr. 1 auf A und B und bastelt in nummerischer Reihenfolge
              (2,3,4, etc.) weiter.`,
        en: `Use double-sided tape or any glue onto the unprinted side of 
              the tabs.Each number exists twice. Stick all the same numbers together 
              in ascending order (1, 2, 3, ...). Start with section 1 and stick it to
              the section marked 1 on paper A, pressing firmly enough to make them stick together.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-vase-oeffnung.jpg',
          en: 'anleitungsschritt-vase-oeffnung.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Vasenöffnung',
          en: 'Step: Vase opening ',
        },
      },
      text: {
        de: `Nach dem Verkleben aller Laschen ist eure Vase im Origami-Stil
              fertig und ihr könnt ein Standard Ikea Glas (Pokal Glas) oder eine
              Flasche (Wein- oder Bierflaschen) recyceln und unter das Cover
              stellen. TIPP: Bei den Papierteilen A-H müssen die Laschen, die
              die obere Kante bilden, komplett umgeknickt werden. So entsteht
              innen der Ring, der die Klebepads oder unschöne Klebeflecken
              abdeckt. Schaut euch am besten das Video dazu an Auf Fotos ist es schwierig zu sehen.`,
        en: `Finally just put a standard Ikea drinking glas beneath the paper vase. Tip: Watch the video to learn how the paper parts A-H have
                to be folded. It is not difficult but pictures won't show that step. Enjoy decorating.`,
      },
    },
  ],
  video: {
    title: {
      de: 'Verpass nicht das Video. Ich zeige dir, wie du lückenlose Übergänge schaffst.',
      en: "Don't miss the video and learn how to create constant transitions.",
    },
    description: {
      de: `Ihr erfahrt, wie ihr die Vase ganz ohne sichtbare Lücken
            verklebt, auch wenn ihr nicht ganz präzise geschnitten habt
            (Pro-Tipp #1). + Ihr lernt, wie ihr die Öffnung der Blumenvase
            beim ersten Mal richtig knickt und klebt (Tipp #2).`,
      en: `You will learn how to craft the paper vase with constant transitions 
          (tipp #1) and how to fold the top of the vase correctly (tip #2).`,
    },
    caption: {
      de: `DIY Papier Vase Cover`,
      en: `How to make a paper flower vase`,
    },
    videoid: '5Fn3fNBBXyY',
  },
  downloadName: 'diy-paper-vase-papershape.zip',
  materials: [
    materials.paper(4),
    materials.glue,
    materials.scissors,
    materials.ruler,
    materials.awl,
    materials.mat,
    {
      type: 'tool',
      name: {
        de: 'Wasserglas (z.B. Ikea Pokal)',
        en: 'drinking glass (e.g. Ikea Pokal)',
      },
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert euch diese DIY Idee auf Pinterest!',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'diy-vase-papier-3d-origami-deko.jpg',
          en: 'how-to-make-flower-paper-vase-easy.jpg',
        },
        alt_text: {
          de: 'DIY Papier Vase im coolen Origami-Design mit Vorlage. Ihr müsste einfach die Papiere schneiden, falten, kleben. Finde die Farben mega - lavendel, rose, weinrot. PaperShape #vases #papercraft #diy',
          en: 'DIY Paper Flower Vase Sleeve with template. It looks so lovely and is pretty easy to make (with pictures and how to video) / PaperShape #vases #paper #diyhomedecor',
        },
      },
      {
        filename: {
          de: 'einfache-diy-blumen-papier-vase-anleitung.jpg',
          en: '3d-origami-vase.jpg',
        },
        alt_text: {
          de: 'DIY Vase aus Papier basteln mit Faltanleitung und Vorlage zum Herunterladen / PaperShape #vases #papercraft #diy',
          en: 'DIY Paper Flower Vase Sleeve with template. It looks so lovely and is pretty easy to make (with pictures and how to video) / PaperShape #vases #paper #diy',
        },
      },
    ],
  },
};
