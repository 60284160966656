import React from 'react';
import { Route } from 'react-router-dom';
import { getTextBySelectedLang } from '../../data/lang';
import Textde from './de';
import Texten from './en';
import PsMeta from '../../common/PsMeta';

export default () => (
  <div>
    <PsMeta
      title={getTextBySelectedLang(
        'Allgemeine Geschäftsbedingungen',
        'Terms & Conditions'
      )}
      noindex
    />

    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1 className="page-header">
            {getTextBySelectedLang(
              'Allgemeine Geschäftsbedingungen',
              'Terms & Conditions'
            )}
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Route path="/de" component={Textde} />
          <Route path="/en" component={Texten} />
        </div>
      </div>
    </div>
  </div>
);
