export default {
  id: '3-19-blog',
  category: ['paper_flowers'],
  name: {
    de: 'Gänseblümchen',
    en: 'Daisy',
  },
  subtitle: {
    de: 'B11 x H11 x T1 cm',
    en: 'W11 x H11 x D1 cm',
  },
  meta_title: {
    de: 'Gänseblümchen aus Papier Vorlage ',
    en: 'Daisy from Paper Template ',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für frühlingshafte Gänseblümchen kannst du etliche kreative Ideen umsetzen. Wie wäre es mit diesen 3 Gänseblümchen-Ideen für den Frühling?',
    en: 'With this template for bright daisies you can realize quite a few creative ideas. How about a wonderful bouquet of daisies for your home?',
  },
  meta_description_social: {
    de: 'Mit dieser Vorlage für knallige Gänseblümchen kannst du etliche kreative Ideen umsetzen. Wie wäre es mit einem wundervollen Blumenstrauß für dein Zuhause?',
    en: 'With this template for bright daisies you can realize quite a few creative ideas. How about a wonderful bouquet of daisies for your home?',
  },

  pdf: {
    description: {
      de: `Mit dieser digitalen Vorlage für frühlingshafte Gänseblümchen kannst du etliche kreative Ideen umsetzen. <a href="/de/blog/gaensebluemchen-basteln/">In diesem Blogpost</a> zeige ich dir, wie einfach das geht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² Tonpapier zu verwenden.
      Damit die Gänseblümchen gelingen, benötigst du einen Drucker, 3 Bögen Tonpapier, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this digital template for our spring daisies you can realize a lot of creative ideas. <a href="/en/blog/gaensebluemchen-basteln/">In this blogpost</a> I show you how easy it is.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use 130g/m² paper.
      To make the daisies succeed, you'll need a printer, 3 sheets of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'products/sfkgaensebluemchen/gaensebluemchen.jpg',
        en: 'products/sfkgaensebluemchen/gaensebluemchen.jpg',
      },
      alt_text: {
        de: 'Vier selbst gebastelte Gänseblümchen aus Papier auf gelbem Hintergrund',
        en: 'Four papercrafted daisies on a yellow background',
      },
    },
  },
};
