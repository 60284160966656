import React from 'react';
import colors from '../../data/colors';
import { selectedLang } from '../../data/lang';

export default ({
  input: { onChange, name, value },
  className,
  disableColors,
}) => {
  return (
    <select
      name={name}
      onChange={onChange}
      value={value}
      className={className}
      style={{
        background: colors[value] && colors[value].color,
        color:
          ['perlweiss', 'weiss', 'bunt'].indexOf(value) === -1
            ? 'white'
            : 'black',
      }}>
      {Object.keys(colors)
        .filter((key) => !disableColors || disableColors.indexOf(key) === -1)
        .map((key) => (
          <option value={key} key={key}>
            {colors[key].description[selectedLang]}
          </option>
        ))}
    </select>
  );
};
