import React, { Component } from 'react';
import { connect } from 'react-redux';
import Lang from '../../common/Lang';
import OrderNumber from '../../common/OrderNumber';
import OrderSumGross from '../../common/OrderSumGross';
import { getTextBySelectedLang } from '../../data/lang';
import PsBankAccount from '../../common/PsBankAccount';
import PsMeta from '../../common/PsMeta';

class PaymentPrePayment extends Component {
  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang('Vorauskasse', 'Prepayment')}
          noindex
        />

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-header">
                {<Lang de="Vorauskasse" en="Prepayment" />}
              </h1>
            </div>
          </div>
        </div>

        <div className="container">
          <p style={{ marginTop: 20 }}>
            <Lang
              de={
                <span>
                  Bitte überweise den Betrag von <OrderSumGross /> unter Angabe
                  der Bestellnummer <OrderNumber /> auf folgendes Konto:
                </span>
              }
              en={
                <span>
                  Please transfer the amount of <OrderSumGross /> specifying the
                  order number <OrderNumber /> to the following account:
                </span>
              }
            />
          </p>

          <PsBankAccount />

          <p style={{ marginTop: 20 }}>
            <Lang
              de={
                <span>
                  Bitte beachte, dass die Ware nach Eingang der Zahlung
                  verschickt wird.
                </span>
              }
              en={
                <span>
                  Please note that the goods will be shipped after your payment.
                </span>
              }
            />
          </p>
        </div>
      </div>
    );
  }
}

export default connect()(PaymentPrePayment);
