import React from 'react';
import { connect } from 'react-redux';
import { getOrderNumber } from '../state/order/orderSelectors';

function OrderNumber({ orderNumber }) {
  return <span>{orderNumber}</span>;
}

const mapStateToProps = (state) => {
  return {
    orderNumber: getOrderNumber(state),
  };
};

export default connect(mapStateToProps)(OrderNumber);
