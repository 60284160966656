import React from 'react';
import { connect } from 'react-redux';
import OrderNumber from './OrderNumber';
import OrderSumGross from './OrderSumGross';
import Lang from './Lang';

function PsBankTransferHint() {
  return (
    <p style={{ marginTop: 20 }}>
      <Lang
        de={
          <span>
            Du kannst den Betrag von <OrderSumGross /> unter Angabe der
            Bestellnummer <OrderNumber /> alternativ auch auf folgendes Konto
            überweisen:
          </span>
        }
        en={
          <span>
            You can alternatively transfer the amount of <OrderSumGross />{' '}
            specifying the order number <OrderNumber /> to the following
            account:
          </span>
        }
      />
    </p>
  );
}

export default connect()(PsBankTransferHint);
