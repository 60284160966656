export default {
  url: '3d-papier-osterhasen-basteln',
  countermark: 'vg07.met.vgwort.de/na/2de086b8f791489f9a836efaa3cdd408',
  imageFolder: 'osterhase',
  landingPage: ['diy-geschenke'],

  name: {
    de: 'Osterhase',
    en: 'Easter Bunny',
  },
  id: '3-01-blog',
  pdf: {
    price: 9.9,
    priceThirdCountry: 9.9,
    image: {
      filename: {
        de: 'content/osterhase/osterhase-basteln-aus-papier-vorlage.jpg',
        en: 'content/osterhase/3d-paper-bunny-rabbit-diy-easter.jpg',
      },
      alt_text: {
        de:
          'Osterhasen basteln mit Papier und Vorlage als coole Osterdeko / PaperShape #osterhase #osterdeko #papercraft #osterhase #diy',
        en:
          'diy paper bunny with template. the rabbit looks adorable and you can hide small Easter gifts in its belly (with pictures and how to video) / PaperShape #easterbunny #easter #papercraft #diy',
      },
    },
  },

  meta_title: {
    de: 'Osterhasen basteln: in 3D aus Papier',
    en: 'How to make 3D paper bunny craft (template)',
  },
  meta_description_google: {
    de:
      'Falte einen DIY Osterhasen aus Papier im Origami-Look als coole Dekoration. Mit der PaperShape Vorlage bastelst du das Häschen in deinen Wunschfarben.',
    en:
      'This diy paper rabbit is the perfect Easter decoration and comes with a template. Choose your favorite paper color and head to PaperShape to download the template.',
  },
  meta_description_social: {
    de:
      'Osterhasen basteln aus Papier ist mit der Vorlage von PaperShape ganz einfach. Es ist eine coole Osterdeko und du kannst die Osterhasen Vorlage herunterladen. ',
    en:
      'This diy paper rabbit is the perfect Easter decoration and comes with a template. Choose your favorite paper color and head to PaperShape to download the template.',
  },
  meta_images_social: [
    {
      de: 'osterhase-basteln-aus-papier-vorlage.jpg',
      en: '3d-paper-bunny-rabbit-diy-easter.jpg',
    },
    {
      de: 'osterhase-basteln-aus-papier-vorlage.jpg',
      en: '3d-paper-bunny-rabbit-diy-easter.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'osterhase-basteln-aus-papier-vorlage.jpg',
      en: '3d-paper-bunny-rabbit-diy-easter.jpg',
    },
    alt_text: {
      de:
        'Osterhasen basteln mit Papier (kostenlose Vorlage) als coole Osterdeko / PaperShape #osterhase #osterdeko #papercraft #osterhase #diy',
      en:
        'diy paper bunny with template. the rabbit looks adorable and you can hide small Easter gifts in its belly (with pictures and how to video) / PaperShape #easterbunny #easter #papercraft #diy',
    },
  },
  h1: {
    de: 'Osterhasen basteln in 3D',
    en: 'How to make a 3D paper bunny (template)',
  },
  description: {
    de: `
      <p>
      Mit welcher coolen Osterdeko dekoriere ich meine Wohnung dieses Jahr?
      </p>
      <p> <a href="/de/blog/ostereier-basteln/">Geometrische Eier </a> sind eine Möglichkeit.
          </p>
      
      <p>
    Einen Osterhasen basteln aber die andere. In diesem Post zeige ich euch wie ihr einen 3D Hasen mit geometrischen Mustern selber machen könnt.
     Die Anleitung und Vorlage für den Hasen findet ihr weiter unten.
      </p>
      <p>Wer nicht genug von Selbermachen an Ostern hat, der probiert unbedingt diese DIY Ideen aus Papier aus:
      <li> <a href="/de/blog/wimpern-hase/">süßes Wimpernhäschen als Geschenkverpackung</a> </li>
      <li>  <a href="/de/product/2-03-sfk/">bunter Dürer-Hase</a> </li>
      <li>  <a href="/de/blog/lustige-ostergruesse/">lustige Osterkarte</a> </li>
      </p>
    
   
      `,

    en: `
      <p>
      Spring is coming and most of us are searching for fancy Easter decoration. I love decor with function and that's why I created a paper Easter bunny 
      with a hideout. In its belly there is plenty of space to hide small gifts.
      </p>
      <p>
      You can either fold the Easter bunny in uni colors or with a geometric pattern. I love both styles. 
      </p>
    `,
  },
  inspirationTitle: {
    de:
      '3 coole Möglichkeiten, was du mit einem Papier Osterhasen machen kannst',
    en: 'How to make a rabbit with paper for Easter (with template)',
  },
  inspirationDescription: {
    de: `
   
<p><h3>Dekoriere deine Wohnung so richtig frühlingslike</h3></p>
<p>Gerade nach Weihnachten sehnen wir uns nach hellen Farben, frühlingshafter Deko und positiver Energie. Mit diesem Hasen aus Papier, faltest du dir eine wirklich coole Osterdeko, die du auf den Wohnzimmertisch, auf dem Fenstersims oder einem kleinen Beistelltisch mit Tulpen und Ostereiern anrichten kannst.</p>

<img class="img-responsive" src="/images/content/osterhase/osterhasen-basteln.jpg" alt="3d Osterhasen basteln im Frühling">

<p>Den Hasen in minimalistischem Weiß (oben im Bild) hat Kathrin von <a href="https://www.instagram.com/kreativmitkathrin/" target="_blank">@kreativmitkathrin</a>
 in richtig coolem Design in Szene gesetzt. Wacholdereier und das schwarze Buchstabenboard samt Gitter machen das Setting richtig trendy.</p>

<p>Eine eher farbexplosive Idee (Bild unten) kommt von <a href="https://www.instagram.com/__mrs.krabs__/" target="_blank">@__mrs.krabs__</a>. Sie hat bei einem der 
Osterhasen sogar die Ohren aufgestellt - wie cool ist das denn! Eine Vorlage mit zwei Varianten.</p>

<img class="img-responsive" src="/images/content/osterhase/osterhasen-basteln-papier.jpg" alt="Rote und graue Osterhasen basteln">


<p><h3>Richtig coole Osterverstecke basteln - die Alternative mit dem Osterhasen</h3></p>
<p>Diese typischen Osterkörbchen in quadratisch, rund oder aus Holz kennen wir nun alle und wenn man auf die Suche geht, dann weiß man zumindest, 
wonach man sucht.</p>

<p>Wenn ihr eine originelle Art für dieses Jahr plant, bei der eure Kids auch mal etwas länger beschäftigt sind, lege ich euch diesen Origami 
Osterhasen ans Herz.</p>

<p>Mit seiner stattlichen Höhe und dem geöffneten Boden, lassen sich super Süßigkeitenn darunter verstecken. Zwar ist dieser Osterhase nicht wirklich geeignet um ihn mit kleinen Kindern zu basteln, aber Versteck spielen l&auml;sst es sich damit allemal!&nbsp;</p>

<p>Ihr könnt zum Beispiel eure kleinen Geschenke in den Bauch stopfen, wie bei einer Weihnachtsgans und anschlie&szlig;end mit ein wenig Heu oder Stroh verschlie&szlig;en, damit nichts rausf&auml;llt.</p>

<img class="img-responsive" src="/images/content/osterhase/3D-Hase-Ostern-Vorlage-basteln.jpg" alt="3d Osterhase basteln im Frühling">


<p><h3>Coole Idee für ein DIY Ostergeschenk</h3></p>
<p>Könntest du mir bitte diesen süßen Osterbasteln basteln aus Tonkarton? Ich weiß von vielen, dass sie das Häschen super schön finden und wirklich gerne eines h&auml;tten. W&auml;re da nicht die Zeit, die man ben&ouml;tigt um diesen mit all seinen Faltungen selber zu machen.</p>
<p>Solltet ihr also zu den DIY-Passionisten gehören, dann garantiere ich euch, dass ihr dem Beschenkten eine riesige Freude machen könnt.</p>
Sehen diese rosa-weißen Hasen von  <a href="https://www.instagram.com/vonollsabissl/" target="blank">@vonollsabissl</a> (Bild unten)
  nicht einfach grandios zum Verschenken aus? </p>

<img class="img-responsive" src="/images/content/osterhase/3d-osterhase-basteln-geschenk.jpg" alt="Osterhase basteln DIY Ostergeschenk">



<h2>7 kreative DIY Ideen, um deinen selbst gemachten Osterhasen zu personalisieren</h2> 

<p>Wenn ihr mit dem Schneiden und Falten des Papiers fertig seid, könnt ihr nun entweder - ohne Firlefanz, ganz schlicht - 
den Osterhasen selber machen oder ihr verleiht ihm noch eine ganz persönliche Note. Welche Möglichkeiten es dabei gibt, zeige ich euch jetzt:</p>

<p>#1: Bemale jede einzelne Facette vom DIY Hasen in einer anderen Farbe. Am besten bemalst du sie vor dem Zusammenbau. Dazu kannst du Buntstifte, 
Fasermalstifte oder Wasserfarbe verwenden, wenn du mindestens Papiere mit einer Grammatur von 160g/m² verwendet hast. </p>

<p>#2: Sprüh deinen 3d Bunny in gold, silber oder kupfer an, falls du nicht die richtige Papierfarbe zur Hand hast. Wie das geht, 
zeigt dir <a href="https://fraeuleinzebra.com/2015/03/27/diy-3d-origami-hase-osterhasen-bausatz/" target="blank">Fräulein Zebra auf ihrem Blog</a> (Bild unten).</p>

<img class="img-responsive" src="/images/content/osterhase/osterhasen-basteln-kupfer-silber-gold.jpg" alt="Osterhasen in kupfer, gold, silber">

<p>#3: Gib deinem Osterhasen einen Zentangle-Look. Ich garantiere euch, ich habe es von einer Kundin bei meinem Elefanten gesehen, es sieht 
einfach Hammer aus! </p>
 
<p>#4: Bastelt den Osterhasen in unterschiedlichen Größen wie 
<a href="https://www.instagram.com/sarah.annina/" target="blank">@sarah.annina</a> (Bild unten). Die Vorlage könnt ihr unterschiedlich groß skalieren und auf A4 bishin zu A0 
drucken.</p>

<img class="img-responsive" src="/images/content/osterhase/osterhasen-basteln-riesig-klein.jpg" alt="Riesiger Osterhase aus Papier">

<p>#5: Nutze Deco Potch und klebe entweder Servietten mit coolen Mustern auf oder Fotos. Achtung, auch das am besten vor dem Zusammenkleben 
der einzelnen Papierteile vom Osterhasen machen.</p>

<p>#6: Mit Fellpapieren sehen eure Osterhasen aus Papier auf einmal wirklich echt aus. Sie sind selbstklebend und können 
einfach auf das Papier aufgeklebt werden. Zwar nicht jedermanns Sache, aber definitiv eine kreative Idee. </p>

<p>#7: Ihr seid Fans von Handlettering? Wie wäre es mit persönlichen Ostergrüßen oder osterrelevanten Wörtern wie Bunny, 
Karotte, Gänseblümchen? Euch fallen bestimmt noch bessere Wörter ein. </p>

    
    `,
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '3d-osterhase-origami.jpg',
          en: 'paper-bunny-easter.jpg',
        },
        alt_text: {
          de:
            'Easter bunny deko aus Papier (kostenlose Vorlage). Ich finde das geometrische Muster total cool - erinnert an Origami. PaperShape #osterhase #osterdeko #papercraft #diy',
          en:
            'Paper rabbit instructions with pdf. Cool Easter rabbit craft. You just cut, fold and glue the paper parts / PaperShape #easterbunny #easter #papercraft #diy',
        },
      },
    },
    {
      text: {},
    },
  ],

  stepsTitle: {
    de: 'Wie bastel ich einen Osterhasen aus Papier - Anleitung ',
    en: 'Paper craft: Easter bunny instructions',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'anleitungsschritt-osterhase-vorlage-zum-ausdrucken.jpg',
          en: 'step-print-template.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Osterhase Vorlage herunterladen',
          en: 'Step: Download Easter bunny template',
        },
      },

      text: {
        de: `Druckt die Osterhasen Vorlage auf DIN A4 Fotokarton in eurer Wunschfarbe oder Muster aus. Ihr könnt den Osterhasen auch mit Tonpapier basteln. Es kommt eben darauf ab, wie stabil ihr ihn möchet.
        Ich habe meine Papiere einfach mit einem Muster von der anderen
        Seite bedruckt. Ihr könnt sie natürlich auch bereits fertig mit anderen Mustern kaufen.`,
        en: `Print the bunny template (DIN A4) on paper of your choice. Originally I designed this template for our German paper size. That is why you have to scale
        down the size of the template to around 80%.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-vorlage-ausschneiden.jpg',
          en: 'step-cut-template.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ausschneiden',
          en: 'Step: Cutting',
        },
      },
      text: {
        de: `Schneide mit einer Schere alle Elemente entlang der durchgezogenen Linie aus. Bei dieser Hasen Vorlage könnt
        ihr auch einen Cutter verwenden, weil es manchmal Stellen gibt, an die man mit einer Schere vielleicht nicht so gut drankommt.`,
        en: `Cut out the paper parts (A, B, C, ...) by cutting along the solid lines. I usually do that with scissors but as there are some tricky parts
        you may prefer to use a cutter knife.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-vorfalzen.jpg',
          en: 'step-folding.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Falzen',
          en: 'Step: Folding',
        },
      },
      text: {
        de: `Legt nun ein Lineal an alle gestrichelten Linien an und fahrt mit einem stumpfen dünnen Gegenstand die Linien mit Druck
        ab. Unabhängig davon, ob ihr Fotokarton oder dünneres Papier verwendet, empfehle ich die Falzlinien
         mit Druck nachzufahren, bevor ihr das Papier faltet. So entstehen schöne Knicke und das Papier bricht nicht an den Kanten.`,
        en: `Place a ruler on the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines. Don't skip this step - no matter what paper you use. The result will look so much better.`,
      },
    },

    {
      image: {
        filename: {
          de: 'anleitungsschritt-knicken.jpg',
          en: 'step-foldingparts.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Knicken',
          en: 'Step: Folding paper parts',
        },
      },
      text: {
        de: `Legt das Papierteil A so vor euch hin, dass ihr die Zahlen und
              Buchstaben seht. Knickt die kurz gestrichelten Linien nach innen,
              d.h. zu euch hin (Talfalte). Faltet alle lang gestrichelten Linien
              nach außen, d.h. von euch weg (Bergfalte).`,
        en: `Start with the paper piece A and place it in front of you. 
              The printed side should be facing you. Subsequently fold all the dotted lines 
              either inwards, which means to fold up (short dotted lines) or outwards, 
              which means to fold down (long dotted lines), according to the markings.`,
      },
    },

    {
      image: {
        filename: {
          de: 'anleitungsschritt-kleben.jpg',
          en: 'step-glueing.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together ',
        },
      },
      text: {
        de: `Bringt auf die Laschen Kleber auf und verklebt jeweils die
        passenden Zahlenpaare. Ich verwende gerne Klebepads, weil bei flüssigem Kleber einfach zu oft aus Versehen doch ein Klecks
        über den Rand läuft. Außerdem sind eure Hände klebefrei und ihr müsste euch keine Sorgen machen, dass der Osterhase von außen mit Kleberesten beschmutzt wird.
        Beginnt mit der Nr. 1 auf A und B und bastelt in nummerischer Reihenfolge (2,3,4, etc.) weiter.`,
        en: `Use double-sided tape or any glue onto the unprinted side of the tabs. I prefer to craft with adhesive tape because your fingers stay clean.
        Each number exists twice. Stick all the same numbers together in ascending order (1, 2, 3, ...). Start with section 1 and stick it to
      the section marked 1 on paper A, pressing firmly enough to make them stick together.`,
      },
    },

    {
      image: {
        filename: {
          de: 'osterhase-ostergeschenke-versteck.jpg',
          en: 'spring-bunny-decoration-bottom.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Osterhase von unten',
          en: 'Step: Bunny bottom ',
        },
      },
      text: {
        de: `Nach dem Verkleben aller Laschen ist euer Osterhase fertig gebastelt. Dank der schönen low poly Facetten erinnert er doch
        an den Origami-Stil, den ich persönlich sehr mag.`,
        en: `Either put small gifts inside or just use it as lovely spring decoration.`,
      },
    },
  ],
  video: {
    title: {
      de:
        'Verpasst nicht das Osterhasen Video und lernt, wie ihr mühelos die Ohren an den Kopf anbringt.',
      en:
        "Don't miss the Easter bunny video and learn how to attach easily the ears to the head.",
    },
    description: {
      de: `Ihr lernt, wie ihr die Ohren knicken müsst und wie euch das Anbringen am Kopf am besten gelingt.  `,
      en: `You will learn how to craft the paper bunny ears and how to stick them to the head.`,
    },
    caption: {
      de: `Osterhasen basteln aus Papier (mit Vorlage)`,
      en: `3D Paper Bunny Craft (fancy diy Easter decoration)`,
    },
    videoid: 'xIERyjVhc7U',
  },
  downloadName: '3d-paper-bunny.pdf',
  materials: [
    {
      de: 'PDF Vorlage (siehe unten)',
      en: 'PDF Template',
    },
    {
      de: '7 DINA4 Papier uni oder gemustert (160-300g/m²)',
      en:
        '7 cardstock paper uni or with pattern (8.5in x 11in) (weight: 65lb-110lb / 176gsm-300gsm)',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'osterhase-basteln-papier.jpg',
          en: 'paper-bunny-easter-tutorial-spring-deco.jpg',
        },
        alt_text: {
          de:
            'Osterhase basteln aus Papier (mit Vorlage und Bastelanleitung). Das 3D Häschen hat sogar Raum für kleine Ostergeschenke / PaperShape #osterhase #papercraft #osterdeko #diy',
          en:
            'DIY Easter rabbit craft: easter bunny from paper in 3D. Cool papercraft model to build and the download. / PaperShape #easterbunny #papercraft',
        },
      },
    ],
  },
};
