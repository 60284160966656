export default {
  url: 'babyflasche-basteln',
  countermark: 'vg07.met.vgwort.de/na/733f30bad30546fc97849fd1f5e9fa1a',
  imageFolder: 'babyflasche',

  name: {
    de: 'Babyflasche',
    en: 'Babyflasche',
  },
  id: '3-10-blog',
  pdf: {
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'content/babyflasche/babyflasche-basteln-papier.jpg',
        en: 'content/babyflasche/babyflasche-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Babyflasche basteln aus Papier #papershape #bastelnmitpapier #diy ',
        en: 'How to make a paper baby bottle #papercraft #crafting #diy',
      },
    },
  },

  meta_title: {
    de: 'Papier Babyflasche basteln (auch als Geschenkverpackung)',
    en: 'How to make a paper baby bottle',
  },
  meta_description_google: {
    de: 'Verschenke zur Geburt eines Babys diese originelle Babyflasche aus Papier. Einfach Vorlage herunterladen, basteln und mit Süßigkeiten füllen.',
    en: 'Make this lovely paper baby bottle for your next baby shower. Download the template, craft it and fill it sweets.',
  },
  meta_description_social: {
    de: 'Verschenke zur Geburt eines Babys diese originelle Babyflasche aus Papier. Einfach Vorlage herunterladen, basteln und mit Süßigkeiten füllen.',
    en: 'Make this lovely paper baby bottle for your next baby shower. Download the template, craft it and fill it sweets.',
  },
  meta_images_social: [
    {
      de: 'babyflasche-basteln-papier.jpg',
      en: 'babyflasche-basteln-papier.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'babyflasche-basteln-papier.jpg',
      en: 'babyflasche-basteln-papier.jpg',
    },
    alt_text: {
      de: 'abyflasche basteln aus Papier #papershape #bastelnmitpapier #diy #babyshower',
      en: 'How to make a paper baby bottle #papercraft #crafting #diy #babayshowergift',
    },
  },
  h1: {
    de: 'DIY Babyflasche basteln (und als Geschenkbox verschenken)',
    en: 'How to make a paper baby bottle',
  },
  description: {
    de: `
      <p>Zur Geburt eines Babys werden sehr gerne Windeltorten verschenkt. Sie sind nützlich und machen etwas her.  </p>
      <p>Wer sein Geschenk aufpeppen, 
      doch eher Geld oder eine kleine Zugabe mit Süßigkeiten verschenken möchte, für den ist diese Babyflasche aus Papier genau das Richtige. </p>
      <p>In diesem DIY Post zeige ich euch, wie ihr ganz einfach eine schöne Babyflasche in blau, rosa oder genderneutral basteln könnt. Die einzelnen Komponenten
      wie der Sauger, der Verschluss und die Flasche können in unterschiedlichen Farben gebastelt werden. </p>
      <p>Größe: HBT 20 x 9 x 9 cm </p>
  
      
    
    `,
    en: `
    <p> For the birth of a baby, diaper cakes are very popular. </ p>
<p> Who wants to spice up his gift makes this cute baby bottle from paper. </ p>
<p> In this DIY post I'll show you the steps how to make a blue, pink or gender neutral baby bottle.</ p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'DIY Babyflasche aus Tonkarton selber machen: Anleitung',
    en: 'Step by step: how to make 3d paper baby bottle',
  },
  steps: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Ihr benötigt pro Babyflasche 4 DINA4 Papier. Verwendet am besten Tonkarton mit einer Grammatur von bis zu 230g/m².
        Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer
        Schere aus.
       
        `,
        en: `You need 4 page construction paper per baby bottle. 
          The best will be you use paper with a grammage of up to 230g/m². 
        Print out the template and cut out all solid lines with scissors.`,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Falzt mit einer Ahle oder einem Falzbein und einem Lineal alle gestrichelten Linien nach.`,
        en: `Crease all dashed lines with an awl or a bonefolder. Therefore, place a ruler next to the dashed lines
        and crease the lines by pressing the bonefolder down. 
      `,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: ` Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin, und die lang gestrichelten Linien nach unten, d.h. von euch weg.
        `,
        en: ` 
        Fold the short dashed lines up, i.e. up to you and the long dashed lines down.`,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Bringt auf die Laschen der einzelnen Papierteile Kleber auf. Wichtig ist, dass den Kleber auf die schöne Seite und damit die unbedruckte Seite der Lasche klebt. 
        Ihr beginnt in nummerischer Reihenfolge anschließend die einzelnen Teile zu verkleben, d.h. ihr startet bei 1 auf A und verklebt diese. Danach verklebt ihr Nr. 2 und so weiter. Eine Zahl existiert immer doppelt, sodass die Zahlenpaare
        immer verklebt werden müssen. Zum Schluss findet ihr einige Laschen ohne Nummern vor. Diese müsst ihr 
        lediglich stecken. Der Vorteil davon ist, dass ihr keine Probleme beim Verkleben der letzten Lasche habt, weil ihr
        nicht mehr drankommt und ihr zudem so eine schöne Geschenkverpackung kreiert habt.
  `,
        en: `Use glue onto the unprinted side of the flaps.
        Each number exists twice. Stick all the same numbers together in ascending order (1, 2, 3, ...). Start with section 1 and stick it to the section marked 1 on paper A, pressing firmly enough to make them stick together.`,
      },
    },
  ],

  downloadName: '',
  materials: [
    {
      de: '4 DINA4 Tonkarton (bis zu 130g/m²)',
      en: '4 colored construction paper (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber',
      en: 'Glue ',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    ],
  },
};
