import React from 'react';
import { connect } from 'react-redux';
import Currency from './Currency';
import { getShippingCosts } from '../state/order/orderSelectors';

const ShippingCosts = ({ shippingCosts }) => <Currency value={shippingCosts} />;

const mapStateToProps = (state) => {
  return {
    shippingCosts: getShippingCosts(state),
  };
};

export default connect(mapStateToProps)(ShippingCosts);
