import React from 'react';
import Lang from './Lang';
import PsBlogPostList from './PsBlogPostList';

export default ({ open }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h3>
            <strong>
              <Lang
                de="weitere DIY Anleitungen"
                en="more 3D DIY instructions"
              />
            </strong>
          </h3>
        </div>
        <PsBlogPostList open={open} />
      </div>
    </div>
  );
};
