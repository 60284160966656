export default {
  id: '2-02-sfk',
  category: ['home_decor'],
  name: {
    de: 'Herz',
    en: 'Heart',
  },
  subtitle: {
    de: 'B13 x H11 x T8 cm',
    en: 'W13 x H11 x D8 cm',
  },
  meta_title: {
    de: 'Herz',
    en: 'Heart',
  },
  meta_description_google: {
    de: 'Das Herz verzaubert als Deko jeden Raum. Das Herz lässt sich einfach falten und kleben. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The heart enchants each room with lots of space for creativity. This panda is very easy to assemble thanks to the new no-glue technique. 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: 'Das Herz verzaubert als Deko jeden Raum. Der Panda lässt sich einfach falten und stecken. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden',
    en: 'The heart enchants each room with lots of space for creativity. This panda is very easy to assemble thanks to the new no-glue technique. 100% Success Guarantee ✓ More than 10.000 happy customers ✓',
  },
  description: {
    de: `Mit diesem Origami Herz lasst ihr die Herzen eurer Mütter höher schlagen. In nur einer Stunden ist dieses facettenreiche Origami Herz geschnitten, gefaltet und geklebt. Dieses puristisch anmutende Origami Herz ist eine einzigartige Tischdeko, an der eure Mütter auch nach dem Muttertag noch Freude haben werden.

♥ DETAILS: ab 14 Jahren

♥ KOMPLETTSET ENTHÄLT

2 DIN A4 Bögen Fotokarton
Doppelseitige Klebepads
Detaillierte Schritt-für-Schritt-Anleitung

♥ BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser`,

    en: `Let your mother’s heart beat faster with this origami heart. In just one hour this multifaceted origami heart is cut, folded and glued. This purist origami heart is a unique table decoration, which will bring joy to your mum even after the Mothers’ Day.

    It's really easy to assemble. First, the 5 paper pieces are cut and pre-folded with a ruler and a butter knife. Just set the ruler to the dotted lines and press along with the back of the butter knife. Then fold all the pre-folded parts. Glue all the tabs using the provided adhesive pads. That's it - your heart is ready!
    
    Details: Age recommendation from 14 years
    
    Complete kit includes:
    3 DIN A4 cardboard sheets Origami Heart
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    Required materials (not included in the kit):
    Scissors
    Ruler
    Bread knife`,
  },

  colorinspirations: [
    {
      caption: {
        de: 'rot',
        en: 'rot',
      },
      image: {
        filename: {
          de: 'products/sfkherz/herz.jpg',
          en: 'products/sfkherz/herz.jpg',
        },
        alt_text: {
          de: 'Herz',
          en: 'Herz',
        },
      },
    },
    {
      caption: {
        de: 'pink',
        en: 'pink',
      },
      image: {
        filename: {
          de: 'products/sfkherz/herz-pink.jpg',
          en: 'products/sfkherz/herz-pink.jpg',
        },
        alt_text: {
          de: 'DIY 3D Herz aus Papier basteln zur Hochzeit, als Geschenkverpackung, als Deko oder Geschenk zum Muttertag #papershape',
          en: 'DIY 3d heart from paper - paper craft with template',
        },
      },
    },
    {
      caption: {
        de: 'rosa',
        en: 'rosa',
      },
      image: {
        filename: {
          de: 'products/sfkherz/herz-rosa.jpg',
          en: 'products/sfkherz/herz-rosa.jpg',
        },
        alt_text: {
          de: 'IY 3D Herz aus Papier basteln zur Hochzeit, als Geschenkverpackung, als Deko oder Geschenk zum Muttertag #papershape',
          en: 'DIY 3d heart from paper - paper craft with template',
        },
      },
    },
    {
      caption: {
        de: 'türkis',
        en: 'türkis',
      },
      image: {
        filename: {
          de: 'products/sfkherz/herz-tuerkis.jpg',
          en: 'products/sfkherz/herz-tuerkis.jpg',
        },
        alt_text: {
          de: 'IY 3D Herz aus Papier basteln zur Hochzeit, als Geschenkverpackung, als Deko oder Geschenk zum Muttertag #papershape',
          en: 'DIY 3d heart from paper - paper craft with template',
        },
      },
    },
  ],
  pdf: {
    description: {
      de: `Mit diesem Origami Herz lasst ihr die Herzen eurer Mütter höher schlagen. In nur einer Stunden ist dieses facettenreiche Origami Herz geschnitten, gefaltet und geklebt. Dieses puristisch anmutende Origami Herz ist eine einzigartige Tischdeko, an der eure Mütter auch nach dem Muttertag noch Freude haben werden.

♥ DETAILS: ab 14 Jahren

♥ KOMPLETTSET ENTHÄLT

Digitale Vorlage als PDF zum Herunterladen
Detaillierte Schritt-für-Schritt-Anleitung

♥ BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : 2 DIN A4 Seiten Papier (mind. 160g/m), Schere, Lineal, Buttermesser`,

      en: `Let your mother’s heart beat faster with this origami heart. In just one hour this multifaceted origami heart is cut, folded and glued. This purist origami heart is a unique table decoration, which will bring joy to your mum even after the Mothers’ Day.

    It's really easy to assemble. First, the 5 paper pieces are cut and pre-folded with a ruler and a butter knife. Just set the ruler to the dotted lines and press along with the back of the butter knife. Then fold all the pre-folded parts. Glue all the tabs using the provided adhesive pads. That's it - your heart is ready!
    
    Details: Age recommendation from 14 years
    
    Complete kit includes:
    3 DIN A4 cardboard sheets Origami Heart
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    Required materials (not included in the kit):
    Scissors
    Ruler
    Bread knife`,
    },
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'products/sfkherz/3d-origami-herz-basteln.jpg',
        en: 'products/sfkherz/3d-origami-herz-basteln.jpg',
      },
      alt_text: {
        de: 'IY 3D Herz aus Papier basteln zur Hochzeit, als Geschenkverpackung, als Deko oder Geschenk zum Muttertag #papershape',
        en: 'DIY 3d heart from paper - paper craft with template',
      },
    },
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkherz/3d-origami-herz-basteln.jpg',
        en: 'products/sfkherz/3d-origami-herz-basteln.jpg',
      },
      alt_text: {
        de: 'IY 3D Herz aus Papier basteln zur Hochzeit, als Geschenkverpackung, als Deko oder Geschenk zum Muttertag #papershape',
        en: 'DIY 3d heart from paper - paper craft with template',
      },
    },
    colors: {
      color1: {
        de: 'Herz',
        en: 'Heart',
      },
    },
    disableColors: {
      color1: ['schwarz', 'anthrazit', 'bunt'],
    },
    price: 14.9,
    priceThirdCountry: 12.5,
  },
};
