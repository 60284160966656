export default {
  url: 'wimpern-hase',
  countermark: 'vg07.met.vgwort.de/na/7fec1e6e1d364f87b88d0dde8fa2910a',

  imageFolder: 'ostereihase',
  meta_title: {
    de: 'Trend Wimpern Häschen als Osterdeko selber machen ',
    en: 'DIY bunny from paper with cute lashes',
  },
  meta_description_google: {
    de:
      'Einhorn Wimpern liegen voll im Trend. Wie wäre es mit einer Osterdeko in Form eines trendy Wimpern Hasen aus Papier. Die Vorlagen einfach kostenlos herunterladen und mit der DIY Idee beginnen.',
    en:
      'Learn how to make a paper bunny with cute lashes in 3d for Easter decoration. Just donwload the free template, choose your favorite paper color and start paper crafting.',
  },
  meta_description_social: {
    de:
      'Einhorn Wimpern liegen voll im Trend. Wie wäre es mit einer Osterdeko in Form eines trendy Wimpern Hasen aus Papier. Die Vorlagen einfach kostenlos herunterladen und mit der DIY Idee beginnen.',
    en:
      'Learn how to make a paper bunny with cute lashes in 3d for Easter decoration. Just donwload the free template, choose your favorite paper color and start paper crafting.',
  },
  meta_images_social: [
    {
      de: 'papier-ostereier-basteln.jpg',
      en: 'diy-bunny-lashes.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'wimpern-hasen-basteln.jpg',
      en: 'diy-bunny-lashes.jpg',
    },
    alt_text: {
      de:
        'Osterdeko basteln: Hase mit trendy Wimpern und kostenloser Vorlage / PaperShape #osterhase #osterdeko #ostereier  #diy',
      en:
        'Modern Easter egg paper craft with free template / PaperShape #eastereggs #easter #papercraft #diy',
    },
  },
  h1: {
    de: 'Trend Wimpern Häschen als Osterdeko ',
    en: 'DIY bunny from paper with cute lashes',
  },
  description: {
    de: `
            <p> Zu Ostern wimmelt es zu Recht nur so von Osterhasen. 
            Und als trendbewusste DIYler ist dieser Trend bestimmt
             nicht an euch vorbeigegangen: Die träumerischen Einhorn Wimpern. 
             Egal, ob als Poster in Riesenformat, oder aufgemalt oder als Accesssoire 
             für meine Osterhasen in Eiform, diese Wimpern sieht man gerade überall. 
             Das Osterei könnt ihr als Osterverpackung benutzen und mit Süßigkeiten füllen. Ich würde die Öffnung unten einfach mit Lametta 
      oder Stroh oder Seidenpapier verschließen.
      </p> 
      <p>Aber auch als Osterdeko neben frühlingshaften Tulpen machen sie sich super schön. 
      Die Vorlage ist identisch mit den Ostereiern aus diesem Post <a href="/de/blog/ostereier-basteln/">hier</a>.</p> 
         
    
    `,
    en: `
      <p>
      I am sure you are already aware of this trend:
      </p>
      <p>
      Unicorn lashes.</p> 
      <p>I decided to stick them this time not to a unicorn or the wall. 
      I made a bunny out of paper eggs as Easter decoration.
      If you want you may also use it as a Easter gift box and 
      fill it's belly with sweet candies. In any ways - they are super adorable and 
      a must-craft for Easter.
      </p>
     
    `,
  },
  inspirationTitle: {
    de: 'DIY Osterhasen mit schlafenden Wimpern',
    en: 'How to diy a paper bunny with cute sleeping lashes?',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'diy-hase-wimpern.jpg',
          en: 'bunny-unicorn-lashes.jpg',
        },
        alt_text: {
          de:
            'DIY Osterhase Deko mit Einhorn Wimpern zum selber machen / PaperShape  #osterdeko #ostereier #ostern #diy',
          en:
            'Easter egg paper craft with free template / PaperShape #eastereggs #easter #papercraft #diy',
        },
      },
    },
    {
      text: {
        de:
          'Ob an Tulpen gelehnt, stehend oder als Osterverpackung im Osternest versteckt. Ich persönlich finde sie super süß.',
        en:
          'You may use these sweetie bunnys as Easer gift boxes. Just fill the bottom with lametta in order to close the paper egg.',
      },
    },
    {
      image: {
        filename: {
          de: 'trend-wimpern-hasen-papier.jpg',
          en: 'trendy-bunny-lashes.jpg',
        },
        alt_text: {
          de:
            'Papier Osterhasen mit süßen Wimpern basteln als Osterdeko / PaperShape #osterhase #osterdeko #ostereier #ostern #diy #papercraft',
          en:
            'DIY paper bunny with cute lashes / PaperShape #eastereggs #easter #papercraft #diy',
        },
      },
    },
    {
      text: {},
    },
  ],
  stepsTitle: {
    de: 'Trend Hasen mit schlafenden Wimpern: so gelingen sie euch!',
    en: "Cute sleeping bunnies with lashes: that's how you make them!",
  },
  steps: [
    {
      image: {
        filename: {
          de: 'step-vorlage-ausschneiden.jpg',
          en: 'step-vorlage-ausschneiden.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt  Vorlage herunterladen',
          en: 'Step: Download template',
        },
      },
      text: {
        de: `Die Vorlage für die Ostereier einfach auf ein DIN A4 Ton- oder Fotokarton in eurer Wunschfarbe ausdrucken. 
         `,
        en: `Print the paper egg template (US letter) on paper of your choice.`,
      },
    },
    {
      image: {
        filename: {
          de: 'step-ausschneiden.jpg',
          en: 'step-ausschneiden.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ausschneiden',
          en: 'Step: Cutting',
        },
      },
      text: {
        de: `Es geht mit dem Schneiden los. Mit einer Schere einfach alle Elemente entlang der durchgezogenen Linie der Osterei Vorlage ausschneiden.
        
        `,
        en: `Cut out the paper parts (A, B, C, ...) by cutting along the solid lines of the Easter egg template.`,
      },
    },
    {
      image: {
        filename: {
          de: 'step-falten.jpg',
          en: 'step-falten.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Falzen',
          en: 'Step: Folding',
        },
      },
      text: {
        de: `Legt nun ein Lineal an alle gestrichelten Linien (egal wie sie aussehen) an und fahrt mit einer Ahle oder Falzbein die Linien mit Druck ab. Die weiteren
        Schritte für das Osterei findet ihr <a href="/de/blog/ostereier-basteln/">in diesem Post</a> `,
        en: `Place a ruler on the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines. 
                    The detailed steps how to craft the easter egg you will find in <a href="/de/blog/ostereier-basteln/">this tutorial here</a>.`,
      },
    },
    {
      image: {
        filename: {
          de: 'step-ohren-augen-vorlage.jpg',
          en: 'step-ohren-augen-vorlage.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Knicken',
          en: 'Step: Folding paper parts',
        },
      },
      text: {
        de: `Auf der zweiten Seite findet ihr die Vorlage für die Einhorn Wimpern und die Hasen-Ohren. 
        Um Papier zu sparen, findet ihr mehr Augen und Ohren auf Seite 2 als ihr für einen Hasen benötigt. 
        `,
        en: `On page 2 you'll find the template for the unicorn lashes and the bunny ears. I placed more ears and lashes on this page in 
        order to save paper.`,
      },
    },
    {
      image: {
        filename: {
          de: 'step-augen-ausschneiden.jpg',
          en: 'step-augen-ausschneiden.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together',
        },
      },
      text: {
        de: `Bei den Wimpern würde ich mit einem Cutter arbeiten oder, wenn es euch zu filigran ist, einfach die Augen aufmalen. Die Ohren ebenfalls ausschneiden.`,
        en: `I would recommend to cut out the lashes with a cutter or to just draw them on the paper egg. Cut out the ears, too.`,
      },
    },
    {
      image: {
        filename: {
          de: 'step-augen-kleben.jpg',
          en: 'step-augen-kleben.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together',
        },
      },
      text: {
        de: ` Klebt nun die Wimpern mit einem Kleber auf das Ei. Ich würde ein Quadrat zwischen den Wimpern freilassen.        `,
        en: `Stick the unicorn lashes with  glue on the paper egg. I would spare one square in betweeen of the lashes.`,
      },
    },
    {
      image: {
        filename: {
          de: 'step-ohren-ankleben.jpg',
          en: 'step-ohren-ankleben.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together',
        },
      },
      text: {
        de: `Zum Schluss die kleine runde Ecke beim Ohr nach hinten knicken und auf das Ei ebenfalls aufkleben - ready. `,
        en: `Finally fold the small part of the ear (dottled lines) and stick them to the egg - ready!`,
      },
    },
  ],

  downloadName: 'bunny-cute-lashes.pdf',
  materials: [
    {
      de: '2 DINA4 Papier uni (160-300g/m²)',
      en:
        '2 cardstock paper uni or with pattern (8.5in x 11in) (weight: 65lb-110lb / 176gsm-300gsm)',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
    {
      de: 'Süßigkeiten zum Befüllen',
      en: 'Sweets for filling',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert die DIY Idee für euer nächstes Projekt auf Pinterest',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'diy-osterhasen-wimpern.jpg',
          en: 'diy-bunny-lashes-paper.jpg',
        },
        alt_text: {
          de:
            'Trendy DIY Hasen mit süßen Wimpern aus Papier als Osterdeko / PaperShape #osterdeko #ostereier #ostern #diy #papercraft',
          en:
            'Super cute diy bunnys with lashes (free template) / PaperShape #eastereggs #easter #papercraft #diy',
        },
      },
    ],
  },
};
