import React from 'react';
import { useParams } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { getIsLoading } from '../state/fetch/fetchSelectors';
import { connect } from 'react-redux';
import cn from 'classnames';

function PsLinkInner({
  to,
  hash,
  onClick, // for navItem (react-bootstrap)
  children,
  style,
  className,
  isLoading,
  disabled,
  disableStyles,
}) {
  // lang could be not a valid path
  let { lang } = useParams();
  const navigateTo =
    lang !== 'en' && to === '/'
      ? to
      : '/' +
        (['de', 'en'].some((ln) => ln === lang) ? lang : 'de') +
        to +
        (to.lastIndexOf('/') === to.length - 1 ? '' : '/');
  return (
    <Link
      to={navigateTo + (hash ? '#' + hash : '')}
      disabled={disabled}
      style={style}
      onClick={onClick}
      className={cn(className, {
        'btn disabled': isLoading,
        'disable-styles': disableStyles,
      })}>
      {children}
    </Link>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: getIsLoading(state),
  };
};

export const PsLink = connect(mapStateToProps)(PsLinkInner);
