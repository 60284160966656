import React, { Component } from 'react';
import { Field } from 'redux-form';
import CountrySelect from '../CountrySelect';
import {
  required,
  maxLength100,
  streetNameNumber,
} from '../../validation';
import PsInput from '../../common/inputs/PsInput';
import PsFieldError from '../../common/inputs/PsFieldError';
import Lang from '../../common/Lang';
import { getTextBySelectedLang } from '../../data/lang';

export default class Address extends Component {
  render() {
    return (
      <div>
        <Field
          name="streetNameNumber"
          label={getTextBySelectedLang('Straße Nr.', 'Street no')}
          placeholder={getTextBySelectedLang(
            'Straße Nr. oder Packstation',
            'Street no'
          )}
          component={PsInput}
          validate={[required, maxLength100, streetNameNumber]}
        />
        <Field
          name="additional"
          label={getTextBySelectedLang('Adresszusatz', 'Additional address')}
          placeholder={getTextBySelectedLang(
            'Straße Nr. oder Postnummer',
            'Street no'
          )}
          component={PsInput}
        />
        <Field
          name="zipCode"
          label={getTextBySelectedLang('PLZ', 'Zip Code')}
          component={PsInput}
          validate={[required, maxLength100]}
        />
        <Field
          name="city"
          label={getTextBySelectedLang('Ort', 'City')}
          component={PsInput}
          validate={[required, maxLength100]}
        />
        <Field
          name="country"
          label={<Lang de="Land" en="Country" />}
          component={({ input, label, children, meta }) => (
            <div className="form-group">
              <label htmlFor="name">{label}</label>
              <CountrySelect input={input} className="form-control" />
              <PsFieldError meta={meta} />
            </div>
          )}
          validate={[required]}
        />
        {/* <Field
          name="state"
          label={'State'}
          component="input"
        /> */}
      </div>
    );
  }
}
