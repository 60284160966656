import materials from './materials';

export default {
  url: 'tasche-selber-machen',
  countermark: 'vg07.met.vgwort.de/na/185a91b3963b45e1941c30d9748401c1',
  imageFolder: 'candywrapperbag',
  landingPage: ['diy-geschenke'],
  meta_title: {
    de: 'Taschen selber machen aus Zeitschriften (Candy Wrapper Bag)',
    en: 'How to make a candy wrapper bag (detailed tutorial)',
  },
  meta_description_google: {
    de:
      'Mit dieser detaillierten Anleitung zeige ich euch Schritt für Schritt wie ihr eure Candy Wrapper Bag selber macht. Ihr benötigt nur Zeitschriften, um euch diese cooel Tasche aus Papier selber basteln zu können. ',
    en:
      "With this step by step tutorial I'll show you how to make a candy wrapper bag from paper.",
  },
  meta_description_social: {
    de:
      'DIY Tasche aus Zeitschriften selber machen mit Anleitung. Candy Wrapper Bag  #paperhape',
    en:
      'tutorial: candy wrapper bag from magazine leftovers diy #papershape   ',
  },
  meta_images_social: [
    {
      de: 'candy-wrapper-bag-tasche-selber-machen-papier.jpg',
      en: 'candy-wrapper-bag-tasche-selber-machen-papier.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'candy-wrapper-bag-tasche-selber-machen-papier.jpg',
      en: 'candy-wrapper-bag-tasche-selber-machen-papier.jpg',
    },
    alt_text: {
      de:
        'DIY Tasche aus Zeitschriften selber machen mit Anleitung. Candy Wrapper Bag  #paperhape ',
      en:
        'tutorial: candy wrapper bag from magazine leftovers diy #papershape ',
    },
  },
  h1: {
    de: 'Taschen selber machen aus Zeitschriften (Candy Wrapper Bag)',
    en: 'How to make a candy wrapper bag (detailed tutorial) ',
  },
  description: {
    de: `
      <p>Wer von uns hat nicht Lifestyle-Magazine daheim liegen und bis auf den Papierkorb keine Verwendung dafür? </p>
      <p> Wer ein wenig Zeit mitbringt, der kann sich mit dieser Candy Wrapper Bag Anleitung 
      seine eigene Tasche aus Zeitschriften oder Zeitungspapier flechten. </p>
      Ich habe in mehreren Stunden Arbeit DIY Taschen, Clutches und Portemonnaies selbst gemacht. 
      Sie sind stabil, machen Spaß und sind auch noch nachhaltig. </p>
      
      <p>Falls ihr euch fragt, welche weiteren Upcycling Ideen ihr ausprobieren könnt, dann schaut euch unbedingt diese DIY Ideen hier an </p>
       <p><li><a href="/de/blog/papierring-basteln/">stylischer Papierring aus Papierresten</a></li>
       <li><a href="/de/blog/schallplatten-uhr-basteln/">Vintage Uhr aus Schallplatten</a></li>
      </p>
    `,
    en: `Candy wrapper bags are a fun way to recycle leftovers from magazines. 
    It will take you some time but the result is so amazing. Depending on the size of your bag
    you can use it for shopping or just for small items. 
    
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'tasche-papier-recycling-bag-candy-wrapper.jpg',
          en: 'tasche-papier-recycling-bag-candy-wrapper.jpg',
        },
        alt_text: {
          de:
            'DIY Tasche aus Zeitschriften selber machen mit Anleitung. Candy Wrapper Bag  #paperhape ',
          en:
            'tutorial: candy wrapper bag from magazine leftovers diy #papershape   ',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: 'taschen-zeitungspapier-anleitung.jpg',
          en: 'taschen-zeitungspapier-anleitung.jpg',
        },
        alt_text: {
          de:
            'DIY Tasche aus Zeitschriften oder Zeitungspapier selber machen mit Anleitung. Candy Wrapper Bag  #paperhape ',
          en:
            'tutorial: candy wrapper bag from magazine leftovers diy #papershape   ',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
  ],

  stepsTitle: {
    de:
      'Wie mache ich eine Tasche aus Zeitschriften oder Zeitungspapier selber - Anleitung',
    en: 'How do I make a candy wrapper bag from paper - tutorial',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01.jpg',
          en: '01.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de:
          'Schneidet das Papier in Rechtecke in der Größe 13,5 x 6cm. Ihr könnt die Rechtecke auch in jeder beliebigen anderen Größe wählen. Achtet nur darauf, dass das Verhältnis beider Seiten bei 2,25 liegt. Ansonsten lassen sich die Papierstreifen am Ende nicht ineinander weben. Ihr benötigt 665 Papierstreifen. ',
        en: `  `,
      },
    },
    {
      image: {
        filename: {
          de: '02.jpg',
          en: '02.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Faltet das Papierrechteck einmal längs in der Mitte und öffnet es.`,
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: '03.jpg',
          en: '03.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Faltet die langen Seiten nun zur Falzmitte. `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '04.jpg',
          en: '04.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: `Diesen Papierstreifen nun wieder in der Mitte zusammenfalten.
        Sobald ihr einige Dutzend dieser Streifen angefertigt habt, geht es an das  Veredeln . 
        Rollt das Tesaband aus und klebt darauf so viele Papierstreifen wie möglich. Bei mir 
        haben immer drei Streifen nebeneinander gepasst. Mit dem Präzisions-Cutter die Streifen zuschneiden und von überstehendem Tesafilm befreien.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '05.jpg',
          en: '05.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: `Der laminierte Papierstreifen wird jetzt einmal in der Mitte gefaltet. Aufpassen, dass die laminierte Seite nach außen liegt.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '06.jpg',
          en: '06.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: `Nun die beiden Enden in die Mitte falten. Fertig ist der zu verwebende Papierstreifen. Von diesen müsst ihr nun einige Dutzend anfertigen (insgesamt 665 Stück)`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '07.jpg',
          en: '07.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Sobald ihr genügend Papierstreifen gefaltet habt, könnt ihr diese verweben. Dazu müsst ihr den einen Streifen (hier den weißen) in den anderen Streifen (hier in den blauen) hineinstecken und so weit wie möglich durchziehen.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '07a.jpg',
          en: '07a.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Diesen Schritt wiederholt ihr nun, bis ihr die gewünschte Länge erreicht habt. Dabei webt ihr immer einen Streifen von unten und den anderen von oben rein, wie auf dem Bild
        beschrieben.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '08.jpg',
          en: '08.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: ` Ich habe 68 Papierstreifen für eine candy wrapper-Reihe verwendet. Insgesamt habe ich 8 solcher candy wrapper-Reihen gewebt.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '09.jpg',
          en: '09.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Der Taschenboden: Die Webtechnik funktioniert wie in den vorigen Schritten beschrieben auch beim Boden. Die einzigen Ausnahmen stellen hier die Ecken dar. 
          Hier muss nicht wie vorher der Papierstreifen von jeweils unten eingewebt werden, sondern er wird von oben eingewebt. 
          So entsteht an der Kante kein Zickzack-Muster, sondern eine Linie. Für den ersten äußeren Bodenrand benötigt ihr 64 Papierstreifen.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '10.jpg',
          en: '10.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de:
          'Für den zweiten Bodenrand, der in den ersten reinpasst, habe ich 48 Papierstreifen verwebt.',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '11.jpg',
          en: '11.jpg',
        },
        alt_text: {
          de: ' ',
          en: ' ',
        },
      },
      text: {
        de: `Nun benötigt ihr 9 einzelne Papierquadrate für den Boden. Ihr nehmt eines der Papierstreifen, die ihr gefaltet habt, und schneidet es in der Mitte durch. 
          Bei beiden Streifen gilt: Je die Enden zur Falzmitte falten. Nun erneut wie bereits oben beschrieben (vgl. Bild 6) zur Mitte schließen.
          Die beiden kleinen Papierstreifen müssen nun ineinander verwebt werden, wie bereits oben gezeigt. Das fertige Papierquadrat kann nun in den Boden vernäht werden.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '12.jpg',
          en: '12.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Jetzt geht es an das Vernähen der Tasche. Dazu schiebt ihr die Nadel unterhalb der ersten Papierlage durch.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '13.jpg',
          en: '13.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Macht nun einen U-Turn mit der Nadel.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '14.jpg',
          en: '14.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Hier bin ich nochmal unter dem grünen Papierstreifen hergegangen, damit ich im nächsten Schritt waagerecht vernähen konnte.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '15.jpg',
          en: '15.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Nun quasi waagerecht durch den angrenzenden Papierstreifen die Nadel ziehen.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '16.jpg',
          en: '16.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Jetzt wieder einen U-turn zum parallel liegenden Papierstreifen machen.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '17.jpg',
          en: '17.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de:
          'Um eine candy wrapper-Reihe zu schließen, müsst ihr an einem Ende der Reihe die zwei Papierenden wieder herausholen.',
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '18.jpg',
          en: '18.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de:
          ' Nun wird das andere Ende der candy wrapper-Reihe zwischen die einzelnen Papierstreifen gelegt.',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '19.jpg',
          en: '19.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de:
          'Die aufragenden Papierstreifen müssen nun in den eben hineingelegten Papierstreifen hineingesteckt werden. Nicht aufgeben, wenn es nicht sofort gelingt. Es erfordert ein wenig Übung. ',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '20.jpg',
          en: '20.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de:
          'Frontansicht des vernähten Bodens. Er beugt sich leicht nach innen, weil an den vier Ecken jeweils vier Kanten durch das Vernähen entstanden sind.',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '21.jpg',
          en: '21.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: 'Rückansicht. Die Ecken sind hier deutlich zu sehen',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '22.jpg',
          en: '22.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de:
          'Frontansicht. Vom Boden aus werden die restlichen candy wrapper-Reihen einfach aufgenäht',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '23.jpg',
          en: '23.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: 'Rückansicht',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '24.jpg',
          en: '24.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de:
          'Die selbst gemachte Tasche in der Mitte provisorisch zusammennähen, damit das Anbringen der Henkel einfacher gelingt. Ich habe von links und rechts im Zickzack jeweils 11 Papierstreifen abgezählt, um die Position des Taschenhenkels zu finden',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '25.jpg',
          en: '25.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de:
          'Nun habe ich die Taschenhenkel angelegt und mit dem Eispickel bzw. der Holzahle die Löcher durch das Material und das Papier gebohrt. Anschließend mit Nadel und Faden vernäht. Wundert euch nicht, wenn das Durchbohren durch das Papier nicht so einfach gelingt. Es sind schließlich mehrere Lagen Papier.',
        en: ``,
      },
    },
  ],

  materials: [
    {
      de: 'Ca. 3 Zeitschriften ',
      en: '3 Magazines ',
    },
    {
      de: 'Präzisions-Cutter',
      en: 'Cutter',
    },

    {
      de: 'Nadel & Faden',
      en: 'Needle and thread',
    },
    materials.scissors,
    {
      de: '2 Tesabänder (ca. 5 cm breit)',
      en: 'transparent tape ',
    },
    {
      de: 'Taschengriffe (ca. 70cm lang)',
      en: 'Case handles (apprx. 70cm long) ',
    },
    {
      de: 'Ahle oder spitzen Gegenstand zum Löcherbohren',
      en: 'Awl ',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'candy-wrapper-bag-tasche-papier-selber-machen.jpg',
          en: 'diy-candy-wrapper-bag-tutorial-paper.jpg',
        },
        alt_text: {
          de:
            'DIY Tasche aus Zeitschriften selber machen mit Anleitung. Candy Wrapper Bag  #paperhape',
          en:
            'tutorial: candy wrapper bag from magazine leftovers diy #papershape  ',
        },
      },
    ],
  },
};
