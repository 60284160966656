import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Step2Link } from '../../common/Links';
import PsProductListByCategory from '../../common/PsProductListByCategory';
import { getTextBySelectedLang } from '../../data/lang';
import ProductRow from '../../common/ProductRow';
import ShippingCosts from '../../common/ShippingCosts';
import Lang from '../../common/Lang';
import OrderSumGross from '../../common/OrderSumGross';
import CountrySelect from '../CountrySelect';
import Currency from '../../common/Currency';
import {
  getOrderProductsEnhanced,
  orderFormName,
  freeShippingVolume,
  isFreeShippingCountry,
  getAlternativeShippingAddress,
} from '../../state/order/orderSelectors';
import PsMeta from '../../common/PsMeta';

class Cart extends Component {
  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang(
            `Bestellung Schritt 1 von 2`,
            `Order step 1 of 2`
          )}
        />

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-header">
                <Lang de="Bestellung " en="Order " />

                <small>
                  <Lang de={`Schritt 1 von 2`} en={`step 1 of 2`} />
                </small>
              </h1>
            </div>
          </div>

          <hr style={{ margin: '5px 0' }} />

          {this.props.orderProducts.map((product) => (
            <ProductRow
              key={`${product.id}${product.variantKey}`}
              {...this.props}
              product={product}
            />
          ))}

          <div className="row form-horizontal">
            <div className="col-xs-12">
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-6">
                  {/* <a className="btn btn-default btn-lg">+</a> */}
                </div>
                <div className="col-xs-12 col-sm-8 col-md-6">
                  <div className="form-group">
                    <label className="col-xs-6 col-sm-6 col-md-9 control-label">
                      <Lang de="Versand nach" en="Shipping to" />{' '}
                      {this.props.alternativeShippingAddress ? (
                        <Field
                          name="shippingAddress.country"
                          component={CountrySelect}
                        />
                      ) : (
                        <Field
                          name="billingAddress.country"
                          component={CountrySelect}
                        />
                      )}
                      <br />
                      {this.props.showFreeShippingHint && (
                        <small
                          style={{
                            fontWeight: 'normal',
                          }}>
                          <Lang
                            de={
                              <span>
                                Versandkostenfrei ab{' '}
                                <Currency value={freeShippingVolume} />{' '}
                                Warenwert
                              </span>
                            }
                            en={
                              <span>
                                Free shipping from an order value of{' '}
                                <Currency value={freeShippingVolume} />
                              </span>
                            }
                          />
                        </small>
                      )}
                    </label>
                    <div className="col-xs-6 col-sm-6 col-md-3 form-control-static">
                      <span style={{}}>
                        <ShippingCosts />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-9" />
                <div className="col-xs-12 col-sm-8 col-md-3">
                  <div className="form-group">
                    <label className="col-xs-6 col-sm-6 control-label">
                      <Lang de="Gesamtpreis" en="Total" />
                    </label>
                    <div className="col-xs-6 col-sm-6 form-control-static">
                      <span
                        style={{
                          fontWeight: 'bold',
                          borderBottom: '3px double black',
                        }}>
                        <OrderSumGross preorder />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-sm-12 col-md-offset-8 col-md-4"
              style={{ marginTop: 20, marginBottom: 40 }}>
              <Step2Link className="btn btn-lg btn-block btn-primary">
                <Lang
                  de="weiter zur Adresseingabe"
                  en="continue with address entry"
                />
              </Step2Link>
            </div>
          </div>

          <PsProductListByCategory />

          <div className="row">
            <div
              className="col-sm-12 col-md-offset-8 col-md-4"
              style={{ marginTop: 20, marginBottom: 40 }}>
              <Step2Link className="btn btn-lg btn-block btn-primary">
                <Lang
                  de="weiter zur Adresseingabe"
                  en="continue with address entry"
                />
              </Step2Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const orderProductsEnhanced = getOrderProductsEnhanced(state);
  return {
    orderProducts: orderProductsEnhanced,
    showFreeShippingHint: isFreeShippingCountry(state),
    alternativeShippingAddress: getAlternativeShippingAddress(state),
  };
};

const Connected = connect(mapStateToProps)(Cart);

export default reduxForm({
  form: orderFormName,
  destroyOnUnmount: false,
  selectedVariantKey: 'grey',
  color1: 'weiss',
  color2: 'weiss',
  initialValues: {
    billingAddress: { country: 'de' },
    shippingAddress: { country: 'de' },
  },
})(Connected);
