export default {
  id: '3-25-blog',
  category: ['christmas'],

  name: {
    de: 'Billiard Adventskalender',
    en: 'Billard Advent Calendar ',
  },
  subtitle: {
    de: 'B6 x H6 x T6 cm',
    en: 'W6 x H6 x D6 cm',
  },
  meta_title: {
    de: 'Billiard Adventskalender aus Papier selber machen',
    en: 'How to make a billiard advent calendar from papier?',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für einen coolen Billiard Adventskalender kannst du 24 Papierkugeln basteln, befüllen und alle Billiard-Fans begeistern. ',
    en: 'With this template for a cool billiard advent calendar you can make 24 paper balls, fill them and delight all billiard fans.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  pdf: {
    description: {
      de: `Mit dieser Vorlage für einen coolen Billiard Adventskalender kannst du 24 Papierkugeln basteln, befüllen und alle Billiard-Fans begeistern. <a href="/de/blog/billard-adventskalender-basteln/">In diesem Blogpost</a> zeige ich dir, wie einfach das geht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² Tonpapier zu verwenden.
      Damit der Adventskalender gelingt, benötigst du einen Drucker, 8 Bögen Tonpapier, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this template for a cool billiard advent calendar you can make 24 paper balls, fill them and delight all billiard fans. <a href="/en/blog/billard-adventskalender-basteln/">In this blogpost</a> I show you how easy it is.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use 130g/m² paper.
      To make the advent calender succeed, you'll need a printer, 8 sheets of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'products/adventskalender-billiard-shop/billiard-weihnachtskalender-basteln-papier.jpg',
        en: 'products/adventskalender-billiard-shop/billiard-weihnachtskalender-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Selbst gebastelter Billiard-Adventskalender aus Fotokarton mit 24 Kugeln und Queue',
        en: 'Papercrafted billiard advent calendar from photo cardboard with 24 balls and cue',
      },
    },
  },
};
