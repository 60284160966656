import React from 'react';
import { selectedLang } from '../data/lang';
import env from '../env';
import { trackSocialButton } from './customga';

export default function PsSocialButtons({ post }) {
  return (
    <div className="social">
      <a
        href={createShareLink('facebook', post)}
        title="Die externe Seite Auf Facebook teilen im neuen Tab/Fenster öffnen"
        className="social-button facebook"
        rel="noopener noreferrer"
        onClick={trackSocialButton('facebook')}
        target="_blank">
        <svg width="18" height="18" viewBox="0 0 24 24">
          <path d="M9 8H6v4h3v12h5V12h3.642L18 8h-4V6.333C14 5.378 14.192 5 15.115 5H18V0h-3.808C10.596 0 9 1.583 9 4.615V8z" />
        </svg>
      </a>

      <a
        href={createShareLink('whatsapp', post)}
        title="Die externe Seite Auf WhatsApp teilen im neuen Tab/Fenster öffnen"
        className="social-button whatsapp"
        rel="noopener noreferrer"
        onClick={trackSocialButton('whatsapp')}
        target="_blank">
        <svg width="18" height="18" viewBox="0 0 24 24">
          <path d="M11.997 0C5.38 0 0 5.383 0 12c0 2.624.846 5.058 2.285 7.033L.79 23.492l4.612-1.474A11.905 11.905 0 0 0 12.003 24C18.62 24 24 18.617 24 12S18.619 0 12.003 0h-.006zM7.885 5.503a6.775 6.775 0 0 0-.402-.014c-.459 0-.938.134-1.227.43-.353.36-1.228 1.2-1.228 2.92 0 1.722 1.256 3.387 1.425 3.62.176.232 2.447 3.816 5.974 5.277 2.757 1.143 3.575 1.037 4.203.903.917-.198 2.067-.875 2.356-1.693.289-.819.289-1.517.204-1.666-.084-.148-.317-.232-.67-.409-.352-.176-2.066-1.023-2.39-1.136-.318-.12-.621-.077-.861.262-.339.472-.67.952-.938 1.241-.212.226-.557.254-.846.134-.388-.162-1.474-.543-2.815-1.735-1.036-.924-1.742-2.074-1.946-2.42-.205-.352-.021-.557.141-.748.176-.218.346-.373.522-.578.176-.205.275-.31.388-.55.12-.233.035-.473-.05-.65-.084-.176-.79-1.897-1.079-2.596-.232-.557-.409-.578-.761-.592z" />
        </svg>
      </a>

      <a
        href={createShareLink('pinterest', post)}
        title="Die externe Seite Auf Pinterest teilen im neuen Tab/Fenster öffnen"
        className="social-button pinterest"
        rel="noopener noreferrer"
        data-pin-custom="true"
        data-pin-do="buttonBookmark"
        onClick={trackSocialButton('pinterest')}
        target="_blank">
        <svg width="20" height="20" viewBox="0 0 28 28">
          <path d="M14.02 1.57c-7.06 0-12.784 5.723-12.784 12.785S6.96 27.14 14.02 27.14c7.062 0 12.786-5.725 12.786-12.785 0-7.06-5.724-12.785-12.785-12.785zm1.24 17.085c-1.16-.09-1.648-.666-2.558-1.22-.5 2.627-1.113 5.146-2.925 6.46-.56-3.972.822-6.952 1.462-10.117-1.094-1.84.13-5.545 2.437-4.632 2.837 1.123-2.458 6.842 1.1 7.557 3.71.744 5.226-6.44 2.924-8.775-3.324-3.374-9.677-.077-8.896 4.754.19 1.178 1.408 1.538.49 3.168-2.13-.472-2.764-2.15-2.683-4.388.132-3.662 3.292-6.227 6.46-6.582 4.008-.448 7.772 1.474 8.29 5.24.58 4.254-1.815 8.864-6.1 8.532v.003z" />
        </svg>
      </a>

      <a
        href={createShareLink('mail', post)}
        className="social-button mail"
        title="Die externe Seite Per E-Mail teilen im neuen Tab/Fenster öffnen"
        rel="noopener noreferrer"
        onClick={trackSocialButton('mail')}
        target="_blank">
        <svg width="18" height="18" viewBox="0 0 24 24">
          <path d="M21.386 2.614H2.614A2.345 2.345 0 0 0 .279 4.961l-.01 14.078a2.353 2.353 0 0 0 2.346 2.347h18.771a2.354 2.354 0 0 0 2.347-2.347V4.961a2.356 2.356 0 0 0-2.347-2.347zm0 4.694L12 13.174 2.614 7.308V4.961L12 10.827l9.386-5.866v2.347z" />
        </svg>
      </a>
    </div>
  );
}

function createShareLink(type, post) {
  switch (type) {
    case 'facebook':
      return `http://www.facebook.com/sharer.php?u=${createLink(type, post)}`;
    case 'whatsapp':
      return `whatsapp://send?text=${createLink(type, post)}`;
    case 'pinterest':
      return `http://pinterest.com/pin/create/button/?url=${createLink(
        type,
        post
      )}`;
    case 'mail':
      return `mailto:?subject=${encodeURIComponent(
        `${post.meta_title[selectedLang]}`
      )}&body=${createLink(type, post)}`;
    default:
      return '';
  }
}

function createLink(type, post) {
  return encodeURIComponent(
    `${env.CLIENT_HOST}${
      window.location.pathname
    }?utm_source=${type}&utm_medium=social-buttons&utm_campaign=${post.url}`
  );
}
