export default {
  url: 'diamant-basteln',
  countermark: 'vg07.met.vgwort.de/na/b7687b0ee8da46ab8e402d649eb83599',
  imageFolder: 'diamant',

  name: {
    de: 'Diamant',
    en: 'Diamand',
  },
  id: '3-09-blog',
  pdf: {
    price: 3.9,
    priceThirdCountry: 3.9,
    image: {
      filename: {
        de: 'content/diamant/papier-diamant-basteln.jpg',
        en: 'content/diamant/papier-diamant-basteln.jpg',
      },
      alt_text: {
        de: '3d Diamanten aus Papier basteln zur Diamantenhochzeit #papershape #bastelnmitpapier #diy ',
        en: 'How to make 3d diamands from paper #papercraft #crafting #diy',
      },
    },
  },

  meta_title: {
    de: 'DIY Diamant aus Papier basteln (auch als Geschenkverpackung)',
    en: 'How to make paper diamands',
  },
  meta_description_google: {
    de: 'Originelle Papier Diamanten basteln und als Deko oder Geschenkverpackung nutzen. Einfach Vorlage herunterladen und losbasteln.',
    en: 'Make these lovely paper diamands for your home decor, Christmas decoration or as a gift. Download the template and get started. ',
  },
  meta_description_social: {
    de: 'Originelle Papier Diamanten basteln und als Deko oder Geschenkverpackung nutzen. Einfahc Vorlage herunterladen und losbasteln.',
    en: 'Make these lovely paper diamands for your home decor, Christmas decoration or as a gift. Download the template and get started.',
  },
  meta_images_social: [
    {
      de: 'papier-diamant-basteln.jpg',
      en: 'papier-diamant-basteln.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'papier-diamant-basteln.jpg',
      en: 'papier-diamant-basteln.jpg',
    },
    alt_text: {
      de: 'DIY Diamant basteln aus Papier. Einfach Vorlage herunterladen #papercraft #papershape #diy #bastelnmitpapier',
      en: 'How to make paper diamands #papercraft #diy #crystal ',
    },
  },
  h1: {
    de: 'DIY Papier Diamanten basteln',
    en: 'How to make paper diamands',
  },
  description: {
    de: `
      <p>Es müssen nicht teure Diamanten sein, die eure Augen zum Funkeln bringen. 
      Heute stelle ich euch vor wie ihr echte Papierdiamanten ganz einfach selber falten könnt. Selbst diese DIY Papierkristalle kreieren einen so schönen Licht-Schatten-Effekt, dass sie dem Funkeln echter Diamanten im Nichts nachstehen - hehe. Diese DIY Papierkristalle eignen sich als Deko genauso gut wie als Geschenk. Denn aufgrund der Öffnung könnt ihr in diesem Papierkistall Kleinigkeiten verstecken. </p>
      <p>  Ihr könnt die Diamanten basteln und als schöne Tischdeko bei einer Diamantenhochzeit, als kleine Give-Aways auf Hochzeiten, als Tannenbaumschmuck oder
      als klassische Deko einsetzen und sie als Origami Diamanten ans Fenster hängen.  </p>
  
      <p>Größe: HBT 6 x 10 x 10 cm </p>
      
    
    `,
    en: `
    <p> Sparkle, sparkle ... even paper diamonds reflect light so beautifully that they turn out to be great decorations and gifts. </ p>
    <p> Today I want to show you how to make your paper crystals out of paper.
    You can tinker the diamond as a beautiful table decoration at a diamond wedding, as a small give-aways at weddings, as Christmas tree ornaments
    as a classic decoration and hang them as origami diamonds on the window. </ p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'DIY Diamant selber machen: Anleitung',
    en: 'Step by step: how to make 3d paper gems',
  },
  steps: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Ihr benötigt pro Diamant 1 DINA4 Papier. Verwendet am besten Tonpapier mit einer Grammatur von bis zu 230g/m².
        Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer
        Schere aus.
       
        `,
        en: `You need 1 page per diamant. 
          The best will be you use paper with a grammage of up to 230g/m². 
        Print out the template and cut out all solid lines with scissors.`,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Falzt mit einer Ahle oder einem Falzbein und einem Lineal alle gestrichelten Linien nach.`,
        en: `Crease all dashed lines with an awl or a bonefolder. Therefore, place a ruler next to the dashed lines
        and crease the lines by pressing the bonefolder down. 
      `,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: ` Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin.
        `,
        en: ` 
        Fold the short dashed lines up, i.e. up to you.`,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Klebt auf die Laschen der einzelnen Papierteile Klebepads. Wichtig ist, dass ihr das Pad auf die schöne Seite und damit die unbedruckte Seite der Lasche klebt. 
        Ihr beginnt in nummerischer Reihenfolge anschließend die einzelnen Teile zu verkleben, d.h. ihr startet bei 1 auf A und verklebt diese. Danach verklebt ihr Nr. 2 und so weiter. Eine Zahl existiert immer doppelt, sodass die Zahlenpaare
        immer verklebt werden müssen. Zum Schluss findet ihr einige Laschen ohne Nummern vor. Diese müsst ihr 
        lediglich stecken. Der Vorteil davon ist, dass ihr keine Probleme beim Verkleben der letzten Lasche habt, weil ihr
        nicht mehr drankommt und ihr zudem so eine schöne Geschenkverpackung kreiert habt.
  `,
        en: `Use double-sided tape or any glue onto the unprinted side of the flaps.
        I prefer to craft with adhesive tape because your fingers stay clean. Each number exists twice. Stick all the same numbers together in ascending order (1, 2, 3, ...). Start with section 1 and stick it to the section marked 1 on paper A, pressing firmly enough to make them stick together.`,
      },
    },
  ],

  downloadName: '',
  materials: [
    {
      de: '1 DINA4 Tonkarton (bis zu 130g/m²)',
      en: '1 colored construction paper (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    ],
  },
};
