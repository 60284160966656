import React from 'react';
import Email from '../../common/Email';
import PsDisableGa from '../../common/PsDisableGa';

export default () => (
  <div>
    <b>1. Personal Information</b>
    <br />
    <br />
    On our website personal data is collected, stored and used abiding the
    following guidelines. Personal data means for example e-mail-address or
    IP-address.
    <br />
    <br />
    Responsible for the collection, processing and use of personal data that is
    collected when visiting this website is
    <br />
    <br />
    PaperShape UG (haftungsbeschränkt) & Co. KG
    <br />
    Anstasia Baron
    <br /> Guttknechtstr. 23, 90547 Stein
    <br /> Deutschland
    <br /> Tel.: 0911 36069244
    <br /> Email: <Email />
    <br />
    <br />
    Our website uses SSL encryption to secure and protect the transfer of
    personal data and other sensitive content (such as orders or requests to the
    person in charge). The encryption connection can be recognized by https://
    in the URL bar.
    <br />
    <br />
    <b>2. Usage of data</b>
    <br />
    <br />
    <b>
      2.1.1 Data collection concerning your computer and website usage, in
      particular
    </b>
    <br />
    <br />
    - name of the requested file
    <br />
    - device in use (mobile device, PC/laptop)
    <br />
    - operating system
    <br />
    - browser in use
    <br />
    - java script activation
    <br />
    - cookie activation
    <br />
    - referral site
    <br />
    - IP address
    <br />
    - length of visit
    <br />
    - number of pages visited <br />
    - click path
    <br />
    <br />
    Data as listed is collected in a personally unidentifiable way that makes
    tracing impossible.We collect this information exclusively to control and
    admister our website and to improve our offering. These purposes are
    legitimate interests in accordance to Article 6 (1) (f) of the General Data
    Protection Regulation (GDPR).
    <br />
    <br />
    You can withdraw to the use of data any time by contacting us. Please note
    that in this case the usage of the website may be limited.
    <br />
    <br />
    <b> 2.2. Data when placing an order</b>
    <br />
    <br />
    According to Art. 6 Abs. 1 lit. b DSGVO we collect and store personal, if a
    user places an order on our website
    <br />
    This kind of data comprises
    <br />
    <br />
    - full name
    <br />
    - country of residence
    <br />
    - full address
    <br />
    - email-address
    <br />
    <br />
    This kind of data is stored and used for the fulfillment of the contract.{' '}
    <br />
    <br />
    The email-address will not be passed on to third parties, particularly not
    for the sake of advertising mail. <br />
    Data in general will not be transmitted to third parties. Data in general
    will not be transmitted to third parties. This does not apply to our service
    partners, which we use for processing the contract.
    <br />
    <br />
    <b>2.3. Data processing for order handling </b>
    <br />
    <br />
    To process your order we work with the following service partners who assist
    us in the execution of the contracts.
    <br />
    <br />
    2.3.1. We ship our goods with external shipping partners (DHL, Deutsche
    Post) to fulfill our contractual obligations. We only give your name and
    delivery address for the purpose of the delivery of the ordered goods to the
    shipping partners.
    <br />
    <br />
    2.3.2. In case of paying via PayPal, credit card via PayPal or direct debit
    via PayPal, we will transfer your payment data to Paypal (Europe) S.a.r.l.
    et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (hereinafter
    referred to as "PayPal"). The disclosure is made in accordance with Art. 6
    para. 1 lit. b DSGVO and only insofar as this is necessary for the payment
    process. All PayPal transactions are subject to the PayPal Privacy Policy.
    These can be found{' '}
    <a
      href="
    https://www.paypal.com/de/webapps/mpp/ua/privacy-prev?locale.x=de_DE"
    >
      here.
    </a>{' '}
    <br />
    <br />
    2.3.3. We use an external merchandise management system for contract
    handling. For this we transfer your collected personal data to Billbee GmbH,
    Paulinenstrasse 54, 32756 Detmold, Germany.
    <br />
    <br />
    <b id="kontaktaufnahme"> 3. Contact support </b>
    <br />
    <br />
    For contacting us via contact form or e-mail personal data is collected
    (message text, e-mail). These data are stored and used solely for the
    purpose of answering your request or for establishing contact. Processing is
    based on Art. 6 (1) lit. a DSGVO with your consent.
    <br />
    <br />
    <b>4. Cookies</b>
    <br />
    <br />
    Our site uses cookies. Cookies are small text files that are generated by a
    web server and stored by the web browser on your computer during an online
    visit.
    <br />
    <br />
    There are session cookies that are automatically deleted after your visit to
    our website. A persistent cookie remains until a set expiry date.
    <br />
    <br />
    Cookies do not store traceable personal data.
    <br />
    <br />
    You can set your browser so that you will be asked prior to the activation
    of a cookie or prevent the activation of cookies generally.
    <br />
    <br />
    Note that in this case the usage of many websites may be limited.
    <br />
    <br />
    4.1.1. Use of Google Analytics
    <br />
    <br />
    Our site uses the Google Analytics cookie. Google Analytics is a service of
    Google, Inc. 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA fort
    the purpose of statistical website usage. Collected data is transmitted to
    Google servers in the U.S. Data is non traceable and does not contain
    information that allows the identification of individuals. Google Analytics
    uses the collected data for surveys and reports on website usage.
    <br />
    <br />
    Google is certified under the Privacy Shield Agreement and offers a
    guarantee to comply with European privacy legislation
    (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).
    <br />
    <br />
    We only use Google Analytics with activated IP anonymization. This means
    that the IP address of the users will be shortened within member states of
    the EU or in other contracting states of the Agreement on the European
    Economic Area
    <br />
    <br />
    To keep the Google Analytics cookie from gathering the data mentioned it is
    possible to install a browser extension from
    https://tools.google.com/dlpage/gaoptout?hl=en-GB or <PsDisableGa />.
    <br />
    <br />
    4.2. Use of Google Adwords Remarketing
    <br />
    <br />
    We use Google Remarketing. Google Remarketing is showing users based on
    earlier interest in our products additional advertisement of our products
    within the Google search network. For this purpose a cookie is stored on the
    user´s device.
    <br />
    <br />
    Data processing is based on Art. 6 (1) lit. f DSGVO of legitimate interest
    in addressing visitors of the website with targeted ads by showing
    personalized and interest-based advertising when visiting other websites of
    the Google Display Network.
    <br />
    <br />
    You have the right to revoke at any time from the processing of personal
    data, based on Art. 6 (1) f GDPR.
    <br />
    <br />
    Settings for Google Remarkting can be changed
    <a href="https://www.google.com/settings/u/0/ads/authenticated"> here </a>.
    <br />
    <br />
    For information about Google Remarketing and its privacy policy, please
    visit: https://policies.google.com/technologies/ads?hl=en
    <br />
    <br />
    <b>5. Information about Social Plugins</b>
    <br />
    <br />
    5.1.1. Our site uses Social Plugins provided by Facebook (Facebook Inc.,
    1601 S. California Ave, Palo Alto, CA 94304, USA) and Pinterest (Pinterest
    Inc., 635 High Street, Palo Alto, CA, 94301, USA).
    <br />
    <br />
    If you are logged in to one of the social networks, a connection between
    your account and the network is established, by which the information of
    visiting our site can be transmitted to the respective network which you are
    logged in to.
    <br />
    When pressing the Facebook Share button other users of these networks see
    which sites you recommend.
    <br />
    <br />
    In order to prevent social networks from receiving and collection your data,
    you should log out of the networks.
    <br />
    <br />
    Facebook Inc., based in US, is certified to the US Privacy Shield, which
    ensures compliance with the level of data protection in the EU.
    <br />
    <br />
    More information about the collection and use of data by social networks can
    be found at
    <br />
    <br />
    http://www.facebook.com/about/privacy/
    <br />
    http://about.pinterest.com/de/privacy-policy
    <br />
    <br />
    5.1.2. This site uses the embedding function of Youtube for displaying and
    playing videos from "Youtube", which is owned by Google LLC, 1600
    Amphitheater Parkway, Mountain View, CA 94043, USA ("Google"). We use the
    extended data protection mode, which, according to the provider, sets up
    storage of user information only when the video(s) is playing.
    <br />
    <br />
    US-based Google LLC is certified under the US Privacy Shield, which ensures
    compliance with the level of data protection in the EU.
    <br />
    <br />
    For more information on data protection at "YouTube", please see the
    provider's privacy policy at:
    https://www.google.com/intl/en/policies/privacy
    <br />
    <br />
    <b>6. Facebook Custom Audience</b>
    <br />
    <br />
    The website uses Facebook Custom Audience, a service of Facebook (Facebook
    Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA). By means of a
    tracking pixel on our website advertisement of our products will be
    displayed for the user when visiting facebook.
    <br />
    <br />
    Data processing is based on Art. 6 (1) lit. f GDPR due to our legitimate
    interest in showing personalized, interest-related ads to the user.
    <br />
    <br />
    Settings for Facebook Custom Audience can be changed here:
    https://www.facebook.com/ads/website_custom_audiences/
    <br />
    <br />
    <b id="newsletter">7. Newsletter subscription</b>
    <br />
    <br />
    If you subscribe to our e-mail newsletter, we will send you regularly
    information about our free DIY ideas and about our promotional products and
    offers.
    <br />
    <br />
    In order to sign up for the newsletter, you only have to enter your e-mail
    address. Other data such as the first name are optional and are only
    intended to address you personally.
    <br />
    <br />
    For sending the newsletter, we use the Double Opt-in procedure. This means
    that we send you a confirmation email, which you must confirm with a link
    click and thus agree to receive further emails.
    <br />
    <br />
    By activating the confirmation link, you give us your consent to the use of
    your personal data in accordance with Art. 6 para. 1 lit. a GDPR.
    <br />
    <br />
    When signing up for the newsletter, your IP address and the login and
    confirmation time will be stored. We do so in order retrace a possible
    misuse of your e-mail address at a later date. Newsletter subscribers may
    unsubscribe at any time. Please click the link in the newsletter email or
    contact us.
    <br />
    <br />
    <b>7.1.1. Newsletter dispatch via Brevo </b>
    <br />
    <br />
    For Email-Marketing and Newsletters we use Brevo GmbH, Köpenicker Straße
    126, 10179 Berlin, Germany.
    <br />
    <br />
    When you sign up for our newsletter, the data you specify in the
    registration is transferred to and stored by the operator of the service.
    The transmission of your data to Brevo takes place in accordance with Art. 6
    para. 1 lit. f DSGVO and serves our legitimate interest in the use of a
    promotional, secure and user-friendly newsletter system.
    <br />
    <br />
    The newsletters contain a pixel-sized file, which is retrieved from the
    Brevo server when the newsletter is opened. This will determine if the
    newsletter has been opened and which links have been clicked. Brevo allows a
    group-based analysis on the behavior towards sent emails within the
    recipient list (e.g. unsubscription of the newsletter, deletion of the
    newsletter, etc.) This information is only used to manage our newsletter
    system and for statistical reasons. We do no kind of analysis based on
    individual emails.
    <br />
    <br />
    We have completed a data processing agreement with Brevo to allow the
    transfer of your personal information to Brevo . This is a contract in which
    Brevo pledges to protect the data of our users, to process it in accordance
    with its privacy policy on our behalf and, in particular, not to disclose it
    to third parties. For more information about Brevo and for Brevo s privacy
    policy please refer to: https://de.Brevo .com/legal/privacypolicy/
    <br />
    <br />
    {/* 7. Live Chat <br />
    <br />
    This site is using Zopim, a service of Zopim Technologies Pte Ltd (401
    Commonwealth Drive, Haw Par Technocentre, #07-01, Singapore 149598). Zopim
    is a live chat tool that enables visitors to interact with the website´s
    agents in real time. The visitor´s inputs are transferred to and stored by
    the operator of the service. The service also tracks the visitors IP Adress,
    Operating System and Browser. The data is used only for solving the
    visitor’s inquiries. There will be no analysis based on individual tracking.<br />*/}
    <br />
    <br />
    <b>8. Links to third party websites</b>
    <br />
    <br />
    Our site links to third party websites.
    <br />
    <br />
    We reject any liability for the gathering and use of private data on these
    websites.
    <br />
    <br />
    <b>9. Storing Data</b>
    <br />
    <br />
    Personal Data will be stored as long as necessary to fulfill contractual
    obligations. Legal provisions, e.g. for keeping bills, may require longer
    storage periods.
    <br />
    <br />
    Non-personal identifiable data in aggregate reports may be stored for up to
    five years.
    <br />
    <br />
    <b> 10. Rights of the data subjects </b>
    <br />
    <br />
    Art. 15 GDPR: You are entitled at any time to request information about data
    that is stored about you.
    <br />
    <br />
    Art. 16 GDPR: You have the right to correct incorrect data or complete
    incorrect stored data about you.
    <br />
    <br />
    You have the right to demand the immediate erasure of personal data
    according to Art. 17 GDPR.
    <br />
    <br />
    You have the right of data portability according to 20 GDPR.
    <br />
    <br />
    According to Art. 77 GDPR you also have the right to complain to a
    regulatory agency about the processing of data.
    <br />
    <br />
    According to Art. 7 (3) GDPR, you have the right to revoke a previously
    granted consent to the data processing at any time.
    <br />
    <br />
    <b>
      Consent into data processing may be withdrawn at any time according to
      Art. 21 GDPR. Also consent into receiving newsletters and other commercial
      communications may be withdrawn at any time.
    </b>
    <br />
    <br />
    25-05-2018
    {/*10. Disclosure of data<br />
    <br />
    Personal data may only be disclosed due to one of the following reasons:<br />
    <br />
    a) fulfillment of the contract<br />
    b) legal obligation<br />
    <br />
    <br />
    <br />
    <br />
    11. User Rights<br />
    <br />
    You are entitled at any time to request information about data that is
    stored about you.<br />
    Requests are subject to reliable evidence for your identification such as a
    photocopy of your ID or passports.<br />
    <br />
    Consent into receiving newsletters and other commercial communications may
    be withdrawn at any time.<br />
    <br />
    <br />
    12. Contact<br />
    <br />
    For requests and information you can contact us <br />
    <br />
    <br />
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG<br />
    Guttknechtstr. 23<br />
    90547 Stein<br />
    Germany<br />
    <br />
    E-mail address: <Email />
    <br />
    Phone nr.: +49 911 36069244<br />*/}
  </div>
);
