import React from 'react';
import { connect } from 'react-redux';
import { getGlobalMessages } from '../state/fetch/fetchSelectors';
import Message from './Message';

/**
 * @return {null}
 */
function GlobalMessages({ messages }) {
  return messages && messages.length > 0 ? (
    <div>
      {messages.map((message) => (
        <Message key={message.key} message={message} />
      ))}
    </div>
  ) : null;
}

const mapStateToProps = (state) => {
  return {
    messages: getGlobalMessages(state),
  };
};

export default connect(mapStateToProps)(GlobalMessages);
