export default {
  id: '3-17-blog',
  category: ['paper_flowers'],

  name: {
    de: 'Rose',
    en: 'Rose',
  },
  subtitle: {
    de: 'B13 x H13 x T6 cm',
    en: 'W13 x H13 x D6 cm',
  },
  meta_title: {
    de: 'Rose aus Papier Vorlage',
    en: 'Rose from Paper Template ',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für sommerliche Rosen kannst du etliche kreative Ideen umsetzen. Wie wäre es mit einer schönen Geschenkverpackung?',
    en: 'With this template for summer roses you can implement quite a few creative ideas. How about a beautiful gift wrapping?',
  },
  meta_description_social: {
    de: 'Mit dieser Vorlage für knallige Gänseblümchen kannst du etliche kreative Ideen umsetzen. Wie wäre es mit einem wundervollen Blumenstrauß für dein Zuhause?',
    en: 'With this template for summer roses you can implement quite a few creative ideas. How about a beautiful gift wrapping?',
  },

  pdf: {
    description: {
      de: `Mit dieser digitalen Vorlage für sommerliche Rosen kannst du etliche kreative Ideen umsetzen. In diesem Blogpost zeige ich dir anhand von <a href="/de/blog/rosen-basteln/">zwei nachhaltigen Geschenkideen</a>, wie einfach das geht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² Tonpapier zu verwenden.
      
      Damit die Rosen gelingen, benötigst du einen Drucker, 1 Bogen Tonpapier, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this digital template for our summer roses you can realize a lot of creative ideas. <a href="/en/blog/rosen-basteln/">In this blogpost</a> I show you how easy it is.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use 130g/m² paper.
      To make the daisies succeed, you'll need a printer, 1 sheet of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'products/sfkrose/rose-papershape.jpg',
        en: 'products/sfkrose/rose-papershape.jpg',
      },
      alt_text: {
        de: 'Zwei selbst gebastelte rote Rosen aus Papier auf rosa Hintergrund',
        en: 'Two papercrafted red roses on a blush background',
      },
    },
  },
};
