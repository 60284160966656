export default {
  url: 'eicheln-papier-herbstdeko',
  countermark: 'vg07.met.vgwort.de/na/be03820b9adf490991d1681e6eeb86c1',
  imageFolder: 'eicheln',
  meta_title: {
    de: 'DIY Eicheln basteln mit Papier (kostenlose Vorlage) ',
    en: 'How to make acorns from paper (free template)',
  },
  meta_description_google: {
    de:
      'Dekoriere deine Wohnung zum Herbst mit diesen modernem Eicheln aus Papier. Mit der Vorlage von PaperShape kannst du sie ganz einfach basteln.',
    en:
      'Decorate your flat with these cute acorns from paper. With the free template from papershape it is super easy. ',
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  meta_images_social: [
    {
      de: 'eicheln-papier-basteln-herbstdeko.jpg',
      en: 'eicheln-papier-basteln-herbstdeko.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'eicheln-papier-basteln-herbstdeko.jpg',
      en: 'acorns-craft-paper.jpg',
    },
    alt_text: {
      de:
        'Eicheln mit Papier als coole Herbstdeko basteln und dekorieren. PaperShape #diy #papershape #herbstdeko',
      en:
        'diy acorn crafts from paper for fall - free template from papershape #papershape #diy',
    },
  },
  h1: {
    de: 'DIY Herbstdeko: Eicheln basteln mit Papier (kostenlose Vorlage)',
    en: 'How to make acorns from paper (free template)',
  },
  description: {
    de: `
    
     <p> Wenn das Ergebnis ein anderes als die Inspiration ist:</p>
      Diese DIY Eicheln aus Papier sollten ursprünglich ganz coole Geschenkverpackungen werden. Während des Entstehungsprozesses fand ich sie aber
      in klein so süß, dass ich gar nicht mehr an Verpackungen denken wollte.
      <p><p> Als moderne Herbstdeko lassen sie sich sehr vielseitig einsetzen: auf dem Tisch verteilt, als Dekoration für den Herbstkranz oder als Girlande aufgehängt. Auch sind sie als kleine
      Geschenkeanhänger ganz süß.</p>
      Ihr könnt sie auch passend zum <a href="/de/blog/3d-kuerbis-basteln/">3D Kürbis aus Papier</a> dekorieren. 
      
    
    `,
    en: `
      <p>
      You have to try this fall diy:
      </p>
      <p>
     These cute acorns are super simple to make and so lovely as a table decor or as a garnish for your fall wreath. You can even hang it as gift tag to your presents. If you are into paper as I am then you have to 
     make this <a href="/en/blog/3d-kuerbis-basteln/">3d pumpkin from paper</a>, too. How about decorate your flat for fall with paper diys this year?
      </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'herbstdeko-eicheln-basteln-papier.jpg',
          en: 'diy-acorns-fall-paper.jpg',
        },
        alt_text: {
          de:
            'DIY Eicheln basteln für die Tür oder den Tisch / PaperShape #diy #papershape',
          en:
            'cute acorn craft idea from paper for your fall decoration - free template for adults from papershape #papershape #diy',
        },
      },
    },
  ],
  stepsBackgroundColor: 'rgb(243, 243, 243)',
  stepsTitle: {
    de: 'Wie bastel ich Eicheln aus Papier als Herbstdeko - Anleitung',
    en: 'How do I make acorns from paper',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '01_schneiden.mp4',
      text: {
        de: `Ihr benötigt zwei unterschiedliche Mocca - bzw. Brauntöne für die Eicheln. Verwendet am besten Tonpapier mit 
        einer Grammatur von 120g/m² und druckt euch die Vorlage aus. Auf der Bastelvorlage findet ihr 3 Eicheln. Möchtet ihr sie zweifarbig
        gestalten, so müsst ihr die Seite zwei mal in der jeweils anderen Farbe ausdrucken. Schneidet nun alle durchgezogenen Linien mit einer
        Schere aus (auch die Zwischenbereiche).
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '02_falzen.jpg',
          en: '02_falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_falzen-2.mp4',
      text: {
        de: `Falzt mit einer Ahle und einem Lineal alle gestrichelten Linien vor. Dazu das Lineal an die Linie anlegen und mit der Ahle mit Druck
        nachfahren.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '03_falten.jpg',
          en: '03_falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '03_falten-2.mp4',
      text: {
        de: `Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin. Faltet die lang gestrichelten Linien von euch weg, d.h. nach unten.
        Nun sollte die Eichel wie ein runder Fächer von oben aussehen.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '04_kleben.jpg',
          en: '04_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '04_kleben-2.mp4',
      text: {
        de: `Verklebt die Laschen des Hütchens der Eicheln mit Klebepads oder normalen Kleber, sodass die Laschen nach innen zeigen und von außen 
        nicht zu sehen sind.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '05_stecken.jpg',
          en: '05_stecken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '05_stecken-2.mp4',
      text: {
        de: `Im letzten Schritt setzt ihr nun das Hütchen auf den unteren Teil der Eichel. Wer mag kann noch kleine Papierstreifen in das Hütchen als 
        Stiel setzen. Dazu mit einem Cuttermesser einen kleinen Schlitz in das Hütchen schneiden und den Papierstreifen durchziehen. Voilá, viel Spaß
        mit diesen süßen Eicheln.`,
        en: ``,
      },
    },
  ],

  downloadName: 'eicheln_acorns_papershape.pdf',
  materials: [
    {
      de: '2 DINA4 Papier in 2 unterschiedlichen Brauntönen (120g/m²)',
      en:
        '2 differently colored papers in brown (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'eicheln-basteln-papier-herbstdeko-kinder.jpg',
          en: 'acorns-paper-fall-decoration.jpg',
        },
        alt_text: {
          de:
            "DIY Eicheln aus Papier als coole Herbstdeko / fall basteln und dekorieren (kostenlose Vorlage). PaperShape #diy #papershape #herbstdeko'",
          en:
            'cute acorn craft idea from paper for your fall decoration - free template for adults from papershape #papershape #diy',
        },
      },
    ],
  },
};
