import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import PsBlogPostListThumbnails from './common/PsBlogPostListThumbnails';
import blogPosts from './data/blogPosts';
import BlogPost from './pages/blog/BlogPost';
import BlogStart from './pages/BlogStart';
import NewsletterSuccessful from './pages/NewsletterSuccessful';
import PaymentPrePayment from './pages/order/PaymentPrePayment';
import Start from './pages/Start';
import Product from './pages/Product/Product';
import AboutMe from './pages/AboutMe';
import Faq from './pages/Faq';
import Press from './pages/Press';
import Imprint from './pages/Imprint';
import ContactForm from './pages/ContactForm';
import Cart from './pages/cart/Cart';
import OrderStep2 from './pages/order/OrderStep2';
import OrderSuccessful from './pages/order/OrderSuccessful';
import PaymentCanceled from './pages/order/PaymentCanceled';
import PaymentSuccessful from './pages/order/PaymentSuccessful';
import Dataprivacy from './pages/dataprivacy/Dataprivacy';
import TermsConditions from './pages/termsconditions/TermsConditions';
import { Navigation } from './common/Navigation';
import { Footer } from './common/Footer';
import ScrollToTop from './common/ScrollToTop';
import './styles/bootstrap.css';
import './styles/languages.min.css';
import PaymentStart from './pages/order/PaymentStart';
import PsLoadingSpinner from './common/PsLoadingSpinner';
import VideosStecken from './pages/VideosStecken';
import VideoKleben from './pages/VideoKleben';
import NotFound from './pages/NotFound';
import CookiesInfo from './common/CookiesInfo';
import Diygeschenke from './pages/landingpages/Diygeschenke';

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <div>
            <Route path="/:lang(de|en|)" component={Navigation} />
            <Route
              path={'/:lang(de|en)/blog/:name'}
              render={() => <PsBlogPostListThumbnails />}
            />
            <Switch>
              <Route
                path="/de/"
                exact={true}
                render={() => <Redirect to={'/'} />}
              />
              <Route path="/" exact={true} component={Start} />
              <Route path="/:lang(de|en)" exact={true} component={Start} />
              <Route path="/:lang(de|en)/product/:id" component={Product} />
              <Route path="/:lang(de|en)/aboutme" component={AboutMe} />
              <Route
                path="/faq"
                exact={true}
                render={() => <Redirect to={'/de/faq'} />}
              />
              <Route path="/:lang(de|en)/faq" exact={true} component={Faq} />

              <Route
                path="/faq/videos"
                exact={true}
                render={() => <Redirect to={'/de/faq/videos'} />}
              />
              <Route
                path="/:lang(de|en)/faq/videos"
                component={VideosStecken}
              />
              <Route
                path="/video"
                exact={true}
                render={() => <Redirect to={'/de/video'} />}
              />
              <Route path="/:lang(de|en)/video" component={VideoKleben} />

              <Route path="/:lang(de|en)/press" component={Press} />
              <Route path="/:lang(de|en)/imprint" component={Imprint} />
              <Route path="/:lang(de|en)/dataprivacy" component={Dataprivacy} />
              <Route
                path="/:lang(de|en)/termsconditions"
                component={TermsConditions}
              />
              <Route path="/:lang(de|en)/contact" component={ContactForm} />
              <Route path="/:lang(de|en)/cart" component={Cart} />
              <Route path="/:lang(de|en)/orderstep2" component={OrderStep2} />
              <Route
                path="/:lang(de|en)/ordersuccessful"
                component={OrderSuccessful}
              />
              <Route
                path="/:lang(de|en)/paymentstart/:orderNumber"
                component={PaymentStart}
              />
              <Route
                path="/:lang(de|en)/prepayment"
                component={PaymentPrePayment}
              />
              <Route
                path="/:lang(de|en)/paymentcanceled"
                component={PaymentCanceled}
              />
              <Route
                path="/:lang(de|en)/paymentsuccessful"
                component={PaymentSuccessful}
              />

              <Route
                path="/:lang(de|en)/blog"
                exact={true}
                component={BlogStart}
              />

              <Route
                path="/:lang(de|en)/newslettersuccessful"
                exact={true}
                component={NewsletterSuccessful}
              />

              <Route
                path="/:lang(de|en)/diy-geschenke"
                exact={true}
                component={Diygeschenke}
              />
              {blogPosts.map((blogPost) => (
                <Route
                  key={blogPost.url}
                  path={'/:lang(de|en)/blog/' + blogPost.url}
                  render={(props) => <BlogPost post={blogPost} {...props} />}
                />
              ))}

              <Route component={NotFound} />
            </Switch>
            <Route path="/:lang(de|en|)" component={Footer} />
            <Route path="/:lang(de|en|)" component={CookiesInfo} />
            <PsLoadingSpinner />
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
