export default {
  id: '3-16-blog',
  category: ['paper_flowers'],

  name: {
    de: 'Tulpe',
    en: 'Tulip',
  },
  subtitle: {
    de: 'B6 x H6 x T8 cm',
    en: 'W6 x H6 x D8 cm',
  },
  meta_title: {
    de: 'Tulpe aus Papier Vorlage',
    en: 'Tulip from Paper Template',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für farbenfrohe Tulpen kannst du etliche kreative Ideen umsetzen. Wie wäre es mit diesem schönen Blumenstrauß für dein Zuhause?',
    en: 'With this template for colourful tulips you can realize quite a few creative ideas. How about a wonderful bouquet of tulips for your home?',
  },
  meta_description_social: {
    de: 'Mit dieser Vorlage für farbenfrohe Tulpen kannst du allerhand kreative Ideen umsetzen. Wie wäre es mit einem wundervollen Blumenstrauß für dein Zuhause?',
    en: 'With this template for colourful tulips you can realize quite a few creative ideas. How about a wonderful bouquet of tulips for your home?',
  },

  pdf: {
    description: {
      de: `Mit dieser digitalen Vorlage für farbenfrohe Tulpen kannst du etliche kreative Ideen umsetzen. <a href="/de/blog/papier-tulpen-basteln/">Hier</a> zeige ich dir, wie einfach das geht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² Tonpapier zu verwenden.
      Damit die Tulpen gelingen, benötigst du einen Drucker, 1 Bogen Tonpapier, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunden.`,

      en: `With this digital template for our spring daisies you can realize a lot of creative ideas. <a href="/en/blog/papier-tulpen-basteln/">Here</a> I show you how easy it is.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use 130g/m² paper.
      To make the tulips succeed, you'll need a printer, 1 sheet of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'products/sfktulpe/tulpe-papershape.jpg',
        en: 'products/sfktulpe/tulpe-papershape.jpg',
      },
      alt_text: {
        de: 'Bunte selbst gebastelte Tulpen aus Papier',
        en: 'Colourful tulips from paper',
      },
    },
  },
};
