export default {
  url: 'diy-geschenkbox-praline',
  countermark: 'vg07.met.vgwort.de/na/b64752f032b84bdba32c52d168d8214a',
  imageFolder: 'geschenkbox-praline',

  name: {
    de: 'Süße Pralinen Geschenkbox',
    en: 'Sweet chocolate gift box',
  },
  id: '3-06-blog',
  pdf: {
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'content/geschenkbox-praline/diy-geschenkbox-praline-basteln.jpg',
        en: 'content/geschenkbox-praline/diy-geschenkbox-praline-basteln.jpg',
      },
      alt_text: {
        de: 'DIY Geschenkbox als süße Papier Praline basteln #bastelnmitpapier #papercraft #diy',
        en: 'Learn how to make a sweet chocolate box from paper #papercraft #diy #papervase',
      },
    },
  },

  meta_title: {
    de: 'DIY Geschenkbox als xxl Praline basteln',
    en: 'How to make an xxl chocolate box from paper',
  },
  meta_description_google: {
    de: 'Ihr benötigt eine Geschenkbox für eure Freundin, zum Geburtstag oder zu Weihnachten? Mit dieser Anleitung bastest du eine schöne Pralinenbox in 4 schnellen Schritten.',
    en: 'In need for a gift box for your girl-friend, birthday party or Christmas? Make this lovely xxl  gift box in four easy steps with our downloadable template. ',
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  meta_images_social: [
    {
      de: 'diy-geschenkbox-praline-basteln.jpg',
      en: 'diy-geschenkbox-praline-basteln.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'diy-geschenkbox-praline-basteln.jpg',
      en: 'diy-geschenkbox-praline-basteln.jpg',
    },
    alt_text: {
      de: 'DIY Geschenkboxen als Papier Pralinen basteln #papercraft #diy #bastelnmitpapier #geschenkverpackung',
      en: 'How to make a chocolate gift box #papercraft #diy #giftbox #giftbox #giftwrapping',
    },
  },
  h1: {
    de: 'DIY Geschenkbox als süße Praline basteln',
    en: 'How to make a sweet chocolate gift box (huge one)',
  },
  description: {
    de: `
      <p>Für eure nächste Feier (Hochzeit, runder Geburtstag, Valentinstag) sucht ihr noch nach der passenden Tischdeko oder Geschenkverpackung?
        </p>
      <p>Wie wäre es mit dieser XXL Praline aus Papier, die genügend Platz für kleine Geschenke für den Liebsten oder die Gäste bietet?  </p>
      <p>Die Praline ist super einfach selber zu machen und mit einer Höhe von 6cm und einer Breite und Tiefe von 9,6cm bietet sie richtig viel Platz. </p>
     
      <p>Wer sie kleiner mag, druckt sie sich einfach nur auf 50% oder noch kleiner aus. Besonders gut gefällt mir
      das Tütü um die Praline, die euren Beschenkten bestimmt ein Schmunzeln aufs Gesicht zaubert.</p>
      
      Wer lieber andere Formen bevorzugt, der findet hier zwei Geschenkbox-Alternativen:
      <a href="/de/blog/herzschachtel-basteln">Herzschachtel selber machen</a> oder 
      <a href="/de/blog/geschenkbox-basteln">schöne stabile Geschenkboxen</a>

    `,
    en: `
    <p> You are still looking for a table decoration or gift packaging for the next 
    celebration (wedding, round birthday, Valentine's Day) ?
    </ p>
    <p> How about this XXL chocolates? </ p>
    <p> This praline is super easy to make and with a height of 6cm and a width and depth of 9.6cm it gives you 
    lots of space for your treats.</ p>
    
    <p> If you like it smaller, just print it at 50% or even smaller. I especially like the Tütü to the praline,
     which will certainly give your recipient a smile on his face. </ p>
    
    <p>Those of you who prefer other shapes, find here two gift box alternatives:
    <a href="/en/blog/herzschachtel-basteln">Heart boxes</a> or
    <a href="/en/blog/geschenkbox-basteln"> beautiful square-shaped gift boxes </a></p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'Anleitung: DIY Geschenkbox als Praline',
    en: 'Step by step: how to make a bon bon box from paper',
  },
  steps: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Ihr benötigt pro Praline 2 DINA4 Seiten. Eine Farbe für die Pralinenfarbe und eine für das Tütü.
        Verwendet am besten Tonpapier mit einer Grammatur von bis zu 230g/m².
        Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer
        Schere aus. Beachtet insbesondere den Hinweis auf Seite 2 für das Papierteil F.
       
        `,
        en: `You need 2 pages per praline. 
        One color for the praline color and one for bottom.
        The best will be you use paper with a grammage of up to 230g/m². 
        Print out the template and cut out all solid lines with scissors. Pay particular attention to the note on page 2 for the paper part F.`,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Falzt mit einer Ahle oder einem Falzbein und einem Lineal alle gestrichelten Linien (egal ob lang oder kurz gestrichelt) vor. Dazu das Lineal an die Linie anlegen und mit der Ahle mit Druck
        nachfahren.`,
        en: `Crease all dashed lines with an awl or a bonefolder. Therefore, place a ruler next to the dashed lines
        and crease the lines by pressing the bonefolder down. 
      `,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: ` Faltet die lang gestrichelten Linien von euch weg, d.h. nach unten. Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin.
        `,
        en: `Fold the long dashed lines away from you, i.e. downward. 
        Fold the short dashed lines up, i.e. up to you.`,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Klebt auf die Laschen der einzelnen Papierteile Klebepads. Wichtig ist, dass ihr das Pad auf die schöne Seite und damit die unbedruckte Seite der Lasche klebt. 
        Ihr beginnt in nummerischer Reihenfolge anschließend die einzelnen Teile zu verkleben, d.h. ihr startet bei 1 auf A und B und verklebt diese. Danach verklebt ihr Nr. 2 und so weiter. Eine Zahl existiert immer doppelt, sodass die Zahlenpaare
        immer verklebt werden müssen.
  `,
        en: `Use double-sided tape or any glue onto the unprinted side of the flaps.
        I prefer to craft with adhesive tape because your fingers stay clean. Each number exists twice. Stick all the same numbers together in ascending order (1, 2, 3, ...). Start with section 1 and stick it to the section marked 1 on paper A and B, pressing firmly enough to make them stick together.`,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Als i-Tüpfelchen schneidet nun schmale Streifen aus eurem restlichen Tonkarton oder verwendet Papier
        in einer anderen Farbe. Hier könnt ihr gerne auch 90g-Papier verwenden, weil es sich einfacher biegen lässt.
        Rollt diesen Streifen nun in eurer gewünschten Form auf und klebt es als Topping
        auf die Praline.
        `,
        en: `Finally, cut thin stripes from your remaining cardboard or use paper
        in another colour. Here you can also use 90g paper, because it is easier to bend.
        Roll this stripe now in your desired shape and stick it as a topping
        on the praline.`,
      },
    },
  ],

  downloadName: 'praline-template_papershape.pdf',
  materials: [
    {
      de: 'Vorlage herunterladen (siehe unten)',
      en: 'Donwload template (scroll down)',
    },
    {
      de: '2 DINA4 Papier (bis zu 230g/m²)',
      en: '2 colored papers (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    ],
  },
};
