import materials from './materials';

export default {
  url: 'wanddeko-tangram-kaktus',
  countermark: 'vg07.met.vgwort.de/na/c751fb7ee3ab4ff09f8a2b89581c5d30',
  imageFolder: 'tangram',
  meta_title: {
    de: 'Coole Wanddeko DIY aus Papier: Kaktus Tangram',
    en: 'DIY Wall decor from paper: tangram cactus',
  },
  meta_description_google: {
    de:
      'Gedruckte Poster sind schön, aber eben auch 2d. Dieser Tangram Kaktus ist ein cooles Wanddeko DIY aus Papier, das du schnell selber machen kannst.',
    en:
      'Printed posters are pretty cool, but 2d. With this tangram cactus you create your own cool 3d wall decor from paper. Super easy and fun.',
  },
  meta_description_social: {
    de:
      'Coole Wanddeko DIY aus Papier: Tangram Kaktus in 3D / PaperShape #papershape #wandgestaltung #wanddeko #diy #papier ',
    en:
      'Wall decor diy from paper: cool tangram cactus in 3d / PaperShape #papershape #walldecor #paper #diy',
  },
  meta_images_social: [
    {
      de: 'wanddeko-diy-kaktus.jpg',
      en: 'wall-decor-diy-cactus.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'wanddeko-diy-kaktus-tangram-papier.jpg',
      en: 'wall-decor-diy-cactus-tangram.jpg',
    },
    alt_text: {
      de:
        'DIY Wanddeko aus Papier: Tangram Kaktus einfach und schnell selber machen mit der kostenlosen Vorlage / PaperShape #papershape #diy',
      en:
        'diy wall decor from paper: cute tangram cactus super easy and fun to make (free printable) / PaperShape #papershape #diy #walldecor',
    },
  },
  h1: {
    de: 'Coole Wanddeko DIY aus Papier: Tangram Kaktus',
    en: 'Wall decor diy from paper: cute tangram cactus',
  },
  description: {
    de: `
      <p>
      Die einen kommen gut mit ihnen aus, bei den anderen gehen auch sie ein:
      </p>
      <p>Die Rede ist vom stylischen Kaktus. Eigentlich sind sie super pflegeleicht, aber bei mir überleben sie leider auch nicht. Deswegen kommen sie bei mir jetzt an die Wand. Dieses Wanddeko DIY macht super viel Spaß und ist in 3D eine schöne Abwechslung zu den sonst eingerahmten Postern. Ihr könnt euch online auch weitere Tangram Figuren ansehen und diese nachlegen - das ist echt spaßig.
          </p>
      
      <p>
    Wem es ähnlich mit den Kakteen geht, der kann sich hier auch einen  <a href="/de/product/2-07-sfk/">3D Kaktus aus Papier zum Hinstellen</a> bestellen, selber machen und behalten oder verschenken.
      </p>
    `,
    en: `
      <p>
      I love cacti. They are super easy to care for and still:
      </p>
          <p>
      In my office they won't make it. That is why I rather craft them from paper and hang them as a cute diy wall decor. I love how the colors pop and if you want to create different tangrams then you will find tons of pictures online. 
      </p>
      <p>
      And just in case if you are like me, you may also want to craft a <a href="/en/product/2-07-sfk/">3D cactus to put on the desk</a> which you can order in my shop.
        </p>
    `,
  },
  inspirationTitle: {
    de:
      'Coole Wanddeko selber machen aus Papier: weitere Ideen für Tangram Tiere',
    en: 'Stylish wall decor from paper: tangram ideas',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'wanddeko-diy-lama-tangram-selber-machen.jpg',
          en: 'wall-decor-diy-paper-lama-tangram.jpg',
        },
        alt_text: {
          de:
            'DIY Wanddeko aus Papier: Tangram Lama einfach und schnell selber machen mit der kostenlosen Vorlage / PaperShape #papershape #diy',
          en:
            'diy wall decor from paper: cute tangram lama super easy and fun to make (free printable) / PaperShape #papershape #diy #walldecor',
        },
      },
    },
    {
      text: {
        de:
          'Die Trend Tiere ganz einfach als Wanddeko in Szene setzen. Hier das Lama.',
        en:
          'You can make a lot of trendy animals with tangram. Like this lama here..',
      },
    },

    {
      image: {
        filename: {
          de: 'wanddeko-diy-flamingo-papier-tangram.jpg',
          en: 'wall-decor-diy-paper-flamingo-tangram.jpg',
        },
        alt_text: {
          de:
            'DIY Wanddeko aus Papier: Tangram Flamingo einfach und schnell selber machen mit der kostenlosen Vorlage / PaperShape #papershape #diy',
          en:
            'diy wall decor from paper: cute tangram flamingo super easy and fun to make (free printable) / PaperShape #papershape #diy #walldecor',
        },
      },
    },
    {
      text: {
        de: 'Der Flamingo wird im Sommer wieder zum Hit werden. ',
        en: 'Flamingo-time.',
      },
    },
  ],
  stepsBackgroundColor: 'rgb(243, 243, 243)',
  stepsTitle: {
    de: 'Wanddeko DIY aus Papier: so gelingt euch der süße Tangram Kaktus ',
    en: 'DIY wall decor from paper: how to',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'vorlage.jpg',
          en: 'vorlage.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Tangram Vorlage herunterladen',
          en: 'Step: Download tangram template',
        },
      },

      text: {
        de: `Druckt die Tangram Vorlage auf DIN A4 Papier in eurer Wunschfarbe oder Muster aus. Meine Empfehlung hier ist Tonkarton (220g/m²) zu verwenden. Ich habe meine Papiere einfach mit einem Muster von der anderen
        Seite bedruckt, diese findet ihr auch in der Vorlage. Je nachdem, in welcher Kombination ihr euren Kaktus gestalten möchtet, müsst ihr evtl. mehrere Papiere ausdrucken.`,
        en: `Print the tangram template on paper of your choice. In the template you'll find a pattern that you can easily print on the other paper side as I did in this tutorial.`,
      },
    },
    {
      image: {
        filename: {
          de: 'schneiden.jpg',
          en: 'schneiden.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ausschneiden',
          en: 'Step: Cutting',
        },
      },
      text: {
        de: `Schneide mit einer Schere alle Elemente entlang der durchgezogenen Linie aus.`,
        en: `Cut out the paper parts (A, B, C, ...) by cutting along the solid lines. `,
      },
    },
    {
      image: {
        filename: {
          de: 'vorfalzen.jpg',
          en: 'vorfalzen.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Falzen',
          en: 'Step: Folding',
        },
      },
      text: {
        de: `Legt nun ein Lineal an alle gestrichelten Linien an und fahrt mit einem stumpfen dünnen Gegenstand die Linien mit Druck
        ab. Unabhängig davon, ob ihr Fotokarton oder dünneres Papier verwendet, empfehle ich die Falzlinien
         mit Druck nachzufahren, bevor ihr das Papier faltet. So entstehen schöne Knicke und das Papier bricht nicht an den Kanten.`,
        en: `Place a ruler on the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines. Don't skip this step - no matter what paper you use. The result will look so much better.`,
      },
    },

    {
      image: {
        filename: {
          de: 'knicken.jpg',
          en: 'knicken.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Knicken',
          en: 'Step: Folding paper parts',
        },
      },
      text: {
        de: `Legt ein beliebiges Papierteil so vor euch hin, dass ihr die Zahlen und
              Buchstaben seht. Knickt die kurz gestrichelten Linien nach innen,
              d.h. zu euch hin (Talfalte). Diesmal gibt es nur eine Art an gestrichelten Linien.`,
        en: `Start with any paper part and place it in front of you. 
              The printed side should be facing you. Subsequently fold all the dotted lines 
              inwards, which means to fold up (short dotted lines).`,
      },
    },

    {
      image: {
        filename: {
          de: 'pads.jpg',
          en: 'pads.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together ',
        },
      },
      text: {
        de: `Bringt auf die Laschen Kleber auf und verklebt jeweils die
        passenden Zahlenpaare. Bis auf den "Deckel", mit dem das Tangram quasi verschlossen wird, habe ich alle Laschen mit Klebepads verklebt, weil bei flüssigem Kleber einfach zu oft aus Versehen doch ein Klecks
        über den Rand läuft. Beginnt mit der Nr. 1 pro Vieleck und bastelt in nummerischer Reihenfolge (2,3,4, etc.) weiter. Jedes Papierteil ist bzgl. der Nummern in sich geschlossen, d.h. ihr müsst kein anderes Papierteil ankleben.`,
        en: `Use double-sided tape or any glue onto the unprinted side of the tabs. I use adhesive tape for all the flaps except of the last two ones.
        Each number exists twice. Stick all the same numbers together in ascending order (1, 2, 3, ...). Start with section 1 and stick it to
      the section marked 1, pressing firmly enough to make them stick together.`,
      },
    },

    {
      image: {
        filename: {
          de: 'kleben.jpg',
          en: 'kleben.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: glue ',
        },
      },
      text: {
        de: `Für die letzten zwei Laschen habe ich sehr starken Flüssigkleber verwendet. Je nachdem, welche Papierstärke ihr verwendet, kommt ihr mit einem normalen Kleber nicht wirklich weit, weil die Lasche wahrscheinlich immer wieder aufploppen wird. Wenn ihr alle Vielecke verklebt habt, könnt ihr sie nun in Kaktus Form anordnen, aufkleben und einrahmen.`,
        en: `For the last two flaps use strong glue. Depending on what paper you use normal glue won't stick good enough and the last paper part will leap up again. Once you glued all the polygons, arrange them as a cactus, glue them on some cardstock and frame it.`,
      },
    },
  ],
  downloadName: 'tangram-papershape.pdf',
  materials: [
    {
      de: '2 DINA4 Papier uni oder gemustert (160-300g/m²)',
      en:
        '2 cardstock paper uni or with pattern (8.5in x 11in) (weight: 65lb-110lb / 176gsm-300gsm)',
    },
    materials.glue,
    materials.scissors,
    materials.ruler,
    materials.awl,
    materials.mat,
    {
      de: 'starken Flüssigkleber',
      en: 'strong glue',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'wanddeko-diy-papier-kaktus-selbermachen-tangram.jpg',
          en: 'wall-decor-diy-paper-cactus-tangram.jpg',
        },
        alt_text: {
          de:
            'Wanddeko diy aus Papier für den Flur oder das Wohnzimmer: hippes Tangram als Kaktus (mit kostenloser Vorlage) / PaperShape #papershape #wandgestaltung #diy #paper',
          en:
            'DIY wall decor from paper (free printable): tangram cactus in fancy colors /PaperShape #papershape #walldecor #diy #paper ',
        },
      },
    ],
  },
};
