import React from 'react';
import OnlyEuOrder from './OnlyEuOrder';
import { PsLink } from './PsLink';
import {
  getOrderNumber,
  getOrderProductsEnhanced,
  isOrderAllowed,
} from '../state/order/orderSelectors';
import { connect } from 'react-redux';

export function DataprivacyLink({ children }) {
  return <PsLink to={'/dataprivacy'}>{children}</PsLink>;
}

export function TermsconditionsLink({ children }) {
  return <PsLink to={'/termsconditions'}>{children}</PsLink>;
}

export function PressLink({ children, onClick }) {
  return (
    <PsLink to={'/press'} onClick={onClick}>
      {children}
    </PsLink>
  );
}

export function ContactLink({ children, onClick }) {
  return (
    <PsLink to={'/contact'} onClick={onClick}>
      {children}
    </PsLink>
  );
}

export function FaqLink({ children, onClick }) {
  return (
    <PsLink to={'/faq'} onClick={onClick}>
      {children}
    </PsLink>
  );
}

// onClick for navItem
export function BlogLink({ children, onClick }) {
  return (
    <PsLink to={'/blog'} onClick={onClick}>
      {children}
    </PsLink>
  );
}

export function AboutMeLink({ children, onClick }) {
  return (
    <PsLink to={'/aboutme'} onClick={onClick}>
      {children}
    </PsLink>
  );
}

export function CartLink({ children, className }) {
  return (
    <PsLink to={'/cart'} className={className}>
      {children}
    </PsLink>
  );
}

export const PaymentstartLink = connect((state) => ({
  orderNumber: getOrderNumber(state),
}))(({ children, className, orderNumber }) => (
  <PsLink to={'/paymentstart/' + orderNumber} className={className}>
    {children}
  </PsLink>
));

export const Step2Link = connect((state) => {
  const orderProductsEnhanced = getOrderProductsEnhanced(state);
  const orderAllowed = isOrderAllowed(state);
  return {
    toAddressEnabled: orderAllowed && orderProductsEnhanced.length > 0,
    orderAllowed,
  };
})(({ children, className, toAddressEnabled, orderAllowed }) => (
  <div>
    {!orderAllowed && (
      <OnlyEuOrder />
    )}
    <PsLink
      to={'/orderstep2/'}
      className={className}
      disabled={!toAddressEnabled}>
      {children}
    </PsLink>
  </div>
));
