export default {
  id: '2-20-sfk',
  category: [ 'home_decor'],
  name: {
    de: 'Anker',
    en: 'Anchor',
  },
  subtitle: {
    de: 'B31 x H32 x T10 cm',
    en: 'W31 x H32 x D10 cm',
  },
  meta_title: {
    de: 'Maritime Deko: 3D Anker aus Papier',
    en: 'Papercraft anchor for your maritime decor',
  },
  meta_description_google: {
    de: 'Als Fan maritimer Deko ist dieser 3D Anker ein Must-have. Mit unserem DIY-Kit oder Vorlage kannst du ihn ganz nach deinen Vorlieben gestalten. ',
    en: 'You love martime decor? Now its time to make your own papercraft anchor! Get the template here.',
  },
  meta_description_social: {
    de: 'Als Fan maritimer Deko ist dieser 3D Anker ein Must-have. Mit unserem DIY-Kit oder Vorlage kannst du ihn ganz nach deinen Vorlieben gestalten.',
    en: 'Papercraft anchor for your home decor - be creative and decorate it with embellishments',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?    
    - 6 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -6 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 6 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 6 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 6 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 6 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkanker/anker-maritime-deko-diy.jpg',
        en: 'products/sfkanker/anker-maritime-deko-diy.jpg',
      },
      alt_text: {
        de: 'Maritime Deko selber machen: Papier Anker #papercraft #3dpapermodel #origami #maritimedeko',
        en: 'Papercraft anchor for your home decor #diypapercraft #origami #3dpapermodel #maritimedecor',
      },
    },
    price: 6.9,
    priceThirdCountry: 6.9,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkanker/anker-maritime-deko-diy.jpg',
        en: 'products/sfkanker/anker-maritime-deko-diy.jpg',
      },
      alt_text: {
        de: 'DIY Anker aus Papier - Vorlage downloaden #origami #maritimedeko #bastelnmitpapier #papershape',
        en: 'Papercraft anchor for your home decor #diypapercraft #origami #3dpapermodel #maritimedecor',
      },
    },
    colors: {
      color1: {
        de: 'Anker',
        en: 'Anchor',
      },
    },
    disableColors: {
      color1: ['tuerkis', 'schwarz'],
    },
    price: 16.9,
    priceThirdCountry: 14.5,
  },
};
