export default {
  url: 'hyazinthen-basteln',
  countermark: 'vg07.met.vgwort.de/na/ef9b1fd32e944b7faab3fbb8c6172704',
  imageFolder: 'hyazinthen',
  meta_title: {
    de: 'Hyazinthen basteln - DIY Blumen für den Frühling aus Papier ',
    en: 'How to make hyacinth from paper',
  },
  meta_description_google: {
    de:
      'Du suchst nach schönen Frühlingsblumen zum Verschenken? Mit dieser einfachen Schritt-für-Schritt Anleitung bastelst du dir schöne Hyazinthen aus Papier selber.',
    en: 'Learn how to make beautiful paper hyacinth ',
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  meta_images_social: [
    {
      de: 'hyazinthen-basteln-papier-anleitung.jpg',
      en: 'hyazinthen-basteln-papier-anleitung.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'hyazinthen-basteln.jpg',
      en: 'hyazinthen-basteln.jpg',
    },
    alt_text: {
      de: 'Hyazinthen basteln aus Papier ',
      en: 'How to make hyacinth from paper',
    },
  },
  h1: {
    de: 'Einfache Hyazinthen basteln als DIY Geschenkidee',
    en: 'Learn how to make beautiful paper hyacinth',
  },
  description: {
    de: `
      <p>Bestimmt liebst du es auch im Frühling deine Wohnung zu dekorieren. </p>
     
      <p>Beim wöchentlichen Schlendern über den Wochenmarkt lachen mich die bunten Frühlingsblumen immer so an,
      dass ich sie am liebsten gleich alle mitnehmen würde. </p>
   
      <p>Egal, ob Narzissen, Krokusse oder Tulpen - irgendwo findet sich schon ein Plätzchen.</p>
      
          
      <p>In diesem DIY Post möchte ich dir zeigen, wie du frühlingshafte Hyazinthen basteln kannst.</p>
      <p>Diese selbst gemachten Papierblumen eignen sich hervorragend als Frühlingsdekoration oder als DIY Geschenk
      zum Muttertag oder für Omis Geburtstag.</p>
      
      <p>Wenn du die Hyazinthen lieber als Bouquet zusammen mit anderen DIY Blumen verschenken möchtest, 
      dann schau mal hier, wie du ganz <a href="/de/blog/papier-tulpen-basteln/">einfache Tulpen basten</a> kannst. </p>
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de:
      '3 schöne Ideen, wie du aus Hyazinthen ein originelles DIY Geschenk machst',
    en: '3 ideas how to decorate with hyacinth',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'hyazinthen-selber-machen-fruehlingsdeko.jpg',
          en: 'hyazinthen-selber-machen-fruehlingsdeko.jpg',
        },
        alt_text: {
          de:
            'Hyazinthen aus Papier basteln. Hintergrundbild von https://de.freepik.com/fotos-vektoren-kostenlos/hintergrund #papercraft #bastelnmitpapier #papierblumen',
          en:
            'How to make hyacinth from paper. Hintergrundbild von https://de.freepik.com/fotos-vektoren-kostenlos/hintergrund #papercraft #paperflowers #hyacinth',
        },
      },
    },
    {
      text: {
        de: `In einem schönen Holzbilderrahmen arrangiert, werden die Hyazinthen aus Papier
        zu einer sehr schönen und eher minimalistischen Wanddeko. Ihr könnt sie auch zum
        Beispiel mit Washi Tape am Stiel auf einem weißen Pappkarton befestigen oder ihr
        tragt ein wenig Kleber einer Heißklebpistole auf und fixiert die Blüten darauf. So
        würden sie ein wenig schweben.
        <p>(<a href="https://de.freepik.com/fotos-vektoren-kostenlos/hintergrund">Hintergrund PSD erstellt von rawpixel.com - de.freepik.com</a>)</p>`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'hyazinthe-selber-basteln-papier.jpg',
          en: 'hyazinthe-selber-basteln-papier.jpg',
        },
        alt_text: {
          de:
            'Hyazinthen aus Papier basteln als Frühlingsdeko #papercraft #bastelnmitpapier #papierblumen',
          en:
            'How to make hyacinth paper flower #papercraft #paperflowers #hyacinth',
        },
      },
    },
    {
      text: {
        de: `In ganz nah seht ihr, wie die Wirbel der Hyazinthen-Blüten aussehen.
        Wenn ihr wie ich Blau- und Lilatöne verwendet, könnte es sogar als Lavendel 
        durchgehen.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'fruehlingsblumen-blumen-basteln-hyazinthen.jpg',
          en: 'fruehlingsblumen-blumen-basteln-hyazinthen.jpg',
        },
        alt_text: {
          de:
            'Hyazinthen aus Papier selber machen als DIY Geschenk zum Muttertag #papercraft #bastelnmitpapier #papierblumen',
          en: 'DIY hyacinth paper flower #papercraft #paperflowers #hyacinth',
        },
      },
    },
    {
      text: {
        de: `Wer liebt es nicht in einem Meer von Frühlngsblumen zu schwimmen. Gerade zum 
        Muttertag oder Geburtstag könnt ihr diese Blumen als ein Blumenbouquet mit 15-20
        Hyazinthen verschenken. Ich habe hier normales Backpapier verwendet, um die Papierblumen
        einzuwickeln. Denn Wasser brauchen diese zum Glück nicht. Nun noch ein Schleifchen 
        darum und eine handschriftlich verfasste Karte - schöner kann ein DIY Geschenk wirklich nicht sein.
         `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'hyazinthe-aus-papier-diy-anleitung.jpg',
          en: 'hyazinthe-aus-papier-diy-anleitung.jpg',
        },
        alt_text: {
          de:
            'Frühlingsblumen basteln: Hyazinthen aus Papier als Frühlingsdeko - Anleitung #papercraft #bastelnmitpapier #papierblumen ',
          en:
            'How to make hyacinth from paper #papercraft #paperflowers #hyacinth',
        },
      },
    },
    {
      text: {
        de: `So wie die DIY Blumen sind, könnt ihr sie auch wunderschön in eine Vase
         arrangieren. Zusammen mit echtem Grün wird der Look noch realistischer.`,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'Wie bastelt man Hyazinthen aus Papier - Schritt für Schritt Anleitung',
    en: '',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'hyazinthen-vorlage-ausdrucken.jpg',
          en: 'hyazinthen-vorlage-ausdrucken.jpg',
        },
        alt_text: {
          de: 'hyazinthen-vorlage-ausdrucken',
          en: 'hyazinthen-vorlage-ausdrucken',
        },
      },

      text: {
        de: `Donwloaded die Vorlage und druckt sie euch aus. Auf ein DIN A4 Tonpapier sind 4 Hyazinthen abgedruckt. 
        Die gestrichelte Linie stellt die Hilfslinie dar, bis zu der ihr schneiden dürft. Ihr schneidet immer links
        von der Hilfslinie.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'hyazinthe-papier-schneiden.jpg',
          en: 'hyazinthe-papier-schneiden.jpg',
        },
        alt_text: {
          de: 'hyazinthe-papier-schneiden',
          en: 'hyazinthe-papier-schneiden',
        },
      },

      text: {
        de: `Nun schneiden wir mit der Schere parallele Streifen bis zur Mittellinie. Je enger ihr die 
        Streifen schneidet, umso buschiger wird die Blüte der Hyazinthe am Ende und damit auch realistischer.
        Solltet ihr aber a la last-minute unterwegs sein, empfehle ich euch die Streifen alle 5mm zu setzen. 
        Achtet darauf, dass ihr sie möglichst parallel schneidet. `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'hyazinthe-papier-wirbel-anleitung.jpg',
          en: 'hyazinthe-papier-wirbel-anleitung.jpg',
        },
        alt_text: {
          de: 'hyazinthe-papier-wirbel-anleitung',
          en: 'hyazinthe-papier-wirbel-anleitung',
        },
      },

      text: {
        de: `Dieser Schritt bedarf am Anfang ein wenig Übung. Mit einem Holzspieß rollt ihr nun jeden der Streifen
        bis zur Hilfslinie ein. Wenn ihr es richtig gemacht habt, bleibt eine kleine Papierspirale an der Hilfslinie 
        "stecken". Diesen Schritt wiederholt ihr nun mit allen Streifen. Ein Tipp: Versucht nach ein wenig Übung 
        mehrere Streifen gleichzeitig aufzurollen, so spart ihr Zeit.
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'hyazinthen-blumenstiel-selber-machen.jpg',
          en: 'hyazinthen-blumenstiel-selber-machen.jpg',
        },
        alt_text: {
          de: 'hyazinthen-blumenstiel-selber-machen',
          en: 'hyazinthen-blumenstiel-selber-machen',
        },
      },

      text: {
        de: `Für den Blumenstiel verwende ich gerne Blumendraht, den es bereits in diversen Längen zu kaufen 
        gibt. Alternativ könnt ihr natürlich auch Tonpapier verwenden, um euch einen grünen Stiel zu drehen.
        Ich zeige euch hier die Variante mit dem Draht: Tragt Kleber auf eine Ecke des Papierblumenstreifens auf.
        Auf diesen legt ihr nun den Blumendraht. Achtet darauf, dass die Wirbel nach außen zeigen. 
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'hyazinthe-blume-wickeln-anleitung.jpg',
          en: 'hyazinthe-blume-wickeln-anleitung.jpg',
        },
        alt_text: {
          de: 'hyazinthe-blume-wickeln-anleitung',
          en: 'hyazinthe-blume-wickeln-anleitung',
        },
      },

      text: {
        de: `Wickelt die Hyazinthen-Papierstreifen nun diagonal um den Blumendraht herum. Verwendet hier
        hin und wieder Kleber. Wenn du am Ende angekommen bist, würde ich dir empfehlen die Blume mit etwas
        Kleber zu fixieren.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'hyazinthe-blumenstiel-basteln.jpg',
          en: 'hyazinthe-blumenstiel-basteln.jpg',
        },
        alt_text: {
          de: 'hyazinthe-blumenstiel-basteln',
          en: 'hyazinthe-blumenstiel-basteln',
        },
      },

      text: {
        de: `Mit Floristenkrepp umwickeln wir nun final den Blumendraht. Dazu setzen wir am Ende der Hyazinthenblüten
        an und fahren mehrmals um die gleiche Stelle, damit ein etwas dickerer Umfang entsteht. Anschließend 
        fährst du das Kreppband diagonal um den Blumendraht bis zum Ende. Voila! 
  `,
        en: ``,
      },
    },
  ],

  video: {
    title: {
      de: '',
      en: '',
    },
    description: {
      de: `  `,
      en: ``,
    },
    caption: {
      de: `Frühlingsblumen basteln: Hyazinthen aus Papier`,
      en: `Paper flowers: how to make paper hyacinth`,
    },
    videoid: '-Xub4R-vBSg',
  },
  downloadName: 'hyazinthe-vorlage_papershape.pdf',
  materials: [
    {
      de:
        'DINA4 Tonpapier in deinen Wunschfarben (bis zu 130g/m²), alternativ: Kreppapier',
      en:
        'Colored papers in your favorite colours (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Heißklebepistole oder Kleber',
      en: 'Glue ',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Holzspieß',
      en: 'wooden skewer',
    },
    {
      de: 'Blumendraht je nach Wunschlänge',
      en: 'flower wire',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'hyazinthen-papier-blumen-basteln-anleitung.jpg',
          en: 'hyazinthen-papier-blumen-basteln-anleitung.jpg',
        },
        alt_text: {
          de:
            'Hyazinthen basteln aus Papier #papercraft #bastelnmitpapier #papierblumen',
          en:
            'How to make hyacinth paper flowers #papercraft #paperflowers #hyacinth',
        },
      },
    ],
  },
};
