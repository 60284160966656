import React from 'react';
import dhga from '../styles/images/logos/dhga.png';
import ardbuffet from '../styles/images/logos/ardbuffet.png';
import b from '../styles/images/logos/b.png';
import flow from '../styles/images/logos/flow.png';
import liv from '../styles/images/logos/liv.png';
import ghgfd from '../styles/images/logos/ghgfd.png';
import br from '../styles/images/logos/br.png';
import wohnrevue from '../styles/images/logos/wohnrevue.png';

export default () => (
  <div className="section">
    <div className="container">
      <div className="row bekanntaus" id="bekanntaus">
        <div className="col-xs-6 col-sm-6 col-md-3">
          <img
            src={ardbuffet}
            alt="ARD Buffet"
            className="img-responsive center-block"
          />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-3">
          <img
            src={dhga}
            alt="Die Höhle des Löwen"
            className="img-responsive center-block"
          />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-3">
          <img src={b} alt="b" className="img-responsive center-block" />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-3">
          <img
            src={flow}
            alt="Flow Magazin"
            className="img-responsive center-block"
          />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-3">
          <img
            src={liv}
            alt="Livingathome"
            className="img-responsive center-block"
          />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-3">
          <img
            src={ghgfd}
            alt="Süddeutsche Zeitung"
            className="img-responsive center-block"
          />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-3">
          <img src={br} alt="BR" className="img-responsive center-block" />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-3">
          <img
            src={wohnrevue}
            alt="wohnrevue"
            className="img-responsive center-block"
          />
        </div>
      </div>
    </div>
  </div>
);

// {/*
//             <div class="col-xs-6 col-sm-6 col-md-3">
//                 <img src={echtbayern.png"  alt="Echt Bayern" class="img-responsive center-block" />
//             </div>
//             <div class="col-xs-6 col-sm-6 col-md-3">
//                 <img src={frankenfernsehen.png"  alt="Frankenfernsehen" class="img-responsive center-block" />
//             </div>
//              */}
