import React from 'react';
import { selectedLang } from '../data/lang';

export default function PsDisableGa() {
  return (
    <a
      onClick={() => {
        document.cookie =
          window.disableStr +
          '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
        window[window.disableStr] = true;
        window.alert('Google Analytics wurde für diese Seite deaktiviert');
      }}>
      {selectedLang === 'en' ? 'click here' : 'hier klicken'}
    </a>
  );
}
