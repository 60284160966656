export default {
  url: 'zitrone-basteln',
  countermark: 'vg07.met.vgwort.de/na/19fe3195865843d8bf175aeef76ed43e',
  imageFolder: 'zitrone',
  meta_title: {
    de: 'Sommerliche DIY Zitronen aus Papier ',
    en: 'How to make lemons from paper',
  },
  meta_description_google: {
    de:
      'Knallig gelbe Zitronen aus Papier sind die Trendfrucht auf Hochzeiten und als sommerliche Deko eine super DIY-Idee. ',
    en: 'Learn how to make these fun yellow lemons for summer',
  },
  meta_description_social: {
    de:
      'Den eigenen Pokal basteln zur Fussball WM 2018 und beim Public Viewing so richtig mitfiebern. Mit der kostenlosen PaperShape Vorlage und Anleitung bastelst du in 3 einfachen Schritten deinen eigenen Pokal.',
    en:
      'Make your paper world cup trophy in just 3 easy steps with this free template. Public Viewing is just not the same without this trophy.',
  },

  image_main: {
    filename: {
      de: 'sommer-diy-zitrone.jpg',
      en: 'zitrone-basteln.jpg',
    },
    alt_text: {
      de: 'Zitrone basteln aus Papier #papercraft #papershape #diy',
      en: 'How to make lemons from paper #papercraft #diy #hochzeitsdeko',
    },
  },
  h1: {
    de: 'Sommerliche DIY Zitronen aus Papier ',
    en: 'How to make lemons from paper',
  },
  description: {
    de: `
      <p>Wenn dir das Leben Zitronen bietet, mach Limonade daraus. </p>
      <p>Wenn du dein Leben auch so optimistisch angehst, dann solltest du unbedingt diese sommerlichen Zitronen aus Papier ausprobieren. </p>
      <p>Die saure Frucht ist dieses Jahr auch Trendfrucht für sommerliche Hochzeitsdeko. Wenn ihr an einem Sommernachmittag eine Zitronenlimonda oder -eistee anbietet, dann könnt ihr als Deko diese schönen Papierzitronen daneben dekorieren. Na dann, los geht's mit der DIY Zitrone zum Basteln.  </p>
     
      <p>Weitere Sommer DIY-Ideen findet ihr hier:</p>
      <u>
      <li><a href="/de/blog/papier-apfel-basteln/">DIY Äpfel basteln</a></li>
      <li><a href="/de/blog/libelle-basteln-papier/">DIY Libellen für den Garten</a></li>
      <li><a href="/de/blog/bilderrahmen-basteln-papier/">DIY Bilderrahmen für die Urlaubsfotos </a></li>
      
    
  </u>
      
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '.jpg',
          en: '.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '.jpg',
          en: '.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '.jpg',
          en: '.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '.jpg',
          en: '.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'Anleitung: Zitronen basteln ',
    en: '',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'ausschneiden.jpg',
          en: 'ausschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Drucke die Vorlage aus und schneide die abgedruckten Papierteile entlang jeder durchgezogenen Linien aus. Die Vorlage ergibt eine große Zitrone bzw. zwei Hälften.
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'zitronenteile-kleben.jpg',
          en: 'zitronenteile-kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Klebe nun auf das weiße Achteck die gelben Dreiecke nebeneinander auf, sodass die Optik einer aufgeschnittenen Zitrone entsteht. Dieses Achteck klebst du nun auf das Teil A auf. Achte dabei, dass du es auf die unbedruckte, d.h. schöne Außenseite klebst. Diesen Schritt wiederholst du für das Papierteil D.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'papier-falzen.jpg',
          en: 'papier-falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: ` Lege nun ein Lineal an die gestrichelten Linien an und falze sie vor, indem du mit der Rückseite eines Buttermessers (oder Ahle, Falzbein) an ihnen entlang fährst. 
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'falten.jpg',
          en: 'falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Falte je nach Markierung nach innen (kurze Striche) oder nach außen (lange Striche).  
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'laschen-kleben.jpg',
          en: 'laschen-kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Klebe auf die unbedruckte Seite der Laschen so viele Klebepads wie Platz ist.    
        Die Laschen werden nun in nummerischer Reihenfolge (1,2,3) verklebt. Beginne mit Lasche Nr. 1 
        auf dem Papierteil A und verklebe diese durch festes Zusammendrücken mit der zugehörigen Nr. 1
         auf Papierteil A. Schaue beim Verkleben der Laschen von außen auf das Modell.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'zitrone-basteln-papier.jpg',
          en: 'zitrone-basteln-papier.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Wenn du am Ende keine Zitronenhälften, sondern lieber eine ganze Zitrone basteln möchtest, dann verklebe die beiden Hälften einfach miteinander. Du kannst die weißen Achtecke und die gelben Dreiecke dann natürlich weglassen.
  `,
        en: ``,
      },
    },
  ],

  downloadName: 'template-lemon_papershape.pdf',
  materials: [
    {
      de:
        '3 gelbe und 1 weißes DIN A4 Blatt je nach Farbwunsch (130g/m²-270g/m²)',
      en: '2 colored papers in red (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: '.jpg',
          en: '.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    ],
  },
};
