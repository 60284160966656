export default {
  url: 'avocado-eierhalter-basteln',
  countermark: 'vg07.met.vgwort.de/na/fb99997b47cf445a8e7e509642e08de3',
  imageFolder: 'avocado',
  meta_title: {
    de: 'Witzig Tischdeko: Avocado Eierhalter basteln aus Papier (Anleitung + Vorlage)',
    en: 'Avocado egg holder from paper',
  },
  meta_description_google: {
    de: 'Witzige Eierhalter basteln als Papier-Avocado mit Osterei als Kern (mit Vorlage)',
    en: 'Funny Easter egg holder as avocado ',
  },
  meta_description_social: {
    de: 'Eierhalter basteln als Papier Avocado mit Osterei als Kern #bastelnmitpapier #ostern #osternest #ostergeschenk ',
    en: 'DIY Easter egg holder as avocado #papercraft #papercraftwithkids',
  },
  meta_images_social: [
    {
      de: 'avocado-eierhalter-basteln.jpg',
      en: 'avocado-eierhalter-basteln.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'avocado-eierhalter-basteln-papier.jpg',
      en: 'avocado-eierhalter-basteln-papier.jpg',
    },
    alt_text: {
      de: 'Lustiges Eierhalter basteln als Papier Avocado mit Osterei als Kern #bastelnmitpapier #osterkorb #osternest #ostergeschenk',
      en: 'Funny easter egg holder #papercraft',
    },
  },
  h1: {
    de: 'Witzige Tischdeko: Avocado Eierhalter selber basteln aus Papier',
    en: 'Paper Avocado Easter nest',
  },
  description: {
    de: `
      <p>In meiner Kindheit habe ich immer ziemlich große Osterkörbchen geschenkt bekommen - gefüllt mit meinen liebsten Süßigkeiten.  </p>
      <p>Damals fand ich das klasse, 
      heute gehe ich jedoch dazu über kleine Osterkörbe zu verschenken, damit genau das, was dort enthalten ist, die volle Aufmerksamkeit bekommt. </p>
      <p>In diesem DIY Post zeige ich euch, wie ihr originelle Eierhalter aus Papier bastelt. Man könnte es auch super als Eierhalter beim Osterfrühstück verwenden 
      oder eben an Ostern verschenken.  </p>
      <p>Die Idee ist simpel: eine Avocado mit Papier selber machen und den typisch dunkelbraunen Kern setzen wir als braunes Osterei ein! Hier könnt ihr im Inneren natürlich auch 
      noch Kleinigeiten verstauen. 
           </p>
     
      <p>Wenn euch diese Idee zu Ostern zu gesund ist, dann ran an die Papierkalorien mit dem <a href="/de/blog/donut-osternest-basteln/">Donut-Osternest</a>.</p>
      
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: 'Witzige Eierhalter für deine Tischdeko an Ostern',
    en: 'Funny egg holder as table decor for Easter',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'avocado-eihalter-basteln-papier.jpg',
          en: 'avocado-eihalter-basteln-papier.jpg',
        },
        alt_text: {
          de: 'DIY Eierhalter basteln aus Papier als Avocado mit Osterei als Kern #bastelnmitpapier #avocdao #osternest #eierhalter',
          en: 'diy Easter nest from paper as avocado',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'avocado-eierhalter-selber-basteln.jpg',
          en: 'avocado-osterkoerbchen-selber-basteln.jpg',
        },
        alt_text: {
          de: 'DIY Eierhalter selber machen als Papier Avocado mit Osterei als Kern #bastelnmitpapier #diy #papercraft #osternest #osterkörbchen',
          en: 'paper avocado as Easter nest #papercraft #easternest',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: '',
    en: '',
  },
  steps: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Ihr benötigt pro Avocado 3 DINA4 Seiten in zwei Grüntönen. Verwendet am besten Tonkarton mit 
        einer Grammatur von bis zu 160g/m². Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer
        Schere aus.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Falzt mit einer Ahle (alternativ Rückseite eines Cuttermesser) und einem Lineal alle gestrichelten Linien vor. Dazu das Lineal an die Linie anlegen und mit der Ahle mit Druck
        nachfahren.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: ` Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin. Faltet die lang gestrichelten Linien von euch weg, d.h. nach unten. 
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Klebt auf die Laschen der einzelnen Papierteile Klebepads. Wichtig ist, dass ihr das Pad auf die schöne Seite und damit die unbedruckte Seite der Lasche klebt.
        Es existiert immer ein Zahlenpaar, das zusammen verklebt werden muss.
        Ihr beginnt in alphabetischer Reihenfolge. D.h., nachdem ihr die Nr. 12 (A) miteinander verklebt habt, klebt ihr irgendeine Nummer vom Teil B an die passende Nummer auf Teil A an.
        Danach kommt C, D, usw. 
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Den Deckel der Avocado bildet das Teil E. Hier seht ihr drei unterschiedlich große Kreise. Der kleinste Kreis ist für ein S-Ei, der in der Mitte für ein M-Ei und der größte Kreis umfasst L-Eier.
        Eines dieser Kreise müsst ihr ausschneiden. Anschließend verklebt ihr das Teil E an die Laschen von Teil D.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Damit eure 3D Avocado nun wie eine Avocado aussieht, druckt ihr euch die dritte Seite der Vorlage in einem hellen Grünton aus. Dann schneidet ihr die Vorlage aus und 
        das Loch in der Mitte ebenfalls.
        Manchmal geht das mit der Lochgröße nicht ganz auf, weil das Ei vllt. doch eine kleine Abweichung von der Norm hat. Dann legt es einfach auf das Papier auf und zeichnet es ein.
        Nun so aufkleben, dass sich die Löcher überlappen. Zum Schluss nun das schöne Ei als Kern positionieren und voilà!
  `,
        en: ``,
      },
    },
  ],

  video: {
    title: {
      de: 'Wie Eierhalter aus basteln?',
      en: 'How to make funny avocado easter egg holder from paper',
    },
    description: {
      de: `Stabile Eierhalter aus Papier zu basteln, ist dank der 3D Origami Technik und den Verklebungen nicht schwer.`,
      en: ``,
    },
    caption: {
      de: `Avocado Eierhalter basteln`,
      en: `How to make Avocado egg holder `,
    },
    videoid: 'SSDxZXguYmI',
  },
  downloadName: 'avocado-vorlage-papershape.pdf',
  materials: [
    {
      de: '3 DINA4 Papier in zwei Grüntönen (ab 160g/m²)',
      en: '3 colored papers in green (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'avocado-eierhalter-basteln.jpg',
          en: 'avocado-eierhalter-basteln.jpg',
        },
        alt_text: {
          de: 'Eierhalter basteln aus Papier als Avocado mit Osterei als Kern #bastelnmitpapier #papercraft #diy #osternest #osterkörbchen #osternbasteln',
          en: 'How to make an egg holder as paper avocado with easter egg (template) #papercraft #easternest #easter #diy ',
        },
      },
    ],
  },
};
