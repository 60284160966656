import React from 'react';
import { useParams } from 'react-router-dom';

function Lang({ en, de, ...other }) {
  const { lang } = useParams();
  const isEn = lang === 'en';
  if (isEn) {
    if (typeof en === 'string') {
      //return <span>{en.format(...Object.values(other))}</span>;
      return <span>{en}</span>;
    }
    return en;
  }
  if (typeof de === 'string') {
    return <span>{de}</span>;
    // return <span>{de.format(...Object.values(other))}</span>;
  }
  return de;
}

export default Lang;
