export default {
  dev: {
    BACKEND_URL: '/test',
    CLIENT_HOST: 'http://localhost:3000',
    PAYPAL_MODE: 'sandbox',
  },
  test: {
    BACKEND_URL: '/test',
    CLIENT_HOST: 'https://test.paper-shape.com',
    PAYPAL_MODE: 'sandbox',
  },
  prod: {
    BACKEND_URL: '/prod',
    CLIENT_HOST: 'https://www.paper-shape.com',
    PAYPAL_MODE: 'live',
  },
}[process.env.REACT_APP_STAGE];
