import {
  getEmail,
  getOrderProductsEnhanced,
  getVoucherCode,
} from '../order/orderSelectors';

export const VOUCHER_VALIDATED = 'VOUCHER_VALIDATED';
export const CLEAR_VOUCHER = 'CLEAR_VOUCHER';

export function validateVoucher() {
  return (dispatch, getState) => {
    const voucherCode = getVoucherCode(getState());
    if (!voucherCode || !voucherCode.trim()) {
      dispatch({
        type: CLEAR_VOUCHER,
      });
      return Promise.resolve();
    }

    const orderProducts = getOrderProductsEnhanced(getState());
    const currentVoucherCode = getVoucherCode(getState());
    return dispatch({
      fetch: {
        body: {
          type: 'ValidateVoucher',
          payload: {
            code: currentVoucherCode ? currentVoucherCode.trim() : '',
            email: getEmail(getState()),
            productIds: orderProducts.map((orderProduct) => orderProduct.id),
            variants: orderProducts.map(
              (orderProduct) => orderProduct.variantKey
            ).filter(variantKey => variantKey != null),
          },
        },
      },
    }).then((voucherResult) => {
      return dispatch({
        type: VOUCHER_VALIDATED,
        payload: voucherResult,
      });
    });
  };
}
