import React from 'react';
import Email from '../../common/Email';

export default () => (
  <div>
    § 1 Anwendungsbereich
    <br />
    <br />
    Die nachfolgenden Allgemeinen Geschäftsbedingungen regeln das vertragliche
    Verhältnis zwischen <br />
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG, Guttknechtstr. 23, 90547
    Stein (im Folgenden Verkäuferin) <br />
    und Kunden, welche Waren auf der Seite www.paper-shape.com kaufen (im
    Folgenden „Kunde“).
    <br />
    <br />
    <br />
    § 2 Gegenstand des Vertrages
    <br />
    <br />
    Gegenstand des Vertrages ist die Lieferung der im Online-Shop angebotenen
    Bastelsets, digitalen Anleitungen und Gutscheine.
    <br />
    <br />
    <br />
    § 3 Vertragsschluss
    <br />
    <br />
    Die Angebote auf www.paper-shape.com stellen noch kein verbindliches Angebot
    zum Vertragsabschluss dar, sondern eine Aufforderung an den Kunden
    seinerseits ein Angebot zu machen.
    <br />
    Die automatisch versandte Email-Bestätigung über den Bestellungseingang
    stellt noch keine Annahme des Angebotes dar.
    <br />
    Der Vertrag kommt durch Auslieferung der Ware per Email (digitale
    Anleitungen, Gutscheine) im Anschluss an den Zahlungseingang bzw. durch
    Auslieferung (Bastelsets) innerhalb von ca. 3 -5 Tagen an den Kunden zu
    Stande.
    <br />
    <br />
    § 4 Preise und Zahlungsbedingungen
    <br />
    <br />
    Die angegebenen Preise verstehen sich als Endpreise inkl. MwSt.
    <br />
    Gegebenenfalls zusätzlich anfallende Liefer- und Versandkosten werden in der
    <br />
    jeweiligen Produktbeschreibung gesondert angegeben.
    <br />
    <br />
    Dem Kunden stehen verschiedene Zahlungsmöglichkeiten zur Verfügung. Dies
    sind Zahlung per Banküberweisung und Zahlung per PayPal.
    <br />
    <br />
    Die Zahlung ist mit Aufgabe einer Bestellung fällig.
    <br />
    <br />
    <br />
    § 5 Lieferbedingungen und Lieferzeit
    <br />
    <br />
    Die Lieferung erfolgt in ca. 3 – 5 Werktagen nach Eingang des Kaufpreises
    auf dem Konto der Verkäuferin (bei PayPal-Zahlungen ist der Eingang auf dem
    PayPal-Konto der Verkäuferin maßgeblich). <br />
    <br />
    Die Lieferung erfolgt per Deutsche Post an die vom Käufer angegebene
    Adresse.
    <br />
    <br />
    Digitale Inhalte werden unmittelbar nach Zahlungseingang per Email bzw.
    Download bereitgestellt. <br />
    <br />
    Gutscheine werden unmittelbar nach Zahlungseingang per Email versandt.
    <br />
    <br />
    <br />
    § 6 Nutzungsrecht <br />
    <br />
    Mit dem Kauf eines Bastelsets oder einer digitalen Anleitung erhält der
    Kunde ein einfaches, auf den privaten Gebrauch beschränktes Nutzungsrecht.
    Nicht gestattet ist, die Bastelleitung öffentlich, insbesondere im Internet
    zugänglich zu machen oder anderweitig zu verbreiten.
    <br />
    <br />
    <br />
    § 7 Eigentumsvorbehalt
    <br />
    <br />
    Das Eigentum an physikalischen Waren (Bastelsets) bleibt bis zum Eingang
    aller Zahlungen aus dem Kaufvertrag vorbehalten.
    <br />
    <br />
    <br />
    § 8 Haftung
    <br />
    <br />
    Die Verkäuferin haftet nach den gesetzlichen Bestimmungen, sofern der Kunde
    Schadensersatzansprüche geltend macht, die auf Vorsatz oder grober
    Fahrlässigkeit, einschließlich von Vorsatz oder grober Fahrlässigkeit der
    Vertreter oder Erfüllungsgehilfen der Verkäuferin beruhen. Soweit der
    Verkäuferin keine vorsätzliche Vertragsverletzung angelastet wird, ist die
    Schadensersatzhaftung auf den vorhersehbaren, typischerweise eintretenden
    Schaden begrenzt.
    <br />
    Die Verkäuferin haftet nach den gesetzlichen Bestimmungen, sofern sie
    schuldhaft eine wesentliche Vertragspflicht verletzt; auch in diesem Fall
    ist aber die Schadensersatzhaftung auf den vorhersehbaren, typischerweise
    eintretenden Schaden begrenzt. Eine wesentliche Vertragspflicht liegt vor,
    wenn sich die Pflichtverletzung auf eine Pflicht bezieht, auf deren
    Erfüllung der Kunde vertraut hat und auch vertrauen durfte.
    <br />
    Die Haftung wegen schuldhafter Verletzung des Lebens, des Körpers oder der
    Gesundheit bleibt unberührt; dies gilt auch für die zwingende Haftung nach
    dem Produkthaftungsgesetz.
    <br />
    Soweit nicht vorstehend etwas Abweichendes geregelt ist, ist die Haftung
    ausgeschlossen.
    <br />
    <br />
    <br />
    <br />
    <br />
    § 9 Gerichtsstand, anwendbares Recht
    <br />
    <br />
    Für Kunden, die Kaufmann im Sinne des HGB sind, ist Gerichtstand für alle
    Streitigkeiten aus dem Vertragsverhältnis der Sitz der Verkäuferin.
    <br />
    <br />
    Auf das Vertragsverhältnis findet deutsches Recht Anwendung. Ist der
    Vertragspartner Verbraucher, bleiben gesetzliche Vorschriften des
    Aufenthaltslandes des Vertragspartner zum Schutz von Verbrauchern hiervon
    unberührt. Das UN-Kaufrecht findet keine Anwendung.
    <br />
    <br />
    <br />
    <br />
    <h1>B. Verbraucherinformationen</h1>
    <br />
    1. Identität der Unternehmerin
    <br />
    <br />
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG
    <br />
    Guttknechtstr. 23
    <br />
    90547 Stein
    <br />
    <br />
    Registergericht: Amtsgericht Fürth
    <br />
    Registernummer: HRA 11276
    <br />
    <br />
    Geschäftsführerin: Anastasia Baron
    <br />
    <br />
    E-Mail-Adresse: <Email /> <br />
    Telefonnummer: +49 911 36069244
    <br />
    Ust-IdNr.: DE305074829
    <br />
    <br />
    <br />
    2. Speicherung des Vertragstextes und Vertragssprache
    <br />
    <br />
    Der Vertragstext wird seitens der Verkäuferin nicht gespeichert.
    <br />
    Vertragssprache ist deutsch für Kunden aus Deutschland, Österreich und der
    deutschsprachigen Schweiz. Für andere Kunden ist Vertragssprache Englisch.
    <br />
    <br />
    <br />
    3. Wesentliche Merkmale der Waren
    <br />
    <br />
    Die wesentlichen Merkmale der angebotenen Ware und digitalen Inhalte können
    den jeweiligen Produktseiten entnommen werden.
    <br />
    <br />
    <br />
    4. Technische Schritte die zum Vertragsschluss führen
    <br />
    <br />
    Die einzelnen technischen Schritte, die zum Vertragsschluss führen sind
    folgende:
    <br />
    <br />
    Zur Abgabe eines Angebotes klickt der Kunde zunächst mit der Maus das
    gewünschte Produkt. Auf der nächsten Seite klickt er auf den Button
    „Bestellen“. Darauf wird eine Seite angezeigt, auf der Farbe und Anzahl des
    Produktes gewählt werden kann. Im Anschluss klickt der Kunde auf „Zur
    Adresseingabe“, wo er Name, Email-Adresse und Liefer- bzw. Rechnungsadresse
    angibt. Die Bestellung wird über den Button „Jetzt kaufen“ abgesandt. <br />
    <br />
    <br />
    Bevor er seine Bestellung absendet, kann er in der Übersicht die Daten
    seiner Bestellung überprüfen. Durch Anklicken des „Zurück“ kann er auf den
    vorigen Bildschirm zurückkehren und die Bestellung ändern.
    <br />
    <br />
    5. Technische Mittel zur Erkennung und Korrektur von Eingabefehlern
    <br />
    <br />
    Vor verbindlicher Abgabe einer Bestellung kann der Kunde seine Eingaben über
    die üblichen Tastatur- und Mausfunktionen korrigieren. Zur Änderung der
    Bestellung (z.B. Menge, Größe, Farbe) gelangt der Kunde über den
    „Zurück“-Button auf der Website oder die Zurück-Funktion seines Browsers.
    <br />
    <br />
    6. Kundendienst, Gewährleistung
    <br />
    <br />
    Es besteht ein gesetzliches Mängelhaftungsrecht. Dieses richtet sich nach §§
    434 ff. BGB.
    <br />
    <br />
    Ein Kundendienst steht werktags von 9:00 Uhr bis 18:00 Uhr unter
    <br />
    <br />
    E-Mail-Adresse: <Email />
    <br />
    Telefonnummer: +49 911 36069244
    <br />
    <br />
    zur Verfügung.
    <br />
    <br />
    <br />
    7. Informationen über das Widerrufsrecht
    <br />
    <br />
    Es besteht ein Widerrufsrecht gemäß der Widerrufsbelehrung, die vor
    Absendung der Bestellung angezeigt und dem Kunden mit der automatischen
    Bestellbestätigung per Email zugesandt wird.
    <br />
    <br />
    Ein Widerrufsrecht besteht nicht bei Fernabsatzverträgen zur Lieferung von
    Waren, die nach Kundenspezifikation angefertigt werden oder eindeutig auf
    die persönlichen Bedürfnisse zugeschnitten sind oder die auf Grund ihrer
    Beschaffenheit nicht für eine Rücksendung geeignet sind oder schnell
    verderben können oder deren Verfalldatum überschritten würde.
    <br />
    <br />
    Das Widerrufsrecht erlischt bei einem Vertrag über die Lieferung von nicht
    auf einem körperlichen Datenträger befindlichen digitalen Inhalten auch
    dann, wenn der Unternehmer mit der Ausführung des Vertrags begonnen hat,
    nachdem der Verbraucher ausdrücklich zugestimmt hat, dass der Unternehmer
    mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt, und
    seine Kenntnis davon bestätigt hat, dass er durch seine Zustimmung mit
    Beginn der Ausführung des Vertrags sein Widerrufsrecht verliert.
    <br />
    <br />
    <br />
    8. Information zu Lieferbedingungen und Lieferzeit
    <br />
    <br />
    Die Lieferung des Artikels an den Kunden erfolgt in ca. 3 – 5 Werktagen ab
    Zahlungseingang auf dem Konto der Verkäuferin (bei PayPal-Zahlungen: Eingang
    auf dem PayPal-Konto der Verkäuferin).
    <br />
    Die Lieferung von Bastelsets und anderen physischen Gütern erfolgt per Post
    an die vom Käufer angegebene Adresse.
    <br />
    Gutscheine und Bastelanleitungen werden sofort nach Zahlungseingang per
    Email bzw. Download bereitgestellt. <br />
    <br />
    <br />
    <br />
    <br />
    Widerrufsbelehrung
    <br />
    <br />
    - Bastelsets -<br />
    <br />
    Widerrufsrecht
    <br />
    <br />
    Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
    Vertrag zu widerrufen.
    <br />
    <br />
    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von
    Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz
    genommen haben bzw. hat.
    <br />
    <br />
    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
    <br />
    <br />
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG
    <br />
    Guttknechtstr. 23
    <br />
    90547 Stein
    <br />
    E-Mail-Adresse: <Email />
    <br />
    Telefonnummer: +49 911 36069244
    <br />
    <br />
    <br />
    mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter
    Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
    widerrufen, informieren. Sie können dafür das beigefügte
    Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
    <br />
    <br />
    Machen Sie von dieser Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich
    (z. B. per E-Mail) eine Bestätigung über den Eingang eines solchen Widerrufs
    übermitteln.
    <br />
    <br />
    Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über
    die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
    <br />
    <br />
    Folgen des Widerrufs
    <br />
    <br />
    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir
    von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der
    zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der
    Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt
    haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag
    zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags
    bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
    Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben,
    es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in
    keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir
    können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten
    haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren
    zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
    <br />
    <br />
    Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
    vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags
    unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist
    gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen
    absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
    <br />
    <br />
    Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn
    dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften
    und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen
    zurückzuführen ist.
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    Widerrufsbelehrung
    <br />
    <br />
    - Digitale Bastelanleitungen und Gutscheine -<br />
    <br />
    Widerrufsrecht
    <br />
    <br />
    Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
    Vertrag zu widerrufen.
    <br />
    <br />
    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von
    Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz
    genommen haben bzw. hat.
    <br />
    <br />
    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
    <br />
    <br />
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG
    <br />
    Guttknechtstr. 23
    <br />
    90547 Stein
    <br />
    E-Mail-Adresse: <Email />
    <br />
    Telefonnummer: +49 911 36069244
    <br />
    <br />
    <br />
    mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter
    Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
    widerrufen, informieren. Sie können dafür das beigefügte
    Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
    <br />
    <br />
    Machen Sie von dieser Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich
    (z. B. per E-Mail) eine Bestätigung über den Eingang eines solchen Widerrufs
    übermitteln.
    <br />
    <br />
    Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über
    die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
    <br />
    <br />
    Folgen des Widerrufs
    <br />
    <br />
    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir
    von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der
    zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der
    Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt
    haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag
    zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags
    bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
    Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben,
    es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in
    keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    Muster-Widerrufsformular
    <br />
    <br />
    <br />
    (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
    Formular aus und senden Sie es zurück.)
    <br />
    <br />
    An <br />
    <br />
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG
    <br />
    Guttknechtstr. 23
    <br />
    90547 Stein
    <br />
    E-Mail-Adresse: <Email />
    <br />
    <br />
    Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag
    über den Kauf der folgenden
    <br />
    <br />
    _________________________________________________________________
    <br />
    Waren (*)/die Erbringung der folgenden Dienstleistung (*)
    <br />
    <br />
    _________________________________________________________________
    <br />
    Bestellt am (*)/erhalten am (*)
    <br />
    <br />
    _________________________________________________________________
    <br />
    Name des/der Verbraucher(s)
    <br />
    <br />
    _________________________________________________________________
    <br />
    Anschrift des/der Verbraucher(s)
    <br />
    <br />
    _________________________________________________________________
    <br />
    Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
    <br />
    <br />
    _________________________________________________________________
    <br />
    Datum
    <br />
    <br />
    (*) Unzutreffendes streichen.
    <br />
    <br />
    <br />
    <br />
  </div>
);
