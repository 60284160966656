export default {
  id: '1-16-sfk',
  category: ['animal_sculpture'],
  name: {
    de: 'Bär',
    en: 'Bear',
  },
  subtitle: {
    de: 'B26 x H28 x T32 cm',
    en: 'W26 x H28 x D32 cm',
  },
  meta_title: {
    de: 'Süßer 3 Bär aus Papier - Gestalte deine Wandkunst selbst ',
    en: 'Papercraft bear - Create your unique wall art',
  },
  meta_description_google: {
    de: 'Als Bärenlieber gar nicht so einfach einen Bären zu halten? Dann haben wir jetzt die vegane Papier Version: Gestalte deinen eigenen Origami Bär für dein Zuhause.',
    en: 'Ever wanted to have your own bear? Now its time to make your own papercraft bear! Get the template here.',
  },
  meta_description_social: {
    de: 'DIY Papierskulptur Bär für deine eigenen vier Wände - Wandkunst für Bärliebhaber',
    en: 'Papercraft bear for your wall art - be creative and decorate it with embellishments',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?    
    - 12 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität (die Nase ist immer schwarz)
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -12 printed DINA4 cardboard sheets for the perfect stability (the nose is always black)
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 12 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 12 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 12 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 12 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkbaer/baer-bear-papercraft_papershape.jpg',
        en: 'products/sfkbaer/baer-bear-papercraft_papershape.jpg',
      },
      alt_text: {
        de: 'DIY 3d Bär aus Papier als Wanddeko #papercraft #3dpapermodel #origami',
        en: 'Papercraft bear for wall decor #diypapercraft #origami #3dpapermodel #origami',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkbaer/baer-bear-papercraft_papershape.jpg',
        en: 'products/sfkbaer/baer-bear-papercraft_papershape.jpg',
      },
      alt_text: {
        de: 'DIY Wandtrophäe Bär aus Papier - Vorlage downloaden #origami #wanddeko #bastelnmitpapier #papershape',
        en: 'Papercraft bear Make your own wall art #papercraft #diy #wallart #papershape #bear',
      },
    },
    colors: {
      color1: {
        de: 'Kopf',
        en: 'Head',
      },
      color2: {
        de: 'Ohren',
        en: 'Ears',
      },
    },
    disableColors: {
      color2: ['schwarz'],
      color1: ['schwarz'],
    },
    price: 24.9,
    priceThirdCountry: 23.9,
  },
};
