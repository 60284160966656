export default {
  url: 'papier-tulpen-basteln',
  countermark: 'vg07.met.vgwort.de/na/53215c6a26b848a090c506665344bb27',
  imageFolder: 'tulpen',

  landingPage: ['diy-geschenke'],

  name: {
    de: ' Tulpe',
    en: ' Tulip',
  },
  id: '3-16-blog',
  pdf: {
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'content/tulpen/papier-blumen-basteln-tulpen.jpg',
        en: 'content/tulpen/papier-blumen-basteln-tulpen.jpg',
      },
      alt_text: {
        de: 'DIY Tulpen basteln aus Papier ',
        en: 'Learn how to make paper tulips',
      },
    },
  },
  meta_title: {
    de: 'Papier Blumen basteln: Einfache Tulpen (mit Vorlage)',
    en: 'How to make a paper tulip (template)',
  },
  meta_description_google: {
    de: 'Jetzt ganz einfach Papier Blumen basteln und in den Frühling starten mit diesen schönen Tulpen. Die Anleitung und Vorlage gibt es hier.',
    en: 'Make these amazing paper tulips with the template for spring. ',
  },
  meta_description_social: {
    de: 'Blumen basteln aus Papier zum Frühling und Ostern. Diese einfachen Tulpen sind dank der Vorlage schnell selbst gemacht und verleihen eurer Tischdeko Farbe und Frische. Ihr habt Kinder im Haus, dann nichts wie ran dieses DIY Projekt.',
    en: 'Make these amazing paper tulips with the template for spring.',
  },
  meta_images_social: [
    {
      de: 'blumen-basteln-aus-papier-mit-kindern-vorlage.jpg',
      en: 'blumen-basteln-aus-papier-mit-kindern-vorlage.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'papier-blumen-basteln-tulpen.jpg',
      en: 'papier-blumen-basteln-tulpen.jpg',
    },
    alt_text: {
      de: 'Blumen basteln aus Papier: mit diesen Tulpen gestatltet ihr eure farbenfrohe Frühlingsdeko #bastelnmitpapier #frühlingsdeko #papercrafts #papercraft',
      en: 'Easy diy paper flowers for Easter or spring. Make these adorable tulips for table decoration #papercrafts #papercraft #paperflowers #mothersday #valentinesday',
    },
  },
  h1: {
    de: 'Papier Blumen basteln: einfache Tulpen als Geschenk (mit Vorlage)',
    en: 'How to make a paper tulip (template)',
  },
  description: {
    de: `
      <p>In diesem DIY Post zeige ich euch, wie ihr ganz einfach Papier Blumen basteln könnt.
       </p>
       <p>Zum Frühling gibt es nichts schöneres als frische, duftende und farbenfrohe Tulpen. 
       Damit ihr diese        schönen Blumen basteln könnt, habe ich für euch eine
       Anleitung samt Vorlage erstellt. </p>
      <p>Ihr könnt sie auch mit euren Kindern basteln. 
       </p>
       <p>Wem nach mehr Osterbasteln ist, der findet hier weitere Bastelideen aus Papier:</p>
       <p>
       <li>Die Einhorn Wimpern sind nach wie vor total im Trend. Mit diesen 
       <a href="/de/blog/wimpern-hase/">süßen Wimpern Häschen </a>habt ihr auch gleich eine schöne Osterverpackung.</li>
       <p><li>Wer das Osternest ein wenig größer mag, der ist mit der Alternative 
       des <a href="/de/blog/3d-papier-osterhasen-basteln/">Osterhasens aus Papier</a> gut
        beraten.</li></p>
       <p> <li>Kaninchen lieben die Einsamkeit ganz und gar nicht. Sie lieben die Zweisamkeit und pflegen sich gerne. 
       Deswegen schaut mal beim<a href="/de/product/2-03-sfk/"> berühmten Albrecht-Dürer Hasen</a> vorbei.</li></p>
       </p>
     
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: 'Blumen basteln aus Papier als Frühlingsdeko',
    en: 'DIY Paper Flowers: Easy tulips',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'blumen-basteln-papier-tulpen.jpg',
          en: 'blumen-basteln-papier-tulpen.jpg',
        },
        alt_text: {
          de: 'Tulpen Strauß aus Papier basteln und als Geschenk zum Muttertag oder Ostern verschenken #muttertag #papercrafts #bastelnmitpapier #papercraft',
          en: 'Use our template to create these cute tulips from paper. Try this diy project for spring decor or as a present for Mothers Day #mothersday #paperflowers #eastern #papercrafts #papercraft',
        },
      },
    },

    {
      image: {
        filename: {
          de: 'blumen-basteln-aus-papier.jpg',
          en: 'blumen-basteln-aus-papier.jpg',
        },
        alt_text: {
          de: 'Farbenfroher Blumenstrauß voller Tulpen aus Papier zum selber basteln. Mit der Vorlage und Anleitung geht das ganz einfach #papercrafts #bastelnmitpapier #muttertag #valentinstag',
          en: 'Colourful flower bouquet from paper for Eastern, Mothers Day or Valentines Day #mothersday #papercraft #papercrafts #paperflowers',
        },
      },
    },

    {
      image: {
        filename: {
          de: 'blumen-basteln-aus-papier-einfach.jpg',
          en: 'blumen-basteln-aus-papier-einfach.jpg',
        },
        alt_text: {
          de: 'Einfache Blumen basteln aus Papier mit Kindern. Anleitung und Vorlage nach dem Klick. #papercrafts #papercraft #bastelnmitpapier #bastelnmitkindern',
          en: 'Paper tulip bouquet for spring decoration. Make these adorable paper flowers by your own #papercrafts #mothersday #valentinesday #paperflowers',
        },
      },
    },
  ],

  stepsTitle: {
    de: 'Wie du einfache Blumen aus Papier basteln kannst - Anleitung',
    en: '',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_tulpe-volrage-ausschneiden.jpg',
          en: '01_tulpe-volrage-ausschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `1.	Drucke die Vorlage aus und schneide die abgedruckten Papierteile entlang jeder 
        durchgezogenen Linien aus. Auf der Vorlage sind zwei Tulpen abgedruckt.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '02_papier-blume-falten-kleben.jpg',
          en: '02_papier-blume-falten-kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `An den Blüten befinden sich Laschen, die auf der kleinen Skizze in der Vorlage gelb 
        markiert sind. Auf diese Laschen klebst du von hinten ein Klebepad auf oder du versiehst
        diese Stelle mit Kleber. Meine Empfehlung: verwende am besten festen Kleber, keinen flüssigen.
        Nun verklebst du die einzelnen Laschen mit den danebenliegenden Blüten, 
        sodass sie sich überlappen. Die Lasche sollte innen liegen und nicht nach außen zeigen.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '03_tulpenbluete-schneiden.jpg',
          en: '03_tulpenbluete-schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Für das Auge: schneide mit einer kleinen Schere die Spitzen der Tulpenblüten leicht 
        rundlich ab, sodass die typische Blütenform entsteht.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '04_tulpen-stempel-basteln.jpg',
          en: '04_tulpen-stempel-basteln.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Für die Stempel und Staubblätter: Schneide dir ein Rechteck von ca. 10x2cm zurecht. 
        Jetzt setzt du mit einer Schere senkrechte Schnitte ganz dicht nebeneinander ohne sie 
        abzuschneiden.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '05_steckstiel-tulpe-basteln.jpg',
          en: '05_steckstiel-tulpe-basteln.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Nimm nun den Steckstiel zur Hand und umwickelt das soeben geschnittene Rechteck
         um die oberste Spitze. Verklebe es entweder direkt mit Flüssigkleber am Stiel oder
          mit einem kleinen Tesastreifen.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '06_papier-tulpe-blumen-basteln.jpg',
          en: '06_papier-tulpe-blumen-basteln.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Nun steckst du den Stiel durch die Blüte hindurch. Hier findest du einen Kreis 
        als Markierung für das Loch, das du am besten vorher mit einem spitzen Gegenstand
        einstichst. Wenn der Tulpenkopf am Stiel zu sehr wackelt, kannst du innen auf das 
        Loch einen Kleckser Flüssigkleber (alternativ Heißklebepistole) auftragen und den 
        Stempel mit den Staubblättern direkt an der Tulpe festkleben.
  `,
        en: ` `,
      },
    },
  ],
  video: {
    title: {
      de: 'Im Video zeige ich dir Schritt-für-Schritt, wie du diese Tulpen selber machen kannst',
      en: '',
    },
    description: {
      de: `Blumen im Frühling sind wunderschön. Mit dieser Vorlage kannst du deine Blumen aus 
      Papier ganz einfach selber basteln.  `,
      en: ``,
    },
    caption: {
      de: `Papierblumen basteln: einfache Tulpen`,
      en: `Paper flowers: simple tulips from paper`,
    },
    videoid: 'D00P3-ln_Ls',
  },
  downloadName: 'vorlage-tulpen_papershape.pdf',
  materials: [
    {
      de: '1 DINA4 Papier in beliebiger Farbe (bis zu 130g/m²)',
      en: '1 colored papers in colours of your choice (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber. doppelseitige Klebepads oder/ und Heißklebepistole',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Steckdraht mind. 1,5mm Durchmesser ',
      en: 'stretched wire',
    },
    {
      de: 'Gelbes Papier (max.130g/m²) für die Tulpen-Stempel und Staubblätter in der Größe 10 x 2 cm',
      en: 'Yellow Paper',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'papier-blumen-basteln-tulpen-einfach.jpg',
          en: 'papier-blumen-basteln-tulpen-einfach.jpg',
        },
        alt_text: {
          de: 'Einfache Blumen basteln aus Papier mit Kindern - die Anleitung und Vorlage gibt es hier #papercrafts #bastelnmitpapier #bastelnmitkindern #muttertag',
          en: 'How to make easy paper flowers: follow these simple steps and template to make your own tulips #mothersday #valentinesday #papercraft #papercrafts #paperflowers',
        },
      },
    ],
  },
};
