import materials from './materials';

export default {
  url: 'schallplatten-uhr-basteln',
  countermark: 'vg07.met.vgwort.de/na/46cd299369344de58283f283145da9fb',
  imageFolder: 'schallplatten-uhr',
  landingPage: ['diy-geschenke'],

  meta_title: {
    de: 'Uhr basteln aus alten Schallplatten mit rätselhafter Zeit (Upcycling)',
    en: 'How to make a wall clock with an old vinyl record (upcycling idea)',
  },
  meta_description_google: {
    de: `In dieser Anleitung zeige ich euch, wie ihr aus einer alten Schallplatte eine Uhr mit Kreuzworträtsel-Optik bastelt.   `,
    en:
      'In this tutorial you learn how to make a wall clock with an old vinyl record and a cool look of a crossword puzzle.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: 'schallplatten-uhr-basteln.jpg',
      en: 'vynil-record-clock.jpg',
    },
    alt_text: {
      de:
        'Uhr basteln für die Wand aus alter Schallplatte und Kreuzworträtsel. Vorlage von #papershape',
      en:
        'upcycling idea: wall clock from an old vinyl record and crossword puzzles. free template from #papershape ',
    },
  },
  h1: {
    de: 'Uhr basteln aus alten Schallplatten mit rätselhafter Zeit (Upcycling)',
    en: 'How to make a wall clock with an old vinyl record (upcycling idea)',
  },
  description: {
    de: `
  
     <p>Eine Uhr aus einer alten Schallplatte zu basteln ist vintage-like.</p>
      In dieser Anleitung zeige ich euch, wie ihr die Schallplatte mit einem coolen Kreuzworträtsel verseht und damit eine originelle Uhr für die Wand selber macht.

      
      Ein weiteres Upcycling DIY findet ihr 
      <p><a href="/de/blog/tasche-selber-machen/">hier mit der Tasche aus Zeitschriften</a>.</p>
      
      
       
      
    
    `,
    en: `
     <p>
    This is a vintage-like clock for your kitchen.
     </p>
     <p>
     In this tutorial you learn how to use vinyl records for making a vintage-like clock. It is a super cool upcycling diy and using the look of a  crossword puzzles gives this clock
     a unique look.
     </p>

     <p>
         And for more upcycling ideas check out this <a href="/en/blog/tasche-selber-machen/">cool candy wrapper bag</a>.
     </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [],

  stepsTitle: {
    de: 'Wie bastel ich eine Uhr aus einer Schallplatte - Anleitung',
    en: 'How to make a clock with a vinyl record - instructions',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'selbstklebefolie.jpg',
          en: 'selbstklebefolie.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Die Vorlage für die Uhr ausdrucken oder selber gestalten und mit einer Schere bzw. Kreisschneider ausschneiden.
        Die Selbstklebefolie auf dem Tisch ausbreiten und alle vier Enden beschweren. 
        Nun die eine Seite der Folie abziehen und die Vorlage von links nach rechts aufkleben.
        Ich habe mit einer Visa-Karte kleine Bläschen glatt gestrichen, bevor ich mit dem weiteren Aufkleben fortgefahren bin.
        Nun die laminierte Vorlage erneut ausschneiden.    
        
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'spruehkleber.jpg',
          en: 'spruehkleber.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Die Schallplatte von möglichen Staubfuseln befreien und mit dem Sprühkleber dünn besprühen. 
        Die laminierte Vorlage für die Uhr auf die Schallplatte aufkleben. 
        Hier habe ich ebenfalls von links nach rechts bzw. von oben nach unten geklebt und mit der Visa-Karte glatt gestrichen. 
          `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'uhrwerk.jpg',
          en: 'uhrwerk.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `
        Das Loch der Schalttplatte ist häufig zu klein, um das Uhrwerk durchzustecken. Deswegen von hinten das Loch vorsichtig mit einer Nagelschere vergrößern.
        Das Uhrwerk nach Anleitung zusammenbauen.  `,
        en: ``,
      },
    },
  ],

  downloadName: 'uhr-schallplatte_papershape.pdf',
  materials: [
    {
      de: '1 DINA3 Papier in weiß (ca. 230g/m²)',
      en: '1 sheet of paper (DINA3) (weight: 65lb-110lb)',
    },

    {
      de: 'Selbstklebefolie',
      en: 'self-adhesive foil',
    },

    {
      de: 'Schallplatte (Durchmesser 20cm)',
      en: 'Vinyl record',
    },

    {
      de: 'Sprühkleber',
      en: 'Spray adhesive',
    },

    {
      de: 'Uhrwerk (z.B. von Junghaus)',
      en: 'Clockwork',
    },

    materials.glue,
    materials.scissors,
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'uhr-selber-basteln-schallplatte-upcycling.jpg',
          en: 'wall-clock-vynil-record-upcycling.jpg',
        },
        alt_text: {
          de:
            'Uhr basteln für die Wand aus alter Schallplatte und Kreuzworträtsel. Vorlage von #papershape',
          en:
            'upcycling idea: wall clock from an old vinyl record and crossword puzzles. free template from #papershape ',
        },
      },
    ],
  },
};
