export default {
  id: '2-26-sfk',
  category: ['christmas'],
  name: {
    de: 'Engel',
    en: 'Angel',
  },
  subtitle: {
    de: 'B19 x H34 x T14 cm',
    en: 'W19 x H34 x D14 cm',
  },
  meta_title: {
    de: '3d Papier Engel',
    en: '3d Paper  Angel',
  },
  meta_description_google: {
    de: 'Möchtest du einen stimmungsvollen Engel aus Papier basteln? Dann haben wir genau die richtige Vorlage für dich und deine liebevolle Weihnachtsdeko.',
    en: 'Would you like to make an atmospheric angel out of paper? Then we have exactly the right template for you and your loving Christmas decorations.',
  },
  meta_description_social: {
    de: 'Weihnachtsengel basteln aus Papier',
    en: 'Craft your Christmas Angel from Paper',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?    
    - 5 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -5 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 5 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 5 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 5 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 5 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkengel/engel-basteln-aus-papier.jpg',
        en: 'products/sfkengel/engel-basteln-aus-papier.jpg',
      },
      alt_text: {
        de: 'Eengel basteln',
        en: 'Angel from Paper',
      },
    },
    price: 9.9,
    priceThirdCountry: 9.9,
  },

  choose: {
    image: {
      filename: {
        de: 'products/sfkengel/engel-basteln-aus-papier.jpg',
        en: 'products/sfkengel/engel-basteln-aus-papier.jpg',
      },
      alt_text: {
        de: 'Weihnachtsengel basteln aus Papier',
        en: 'Christmas Angel from Paper ',
      },
    },
    colors: {
      color1: {
        de: 'Engel',
        en: 'Engel',
      },
    },
    disableColors: {
      color1: ['schwarz', 'tuerkis', 'anthrazit'],
    },
    price: 19.9,
    priceThirdCountry: 19.9,
  },
};
