export default {
  id: '2-17-sfk',
  category: [ 'home_decor'],
  name: {
    de: 'Kürbis (2 Stk)',
    en: 'Pumpkin (2 pc)',
  },
  subtitle: {
    de: 'B26 x H25 x T27 cm',
    en: 'W26 x H25 x D27 cm',
  },
  meta_title: {
    de: '3d Papier Kürbis ',
    en: '3d paper pumpkin',
  },
  meta_description_google: {
    de: 'Mit diesem 3d Papierkürbis kannst du den echten Kürbis endlich dafür verwenden, wofür er gedacht ist: zum Kochen. Mit der PaperShape Vorlage bastelst du dir deinen Papier Kürbis für den Herbst oder Halloween ganz einfach selbst. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'Low poly 3d paper pumpkin for halloween oder fall decor. Super fun fall craft with the template of PaperShape. 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: 'DIY 3D Papier Kürbis als Herbstdeko, Lampe oder für Halloween basteln - Vorlage #papershape ',
    en: 'fall decor craft: 3d paper pumpkin with template #papershape ',
  },
  description: {
    de: `KOMPLETTES SET BEINHALTET

    9 DIN A4 Seiten Fotokarton (großer Kürbis)
     Größe: B26 x H25 x T27 cm 
    5 DIN A4 Seiten Fotokarton (kleiner Kürbis)
     Größe: B18 x H16 x T19 cm 
    Doppelseitige Klebepads
    Detaillierte schrittweise Anleitung

    Diese Herbstdeko aus Papier ist einzigartig, denn ihr könnt sie als Tischdeko, als Lampe oder Halloween-Kürbis verzieren. Schneiden, falten, kleben! Schaut euch die Anleitung im Video an.
    DETAILS : Altersempfehlung ab 14 Jahren
BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser

    `,
    en: ` COMPLETE KIT INCLUDES

    9 DIN A4 cardboard sheets (big pumpkin)
    Size: W26 x H25 x D27 cm
    5 DIN A4 cardboard sheets (small pumpkin)
    Size: W18 x H16 x D19 cm
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
This 3d paper pumpkin ist unique. You can use it as tabel decor, as a lamp or you decorate it for Halloween. Be creative and start with cutting, folding and sticking. Also watch the video tutorial.

DETAILS: Age recommendation from 14 years
REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife`,
  },
  colorinspirations: [
    {
      caption: {
        de: 'orange mit angesprühtem grünen Stiel (nachträglich)',
        en: 'pearl white',
      },
      image: {
        filename: {
          de: 'products/sfkkuerbis/3d-kuerbis-papier-deko-basteln.jpg',
          en: 'products/sfkkuerbis/kuerbis.jpg',
        },
        alt_text: {
          de: 'DIY 3D Papier Kürbis als Herbstdeko, Lampe oder für Halloween basteln - #papershape',
          en: 'fall decor craft: 3d paper pumpkin with template  #papershape ',
        },
      },
    },
    {
      caption: {
        de: 'Größenverhältnis kleiner und großer Kürbis',
        en: 'Relation of small and big pumpkin',
      },
      image: {
        filename: {
          de: 'products/sfkkuerbis/papierkuerbis.jpg',
          en: 'products/sfkkuerbis/papierkuerbis.jpg',
        },
        alt_text: {
          de: 'DIY 3D Papier Kürbis als Herbstdeko, Lampe oder für Halloween basteln - #papershape',
          en: 'fall decor craft: 3d paper pumpkin with template  #papershape',
        },
      },
    },
    {
      caption: {
        de: 'links: chamois, rechts: perlweiß',
        en: 'left: chamois, right: pearl white',
      },
      image: {
        filename: {
          de: 'products/sfkkuerbis/kuerbis-papier-3d.jpg',
          en: 'products/sfkkuerbis/kuerbis-papier-3d.jpg',
        },
        alt_text: {
          de: 'DIY 3D Papier Kürbis als Herbstdeko, Lampe oder für Halloween basteln - #papershape',
          en: 'fall decor craft: 3d paper pumpkin with template  #papershape',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkkuerbis/3d-papier-kuerbis.jpg',
        en: 'products/sfkkuerbis/3d-papier-kuerbis.jpg',
      },
      alt_text: {
        de: 'DIY 3D Papier Kürbis als Herbstdeko, Lampe oder für Halloween basteln - #papershape',
        en: 'fall decor craft: 3d paper pumpkin with template  #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Großer Kürbis',
        en: 'Big pumpkin',
      },
      color2: {
        de: 'Kleiner Kürbis',
        en: 'Small pumpkin',
      },
    },
    disableColors: {
      color2: ['schwarz', 'tuerkis', 'anthrazit'],
      color1: ['schwarz', 'tuerkis', 'anthrazit'],
    },
    price: 19.9,
    priceThirdCountry: 16.9,
  },
};
