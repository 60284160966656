import materials from './materials';

export default {
  url: '3d-stern-falten',
  countermark: 'vg07.met.vgwort.de/na/54fdb04e4e7349478de4c2ae28059675',
  imageFolder: 'bascettastern',

  name: {
    de: 'Großer Stern',
    en: 'Star paper craft',
  },
  id: '3-27-blog',
  pdf: {
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'content/bascettastern/3d-star-paper-bascetta.jpg',
        en: 'content/bascettastern/3d-star-paper-bascetta.jpg',
      },
      alt_text: {
        de: 'Sehr großer Stern a la Bascetta ',
        en: 'Learn how to paper craft a huge star',
      },
    },
  },

  meta_title: {
    de: 'Riesen 3d Stern falten a la Bascetta ohne Zusammenstecken',
    en: 'Step by step: How to make a huge 3d star from paper for Christmas ',
  },
  meta_description_google: {
    de: 'Mit dieser Anleitung und Vorlage zeige ich euch, wie ihr ganz einfach einen 3d Stern aus Papier a la Bascetta-Stern bastelt und das ganz ohne Zusammentecken. Für Weihnachten könnt ihr ihn auch beleuchten. ',
    en: ' In this tutorial I show you how to make an easy 3d Paper Star for Christmas from paper. ',
  },
  meta_description_social: {
    de: 'DIY 3d Weihnachtsstern aus Papier à la Bascetta basteln - ganz ohne Zusammenstecken #papershape #bastelnmitpapier',
    en: 'DIY 3d paper star for Christmas with template #papershape ',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: '3d-stern-falten-bascetta.jpg',
      en: '3d-star-paper-bascetta.jpg',
    },
    alt_text: {
      de: 'DIY Weihnachten: einfachen 3d Stern aus Papier mit 20 Spitzen wie beim Bascetta-Stern selber basteln #papershape ',
      en: ' DIY 3d paper star for Christmas with template #papershape  ',
    },
  },
  h1: {
    de: 'Riesen 3d Stern falten a la Bascetta ohne Zusammenstecken',
    en: 'Step by step: How to make a huge 3d star from paper for Christmas ',
  },
  description: {
    de: `
      <p> Was ist ein Bascetta-Stern? </p>
      <p>Es handelt sich dabei um ein modulares Origami, 
      bei dem einzelne Papierdreiecke so ineinander gesteckt werden, das am Ende ein Stern mit 20 Spitzen entsteht.
      Der Namensgeber und Erfinder ist Paolo Bascetta. </p>
      
      <p>Anders als bei Bascetta, zeige ich euch mit diesem einfachen Stern , wie ihr einen Weihnachtsstern aus Papier mit 20 Zacken selber macht
       und dabei einfach nur schneiden, falten und verkleben müsst. Beim Zusammenkleben der Zahlenpaare könnt ihr euch also nicht vertun.
      </p>
       <p>Vorgestellt habe ich diesen <a href="https://www.swr.de/buffet/kreativ/3-d-weihnachtsstern-aus-papier/-/id=257204/did=20477992/nid=257204/6jqkki/index.html" target="_blank">Weihnachtsstern beim ARD-Buffet</a>. Meinen Live-Gastauftritt gibt es unten als Video zu sehen.
      <p>Stern Größe: 30x30cm </p>
      
      
      <p>Passend dazu solltet ihr euch unbedingt diese winterlichen DIY Ideen aus Papier ansehen:</p>
      <p>
      <li><a href="/de/blog/3d-schneeflocke-basteln/">Schneeflocken in Kristalloptik</a></li> 
      <li><a href="/de/blog/nikolaus-knallbonbon-basteln/">lachende Nikoläuse als Knallbonbon verschenken</a></li>
      <li><a href="/de/blog/ombre-weihnachtskranz-basteln/">origineller Ombré Weihnachtskranz</a></li>
      </p>
    
    `,
    en: `
      <p>
     In this tutorial I'll show you how to make a goergous 3d star from paper. The star has a size of 30 cm - so it is really big. Use it as a lovely decor for Christmas. You can hang it or put in on a table with some led lamps inside. Enjoy!
      </p>
          
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '3d-stern-basteln-bascetta.jpg',
          en: '3d-paper-star-bascetta.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'papier-stern-basteln-vorlage.jpg',
          en: 'paper-star-folding-template.jpg',
        },
        alt_text: {
          de: ``,
          en: '',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: 'beleuchtet-3d-weihnachtsstern-basteln-papier.jpg',
          en: '3d-christmas-star-lighting.jpg',
        },
        alt_text: {
          de: ``,
          en: '',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
  ],

  stepsTitle: {
    de: 'Wie bastel ich einen 3d Stern zu Weihnachten - Anleitung',
    en: 'How to make a 3d paper Christmas star from paper - instructions',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01.jpg',
          en: '01.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: ` Drucke die Vorlagen S. 1-10 aus und schneide die abgedruckten Papierteile entlang jeder durchgezogenen Linien aus. Es gibt einen Stern mit Muster und den anderen ohne.`,
        en: `  `,
      },
    },
    {
      image: {
        filename: {
          de: '02.jpg',
          en: '02.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: 'Lege ein Lineal an die gestrichelten Linien an und falze sie, indem du mit der Rückseite eines Buttermessers oder mit einer Ahle an ihnen entlang fährst. Die Miniaturansicht auf S. 1 verdeutlicht, wo du genau falten musst. Die kurz gestrichelten Linien werden zu dir hin geknickt, die lang gestrichelten Linien von dir weg.',
        en: ' ',
      },
    },

    {
      image: {
        filename: {
          de: '03.jpg',
          en: '03.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: ' Beginne damit pro Vieleck die Spitzen zu verkleben. Diese haben keine Zahlenpaare.',
        en: ` `,
      },
    },

    {
      image: {
        filename: {
          de: '04.jpg',
          en: '04.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: `Anschließend kannst du mit jeder beliebigen angrenzenden Nummer fortfahren. Das Verkleben erfolgt hier nicht in nummerischer Reihenfolge. Auf dem Bild z.B. wird die Nr. 10 mit der 10 verklebt.
        Es gibt immer ein Zahlenpaar. Du kannst starten, wo du magst. Wichtig ist jedoch, dass du die Dreiecke von Seite 10 ganz zum Schluss anbringst. Das Ende ist ein wenig herausfordernd, da nicht mehr so viel Freiraum zum Verkleben besteht. Zuerst bringst du am besten das Dreieck mit der Nr. 7 und ganz zum Ende hin das mit der Nr. 5 an. Das letzte Dreieck wird gesteckt.
        Jetzt kannst du den Weihnachtsstern entweder aufhängen oder als Deko einfach hinstellen.`,

        en: ``,
      },
    },
  ],
  video: {
    title: {
      de: 'Mein Gastauftritt beim ARD-Buffet zum Thema Weihnachtsstern à la Bascetta',
      en: '',
    },
    description: {
      de: `Hier seht ihr nochmal als Video-Anleitung, wie der 3d Stern gebastelt wird.  `,
      en: ``,
    },
    caption: {
      de: ``,
      en: ``,
    },
    videoid: 'L6hzVm-ao8E',
  },

  downloadName: '3dstern.zip',
  materials: [
    {
      de: '10 DINA4-Papierbögen (300g/m²) in deiner Wunschfarbe ',
      en: '10 paper sheets in your favorite color (300g)',
    },

    {
      de: 'doppelseitiges Klebeband',
      en: 'self-adhesive tape',
    },

    {
      de: 'Stopfnadel',
      en: 'darning needle',
    },

    materials.scissors,
    materials.ruler,
    materials.mat,
  ],
};
