import React, { Component } from 'react';
import Lang from '../common/Lang';
import Message from '../common/Message';
import PsMeta from '../common/PsMeta';
import PsProductListByCategory from '../common/PsProductListByCategory';
import { getTextBySelectedLang } from '../data/lang';

class NewsletterSuccessful extends Component {

  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang(
            'Anmeldung erfolgreich',
            'Registration successful'
          )}
          noindex
        />

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="page-header">
                  <Lang
                    de={`Anmeldung erfolgreich`}
                    en={`Registration successful`}
                  />
                </h1>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                  <Message message={{success: getTextBySelectedLang(
                      'Die Anmeldung zum Newsletter war erfolgreich.',
                      'Your subscription to the newsletter was successful.'
                    )}}  />
              </div>
            </div>

          </div>

            <PsProductListByCategory open />
      </div>
    );
  }
}

export default NewsletterSuccessful;
