import { selectedLang } from '../../data/lang';

export const NEWSLETTER_SIGNIN_FORM_SEND_SUCCESSFUL = 'NEWSLETTER_SIGNIN_FORM_SEND_SUCCESSFUL';

export function newsletterSignInSendSuccessful() {
  return {
    type: NEWSLETTER_SIGNIN_FORM_SEND_SUCCESSFUL,
  };
}

export function createNewsletterSignIn(formValues) {
  return async (dispatch) => {
    // console.info('submitted: ', formValues);

    await dispatch({
      fetch: {
        body: {
          type: 'CreateNewsletterSignIn',
          payload: {
            email: formValues.email,
            surname: formValues.surname,
            language: selectedLang,
          },
        },
      },
    });
    dispatch(newsletterSignInSendSuccessful());
  };
}
