export const FETCH_START = 'FETCH_START';
export const FETCH_ERROR = 'FETCH_ERROR';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';

export function fetchStart() {
  return {
    type: FETCH_START,
  };
}

export function fetchError(customMessage, error) {
  return {
    type: FETCH_ERROR,
    payload: {
      customMessage,
      error: typeof error === 'string' ? error : JSON.stringify(error, null, 4),
    },
  };
}

export function validationError(errorText) {
  return {
    type: VALIDATION_ERROR,
    payload: {
      errorText,
    },
  };
}
