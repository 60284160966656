import React from 'react';
import { connect } from 'react-redux';
import { getVoucherMessage } from '../state/fetch/fetchSelectors';
import Message from './Message';

function VoucherMessage({ message }) {
  return <Message message={message} />;
}

const mapStateToProps = (state) => {
  return {
    message: getVoucherMessage(state),
  };
};

export default connect(mapStateToProps)(VoucherMessage);
