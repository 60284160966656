import React from 'react';
import { useParams } from 'react-router-dom';

export default function Currency({ value = 0.0 }) {
  const { lang } = useParams();
  const isEn = lang === 'en';
  return (
    <span>
      {!isEn ? (
        <span itemProp="price" content={value.toFixed(2).toString()}>
          {value
            .toFixed(2)
            .toString()
            .replace('.', ',')}
        </span>
      ) : (
        <span itemProp="price" content={value.toFixed(2).toString()}>
          {value.toFixed(2).toString()}
        </span>
      )}

      <span itemProp="priceCurrency" content="EUR">
        {' '}
        &euro;
      </span>
    </span>
  );
}
