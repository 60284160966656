import React from 'react';
import PsLabel from './PsLabel';

export default function PsInput({ input, label, meta, placeholder, ...rest }) {
  return (
    <PsLabel label={label} meta={meta}>
      <textarea
        {...input}
        className="form-control"
        placeholder={placeholder || label}
        {...rest}
      />
    </PsLabel>
  );
}
