import { flatten } from 'lodash/array';
import Raven from 'raven-js';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect-change-memoize';
import products from '../../data/products';
import blogPosts from '../../data/blogPosts';
import colors from '../../data/colors';
import { getVoucher } from '../voucher/voucherSelectors';
import { round } from 'lodash/math';
import { groupBy, sortBy } from 'lodash/collection';
import find from 'lodash/find';
import includes from 'lodash/includes';
import categories from '../../data/categories.json';

export const freeShippingVolume = 45;

export const getOrderNumber = (state) => state.order.general.orderNumber;
export const getOrder = (state) => state.order.general.data;
const getOrderProducts = (state) => state.order.products;
export const getProducts = () =>
  products.concat(
    blogPosts.filter((blogPost) => blogPost.id != null && blogPost.pdf != null)
  );
export const getColors = () => colors;

export const getCategories = () => categories;

const getProductsByCategory = (products, category) =>
  products.filter((product) => includes(product.category, category));

export const getProductsGroupByCategory = createSelector(
  [getProducts, getCategories],
  (products, categories) =>
    groupBy(
      sortBy(
        flatten(
          products
            .filter((p) => p.category != null)
            .map((p) => p.category.map((c) => ({ ...p, singleCategory: c })))
        ),
        (p) => categories[p.singleCategory].prio
      ),
      (p) => p.singleCategory
    )
);

export const getProductId = (state, props) => props.productId;
export const getDigitalProduct = (state, props) => props.isDigitalProduct;
export const getProductVariantByIdAndVariantKey = createSelector(
  [getProducts, getProductId, getDigitalProduct],
  (products, productId, isDigitalProduct) => {
    const product = getOrderProductById(products, productId);
    if (isDigitalProduct) {
      return product.pdf;
    } else if (product.choose) {
      return product.choose;
    }
    return null;
  }
);

export const orderFormName = 'order';
const orderFormSelector = formValueSelector(orderFormName);
export const getColor1 = (state) => orderFormSelector(state, 'color1');
export const getColor2 = (state) => orderFormSelector(state, 'color2');
export const getSelectedVariantKey = (state) =>
  orderFormSelector(state, 'selectedVariantKey');
export const isDigitalProductSelected = (state) =>
  orderFormSelector(state, 'kind') === 'pdf';
export const getAlternativeShippingAddress = (state) =>
  orderFormSelector(state, 'alternativeShippingAddress');
export const getVoucherCode = (state) =>
  orderFormSelector(state, 'voucherCode');
export const getName = (state) => orderFormSelector(state, 'name');
export const getSurname = (state) => orderFormSelector(state, 'surname');
export const getEmail = (state) => orderFormSelector(state, 'email');
const getShippingAddressCountry = (state) =>
  orderFormSelector(state, 'shippingAddress.country');
const getBillingAddressCountry = (state) =>
  orderFormSelector(state, 'billingAddress.country');
export const getShippingCountryKey = createSelector(
  'getShippingCountryKey',
  [
    getShippingAddressCountry,
    getBillingAddressCountry,
    getAlternativeShippingAddress,
  ],
  (shippingAddressCountry, billingAddressCountry, alternativeShippingAddress) =>
    (alternativeShippingAddress
      ? shippingAddressCountry
      : billingAddressCountry) || 'de'
);

export const getOrderProductById = (products, id) => {
  return find(products, (product) => product.id === id);
};

export const getOrderProductByIdAndVariantKey = (
  products,
  id,
  variantKey,
  isDigitalProduct
) => {
  return find(
    products,
    (product) =>
      product.id === id &&
      product.variantKey === variantKey &&
      product.isDigitalProduct === isDigitalProduct
  );
};

export const isEuShipping = createSelector(
  'isEuShipping',
  [getShippingCountryKey],
  (countryKey) =>
    !includes(['ch', 'us', 'no', 'br', 'ca', 'ru', 'gb'], countryKey)
);

export const getProductPrice = createSelector(
  'getProductPrice',
  [getProductVariantByIdAndVariantKey, isEuShipping],
  (productVariant, euShipping) => {
    return euShipping ? productVariant.price : productVariant.priceThirdCountry;
  }
);

export const getVariantKey = (product, orderProduct) =>
  orderProduct.isDigitalProduct
    ? null
    : product.choose
    ? orderProduct.color1 + '_' + orderProduct.color2
    : null;

const getColorDescription = (
  colors,
  productColors,
  orderProduct,
  lang,
  colorNr
) =>
  productColors[colorNr][lang] +
  ' in ' +
  colors[orderProduct[colorNr]].description[lang];

const getVariantName = (colors, productColors, orderProduct, lang) =>
  getColorDescription(colors, productColors, orderProduct, lang, 'color1') +
  (orderProduct.color2
    ? ' / ' +
      getColorDescription(colors, productColors, orderProduct, lang, 'color2')
    : '');

export const getOrderProductEnhanced = createSelector(
  'getOrderProductEnhanced',
  [(state) => state, getProducts, getColors, (state, props) => props],
  (state, products, colors, orderProduct) => {
    const product = getOrderProductById(products, orderProduct.id);
    // console.log('orderProduct', orderProduct, product);
    // product doesn't exist anymore
    if (product == null) {
      return null;
    }
    let skuPostfix, image, variantName;
    if (orderProduct.isDigitalProduct) {
      skuPostfix = 'pdf';
      image = product.pdf.image;
      variantName = '';
    } else if (product.choose) {
      if (!colors.hasOwnProperty(orderProduct.color1)) {
        Raven.captureMessage(
          `color1 [${orderProduct.color1}] is not a valid color`,
          { extra: { orderProduct, colors } }
        );
      }
      skuPostfix =
        colors[orderProduct.color1].skuPostfix +
        (orderProduct.color2 ? colors[orderProduct.color2].skuPostfix : '');
      image = product.choose.image;
      const productColors = product.choose.colors;
      variantName = {
        de: getVariantName(colors, productColors, orderProduct, 'de'),
        en: getVariantName(colors, productColors, orderProduct, 'en'),
      };
    }
    const productPrice = getProductPrice(state, {
      productId: product.id,
      isDigitalProduct: orderProduct.isDigitalProduct,
    });
    return {
      id: product.id,
      sku: product.id + '-' + skuPostfix,
      name: product.name,
      category: product.category,
      image: image,
      image_alt_text: product.image_alt_text,
      variantKey: getVariantKey(product, orderProduct),
      variantName: variantName,
      color1: orderProduct.color1,
      color2: orderProduct.color2,
      quantity: orderProduct.quantity,
      pricePerUnit: productPrice,
      subTotal: round(productPrice * orderProduct.quantity, 2),
      isDigitalProduct: orderProduct.isDigitalProduct,
    };
  }
);

export const getOrderProductsEnhanced = createSelector(
  'getOrderProductsEnhanced',
  [(state) => state, getOrderProducts],
  (state, orderProducts) =>
    orderProducts
      .map((orderProduct) => getOrderProductEnhanced(state, orderProduct))
      .filter((p) => p != null)
);

export const getOrderProductsCount = createSelector(
  'getOrderProductsCount',
  [getOrderProducts],
  (orderProducts) =>
    orderProducts
      .filter((orderProduct) => !orderProduct.isDigitalProduct)
      .reduce((accu, orderProduct) => round(accu + orderProduct.quantity, 0), 0)
);

export const getOrderProductsDigitalCount = createSelector(
  'getOrderProductsDigitalCount',
  [getOrderProducts],
  (orderProducts) =>
    orderProducts.filter((orderProduct) => orderProduct.isDigitalProduct).length
);

export const hasOnlyDigitalProducts = createSelector(
  'hasOnlyDigitalProducts',
  [getOrderProductsCount, getOrderProductsDigitalCount],
  (orderProductsCount, orderProductsDigitalCount) =>
    orderProductsCount === 0 && orderProductsDigitalCount > 0
);

export const getOrderSubtotalSum = createSelector(
  'getOrderSubtotalSum',
  [getOrderProductsEnhanced],
  (orderProductsEnhanced) =>
    orderProductsEnhanced.reduce(
      (accu, orderProductEnhanced) =>
        round(accu + orderProductEnhanced.subTotal, 2),
      0
    )
);

export const getVoucherAmount = createSelector(
  'getVoucherAmount',
  [getVoucher, getOrderSubtotalSum],
  (voucher, orderSubtotalSum) => {
    if (voucher && voucher.valid) {
      const { fixedAmount, percent } = voucher.calculation;
      if (fixedAmount) {
        if (orderSubtotalSum < fixedAmount) {
          return orderSubtotalSum;
        }
        return fixedAmount;
      }
      if (percent) {
        const voucherValue = (orderSubtotalSum * percent) / 100;
        return round(voucherValue, 2);
      }
    }
    return null;
  }
);

// aka orderSubtotalSumTotal
export const getOrderSubtotalSumWithVoucher = createSelector(
  'getOrderSubtotalSumWithVoucher',
  [getOrderSubtotalSum, getVoucherAmount],
  (orderSubtotalSum, voucherAmount) =>
    round(orderSubtotalSum - voucherAmount, 2)
);

export const isFreeShippingCountry = createSelector(
  'isFreeShippingCountry',
  [getShippingCountryKey],
  (countryKey) => {
    switch (countryKey) {
      case 'de':
        return true;

      default:
        return false;
    }
  }
);

export const getShippingCostsNotDe = createSelector(
  'getShippingCostsNotDe',
  [getOrderProductsCount],
  (orderProductsCount) => 11.9
);

export const getShippingCostsCountry = createSelector(
  'getShippingCostsCountry',
  [getShippingCountryKey, isEuShipping, getShippingCostsNotDe],
  (countryKey, isEuShipping, shippingCostsNotDe) => {
    if (!isEuShipping) {
      return shippingCostsNotDe;
    }

    switch (countryKey) {
      case 'de':
        return 5.9;

      default:
        return shippingCostsNotDe;
    }
  }
);

export const getShippingCosts = createSelector(
  'getShippingCosts',
  [
    getShippingCostsCountry,
    getOrderSubtotalSumWithVoucher,
    isFreeShippingCountry,
    hasOnlyDigitalProducts,
  ],
  (
    shippingCostsCountry,
    orderSubtotalSumWithVoucher,
    freeShippingCountry,
    onlyDigitalProducts
  ) => {
    if (onlyDigitalProducts) {
      return 0;
    }
    if (
      freeShippingCountry &&
      orderSubtotalSumWithVoucher >= freeShippingVolume
    ) {
      return 0;
    }
    return shippingCostsCountry;
  }
);

export const getOrderSumGross = createSelector(
  'getOrderSumGross',
  [getOrderSubtotalSumWithVoucher, getShippingCosts],
  (orderSubtotalSumWithVoucher, shippingCosts) =>
    round(orderSubtotalSumWithVoucher + shippingCosts, 2)
);

export const getVatPercent = createSelector(
  'getVatPercent',
  [isEuShipping],
  (isEuShipping) => {
    if (isEuShipping) {
      return 19.0;
    }
    return 0;
  }
);

export const getVatAmount = createSelector(
  'getVatAmount',
  [getOrderSumGross, getVatPercent],
  (orderSumGross, vatPercent) => {
    if (vatPercent) {
      return round((orderSumGross / (100 + vatPercent)) * 19, 2);
    }
    return 0;
  }
);

// Gross
// export const getOrderSumByShippingCountry = createSelector(
//   [getGrossOrderSum, getVatPercent],
//   (sumValue, vatPercent) => {
//     if (vatPercent > 0) {
//       return Math.round(sumValue / (1 + vatPercent) / 100);
//     }
//     return sumValue;
//   }
// );

// export const getOrderSumByShippingCountryNet = createSelector(
//   [getOrderSumGross, getVatPercent],
//   (sumValue, vatPercent) => {
//     if (vatPercent > 0) {
//       return sumValue;
//     }
//     return Math.round(sumValue / vatPercent + 100) * 100;
//   }
// );

// export const getVatAmount = createSelector(
//   [getOrderSumByShippingCountry, getOrderSumByShippingCountryNet],
//   (orderSumByShippingCountry, orderSumByShippingCountryNet) => {
//     return orderSumByShippingCountry - orderSumByShippingCountryNet;
//   }
// );

export const getDeliveryTime = createSelector(
  'getDeliveryTime',
  [getShippingCountryKey, isEuShipping],
  (shippingCountryKey, isEuShipping) =>
    shippingCountryKey === 'de' ? '3-5' : isEuShipping ? '20-22' : '22-25'
);

export const isOrderAllowed = createSelector(
  'isOrderAllowed',
  [isEuShipping, getOrderProductsDigitalCount],
  (isEuShipping, orderProductsDigitalCount) =>
    isEuShipping || orderProductsDigitalCount === 0
);
