export default {
  id: '2-32-sfk',
  category: ['home_decor'],

  name: {
    de: ' Paprika ',
    en: ' Sweet Pepper',
  },
  subtitle: {
    de: 'B14 x H20 x T13 cm',
    en: 'B14 x H20 x T13 cm',
  },
  meta_title: {
    de: '3D Papier Paprika zum Basteln',
    en: 'Papercraft a sweet pepper with our template',
  },
  meta_description_google: {
    de: 'Mit unserer Vorlage für die Papercraft Paprika gelingt dir dieses schöne Dekobojekt im Low Poly Design ganz leicht.',
    en: 'With our papercraft sweet bell pepper template, you can easily create this beautiful decorative object in a low poly design.',
  },
  meta_description_social: {
    de: '3D Obst und Gemüse basteln macht richtig Spaß, vor allem, wenn es als Deko nicht mal schlecht wird. Mit unserer Vorlage kannst du eine low poly Paprika aus Papier falten. Den Download dazu findest du in unserem Shop.',
    en: 'Crafting 3D fruits and vegetables is a lot of fun, especially when they don not spoil as decor. With our template, you can fold a low poly pepper from paper. You can find the download in our shop.',
  },

  pdf: {
    description: {
      de: `       
      3D Obst und Gemüse basteln macht richtig Spaß, vor allem, wenn es als Deko nicht mal schlecht wird. 
      Vegan und knackig! Mit unserer Vorlage kannst du eine low poly Paprika aus Papier falten.
         
      Als wäre sie von dir gerade aus dem Garten gepflückt, erstrahlt sie knackig und farbenfroh auf deinem Küchen- oder Esstisch. 
      Dank der Papierfasern erhält diese Low Poly Deko etwas wunderbar Natürliches.
           
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du mit deinem gesunden Kunstobjekt direkt loslegen. Ich empfehle dir 270/m² Tonkarton zu verwenden.
      Damit diese große Papierpaprika gelingt, benötigst du 4 DIN A4 Bögen Tonkarton, einen Drucker, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `Vegan and crisp! Dive into a world of culinary creativity with our large 3D paper capsicum.

      As if freshly picked from your garden, it shines bright and colorful on your kitchen or dining table. Thanks to the paper fibers, this low poly decor item has a wonderfully natural feel.
      
      With the PDF template in DIN A4 or letter format for self-printing, you can start crafting your healthy art object right away. I recommend using 270gsm cardstock.
      
      To successfully create this large paper pepper, you will need 4 sheets of DIN A4 cardstock, a printer, scissors, and glue.
      
      This is a digital product. You will receive the download link via email immediately after payment. This will occur within an hour.`,
    },

    price: 6.9,
    priceThirdCountry: 6.9,

    image: {
      filename: {
        de: 'products/paprika/3d-paprika-papier-basteln.jpg',
        en: 'products/paprika/3d-paprika-papier-basteln.jpg',
      },
      alt_text: {
        de: 'Zwei große rote Paprikas aus Papier in 3D. ',
        en: 'Two big low poly sweet bell peppers from paper in 3D',
      },
    },
  },

  colorinspirations: [
    {
      caption: {
        de: 'Größenverhältnis',
        en: 'Size ratio',
      },
      image: {
        filename: {
          de: 'products/paprika/3d-paprika-verhaeltnis-apfel.jpg',
          en: 'products/paprika/3d-paprika-verhaeltnis-apfel.jpg',
        },
        alt_text: {
          de: '3D Paprika aus Papier basteln',
          en: '3D Paper Sweet Pepper',
        },
      },
    },
    {
      caption: {
        de: 'Paprika in tannengrün, gelb & rot',
        en: 'Sweet pepper in green, yellow and red.',
      },
      image: {
        filename: {
          de: 'products/paprika/papier-paprika-rot-gruen-gelb.jpg',
          en: 'products/paprika/papier-paprika-rot-gruen-gelb.jpg',
        },
        alt_text: {
          de: 'Papierpaprikas basteln in 3D ',
          en: 'Papercraft Sweet Peppers in 3D',
        },
      },
    },

    {
      caption: {
        de: 'Vorschau auf die Bastelvorlage',
        en: 'Preview of papercraft template',
      },
      image: {
        filename: {
          de: 'products/paprika/paprika-bastel-vorlage.jpg',
          en: 'products/paprika/paprika-bastel-vorlage.jpg',
        },
        alt_text: {
          de: '3D Paprika Bastelvorlage Vorschau PDF',
          en: '3D papercraft sweet pepper bell preview PDF ',
        },
      },
    },
  ],
};
