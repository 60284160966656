export default {
  url: 'schmetterlinge-aus-tonpapier-basteln',
  countermark: 'vg07.met.vgwort.de/na/52858e917a1842bb90d8de95f9e5929d',
  imageFolder: 'schmetterling-tonpapier',

  name: {
    de: 'Schmetterling aus Tonpapier',
    en: 'Butterfly from paper',
  },
  id: '3-12-blog',
  pdf: {
    price: 3.9,
    priceThirdCountry: 3.9,
    image: {
      filename: {
        de: 'content/schmetterling-tonpapier/schmetterlinge-aus-tonpapier-basteln-einfach.jpg',
        en: 'content/schmetterling-tonpapier/schmetterlinge-aus-tonpapier-basteln-einfach.jpg',
      },
      alt_text: {
        de: 'DIY Schmetterling basteln mit Papier zur Deko  #papershape #bastelnmitpapier #papercrafts #schmetterling #diygeschenke #frühlingsdekoy',
        en: 'Learn how to make simple butterfly from paper. Just donwload the template and create your modern wall decor. #papershape #butterfly.',
      },
    },
  },

  meta_title: {
    de: 'Einfache Schmetterlinge aus Tonpapier basteln (mit Vorlage)',
    en: 'Simple butterflies from coloured drawing paper for kids',
  },
  meta_description_google: {
    de: 'Lass den Frühling in dein Zuhause und bastel diese einfachen Schmetterlinge aus Tonpapier ganz einfach nach.',
    en: 'Learn how to make these simple butterflies from paper with kids',
  },
  meta_description_social: {
    de: 'Schmetterlinge basteln. Dekoration für den Frühling aus Tonpapier - super schnell mit der PaperShape Vorlage #papershape #schmetterling #diygeschenke #frühlingsdeko',
    ef3n: 'Learn how to make simple butterfly from paper. Just donwload the template and create your modern wall decor. #papershape #butterfly.',
  },
  meta_images_social: [
    {
      de: 'schmetterling-basteln-tonpapier-anfaenger.jpg',
      en: 'schmetterling-basteln-tonpapier-anfaenger.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'schmetterlinge-aus-tonpapier-basteln.jpg',
      en: 'schmetterlinge-aus-tonpapier-basteln.jpg',
    },
    alt_text: {
      de: 'DIY Schmetterling basteln mit Papier zur Deko  #papershape #bastelnmitpapier #papercrafts #schmetterling #diygeschenke #frühlingsdeko',
      en: 'butterfly crafts for kids from colured drawing paper #butterflies #papercraft  ',
    },
  },
  h1: {
    de: 'Schmetterlinge aus Tonpapier basteln',
    en: 'How to make butterflies from coloured drawing paper',
  },
  description: {
    de: `
          
      <p>Braucht deine Wohnung eine neue Dekokur? Weg vom winterlichen Hygge zu frühlingshafter Frische und leuchtenden Farben? 
      <p>Zum einen könnt ihr <a href="/de/blog/papier-tulpen-basteln/">frische Papiertulpen </a>aufstellen oder -auch ganz einfach- diese schönen Schmetterlinge als Frühlingsdeko aus Tonpapier basteln. </p>
         
      
      Wie ihr sie ganz schnell selber machen könnt, zeige ich euch in diesem DIY Blogpost.</p>
      <p> </p>
      <p></p>
      <p>Als Gesellschaft passen <a href="/de/blog/libelle-basteln-papier/">federleichte Libellen aus Papier</a> übrigens super zu den schönen Faltern.</p>
      <p>Wer eine weitere und leicht abgewandelte Variante der Schmetterlinge basteln möchte, der schaut einfach mal <a href="/de/product/2-11-sfk/"> hier im Shop vorbei.</a> </p>
    
  
    `,
    en: `
     
    `,
  },
  inspirationTitle: {
    de: 'Basteln mit Papier und Schere: Schmetterlinge aus Tonpapier basteln ',
    en: 'You have to try these butterflies',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'schmetterlinge-aus-tonpapier-selber-basteln.jpg',
          en: 'schmetterlinge-aus-tonpapier-selber-basteln.jpg',
        },
        alt_text: {
          de: 'Schmetterlinge basteln Vorlage von Papershape, um den Frühling willkommen zu heißen #schmetterling #diygeschenke #frühlingsdeko',
          en: 'butterfly crafts for adults with paper #butterflies #springdecor #papercraft',
        },
      },
    },
    {
      text: {
        de: `Ihr wollt Schmetterlinge basteln und das mit euren Kindern? Dafür ist die Vorlage gut geeignet. Kinder konzentrieren sich meiner Erfahrung nach
        sehr genau auf das Schneiden und trainieren ganz spielend ihre motorischen Fingerfertigkeiten. `,
        en: `These butterlfies are adorabel as both table decor and wall decor. You just have to decide where to put them.`,
      },
    },

    {
      image: {
        filename: {
          de: 'schmetterling-basteln-aus-tonpapier-diy.jpg',
          en: 'schmetterling-basteln-aus-tonpapier-diy.jpg',
        },
        alt_text: {
          de: 'Einfache Schmetterlinge basteln mit Kindern als Fensterdeko #schmetterling #diygeschenke #frühlingsdeko',
          en: 'butterfly crafts for infants #papercraft #diychildren #diytoddlers',
        },
      },
    },

    {
      text: {
        de: `Nicht nur an der Wand, sondern auch an einem kargen Ast lassen sich die Schmetterlingen super schön arrangieren. Haltet beim nächsten
        Wandern einfach die Augen nach einem schlichten Ast auf, die meistens zu Genüge auf dem Boden liegen.
       `,
        en: `These butterlfies are adorabel as both table decor and wall decor. You just have to decide where to put them.`,
      },
    },
    {
      image: {
        filename: {
          de: 'bunte-schmetterlinge-basteln-aus-tonpapier.jpg',
          en: 'bunte-schmetterlinge-basteln-aus-tonpapier.jpg',
        },
        alt_text: {
          de: 'Einfache Schmetterlinge basteln mit Kindern als Wanddeko #schmetterling #diygeschenke #frühlingsdeko',
          en: 'DIY butterfly crafts for adults - decorating your home #butterflies #diy #papercraft',
        },
      },
    },
    {
      text: {
        de: `Ihr findet mehrere Größen in der Vorlage, sodass ihr einen ganzen Schwarm von Schmetterlingen aus Papier selber machen und an der Wand
        befestigen könnt.
       `,
        en: `These butterlfies are adorabel as both table decor and wall decor. You just have to decide where to put them.`,
      },
    },
  ],
  stepsBackgroundColor: '',
  stepsTitle: {
    de: 'Anleitung: Schmetterlinge aus Tonpapier basteln',
    en: 'Tutorial: follow these steps for crafting paper butterflies',
  },
  steps: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Die Vorlage für die Schmetterlinge auf ein DIN A4 Tonpapier oder Tonkarton (120-160g/m²) in eurer Wunschfarbe ausdrucken.`,
        en: `Print the paper butterfly template (US letter) on paper of your choice.`,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Beginnt mit dem Schneiden. Mit einer Schere einfach alle Elemente entlang der durchgezogenen Linie der Schmetterlinge Vorlage ausschneiden.`,
        en: `Cut out the paper parts (A, B, C, ...) by cutting along the solid lines of the butterfly template.`,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Wie und wo ihr nun die Flügel falten solltet, seht ihr im Video weiter unten ganz genau.`,
        en: `How to fold the lines of the butterfly wings you will find in the video below. 
                    `,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Das kleine Flügelpaar klebt ihr nun auf das größere auf. In die Mitte positioniert ihr mit einem Klecks Kleber den 
        Körper des Schmetterlings. Voilá, fertig. `,
        en: `Glue the small pair of butterfly wings on the bigger one. Then place in the middle of the butterfly the body using some glue.`,
      },
    },
  ],
  video: {
    title: {
      de: 'Youtube Schmetterling basteln: eine einfache Video-Anleitung',
      en: '',
    },
    description: {
      de: `Bring den Frühling in dein Zuhause und bastel diese Schmetterlinge für dein Fenster oder die Wand.   `,
      en: ``,
    },
    caption: {
      de: `Schmetterlinge aus Tonpapier basteln`,
      en: `Paper butterflies: easy paper craft`,
    },
    videoid: 's9Z-gm9Wb0E',
  },

  downloadName: 'schmetterlinge-tonpapier-vorlage_papershape.pdf',
  materials: [
    {
      de: '1 DINA4 Tonpapier oder Tonkarton (120g-160g/m²)',
      en: '1 coloured drawing paper (8.5in x 11in) (weight: 65lb / 176gsm)',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert die DIY Idee für euer nächstes Projekt auf Pinterest',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'schmetterlinge-basteln-aus-tonpapier-kinder.jpg',
          en: 'schmetterlinge-basteln-aus-tonpapier-kinder.jpg',
        },
        alt_text: {
          de: 'Schmetterling basteln - Dekoration für Ostern und den Frühling.  #bastelnmitpapier #schmetterling #diygeschenke #frühlingsdekos',
          en: 'How to make paper butterfly for adults - great diy project for decorating your home for spring #papercraft #butterfly #diyspring ',
        },
      },
    ],
  },
};
