import materials from './materials';

export default {
  url: 'fussball-wm-diy',
  countermark: 'vg07.met.vgwort.de/na/9d5ccf85c9484db49c4cf19a3f7d8655',
  imageFolder: 'wmpokal',
  meta_title: {
    de: 'Fussball-DIY zur WM (mit kostenloser Vorlage)',
    en: 'Soccer-DIY for the World Cup (with template)',
  },
  meta_description_google: {
    de:
      'Hier kannst du dein Fussball-DIY zur WM selber machen. Die Vorlage gibt es gratis von PaperShape zum Download.',
    en:
      'Make your paper soccer must-have in just 3 easy steps with this free template from PaperShape.',
  },
  meta_description_social: {},
  meta_images_social: [{}],
  image_main: {
    filename: {
      de: 'fussball-diy-selber-machen-vorlage.jpg',
      en: '',
    },
    alt_text: {
      de:
        'Fussball-DIY basteln aus Papier (kostenlose Vorlage) / PaperShape #deko #papercraft #wm',
      en: 'Soccer DIY out of paper / PaperShape #diy ',
    },
  },
  h1: {
    de: 'Fussball-DIY zur WM (mit kostenloser Vorlage)',
    en: 'Soccer-DIY for the World Cup (with template)',
  },
  description: {
    de: `
      <p>
        Wie geil wäre es während des Public Viewings dieses Fussball-DIY präsentieren zu können?
       </p>
       <p>
       Zugegeben, ich bin immer vier Jahre kein Fußball-Fan und wenn die WM losgeht, kann ich richtig mitfiebern. 
        
      </p>
      <p>  
        Wenn ihr dieses Fussball-DIY bastelt, habt ihr eine echte coole Deko-Idee, falls ihr die Party daheim 
        veranstaltet. Die Trophy hat die Originalgröße von 36,8 cm Höhe und wenn ihr ihn stabiler gestalten möchtet, dann stopft ihn am besten mit Papier 
        oder Luftpolstfolie aus. Ich werde nach der WM unsere Freunde, mit denen wir uns die Spiele angesehen haben, darauf unterschreiben lassen.
      </p>
    `,
    en: `
      <p>
        Do you love parties and do you love decorating your home during the World Championship? Well, then this soccer diy out of paper
        is for you. It is easy to fold and it's original measurements make it a fun decor. 
      </p>
      <p>
        After each game you should let you friends sign on it. Cool memory, don't you think?
      </p>
    `,
  },
  inspirationTitle: {
    de: 'Fussball-DIY zur WM als Deko gestalten',
    en: 'Soccer DIY - How to',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'wm-basteln.jpg',
          en: 'wm-basteln.jpg',
        },
        alt_text: {
          de:
            'Fussball-DIY zur WM selber machen (inkl. Vorlage zum Ausdrucken)',
          en:
            'World Cup Party Ideas: Free printable for the soccer DIY out of paper / PaperShape #partyideas #soccer',
        },
      },
    },
    {
      text: {
        de:
          'Ob mit einer oder mit beiden Händen, dieses Fussball-DIY ist super leicht zu tragen und deswegen ein absolutes Must-have beim Public Viewing.',
        en:
          "Lift up this soccer diy either with one or with two hands. It's light weight makes it super easy to carry it around.",
      },
    },
    {
      image: {
        filename: {
          de: 'wm-maedchen-basteln.jpg',
          en: 'wm-maedchen-basteln.jpg',
        },
        alt_text: {
          de:
            'DIY WM basteln aus Papier - die Mädchenversion (kostenlose Vorlage) / PaperShape #deko #papercraft #wm',
          en:
            'Fun World Cup Party Idea: DIY World Cup trophy out of paper (free printable for girls only)/ PaperShape #partyideas #soccer',
        },
      },
    },
    {
      text: {
        de: `Girls, we run the world :D Ich persönlich finde die echten Farben echt öde und habe mir deswegen meine Variante selber gemacht. Like it?`,
        en: `Girls, we run the world :) Personally, I don't like the gold and green colors. Thats why I papercrafted my own girly version of it.`,
      },
    },
  ],
  stepsBackgroundColor: '#fcf3f3',
  stepsTitle: {
    de: 'Fussball-DIY zur WM basteln: Anleitung in 3 einfachen Schritten',
    en: '3 step tutorial for your soccer diy ',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'anleitungsschritt-bastelvorlage.jpg',
          en: 'step-paper-template.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Vorlage herunterladen',
          en: 'Step: Download template',
        },
      },
      text: {
        de: `Druckt die PDF-Vorlage für dieses Fussball-DIY auf DIN A4 Fotokarton aus. Ihr benötigt 6 Bögen. Wenn ihr ihn "echt" gestaltet möchtet, 
        dann druckt ihr die erste Seite auf grünem Papier (das sind die Streifen) und die restlichen 5 Seiten in gold.
        `,
        en: `Print the template (US letter or DIN A4) on paper of your choice. You'll need 6 sheets of paper (5 in gold and 1 in green
          if you wish to craft this soccer-diy in it's "realisitic look". Each printer should easily print cardstock up to 230gsm (80lb).`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-schneiden.jpg',
          en: 'step-cutting.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ausschneiden',
          en: 'Step: Cutting',
        },
      },
      text: {
        de: `Schneide mit einer Schere oder einem Cutter alle Elemente entlang der durchgezogenen Linie aus.`,
        en: `Cut out the paper parts (A, B, C, ...) by cutting along the solid lines.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-falzen.jpg',
          en: 'step_fold.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Falzen',
          en: 'Step: Folding',
        },
      },
      text: {
        de: `Legt                       ein Lineal an alle gestrichelten Linien an und fahrt mit einem
                      stumpfen dünnen Gegenstand die Linien mit Druck ab.`,
        en: `Place a ruler on the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines. 
                    `,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-knicken.jpg',
          en: 'step-foldingpaperparts.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Knicken',
          en: 'Step: folding paper parts',
        },
      },
      text: {
        de: `Legt das Papierteil A so vor euch hin, dass ihr die Zahlen und
              Buchstaben seht. Knickt die kurz gestrichelten Linien nach innen,
              d.h. zu euch hin (Talfalte). Faltet alle lang gestrichelten Linien
              nach außen, d.h. von euch weg (Bergfalte).`,
        en: `Start with the paper piece A and place it in front of you. 
              The printed side should be facing you. Subsequently fold all the dotted lines 
              either inwards, which means to fold up (short dotted lines) or outwards, 
              which means to fold down (long dotted lines), according to the markings.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-kleben.jpg',
          en: 'step-glue.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together',
        },
      },
      text: {
        de: `Bringt auf die Laschen Kleber auf und verklebt jeweils die
              passenden Zahlenpaare. Ich verwende gerne Klebepads. Beginnt mit
              der Nr. 1 auf A und B und bastelt in nummerischer Reihenfolge
              (2,3,4, etc.) weiter. Wenn manchmal die Teile etwas größer sind, könnt ihr auch ein Lineal nehmen und damit von innen gegen
              die Klebepads drücken.`,
        en: `Use double-sided tape or any glue onto the unprinted side of 
              the tabs.Each number exists twice. Stick all the same numbers together 
              in ascending order (1, 2, 3, ...). Start with section 1 and stick it to
              the section marked 1 on paper A, pressing firmly enough to make them stick together.`,
      },
    },
  ],
  video: {
    title: {
      de:
        'Im Video zeige ich dir, mit welchem Trick du die langen Seiten easy verklebst.',
      en:
        "In the video I'll show you the trick how to glue easily the long edges.",
    },
    description: {
      de: ``,
      en: ``,
    },
    caption: {
      de: `Fussball-DIY zur WM`,
      en: `Soccer DIY for the World Cup from paper`,
    },
    videoid: 'MGjOXXU9Avo',
  },
  downloadName: 'pokal-vorlage.pdf',
  materials: [
    materials.paper(6),
    materials.glue,
    materials.scissors,
    materials.ruler,
    materials.awl,
    materials.mat,
  ],
  pinterest: {
    title: {
      de: 'Speichert euch diese DIY Idee auf Pinterest!',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'fussball-diy-basteln-papier-fussball.jpg',
          en: 'fussball-diy-basteln-papier-fussball.jpg',
        },
        alt_text: {
          de:
            'Fussball-DIY basteln aus Papier zur WM / PaperShape #basteln #diy #papier #partyideas',
          en:
            'Funny World Cup Party Idea: Soccer DIY out of paper (free printable for girls only) / PaperShape #partyideas #soccer',
        },
      },
    ],
  },
};
