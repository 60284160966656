import { saveOrderNumber } from '../order/orderActions';

export const START_CREATE_PAYMENT = 'START_CREATE_PAYMENT';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const PAYMENT_DONE = 'PAYMENT_DONE';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const EXECUTE_PAYMENT = 'EXECUTE_PAYMENT';

export function createPayment(orderNumber) {
  return dispatch => {
    const normalizedOrdernumber = orderNumber
      ? orderNumber.trim().toUpperCase()
      : orderNumber;
    dispatch(saveOrderNumber(normalizedOrdernumber));
    dispatch({
      type: START_CREATE_PAYMENT,
    });
    return dispatch({
      fetch: {
        body: {
          type: 'CreatePayment',
          payload: {
            orderAggregateId: normalizedOrdernumber,
          },
        },
      },
    })
      .then(result => {
        if (result.paymentDone) {
          dispatch({
            type: PAYMENT_DONE,
            payload: {
              order: result.order,
            },
          });
        } else {
          dispatch({
            type: CREATE_PAYMENT,
            payload: {
              paymentId: result.payment.paymentId,
              approvalUrl: result.payment.approvalUrl,
              order: result.order,
            },
          });
        }
      })
      .catch(error => {
        dispatch({
          type: PAYMENT_ERROR,
          payload: {
            error,
          },
        });
      });
  };
}

export function executePayment(paymentId, payerId, orderNumber) {
  return dispatch => {
    return dispatch({
      fetch: {
        body: {
          type: 'ExecutePayment',
          payload: {
            paymentId,
            payerId,
            orderAggregateId: orderNumber || undefined,
          },
        },
      },
    }).then(result => {
      if (result.paymentDone) {
        dispatch({
          type: PAYMENT_DONE,
          payload: {
            order: result.order,
          },
        });
      } else {
        dispatch({
          type: EXECUTE_PAYMENT,
        });
      }
    });
  };
}
