import React from 'react';
import cn from 'classnames';

/**
 * @return {null}
 */
function Message({ message }) {
  if (!message) {
    return null;
  }
  return (
    <div
      className={cn('alert', {
        'alert-danger': !!message.error,
        'alert-success': !!message.success,
      })}
      style={{ marginTop: '20px', marginBottom: '20px' }}>
      {message.error && '🤔 '}
      {message.error || message.success}
    </div>
  );
}

export default Message;
