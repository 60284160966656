export default {
  id: '2-27-sfk',
  category: ['home_decor'],
  name: {
    de: 'Ballonhund',
    en: 'Ballon Dog',
  },
  subtitle: {
    de: 'B26 x H25 x T10 cm',
    en: 'W26 x H25 x D10 cm',
  },
  meta_title: {
    de: 'Ballonhund machen aus Papier',
    en: 'Make your own Balloon dog from Paper',
  },
  meta_description_google: {
    de: 'Mit unserem schönen 3D Ballonhund zieht ein wahres Unikat bei dir zuhause ein. Startest du mit der Vorlage oder lieber mit dem Bastelset? ',
    en: 'With our beautiful 3D balloon dog moves an unique piece in your home. Do you wanna start with the template or rather with the craft kit?',
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?    
    - 6 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -6 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 6 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 6 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 6 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 6 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkballonhund/ballonhund-papier.jpg',
        en: 'products/sfkballonhund/ballonhund-papier.jpg',
      },
      alt_text: {
        de: 'Selbstgebastelter 3d Ballonhund aus Papier in altrosa',
        en: 'Handmade 3d ballon dog from paper in rose',
      },
    },
    price: 9.9,
    priceThirdCountry: 9.9,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkballonhund/ballonhund-papier.jpg',
        en: 'products/sfkballonhund/ballonhund-papier.jpg',
      },
      alt_text: {
        de: 'Selbstgebastelter 3d Ballonhund aus Papier in altrosa',
        en: 'Handmade 3d ballon dog from paper in rose',
      },
    },
    colors: {
      color1: {
        de: 'Ballonhund',
        en: 'Balloon dog',
      },
    },
    disableColors: {
      color1: ['schwarz'],
    },
    price: 19.9,
    priceThirdCountry: 19.9,
  },
};
