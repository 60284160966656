export default {
  id: '2-34-sfk',
  category: ['animal_sculpture'],

  name: {
    de: ' Katze Simba',
    en: ' Cat Simba',
  },
  subtitle: {
    de: 'B21 x H40 x T35 cm',
    en: 'W21 x H40 x D35 cm',
  },
  meta_title: {
    de: 'Papercraft Katze Simba aus Papier basteln',
    en: '3D Papercraft Cat Simba',
  },
  meta_description_google: {
    de: 'Bastel unsere 3D Papier Katze Simba als originelle Geschenkidee für kreative Katzenliebhaber oder als low poly Deko für dein Zuhause.',
    en: 'Papercraft our 3D cat Simba as an original gift idea for creative cat lovers or as a decoration for your home.',
  },
  meta_description_social: {
    de: 'Eine Papier Katze zu basteln, ist eine originelle Geschenkidee für kreative Katzenliebhaber. Mit ein wenig Papier, Schere und Kleber entsteht aus unserer Vorlage deine pflegeleichte Papercraft Katze. Den Download dazu findest du in unserem Shop.',
    en: 'Papercraft our 3D cat Simba as an original gift idea for creative cat lovers or as a low poly decoration for your home. Download the template in our shop.',
  },

  description: {
    de: `Miau, miau! Eine verspielte Papier Katze zu basteln, ist eine originelle Geschenkidee für kreative Katzenliebhaber oder als low poly Deko für dein Zuhause. Sie fängt zwar keine Mäuse, ist dafür aber auch sehr pflegeleicht.  
   
    Was ist drin im Bastelset?    
    - 10 vorgedruckte DIN A4 Bögen Tonkarton für eine Katze Simba 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das Bastelset für Kinder ab 14 Jahren.
    
    `,
    en: `Meow, meow! Making a playful papercraft cat is an original gift idea for creative cat lovers or as a low poly decoration for your home. It may not catch mice, but it is also very easy to care for.  
   
    What's in the craft kit?    
    - 10 pre-printed A4 sheets of cardboard for one cat 
    - 500 double-sided, strong adhesive pads for clean and quick gluing
    - Detailed step-by-step instructions in German and English for easy assembly
   
    What else do you need?
    A little patience, scissors, a ruler and a folding tool (e.g. awl, butter knife)
`,
  },

  pdf: {
    description: {
      de: `Miau miau! Bastel unsere verspielte 3D Papier Katze Simba als originelle Geschenkidee für kreative Katzenliebhaber oder als low poly Deko für dein Zuhause. Sie fängt zwar keine Mäuse, ist dafür aber auch sehr pflegeleicht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du mit deinem tierischen Kunstobjekt direkt loslegen. Ich empfehle dir 230g/m² Tonkarton zu verwenden, wenn du die Vorlage auf DIN A4 ausdruckst. Wenn du die Vorlage auf die Hälfte skalierst, dann rate ich dir zu 130g/m².
      Damit diese low poly Katze gelingt, benötigst du 10 DIN A4 Bögen Tonkarton, einen Drucker, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `Meow meow! Craft our playful 3D paper cat Simba as an original gift idea for creative cat lovers or as a decoration for your home. It may not catch mice, but it is also very easy to care for. 
      
      With the PDF template in A4 or letter format to print out yourself, you can get started with your animal art object straight away. I recommend using 230g/m² cardboard if you print the template on letter size. If you scale the template in half, I recommend using 130g/m².
      To make this low poly cat, you will need 10 A4 or letter sheets of cardboard, a printer, scissors and glue.

      This is a digital product. You will receive the download link by email immediately after receipt of payment. This will be done within one hour.`,
    },
    price: 16.9,
    priceThirdCountry: 16.9,

    image: {
      filename: {
        de: 'products/katzesimba/papercraft-katze-3d-basteln.jpg',
        en: 'products/katzesimba/papercraft-katze-3d-basteln.jpg',
      },
      alt_text: {
        de: 'Papier Katze Simba in der Farbe schokobraun',
        en: 'Papercraft Cat Simba in chocolate brown',
      },
    },
  },

  colorinspirations: [
    {
      caption: {
        de: 'Katze Simba in der Farbe schokobraun',
        en: 'Cat Simba in chocolate brown',
      },
      image: {
        filename: {
          de: 'products/katzesimba/papercraft-katze-3d-basteln.jpg',
          en: 'products/katzesimba/papercraft-katze-3d-basteln.jpg',
        },
        alt_text: {
          de: 'Braune 3D Katze aus Papier',
          en: 'Brown 3d papercraft cat',
        },
      },
    },

    {
      caption: {
        de: 'Male jede Facette an',
        en: 'Paint each facet',
      },
      image: {
        filename: {
          de: 'products/katzesimba/3d-papercraft-katze-orange.jpg',
          en: 'products/katzesimba/3d-papercraft-katze-orange.jpg',
        },
        alt_text: {
          de: 'Bemalte Papercraft Katze in Orangetönen',
          en: 'Painted papercraft cat in orange tones',
        },
      },
    },

    {
      caption: {
        de: 'Kunst-Katze im Bauhaus-Stil',
        en: 'Bauhaus style cat ',
      },
      image: {
        filename: {
          de: 'products/katzesimba/papercraft-katze-bauhaus-stil.jpg',
          en: 'products/katzesimba/papercraft-katze-bauhaus-stil.jpg',
        },
        alt_text: {
          de: 'Bemalte Papercraft Katze in Orangetönen',
          en: 'Painted papercraft cat in orange tones',
        },
      },
    },

    {
      caption: {
        de: 'Katze im Größenverhältnis',
        en: 'Size ratio of the cat',
      },
      image: {
        filename: {
          de: 'products/katzesimba/low-poly-papier-katze-stuhl.jpg',
          en: 'products/katzesimba/low-poly-papier-katze-stuhl.jpg',
        },
        alt_text: {
          de: 'Papercraft Katze von hinten in perlweiß',
          en: 'Papercraft cat from behind in pearl white',
        },
      },
    },

    {
      caption: {
        de: 'Detailansicht Kopf in der Farbe rehbraun',
        en: 'Detailled view from the head in deer brown',
      },
      image: {
        filename: {
          de: 'products/katzesimba/Papier-Katze-Detailansicht-Kopf.jpg',
          en: 'products/katzesimba/Papier-Katze-Detailansicht-Kopf.jpg',
        },
        alt_text: {
          de: '3D Katzenkopf aus Papier  ',
          en: '3d papercraft cat head',
        },
      },
    },

    {
      caption: {
        de: 'Katze Simba in perlweiß',
        en: 'Cat Simba in pearl white',
      },
      image: {
        filename: {
          de: 'products/katzesimba/papier-katze-basteln.jpg',
          en: 'products/katzesimba/papier-katze-basteln.jpg',
        },
        alt_text: {
          de: '3D Papercraft Katze von hinten ',
          en: '3D Papercraft Cat from behind',
        },
      },
    },

    {
      caption: {
        de: 'Vorschau auf die Bastelvorlage',
        en: 'Preview of template',
      },
      image: {
        filename: {
          de: 'products/katzesimba/papercraft-vorlage-katze-simba-vorschau.jpg',
          en: 'products/katzesimba/papercraft-vorlage-katze-simba-vorschau.jpg',
        },
        alt_text: {
          de: '3D Papercraft Katze Bastelvorlage Vorschau PDF',
          en: '3D Papercraft Cat Craft Template Preview PDF',
        },
      },
    },

    {
      caption: {
        de: 'Verschmuste Katzen in reh- und schokobraun',
        en: 'Loving cats chocolate brown and deer brown',
      },
      image: {
        filename: {
          de: 'products/katzesimba/verschmuste-papier-katzen-basteln.jpg',
          en: 'products/katzesimba/verschmuste-papier-katzen-basteln.jpg',
        },
        alt_text: {
          de: 'Küssende Papercraft Katzen in schoko- und rehbraun',
          en: 'Kissing papercraft cats in chocolate and deer brown',
        },
      },
    },
  ],

  choose: {
    image: {
      filename: {
        de: 'products/katzesimba/papercraft-katze-3d-basteln.jpg',
        en: 'products/katzesimba/papercraft-katze-3d-basteln.jpg',
      },
      alt_text: {
        de: 'Papercraft Katze Simba von PaperShape',
        en: 'Papercraft cat Simba from PaperShape',
      },
    },
    colors: {
      color1: {
        de: 'Katze',
        en: 'Cat',
      },
    },
    disableColors: {
      color1: ['schwarz'],
      color2: ['schwarz'],
    },
    price: 29.9,
    priceThirdCountry: 29.9,
  },
};
