import React, { Component } from 'react';
import { ControlBar, Player } from 'video-react';
import VisibilitySensor from 'react-visibility-sensor';

export default class PsStepVideo extends Component {
  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange);
  }

  handleStateChange = (state, prevState) => {
    if (state.ended && !prevState.ended) {
      if (this.player) {
        this.player.seek(0);
      }
      setTimeout(() => {
        if (this.player) {
          this.player.play();
        }
      }, 2000);
    }
  };

  render() {
    const { image, video } = this.props;
    return (
      <div>
        <VisibilitySensor
          scrollCheck
          onChange={(isVisible) => {
            if (this.player) {
              if (isVisible && this.player.getState().player.paused) {
                this.player.play();
              } else if (!isVisible && !this.player.getState().player.paused) {
                this.player.pause();
              }
            }
          }}>
          <Player
            playsInline
            poster={image}
            src={video}
            ref={(ref) => (this.player = ref)}>
            <ControlBar autoHide={true} disableDefaultControls={true} />
          </Player>
        </VisibilitySensor>
      </div>
    );
  }
}
