import React from 'react';
import { selectedLang } from '../data/lang';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import env from '../env';

function PsMeta({
  title,
  description,
  titlefb,
  socialdescription,
  socialimages,
  noindex,
  type,
  children,
}) {
  const location = useLocation();
  return (
    <Helmet>
      <html lang={selectedLang} />
      <title>{title} - Papershape</title>
      <meta name="description" content={description || title} />

      <link
        rel="alternate"
        href={env.CLIENT_HOST + replaceToDe(location.pathname)}
        hrefLang="de"
      />
      <link
        rel="alternate"
        href={env.CLIENT_HOST + replaceToEn(location.pathname)}
        hrefLang="en"
      />
      <link rel="canonical" href={env.CLIENT_HOST + replaceToDe(location.pathname)} />

      <meta content={titlefb || title} property="og:title" />
      {socialimages &&
        socialimages.map((image) => (
          <meta
            key={image}
            content={env.CLIENT_HOST + '/images/' + image}
            property="og:image"
          />
        ))}
      <meta
        content={socialdescription || description || title}
        property="og:description"
      />
      <meta content={type || 'website'} property="og:type" />
      <meta content={'Papershape'} property="og:site_name" />
      <meta
        content={selectedLang !== 'de' ? 'en_US' : 'de_DE'}
        property="og:locale"
      />
      {noindex && <meta name="robots" content="noindex" />}
      {children}
    </Helmet>
  );
}

export default PsMeta;

function replaceToEn(url) {
  if (url === '/') {
    return '/en/';
  }
  return url.replace(/^\/de\//, '/en/').replace(/^\/de$/, '/en');
}

function replaceToDe(url) {
  if (url === '/en/') {
    return '/';
  }
  return url.replace(/^\/en\//, '/de/').replace(/^\/en$/, '/de');
}
