export default {
  id: '1-19-sfk',
  category: ['animal_sculpture'],
  name: {
    de: 'Widder',
    en: 'Aries',
  },
  subtitle: {
    de: 'B26 x H30 x T32 cm',
    en: 'W26 x H30 x D32 cm',
  },
  meta_title: {
    de: '3D Widder aus Papier - Tierkopf Papierkunst als Wanddeko',
    en: 'Papercraft aries: make your own animal head wall art decor ',
  },
  meta_description_google: {
    de: 'Bastel deinen eigenen 3D Widder aus Papier. Egal, ob als Sternzeichen, Wanddeko oder für Okkultismus-Fans, mit diesem DIY Kit verzauberst du deine Wände.',
    en: 'Make your own 3D aries from paper with our DIY kit.',
  },
  meta_description_social: {
    de: '3D Widder aus Papier zum Selberbasteln #papercraft #okkultismus #bastelnmitpapier #Widdersternzeichen #papershape ',
    en: '3d #papercraft aries animal head - #diy #aries # #papershape #occultism',
  },
  description: {
    de: `  Was ist drin im DIY Kit?    
    - 11 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -11 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },

  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 11 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 11 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 11 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 11 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkwidder/widder-aries-papercraft-papershape.jpg',
        en: 'products/sfkwidder/widder-aries-papercraft-papershape.jpg',
      },
      alt_text: {
        de: 'DIY 3d Widder aus Papier als Wanddeko ',
        en: 'Papercraft aries for wall decor ',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },

  colorinspirations: [
    {
      caption: {
        de: 'Farben: Weiß und Gold',
        en: 'Colors: white and gold',
      },
      image: {
        filename: {
          de: 'products/sfkwidder/widder-aries-papercraft-papershape.jpg',
          en: 'products/sfkwidder/widder-aries-papercraft-papershape.jpg',
        },
        alt_text: {
          de: '3D Widder Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
          en: '3d aries trophy from paper - diy papersculpture as unique wall decor #papershape ',
        },
      },
    },

    {
      caption: {
        de: 'Frontalansicht',
        en: 'Front view',
      },
      image: {
        filename: {
          de: 'products/sfkwidder/widder-aries-papershape_front.jpg',
          en: 'products/sfkwidder/widder-aries-papershape_front.jpg',
        },
        alt_text: {
          de: '3D Widder Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
          en: '3d aries trophy from paper - diy papersculpture as unique wall decor #papershape ',
        },
      },
    },

    {
      caption: {
        de: 'Seitliche Ansicht',
        en: 'Side view',
      },
      image: {
        filename: {
          de: 'products/sfkwidder/widder-aries-papershape_left.jpg',
          en: 'products/sfkwidder/widder-aries-papershape_left.jpg',
        },
        alt_text: {
          de: '3D Widder Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
          en: '3d aries trophy from paper - diy papersculpture as unique wall decor #papershape ',
        },
      },
    },

    {
      caption: {
        de: 'Seitliche Ansicht',
        en: 'Side view',
      },
      image: {
        filename: {
          de: 'products/sfkwidder/widder-aries-papershape_right.jpg',
          en: 'products/sfkwidder/widder-aries-papershape_right.jpg',
        },
        alt_text: {
          de: '3D Widder Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
          en: '3d aries trophy from paper - diy papersculpture as unique wall decor #papershape ',
        },
      },
    },

    {
      caption: {
        de: 'Rückansicht mit Aufhängung',
        en: 'Back view with wall mount',
      },
      image: {
        filename: {
          de: 'products/sfkwidder/widder-aries-papershape_back.jpg',
          en: 'products/sfkwidder/widder-aries-papershape_back.jpg',
        },
        alt_text: {
          de: '3D Widder Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
          en: '3d aries trophy from paper - diy papersculpture as unique wall decor #papershape ',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkwidder/widder-aries-papercraft-papershape.jpg',
        en: 'products/sfkwidder/widder-aries-papercraft-papershape.jpg',
      },
      alt_text: {
        de: '3d Papierskulptur Widder als Wanddeko -  #diy #bastelnmitpapier #Widder #papershape',
        en: 'Papercraft aries animal head - #aries #papercraft #aries #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Kopf & Ohren',
        en: 'Head & ears',
      },
      color2: {
        de: 'Hörner',
        en: 'Horns',
      },
    },
    disableColors: {
      color2: ['schwarz'],
      color1: ['schwarz'],
    },
    price: 29.9,
    priceThirdCountry: 28.9,
  },
};
