import React from 'react';
import { PsLink } from './PsLink';
import PsImage from './PsImage';
import { selectedLang } from '../data/lang';
import Lang from '../common/Lang';

export default function PsProductView({ product }) {
  const image = product.choose
    ? product.colorinspirations
      ? product.colorinspirations[0].image
      : product.choose.image
    : product.pdf.image;
  return (
    <div>
      <PsLink to={'/product/' + product.id}>
        <PsImage
          image={image}
          className="img-home-portfolio"
          data-pin-nopin="true"
          format="600x900"
          style={{marginBottom: '10px'}}
        />
      </PsLink>
      <PsLink to={'/product/' + product.id} className="product-title">
        <div
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 300,
            fontSize: '20px',
          }}>
          {product.name[selectedLang]}
        </div>
      </PsLink>

    </div>
  );
}
