import React from 'react';
import { connect } from 'react-redux';
import { isEuShipping } from '../state/order/orderSelectors';
import Lang from '../common/Lang';

const Vat = ({ isEuShipping }) =>
  isEuShipping ? (
    <small>{<Lang de="inkl. 19 %" en="incl. 19% VAT" />}</small>
  ) : (
    <small>
      (<Lang
        de="Bitte beachte, dass bei Sendungen außerhalb der EU Gebühren (Zoll und Mehrwertsteuer) im Zielland anfallen können."
        en="Please note that for shipments outside of EU extra charges (duty and VAT) may be applied."
      />
      )
    </small>
  );

const mapStateToProps = (state) => {
  return {
    isEuShipping: isEuShipping(state),
  };
};

export default connect(mapStateToProps)(Vat);
