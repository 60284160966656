export default {
  url: 'gaensebluemchen-basteln',
  countermark: 'vg07.met.vgwort.de/na/6a971efab20041d0852d64e675fc9714',
  imageFolder: 'gaensebluemchen',
  meta_title: {
    de: 'Gänseblümchen basteln: 3 frühlingshafte DIY-Ideen',
    en: 'How to make a paper daisy: 3 creative ideas from paper',
  },

  name: {
    de: ' Gänseblümchen',
    en: ' Daisy',
  },
  id: '3-19-blog',
  pdf: {
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'content/gaensebluemchen/gaensebluemchen-basteln-fruehling-papierblumen.jpg',
        en: 'content/gaensebluemchen/gaensebluemchen-basteln-fruehling-papierblumen.jpg',
      },
      alt_text: {
        de: 'DIY Gänseblümchen basteln aus Papier ',
        en: 'Learn how to make a paper daisy',
      },
    },
  },

  meta_description_google: {
    de: 'Mit selbst gebastelten Gänseblümchen lassen sich so viele schöne Dekoideen umsetzen. 3 kreative Ideen zeige ich euch hier! ',
    en: 'Learn how to make paper daisy flowers and how to style them for your home decor ',
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  meta_images_social: [
    {
      de: 'gaensebluemchen-basteln-fruehling-ideen.jpg',
      en: 'how-to-make-paper-daisy-flowers.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'gaensebluemchen-basteln-fruehling-papierblumen.jpg',
      en: 'gaensebluemchen-basteln-fruehling-papierblumen.jpg',
    },
    alt_text: {
      de: 'Gänseblümchen aus Papier basteln als kreative DIY Geschenkverpackung #papercraft #bastelnmitpapier #paperflowers',
      en: 'How to make paper daisy flowers with template #paperflowers #papercraft #homedecor #paperdaisy',
    },
  },
  h1: {
    de: 'Gänseblümchen basteln: 3 DIY-Ideen für den Frühling',
    en: 'How to make paper daisy flowers',
  },
  description: {
    de: `
      <p>Seid ihr als Kind ländlich aufgewachsen? Dann teilt ihr sicherlich die Erinnerung 
      barfuß über grüne Wiesen gelaufen zu sein und hier und da 
      ein Gänseblümchen gepflückt zu haben. Gänseblümchen sind so coole Blumen, weil sie 
       endlich den Frühling ankündigen. Habt ihr auch aus ihnen Haarkränze geflochten? </p>
      <p>In diesem Post möchte ich euch zeigen, wie ihr die zarten 
      Gänseblümchen basteln könnt und mit ihnen eure Ostergeschenke stylish dekorieren könnt. Ihr benötigt dafür
      mal wieder lediglich Tonpapier als Hauptmaterial.</p>
    
     
      <p></p>
      
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: '3 Ideen, was du mit Papier-Gänseblümchen machen kannst',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'gaensebluemchen-basteln-geschenk-verpacken.jpg',
          en: 'gaensebluemchen-basteln-geschenk-verpacken.jpg',
        },
        alt_text: {
          de: 'Gänseblümchen aus Papier basteln als kreative Geschenkverpackung #papercraft #bastelnmitpapier #paperflowers',
          en: 'DIY paper daisy flowers with template #papercraft #paperflowers #paperdaisy',
        },
      },
    },
    {
      text: {
        de: `Um Ostergeschenke frühlingshaft zu verpacken, kannst du das Gänseblümchen
        als Topping aufkleben und dein Geschenk so ziemlich cool in Szene setzen. Natürlich könntest du auch einfach ein gelbes oder grünes Geschenkband verwenden, aber wie viel 
        cooler ist diese Papierblume, bei der man meint, sie würde gleich Frühlingsduft versprühen.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'papier-gaensebluemchen-selber-machen-vase.jpg',
          en: 'papier-gaensebluemchen-selber-machen-vase.jpg',
        },
        alt_text: {
          de: 'Einfache Papierblumen basteln: Gänseblümchen aus Tonpapier selber machen #papercraft #bastelnmitpapier #papierblumen #paperflowers',
          en: 'White paper daisy tutorial: fun paper flowers for wedding, home decor or a gift topper #papercraft #paperflowers #weddingflowers',
        },
      },
    },
    {
      text: {
        de: `In Vasen machen sich Papierblumen immer und zwar wirklich immer perfekt!
        Damit sie ihre Wirkung aber auch wirklich entfalten können, solltet ihr entweder nur ein bis drei 
        Gänseblümchen aus Tonpapier hineinstecken (sehr minimalistischer und cleaner Look) oder
        aber ein ganzes Bouquet voll Gänseblumen. Irgendetwas dazwischen sieht nach nichts Ganzem und nichts Halbem aus.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'gaensebluemchen-diy.jpg',
          en: 'gaensebluemchen-diy.jpg',
        },
        alt_text: {
          de: 'DIY Wanddeko zum Frühling: Gänseblümchen aus Paper im Bilderrahmen #papercraft #bastelnmitpapier #paperflowers #diywanddeko',
          en: 'How to make fun paper daisy flowers for home decor or birthday present #papercraft #paperflowers #paperdaisy #happybirthday',
        },
      },
    },
    {
      text: {
        de: `Ähnlich wie diese <a href="/de/blog/hyazinthen-basteln/">Hyazinthen aus Papier</a>  könnt ihr auch eure selbst gemachten Papierblumen als
        Wanddeko ziemlich frühlingslike in Szene setzen oder gleich als DIY Geschenk nutzen. Dazu müsst ihr die 
        Gänseblümchen lediglich mit Stiel (oder ohne?) auf einen weißen Pappkarton kleben und einrahmen.`,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: '',
    en: '',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'gaensebluemchen-vorlage_01.jpg',
          en: 'gaensebluemchen-vorlage_01.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Um das Gänseblümchen selber zu basteln, benötigt ihr zwei DINA4 Seiten Tonpapier. 
        Eines davon in weiß und das andere in einem schönen Sonnengelb. Druckt euch die Vorlage (siehe unten) aus und
        schneidet alle Elemente entlang der durchgezogenen schwarzen Linie aus. 
        
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'gaensebluemchen-falten.jpg',
          en: 'gaensebluemchen-falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Nehmt euch eins der großen weißen Quadrate und knickt es in der Hälfte. Diese Hälfte knickt ihr
        erneut in der Hälfte, sodass ihr in dem nun kleinen Quadrat die zwei Blüten der Gänseblume seht. Wiederholt
        diese Schritte für alle Quadrate (sowohl gelbe als auch weiße).`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'gaensebluemchen-schneiden.jpg',
          en: 'gaensebluemchen-schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Schneidet die Blüten mit einer Schere entlang der Markierung aus.
        Öffnet die Quadrate und ihr solltet eine ganze Gänseblümchenblüte in der Hand halten. 
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'blueten-holzstab.jpg',
          en: 'blueten-holzstab.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Mit einem Holzstab formt ihr Falzknicke in jede einzelen Blüte. Dazu das Holzstäbchen in der Mitte
        der Blüte auflegen und die Seiten der Blüte jeweils rechts und links leicht nach oben biegen. Dies wiederholt ihr
        mit allen weißen Blüten. 
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'gaensebluemchen-kleben.jpg',
          en: 'gaensebluemchen-kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Klebt die 4 weißen Blüten der Gänseblümchen nun übereinander, indem ihr in der 
        Mitte ein wenig Kleber auftragt. Meine 
        Empfehlung ist hier ein trockener Klebestift. 
        Achtet beim Aufeinanderkleben, dass die Blüten leicht versetzt sind. 
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'blueten-gelb-ausschneiden.jpg',
          en: 'blueten-gelb-ausschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Nehmt eines der kleinen gelben Blüten zur Hand und schneidet sie in der Hälfte einmal durch. Ihr benötigt
        insgesamt 5 gelbe Hälften. 
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'gelbe-blueten-kleben.jpg',
          en: 'gelbe-blueten-kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Nun beginnt ihr die einzelnen Hälften am Rand von der Mitte der weißen Blüten aufzukleben. Beim Aufkleben
        solltet ihr darauf achten, dass sich die gelben Blüten leicht überschneiden. Klebt insgesamt 5 gelbe Blüten 
        ringsherum auf. 
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `So bastelt ihr nun den Blütenstempel: Nehmt euch eines der gelben Streifen zur Hand und schneidet
        parallele Schnitte bis zur Hilfslinie ein. Je mehr parallele Streifen, umso realistischer wird das 
        Gänseblümchen am Ende. Um den Prozess ein wenig abzukürzen, legt euch 5 Streifen übereinander und legt los, cut, cut, cut.
         
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Klebt die 5 Streifen zu einem langen Strang fest oder beginnt mit einem Streifen und
        wickelt ihn ein. Sobald ihr am Ende angekommen seid, klebt ihr den nächsten Streifen an und wickelt diesen
        um den bereits vorhandenen Blütenstempel herum. Ingesamt benötigt ihr 5 Streifen. 
         
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'bluetenstempel-basteln.jpg',
          en: 'bluetenstempel-basteln.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Biegt die einzelnen Papierstreifen nun auseinander. Die äußeren Streifen des Blütenstempels versucht 
        ihr komplett umzuknicken.
         
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'bluetenstempel-aufkleben.jpg',
          en: 'bluetenstempel-aufkleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Final den Blütenstempel in die Mitte des weißen Gänselbümchens aufkleben, falls ihr keinen
        Stiel mögt und fertig ist euer Gänseblümchen.
      
         
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Möchtet ihr doch einen Stiel anbringen? Dann empfehle ich euch Steckdraht zu verwenden und diesen
        zuerst mit dem Blütenstempel zu verkleben. Danach bohrt ihr mit einer Ahle z.B. ein Loch in die Mitte des
        Gänseblümchens und steckt den Blumendraht samt Blütenstempel durch. Hier würde ich den Blütenstempel nun
        ankleben.
      
         
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'gruene-bluete.jpg',
          en: 'gruene-bluete.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Druckt euch Seite 3 der Vorlage auf grünem Tonpapier aus und wiederholt Schritte 1-3.
      
         
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'stiel-gaensebluemchen.jpg',
          en: 'stiel-gaensebluemchen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Ihr benötigt zwei grüne Blüten. Diese steckt ihr von unten des Steckdrahts auf, sodass sie an den weißen Blüten andocken. Final den Steckdraht mit Floristenkrepp umwickeln und voilà!  
      
         
        `,
        en: ``,
      },
    },
  ],

  downloadName: 'paper-daisy_papershape.pdf',
  materials: [
    {
      de: '3 DINA4 Tonpapier in weiß, gelb, grün (bis zu 130g/m²)',
      en: '3 colored papers in white, yellow, green (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder Heißklebepistole',
      en: 'Glue',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Holzspieß',
      en: 'Wooden stick',
    },
    {
      de: 'Steckdraht je nach Wunschlänge',
      en: 'flower wire',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'gaensebluemchen-basteln-papierblumen-anleitung.jpg',
          en: 'how-to-make-paper-daisy-flowers-spring.jpg',
        },
        alt_text: {
          de: 'Gänseblümchen aus Papier basteln als kreative Geschenkverpackung #papercraft #bastelnmitpapier #paperflowers',
          en: 'How to make paper daisy flowers with template (step by step tutorial) #papercraft #paperflowers #paperdaisy',
        },
      },
    ],
  },
};
