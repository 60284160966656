import React, { Component } from 'react';

export default class ShowMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
    };
  }
  render() {
    return (
      <div>
        <div>{this.props.text}</div>
        {/*<a*/}
        {/*  className="btn-block"*/}
        {/*  onClick={() =>*/}
        {/*    this.setState(({ open }) => ({*/}
        {/*      open: !open*/}
        {/*    }))}>*/}
        {/*  anzeigen*/}
        {/*</a>*/}
        {/*{this.state.open === true ? this.props.more : null}*/}
        <div className="visible-sm">{this.props.more}</div>
      </div>
    );
  }
}
