import React from 'react';
import { maxLength100 } from '../validation';
import PsInputGroup from './inputs/PsInputGroup';
import { connect } from 'react-redux';
import { validateVoucher } from '../state/voucher/voucherActions';
import { Field } from 'redux-form';
import { getTextBySelectedLang } from '../data/lang';

function VoucherInput({ dispatch }) {
  return (
    <Field
      name="voucherCode"
      label={getTextBySelectedLang('Gutschein', 'Gift certificate')}
      buttonOnClick={() => dispatch(validateVoucher())}
      buttonText={getTextBySelectedLang(
        'Preis berechnen',
        'Calculate the price'
      )}
      component={PsInputGroup}
      validate={[maxLength100]}
    />
  );
}

export default connect()(VoucherInput);
