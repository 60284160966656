export default {
  id: '3-06-blog',
  category: ['gift_wrapping'],

  name: {
    de: 'Süßes Gastgeschenk: Praline',
    en: 'Sweet gift for guests: Praline',
  },
  subtitle: {
    de: 'B9 x H6 x T9 cm',
    en: 'W9 x H6 x D9 cm',
  },
  meta_title: {
    de: 'Entdecke diese süße Verführung für deine Gäste in Form einer leckeren Praline aus Papier.  ',
    en: 'Discover this sweet temptation for your guests in the form of a delicious paper praline.',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für süße Pralinen aus Papier kannst du deinen Gästen selbstgemachte Kleinigkeiten schmackhaft verpacken.',
    en: 'With this template for beautiful paper chocolates you can pack your guests homemade trifles tasty.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  pdf: {
    description: {
      de: `Mit dieser Vorlage für süße Pralinen aus Papier kannst du deinen Gästen selbstgemachte Kleinigkeiten schmackhaft verpacken. Oder wie wäre es als Hochzeitsgeschenk mit kleinen Geldscheinen zu Herzen gefaltet? <a href="/de/blog/diy-geschenkbox-praline/">In diesem Blogpost</a> zeige ich dir, wie einfach das geht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir mindestens 130g/m² Tonpapier zu verwenden.
      Damit die Pralinen gelingen, benötigst du einen Drucker, 2 Bögen Tonpapier, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this template for sweet chocolates made of paper you can pack your guests homemade trifles tasty. Or how about a wedding gift with small banknotes folded into hearts?  <a href="/en/blog/diy-geschenkbox-praline/">In this blogpost</a> I show you how easy it is.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use at least 130g/m² paper.
      To make the chocolates succeed, you'll need a printer, 2 sheets of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'products/sfkpralineshop/gastgeschenk-geburtstag-praline-papier.jpg',
        en: 'products/sfkpralineshop/gastgeschenk-geburtstag-praline-papier.jpg',
      },
      alt_text: {
        de: 'Selbst gebastelte Praline aus Papier mit verpackten Geldscheinen',
        en: 'Homemade paper chocolates with wrapped banknotes',
      },
    },
  },
};
