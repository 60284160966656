export default {
  url: 'papier-lebkuchenmann-basteln',
  countermark: 'vg07.met.vgwort.de/na/7d479787dbb44ed78effee7860201ec0',
  imageFolder: 'lebkuchen',
  meta_title: {
    de: 'Schönsten Lebkuchenmann mit Papier basteln (mit Vorlage)',
    en: 'Lovely ginger bread man from paper (with template)',
  },
  meta_description_google: {
    de:
      'Diesen Lebkuchenmann mit Papier bastelt ihr euch mit der kostenlosen Vorlage von PaperShape ganz einfach. Als Geschenkeanhänger, als Girlande oder als Weihnachtsdeko macht er sich wunderbar zu Weihnachten.',
    en:
      "This paper ginger bread man is an awesome gift tag for Christmas presents. In this tutorial I'll show you how you can make it using our free template. ",
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  meta_images_social: [
    {
      de: 'lebkuchenmann-basteln-papier-kinder-vorlage.jpg',
      en: 'ginger-bread-man-paper-garland.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'lebkuchenmann-basteln-papier-kinder-vorlage.jpg',
      en: 'ginger-bread-man-paper-garland.jpg',
    },
    alt_text: {
      de:
        'Lebkuchenmann aus Papier basteln zu Weihnachten als Geschenkeanhänger, Girlande oder Weihnachtsdeko #papershape',
      en:
        'christmas crafts: diy ginger bread man from paper with template #papershape',
    },
  },
  h1: {
    de:
      'Schönsten Lebkuchenmann mit Papier basteln als Geschenkeanhänger (mit Vorlage)',
    en: 'Lovely ginger bread man from paper (with tempate)',
  },
  description: {
    de: `
      <p>Lebkuchen zu Weihnachten mal aus Papier basteln, nicht backen.</p>
      <p>Diesen begehrenswerten Mann könnt ihr euch ganz einfach selber basteln mit der kostenlose Vorlage, die ihr unten herunterladen könnt. 
      Ihr könnt den Lebkuchenmann aus Papier entweder als Geschenkeanhänger, als Girlande oder als Tischeko nutzen.
      </p> 
      Und wer nicht genug von DIY an Weihnachten bekommen kann, der bereitet sich auf den Nikolaustag mit dieser 
      <a href="/de/blog/nikolaus-knallbonbon-basteln/">lustigen Nikolaus Geschenkverpackung</a> vor.
      `,
    en: `
      <p> In this tutorial I'll show you how to bake your own dream ginger bread man from paper. You can use it as a gift tag, christmas garland or lovely Christmas Table Decor.</p>
      <p> And if you are up for more Christmas DIYs you must try this <a href="/en/blog/nikolaus-knallbonbon-basteln/">super cool and funny Santa Claus Christmas cracker</a>. </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'lebkuchenmann-papier-basteln.jpg',
          en: 'ginger-bread-man-template-papercraft.jpg',
        },
        alt_text: {
          de:
            'Lebkuchenmann aus Papier basteln zu Weihnachten als Geschenkeanhänger, Girlande oder Weihnachtsdeko mit Vorlage #papershape',
          en:
            'christmas crafts: diy ginger bread man from paper with template #papershape',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'lebkuchen-basteln-papier-vorlage.jpg',
          en: 'ginger-bread-man-paper-template.jpg',
        },
        alt_text: {
          de: `Lebkuchenmann aus Papier basteln zu Weihnachten als Geschenkeanhänger, Girlande oder Weihnachtsdeko #papershape`,
          en: `christmas crafts: diy ginger bread man from paper with template. use it for garlands, gift tags oder Christmas decor #papershape`,
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
  ],

  stepsTitle: {
    de: `Wie bastel ich einen Lebkuchenmann aus Papier - Anleitung mit Vorlage`,
    en: `Step by step: how to make a ginger bread man from paper`,
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: 'Lebkuchenmann Vorlage herunterladen',
          en: ``,
        },
      },
      video: '01_schneiden.mp4',
      text: {
        de: `Für zwei Lebkuchenmänner benötigt ihr ein DINA4 Papier in einer Grammatur von ca. 170-300g/m². Druckt euch die Vorlage aus und schneidet sie aus.
        Das ist die einfachste Möglichkeit. Ich habe euch eine Vorlage mit Muster und ohne vorbereitet. Die uni-Variante ist super um mit Kindern verziert zu werden.
        
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '02_silhouette.jpg',
          en: '02_silhouette.jpg',
        },
        alt_text: {
          de: 'Lebkuchenmann Vorlage herunterladen',
          en: ``,
        },
      },
      video: '02_silhouette.mp4',
      text: {
        de: `Wer die Augen und Co. aber ausgeschnitten haben möchte, wie in meinen Bildern, der nutzt am besten einen Schneideplotter. Die Vorlage hierfür findet ihr ebenfalls weiter unten.
        So könnt ihr dann den Hintergrund mit farbigem Papier abkleben oder einfach so als Fensterdeko nutzen. 
        
        `,
        en: ``,
      },
    },
  ],

  downloadName: 'lebkuchenmann-gingerbreadman_papershape.pdf',
  materials: [
    {
      de: '1 DINA4 Papier (130-300g/m²)',
      en: '1 colored paper (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'ggf. Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'lebkuchen-selber-basteln-papier-vorlage.jpg',
          en: 'ginger-bread-man-paper-how-to-template.jpg',
        },
        alt_text: {
          de:
            'Lebkuchenmann aus Papier basteln zu Weihnachten als Geschenkeanhänger, Girlande oder Weihnachtsdeko #papershape',
          en:
            'christmas crafts: diy ginger bread man from paper with template #papershape',
        },
      },
    ],
  },
};
