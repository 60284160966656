import React from 'react';
import Lang from './common/Lang';

export const required = (value) =>
  value ? (
    undefined
  ) : (
    <Lang de="Das Feld ist erforderlich." en="This field is required." />
  );

export const maxLength = (max) => (value) =>
  value && value.length > max ? (
    <Lang
      de={`Das Feld muss eine Zeichenfolge mit einer maximalen Länge von ${max} sein.`}
      en={`Must be ${max} characters or less.`}
    />
  ) : (
    undefined
  );

export const maxLength100 = maxLength(100);
export const maxLength1000 = maxLength(1000);
export const maxLength500 = maxLength(500);

export const minLength = (min) => (value) =>
  value && value.length < min ? (
    <Lang
      de={`Das Feld muss eine Zeichenfolge mit einer minimalen Länge von ${min} sein.`}
      en={`Must be ${min} characters or more.`}
    />
  ) : (
    undefined
  );

export const minLength2 = minLength(2);

export const number = (value) =>
  value && isNaN(Number(value)) ? 'Must be a number.' : undefined;

export const minValue = (min) => (value) =>
  value && value < min ? (
    <Lang de={`Muss mindestens ${min} sein.`} en={`Must be at least ${min}.`} />
  ) : (
    undefined
  );

export const streetNameNumber = (value) =>
  value && !value.match(/\d/) ? (
    <Lang
      de={`Die Straße muss mit Hausnummer angegeben werden.`}
      en={`The street must have a number.`}
    />
  ) : (
    undefined
  );

export const zipCode = (value) =>
  value && !value.match(/\d{4,5}/) ? (
    <Lang
      de={`Muss eine gültige Postleitzahl sein.`}
      en={`Musst be a valid zip code.`}
    />
  ) : (
    undefined
  );

export const minValue18 = minValue(18);

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? (
    <Lang
      de={'Muss eine gültige E-Mail-Adresse sein.'}
      en={'Musst be a valid email address.'}
    />
  ) : (
    undefined
  );
