export default {
  url: 'ombre-weihnachtskranz-basteln',
  countermark: 'vg07.met.vgwort.de/na/b01bebcfdd7040bcae6371caef0df491',
  imageFolder: 'loopweihnachtskranz',
  meta_title: {
    de: 'DIY Papier Ombré Weihnachtskranz (Basteln mit Vorlagen)',
    en: 'DIY ombré Christmas Wreath from paper loops (free templates)',
  },
  meta_description_google: {
    de:
      'Einen Ombre Weihnachtskranz aus Papier basteln und an der Tür dekorieren oder als Tischdeko für den Advent arrangieren. Mit den kostenlosen Vorlagen zum Winter geht das ganz leicht.',
    en:
      'Try this colorful christmas wreath for your christmas decoration. The wreath is so easy to make. All you need are some loops from paper. ',
  },
  meta_description_social: {
    de:
      'Hängenden Weihnachtskranz basteln aus Papier in grünen Schattierungen #bastelnmitpapier #papershape #weihnachtsdeko',
    en: 'Christmas wreath from paper in green shades #christmas #wreath',
  },
  meta_images_social: [
    {
      de: 'ombre-weihnachtskranz-basteln.jpg',
      en: 'diy-ombre-christmas-wreath-paper.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'ombre-weihnachtskranz-basteln.jpg',
      en: 'diy-ombre-christmas-wreath-paper.jpg',
    },
    alt_text: {
      de:
        'DIY Weihnachtskranz basteln für die Tür oder den Tisch von PaperShape #papershape',
      en:
        'Christmas wreath diy from paper for your door oder table decor from PaperShape #papershape',
    },
  },
  h1: {
    de: 'DIY Papier Ombré Weihnachtskranz (Basteln mit Vorlagen)',
    en: 'DIY ombré Christmas Wreath from paper loops (free templates)',
  },
  description: {
    de: `
      
     <p> Urban Jungle ist nicht nur im Sommer angesagt.</p>
     <p> Grün entspannt unsere Augen und macht uns happy. Deswegen gibt es als Weihnachtsdeko diesen coolen Ombré-Weihnachtskranz.
     Ihr könnt ihn an die Tür hängen (am besten im Innenbereich) oder als Tischkranz mit Adventskerzen in der Mitte arrangieren. </p>
     <p>
     Also, zieht euch in eure Lieblingsecke zurück und los gehts mit dem Basteln dieses 
     echt einfachen Weihnachtskranzes. 
      </p>
      Und wer nicht genug von DIY an Weihnachten bekommen kann, der bereitet sich auf den Nikolaustag 
      mit dieser <a href="/de/blog/nikolaus-knallbonbon-basteln/">lustigen Geschenkverpackung</a> vor oder backt schon mal 
      <a href="/de/blog/papier-lebkuchenmann-basteln/">Papier-Lebkuchen</a> mit mir. 
      
    `,
    en: `
      <p>
      Urban Jungle is not just for summer!</p>
<p>
Green is a wonderful color and it relaxes our eyes and makes us happy. So I created this ombre Christmas wreath 
from green paper loops. You can hang it at the door or use it as a table wreath with some led-candles.       
</p>

          <p>
     If you like other shapes than loops then you must try this <a href="/en/blog/hexentreppe-herbstkranz-basteln/"> fall wreath </a>
     which you can transform easily into a Christmas wreath. 
      </p>
      <p>
      Or you start with these <a href="/en/blog/nikolaus-knallbonbon-basteln/">lovely St. Nicholaus crackers</a> or how about baking
      some <a href="/en/blog/papier-lebkuchenmann-basteln/">gingerbread figurs from paper</a> with me?
       </p>
    
      `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'weihnachtskranz-basteln-papier-ombre-loop.jpg',
          en: 'diy-christmas-wreath-door-paper-loops.jpg',
        },
        alt_text: {
          de:
            'DIY Weihnachtskranz basteln für die Tür oder den Tisch / PaperShape #diy #papershape',
          en:
            'Christmas wreath diy from paper for your door oder table decor from PaperShape #papershape',
        },
      },
    },

    {
      text: {
        de: '',
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: 'weihnachtskranz-basteln-papier.jpg',
          en: 'christmas-wreathp-paper-diy.jpg',
        },
        alt_text: {
          de:
            'DIY Weihnachtskranz basteln für die Tür oder den Tisch von PaperShape #papershape',
          en:
            'Christmas wreath diy from paper for your door oder table decor from PaperShape #papershape',
        },
      },
    },
  ],
  stepsBackgroundColor: 'rgb(243, 243, 243)',
  stepsTitle: {
    de: 'Wie bastel ich einen Weihnachtskranz aus Papier Schleifen',
    en: 'How do I to make a Christmas wreath from paper loops',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '01_schneiden.mp4',
      text: {
        de: `Sucht euch 6 unterschiedliche DIN A4 Papierfarben heraus. Für den Ombre-Look ist es perfekt, wenn es sich um Nuancen der gleichen Farbe handelt.
        Verwendet am besten Tonpapier mit einer Grammatur von 120g/m², weil sich dieses Papier sehr leicht biegen lässt ohne zu knicken.
        Mithilfe der Vorlage habe ich euch bereits eine Vorgabe gemacht, wie breit und lang die einzelnen Papierstreifen sein sollten. Los geht es mit dem Ausschneiden von diesen Streifen.
        Am Ende solltet ihr pro Farbe 20 Papierstreifen haben, aus denen sich der Weihnachtskranz zusammensetzt.
        
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '02_schleifen.jpg',
          en: '02_schleifen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_schleifen.mp4',
      text: {
        de: `Jetzt tragt ihr auf das eine Ende des Streifens einen Klebepad oder einen Kleckser Kleber auf und schlagt das andere Ende des Papierstreifens um 180 Grad um,
        sodass eine Schleife entsteht. `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '03_halbkreis.jpg',
          en: '03_halbkreis.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '03_halbkreis.mp4',
      text: {
        de: `Druckt euch die Vorlage für die Halbkreise 2x auf stabilem Fotokarton aus. Schneidet die beiden Halbkreise aus und klebt sie mit einem Tesa von beiden Seiten
        fest. Wenn ihr den Kranz stabiler gestalten möchtet, könnt ihr die Vorlagen auch auf einen Karton übertragen.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '04_kleben.jpg',
          en: '04_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '04_kleben.mp4',
      text: {
        de: `Auf den Halbkreisen seht ihr 6 Abschnitte mit "Farbe 1" etc. markiert. Mir hat es sehr geholfen ungefähr zu wissen, bis wohin ich die 20 Papierschleifen verteilen muss.
        Also - startet am Anfang eines dieser Markierungen mit 3 Papierschleifen, die ihr nebeneinander auf den Weihnachtskranz klebt. Die ersten solltet ihr relativ gerade anbringen. 
        Für die zweite Reihe verwendet ihr nur 2 Papierschleifen - das ist wie Mauern hochziehen, immer schön versetzt. Die Regel ist also immer 3-2-3-2. Um eine schöne Kurve beim Kranz 
        hinzubekommen, versucht einfach hin und wieder die äußeren Papierschleifen ein wenig nach außen zu kleben, sodass es euch gefällt.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '05_zweifarben.jpg',
          en: '005_zweifarbenn.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      video: '05_zweitefarben.mp4',
      text: {
        de: `Wenn ihr alle 20 Papierstreifen verklebt habt, beginnt ihr mit der zweiten Farbe usw. bis ihr alle 6 Farben angeklebt habt. Ziemlich cooler Effekt, oder? `,
        en: '',
      },
    },
  ],

  downloadName: 'weihnachtskranz-christmas-wreath_papershape.pdf',
  materials: [
    {
      de: '6 DINA4 Papier in 6 unterschiedlichen Farben (120g/m²)',
      en: '6 differently colored papers (8.5in x 11in) (weight: 65lb-110lb)',
    },
    {
      de:
        'Für den Kranz: 2 DIN A4 in grün (300g/m²) oder Karton (am besten grün bemalen)',
      en:
        'For the wreath background: 2 papers in green (weight: 65lb-110lb) or cardboard (paint it green)',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },

    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'weihnachtskranz-basteln-diy.jpg',
          en: 'christmas-wreath-paper-loops-diy.jpg',
        },
        alt_text: {
          de:
            'DIY Weihnachtskranz basteln für die Tür oder den Tisch von PaperShape #papershape',
          en:
            'Christmas wreath diy from paper for your door oder table decor from PaperShape #papershape',
        },
      },
    ],
  },
};
