import React from 'react';
import PsMeta from '../common/PsMeta';

export default () => (
  <div>
    <PsMeta title={'404 - Page not found'} noindex />

    <div className="container text-center">
      <h2 style={{ margin: '40px 0 20px' }}>
        <span role="img" aria-label="Sad">
          &#128533;
        </span>
      </h2>
      <h1 style={{ margin: '40px' }}>404 - Page not found</h1>
      <a href="/" className="btn btn-default">
        zum PaperShape Shop
      </a>
    </div>
  </div>
);
