import React, { Component } from 'react';
import { connect } from 'react-redux';
import PsBlogPostView from '../common/PsBlogPostView';
import { getTextBySelectedLang } from '../data/lang';
import Lang from '../common/Lang';
import { Grid, Row, Col } from 'react-bootstrap';
import PsMeta from '../common/PsMeta';
import { getBlogPosts } from '../state/blog/blogSelectors';

class BlogStart extends Component {
  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang(
            '3D Basteln mit Papier für Erwachsene: Deko und Geschenke',
            'DIY paper crafts'
          )}
          description={getTextBySelectedLang(
            'Entdecke die schönsten DIY Ideen aus Papier als Geschenke oder zum Aufhübschen deiner Wohnung mit unseren digitalen Vorlagen.',
            'In my blog you will find amazing diy paper crafts.'
          )}
          socialdescription={null}
          socialimages={null}
        />

        <Grid>
          <Row style={{ marginTop: '30px' }}>
            <Col xs={12}>
              <h1 className="text-center" style={{ marginBottom: '30px' }}>
                <Lang
                  de="3D Basteln mit Papier für Erwachsene"
                  en="DIY paper crafts"
                />
              </h1>
              <p>
                <Lang
                  de="Hereinspaziert in die kunstvolle Welt des Papiers: bei uns findest du die schönsten DIY Ideen aus Papier für Erwachsene zum Aufhübschen deiner Wohnung, zum Verschenken oder gegen Langeweile an so kalten Wintertagen. Tauch ein in die neue Dimension des Papierbastelns, denn bei uns ist alles 3D."
                  en=""
                />
              </p>
            </Col>
            {this.props.blogPosts.map((blogPost, index) => (
              <div key={blogPost.url}>
                <Col xs={6} sm={4}>
                  <PsBlogPostView blogPost={blogPost} withDescription />
                </Col>
                {++index % 2 === 0 && <div className="clearfix visible-xs" />}
                {index % 3 === 0 && (
                  <div className="clearfix visible-sm visible-md visible-lg" />
                )}
              </div>
            ))}
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blogPosts: getBlogPosts(state),
  };
};

export default connect(mapStateToProps)(BlogStart);
