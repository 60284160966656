export default {
  url: 'bilderrahmen-basteln-papier',
  countermark: 'vg07.met.vgwort.de/na/82ed359943864b7bba50e7bbbba2eb2f',
  imageFolder: 'bilderrahmen',
  meta_title: {
    de: 'Geniale Bilderrahmen basteln (in 3D und mit und ohne Kleben)  ',
    en: 'paper photo frame?',
  },
  meta_description_google: {
    de:
      'Einfache Bilderrahmen basteln aus Papier ist jetzt ganz einfach mit dieser kostenlosen Anleitung und Vorlage. Es ist gibt sie in zwei Größen 10x15cm & 7,5x10cm ',
    en:
      'Download the free template for these cute diy photo frames with paper now. Super easy to make and fun.',
  },
  meta_description_social: {
    de:
      'Bilderrahmen basteln aus Papier und mit Kindern gestalten. Ganz einfach selber machen, falten und aufhängen. Perfekt für 10x15cm Bilder. Einfaches Origami. #papershape #wanddeko #bilderrahmen #diy',
    en:
      'Photo frame diy - cool paper craft even with kids #papershape #papercraft #diy #photoframe',
  },
  meta_images_social: [
    {
      de: 'bilderrahmen-basteln-3d-einfach-ohne-kleben.jpg',
      en: 'diy-photo-frame-paper.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'bilderrahmen-basteln-3d-einfach-ohne-kleben.jpg',
      en: 'diy-photo-frame-paper.jpg',
    },
    alt_text: {
      de:
        '3D Bilderrahmen basteln aus Papier - einfach wie Origami falten und aufhängen / www.paper-shape.com #papershape #bilderrahmen #diy #wanddeko',
      en:
        '3d diy photo frame with paper -super easy origami and a lovely gift for your boyfriend or girlfriend / www.paper-shape.com #papershape #photoframe #diy #walldecor',
    },
  },
  h1: {
    de: 'Bilderrahmen basteln aus Papier ganz ohne Kleben ',
    en: 'How to make a diy paper photo frame?',
  },
  description: {
    de: `
      <p> Man kann sie zu allem gebrauchen:</p>
      <p>
      Ob als Wanddeko aufgehängt, an ein Geschenk geheftet, zum Muttertag oder gegen die Wand gelehnt, Bilderrahmen verpassen unseren schönsten Momenten
      oft den richtigen Rahmen. Oft möchte man seinem Geschenk noch eine individuelle Note verleihen.  
      </p>
      <p>
      Mit diesen zwei Bilderrahmen Ideen aus Papier habt ihr auf jeden Fall die notwendige Last Minute Idee.
      Einfach mit einem Foto versehen und voilà ist euer Geschenk mit etwas Selbstgemachtem versehen.  
      </p>
      
      <p> Diese anderen DIY Wanddeko Ideen müsst ihr euch unbedingt ansehen:</p>
      <p>
      <li><a href="/de/blog/geometrische-tiere-sticken/">coole low poly Tiere im Rahmen sticken</a></li>
      <li><a href="/de/blog/wanddeko-tangram-kaktus/">originelle Tangram-Kakteen züchten</a></li>
      </p>
    `,
    en: `
      <p>
      You can use them for everything:
      </p>
      <p>These photo frames are so easy to make and even with children you will enjoy decorating these fancy frames. You can hang them on walls, use them as gift tags or simply put
      them in your cupboards as cute decoration.      
      </p>
          <p>
      In this post you will find two templates for two different sizes of the photo frame. While the small one is made out of one sheet of paper, you will need 3 for the bigger one. 
      </p>
      <p> If you are interested in more wall deco ideas jump to these <a href="/en/blog/wanddeko-tangram-kaktus/">adorable tangram wall decor ideas</a>.</p>
    `,
  },
  inspirationTitle: {
    de: 'Bilderrahmen einfach basteln - auch mit Kindern',
    en: 'Photo frame ideas you can do even with your children',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'bilderrahmen-basteln-pappe.jpg',
          en: 'paper-photo-frame-3d.jpg',
        },
        alt_text: {
          de:
            '3D Bilderrahmen basteln und gestalten aus Papier. Coole DIY Deko Idee aus Papier zum Dekorieren der Wand oder als Geschenkanhänger. #papershape',
          en:
            '3d photo frames from paper. Cute DIY idea for your walls. or use them as gift tags. #papershape',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'bilderrahmen-basteln-einfach.jpg',
          en: 'bilderrahmen-basteln-einfach.jpg',
        },
        alt_text: {
          de:
            '3D Bilderrahmen basteln und gestalten aus Papier. Coole DIY Deko Idee aus Papier zum Dekorieren der Wand oder als Geschenkanhänger. #papershape',
          en:
            '3d photo frames from paper. Cute DIY idea for your walls. or use them as gift tags. #papershape',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'basteln-papier-bilderrahmen.jpg',
          en: 'basteln-papier-bilderrahmen.jpg',
        },
        alt_text: {
          de:
            '3D Bilderrahmen selber machen und gestalten aus Papier. Coole DIY Wanddeeko Idee aus Papier zum Dekorieren der Wand oder als Geschenkanhänger. #papershape',
          en:
            '3d photo frames from paper. Cute DIY idea for your walls. or use them as gift tags. #papershape',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
  ],
  stepsBackgroundColor: 'rgb(255, 255, 255)',
  stepsTitle: {
    de: 'Anleitung Nr. 1: Bilderrahmen basteln mit Kleben (10x15cm)',
    en: 'Instructions No 1: How to make paper photo frames (10x15cm)',
  },
  steps: [
    {
      image: {
        filename: {
          de: '02_ausschneiden.jpg',
          en: '02_ausschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_ausschneiden.mp4',
      text: {
        de: `Druckt die Bilderrahmen Vorlage auf DIN A4 Fotokarton in eurer Wunschfarbe oder Muster aus. Die Seite 2 müsst ihr zweimal ausdrucken, die Seite 1 nur einmal.
        Schneide mit einer Schere alle Elemente entlang der durchgezogenen Linie aus. `,
        en: `Print the photo frame template (US letter) on paper of your choice. You have to print the second page twice. Cut out the paper parts by cutting along the solid lines.`,
      },
    },

    {
      image: {
        filename: {
          de: '04_falzen.jpg',
          en: '04_falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '03_falzen.mp4',
      text: {
        de: `Alle gestrichelten Linien müssen am besten vorgefalzt werden, damit eine schöne Knickkante entsteht. 
        Legt nun ein Lineal an alle gestrichelten Linien an und fahrt mit einem stumpfen dünnen Gegenstand die Linien mit Druck
        ab. Unabhängig davon, ob ihr Fotokarton oder dünneres Papier verwendet, empfehle ich die Falzlinien
         mit Druck nachzufahren, bevor ihr das Papier faltet. So entstehen schöne Knicke und das Papier bricht nicht an den Kanten.`,
        en: ` All dotted lines should be pre-folded in order to get sharp edges. Place a ruler on the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines. Don't skip this step - no matter what paper you use. The result will look so much better.
        `,
      },
    },

    {
      image: {
        filename: {
          de: '05_knicken.jpg',
          en: '05_knicken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '04_knicken.mp4',
      text: {
        de: `     
         Legt die kurze Bilderrahmenseite so vor euch hin, dass ihr die gestrichelten Linien sehen könnt.
         Knickt die kurz gestrichelten Linien nach innen, d.h. zu euch hin (Talfalte). Macht dies bei allen gestrichelten Linien und achtet darauf sie wirklich komplett umzuknicken. So erhaltet ihr sehr schöne Ecken
         am Schluss beim Bilderrahmen, die nicht auseinanderstehen. Nun wiederholt ihr Schritt 2 und 3 für die restlichen Bilderrahmenseiten.`,
        en: `        
        Start with the small/ short paper part of the second page and place it front of you. 
              The dashed lines should be facing you. Subsequently fold all the dotted lines 
              inwards, which means to fold up. Repeat step 2 and 3 with the other paper parts for the photo frame.`,
      },
    },

    {
      image: {
        filename: {
          de: '08_kleben.jpg',
          en: '08_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '08_kleben.mp4',
      text: {
        de: ` Tragt eine Linie Kleber dünn auf die schraffierte Fläche auf, damit möglichst nichts überläuft. Klebt nun auf die schraffierte Fläche das Rechteck von Vorlage S. 1. Wiederholt Schritt 4 + 5 mit der zweiten kurzen Bilderrahmenseite und für eine der langen Bilderrahmenseiten.    
         `,
        en: `Place some glue on the hatched area of the short paper part. Stick the rectangle from page 1 of the template on the hatched area. Repeat step 4 + 5 with the second short paper part and for one of the long paper parts.         
       `,
      },
    },

    {
      image: {
        filename: {
          de: '11_zusammenstecken.jpg',
          en: '11_zusammenstecken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '11_ineinanderstecke.mp4',
      text: {
        de: ` Rollt nun die kurzen gegenüber liegenden Seite nach innen ein. Haltet sie mit dem Daumen und Mittelfinger euer linken Hand fest. 
        Mit der rechten Hand rollt ihr nun auch die lange Seite so ein, dass die Ecken in den Ecken der kurzen Seite verschwinden. (Wer Formulierungsvorschläge hat, sehr gerne her damit :))     
         `,
        en: `Furl the short paper parts inwards. Hold both parts with your left hand's thumb and middle finger. Furl the long paper part inwards with your right hand so that it plugs into the ends of the shorter paper parts.        
       `,
      },
    },

    {
      image: {
        filename: {
          de: '12_kleben5.jpg',
          en: '12_kleben5.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '12_kleben_2.mp4',
      text: {
        de: `Klebt nun das Rechteck auf die zweite lange Seite (schraffierte Fläche).     
         `,
        en: `     Stick the rectangle on the second long paper part of the frame (hatched area).   
       `,
      },
    },

    {
      image: {
        filename: {
          de: 'finalbild.jpg',
          en: 'finalbild.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: 'finalvideo.mp4',
      text: {
        de: `Schiebt ein Bild oder Foto eurer Wahl in den Bilderrahmen. Am besten hat das Foto die Maße 11,5 x 17,5cm. 
        Rollt die noch offene Bilderrahmenseite wieder nach innen ein, sodass sich die Ecken mit den Ecken der kurzen Seite verkanten. Rückt die Ecken noch zurecht, sodass sie schön verschließen.
        Et voilà!      
         `,
        en: `Slide your picture / photo into the frame. The size of the photo should be around 11,5 x 17,5cm. Furl the last side inwards and plug it into the flaps as in the previous step 11.         
       `,
      },
    },
  ],
  video: {
    title: {
      de: 'Anleitung Nr. 2: Bilderrahmen basteln ohne Kleben (7,5 x 10cm)',
      en:
        'Instructions No. 2: Photo frame from paper with no glue (7,5 x 10cm ).',
    },
    description: {
      de: `  Hier zeige ich euch, wie ihr den zweiten Bilderrahmen (7,5 x 10cm) aus einem DIN A4 faltet ohne zuKkleben.`,
      en: `Don't miss the photo frame video and learn how to craft photo frames from just one sheet of paper  with no glue.`,
    },
    caption: {
      de: `3D Bilderrahmen basteln aus Papier (mit Vorlage)`,
      en: `3D Photo frame from paper (with free template)`,
    },
    videoid: 'V6pTD__aiC0',
  },
  downloadName: 'bilderrahmen-basteln.zip',
  materials: [
    {
      de:
        '3 DINA4 Papier (Bilderrahmen 1) oder 1 DINA4 Papier (Bilderrahmen 2) uni oder gemustert (160-300g/m²)',
      en:
        '3 cardstock paper (photo frame no 1) or 1 cardstock paper (photo frame no 2) uni or with pattern (weight: 65lb-110lb / 176gsm-300gsm)',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
    {
      de:
        'Foto oder Illustration für Bilderahmen Nr. 1 in den Maßen 11,5x17,5cm',
      en: 'Photo or illustration for photo frame no 1: size 11,5x17,5cm',
    },
    {
      de: 'Foto oder Illustration für Bilderahmen Nr. 2 in den Maßen 8x12cm',
      en: 'Photo or illustration for photo frame no 2: size 8x12cm',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'bilderrahmen-basteln-papier.jpg',
          en: 'diy-photo-frame-paper-no-glue.jpg',
        },
        alt_text: {
          de:
            '3D Bilderrahmen basteln und gestalten aus Papier mit Anleitung und Vorlage - total einfach und super schönes Geschenk / PaperShape #papershape #bilderrahmen #wanddeko',
          en:
            'Paper photo frame tutorial - cute diy with template and instructions / PaperShape #papershape #photoframe #diywithkids #walldecor',
        },
      },
    ],
  },
};
