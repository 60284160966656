import React from 'react';
import { useSelector } from 'react-redux';
import { getCategories, getProductsGroupByCategory } from '../state/order/orderSelectors';
import Lang from './Lang';
import PsListMore from './PsListMore';
import PsProductView from './PsProductView';

function PsProductListByCategory({ open }) {
  const productsGroupByCategory = useSelector(getProductsGroupByCategory);
  const categories = useSelector(getCategories);

  return Object.keys(productsGroupByCategory).map(category => {
    const cat = categories[category] || {
      description: { de: category, en: category },
    };
    return (
      <div className="container" key={category}>
        <h2 className="text-center" style={{ marginBottom: 40 }}>
          <Lang de={cat.description.de} en={cat.description.en} />
        </h2>
        <div className="row">
          <PsListMore
            items={productsGroupByCategory[category]}
            open={open}
            renderItem={product => <PsProductView product={product} />}
          />
        </div>
      </div>
    );
  });
}

export default PsProductListByCategory;
