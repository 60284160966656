import React from 'react';

export default function PsFieldError({ meta }) {
  if (!React.isValidElement(meta.error)) {
    return <div />;
  }
  return (
    <div>
      {meta.touched &&
        meta.error && (
          <span className="field-validation-error">{meta.error}</span>
        )}
    </div>
  );
}
