import materials from './materials';

export default {
  url: 'papierring-basteln',
  countermark: 'vg07.met.vgwort.de/na/61a02eb313f14a868c7c096601448bbf',

  imageFolder: 'papierring',
  meta_title: {
    de: 'Stylishen Papierring selber machen aus Papierresten (Upcycling Idee)',
    en: 'How to make a paper ring from left overs (upcycling idea) ',
  },
  meta_description_google: {
    de:
      'Mit dieser Anleitung zeige ich euch, wie ihr aus Papierresten euren eigenen DIY Papierring selber machen könnt.',
    en:
      ' In this tutorial I show you how to make a paper ring from paper left overs. Really cool upcycling idea. ',
  },
  meta_description_social: {
    de: 'DIY Papier Ring selber machen aus Papierresten #papershape',
    en: 'DIY paper ring from paper left overs - tutorial #papershape ',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: 'papier-ring-diy-upcycling.jpg',
      en: 'how-to-paper-ring-upcycling.jpg',
    },
    alt_text: {
      de: 'DIY Papier Ring selber machen aus Papierresten #papershape',
      en: ' DIY 3d paper star for Christmas with template #papershape  ',
    },
  },
  h1: {
    de: 'Stylishen Papierring selber machen aus Papierresten (Upcycling Idee)',
    en: 'How to make a paper ring from paper left overs (upcycling idea)',
  },
  description: {
    de: `
      <p> Wie lange so ein DIY Papierring wohl hält?</p>
      <p>Das habe ich mich auch gefragt und war erstaunt, dass er durch die vielen Schichten Papier so stabil wie Holz war. 
      Wenn ihr also nach einer DIY Geschenkidee sucht oder einfach einen Ring aus Papierresten für euch selber machen wollt,
      dann ist diese Upcycling-Anleitung genau richtig für euch.
      </p>
    
    
      Und falls ihr Zeitschriften upcyceln wollt, probiert unbedingt diese 
      <a href="/de/blog/tasche-selber-machen/">geniale Candy Wrapper Bag</a> 
      aus - entweder als Shopper, Portemonnaie oder kleine Handtasche. 
            
    `,
    en: `
      <p>
    In this tutorial I'll show you an amazing upcycling idea for paper left overs.
      </p>
          <p>If you are searching for a diy gift or just want to craft a paper ring for yourself then this tutorial is for you!
             </p>
             
      <p>And maybe you are wondering what to do with your old magazines? What about 
      <a href="/en/blog/tasche-selber-machen/">making a candy wrapper bag</a> of them?
      </a>
        </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [],

  stepsTitle: {
    de: 'Wie bastel ich einen Ring aus Papier - Anleitung',
    en: 'How to make a paper ring from paper left overs - instructions',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: ` Eines vorweg: die Anleitungsbilder zeigen eine andere Form als auf dem Titelbild. Anstelle 
        von Dreiecken nehmt ihr Quadrate. Stanzt das Papier quadratisch aus oder schneidet es in einer Größe zu, die euch zusagt. Ich habe hier eine Größe von ca. 2,5cm verwendet.
        Danach zu Dreiecken schneiden. Falls ihr eher den Ring auf dem Titelbild herstellen möchtet, dann solltet ihr das Papier ca. 1,5x 1,5 cm zurechtschneiden. Durch das Schleifen geht nachher 
        auch noch Papier verloren. Deswegen nicht zu klein schneiden. 
        Ihr benötigt bei 300g/m² Papier ca. 30 Schichten.`,
        en: `  `,
      },
    },
    {
      image: {
        filename: {
          de: '02_kleben.jpg',
          en: '02_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Klebt alle 30 Schichten nun übereinander. Drückt das Papier wirklich fest zusammen.
        Wahrscheinlich ist hier Sekundenkleber sogar noch besser geeignet als Kraftkleber.`,
        en: ' ',
      },
    },

    {
      image: {
        filename: {
          de: '03_einklemmen.jpg',
          en: '03_einklemmen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Klemmt die verklebten Papierschichten in den Schraubstock ein und zieht es richtig fest zu.
         Hier nicht wundern, wenn nun aus allen Seiten der Kleber fließt. Nach 4 Stunden könnt ihr das gepresste Papier aus dem Schraubstock nehmen`,
        en: ` `,
      },
    },

    {
      image: {
        filename: {
          de: '04_schleifgeraet.jpg',
          en: '04_schleifgeraet.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: `Nun benötigt ihr ein Schleifgerät, mit dem ihr das Papier in Form schleift. Keine Sorge - durch die vielen Schichten Papier ist das Stück nun fast so hart wie Holz.        `,

        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '05_schleifen.jpg',
          en: '05_schleifen.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: `Nun das Ganze schleifen bis ihr zufrieden seid. Und ganz wichtig: Schutzbrille aufsetzen!!        `,

        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '06_muster.jpg',
          en: '06_muster.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: `Nachdem ihr alle Ecken und Kanten glatt geschliffen habt, könnt ihr auf der Oberseite Muster hineinschmirgeln, indem ihr mal tiefer und weniger tief in das Papier schmirgelt. 
        Je nachdem, welche Farbe unter dem obersten Papier liegt, kommt diese zum Vorschein.        `,

        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '07_fertig.jpg',
          en: '07_fertig.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: `Ich habe das Papierdreieck mit ein wenig Wasser befeuchtet, damit der Staub vom Schmirgeln abging und anschließend mit Klarlack 3x lackiert.
         Durch das Wasser und den Lack leuchtet das Papier anschließend sehr schön. Nun nur noch mit Sekundenkleber auf einer Ringschiene anbringen und ihr könnt
         - wie in diesem Fall - das Stück Wassermelone spazieren tragen.    `,

        en: ``,
      },
    },
  ],
  materials: [
    {
      de: 'Papierreste ',
      en: 'Paper left overs',
    },

    {
      de: 'transparenten Nagellack',
      en: 'transparent nail polish',
    },

    {
      de: 'Sekundenkleber',
      en: 'Superglue',
    },

    {
      de: 'Ringschiene (gold oder silber)',
      en: 'Ring rail (gold or silver)',
    },

    {
      de: 'Schutzbrille',
      en: ' Safety goggles',
    },

    {
      de: 'Schleifgerät',
      en: 'Grinding machine ',
    },

    {
      de: 'Schraubstock',
      en: 'Bench vice ',
    },

    materials.scissors,
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'diy-papier-ring-selber-machen-upcycling.jpg',
          en: 'diy-paper-ring-how-to-upcycle.jpg',
        },
        alt_text: {
          de: 'DIY Papier Ring selber machen aus Papierresten #papershape',
          en: 'DIY 3d paper star for Christmas with template #papershape',
        },
      },
    ],
  },
};
