import { getOrderProductByIdAndVariantKey } from './orderSelectors';
import {
  ADD_PRODUCT,
  ORDER_SUCCESSFUL,
  REMOVE_PRODUCT,
  SAVE_ORDER_NUMBER,
} from './orderActions';
import { combineReducers } from 'redux';
import { CLEAR_VOUCHER, VOUCHER_VALIDATED } from '../voucher/voucherActions';
import { CREATE_PAYMENT, PAYMENT_DONE } from '../payment/paymentActions';

const initialProductstState = [];
function productsReducer(state = initialProductstState, { type, payload }) {
  switch (type) {
    case ORDER_SUCCESSFUL:
      // reset state after the order has been placed
      return initialProductstState;
    case ADD_PRODUCT:
      return getOrderProductByIdAndVariantKey(
        state,
        payload.productId,
        payload.variantKey,
        payload.isDigitalProduct
      ) != null
        ? state.map(
            (product) =>
              product.id !== payload.productId ||
              product.variantKey !== payload.variantKey ||
              payload.isDigitalProduct
                ? product
                : {
                    ...product,
                    quantity: product.quantity + 1,
                  }
          )
        : [
            ...state,
            {
              id: payload.productId,
              variantKey: payload.variantKey,
              color1: payload.color1,
              color2: payload.color2,
              isDigitalProduct: payload.isDigitalProduct,
              quantity: 1,
            },
          ];
    case REMOVE_PRODUCT:
      const totalProductsQuantity = state.reduce(
        (quantitySum, product) => quantitySum + product.quantity,
        0
      );
      return state
        .map(
          (product) =>
            product.id !== payload.productId ||
            (product.variantKey !== payload.variantKey &&
              payload.isDigitalProduct !== true) ||
            // do not remove last product
            (totalProductsQuantity === 1 && product.quantity === 1)
              ? product
              : {
                  ...product,
                  quantity: product.quantity - 1,
                }
        )
        .filter((product) => product.quantity !== 0);

    default:
      return state;
  }
}

// attention: different lifecycles (orderNumber and data live after order creation - voucher before)
function generalReducer(
  state = { orderNumber: null, data: null, voucher: null },
  { type, payload }
) {
  switch (type) {
    case PAYMENT_DONE:
    case CREATE_PAYMENT:
      return {
        ...state,
        data: payload.order,
      };
    case SAVE_ORDER_NUMBER:
    case ORDER_SUCCESSFUL:
      return {
        ...state,
        orderNumber: payload.orderNumber,
        data: payload.order,
        voucher: null,
      };
    case CLEAR_VOUCHER:
      return {
        ...state,
        voucher: null,
      };
    case VOUCHER_VALIDATED:
      return {
        ...state,
        voucher: payload,
      };
    default:
      return state;
  }
}

export const orderReducer = combineReducers({
  products: productsReducer,
  general: generalReducer,
});
