export default {
  url: 'geschenkbox-basteln',
  countermark: 'vg07.met.vgwort.de/na/b494f4498bb2430ea7ff457f8cb1ca5a',
  imageFolder: 'geschenkbox',

  name: {
    de: 'Stabile Geschenkbox',
    en: 'Sturdy gift box',
  },
  id: '3-23-blog',
  pdf: {
    price: 3.9,
    priceThirdCountry: 3.9,
    image: {
      filename: {
        de: 'content/geschenkbox/geschenkbox-basteln-vorlage.jpg',
        en: 'content/geschenkbox/geschenkbox-basteln-vorlage.jpg',
      },
      alt_text: {
        de: 'DIY Geschenkbox als süße Papier Praline basteln #bastelnmitpapier #papercraft #diy',
        en: 'Learn how to make a sweet chocolate box from paper #papercraft #diy #papervase',
      },
    },
  },

  meta_title: {
    de: 'Stabile Geschenkbox basteln [Vorlage] ',
    en: 'How to make a paper gift box [template]',
  },
  meta_description_google: {
    de: 'Du bist auf der Suche nach einer DIY Verpackung? Hier zeige ich dir, wie du ganz einfach eine Geschenkbox basteln kannst.',
    en: ' ',
  },
  meta_description_social: {
    de: 'Geschenkbox basteln #papercraft #bastelnmitpapier #geschenkbox',
    en: 'DIY gift box tutorial for best friend with template (so easy) #papercraft #giftbox #packaging',
  },

  image_main: {
    filename: {
      de: 'geschenkbox-basteln-vorlage.jpg',
      en: 'geschenkbox-basteln-vorlage.jpg',
    },
    alt_text: {
      de: 'Geschenkbox basteln mit Vorlage zum Geburtstag #bastelnmitpapier #geschenkverpackung #diy #papercraft ',
      en: 'DIY gift box for birthday with template (easy) #papercraft #giftbox #diy',
    },
  },
  h1: {
    de: 'Stabile Geschenkbox basteln',
    en: 'How to make a simple diy gift box',
  },
  description: {
    de: `
      <p>Wer etwas Schönes verschenkt, der möchte es auch hübsch verpackt wissen. 
      Am besten machen sich selbstgemachte Verpackungen, denn sie kann man individualisieren, 
      beschriften, bemalen – einfach mit Liebe gestalten.  </p>
      <p>In diesem DIY Post zeige ich euch, wie ihr eine schöne quadratische Geschenkbox basteln könnt. 
      Dazu gibt es einen stabilen Deckel. So könnt ihr nicht nur Kleinigkeiten verschenken, sondern auch etwas schwerere Geschenke ohne dass sich das Papier der
      Geschenkverpackung verbiegt.  </p>
      <p>Die Geschenkbox hat eine Größe von je 9cm Breite und Länge und einer Höhe von 4cm.    </p>
     
      <p>Solltet ihr auf der Suche nach einer anderen Form der Geschenkverpackung sein, dann emfpehle ich euch hier vorbeizuschauen:
      <ul>
      <li><a href="/de/blog/herzschachtel-basteln/">Süße Herzschachteln mit Deckel</a> </li></p>
      <li><a href="/de/blog/geschenktuete-basteln/">Geschenktüte mit süßem Regenbogen-Henkel</a></li> </p>
      <li><a href="/de/blog/nikolaus-knallbonbon-basteln/">Lustiger Knallbonbon-Nikolaus als Geschenkverpackung</a> </li>
      </ul>
    
    `,
    en: `
      <p>If you give away something beautiful, you also want to to pack it nicely.
      The best way is to make homemade packaging, because you can customize it the way you want - simply put: you design it with love. </ P>
      <p> In this DIY post, I'll show you how to make a nice simple square gift box.
       </ P>
      <p> The gift box has a size of 9cm width and length and a height of 4cm. </ P>
     
      <p> If you are looking for another shape of gift wrapping then I suggest you stop by here:
      <Ul>
      <li> <a href="/en/blog/herzschachtel-basteln/"> Cute heart shaped boxes</a> </ li> </ p>
      <li> <a href="/en/blog/geschenktuete-basteln/"> Gift bag with cute rainbow handle </a> </ li> </ p>
      <li> <a href="/en/blog/nikolaus-knallbonbon-basteln/"> Funny cracker in the shape of Santa Claus</a> </ li>
      </ Ul>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: '3 bunte  DIY Ideen aus Geschenkboxen für verschiedene Anlässe',
    en: '',
  },

  inspirationDescription: {
    de: `
   
<p><h3>Geschenkschachtel selber machen zum Muttertag (mit Kindern)</h3></p>
<p>Diese Papierschachtel ist so einfach zu basteln, dass Väter mit ihren Kindern sie im Nu kreieren können. 
Bemalt, mit Glitzer, Pailetten und Bändern beklebt, oder mit der handschriftlichen Notiz vom Kind „für Mama“ versehen, 
entsteht so eine ganz individuelle Geschenkverpackung zum besonderen Anlass.</p>




<p><h3>DIY Box als Organizer </h3></p>
<p>Wenn auf eurem Schreibtisch mal wieder das Chaos wütet und ihr nicht so recht wisst, wohin mit den Büroklammern, 
Pinnnadeln und Reißzwecken, dann ist diese Box eine sehr ästhetische Form dem Chaos beizukommen.
 Sie sieht sehr hübsch aus und kann aus verschiedenem Papier euren Farben im Zimmer angepasst werden. </p>




<p><h3>Mini Geschenkbox basteln für Schmuck</h3></p>
<p>Wem die Box ein wenig zu groß ist und er sie lieber kleiner gestalten möchte, der kann die Vorlage einfach auf der Hälfte 
der Größe ausdrucken. Beim Drucker dazu entweder über Einstellungen > Seite einrichten > "Seitenlayout" oder "skaliert" auswählen. Im letzten Fall müsst ihr lediglich noch die Prozentzahl 
angeben, auf die skaliert werden soll. Hier kann dann auch gerne Dekorpapier oder Geschenkpapier verwendet werden, um einfache und kleine 
Gegenstände wie Schmuck oder Süßigkeiten zu verpacken.  </p>



    
    `,
    en: '',
  },

  stepsTitle: {
    de: 'Schachtel mit Deckel basteln - Anleitung ',
    en: '',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'geschenkbox-vorlage-ausdrucken.jpg',
          en: 'geschenkbox-vorlage-ausdrucken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Ihr benötigt pro Geschenkbox 2 DINA4 Seiten in einem Papier eurer Wahl. Soll etwas leichtes verschenkt werden, dann könnt ihr auch Geschenkpapier verwenden.
          Wenn ihr aber zum Beispiel Plätzchen als Inhalt verpacken möchtet, dann empfehle ich euch Fotokarton, weil dieser am stabilsten ist. Druckt euch die Vorlage aus. Die erste Seite 
          ist der Boden und die zweite Seite der Deckel. Ihr könnt also auch unterschiedliche Farben verwenden. 
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'geschenkbox-ausschneiden.jpg',
          en: 'geschenkbox-ausschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Schneidet alle durchgezogenen Linien mit einer Schere aus.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'geschenkschachtel-falzen.jpg',
          en: 'geschenkschachtel-falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Falzt mit einem Falzbein und einem Lineal alle gestrichelten Linien vor. Dazu das Lineal an die Linie anlegen und mit dem Falzbein mit Druck
        nachfahren. Solltet ihr zum Beispiel Geschenkpapier verwenden, könnt ihr euch diesen Schritt sparen. Das Papier ist so dünn, da wird die Falte in der Regel
        nicht brechen.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'papierschachtel-falten.jpg',
          en: 'papierschachtel-falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin.
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'geschenkbox-kleben.jpg',
          en: 'geschenkbox-kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Verklebt die Laschen nun so, dass sie von außen nicht sichtbar sind, indem ihr ein wenig Kleber oder Klebepads auf die Laschen auftragt.
        Für den Deckel wiederholt ihr die Schritte 1-5, falls ihr lediglich mit dem Boden begonnen habt. 
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'geschenkbox-deckel.jpg',
          en: 'geschenkbox-deckel.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Wenn der Deckel fertig ist, könnt ihr ihn ganz einfach über die Box stülpen. Nun ist die Geschenkbox fertig zum Verzieren und Befüllen. Viel Spaß!
  `,
        en: ``,
      },
    },
  ],

  downloadName: 'geschenkbox-vorlage-papershape.pdf',
  materials: [
    {
      de: 'Vorlage herunterladen (siehe unten)',
      en: 'Donwload template (see below) ',
    },
    {
      de: '2 DINA4 Tonpapier bis Fotokarton, auch Designpapiere (je nach Inhalt ab 120g/m² bis zu 300g/m²)',
      en: '2 colored papers ',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Falzbein oder ähnliches',
      en: 'Bonefolder.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'geschenkbox-basteln-anleitung-vorlage.jpg',
          en: 'gift-box-tutorial-diy-template.jpg',
        },
        alt_text: {
          de: 'Geschenkbox basteln aus Fotokarton mit Vorlage (sehr einfach) #bastelnmitpapier #geschenkverpackung #papercraft ',
          en: 'Learn how to make this simple diy gift box - tutorial with template #papercraft #howto #giftbox ',
        },
      },
    ],
  },
};
