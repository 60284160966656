import React from 'react';
import Lang from '../../common/Lang';

export default ({ onClick }) => {
  return (
    <div className="section grey-section">
      <div className="container">
        <div className="row text-center" style={{}}>
          <a
            id="jetztKaufenLink"
            className="btn btn-primary btn-lg jetzt-kaufen"
            onClick={onClick}>
            <Lang de="Jetzt bestellen" en="Add to bag" />
          </a>
        </div>
      </div>
    </div>
  );
};
