export default {
  url: 'papershape-dieser-kalender-ist-ein-unikat',
  countermark: 'vg07.met.vgwort.de/na/1734152dff4741b5b11dfa9b23671064',
  imageFolder: 'ackermannkalender',
  meta_title: {
    de: 'Dieser Kalender ist ein Unikat',
    en: 'This calender is one of a kind',
  },
  meta_description_google: {
    de: 'Der Kalender für kreative Papierliebhaber und DIY-Begeisterte. Hier findest du jeden Monat Kunst und Bastelspaß.',
    en: 'The calendar for creative paper lovers and DIY enthusiasts. You will find every month art and crafts',
  },
  meta_description_social: {
    de: 'Wirfst du deine Wandkalender nach einem Jahr einfach weg? Viel zu schade, haben sich der Ackermann Kunstverlag und PaperShape gedacht. Mit unserem Bastelkalender erhältst du nicht nur wunderschöne Kalenderkunst an deine Wände, sondern auch gleich Vorlagen zum Basteln cooler Dekoobjekte aus Papier. Klick dich gleich weiter zum Shop.',
    en: '',
  },
  meta_images_social: [
    {
      de: 'Kunstkalender 2024 Geschenk für Papierliebhaber und Bastelbegeisterte',
      en: 'Art calendar 2024 gift for paper lovers and craft enthusiasts',
    },
  ],
  image_main: {
    filename: {
      de: 'papershape-dieser-kalender-ist-ein-unikat.jpg',
      en: 'papershape-dieser-kalender-ist-ein-unikat.jpg',
    },
    alt_text: {
      de: 'Kunstkalender 2024 Geschenk für Papierliebhaber und Bastelbegeisterte',
      en: 'Art calendar 2024 gift for paper lovers and craft enthusiasts',
    },
  },
  h1: {
    de: 'Dieser Kalender ist ein Unikat',
    en: 'This calender is one of a kind',
  },
  description: {
    de: `
      <p>YAY! Dieser Kunstkalender ist ein ganz besonderes Unikat. </p>
      <p>Der Ackermann Verlag und PaperShape haben gemeinsam einen <a href="https://www.ackermann-kalender.de/papershape-dieser-kalender-ist-ein-unikat?affiliateCode=papershape " target="_blank">Kalender für kreative Papierliebhaber</a> entwickelt. 
      
 
      <p>Die Idee hinter diesem immerwährenden Kalender entstand aus dem Wunsch heraus die Papiere 
      nach einem abgelaufenen Jahr wieder zu recyceln.</p>
      <p>Herausgekommen ist ein irrer cooler Kalender in riesiger Größe (49x66cm). 
      Aus den zwölf Monatsblättern können zwölf PaperShape Deko-Objekte gebastelt werden.
      Sie orientieren sich thematisch an der Saison und so werden die Weihnachtskugeln aus dem November 
      passend zum Weihnachtsbaum im Dezember gebastelt.
      

      <p>Es ist ein passendes Weihnachtsgeschenk für alle Papierliebhaber, kreative DIY-Fans und Kunstbegeisterte. Denn die Monatsblätter
      sind aufgrund ihrer Größe ein echter Hingucker - egal ob eingerahmt oder ganz minimalistisch am Nagel hängend. </p>

     <p>Im Shop vom Ackermann Verlag könnt ihr <a href="https://www.ackermann-kalender.de/papershape-dieser-kalender-ist-ein-unikat?affiliateCode=papershape" target="_blank"> diesen Kalender erwerben</a>. </p>
     
      
      
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: 'Ein sehr altes Druckverfahren hat unsere Kalender zu etwas ganz besonderem gemacht',
    en: 'A very old printing process has made our calendars very special',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'papershape-kalender-druckverfahren-irisdruck.jpg',
          en: 'papershape-kalender-druckverfahren-irisdruck.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: `Zusammen mit der Druckerei Staudigl hat der Ackermann Verlag das historische Irisdruckverfahren zum Leben erweckt. 
        Dabei wird auf die einzelnen Farbwalzen Farbe aufgetragen. Durch die mehrfache Rotation vermischen sich die Farben und es entsteht ein ganz besonderer Farbverlauf, der mit jeder weiteren
        Umdrehung ineinander verläuft und damit jedes Stück zu einem Unikat macht.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'papershape-kalender-farbwalzen.jpg',
          en: 'papershape-kalender-farbwalzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: `Das Team vom Staudigl war mit Feuer und Flamme dabei. Eigentlich hieß es vom Maschinenhersteller, dass diese Art des Drucks
        mit der Druckmaschine nicht funktionieren würde. Aber alle sagten sich, dass es auf einen Versuch ankäme...und das Wundervolle geschah: es war möglich.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'ackermann-papershape-wandkalender.jpg',
          en: 'ackermann-papershape-wandkalender.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: `Ein unglaublich magisches Gefühl war es, die Fotos von den fertigen Kalendern zu sehen. Leider konnte ich beim 
        Druck selbst nicht dabei sein. Ich war zu dem Zeitpunkt hochschwanger. Die leuchtenden Farbverläufe verführen dich wirklich in malerische Momente: es ist so, als würdest du auf einen bunten Regenbogen 
        nach einem erfrischenden Sommerregen blicken. Und sobald der Monat verstrichen ist, 
        kannst du aus dem edlen Papier ein beeindruckendes Papierobjekt basteln. `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'papershape-ackermann-wandkalender-unikat-monatsblatt-bastelvorlage.jpg',
          en: 'papershape-ackermann-wandkalender-unikat-monatsblatt-bastelvorlage.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: `Jedes der Monatsblätter hat einen anderen intensiven bunten oder monochromen Regenbogen-Verlauf. Auf der
        Rückseite findet ihr die original PaperShape Vorlagen. An Materialien werden nur der Kalender, Schere, Falzbein und Kleber benötigt.
        Aber auch das ist haargenau im Kalender beschrieben. Im Shop vom Ackermann Verlag könnt ihr <a href="https://www.ackermann-kalender.de/papershape-dieser-kalender-ist-ein-unikat?affiliateCode=papershape" target="_blank"> diesen Kalender erwerben</a>. </p> `,
        en: ``,
      },
    },
  ],

  materials: [
    {
      de: 'PaperShape Kalender vom Ackermann Verlag',
      en: 'PaperShape Calendar from Ackermann publisher',
    },

    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle oder Rückseite der Schere',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
  ],

  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    ],
  },
};
