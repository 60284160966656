import materials from './materials';

export default {
  url: 'geometrische-tiere-sticken',
  countermark: 'vg07.met.vgwort.de/na/4f912e88a2e640d494e4767890ab7fcc',

  imageFolder: 'tieresticken',
  meta_title: {
    de: 'Geometrische Tiere sticken auf Karton (mit kostenloser Vorlage)',
    en:
      'Step by step: How to embroider geometric animals on cardboard (free template)',
  },
  meta_description_google: {
    de:
      'Mit dieser Vorlage entstehen eure geometrischen Tiere spielend. Einfach Vorlage auftragen und mit einem Faden auf Karton oder dickeres Papier sticken.  ',
    en:
      " In this tutorial I'll show you how to make an a cool wall decor with geometric animals on cardboard ",
  },
  meta_description_social: {
    de:
      'Geometrische Tiere sticken auf Karton als Wanddeko mit kostenlosen Vorlagen von #papershape',
    en: 'How to embroider geometric animals on cardboard (with templates)  ',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: 'geometrische-tiere-sticken-nashorn.jpg',
      en: 'geometric-animals-embroidery-rhino.jpg',
    },
    alt_text: {
      de:
        'DIY Geometrische Tiere sticken auf Karton als Wanddeko mit kostenlosen Vorlagen von #papershape',
      en: ' How to embroider geometric animals on cardboard (with templates) ',
    },
  },
  h1: {
    de: 'Geometrische Tiere sticken auf Karton (mit kostenloser Vorlage)',
    en: 'How to embroider geometric animals on cardboard (with templates)  ',
  },
  description: {
    de: `
      <p>
      Geometrische Tiere sind bei mir ja Programm, aber diesmal gibt es sie gestickt auf Karton. Die Verbindung aus Garn und Papier habe ich bei anderen Künstlern schön häufiger gesehen und Sticken ist ja nun gerade wieder total im Trend.
      </p>

      <p>
      Ihr könnt ganz einfach alte Kartons recyceln. Verwendet einfach die Vorlagen für das Nashorn, das Pferd und die Kuh um schöne günstige Wanddeko zu gestalten. Am besten rahmt ihr das ganze noch in einen Bilderrahmen ein,
      dann hat es einen schöneren Touch. 
      </p>
      
      
    `,
    en: `
      <p>
     In this tutorial I'll show you how to embroider goergous geometric animals on cardboard. Have fun with this lovely wall decor. !
      </p>
          
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'geometrische-tiere-sticken-pferd.jpg',
          en: 'geometrische-tiere-sticken-pferd.jpg',
        },
        alt_text: {
          de:
            'Geometrische Tiere sticken auf Karton (mit kostenloser Vorlage) #papershpe',
          en:
            'How to embroider geometric animals on cardboard (with templates) papershape',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'geometrische-tiere-sticken-kuh.jpg',
          en: 'geometrische-tiere-sticken-kuh.jpg',
        },
        alt_text: {
          de:
            'Geometrische Tiere sticken auf Karton (mit kostenloser Vorlage) #papershpe',
          en:
            'How to embroider geometric animals on cardboard (with templates) #papershpe',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
  ],

  stepsTitle: {
    de: 'Wie sticke ich geometrische Tierköpfe auf Karton - Anleitung',
    en: 'How to embroider geometric animal heads on cardboard - step by step',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01.jpg',
          en: '01.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '01.mp4',
      text: {
        de: `Drucke die Vorlage auf normales 90g Druckerpapier aus. Ihr benötigt einen Pappkarton, der in etwa so groß ist wie die Vorlage.
        Am besten ein wenig größer. Auf diesen legt ihr die Vorlage und das Passpartout auf. 
        Nun die Vorlage festhalten und das Passepartout entfernen. Die Vorlage könnt ihr nun mit je einem Tesafilm an den vier Seiten auf dem Pappkarton befestigen.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '01.jpg',
          en: '01.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02.mp4',
      text: {
        de: `Nun mit einer Nadel oder Ahle in die Eckpunkte des Tierkopfes ein Loch hineinstechen.
        Je nachdem, womit ihr arbeitet, benötigt ihr mehr oder weniger Druck.
         Wenn ihr alle Löcher durchgestochen habt, könnt ihr die Vorlage wieder entfernen.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '03.jpg',
          en: '03.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '03.mp4',
      text: {
        de: `Schneidet euch von dem Garn ca. 40 cm ab und fädelt ihn in die Nadel ein. 
        Am Ende des Garns macht ihr einen Doppelknoten. Nun beginnt ihr mit dem Sticken bis ihr die Kuh, das Nashorn oder Pferd fertigt gestickt habt.
         Am besten legt ihr die Vorlage neben euch und versucht diese nun von Loch zu Loch zu sticken. Ich habe mir 
         selbst dabei einbisschen Spielraum gelassen. D.h. ich habe zwar die Konturen sehr penibel gestickt, aber bei 
         den einzelnen Dreiecken improvisiert. Macht es wie ihr mögt! Viel Spaß wünsche ich euch mit diesem polygonalen Do it Yourself.`,
        en: ``,
      },
    },
  ],

  downloadName: 'tiere-vorlagen-animal-templates_papershape.pdf',
  materials: [
    {
      de: 'Pappkarton (ca. DINA4-Größe oder etwas größer) ',
      en: 'Cardboard (a little bit bigger than A4 size)',
    },

    {
      de: 'Tesafilm',
      en: 'self-adhesive tape',
    },

    {
      de: 'weißes Garn (am besten etwas dicker)',
      en: 'white yarn ',
    },

    {
      de: 'Bilderrahmen inkl. Passpartout',
      en: 'frame',
    },

    materials.awl,
    materials.scissors,
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'geometrische-tiere-sticken-vorlagen.jpg',
          en: 'geometric-animals-embroider-cardboard-templates.jpg',
        },
        alt_text: {
          de:
            'DIY Geometrische Tiere sticken auf Karton als Wanddeko mit kostenlosen Vorlagen von #papershape ',
          en:
            'How to embroider geometric animals on cardboard (with templates) #papershape',
        },
      },
    ],
  },
};
