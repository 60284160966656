import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

function PsYoutube({ title, videoid, socialmedia, dispatch }) {
  return (
    <div className="vid">
      {socialmedia ? (
        <iframe
          title={title}
          width="560"
          height="315"
          src={
            'https://www.youtube-nocookie.com/embed/' +
            videoid +
            '?rel=0&amp;showinfo=0'
          }
          frameBorder="0"
          allowFullScreen
        />
      ) : (
        <div style={{ width: '100%', height: '315px', backgroundColor: '#eeeeee' }} className="text-center center-block">
          <a className="btn btn-default" style={{marginTop: '30px'}} onClick={() => {
            dispatch(change('cookies', 'socialmedia', true));
          }}>YouTube Video aktivieren</a>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    socialmedia:
      state.form != null &&
      state.form.cookies != null &&
      state.form.cookies.values != null
        ? state.form.cookies.values.socialmedia
        : false,
  };
};

export default connect(mapStateToProps)(PsYoutube);
