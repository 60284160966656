export default {
  url: 'gerbera-basteln',
  countermark: 'vg07.met.vgwort.de/na/c4b07bf135e846be93ab841f8c748e7a',
  imageFolder: 'gerbera',
  meta_title: {
    de: 'Gerbera basteln aus Tonpapier (Vorlage) ',
    en: 'DIY Gerbera Daisy paper flower',
  },

  name: {
    de: ' Gerbera',
    en: ' Gerber daisy',
  },
  id: '3-18-blog',
  pdf: {
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'content/gerbera/diy-gerbera-daisy-basteln.jpg',
        en: 'content/gerbera/diy-gerbera-daisy-basteln.jpg',
      },
      alt_text: {
        de: 'DIY Gerbera basteln aus Papier ',
        en: 'Learn how to make a paper gerbera daisy',
      },
    },
  },

  meta_description_google: {
    de: 'Schöne Gerbera Blumen mit Papier basteln geht mit der PaperShape Vorlage ganz einfach.',
    en: 'Learn how to make paper gerbera daisy with this easy template ',
  },
  meta_description_social: {
    de: 'DIY Gerbera basteln mit Tonpapier: Anleitung für besondere Anlässe wie Muttertag oder Hochzeit #bastelnmitpapier #paperflowers #papercraft',
    en: 'How to make diy paper gerbera daisy #paperflowers #papercraft',
  },
  meta_images_social: [
    {
      de: 'diy-gerbera-daisy-basteln.jpg',
      en: 'diy-gerbera-daisy-basteln.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'diy-gerbera-daisy-basteln.jpg',
      en: 'diy-gerbera-daisy-basteln.jpg',
    },
    alt_text: {
      de: 'Gerbera basteln mit Papier zum Muttertag #papierrosen #muttertag #papierblumen #bastelnmitpapier',
      en: 'Learn how to make paper daisy #paperflowers #papercraft #mothersday',
    },
  },
  h1: {
    de: 'Gerbera basteln aus Papier (Vorlage)',
    en: 'How to make paper gerbera daisy (printable)',
  },
  description: {
    de: `
      <p>Es gibt DIY Ideen, die eignen sich einfach für alle Gelegenheiten - wie diese Gerbera zum Beispiel. </p>
      <p>Papier Gerbera selber machen ist nicht nur eine coole Freizeitbeschäftigung, sie vermitteln auch eine schöne Symbolik, die sagt: Mit dir ist alles besser.. </p>
      
     
        
      <p>Weitere schöne Papierblumen könnt ihr hier basteln: <a href="/de/blog/hyazinthen-basteln/">Hyazinthen aus Papier</a> oder <a href="/de/blog/papier-tulpen-basteln/">einfache Tulpen</a>.</p>
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: 'DIY Gerbera: 2 Ideen für Blumengeschenke',
    en: '2 fun ideas with a paper gerbera daisy',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'diy-papier-gerbera-blumenstrauss.jpg',
          en: 'diy-papier-gerbera-blumenstrauss.jpg',
        },
        alt_text: {
          de: '',
          en: 'Make this lovely paper rose topper for your Mothersday Gift #paperflowers #paper #papercraft #packaging #paperrose #diy',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'papercraft-gerbera-daisy.jpg',
          en: 'papercraft-gerbera-daisy.jpg',
        },
        alt_text: {
          de: 'DIY Papierblumen basteln: schöne Rosen falten für eine dekorative Vase #bastelnmitpapier #papierblumen #papierrosen #diy',
          en: 'DIY paper rose tutorial: lovely paper flowers for every occasion #papercraft #paperflower #paperdiy #paperrose',
        },
      },
    },
    {
      text: {
        de: `
       `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'tonpapier-gerbera-blumentopf-muttertag.jpg',
          en: 'tonpapier-gerbera-blumentopf-muttertag.jpg',
        },
        alt_text: {
          de: 'DIY Papierblumen basteln: schöne Rosen falten für eine dekorative Vase #bastelnmitpapier #papierblumen #papierrosen #diy',
          en: 'DIY paper rose tutorial: lovely paper flowers for every occasion #papercraft #paperflower #paperdiy #paperrose',
        },
      },
    },
    {
      text: {
        de: ` 
         `,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'Wie bastelt man Gerbera aus Papier - Anleitung',
    en: 'Step by step tutorial for diy paper gerbera daisy ',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'gerbera-vorlage-ausdrucken.jpg',
          en: 'gerbera-vorlage-ausdrucken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Druck dir die Vorlage auf Tonpapier (ca. 120g/m²) aus. 
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'gerbera-blume-ausschneiden.jpg',
          en: 'gerbera-blume-ausschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Schneide die Vorlagen entlang der durchgezogenen Linien aus.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'tonpapier-lineal-ahle-falzen.jpg',
          en: 'tonpapier-lineal-ahle-falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: 'Lege ein Lineal an die gestrichelten Linien und fahren mit einer Ahle und etwas Druck diese nach.',
      },
    },
    {
      image: {
        filename: {
          de: 'diy-gerbera-papier-falten.jpg',
          en: 'diy-gerbera-papier-falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Falte nun alle kurz gestrichelten Linien zu dir hin (Talfalte), die lang gestrichelten Linien faltest du von dir weg (Bergfalte). Damit die Knicke so richtig schön zur Geltung kommen, knicke sie komplett um und drücke sie wie eine Ziehharmonika zusammen`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'gerbera-daisy-blueten-kleben.jpg',
          en: 'gerbera-daisy-blueten-kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Verklebe nun die zwei Blütenteile miteinander. Auf der Vorlage sind vier Blütenelemente abgedruckt. Je zwei Elemente mit dem gleichen Buchstaben gehören zusammen.
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'diy-gerbera-papierblume.jpg',
          en: 'diy-gerbera-papierblume.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Wenn du die Blüten richtig geknickt und verklebt hast, beugt sie sich leicht nach oben.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'gerbera-stempel-falten-einschneiden.jpg',
          en: 'gerbera-stempel-falten-einschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Nun nimmst du dir den großen Kreis von den drei Kreisen zur Hand und knickst ihn zweimal je zur Hälfte. Du solltest nun eine Art Tortenstück vor dir liegen haben. Setze von außen zur Spitze je 3 Schnitte. Der vierte Kreis in der der Vorlage dient lediglich als Backup.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'papierkreis-runden.jpg',
          en: 'papierkreis-runden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Öffne den Kreis nun und zieh mit einer Schere die äußeren Fransen in Form (wie du es bei einem Geschenkband tun würdest). Diesen Schritt wiederholst du für die restlichen 2 Kreise ebenfalls.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'blueten-stempel-einkrauseln.jpg',
          en: 'blueten-stempel-einkrauseln.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: 'Kräusel die Kreise zu einer Kugel bis auf den letzten Kreis, den du flach liegen lässt.',
      },
    },

    {
      image: {
        filename: {
          de: 'steckdraht-oese.jpg',
          en: 'steckdraht-oese.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `11.	Nimm dir nun den Steckdraht zur Hand und biege eine kleine Öse in den Draht. Nun fädeln wir die Blume auf ein Steckdraht in der folgenden Reihenfolge: Blütenstempel, Blüte B, Blüte A, Kreis. 
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'tonpapier-stempel-loch-einstechen.jpg',
          en: 'tonpapier-stempel-loch-einstechen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `10.	Nun stichst du in die Mitte des großen Kreises ein Loch hinein.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'gerbera-basteln-anleitung.jpg',
          en: 'gerbera-basteln-anleitung.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Auf den Steckdraht steckst du nun als erstes den Blütenstempel (d.h. den gekräuselten Kreis).
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'gerbera-aufstecken.jpg',
          en: 'gerbera-aufstecken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Als nächstes steckst du die Blüten durch den Draht. 
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'papier-blume-letzter-teil.jpg',
          en: 'papier-blume-letzter-teil.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Zum Schluss kommt der dritte Kreis auf den Steckdraht.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'gerbera-steckdraht-floristenkrepp-umwickeln.jpg',
          en: 'gerbera-steckdraht-floristenkrepp-umwickeln.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Mit etwas Floristikband verkleben wird den Schafft der Blume. 
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'gerbera-stiel-basteln.jpg',
          en: 'gerbera-stiel-basteln.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Der Schafft mit dem Floristenkrepp sollte in ungefähr so aussehen. Zieht nun nochmal alles gut fest, in dem ihr das Krepp nach oben schiebt.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'diy-gerbera-tonpapier-basteln.jpg',
          en: 'diy-gerbera-tonpapier-basteln.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Zum Schluss setzen wir den dritten Kreis (zu einer Kugel gekräuselt) auf die Steckdrahtöse, damit sie nicht sichtbar ist. Viel Spaß beim Dekorieren! 
        
  `,
        en: ``,
      },
    },
  ],

  downloadName: 'gerbera-bastelvorlage_papershape.pdf',
  materials: [
    {
      de: 'Vorlage herunterladen (siehe unten)',
      en: 'Download the template (see below)',
    },
    {
      de: '1 DINA4 Tonpapier in pink (bis zu 130g/m²)',
      en: '1 colored paper in rink (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder Heißkleberpistole',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },

    {
      de: 'Holzspieß (Schaschlikspieß) oder Steckdraht',
      en: 'wooden stick',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },

    {
      de: 'ggf. Steckdraht & Floristenkrepp',
      en: 'wire',
    },

    {
      de: 'Floristenkrepp',
      en: 'Florist crepe',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'diy-paper-gerbera.jpg',
          en: 'diy-paper-gerbera.jpg',
        },
        alt_text: {
          de: 'Gerbera basteln aus Papier mit Anleitung #bastelnmitpapier #papierblumen #papiergerbera #papershape',
          en: 'how to make diy paper gerbera daisy with template #paperflowers #papercrafting #papercraft #paperdaisy #papergerbera',
        },
      },
    ],
  },
};
