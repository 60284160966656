import materials from './materials';

export default {
  url: 'watteschaf-adventskalender-basteln',
  countermark: 'vg07.met.vgwort.de/na/97c8ea607df241d39b7389be73508e27',
  imageFolder: 'adventskalender-schaf',

  landingPage: ['diy-geschenke'],
  meta_title: {
    de:
      'Einfach und schnell: Adventskalender als Watteschaf (kostenlose Vorlage)',
    en: 'How to make a sheep Advent calendar (free template)',
  },
  meta_description_google: {
    de: `Ihr seid auf der Suche nach einem originellen Adventskalendern zum selber machen? Dann ist dieser DIY Adventskalender als Watteschaf eine Alternative zum 
    Schäfchen zählen. Mit der Anleitung und kostenlosen Vorlage gelingt euch dieses Last-Minute per Druckknopf.  `,
    en:
      'Searching for a special Advent calendar for children? Then this cotton wool sheep is a must DIY for you. Download the free template and get started.',
  },
  meta_description_social: {
    de:
      'Schnellen und einfachen Adventskalender basteln mit Papier - das Watteschaf #adventskalenderbasteln #bastelnmitpapier #papershape',
    en: '',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: 'einfach-schnell-adventskalender-diy-watteschaf.jpg',
      en: 'advent-calendar-sheep-last-minute-diy.jpg',
    },
    alt_text: {
      de:
        'DIY Adventskalender als Watteschaf selber machen für Kinder mit kostenloser Vorlage von #papershape #adventskalender #bastelnmitpapier',
      en:
        'diy cotton wool Advent calender for children with free template from #papershape #adventcalendar #papercraft ',
    },
  },
  h1: {
    de:
      'Einfacher und schneller Adventskalender als weiches Watteschaf (kostenlose Vorlage)',
    en:
      'How to make an Advent calendar as a cute cotton wool sheep (5 minutes diy)',
  },
  description: {
    de: `
    
     <p>Wer kennt das Schäfchen-Zählen nicht.</p>
      Gerade wenn man als Kind auf Weihnachten wartet, kann das Einschlafen schon mal schwer fallen. Umso schöner, wenn man sich auf den nächsten Tag freuen
      kann und ein flauschiges Watteknäul auf das noch nackte Schaf kleben darf.

      <p> 
      Eine Alternative zu den runden Watteknäueln ist Noppenfolie, so wie in diesem 
      <a href="/de/blog/noppenfolie-adventskalender-basteln/">Luftpolster Adventskalender</a></li>.
      </p>
     
      Und weitere Adventskalender Vorlagen für Erwachsene findet ihr hier:
      <p><li> <a href="/de/blog/billard-adventskalender-basteln/">Billiard Adventskalender für den Mann</a></li>
      <li><a href="/de/blog/bienenwaben-adventskalender-basteln/">Adventskalender für fleißige Bienchen</a></li> 
      </p>
      
      
      
    
    `,
    en: `
     <p>
    In this tutorial I'll show you how to make a cute cotton wool sheep Advent calendar for children. All you need are some cotton wool and paper to print on the 
    template. So fun and children have a fun time waiting for their presents.
     </p>
     <p>
     </p>
     
     <p>
     Here you'll find more Advent calendar templates for grown-ups and children with paper: 
     <p><li> <a href="/en/blog/billard-adventskalender-basteln/">Billard Advent calender for men</a></li>
     <li><a href="/en/blog/bienenwaben-adventskalender-basteln/">Advent calendar for busy bees</a></li> 
      
     </p>   
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'adventskalender-selber-basteln-watteschaf-schnell.jpg',
          en: 'advent-calender-bubble-wap-how-to.jpg',
        },
        alt_text: {
          de:
            'DIY Adventskalender als Watteschaf selber machen für Kinder mit kostenloser Vorlage von #papershape',
          en:
            'diy cotton wool Advent calender for children with free template from #papershape ',
        },
      },
    },
    {
      text: {},
    },
  ],

  stepsTitle: {
    de: 'Wie bastel ich einen Adventskalender mit Luftpolsterfolie - Anleitung',
    en: 'How to make a bubble wrap Advent calendar - instructions',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_wattebauschen-formen.jpg',
          en: '01_wattebauschen-formen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Einzelne  Luftpölsterchen rund ausschneiden. Vorsicht hier, dass die Luft nicht schon vorzeitig entweicht. Ihr benötigt für meine Vorlage 31 Luftpölsterchen. Wem die Zeit
        dafür fehlt, der klebt die komplette Luftpolsterfolie einfach auf das DINA4 Papier auf.
        
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '02_kleber.jpg',
          en: '02_kleber.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Die Vorlage herunterladen und ausdrucken. Alle Kreise mit Nummern werden mit doppelseitigem transparenten Klebeband beklebt. `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '03_wattepad-kleben.jpg',
          en: '03_wattepad-kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Die einzelnen Luftpölsterchen nun auf die Vorlage kleben und vor Freude platzen!
        `,
        en: ``,
      },
    },
  ],

  downloadName: 'schaf-adventskalender_papershape.pdf',
  materials: [
    {
      de: '1 DINA4 Papier (220-300g/m²)',
      en: '1 sheet of paper (8.5in x 11in) (weight: 65lb-110lb)',
    },
    materials.glue,

    {
      type: 'tool',
      name: {
        de: 'Watte',
        en: 'Cotton wool',
      },
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'watteschaf-adventskalender-basteln-kinder-last-minute.jpg',
          en: 'diy-advent-calendar-cotton-wool-sheep-last-minute.jpg',
        },
        alt_text: {
          de:
            'DIY Adventskalender als Watteschaf selber machen für Kinder mit kostenloser Vorlage von #papershape',
          en:
            'diy cotton wool Advent calender for children with free template from #papershape ',
        },
      },
    ],
  },
};
