import React from 'react';
import countries from '../data/countries';
import { selectedLang } from '../data/lang';

export default ({ input: { onChange, name, value }, className }) => {
  return (
    <select name={name} onChange={onChange} value={value} className={className}>
      {Object.keys(countries).map((countryKey) => (
        <option value={countryKey} key={countryKey}>
          {countries[countryKey]['bezeichnung_' + selectedLang]}
        </option>
      ))}
    </select>
  );
};
