export default {
  id: '2-14-sfk',
  category: ['christmas'],
  name: {
    de: 'Weihnachtskrippe',
    en: 'Nativity Scene',
  },
  subtitle: {
    de: 'B40 x H30 x T25 cm',
    en: 'W40 x H30 x D25 cm',
  },
  meta_title: {
    de: '3D Weihnachtskrippe basteln aus Papier',
    en: '3d Paper Nativity Scene',
  },
  meta_description_google: {
    de: 'Der etwas andere Adventskalender. Diese Weihnachtskrippe aus Papier enthält für 24 Tage Bastelspaß und belohnt den DIY-Begeisterten an Heiligabend mit einer kompletten Krippe. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'This nativity scene from paper is a lovely present as a christmas calendar. After 24 days you have built an amazing nativity scene everyone will love. 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  description: {
    de: `So weihnachtlich: Mit unserer Vorlage bastelst du dir eine moderne 3D Weihnachtskrippe aus Papier. Sie ist eine schöne DIY-Beschäftigung im Advent und kann sogar als ausgefallener Adventskalender genutzt werden.
    Die Weihnachtskrippe besteht aus 24 Papierseiten, sodass du jeden Tag eine Seite basteln kannst.

    Die Figuren Maria, Josef und der Engel sind 20cm hoch.
    
    Was ist drin im DIY Kit?    
    - 24 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -24 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 24 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 24 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 24 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 24 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkweihnachtskrippe/krippe-basteln-papier.jpg',
        en: 'products/sfkweihnachtskrippe/krippe-basteln-papier.jpg',
      },
      alt_text: {
        de: 'DIY 3d Seepferdchen aus Papier als Wanddeko #papercraft #3dpapermodel #origami',
        en: 'Papercraft sea horse for wall decor #diy #papercraft #origami #3dpapermodel #origami',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },

  choose: {
    image: {
      filename: {
        de: 'products/sfkweihnachtskrippe/krippe-basteln-papier.jpg',
        en: 'products/sfkweihnachtskrippe/krippe-basteln-papier.jpg',
      },
      alt_text: {
        de: '3d Weihnachtskrippe aus Papier als Adventskalender #papershape',
        en: '3d nativity scene from paper is a diy christmas calendar #papershape ',
      },
    },
    colors: {
      color1: {
        de: 'Maria, Josef, Engel, Jesuskind',
        en: 'Maria, Josef, Angel, Jesus baby',
      },

      color2: {
        de: 'Schafe, Heilige Drei Könige, Stern',
        en: 'Sheep, the three Magi, star',
      },
    },
    disableColors: {
      color1: ['schwarz', 'tuerkis', 'anthrazit'],
      color2: ['schwarz', 'tuerkis', 'anthrazit'],
    },
    price: 29.9,
    priceThirdCountry: 25.5,
  },
};
