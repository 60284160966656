export default {
  id: '3-23-blog',
  category: ['gift_wrapping'],

  name: {
    de: 'Stabile Geschenkbox',
    en: 'Sturdy gift box',
  },
  subtitle: {
    de: 'B9 x H4 x T9 cm',
    en: 'W9 x H4 x D9 cm',
  },
  meta_title: {
    de: 'Stabile Geschenkbox basteln',
    en: 'How to make a sturdy gift box',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für eine stabile Geschenkbox kannst du etliche kreative Ideen umsetzen und verschenken. ',
    en: 'With this template for a sturdy gift box you can implement quite a few creative ideas and give away.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  pdf: {
    description: {
      de: `Mit dieser Vorlage für eine stabile Geschenkbox kannst du etliche kreative Ideen umsetzen und verschenken. <a href="/de/blog/geschenkbox-basteln/">In diesem Blogpost</a> zeige ich dir, wie einfach das geht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir für die Stabilität mindestens 270g/m² Fotokarton zu verwenden.
      Damit die Geschenkbox gelingt, benötigst du einen Drucker, 2 Bögen Fotokarton, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this digital template for our sturdy gift box you can realize a lot of creative ideas. <a href="/en/blog/geschenkbox-basteln/">In this blogpost</a> I show you how easy it is.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use at least 270g/m² paper.
      To make the gift box succeed, you'll need a printer, 2 sheets of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 3.9,
    priceThirdCountry: 3.9,
    image: {
      filename: {
        de: 'products/sfkgeschenkboxshop/stabile-geschenkbox-basteln.jpg',
        en: 'products/sfkgeschenkboxshop/stabile-geschenkbox-basteln.jpg',
      },
      alt_text: {
        de: 'Drei selbstgebastelte quadratische Geschenkboxen aus Fotokarton in grau mit pinkem Deckel',
        en: 'Three papercrafted square gift boxes in gray with pink lid',
      },
    },
  },
};
