import React from 'react';
import { connect } from 'react-redux';
import { getIsLoading } from '../state/fetch/fetchSelectors';

function PsLoadingSpinner({ isLoading }) {
  return (
    isLoading && (
      <div
        data-reactroot
        role="dialog"
        style={{
          position: 'fixed',
          zIndex: 1040,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}>
        <div
          style={{
            position: 'fixed',
            zIndex: 'auto',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgb(0, 0, 0)',
            opacity: '0.5',
          }}
        />
        <div
          role="document"
          tabIndex={-1}
          style={{
            position: 'absolute',
            width: 400,
            top: '48%',
            left: '50%',
            transform: 'translate(-48%, -50%)',
            padding: 20,
          }}>
          <div className="sk-folding-cube">
            <div className="sk-cube1 sk-cube" />
            <div className="sk-cube2 sk-cube" />
            <div className="sk-cube4 sk-cube" />
            <div className="sk-cube3 sk-cube" />
          </div>
        </div>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: getIsLoading(state),
  };
};

export default connect(mapStateToProps)(PsLoadingSpinner);
