export default {
  url: 'hexentreppe-herbstkranz-basteln',
  countermark: 'vg07.met.vgwort.de/na/c47eafb883394e968cb3fd146e8e8da6',
  imageFolder: 'herbstkranz',
  meta_title: {
    de: 'Einfachen Papier Herbstkranz als Deko basteln (mit Kindern) ',
    en: 'Easy diy fall wreath from paper (simple instructions)',
  },
  meta_description_google: {
    de:
      'Einen Herbstkranz aus Papier basteln und an der Tür oder auf dem Tisch dekorieren. Dieser Kranz lässt sich auch mit Kindern basteln.',
    en:
      'Try this colorful and simple fall wreath for your automn decor. The wreath is so easy to make. All you need are some paper strings. ',
  },
  meta_description_social: {
    de:
      'DIY Papier Herbstkranz basteln aus zickzack-Streifen als Türkranz oder als Tischdeko mit Anleitung (auch für Kinder) #papershape ',
    en:
      'fall craft: diy fall wreath with paper strings. easy to make even with children #papershape',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: 'herbstkranz-basteln.jpg',
      en: 'fall-paper-wreath-diy.jpg',
    },
    alt_text: {
      de:
        'DIY Papier Herbstkranz basteln aus zickzack-Streifen als Türkranz oder als Tischdeko mit Anleitung (auch für Kinder) #papershape',
      en:
        'fall craft: diy fall wreath with paper strings. easy to make even with children #papershape',
    },
  },
  h1: {
    de:
      'Aber zackig: einfachen Papier Herbstkranz als Deko basteln (mit Kindern)',
    en: 'Easy diy fall wreath from paper (simple instructions)',
  },
  description: {
    de: `
    
    <p> Irgendwie erinnert mich Hexentreppe eher an Halloween als Herbst, aber mit genau diesen Papiertreppen entsteht dieser cooler Ombre-Herbstkranz.</p>
    <p>
      In der Regel findet ihr hier DIYs, die eher für Erwachsene gedacht sind. Heute mache ich eine Ausnahme -eher zufällig. Diesen Herbstkranz könnt ihr an einem gemütlichen Abend ganz 
      einfach mit euren Kindern (allein geht natürlich auch) basteln. Und zusätzlich könnt ihr den Kranz mit diesen <a href="/de/blog/eicheln-papier-herbstdeko/">süßen Papier Eicheln</a> verzieren, indem ihr sie z.B. an einem 
      schlichten Band herunterhängen lasst.
    </p>
      Falls Papierstreifen nicht so euer Ding sind, gibt es hier auch einen <a href="/de/blog/ombre-weihnachtskranz-basteln/">Weihnachtskranz aus Papierschleifen</a>. 
      Je nachdem, welche Farben ihr verwendet, könnt ihr diesen winterlichen Kranz ganz einfach in einen Herbstkranz verwandeln.
       
      
    
    `,
    en: `
      <p>
      When automn comes, it's time to change our home decor from bright summer colors to coizy and warm ones. </p>
      <p>If you agree, you should definitely give a try to this paper wreath in orange shades. It is super easy to make 
      and the outcome is stunning. You can hang your paper wreath at a door (though I would recommend to do it inside) or even put it on a table and arrange it together with some candles. 
      </p>
      <p>And maybe you wanna pimp your wreath with these <a href="/en/blog/eicheln-papier-herbstdeko/">cute paper acorns.</a> </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'herbstkranz-basteln-papier.jpg',
          en: 'paper-fall-wreath-diy.jpg',
        },
        alt_text: {
          de:
            'DIY Papier Herbstkranz basteln aus zickzack-Streifen als Türkranz oder als Tischdeko mit Anleitung (auch für Kinder) #papershape',
          en:
            'fall craft: diy fall wreath with paper strings. easy to make even with children #papershape',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'herbstkranz-hexentreppe-basteln.jpg',
          en: 'paper-fall-wreath-paper-strings-diy.jpg',
        },
        alt_text: {
          de:
            "DIY Papier Herbstkranz basteln aus zickzack-Streifen als Türkranz oder als Tischdeko mit Anleitung (auch für Kinder) #papershape'",
          en:
            'fall craft: diy fall wreath with paper strings. easy to make even with children #papershape',
        },
      },
    },
  ],
  stepsBackgroundColor: 'rgb(243, 243, 243)',
  stepsTitle: {
    de:
      'Wie bastel ich einen zickzack Herbstkranz aus Papierstreifen - Anleitung',
    en: 'How do I make a paper fall wreath from paper strings',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '01_schneiden.mp4',
      text: {
        de: `Ihr braucht 4 unterschiedliche DIN A4 Papierfarben in warmen Herbsttönen (orange, gelb, ocker...). Wenn ihr auch einen Ombre-Look gestaltet möchtet, dann entscheidet euch am besten
        für eine Farbe mit unterschiedlichen Nuancen.
        Verwendet Tonpapier mit einer Grammatur von 120g/m², weil sich dieses Papier ohne vorfalzen ganz einfach knicken lässt.
        Schneidet das DINA4 Papier mit einem Cutter oder Schere nun in Streifen in der Größe 1cm x 29,7cm (wer mag, druckt sich die Linien mit meiner Vorlage aus). 
        Ihr solltet am Ende 21 Papierstreifen erhalten.  
        
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '02_rechterwinkel.jpg',
          en: '02_rechterwinkel.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_rechterwinkel.mp4',
      text: {
        de: `Ihr startet mit zwei Papierstreifen der gleichen Farbe. Legt einen Papierstreifen im rechten Winkel auf den anderen Papierstreifen und klebt die überlappenden/ übereinanderliegenden
         Papierenden zusammen.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '03_zickzack.jpg',
          en: '03_zickzack.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '03_zickzack.mp4',
      text: {
        de: `Nun beginnen wir die sogenannte Hexentreppe zu falten: Dazu den unteren Papierstreifen über den anderen Streifen falten und immer wieder wiederholen bis ihr am Ende angekommen 
        seid. Die Anzahl der Streifen, die ihr benötigt, hängt von dem Durchmesses eures Holzrahmens ab. Deswegen testet am besten laufend, ob eure Hexentreppe schon lang genug ist, indem ihr sie mehrmals um den Holzrahmen
        wickelt (s. Schritt 5).`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '04_verlaengern.jpg',
          en: '04_verlaengern.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '04_verlaengern.mp4',
      text: {
        de: `Da wir für den Herbstkranz eine lange Hexentreppe am Stück benötigen, müssen wir die Hexentreppe mit dem dritten Papierstreifen wieder am Anfang verkleben. Wiederholt diesen Schritt 
        mit den weiteren Papierstreifen einer Farbe bis ihr eine sehr lange Papierschlange fertig gefaltet habt.
  `,
        en: `S`,
      },
    },

    {
      image: {
        filename: {
          de: '05_wickeln.jpg',
          en: '05_wickeln.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      video: '05_wickeln.mp4',
      text: {
        de: `Ihr solltet nun 4 sehr lange Papierschlangen haben. Als Kranzbasis habe ich einen Stickrahmen zweckentfremdet (Durchmesser ca. 20cm). Um den Stickrahmen wickelt ihr die erste Papierschlange.
        Anschließend nehmt ihr euch die zweite, dritte und vierte Papierschlange und wickelt sie im gleichem Rhythmus um euren Rahmen. Schaut, dass ihr am Ende die Papierstreifen relativ eng 
        aneinander gewickelt habt, weil sonst der Rahmen durchscheint. `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '06_loopschliessen.jpg',
          en: '06_loopschliessen.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      video: '06_loopschliessen.mp4',
      text: {
        de: `Wenn ihr den Herbstkranz aufhängen möchtet, dann solltet ihr nun einen Faden um dem Rahmen binden und zubinden. Nachher kommt ihr nicht so gut ran.
        Die Hexentreppen verschließt ihr nun, indem ihr zuerst die überstehenden Papierstreifen abschneidet und die jeweils letzten Quadrate des Papierstreifens verklebt (ähnlich wie ganz
        am Anfang).    `,
        en: ``,
      },
    },
  ],

  downloadName: 'hexentreppe-paperstrings-papershape.pdf',
  materials: [
    {
      de: '4 DINA4 Papier in 4 unterschiedlichen Farben (120g/m²)',
      en: '4 differently colored papers (8.5in x 11in) (weight: 65lb-110lb)',
    },
    {
      de: 'Stickrahmen, runden Holzrahmen oder ähnliches',
      en: 'Stick frame, wooden frame or something similar',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'herbstkranz-diy-deko.jpg',
          en: 'paper-wreath-fall-diy-craft.jpg',
        },
        alt_text: {
          de:
            'DIY Papier Herbstkranz basteln aus zickzack-Streifen als Türkranz oder als Tischdeko mit Anleitung (auch für Kinder) #papershape',
          en:
            'fall craft: diy fall wreath with paper strings. easy to make even with children #papershape',
        },
      },
    ],
  },
};
