import React from 'react';
import { trackDownload } from './customga';
import Lang from './Lang';
import { PsLink } from './PsLink';
import downloadIcon from './downloadIcon.svg';

export default function PsDownloadButton({ name }) {
  return (
    <div>
      <a
        onClick={trackDownload(name)}
        className="btn btn-lg btn-primary btn-block btn-download"
        href={'/downloads/' + name}
        target="_blank"
        style={{
          margin: '40px 0 10px',
          padding: '30px',
          fontFamily: 'Montserrat',
          fontSize: '28px',
          color: 'white',
          backgroundColor: '#7fbfbf',
          border: 0,
        }}>
        <img
          style={{
            width: '50px',
            margin: '0',
            position: 'relative',
            top: '-20px',
            left: '-20px',
          }}
          src={downloadIcon}
          alt="Download Icon"
        />
        <span style={{ display: 'inline-block' }}>
          <Lang de="Vorlage downloaden" en="Download Template" />
          <br />
          <small style={{ fontSize: '20px' }}>(DIN A4 + US Letter)</small>
        </span>
      </a>
      <div
        style={{ fontSize: '12px', margin: '5px 0 30px', textAlign: 'center' }}>
        <PsLink to="/faq" hash="faq-terms-of-use">
          <Lang
            de="Durch das Herunterladen der Vorlage akzeptierst du die Nutzungsbedingungen."
            en="By downloading the template you agree to the terms of use."
          />
        </PsLink>
      </div>
    </div>
  );
}
