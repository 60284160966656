import React from 'react';
import Lang from './Lang';

export default function PaymentError() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1 className="page-header">
            <Lang
              de={`Leider ist ein Fehler aufgetreten`}
              en={`Sorry, an error has occurred`}
            />
          </h1>
        </div>
      </div>

      <p style={{ marginTop: 20 }}>
        <a href="#" onClick={() => window.location.reload()}>
          <Lang
            de={`Bitte klicke hier und versuche es erneut.`}
            en={`Please clicke here to try it again.`}
          />
        </a>
      </p>
    </div>
  );
}
