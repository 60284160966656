import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import isEqual from 'lodash/isEqual';
import { getIsRehydrated } from '../state/fetch/fetchSelectors';
import PsCheckbox from './inputs/PsCheckbox';
import { DataprivacyLink } from './Links';
import Lang from './Lang';
import ShowMore from './ShowMore';

const initialValues = {
  essential: true,
};

class CookiesInfoForm extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      setupScriptsDone: false,
    };
  }

  componentDidMount() {
    if (window.navigator.userAgent === 'ReactSnap'){
      return;
    }
    if (
      this.props.rehydrated &&
      isEqual(initialValues, this.props.values)
    ) {
      this.setState({ visible: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (window.navigator.userAgent === 'ReactSnap'){
      return;
    }

    if (
      window.navigator.userAgent !== 'ReactSnap' &&
      prevProps.rehydrated !== this.props.rehydrated &&
      isEqual(initialValues, this.props.values)
    ) {
      this.setState({ visible: true });
    }

    this.setupGa();
    this.setupPinterest();
  }

  appendScript = (id, src, onload) => {
    if (typeof document.getElementById !== "function"){
      return;
    }
    if (document.getElementById(id) != null){
      return;
    }

    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.async = true;
    script.onload = onload;
    document.body.appendChild(script);
  }

  removeScript = (id) => {
    if (typeof document.getElementById !== "function"){
      return;
    }
    const script = document.getElementById(id);
    if (script == null){
      return;
    }
    script.remove();
  }

  setupGa = () => {
    if (!this.props.values.websitetracking){
      return;
    }
    this.appendScript('analytics', 'https://www.googletagmanager.com/gtag/js?id=G-3C9743E197', () => {
      console.debug('googletagmanager loaded');

      let gaProperty = 'G-3C9743E197';
      window.disableStr = 'ga-disable-' + gaProperty;
      if (window.document.cookie.indexOf(window.disableStr + '=true') > -1) {
        window[window.disableStr] = true;
      }

      window.dataLayer = window.dataLayer || [];

      function gtag(){window.dataLayer.push(arguments);}
      window.gtag = gtag;

      gtag('js', new Date());
      gtag('config', gaProperty);

      // window.ga('set', 'anonymizeIp', true);



    });
  }

  setupPinterest = () => {
    if (!this.props.values.socialmedia){
      return;
    }

    this.appendScript('pinterest', 'https://assets.pinterest.com/js/pinit.js', () => {
      console.debug('pinit.js loaded');
    });
  }

  render() {
    return (
      <div
        className="footer navbar-fixed-bottom"
        style={{
          // backgroundColor: '#bababa',
          display: this.state.visible ? 'block' : 'none',
        }}
        id="ckmeldung">
        <div className="container">
          <form
            onSubmit={this.props.handleSubmit(formValues => {
              this.props.dispatch(change('cookies', 'websitetracking', true));
              this.props.dispatch(change('cookies', 'socialmedia', true));
              this.setState({ visible: false });
            })}>
            <div className="row">
              <div className="col-md-offset-3 col-md-6">
                <div
                  className="row"
                  style={{ padding: '12px 0px', backgroundColor: '#eeeeee' }}>
                  <div className="col-sm-12">
                    <Lang
                      de="PaperShape nutzt Cookies für Website Tracking und bettet Social Media Plugins ein, damit wir die Performance im Blick behalten und du ein gutes Benutzererlebnis hast."
                      en="PaperShape uses cookies for website tracking and embeds social media plugins to help us keep track of performance and give you a good user experience."
                    />
                    <Field
                      name="essential"
                      disabled
                      label={
                        <Lang
                          de={
                            <div>
                              <div>Essenziell</div>
                              <div />
                            </div>
                          }
                          en="Essential"
                        />
                      }
                      component={PsCheckbox}
                    />
                    <Field
                      name="websitetracking"
                      label={
                        <Lang
                          de={
                            <div>
                              <ShowMore
                                text="Website Tracking"
                                open={false}
                                more={
                                  <small>
                                    Website Tracking Cookies erfassen
                                    Informationen anonym. Diese Informationen
                                    helfen uns zu verstehen, wie unsere Besucher
                                    unsere Website nutzen. Wir nutzen hierfür
                                    Google Analytics von Google LLC.
                                  </small>
                                }
                              />
                            </div>
                          }
                          en="Website Tracking"
                        />
                      }
                      component={PsCheckbox}
                    />
                    <Field
                      name="socialmedia"
                      label={
                        <Lang
                          de={
                            <div>
                              <ShowMore
                                open={false}
                                text="Social Media"
                                more={
                                  <small>
                                    Wir zeigen Inhalte von Youtube und Social
                                    Media Plattformen (Pinterest) an oder
                                    verlinken auf diese.
                                  </small>
                                }
                              />
                            </div>
                          }
                          en="Social Media"
                        />
                      }
                      component={PsCheckbox}
                    />
                  </div>
                  <div className="col-sm-12">
                    <button type="submit" className="btn btn-block btn-primary">
                      <Lang de="Alle akzeptieren" en="Accept all" />
                    </button>
                    <a
                      className="btn btn-block"
                      style={{
                        color: '#9F9F9F',
                        backgroundColor: '#F5F5F5',
                      }}
                      onClick={() => {
                        this.setState({ visible: false });
                      }}>
                      <Lang de="Speichern" en="Save" />
                    </a>
                  </div>
                  <div
                    className="col-sm-12 text-center"
                    style={{ marginTop: '15px' }}>
                    <DataprivacyLink>
                      <Lang de=" zur Datenschutzerklärung" en=" more" />
                    </DataprivacyLink>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    values: state.form.cookies.values,
    rehydrated: getIsRehydrated(state),
  };
};

const Connected = connect(mapStateToProps)(CookiesInfoForm);

export default reduxForm({
  form: 'cookies',
  destroyOnUnmount: false,
  initialValues,
})(Connected);
