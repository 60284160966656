import React, { Component } from 'react';
import Raven from 'raven-js';
import { connect } from 'react-redux';
import PaymentAlreadyDone from '../../common/PaymentAlreadyDone';
import { executePayment } from '../../state/payment/paymentActions';
import { getTextBySelectedLang } from '../../data/lang';
import Lang from '../../common/Lang';
import PsMeta from '../../common/PsMeta';
import { getOrderNumber } from '../../state/order/orderSelectors';
import { getIsRehydrated } from '../../state/fetch/fetchSelectors';
import { getIsPaymentDone } from '../../state/payment/paymentSelectors';

class PaymentSuccessful extends Component {
  componentDidMount() {
    // payerid, paymentid, token
    const params = getParams(this.props.location.search);
    console.log(
      'componentDidMount location.search',
      JSON.stringify(params, null, 4)
    );
    console.log('props.rehydrated', this.props.rehydrated);
    if (params.paymentid && params.payerid && this.props.rehydrated) {
      this.props.dispatch(
        executePayment(params.paymentid, params.payerid, this.props.orderNumber)
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.rehydrated && nextProps.rehydrated) {
      const params = getParams(this.props.location.search);
      console.log(
        'UNSAFE_componentWillReceiveProps location.search',
        JSON.stringify(params, null, 4)
      );
      console.log('nextProps.rehydrated', nextProps.rehydrated);
      if (!params.paymentid || !params.payerid) {
        Raven.captureMessage(
          'could not dispatch execute payment. paymentid or payerid is null.',
          { extra: { orderNumber: nextProps.orderNumber } }
        );
      } else {
        this.props.dispatch(
          executePayment(
            params.paymentid,
            params.payerid,
            nextProps.orderNumber
          )
        );
      }
    }
  }

  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang(
            'Bezahlung erfolgreich',
            'Your payment was successful'
          )}
          noindex
        />

        {this.props.isPaymentDone ? (
          <PaymentAlreadyDone />
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="page-header">
                  <Lang
                    de={`Bezahlung erfolgreich`}
                    en={`Your payment was successful`}
                  />
                </h1>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p>
                  <Lang
                    de={`Danke für deine Zahlung. Deine Transaktion wurde abgeschlossen und du erhältst per E-Mail eine Bestätigung für deinen Kauf.`}
                    en={`Thank you for your payment. Your transfer is completed and you will receive a confirmation of your purchase via e-mail.`}
                  />
                </p>

                <p>
                  <Lang
                    de={`Sobald deine Bestellung verschickt wurde, erhältst du eine Versandbestätigung per E-Mail.`}
                    en={`Once your order is shipped, you will receive a confirmation e-mail.`}
                  />
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderNumber: getOrderNumber(state),
    rehydrated: getIsRehydrated(state),
    isPaymentDone: getIsPaymentDone(state),
  };
};

export default connect(mapStateToProps)(PaymentSuccessful);

function getParams(searchString) {
  return searchString
    .substr(1)
    .split('&')
    .reduce(
      (params, next) => ({
        ...params,
        ...{
          [next.split('=')[0].toLowerCase()]: next.split('=')[1],
        },
      }),
      {}
    );
}
