export default {
  id: '3-21-blog',
  category: ['home_decor'],
  name: {
    de: 'Lampion Vase',
    en: 'Lampion Vase',
  },
  subtitle: {
    de: 'B17 x H15 x T17 cm',
    en: 'W17 x H15 x D17 cm',
  },
  meta_title: {
    de: 'Vase Lampion aus Papier Vorlage ',
    en: 'Vase Lampion from Paper Template',
  },
  meta_description_google: {
    de: 'Mit dieser digitalen Vorlage für eine Vase in Lampionform kannst du deinen wunderschönen Blumen endlich den verdienten Platz geben.',
    en: 'With this digital template for a lampion shaped vase, you can finally give your beautiful flowers the space they deserve.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  pdf: {
    description: {
      de: `Mit dieser digitalen Vorlage für eine Vase in Lampionform kannst du deinen wunderschönen Blumen endlich den verdienten Platz geben. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² Tonpapier zu verwenden.
      
      Damit die Vase gelingt, benötigst du einen Drucker, 3 Bögen Tonpapier, eine Schere und einen Kleber.
  
      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.

`,

      en: `With this digital template for a lampion shaped vase, you can finally give your beautiful flowers the space they deserve.
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use 130g/m² paper.
      To make the vase succeed, you'll need a printer, 3 sheets of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.
    `,
    },
    price: 3.9,
    priceThirdCountry: 3.9,
    image: {
      filename: {
        de: 'products/sfkvaselampion/lampion-vase-papier-basteln.jpg',
        en: 'products/sfkherz/3d-origami-herz-basteln.jpg',
      },
      alt_text: {
        de: 'Mohnblumen in einer selbstgemachten Papiervase in Lampionform.',
        en: 'Poppies in a handcrafted lampion shaped vase ',
      },
    },
  },
};
