import React from 'react';
import PsLabel from './PsLabel';

export default function PsInputGroup({
  input,
  label,
  meta,
  placeholder,
  buttonOnClick,
  buttonText,
  ...rest
}) {
  return (
    <PsLabel label={label} meta={meta}>
      <div className="input-group">
        <input
          {...input}
          type="text"
          className="form-control"
          placeholder={placeholder || label}
          {...rest}
        />
        <span className="input-group-btn">
          <button
            className="btn btn-default"
            name="button"
            type="button"
            onClick={buttonOnClick}>
            {buttonText}
          </button>
        </span>
      </div>
    </PsLabel>
  );
}
