export default {
  id: '3-04-blog',
  category: ['gift_wrapping'],

  name: {
    de: 'Geldgeschenk zur Hochzeit: Bulli mit Geldschein-Dosen',
    en: 'Bus for travel enthusiasts (cash gift)',
  },
  subtitle: {
    de: 'B23 x H9 x T11 cm',
    en: 'W23 x H9 x D11 cm',
  },
  meta_title: {
    de: 'Bulli mit Geldschein-Dosen für reiselustige Hochzeitspaare (Geldgeschenk Vorlage)',
    en: 'Wedding bus for travel enthusiasts (template cash gift)',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für einen Retro-Bus als Geschenkverpackung setzt du dein Geldgeschenk für reisebegeisterte Brautpaare gekonnt in Szene.',
    en: 'With this retro bus gift wrap template, you will expertly set off your cash gift for travel-loving brides and grooms.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  pdf: {
    description: {
      de: `Mit dieser Vorlage für einen stylischen Retro-Bulli als Geschenkverpackung setzt du dein Geldgeschenk für reisebegeisterte Brautpaare gekonnt in Szene. Sicherlich freuen sich auch reiselustige Geburtstagskinder über dieses Geschenk - vielleicht dann ohne Herzchen und 'Just married-Logo'. 
      
      <a href="/de/blog/retro-bus-geldgeschenk/">In diesem Blogpost</a> zeige ich dir, wie einfach du den Bulli basteln kannst. Schaue dir am besten das Video in der Anleitung an. 
      
      Mit der 6-seitigen PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² Tonpapier zu verwenden.
      Damit der HBulli gelingt, benötigst du einen Drucker, 6 Bögen Tonpapier, eine Schere und einen Kleber. 

      In der Vorlage enthalten ist der Bulli mit Rädern und Fenstern, Herzen, Just married Logo, ein Logo zum Selbstbeschriften, Buskennzeichen zum Selbstbeschriften und die Koffer.
      Die Koffer selber lassen sich auch wie im Video gezeigt mit Geld füllen.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this template for an old retro bus as a gift wrapping you set your gift of money for travel-loving bride and groom skillfully in scene. Surely also travel-loving birthday children are happy about this gift - perhaps then without hearts and 'Just married' logo. 
      
      <a href="/en/blog/retro-bus-geldgeschenk/">In this blogpost</a> I show you how easy it is. It's best to watch the video in the tutorial.
      
      With the 6-page PDF template in A4 format to print yourself you can get started right away. I recommend you to use 130g/m² paper.

      Included in the template is the bus with wheels and windows, hearts, Just married logo, a logo for self-labeling, bus license plates for self-labeling and the suitcases. The suitcases themselves can also be filled with money as shown in the video.
      
      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 9.9,
    priceThirdCountry: 9.9,
    image: {
      filename: {
        de: 'products/sfkretrobushochzeit/geldgeschenk-hochzeit-bulli-basteln.jpg',
        en: 'products/sfkretrobushochzeit/geldgeschenk-hochzeit-bulli-basteln.jpg',
      },
      alt_text: {
        de: 'Gebastelter Hochzeits-Bulli aus Papier mit verzierten Herzen und aufgerollten Geldscheinen als Dosen',
        en: 'Crafted paper wedding bus with decorated hearts and rolled up banknotes as cans',
      },
    },
  },
};
