export default {
  url: 'osterkoerbchen-basteln',
  countermark: 'vg07.met.vgwort.de/na/403540f442634758980ba8991769bb5c',
  imageFolder: 'osterkorb',
  meta_title: {
    de: 'Osterkörbchen basteln aus Papier (wirklich schnell und einfach)',
    en: 'How to make Easter basket from paper (easy and fast)',
  },
  meta_description_google: {
    de: 'Egal, ob du es als Osterkörbchen oder Osternest basteln möchtest - mit der PaperShape Vorlage, einem Stück Papier und der Anleitung gelingt es ganz schnell und einfach.',
    en: 'Learn how to make very easy a cute Easter basket from paper ',
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: 'osterkoerbchen-basteln.jpg',
      en: 'osterkoerbchen-basteln.jpg',
    },
    alt_text: {
      de: 'Osterkörbchen basteln aus Papier mit PDF Vorlage und Anleitung #bastelnmitpapier #papershape #osterkörbchen',
      en: '',
    },
  },
  h1: {
    de: 'Süße Osterkörbchen basteln aus Papier (mit und ohne Henkel)',
    en: 'How to make an Easter basket from paper',
  },
  description: {
    de: `
      <p>In diesem Post zeige ich euch, wie ihr ganz einfach ein süßes Osterkörbchen basteln könnt. Alles, was ihr dafür benötigt, 
      ist ein Blatt Tonkarton oder Fotokarton. Auf jeden Fall dürfen diese süßen Osternester in farbintensiven Tönen daherkommen.
         </p>
      <p>Da gilt es, diese selbstgemachten Körbchen ganz besonders gut zu verstecken.  </p>
      <p>Für weitere DIY Osterideen aus Papier schaut mal hier: </p>
     
      <p><li><a href="/de/blog/3d-papier-osterhasen-basteln/">3D Osterhase im Origami-Style</a></li></p>
      
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: 'Körbchen basteln mit Kindern - mit dieser Vorlage kein Problem',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'osternest-basteln.jpg',
          en: 'osterkoerbchen-basteln-papier.jpg',
        },
        alt_text: {
          de: 'Osternest selber machen aus Papier mit Anleitung und Vorlage #bastelnmitpapier #papershape #osterkörbchen',
          en: '',
        },
      },
    },
    {
      text: {
        de: ` `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'osterkoerbchen-basteln-vorlage-pdf.jpg',
          en: 'osterkoerbchen-basteln-vorlage-pdf.jpg',
        },
        alt_text: {
          de: 'Osterkörbchen basteln aus Papier zum Befüllen mit Ostereiern (ganz schnell und einfach) #bastelnmitpapier #papershape #osterkörbchen',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'osterkoerbchen-basteln-schnell-einfach.jpg',
          en: 'osterkoerbchen-basteln-schnell-einfach.jpg',
        },
        alt_text: {
          de: 'DIY Osternest selber machen mit Tonkarton und PDF Vorlage zum Download #bastelnmitpapier #osterkörbchen #papershape #osterdeko ',
          en: '',
        },
      },
    },

    {
      text: {
        de: ` `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'osterkoerbchen-basteln-papier.jpg',
          en: 'osterkoerbchen-basteln-papier.jpg',
        },
        alt_text: {
          de: 'Bunte, moderne DIY Osternester aus Papier basteln mit Anleitung und Vorlage #bastelnmitpapier #osterdeko #papershape #osternest ',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'Osterkörbchen basteln aus Tonkarton: so gehts',
    en: '',
  },
  steps: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Ihr benötigt pro Osterkörbchen 1 DINA4 Blatt Tonkarton (130-210g/m²) oder Fotokarton (ab 270g/m²). Eine geringere Grammatur
        empfehle ich nicht, weil sie ansonsten nicht stabil genug sind.
        Druckt euch die "Osterkörbchen basteln Vorlage" als PDF aus und schneidet nun alle durchgezogenen Linien mit einer
        Schere aus. Wenn ihr das Osterkörbchen mit Henkel basteln möchtet, dann schneidet das abgedruckte Rechteck ebenfalls aus. Falls ihr nur ein 
        Osternest möchtet, dann lasst es weg.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Falzt mit der Rückseite eines Cuttermessers und einem Lineal alle kurz gestrichelten Linien vor. 
        Dazu das Lineal an die Linie anlegen und mit der Rückseite des Cuttermessers mit Druck
        nachfahren.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: ` Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin.
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Klebt auf die Laschen der einezelnen Papierteile Klebepads. Ihr könnt auch trockenen Klebstoff verwenden. Das würde ich hier sogar empfehlen.
        Ich hatte nur keinen zur Hand. Wichtig ist, dass ihr das Pad auf die schöne Seite und damit die unbedruckte Seite der Lasche klebt. Verklebt die 
        anliegenden Laschen zu einer Schale oder eben Osterkörbchen.
       
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Klebt zum Schluss den Henkel an. Ready! Nun geht es ans Befüllen. Anstelle des grünen Ostergrases habe ich holographisches Lametta genommen. Wo ihr
        das bestellen könnt, findet ihr in der Materialliste.
       
  `,
        en: ``,
      },
    },
  ],

  video: {
    title: {
      de: 'Bunte DIY Osterkörbchen oder -nester selber machen ',
      en: '',
    },
    description: {
      de: `Im Youtube Video zeige ich euch, wie schnell das Osterkörbchen zu basteln ist und worauf ihr achten solltet. `,
      en: ``,
    },
    caption: {
      de: `DIY Osterkörbchen basteln aus Papier`,
      en: `How make Easter basket from paper`,
    },
    videoid: 'ZwSV2bCY1xI',
  },
  downloadName: 'osterkoerbchen-basteln_papershape.pdf',
  materials: [
    {
      de: '1 DINA4 Tonkarton oder Fotokarton (ab 130g/m²)',
      en: '1 colored papers (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
    {
      de: 'holographisches Lametta',
      en: 'Easter gras',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'osterkoerbchen-basteln-anleitung-papier.jpg',
          en: 'osterkoerbchen-basteln-anleitung-papier.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    ],
  },
};
