export default {
  id: '2-38-sfk',
  category: ['christmas'],

  name: {
    de: ' Nussknacker',
    en: ' Nutcracker',
  },
  subtitle: {
    de: 'B11 x H38 x T8 cm',
    en: 'W11 x H38 x D8 cm',
  },
  meta_title: {
    de: 'Wie weihnachtlich: Papier Nussknacker basteln',
    en: 'How to make a 3d papercraft nutcracker',
  },
  meta_description_google: {
    de: 'Bastel dir mit unserer Vorlage für einen 3D Papercraft Nussknacker eine weihnachtliche Deko für dein Zuhause oder Büro.',
    en: 'Make a Christmas decoration for your home or office with our template for a papercraft nutcracker',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  description: {
    de: `Wie weihnachtlich: Bastel diesen Papercraft Nussknacker als wunderschöne Weihnachtsdeko für dein Zuhause oder Büro. Dank der low poly Flächen kannst du das Tonpapier mit Wasserfarben, Acrylstiften und Buntstiften bemalen oder bestempeln. 
   
    Als Inspiration für den grün-pink gestreiften Nussknacker habe ich folgendes Muster hier <a href="https://de.freepik.com/vektoren-kostenlos/flaches-weihnachtliches-kariertes-musterdesign_20938760.htm"> bei Freepik</a> verwendet.
   
    Was ist drin im Bastelset?    
    - 6 vorgedruckte DIN A4 Bögen Tonkarton für einen Nussknacker
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das Bastelset für Kinder ab 14 Jahren.
    
    `,
    en: `How Christmassy: Craft this papercraft nutcracker as a beautiful Christmas decoration for your home or office. Thanks to the low poly areas, you can paint and decorate the paper. 
   I used the following pattern here <a href="https://de.freepik.com/vektoren-kostenlos/flaches-weihnachtliches-kariertes-musterdesign_20938760.htm">at Freepik</a> as inspiration for the checkered nutcracker.
   
    What's in the craft kit?    
    - 6 pre-printed A4 sheets of cardboard for nutcracker
    - 500 double-sided, strong adhesive pads for clean and quick gluing
    - Detailed step-by-step instructions in German and English for easy assembly
   
    What else do you need?
    A little patience, scissors, a ruler and a folding tool (e.g. awl, butter knife)
`,
  },

  pdf: {
    description: {
      de: `Wie weihnachtlich: Bastel diesen Papercraft Nussknacker als wunderschöne Weihnachtsdeko für dein Zuhause oder Büro. Dank der low poly Flächen kannst du das Tonpapier mit Wasserfarben, Acrylstiften und Buntstiften bemalen oder bestempeln. 
    
    Als Inspiration für den grün-pink gestreiften Nussknacker habe ich folgendes Muster hier <a href="https://de.freepik.com/vektoren-kostenlos/flaches-weihnachtliches-kariertes-musterdesign_20938760.htm"> bei Freepik</a> verwendet.
  
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du mit deinem Nussknacker direkt loslegen. Ich empfehle dir 230g/m² Tonkarton zu verwenden, wenn du die Vorlage auf DIN A4 ausdruckst. Damit dieser low poly Nussknacker gelingt, benötigst du 6 DIN A4 Bögen Tonkarton, einen Drucker, eine Schere und einen Kleber. 

      Es handelt sich hierbei um ein digitales Produkt. Sobald die Zahlung bei uns eingegangen ist, erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `How Christmassy: Craft this papercraft nutcracker as a beautiful Christmas decoration for your home or office. Thanks to the low poly areas, you can paint and decorate the paper. With our instructions and template, this modern paper nutcracker is an easy paper project for beginners who have always wanted to try their hand at a 3D sculpture. Together with an adult, it is also suitable as a craft project for children in winter.
      
      With the PDF template in A4 or letter format to print out yourself, you can get started with your nutcracker straight away. I recommend using 230g/m² cardboard if you print the template on letter size. If you scale the template in half, I recommend using 130g/m².
      To make this low poly nutcracker, you will need 6 A4 or letter sheets of cardboard, a printer, scissors and glue.
I used the following pattern here <a href="https://de.freepik.com/vektoren-kostenlos/flaches-weihnachtliches-kariertes-musterdesign_20938760.htm">at Freepik</a> as inspiration for the checkered nutcracker.

      This is a digital product. You will receive the download link by email immediately after receipt of payment. This will be done within one hour.`,
    },
    price: 9.9,
    priceThirdCountry: 9.9,

    image: {
      filename: {
        de: 'products/nussknacker/nussknacker-basteln.jpg',
        en: 'products/nussknacker/nussknacker-basteln.jpg',
      },
      alt_text: {
        de: 'Papercraft Nussknacker in bunten Farben vor beigem Hintergrund',
        en: 'Papercraft nutcracker in bright colors against a beige background',
      },
    },
  },

  colorinspirations: [
    {
      caption: {
        de: 'Nussknacker in den Farbe "bunt" (helllila, chamois, orange, ultramarin, rehbraun)',
        en: 'Nutcracker in the color "colorful"',
      },
      image: {
        filename: {
          de: 'products/nussknacker/nussknacker-basteln.jpg',
          en: 'products/nussknacker/nussknacker-basteln.jpg',
        },
        alt_text: {
          de: 'Bunter Papier Nussknacker vor einem beigen Hintergrund',
          en: 'Colorful paper nutcracker against a beige background',
        },
      },
    },

    {
      caption: {
        de: '38cm hoher Nussknacker mit Deko',
        en: '38cm high Nutcracker with decoration',
      },
      image: {
        filename: {
          de: 'products/nussknacker/nussknacker-basteln-einfach.jpg',
          en: 'products/nussknacker/nussknacker-basteln-einfach.jpg',
        },
        alt_text: {
          de: 'Bunter DIY Papier Nussknacker neben einer Vase, einer Elchskulptur und Tannenzapfen vor beigen Hintergrund',
          en: 'Colorful paper nutcracker next to a vase, an elk sculpture and pine cones against a beige background',
        },
      },
    },

    {
      caption: {
        de: 'Nussknacker in rot, helllila, königsblau, gelb',
        en: 'Nutcracker in red, light purple, royal blue, yellow ',
      },
      image: {
        filename: {
          de: 'products/nussknacker/nussknacker-papercraft-3d.jpg',
          en: 'products/nussknacker/nussknacker-papercraft-3d.jpg',
        },
        alt_text: {
          de: 'Papercraft Nussknacker in rot, hellila, königsblau und gelb auf einem weißen Regalbrett',
          en: 'Papercraft nutcracker in red, light purple, royal blue and yellow on a white shelf board',
        },
      },
    },

    {
      caption: {
        de: 'Nussknacker in dunkelgrün, rosa, rot (Link zum Muster s. Produktbeschreibung)',
        en: 'Nutcracker in dark green, pink, red (see product description for the link to the pattern)',
      },
      image: {
        filename: {
          de: 'products/nussknacker/nussknacker-basteln-papercraft.jpg',
          en: 'products/nussknacker/nussknacker-basteln-papercraft.jpg',
        },
        alt_text: {
          de: 'Papercraft Nussknacker in rot, hellila, königsblau und gelb auf einem weißen Regalbrett',
          en: 'Papercraft nutcracker in red, light purple, royal blue and yellow on a white shelf board',
        },
      },
    },

    {
      caption: {
        de: 'Vorschau auf die Bastelvorlage',
        en: 'Preview of template',
      },
      image: {
        filename: {
          de: 'products/nussknacker/nussknacker-basteln-vorlage.jpg',
          en: 'products/nussknacker/nussknacker-basteln-vorlage.jpg',
        },
        alt_text: {
          de: '3D Papercraft Nussknacker Bastelvorlage Vorschau PDF',
          en: '3D Papercraft nutcracker Template Preview PDF',
        },
      },
    },
  ],

  choose: {
    image: {
      filename: {
        de: 'products/nussknacker/nussknacker-basteln.jpg',
        en: 'products/nussknacker/nussknacker-basteln.jpg',
      },
      alt_text: {
        de: 'Papercraft Nussknacker von PaperShape',
        en: 'Papercraft nutcracker from PaperShape',
      },
    },
    colors: {
      color1: {
        de: 'Nussknacker',
        en: 'Nutcracker',
      },
    },
    disableColors: {
      color1: ['schwarz'],
      color2: ['schwarz'],
    },
    price: 19.9,
    priceThirdCountry: 19.9,
  },
};
