import React from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop({ children }) {
  const location = useLocation();

  React.useEffect(
    () => {
      try {
        if (window.gtag){
          window.gtag('set', 'page_path', location.pathname);
          window.gtag('event', 'page_view');
        }
        window.scrollTo(0, 0);
      } finally {
      }
    },
    [location]
  );

  return children;
}

export default ScrollToTop;
