import {
  CREATE_PAYMENT,
  PAYMENT_DONE,
  PAYMENT_ERROR,
  START_CREATE_PAYMENT,
} from './paymentActions';

const initialState = {
  paymentId: null,
  approvalUrl: null,
  paymentDone: false,
  paymentError: false,
};

export function paymentReducer(state = initialState, { type, payload }) {
  switch (type) {
    case START_CREATE_PAYMENT:
      return initialState;
    case PAYMENT_DONE:
      return {
        ...state,
        paymentDone: true,
      };
    case CREATE_PAYMENT:
      return {
        ...state,
        paymentId: payload.paymentId,
        approvalUrl: payload.approvalUrl,
      };
    case PAYMENT_ERROR:
      return {
        ...initialState,
        paymentError: payload.error,
      };
    default:
      return state;
  }
}
