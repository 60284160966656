import * as React from 'react';
import Lang from '../../common/Lang';
import PsImage from '../../common/PsImage';
import { getTextBySelectedLang } from '../../data/lang';

function ProductMainImage({ image }) {
  return (
    <div className="row">
      <div className="col-md-12">
        <PsImage
          image={image}
          className="mainimage"
          itemProp="image"
          format="555x555"
        />
        <p className="text-center small" style={{ marginTop: '5px' }}>
          <span
            className="tooltip-text"
            title={getTextBySelectedLang(
              'Je nach Bildschirmeinstellung können die Farben abweichen',
              'Colors can deviate due to your setting of the computer screen'
            )}>
            <Lang
              de="Farbabbildung ähnlich"
              en="Similar color to illustration"
            />
          </span>
        </p>
      </div>
    </div>
  );
}

export default ProductMainImage;
