import React from 'react';

const Email = () => (
  <span>
    <span className="code-dir">moc.epah&#115;-</span>
    <span className="code-dir hidden">hguorht-</span>
    <span className="code-dir">repap@&#109;aet</span>
  </span>
);

export default Email;
