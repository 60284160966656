export default {
  id: '2-12-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Frosch (2 Stk)',
    en: 'Frog (2 pc)',
  },
  subtitle: {
    de: 'B17 x H9 x T20 cm',
    en: 'W17 x H9 x D20 cm',
  },
  meta_title: {
    de: 'Frosch',
    en: 'Frog',
  },
  meta_description_google: {
    de: ' 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: ' 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: '3D Frosch aus Papier zum Küssen. Ob er sich zum Prinzen verwandelt, finde es heraus.',
    en: '3d frog from paper. Cool diy for everyone who believes in fairy tales.',
  },
  description: {
    de: `KOMPLETTES SET BEINHALTET
  2 Frösche
     
    a 3 DIN A4 Seiten Fotokarton
    Doppelseitige Klebepads
    Detaillierte schrittweise Anleitung
    
  ANLEITUNG 
    
    In einem kurzen Video erkläre ich euch live, wie ihr in 3 Schritten zu eurem einzigartigen 3D Origami Sparschwein gelangt. Praktische Tipps inklusive.
    Schneide alle Papierteile mit einer Schere oder einem Cutter aus. Alle durchgezogenen Linien müssen geschnitten werden. Schneide auch dort ein, wo eine kleine Schere angegeben ist.
    Um die Papierteile zu falten, musst du sie vorerst mit einem Lineal und einem Buttermesser oder einer Ahle vorfalzen. Lege dazu das Lineal an die gestrichelten Markierungen an und fahre mit dem Buttermesser und viel Druck am Lineal auf den Linien entlang. Anschließend knickst du die Linien wie in der Anleitung angegeben entweder nach außen oder innen.
    Jetzt gilt es alle Papierteile des 3D Origami miteinander zu verkleben. Verwende dazu die Klebepads, die im Set mitgeliefert sind. Das Verkleben erfolgt in nummerischer Reihenfolge bei 1, 2, 3, etc. Klebe die Pads von außen auf die Lasche und halte einen Sicherheitsabstand von ca. 2mm ein. Beim Verkleben unbedingt auf das Modell von außen schauen. BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser
    ab 14 Jahren empfohlen`,

    en: `COMPLETE KIT INCLUDES
   2 frogs

    a 3 DIN A4 cardboard sheets
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife

    Age recommendation 14+
    
    STEP BY STEP
    
    Cut the paper parts.
    To fold the paper parts, you need to pre-fold them first with a ruler and a bread knife or an awl. Set the ruler to the dashed line and press with the butter knife along. Then fold the parts as specified in the instructions: either outwardly or inwardly.
    Now glue all the paper parts of the 3D origami together. Use the pads, which are included in the set. The folding takes place in numerical order in 1, 2, 3, etc. Set the adhesive pads on the outer sides of the tabs and keep a safe distance of about 2mm. When gluing necessarily watch the model from the outside.`,
  },

  pdf: {
    description: {
      de: `Digitale Vorlage als PDF zum Herunterladen
Detaillierte Schritt-für-Schritt-Anleitung

Du benötigst: 2 DIN A4 Seiten Papier (mind. 160g/m), Schere, Lineal, Buttermesser, Klebepads oder Kleber`,

      en: `
        
    Details: Age recommendation from 14 years
    
    You need 2 DIN A4 cardboard sheets
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    Required materials (not included in the kit):
    Scissors
    Ruler
    Bread knife`,
    },
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'products/sfkfrosch/3d-papier-frosch.jpg',
        en: 'products/sfkfrosch/3d-papier-frosch.jpg',
      },
      alt_text: {
        de: 'IY 3D Herz aus Papier basteln zur Hochzeit, als Geschenkverpackung, als Deko oder Geschenk zum Muttertag #papershape',
        en: 'DIY 3d heart from paper - paper craft with template',
      },
    },
  },

  choose: {
    image: {
      filename: {
        de: 'products/sfkfrosch/3d-papier-frosch.jpg',
        en: 'products/sfkfrosch/3d-papier-frosch.jpg',
      },
      alt_text: {
        de: '3d Frosch aus Papier zum selbst basteln mit Vorlage und Anleitung - diy Papierskultpur #papershape',
        en: '3d paper frog - great diy paper sculpture #papershape',
      },
    },
    colors: {
      color1: {
        de: '1.Frosch',
        en: '1. Frog',
      },
      color2: {
        de: '2. Frosch',
        en: '2. Frog',
      },
    },
    disableColors: {
      color1: ['schwarz', 'anthrazit', 'bunt', 'tuerkis'],
      color2: ['schwarz', 'anthrazit', 'bunt', 'tuerkis'],
    },
    price: 14.9,
    priceThirdCountry: 12.5,
  },
};
