export default {
  url: 'tannenbaum-basteln',
  countermark: 'vg07.met.vgwort.de/na/7dfd52f5ef234ddb8e53d73f30283bcb',
  imageFolder: 'tannenbaum',

  name: {
    de: 'Moderner Tannenbaum',
    en: 'Paper Christmas tree',
  },
  id: '3-07-blog',
  pdf: {
    price: 3.9,
    priceThirdCountry: 3.9,
    image: {
      filename: {
        de: 'content/tannenbaum/tannenbaum-basteln-papier.jpg',
        en: 'content/tannenbaum/tannenbaum-basteln-papier.jpg',
      },
      alt_text: {
        de:
          'Tannenbaum basteln aus Papier als Weihnachtsdeko #papershape #bastelnmitpapier #diy #weihnachtsdeko #weihnachtsbaum',
        en:
          'How to make a papercraft Christmas tree #papercraft #christmastree #christmasdecor #crafting #diy',
      },
    },
  },

  meta_title: {
    de: 'Tannenbaum basteln aus Papier ',
    en: 'How to make a 3d Christmas tree from paper',
  },
  meta_description_google: {
    de:
      'Papershape zeigt dir, wie du einen 3D Tannenbaum bastelst aus Papier und diesen auch als Windlicht verwenden kannst.',
    en: 'How to make a 3d Christmas tree from paper',
  },
  meta_description_social: {
    de:
      'Papershape zeigt dir, wie du einen 3D Tannenbaum bastelst aus Papier und diesen auch als Windlicht verwenden kannst.',
    en: 'How to make a 3d Christmas tree from paper',
  },
  meta_images_social: [
    {
      de: 'tannenbaum-basteln-papier.jpg',
      en: 'tannenbaum-basteln-papier.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'tannenbaum-basteln-papier.jpg',
      en: 'tannenbaum-basteln-papier.jpg',
    },
    alt_text: {
      de:
        'Tannenbaum basteln aus Papier als Weihnachtsdeko #papershape #bastelnmitpapier #diy #weihnachtsdeko #weihnachtsbaum',
      en:
        'How to make a papercraft Christmas tree #papercraft #christmastree #christmasdecor #crafting #diy',
    },
  },
  h1: {
    de: '3D Tannenbaum basteln aus Papier ',
    en: 'How to make a papercraft Christmas tree?',
  },
  description: {
    de: `
      <p>Oh Tannenbaum, oh Tannenbaum, wie schön sind deine Blätter. Jeder von uns kennt dieses Lied, das wir insbesondere gerne
      mit unseren Kindern zur Weihnachtszeit singen.  </p>
      <p> Bestimmt kennt ihr die einfachen Tannenbäume aus Tonkarton, bei denen ihr die 2D Bäumchen im 90 Grad Winkel aneinanderklebt.
      Heute zeige ich euch aber in diesem DIY Post, wie ihr in wenigen Schritten einen wirklich schönen 3D Tannenbaum basteln könnt, der nicht aus 2D Elementen besteht.  </p>
      <p>Man kann unter ihn entweder eine LED-Lampe stellen wie bei diesem <a href="/de/blog/windlicht-basteln-herz">3D Herz-Windlicht </a> 
      oder kleine Geschenke verstecken oder als dekorative Elemente für den Weihnachtstisch nutzen.  Ihr könnt ihn auch sehr schön an den Weihnachtsbaum als Schmuckornament in verschiedenen Farben hängen. </p>
     
      <p>Größe: HBT 19 x 16 x 14 cm</p>
      
    
    `,
    en: `
      <p> Oh Christmas tree, oh Christmas tree, how beautiful are your leaves. In Germany this is a famous Christmas song that
      we especially like singing with our children at Christmas time. </ P>
      <p> Today I'll show you how to make a really nice 3D papercraft Christmas tree in just a few steps. </ P>
      <p> You can either put an LED lamp under it like this <a href="/en/blog/windlicht-basteln-herz"> 3D heart lantern </a>
     or use as decorative elements for the Christmas table. </ P>

     <p>Size: HWD 19 x 16 x 14 cm</p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'Weihnachtsbaum basteln: Anleitung',
    en: 'Step by step: how to make a Christmas tree',
  },
  steps: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Ihr benötigt pro Tannenbaum 4 DINA4 Papierseiten. Verwendet am besten Tonpapier mit einer Grammatur von bis zu 230g/m².
        Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer
        Schere aus.
       
        `,
        en: `You need 4 pages per Christmas tree. 
          The best will be you use paper with a grammage of up to 230g/m². 
        Print out the template and cut out all solid lines with scissors.`,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Falzt mit einer Ahle oder einem Falzbein und einem Lineal alle gestrichelten Linien (egal ob lang oder kurz gestrichelt) vor. Dazu das Lineal an die Linie anlegen und mit der Ahle mit Druck
        nachfahren.`,
        en: `Crease all dashed lines with an awl or a bonefolder. Therefore, place a ruler next to the dashed lines
        and crease the lines by pressing the bonefolder down. 
      `,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: ` Faltet die lang gestrichelten Linien von euch weg, d.h. nach unten. Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin.
        `,
        en: `Fold the long dashed lines away from you, i.e. downward. 
        Fold the short dashed lines up, i.e. up to you.`,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Klebt auf die Laschen der einzelnen Papierteile Klebepads. Wichtig ist, dass ihr das Pad auf die schöne Seite und damit die unbedruckte Seite der Lasche klebt. 
        Ihr beginnt in nummerischer Reihenfolge anschließend die einzelnen Teile zu verkleben, d.h. ihr startet bei 1 auf A und verklebt diese. Danach verklebt ihr Nr. 2 und so weiter. Eine Zahl existiert immer doppelt, sodass die Zahlenpaare
        immer verklebt werden müssen.
  `,
        en: `Use double-sided tape or any glue onto the unprinted side of the flaps.
        I prefer to craft with adhesive tape because your fingers stay clean. Each number exists twice. Stick all the same numbers together in ascending order (1, 2, 3, ...). Start with section 1 and stick it to the section marked 1 on paper A, pressing firmly enough to make them stick together.`,
      },
    },
  ],

  downloadName: 'praline-template_papershape.pdf',
  materials: [
    {
      de: 'Vorlage herunterladen (siehe unten)',
      en: 'Donwload template (scroll down)',
    },
    {
      de: '4 DINA4 Tonkarton oder Tonpapier (bis zu 230g/m²)',
      en: '4 colored papers (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    ],
  },
};
