export default {
  url: 'donut-osternest-basteln',
  countermark: 'vg07.met.vgwort.de/na/ece05078249c4fd2bdc0759807387900',
  imageFolder: 'donut-osternest',
  meta_title: {
    de: 'Süßes Osternest basteln mit Papier als Donut (kostenlose Vorlage)',
    en: 'How to make a doughnet from paper as Easternest (free template)',
  },
  meta_description_google: {
    de:
      'Ein saftiges Osternest basteln mit Papier als Donut - da will man nicht nur ins Ei beißen. Die Vorlage und Anleitung für diese Osteridee findet ihr hier.',
    en: ' ',
  },
  meta_description_social: {
    de:
      'Süßes Donut Osternest für ein Ei. Macht sich super als Tischdeko für Ostern. Hoppt rüber für die Donut-Vorlage. #ostern #osternest #papercraft #bastelnmitpapier #ei',
    en: '',
  },
  meta_images_social: [
    {
      de: 'osternest-basteln-donut-mit-papier.jpg',
      en: 'osternest-basteln-donut-mit-papier.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'osternest-basteln-donut-ei.jpg',
      en: 'osternest-basteln-papier-donut.jpg',
    },
    alt_text: {
      de: 'Süßes Donut Osternest aus Papier selber machen (mit Vorlage) ',
      en: '',
    },
  },
  h1: {
    de: 'Süßes Osternest basteln mit Papier als trendy Donut',
    en: 'Cute Donut Easternest from paper ',
  },
  description: {
    de: `
      <p>Langweilige Eierhalter gibt es dieses Jahr an Ostern nicht bei uns. Einige von euch 
      kennen meine Vorliebe für den dezenten Einsatz von Deko. 
        </p>
      <p>Aber wenn Deko, dann liebe ich sie in strahlenden Farben. In diesem Post zeige ich 
      euch, wie ihr ein farbenfrohes und minimalistisches Osternest für genau ein Osterei gestaltet.  </p>
      <p> Es hat die Form eines Donuts und bringt sicher nicht nur eure Kids zum Grinsen.
           
      
  
      <p> Wollt ihr noch einen draufsetzen, dann bemalt ihr 
       <a href="https://karaspartyideas.com/2015/04/diy-doughnut-easter-eggs.html" 
 target="_blank">die Eier ebenfalls wie einen Donut</a>.</p>
      
 </p>
      <p>Wer Lust auf Ostereier mit Augenschlag hat, der findet hier weitere DIY Ideen zu Ostern aus Papier:</p>
      <p>
      <li>Die Einhorn Wimpern sind nach wie vor total im Trend. Mit diesen 
      <a href="/de/blog/wimpern-hase/">süßen Wimpern Häschen </a>habt ihr auch gleich eine schöne Osterverpackung.</li>
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'osternest-basteln-donut.jpg',
          en: 'osternest-basteln-donut.jpg',
        },
        alt_text: {
          de:
            'Osternest basteln für den Osterisch als trendigen Donut  #ostern #osternest #papercraft #bastelnmitpapier #ei',
          en: 'Cute Donut Easternest from paper',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'osternest-basteln-papier-donut.jpg',
          en: 'osternest-basteln-papier-donut.jpg',
        },
        alt_text: {
          de:
            'Osternest basteln aus Papier in Donut-Form. Süße Idee für farbenfrohe Minimalisten  #ostern #osternest #papercraft #bastelnmitpapier #ei',
          en: 'Cute Donut Easternest from paper',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'osternest-basteln-donut-papier.jpg',
          en: 'osternest-basteln-donut-papier.jpg',
        },
        alt_text: {
          de:
            'Osternest basteln für den Osterisch als trendigen Donut  #ostern #osternest #papercraft #bastelnmitpapier #ei',
          en: 'Cute Donut Easternest from paper',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'So gelingt dir das Donut-Osternest - Anleitung mit Vorlage',
    en: 'Instructions: how to make 3d easternest as donut',
  },
  steps: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Um das Ostekörbchen zu basteln, benötigt ihr 2 DINA4 Seiten, eine davon für den Donut in einem Mustard Gelb oder Ockerton und eine Seite in pink oder rosa
        für die Glasur. Verwendet am besten Tonkarton (ca. 230g) für das Osternest und Tonpapier (ca. 130g) für die Glasur.
        Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer
        Schere aus.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Falzt mit einer Ahle und einem Lineal alle gestrichelten Linien (egal ob lang oder kurz gestrichelt) vor. Dazu das Lineal an die Linie anlegen und mit der Ahle mit Druck
        nachfahren.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: ` Faltet die lang gestrichelten Linien von euch weg, d.h. nach unten. Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin.
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de:
          'Zum Donut: Klebt auf die Laschen der einezelnen Papierteile Klebepads. Wichtig ist, dass ihr das Pad auf die schöne Seite und damit die unbedruckte Seite der Lasche klebt. Das kleine Loch wird mit dem kleinen Rechteckt, das ihr euch zu einem Zylinder klebt, verschlossen. Die Zacken liegen oben auf.',
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: 'Zur Glasur: Die Glasur klebt ihr nun auf den Donut auf. ',
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de:
          'Jetzt aus den Papierresten des Donuts und der Glasur die Streusel schneiden. Schneidet dazu unterschiedlich dünne Längsstreifen ab oder locht mit einer Lochzange / Locher euch kleine runde Streusel. Diese klebt ihr nun mit einer Pinzette und Leim auf den Dout auf. Yammi - fertig. ',
        en: '',
      },
    },
  ],

  video: {
    title: {
      de: 'Youtube: So bastelst du einen Donut-Osterkorb',
      en: '',
    },
    description: {
      de:
        'Versucht euch an diesem coolen Donut-Osterkörbchen. Es ist relativ schnell gemacht und ich garantiere euch: das Ankleben der Streusel macht mega Spaß.',
      en: '',
    },
    caption: {
      de: 'Donut-Osternest basteln aus Papier',
      en: 'Cute Donut Easternest from paper',
    },
    videoid: 't4zJMkGyAAg',
  },
  downloadName: 'donut-vorlage_papershape.pdf',
  materials: [
    {
      de: '2 DINA4 Papier in mustard gelb und pink (bis zu 130g/m²)',
      en:
        '2 colored papers in mustard yellow and pink (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'donut-osternest-basteln-mit-papier.jpg',
          en: 'donut-osternest-basteln-mit-papier.jpg',
        },
        alt_text: {
          de:
            'Osternest basteln als süßen Donut. Hoppt rüber zur Vorlage für das Osterkörbchen #papercraft #ostern #ostergeschenk #ei #bastelnmitpapier',
          en: 'Cute Donut Easternest from paper #easter #papercraft',
        },
      },
    ],
  },
};
