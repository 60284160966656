import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import OrderSumGross from '../../common/OrderSumGross';
import OrderNumber from '../../common/OrderNumber';
import { orderFormName } from '../../state/order/orderSelectors';
import PsBankAccount from '../../common/PsBankAccount';
import { PaymentstartLink } from '../../common/Links';
import { getTextBySelectedLang } from '../../data/lang';
import Lang from '../../common/Lang';
import PsMeta from '../../common/PsMeta';

class PaymentCanceled extends Component {
  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang(
            'Die Bezahlung war nicht erfolgreich',
            'The payment has failed'
          )}
          noindex
        />

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-header">
                {
                  <Lang
                    de="Die Bezahlung war nicht erfolgreich"
                    en="The payment has failed"
                  />
                }
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <p className={'text-center'}>
                <PaymentstartLink className="btn btn-primary btn-lg">
                  {
                    <Lang
                      de="weiter zum Bezahlvorgang"
                      en="pay your order now"
                    />
                  }
                </PaymentstartLink>
              </p>
              <p>
                <Lang
                  de={
                    <span>
                      Bitte versuche es noch einmal oder überweise den Betrag
                      von <OrderSumGross /> unter Angabe der{' '}
                      <strong>
                        Bestellnummer <OrderNumber />
                      </strong>{' '}
                      auf folgendes Konto:
                    </span>
                  }
                  en={
                    <span>
                      Please try again or transfer the amount of{' '}
                      <OrderSumGross /> specifying the{' '}
                      <strong>
                        order number <OrderNumber />
                      </strong>{' '}
                      to the following account:
                    </span>
                  }
                />
              </p>

              <PsBankAccount />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const orderFormSelector = formValueSelector(orderFormName);
const mapStateToProps = (state) => {
  return {
    name: orderFormSelector(state, 'name'),
    surname: orderFormSelector(state, 'surname'),
    email: orderFormSelector(state, 'email'),
  };
};

const Connected = connect(mapStateToProps)(PaymentCanceled);

export default reduxForm({
  form: orderFormName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Connected);
