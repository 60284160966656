import React, { Component } from 'react';
import TouchCarousel, { clamp } from 'react-touch-carousel';
import cx from 'classnames';
import { connect } from 'react-redux';
import { getBlogPosts } from '../state/blog/blogSelectors';
import NonPassiveTouchTarget from './NonPassiveTouchTarget';
import PsBlogPostThumbnail from './PsBlogPostThumbnail';

const cardSize = 100;
const cardPadCount = 0;
const carouselWidth = clamp(window.innerWidth, 0, 960);

class PsBlogPostListThumbnails extends Component {
  state = {
    show: false,
    pageYOffset: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { pageYOffset } = window;
    const oldpageYOffset = this.state.pageYOffset;
    this.setState({ pageYOffset });

    // scroll up
    if (oldpageYOffset > pageYOffset) {
      this.setState({
        show: true,
      });
    } else {
      this.setState({
        show: false,
      });
    }
    // hide on navigate
    if (pageYOffset === 0) {
      this.setState({
        show: false,
      });
    }
  };

  render() {
    const { blogPosts } = this.props;
    return (
      <div
        className="thumbnails visible-xs"
        style={{
          transform: 'translateY(' + (this.state.show ? 0 : 166) + 'px)',
          transition: 'all 0.5s ease-in-out 0s',
        }}>
        <TouchCarousel
          component={(props) => {
            const {
              cursor,
              carouselState: { active, dragging },
              ...rest
            } = props;
            let current = -Math.round(cursor) % blogPosts.length;
            while (current < 0) {
              current += blogPosts.length;
            }
            // Put current card at center
            const translateX =
              (cursor - cardPadCount) * cardSize +
              (carouselWidth - cardSize) / 2;
            return (
              <NonPassiveTouchTarget
                className={cx('carousel-container', {
                  'is-active': active,
                  'is-dragging': dragging,
                })}>
                <NonPassiveTouchTarget
                  className="carousel-track"
                  style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
                  {...rest}
                />
              </NonPassiveTouchTarget>
            );
          }}
          cardSize={cardSize}
          cardCount={blogPosts.length}
          cardPadCount={cardPadCount}
          defaultCursor={-1}
          loop={false}
          renderCard={(index, modIndex) => {
            const item = blogPosts[modIndex];
            return (
              <div key={index} style={{}}>
                <PsBlogPostThumbnail blogPost={item} />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blogPosts: getBlogPosts(state),
  };
};

export default connect(mapStateToProps)(PsBlogPostListThumbnails);
