import React from 'react';
import { Accordion, Panel, PanelGroup } from 'react-bootstrap';
import { getTextBySelectedLang } from '../data/lang';
import { PsLink } from '../common/PsLink';
import howToHangPapershape from '../styles/images/how-to-hang-papershape.jpg';
import Lang from '../common/Lang';
import { ContactLink } from '../common/Links';
import PsMeta from '../common/PsMeta';

const CustomPanel = ({eventKey, header, children }) => <Panel eventKey={eventKey}>
  <Panel.Heading>
    <Panel.Title toggle>{header}</Panel.Title>
  </Panel.Heading>
  <Panel.Body collapsible>
    {children}
  </Panel.Body>
</Panel>;

export default () => (
  <div>
    <PsMeta
      title={getTextBySelectedLang('FAQ', 'FAQ')}
      description={getTextBySelectedLang(
        'In den FAQs beantworte ich eure häufigsten Fragen.',
        'I answer to your most frequent questions in FAQ.'
      )}
    />

    <div className="container faq">
      <div className="row">
        <div className="col-lg-12">
          <h1 className="page-header">
            FAQ
            <small>
              <Lang
                de=" häufige Fragen und Antworten"
                en=" frequently asked questions"
              />
            </small>
          </h1>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <h2>
            <Lang de="Shop" en="Shop" />
          </h2>
          <PanelGroup accordion id="general">
            <CustomPanel
              header={getTextBySelectedLang(
                'Wo ist das ABER bei der Geling-Garantie?',
                'Money-back guarantee: where is the catch?'
              )}
              eventKey="1">
              <Lang
                de={
                  'Es ist einfach. Solltest du mit dem Ergebnis doch nicht zufrieden sein, erstatte ich dir dein Geld bis zu 60 Tagen nach Kauf zurück – ganz ohne aber. Schreib mir einfach eine Mail und ich kümmere mich gerne darum.'
                }
                en={
                  'It is simple. I guarantee you a refund of your money if you are not satisfied with your 3d trophy from paper. We offer a 60 days money back guarantee. Just drop me an email and I will take care of it.'
                }
              />
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Wie befestige ich meine Tiertrophäe aus Papier an der Wand?',
                'How do I install my trophy from paper on the wall?'
              )}
              eventKey="2">
              <div style={{ margin: '0 -15px' }}>
                <div className="col-xs-6 col-sm-4 col-md-2">
                  <img
                    src={howToHangPapershape}
                    alt="how to hang papershape"
                    className="img-responsive"
                  />
                </div>
              </div>
              <Lang
                de={
                  'Es ist so einfach wie das Aufhängen eines Bildes. Zum Aufhängen der Modelle einen Nagel sehr steil an die Wand anbringen und das Loch am Rückenteil als Aufhängung nutzen.'
                }
                en={
                  'It is as easy as hanging a picture on the wall. In order to hang a papershape trophy just fix a nail very steep on the wall and hang it like a picture using the hole at the back of the trophy.'
                }
              />
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Liefert ihr die PaperShapes bereits zusammengebaut?',
                'Do you offer already assembled trophies?'
              )}
              eventKey="3">
              <Lang
                de={
                  'Ja, gerne. Schreib uns einfach, welches papershape es sein soll und deinen Wunschtermin. Wir senden dir anschließend den Preis und Lieferzeitpunkt als unverbindliches Angebot.'
                }
                en={
                  'Yeah, sure. Drop us an email what trophy you want and when we should deliver. We will send you the price and shipping date as an offer without obligation.'
                }
              />
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Wo und wie kann ich meine Bestellung widerrufen?',
                'Where and how can I cancel my order?'
              )}
              eventKey="4">
              <Lang
                de={
                  <span>
                    Bitte schreibe mir über das{' '}
                    <PsLink to={'/contact'}>Kontaktformular</PsLink>.
                  </span>
                }
                en={
                  <span>
                    Please use the <PsLink to={'/contact'}>contact form</PsLink>.
                  </span>
                }
              />
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Ich hätte da eine weitere Frage...',
                'I have another question...'
              )}
              eventKey="5">
              <Lang
                de={
                  <span>
                    Ich freue mich von dir zu hören. Schreibe mir einfach über
                    das <ContactLink>Kontaktformular</ContactLink> oder direkt
                    per Chat. Ich kann sofort weiterhelfen.
                  </span>
                }
                en={
                  <span>
                    I look forward to hearing from you. Just drop me a line
                    using the <ContactLink>contact form</ContactLink> or
                    directly the chat. I am happy to help asap.
                  </span>
                }
              />
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Was ist im Set enthalten?',
                'What is included in the kit'
              )}
              eventKey="9">
              <Lang
                de={
                  <span>
                    Dem Set liegen alle Materialien bei, die du benötigst. Die
                    stabilen Fotokartonagen sind bereits vorgedruckt, sodass du
                    direkt starten kannst. Um dir die Suche nach dem richtigen
                    Kleber für diese Art von Papier zu ersparen, liegen auch
                    Klebepads bei, mit denen du das PaperShape mühelos und frei
                    von Klebeflecken zusammenbauen kannst.
                  </span>
                }
                en={
                  <span>
                    All materials that you'll need are included in the diy kit:
                    you will get the printed papers and double-sided
                    self-adhesive dots.
                  </span>
                }
              />
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Wo finde ich das Anleitungsvideo?',
                'Where do I find the video tutorial?'
              )}
              eventKey="10">
              <div>
                <PsLink to={'/video'}>
                  <Lang de={'hier'} en={'here'} />
                </PsLink>
              </div>
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Was benötige ich neben dem Set für den Zusammenbau?',
                'What do I need except of the diy kit'
              )}
              eventKey="11">
              <Lang
                de={
                  <span>
                    Du benötigst eine handliche Schere und für das Falzen ein
                    Lineal und einen dünnen stumpfen Gegenstand (z.B. Ahle,
                    Rückseite eines Buttermessers, Stricknadel). Als Unterlage
                    eignet sich eine Schneidematte sehr gut.
                  </span>
                }
                en={
                  <span>
                    You'll need a scissor and for the folding part a ruler and a
                    thin stub item such as an awl, the back of a butter knife,
                    knitting needle). Use a cutting mat as underlay.
                  </span>
                }
              />
            </CustomPanel>
          </PanelGroup>
          <h2 id="faq-terms-of-use">
            <Lang
              de="Blog Vorlagen: Nutzungsbedingungen"
              en="Blog templates: terms of use"
            />
          </h2>
          <Accordion id="rights">
            <CustomPanel
              header={getTextBySelectedLang(
                'NUR FÜR DEN PRIVATEN GEBRAUCH: Wofür darf ich die kostenlosen Vorlagen verwenden?',
                'PRIVATE USE ONLY: What am I allowed to do with the free templates?'
              )}
              eventKey="12">
              <Lang
                de={
                  <span>
                    Du darfst die Vorlagen so oft wie du magst herunterladen,
                    ausdrucken, basteln, verschenken und auf deinem PC dauerhaft
                    speichern. Jegliche kommerzielle Nutzung der Vorlagen ist
                    untersagt.
                  </span>
                }
                en={
                  <span>
                    You are allowed to download, print and craft the files as
                    many times as you want and to save the file on your
                    computer. It is prohibited to use the templates for any
                    commercial use.
                  </span>
                }
              />
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Wann handelt es sich um eine kommerzielle Nutzung?',
                'What is a commercial use of the templates?'
              )}
              eventKey="13">
              <Lang
                de={
                  <span>
                    Hier findest du eine Auflistung von Beispielen, die nicht
                    abschließend ist:
                    <ul>
                      <li>
                        Nutzung der Vorlagen oder daraus entstandenen Produkte
                        für (professionelle) Events wie Messen, Märkte,
                        Workshops (bezahlt oder unbezahlt)
                      </li>

                      <li>
                        Nutzung als Dekoration oder Displays für Schaufenster
                        (z.B. in Läden)
                      </li>

                      <li>Verwendung als Give-Aways </li>
                    </ul>
                    Bei Interesse an der kommerziellen Nutzung der Vorlagen,
                    schreib uns bitte eine E-Mail an team(äet)paper-shape.com
                  </span>
                }
                en={
                  <span>
                    Here you find a list of examples which is however not
                    limited to:
                    <ul>
                      <li>
                        Use as part of commercial events such as trade fairs,
                        exhibitions, workshops (paid or unpaid)
                      </li>
                      <li>
                        Use as part of displays in commercial venues (e.g.
                        stores, fair booths)
                      </li>
                      <li>Use as promotional items</li>
                    </ul>
                    If you are interested in using our printables for an
                    commercial use, please contact us team(äet)paper-shape.com
                  </span>
                }
              />
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Darf ich euer DIY Projekt auf meinem Blog posten?',
                'Can I post your DIY project on my blog?'
              )}
              eventKey="14">
              <Lang
                de={
                  <span>
                    Ja, bitte beachte dazu folgende Regeln: Du kannst gerne ein
                    Foto des fertigen DIY Projektes mit einem Link zum
                    ursprünglichen Blogbeitrag posten. Gerne darfst du jegliche
                    Fotos auf Pinterest teilen.
                  </span>
                }
                en={
                  <span>
                    Yes, but please follow these guidelines: You can post one
                    photo of the finished paper project with a link to our
                    original blog post. You can also pin our photos on
                    pinterest. Thank you!
                  </span>
                }
              />
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Darf ich die kostenlosen Vorlagen teilen?',
                'Can I share the free printable?'
              )}
              eventKey="15">
              <Lang
                de={
                  <span>
                    Ja, bitte beachte dabei direkt auf den DIY Blogbeitrag zu
                    verlinken. Die Verlinkung direkt auf die Vorlagen-URL ist
                    nicht erlaubt.
                  </span>
                }
                en={
                  <span>
                    Yes, please link to the original blog post and not directly
                    to the template.
                  </span>
                }
              />
            </CustomPanel>

            <CustomPanel
              header={getTextBySelectedLang(
                'Wir sind eine gemeinnützige Organisation. Dürfen wir die Vorlagen verwenden?',
                'Non-profit use: We are a non-profit-organization, can we use your files?'
              )}
              eventKey="16">
              <Lang
                de={
                  <span>
                    Ja, gemeinnützige und non-profit Organisationen wie z.B.
                    Vereine, Kirchen dürfen unsere kostenlosen Vorlagen nutzen.
                    Eine Nutzung kann beinhalten: die gebastelten Produkte als
                    Tombolagewinne zum Zweck der Spendengewinnung einzusetzen,
                    als Freizeitaktivität mit Kindern/Jugendlichen während
                    Freizeitcamps. Bitte kontaktiert uns dennoch vorher, um euer
                    Vorhaben mit uns abzusprechen.
                  </span>
                }
                en={
                  <span>
                    Yes, charities and non-profit-organizations are allowed to
                    use our files. However please contact us in advance to
                    obtain permission.
                  </span>
                }
              />
            </CustomPanel>
          </Accordion>
        </div>
      </div>
    </div>
  </div>
);
