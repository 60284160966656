export default {
  id: '3-18-blog',
  category: ['paper_flowers'],

  name: {
    de: 'Gerbera',
    en: 'Gerbera Daisy',
  },
  subtitle: {
    de: 'B14 x H14 x T3 cm',
    en: 'W14 x H14 x D3 cm',
  },
  meta_title: {
    de: 'Gerbera aus Papier Vorlage',
    en: 'Gerbera Daisy from Paper Template ',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für schöne Gerbera kannst du etliche kreative Ideen umsetzen. Wie wäre es mit einem Bouquet aus Gerberas für dein Zuhause?',
    en: 'With this template for beautiful gerbera daisies you can realize quite a few creative ideas. How about a wonderful bouquet of gerbera daisies for your home?',
  },
  meta_description_social: {
    de: 'Mit dieser Vorlage für schöne Gerbera kannst du etliche kreative Ideen umsetzen. Wie wäre es mit einem wundervollen Blumenstrauß für dein Zuhause?',
    en: 'With this template for beautiful gerbera daisies you can realize quite a few creative ideas. How about a wonderful bouquet of daisies for your home?',
  },

  pdf: {
    description: {
      de: `Mit dieser digitalen Vorlage für schöne Gerberas kannst du etliche kreative Ideen umsetzen. <a href="/de/blog/gerbera-basteln/">In dieser Anleitung</a> zeige ich dir, wie einfach das geht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² Tonpapier zu verwenden.
      Damit die Gerbera gelingen, benötigst du einen Drucker, 1 Bogen Tonpapier pro Gerbera, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this digital template for our gerbera daisies you can realize a lot of creative ideas. <a href="/en/blog/gerbera-basteln/">In this instruction</a> I show you how easy it is.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use 130g/m² paper.
      To make the gerbera daisy succeed, you'll need a printer, 1 sheet of paper for a gerbera daisy, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'products/sfkgerbera/gerbera-papershape.jpg',
        en: 'products/sfkgerbera/gerbera-papershape.jpg',
      },
      alt_text: {
        de: 'Selbst gebastelte Gerbera aus Papier auf lila Hintergrund',
        en: 'A papercrafted gerbera daisy on a purple background',
      },
    },
  },
};
