import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Lang from '../../common/Lang';
import PsImage from '../../common/PsImage';

class VaseTipp extends Component {
  render() {
    return (
      <Row style={{ marginTop: '30px' }}>
        <div
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/HowToStep">
          <div
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/HowToDirection">
            <Col smOffset={0} sm={4}>
              <div itemProp="duringMedia">
                <PsImage
                  itemProp="image"
                  image={{
                    filename: {
                      de: 'content/vase/papier-vasen-falten.gif',
                      en: 'content/vase/how-to-make-homemade-flower-vase.gif',
                    },
                    alt_text: {
                      de:
                        'DIY Vase aus Papier basteln mit Faltanleitung und kostenloser Vorlage zum Herunterladen / PaperShape #vases #papercraft #diy',
                      en:
                        "3d origami-look vase with free printable (with pictures and how to video) How lovely would that vase be as present for Mother's Day? / PaperShape #vases #paper #diy #mothersday",
                    },
                  }}
                />
              </div>
            </Col>
            <Col smOffset={0} sm={7}>
              <div className="highlight-font">
                <Lang
                  de="Hier solltet ihr aufpassen:"
                  en="You should pay attention to this while crafting the vase:"
                />
              </div>
              <p itemProp="text">
                <Lang
                  de="Sobald die Blumen verwelkt sind, müssen sie raus. Nur wie ohne
                      die Öffnung nass zu machen?
                      Ich handhabe es so:
                      Alleine geht es wahrscheinlich auch, aber ich hole mir meinen
                      Freund dazu und lasse ihn die Vase samt Wasserglas und Blumen
                      anheben. Dann ziehe ich das Glas von unten weg und wickel die
                      Blumen mit einem Taschentuch ein. Anschließend ziehe ich sie
                      samt Taschentuch von oben wieder herraus."
                  en="You may wondering how to put out the flowers once they withered - without getting the inside wet. 
                      Here is my recommendation: It is easier with a helping hand, so I ask my 
                      boyfriend to lift the entire vase (with the glass and flowers). 
                      Then I take the glass and put it aside and dry the flowers from beneath with a tissue. Then simply pull out the flowers of the vase."
                />
              </p>
              <p itemProp="text">
                <Lang
                  de="Beachtet auch, dass je nach Höhe eures Glases
                        unterschiedlich viele Blumen in der Vase stehen können. Ihr
                        merkt schnell, wenn der Blumenstrauß zu üppig ausfällt - denn
                        dann kippt die Vase."
                  en="Take care how many flowers you put into the vase as it may fall over."
                />
              </p>
            </Col>
          </div>
        </div>
      </Row>
    );
  }
}

export default VaseTipp;
