export default {
  id: '1-09-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Fuchs',
    en: 'Fox',
  },
  subtitle: {
    de: 'B29 x H21 x T31 cm',
    en: 'W29 x H21 x D31 cm',
  },
  meta_title: {
    de: 'Tiertrophäe Fuchs',
    en: 'Fox Animal',
  },
  meta_description_google: {
    de: 'Das Trendtier Fuchs nun als 3D Tiertrophäe für die Wand gestalten. Besser als jedes Poster.',
    en: 'Decorate your room with a cool and fun 3d paper fox. Enjoy the process of making your own paper sculpture. ',
  },
  meta_description_social: {
    de: 'Genieße den Prozess mit deinen eigenen Händen etwas zu erschaffen und diy dir diesen coolen Papier Fuchs als Wandtrophäe.',
    en: 'Enjoy papercrafting your own fun paper fox. It is a great wall decor and am amazing alternative for boring posters.',
  },
  description: {
    de: `     
    Inhalt des Sets:    
    -7 DIN A4 Seiten Fotokarton (die Nase ist immer schwarz)
    -Doppelseitige Klebepads
    -Detaillierte Anweisungen schrittweise

    Altersempfehlung ab 14 Jahren
    
    Ihr benötigt noch diese Werkzeuge: Schere, Lineal, Buttermesser`,

    en: `DETAILS: Age recommendation 14+
    
    COMPLETE KIT INCLUDES
    
    7 DIN A4 cardboard sheets
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife
    
    STEP BY STEP
    
    Cut the paper parts.
    To fold the paper parts, you need to pre-fold them first with a ruler and a bread knife or an awl. Set the ruler to the dashed line and press with the butter knife along. Then fold the parts as specified in the instructions: either outwardly or inwardly.
    Now glue all the paper parts of the 3D origami together. Use the pads, which are included in the set. The folding takes place in numerical order in 1, 2, 3, etc. Set the adhesive pads on the outer sides of the tabs and keep a safe distance of about 2mm. When gluing necessarily watch the model from the outside.`,
  },

  choose: {
    image: {
      filename: {
        de: 'products/sfkfuchs/papier-fuchs-tierkopf-3d-diy.jpg',
        en: 'products/sfkfuchs/papier-fuchs-tierkopf-3d-diy.jpg',
      },
      alt_text: {
        de: 'Tierskulptur Fuchs aus Papier zum Basteln #papercraft #bastelnmitpapier ',
        en: 'Papercraft fox wall decor #papercraft ',
      },
    },
    colors: {
      color1: {
        de: 'Kopf (oben)',
        en: 'Head (top)',
      },
      color2: {
        de: 'Kopf (unten)',
        en: 'top (bottom)',
      },
    },
    disableColors: {
      color1: ['schwarz'],
    },

    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
