export default {
  id: '2-03-sfk',
  category: [ 'animal_sculpture', 'easter'],
  name: {
    de: 'Dürer-Hase',
    en: 'Dürer-Bunny',
  },
  subtitle: {
    de: 'B15 x H23 x T30 cm',
    en: 'W15 x H23 x D30 cm',
  },
  meta_title: {
    de: '3d Dürer-Hase Papiertier',
    en: '3d Paper Bunny',
  },
  meta_description_google: {
    de: 'Der 3d Dürer-Hase aus Papier verzaubert als Deko jeden Raum.  100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The 3d paper bunny enchants each room with lots of space for creativity.100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: 'Der 3d Dürer-Hase aus Papier verzaubert als Deko jeden Raum. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The 3d paper bunny enchants each room with lots of space for creativity.  100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  description: {
    de: `Albrecht Dürers weltbekannter Feldhase stand dem PaperShape Osterhasen Modell und präsentiert sich nun in seiner gesamten Farbenpracht. Ob unifaben oder frühlingshaft bunt, auf dem Ostertisch ist er ein echter Hingucker. Und wer seinen Kindern mal ein ungewöhnliches Versteck für die Ostersuche bieten mag, der versteckt die Süßigkeiten einfach unter dem PaperShape Osterhasen.
In bunt gesellt sich der Hase gerne zu frühlingshaften Blumen.
Die edle Version für alle Puristen.

DETAILS: Altersempfehlung ab 14 Jahren

KOMPLETTES SET BEINHALET

6 DIN A4 Seiten Fotokarton
Doppelseitige Klebepads
Detaillierte schrittweise Anleitung

BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN): Schere; Lineal; Buttermesser

    `,
    en: ` Albrecht Dürer's world famous Hare was the Paper Shape’s model for the Easter Rabbit and is now presented in its colourful version. Whether mono-coloured or vernally colourful, he is a real eye-catcher on the Easter table. And those who want to offer their children an unusual hiding place for the Easter search, can hide the candy right under the Paper Shape Easter Rabbit.
The colourful rabbit surrounded by the vernal flowers.
The noble version for all purists.

Details: Age recommendation from 14 years

Complete kit includes:
6 DIN A4 cardboard sheets Origami Rabbit
Double-sided adhesive pads
Detailed step-by-step instructions

Required materials (not included in the kit):
Scissors
Ruler
Bread knife`,
  },

  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 3 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 6 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 6 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 6 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkduererhase/papier-duerer-hase-diy.jpg',
        en: 'products/sfkduererhase/papier-duerer-hase-diy.jpg',
      },
      alt_text: {
        de: '3D Dürer-Hase aus Papier DIY Papiertier #papershape',
        en: '3d Paper bunny DIY #papershape',
      },
    },
    price: 14.9,
    priceThirdCountry: 14.9,
  },

  colorinspirations: [
    {
      caption: {
        de: 'bunt',
        en: 'bunt',
      },
      image: {
        filename: {
          de: 'products/sfkduererhase/papier-duerer-hase-diy.jpg',
          en: 'products/sfkduererhase/papier-duerer-hase-diy.jpg',
        },
        alt_text: {
          de: '3D Dürer-Hase aus Papier DIY Papiertier #papershape',
          en: '3d Paper bunny DIY #papershape',
        },
      },
    },
    {
      caption: {
        de: 'hell lila',
        en: 'pale lilac',
      },
      image: {
        filename: {
          de: 'products/sfkduererhase/diy-deko-hase-aus-papier.jpg',
          en: 'products/sfkduererhase/diy-deko-hase-aus-papier.jpg',
        },
        alt_text: {
          de: '3D Dürer-Hase aus Papier DIY Papiertier #papershape',
          en: '3d Paper bunny DIY #papershape',
        },
      },
    },
    {
      caption: {
        de: 'gelb',
        en: 'yellow',
      },
      image: {
        filename: {
          de: 'products/sfkduererhase/hase_gelb.jpg',
          en: 'products/sfkduererhase/hase_gelb.jpg',
        },
        alt_text: {
          de: '3D Dürer-Hase aus Papier DIY Papiertier #papershape',
          en: '3d Paper bunny DIY #papershape',
        },
      },
    },
    {
      caption: {
        de: 'pink',
        en: 'pink',
      },
      image: {
        filename: {
          de: 'products/sfkduererhase/hase_pink.jpg',
          en: 'products/sfkduererhase/hase_pink.jpg',
        },
        alt_text: {
          de: '3D Dürer-Hase aus Papier DIY Papiertier #papershape',
          en: '3d Paper bunny DIY #papershape',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkduererhase/hase_weiss.jpg',
        en: 'products/sfkduererhase/hase_weiss.jpg',
      },
      alt_text: {
        de: '3D Dürer-Hase aus Papier DIY Papiertier #papershape',
        en: '3d Paper bunny DIY #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Hase',
        en: 'Bunny',
      },
    },
    disableColors: {
      color1: ['schwarz'],
    },
    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
