import React, { Component } from 'react';
import { selectedLang } from '../data/lang';
import cn from 'classnames';

const reloadImages = {
  "555x555": {
    width: "555",
    height: "555"
  },
  "600x900": {
    width: "600",
    height: "900"
  }
}

class PsImage extends Component {

  render() {
    const { image, className, itemProp, style, format, ...other } = this.props;
    const filename = image.filename[selectedLang];
    if (
      filename == null
    ) {
      return null;
    }
    const preloadSize = format != null ? reloadImages[format] : null;
    return (
      <div>
        <img
          src={
            process.env.PUBLIC_URL + '/images/' + filename
          }
          alt={image.alt_text[selectedLang]}
          className={cn(className, 'img-responsive')}
          itemProp={itemProp}
          style={style}
          {...preloadSize}
          {...other}
        />
      </div>
    );
  }
}

export default PsImage;
