import React from 'react';
import Email from '../common/Email';
import { getTextBySelectedLang } from '../data/lang';
import Lang from '../common/Lang';
import PsMeta from '../common/PsMeta';

export default () => (
  <div>
    <PsMeta title={getTextBySelectedLang('Impressum', 'Imprint')} noindex />

    <div className="container">
      <h1 className="page-header">
        <Lang de="Impressum" en="Imprint" />
      </h1>

      <div className="row">
        <div className="col-md-12">
          <div>
            <br />
            PaperShape UG (haftungsbeschränkt) &amp; Co. KG
            <br />
            Guttknechtstr. 23
            <br />
            90547 Stein
            <br />
            <Lang de="Deutschland" en="Germany" />
            <br />
            <Lang de="Registergericht: " en="Registry court: " />
            Amtsgericht Fürth
            <br />
            <Lang de="Registernummer: " en="Register number: " />
            HRA 11276
            <br />
            <br />
            <Lang de="Ust-IdNr: " en="VAT no.: " />
            DE305074829
            <br />
            <br />
            <Lang de="Geschäftsführer: " en="CEO: " />
            <br />
            Anastasia Baron &amp; Harald Baron <br />
            <br />
            E-Mail: <Email />
            <br />
            <Lang de="Telefonnummer: " en="Phone no.: " />
            +49 911 36069244
            <br />
            <br />
            <Lang
              de="Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO"
              en="Online dispute settlement in accordance with art. 14 par. 1 ODR-VO"
            />
            <br />
            <Lang
              de="Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter folgendem Link finden: "
              en="The European Commission provides a platform for online dispute settlement, which you can find following the link: "
            />{' '}
            <a
              href="http://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer">
              http://ec.europa.eu/consumers/odr/
            </a>{' '}
            <br />
            <br />
            <br />
            <div>
              Icon made by{' '}
              <a href="http://www.freepik.com" rel="nofollow" title="Freepik">
                Freepik
              </a>{' '}
              from{' '}
              <a href="http://www.flaticon.com" rel="nofollow" title="Flaticon">
                www.flaticon.com
              </a>{' '}
              is licensed under{' '}
              <a
                href="http://creativecommons.org/licenses/by/3.0/"
                rel="nofollow"
                title="Creative Commons BY 3.0">
                CC BY 3.0
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
