import React from 'react';
import { PsLink } from '../common/PsLink';
import PsYoutube from '../common/PsYoutube';
import { getTextBySelectedLang } from '../data/lang';
import Lang from '../common/Lang';
import PsMeta from '../common/PsMeta';

export default () => (
  <div>
    <PsMeta
      title={getTextBySelectedLang('Anleitungsvideo', 'My tutorial videos')}
      description={getTextBySelectedLang(
        'Anleitungsvideo',
        'My tutorial videos'
      )}
    />

    <div className="section" style={{ paddingBottom: 0 }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h1 className="page-header">
              <Lang de="Anleitungsvideo" en="My tutorial videos" />
            </h1>
          </div>
        </div>
        <p className="text-center" style={{ marginTop: '40px' }}>
          <Lang
            de={
              <span>
                Du hast ein Papershape zum Kleben? Dann{' '}
                <PsLink to="/video">hier entlang</PsLink>.
              </span>
            }
            en={
              <span>
                You've a Papershape that has to be cut, fold and glued?{' '}
                <PsLink to="/video">click here</PsLink>.
              </span>
            }
          />
        </p>
      </div>
    </div>

    <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-offset-2 col-md-8 text-center">
            <PsYoutube
              title={getTextBySelectedLang(
                'Anleitungsvideo',
                'My tutorial videos'
              )}
              videoid="bWeqWe5gfSg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);
