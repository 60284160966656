import React from 'react';
import jolie from '../styles/images/presse/print/jolie.jpg';
import brigitte from '../styles/images/presse/print/Brigitte.jpg';
import sahco from '../styles/images/presse/print/sahco.jpg';
import deco from '../styles/images/presse/print/DECO.jpg';
import bmwmini from '../styles/images/presse/print/bmw-mini.jpg';
import wohnrevue from '../styles/images/presse/print/wohnrevue.jpg';
import ad from '../styles/images/presse/print/AD.jpg';
import echtbayernheimat from '../styles/images/presse/print/echtbayern-heimat.jpg';
import echtbayern from '../styles/images/presse/print/echtbayern.jpg';
import eigenwerkmagazin from '../styles/images/presse/print/eigenwerk-magazin.jpg';
import handemadekultur from '../styles/images/presse/print/handmade-kultur.jpg';
import madeinpaper from '../styles/images/presse/print/madeinpaper.jpg';
import ardbuffet2015 from '../styles/images/presse/tv/ARD-Buffet-2015-11.jpg';
import frankenfernsehen from '../styles/images/presse/tv/frankenfernsehen.jpg';
import jdfasdfawre6 from '../styles/images/presse/tv/jdfasdfawre6.jpg';
import brpapershape from '../styles/images/presse/tv/br-papershape.jpg';
import ardbuffet3 from '../styles/images/presse/tv/ARD-Buffet-3.jpg';
import ardbuffet from '../styles/images/presse/tv/ARD-Buffet.jpg';
import ardbuffet2016 from '../styles/images/presse/tv/ARD-Buffet-2016-12.jpg';
import { getTextBySelectedLang } from '../data/lang';
import PsMeta from '../common/PsMeta';

const PressImg = (props) => (
  <img
    alt={props.title}
    style={{ margin: '0 auto 30px' }}
    className="img-responsive"
    {...props}
  />
);

export default () => (
  <div>
    <PsMeta
      title={getTextBySelectedLang('Presse', 'Press')}
      description={getTextBySelectedLang(
        'Meine PaperShapes hast du schon in folgenden Zeitungen gesehen',
        'You have already seen my PaperShapes in the following newspapers'
      )}
    />

    <div className="container">
      <h1 className="page-header text-center">PRINT</h1>
      <div className="row">
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg src={jolie} className="img-responsive" title="jolie" />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg
            src={brigitte}
            className="img-responsive"
            title="Brigitte"
          />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg src={sahco} className="img-responsive" title="sahco" />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg src={deco} className="img-responsive" title="DECO" />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg src={bmwmini} className="img-responsive" title="BMW Mini" />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg
            src={wohnrevue}
            className="img-responsive"
            title="wohnrevue"
          />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg src={ad} className="img-responsive" title="AD" />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg
            src={echtbayernheimat}
            className="img-responsive"
            title="echt bayern heimat"
          />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg
            src={echtbayern}
            className="img-responsive"
            title="echt bayern"
          />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg
            src={eigenwerkmagazin}
            className="img-responsive"
            title="eigenwerk magazin"
          />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg
            src={handemadekultur}
            className="img-responsive"
            title="Handmade Kultur"
          />
        </div>
        <div className="col-xs-6 col-sm-4 col-md-3">
          <PressImg
            src={madeinpaper}
            className="img-responsive"
            title="made in paper"
          />
        </div>
      </div>
      <h2 className="page-header text-center">TV</h2>
      <div className="row">
        <div className="col-sm-6">
          <PressImg
            src={ardbuffet2015}
            className="img-responsive"
            title="ARD-BUFFET 2016"
          />
        </div>
        <div className="col-sm-6">
          <PressImg
            src={frankenfernsehen}
            className="img-responsive"
            title="FRANKEN FERNSEHEN 2016"
          />
        </div>
        <div className="col-sm-6">
          <PressImg src={jdfasdfawre6} className="img-responsive" />
        </div>
        <div className="col-sm-6">
          <PressImg
            src={brpapershape}
            className="img-responsive"
            title="BR 2016"
          />
        </div>
        <div className="col-sm-6">
          <PressImg
            src={ardbuffet3}
            className="img-responsive"
            title="ARD-BUFFET 2016"
          />
        </div>
        <div className="col-sm-6">
          <PressImg
            src={ardbuffet}
            className="img-responsive"
            title="ARD-BUFFET 2015"
          />
        </div>
        <div className="col-sm-6">
          <PressImg
            src={ardbuffet2016}
            className="img-responsive"
            title="ARD-BUFFET 2016"
          />
        </div>
      </div>
    </div>
  </div>
);
