import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { PsLink } from '../common/PsLink';
import { sendContactForm } from '../state/contact/contactFormActions';
import GlobalMessages from '../common/GlobalMessages';
import { email, maxLength100, maxLength1000, required } from '../validation';
import PsTextarea from '../common/inputs/PsTextarea';
import { contactFormName } from '../state/contact/contactFormSelectors';
import PsEmail from '../common/inputs/PsEmail';
import { getTextBySelectedLang } from '../data/lang';
import Lang from '../common/Lang';
import PsMeta from '../common/PsMeta';

function Contact({ dispatch, handleSubmit }) {
  return (
    <div>
      <PsMeta
        title={getTextBySelectedLang('Kontakt', 'Contact')}
        description={getTextBySelectedLang(
          'Bei Fragen oder Wünschen verwende doch einfach mein Kontaktformular hier.',
          'For questions or wishes please use the contact form here.'
        )}
      />

      <div className="container">
        <h1 className="page-header">
          <Lang de="Kontakt" en="Contact" />
        </h1>

        <div className="row">
          <div className="col-md-12">
            <div className="col-md-5">
              <ul>
                <li>
                  <Lang
                    de="Du hast eine Frage zu PaperShape?"
                    en="Do you have a question about PaperShape?"
                  />
                </li>
                <li>
                  <Lang
                    de="Du hast eine Frage zu deiner Bestellung?"
                    en="Do you have a question regarding your order?"
                  />
                </li>
                <li>
                  <Lang
                    de="Du hast einen Fehler entdeckt?"
                    en="Did you notice an error?"
                  />
                </li>
                <li>
                  <Lang
                    de="Du möchtest einfach etwas los werden?"
                    en="Do you want to share a thought?"
                  />
                </li>
              </ul>
              <p>
                <Lang
                  de="Außerdem erreichst du mich unter meiner Nürnberger Festnetznummer 0911-36069244."
                  en="Besides, you can reach me at my Nuremberg landline number 0911-36069244."
                />
              </p>
            </div>

            <form
              onSubmit={handleSubmit((formValues) =>
                dispatch(sendContactForm(formValues))
              )}>
              <div className="col-md-offset-1 col-md-6">
                <Field
                  name="message"
                  placeholder={getTextBySelectedLang(
                    'Deine Nachricht an mich..',
                    'Your message to me...'
                  )}
                  label={<Lang de="Nachricht" en="Message" />}
                  rows="10"
                  component={PsTextarea}
                  validate={[required, maxLength1000]}
                />

                <Field
                  name="email"
                  label={<Lang de="Deine E-Mail" en="Your e-mail" />}
                  component={PsEmail}
                  placeholder="E-Mail"
                  validate={[required, maxLength100, email]}
                />

                <GlobalMessages />

                <p
                  className="form-control-static"
                  style={{ fontSize: '11px', paddingBottom: '10px' }}>
                  <Lang
                    de="Die abgesendeten Daten werden nur zum Zweck der Bearbeitung deines Anliegens verarbeitet. Weitere Informationen findest du in unserer"
                    en=""
                  />
                  <PsLink to={'/dataprivacy'} hash="kontaktaufnahme">
                    <Lang de=" Datenschutzerklärung." en="" />
                  </PsLink>
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <button type="submit" className="btn btn-primary btn-block">
                      <Lang de="Nachricht absenden" en="Send message" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = () => {
  return {};
};

const Connected = connect(mapStateToProps)(Contact);

export default reduxForm({
  form: contactFormName,
  destroyOnUnmount: false,
})(Connected);
