import React from 'react';
import { Route } from 'react-router-dom';
import Textde from './de';
import Texten from './en';
import { getTextBySelectedLang } from '../../data/lang';
import Lang from '../../common/Lang';
import PsMeta from '../../common/PsMeta';

export default () => (
  <div>
    <PsMeta
      title={getTextBySelectedLang('Datenschutzerklärung', 'Privacy Policy')}
      noindex
    />

    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1 className="page-header">
            <Lang de="Datenschutzerklärung" en="Privacy Policy" />
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Route path="/de" component={Textde} />
          <Route path="/en" component={Texten} />
        </div>
      </div>
    </div>
  </div>
);
