export default {
  url: 'schiff-basteln',
  countermark: 'vg07.met.vgwort.de/na/2424539bc03349afbc14af79aeb4fa35',
  imageFolder: 'schiff',

  name: {
    de: 'Schiff',
    en: 'boat',
  },
  id: '3-15-blog',
  pdf: {
    price: 3.9,
    priceThirdCountry: 3.9,
    image: {
      filename: {
        de: 'schiff-basteln-papier.jpg',
        en: 'schiff-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Papierschiffchen basteln',
        en: 'Make a paper boat ',
      },
    },
  },

  meta_title: {
    de: 'Einfaches Schiff basteln mit Papier (Vorlage).',
    en: '',
  },
  meta_description_google: {
    de:
      'So einfach lässt sich ein stabiles Schiff basteln mit Tonkarton. Du kannst es bemalen oder in vercshiedenen Farben ausdrucken.',
    en: '',
  },
  meta_description_social: {
    de:
      'Aus Tonkarton ein Papierschiff basteln. Weil es so stabil ist, lässt es sich super als maritime Deko aufstellen. Endlich eine Alternative zu den trendy Origami-Boote aus Keramik.',
    en: '',
  },
  image_main: {
    filename: {
      de: 'schiff-basteln-papier.jpg',
      en: 'schiff-basteln-papier.jpg',
    },
    alt_text: {
      de: 'Schiff aus Papier',
      en: '',
    },
  },
  h1: {
    de: 'Einfaches Schiff basteln aus Papier',
    en: '',
  },
  description: {
    de: `
      <p>Wer kennt sie nicht aus seiner Kindheit: die Papierschiffchen zum Falten. Sie sind schnell aus Kopierpapier gemacht und schwimmen 
      sogar auf dem Wasser.
      </p> 
      <p>In diesem DIY Post möchte ich euch nun zeigen, wie ihr genau so ein Schiff basteln könnt, das aber deutlich stabiler ist.
      Inspiriert haben mich die Boote aus Keramik und Ton im Origami-Look. Wer selber zur Schere greifen mag, kann sich nun 
      mit unserer Vorlage ein eigenes Deko Schiff aus Tonkarton falten. </p>
      <p>In der Optik habe ich es natürlich an das Schiffchen aus unserer Kindheit angelehnt - ein wenig Nostalgie muss sein.
        </p>
    
     
    <p>Das Schiff hat eine Größe von HBT 6 x 21 x 10 cm.</p>`,
    en: ``,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },

  stepsTitle: {
    de: 'Schiff basteln aus Tonkarton - Anleitung mit Vorlage',
    en: '',
  },

  downloadName: 'schiff_papershape.pdf',
  materials: [
    {
      de: '1 DINA4 Papier  (bis zu 130g/m²)',
      en: '1 cardstock paper',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
};
