export default {
  id: '1-13-sfk',
  category: ['animal_sculpture'],
  name: {
    de: 'Lama',
    en: 'Llama',
  },
  subtitle: {
    de: 'B19 x H48 x T23 cm',
    en: 'W19 x H48 x D23 cm',
  },
  meta_title: {
    de: 'Süßes DIY Lama aus Papier - Gestalte deine Wandkunst selbst ',
    en: 'Papercraft llama - Create your unique wall art',
  },
  meta_description_google: {
    de: 'Als Lamaliebhaber gar nicht so einfach ein Lama zu halten? Dann haben wir jetzt die vegane Papier Version: Gestalte deinen eigenes Origami Lama für dein zuhause.',
    en: 'Ever wanted to have your own lama? Now its time to make your own papercraft alpaca! Get the template here.',
  },
  meta_description_social: {
    de: 'DIY Papierskulptur Lama für deine eigenen vier Wände - Wandkunst für Lamaliebhaber #lama #bastelnmitpapier #papershape',
    en: 'Papercraft llama for your wall art - be creative and decorate it with embellishments #papercraft #llama #paper #diy',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?    
    - 10 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -10 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 10 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 10 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 10 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 10 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfklama/lama-3d-papier-wand-trophaee.jpg',
        en: 'products/sfklama/lama-3d-papier-wand-trophaee.jpg',
      },
      alt_text: {
        de: 'DIY 3d Lama aus Papier als Wanddeko #papercraft #3dpapermodel #origami #llama #alpaca',
        en: 'Papercraft llama for wall decor #diypapercraft #origami #3dpapermodel #origami #llama #alpaca',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfklama/lama-3d-papier-wand-trophaee.jpg',
        en: 'products/sfklama/lama-3d-papier-wand-trophaee.jpg',
      },
      alt_text: {
        de: 'DIY Wandtrophäe Lama aus Papier - Vorlage downloaden #origami #wanddeko #bastelnmitpapier #papershape #lama',
        en: 'Papercraft llama Make your own wall art #papercraft #diy #wallart #papershape #llama #alpaca',
      },
    },
    colors: {
      color1: {
        de: 'Ohren & Körper',
        en: 'Ears & body',
      },
      color2: {
        de: 'Gesicht',
        en: 'Face',
      },
    },
    disableColors: {
      color2: ['bunt', 'schwarz'],
      color1: ['schwarz'],
    },
    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
