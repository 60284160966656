import React from 'react';
import { connect } from 'react-redux';
import OrderNumber from './OrderNumber';
import OrderSumGross from './OrderSumGross';
import Lang from '../common/Lang';

function PsBankAccount() {
  return (
    <div>
      <div className="row" style={{ fontSize: 16, padding: '25px 0' }}>
        <div
          className="hidden-xs col-sm-offset-3 col-sm-3"
          style={{ textAlign: 'right' }}>
          <Lang de="Kontoinhaber:" en="Account holder:" />
          <br />
          IBAN:<br />
          BIC:<br />
          Bank:<br />
          <Lang de="Verwendungszweck:" en="reason for payment:" />
          <br />
          <Lang de="Betrag: " en="Amount: " />
          <br />
        </div>
        <div className="hidden-xs col-sm-6" style={{ fontWeight: 'bold' }}>
          PaperShape UG & Co. KG <br />
          IBAN: DE96100101234267910643 <br />
          Bank: Olinda <br />
          <OrderNumber />
          <br />
          <OrderSumGross />
          <br />
        </div>
        <div className="visible-xs col-xs-12">
          PaperShape UG & Co. KG <br />
          IBAN: <strong>DE96100101234267910643</strong>
          <br />
          Bank: Olinda <br />
          <Lang de="Verwendungszweck:" en="reason for payment:" />{' '}
          <strong>
            <OrderNumber />
          </strong>
          <br />
          <Lang de="Betrag: " en="Amount: " />{' '}
          <strong>
            <OrderSumGross />
          </strong>
          <br />
        </div>
      </div>
    </div>
  );
}

export default connect()(PsBankAccount);
