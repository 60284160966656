import materials from './materials';

export default {
  url: '3d-origami-panther',
  countermark: 'vg07.met.vgwort.de/na/4691f6d506b14fe5a3455267c496f153',
  imageFolder: 'panther',
  meta_title: {
    de: 'DIY 3D Origami Panther im Trophäenlook',
    en: 'How to make a 3d origami panther from paper',
  },
  meta_description_google: {
    de:
      'Origami Pantherkopf als Trophäe basteln. Mithilfe der Druckvorlage, der Anleitung und ein wenig Papier bastelt ihr eure eigene DIY Wanddeko.',
    en: ' ',
  },
  meta_description_social: {
    de: ' 3d origami panther trophäe aus papier #papershape #papercraft ',
    en: ' ',
  },
  image_main: {
    filename: {
      de: 'diy-3d-origami-panther-trophaee.jpg',
      en: 'diy-3d-origami-panther-trophaee.jpg',
    },
    alt_text: {
      de: '3d origami panther trophäe aus papier #papershape ',
      en: '3d origami panther trophy from paper #papershape  ',
    },
  },
  h1: {
    de: 'DIY 3D Origami Panther im Trophäenlook',
    en: 'How to make a 3d origami panther from paper  ',
  },
  description: {
    de: `
      <p> Mit diesem 3d Origami Panther aus Papier begann meine Reise zur Gründung von PaperShape. Damals und das war 2014 wusste ich davon aber noch nichts - es war ein Hobby.</p>
      <p>In dieser Anleitung seht ihr also meine allererste Papiertrophäe, die ich am 13. März 2014 auf meinem damaligen Blog Kreativbühne vorgestellt habe. 
      Sie ist im Vergleich zu den aktuellen Tierköpfen sehr facettenreich und im Zusammenbau sehr anspruchsvoll - kein Vergleich zu meinen aktuellen Modellen.</p>
           

Die Druckvorlage zu diesem Panther gibt es leider nicht mehr online. Sie ist zu facettenreich und wenig intuitiv. Jemand, der so viel Zeit in die
Trophäe investiert, sollte mit dem Ergebnis auch zufrieden sein.
           
    `,
    en: `
      
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [],
  stepsBackgroundColor: 'rgb(243, 243, 243)',
  stepsTitle: {
    de: 'Wie bastel ich einen 3D Panther als Trophäe aus Papier',
    en: 'How do I make a 3d paper panther',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_vorlage.jpg',
          en: '01_vorlage.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Graues Papierkarton mit der Vorlage bedrucken. Die einzelnen Teile ausschneiden. Alle durchgezogenen Linien müssen geschnitten werden. 
          An den gestrichelten Linien muss geknickt werden. Ich habe mit der Küchenschere alle groben Teile ausgeschnitten und die engen Stellen 
          mit einer Nagelschere. Das kann man natürlich auch gerne mit einem Cutter machen.`,
        en: `  `,
      },
    },
    {
      image: {
        filename: {
          de: '02_falzen.jpg',
          en: '02_falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de:
          'Ihr werdet merken, dass sich 300g Papierkarton nicht einfach so falten lässt. Deswegen müsst ihr den Karton an den gestrichelten Linien zuerst mit einem stumpfen Gegenstand nachziehen. Danach könnt ihr diese Stellen ganz einfach falten. Der Knick muss also nach innen zu der Beschriftung hin erfolgen.',
        en: ' ',
      },
    },

    {
      image: {
        filename: {
          de: '03_knicken.jpg',
          en: '03_knicken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de:
          'Nun alle Teile an den vorgesehenen Stellen falten. Ich habe darauf geachtet, dass die Beschriftung nach innen zeigt. Das hat den Vorteil, dass am Ende die kleinen Zahlen von außen nicht sichtbar sind. Zudem habe ich die einzelnen Teile genauso gruppiert (pro Seite) wie sie auch ausgedruckt worden sind. Das hat den Vorteil, dass ihr beim Zusammenkleben nicht ewig nach der passenden Nr. suchen müsst. Ihr könnt am PC einfach in der PDF nach der Zahl suchen. Auf jeder Lasche steht eine Zahl und zwei gleiche Zahlen müssen zusammengeklebt werden (z.B. 1 und 1).',
        en: ` `,
      },
    },

    {
      image: {
        filename: {
          de: '04_kleben.jpg',
          en: '04_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      text: {
        de: `Hier wollte ich euch zeigen, wie sich die Teile langsam zusammensetzen. Ich habe doppelseitiges Klebeband verwendet.
           Es ist sehr gut, weil die Kanten schön sauber bleiben und ihr  sie bei Bedarf noch mal vorsichtig auseinander kleben könnt.
            Ich glaube dieser Vorgang benötigt keiner weiteren Erklärung, oder? Am Ende habe ich in den Rücken mit der Nagelschere ein 
            kleines Loch reingebohrt, sodass ich es dann am Nagel aufhängen konnte. Voilà! `,

        en: ``,
      },
    },
  ],

  materials: [
    {
      de:
        '10 Papierbögen in grau (300g, Achtung nicht zu dunkel, damit ihr nach dem Druck die Linien noch seht) ',
      en: '10 paper sheets in grey (300g)',
    },

    {
      de: 'doppelseitiges Klebeband',
      en: 'self-adhesive tape',
    },

    {
      de: 'Stopfnadel',
      en: 'darning needle',
    },

    materials.scissors,
    materials.ruler,

    materials.mat,
  ],
};
