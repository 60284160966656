import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getTextBySelectedLang } from '../data/lang';
import { createNewsletterSignIn } from '../state/newsletter/newsletterFormActions';
import { newsletterFormName } from '../state/newsletter/newsletterFormSelectors';
import { email, maxLength100, required } from '../validation';
import { trackNewsletter } from './customga';
import GlobalMessages from './GlobalMessages';
import PsEmail from './inputs/PsEmail';
import PsInput from './inputs/PsInput';
import Lang from './Lang';
import { PsLink } from './PsLink';

function Newsletter({ handleSubmit, dispatch, text, galabel }) {
  return (
    <div className="row newsletter text-center">
      <h5
        style={{
          marginTop: 0,
          fontSize: 30,
        }}
      >
        <Lang de="Newsletter" en="Newsletter" />
      </h5>
      <form
        onSubmit={handleSubmit((formValues) =>
          dispatch(createNewsletterSignIn(formValues))
        )}
      >
        <label
          htmlFor="EMAIL"
          className="col-xs-12 text-center"
          style={{
            fontWeight: 'normal',
          }}
        >
          {text ? (
            text
          ) : (
            <Lang
              de="Erhalte exklusive DIY Vorlagen aus Papier und Infos zu unseren Produkten."
              en="Get exclusive DIY paper craft templates and news about our products"
            />
          )}
        </label>

        <p
          className="form-control-static"
          style={{ fontSize: '11px', paddingBottom: '10px' }}
        >
          <Lang
            de="Informationen zur Einwilligung, dem Versand mit Brevo und deinem
        Widerrufsrecht kannst du"
            en="Information about consent, shipping with Brevo and your right to withdraw you may"
          />
          <PsLink to={'/dataprivacy'} hash="newsletter">
            <Lang de=" hier nachlesen." en="read here" />
          </PsLink>
        </p>
        <div className="form-group col-xs-12">
          <Field
            id="email"
            name="email"
            className="form-control text-center"
            component={PsEmail}
            placeholder={getTextBySelectedLang(
              'E-Mail eintragen',
              'insert e-mail'
            )}
            validate={[required, maxLength100, email]}
          />

          <GlobalMessages />
        </div>
        <div className="form-group col-xs-12 text-center">
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}{' '}
          <div
            style={{
              position: 'absolute',
              left: '-5000px',
            }}
          >
            <Field name="surname" component={PsInput} tabIndex={-1} />
          </div>
          <button
            type="submit"
            id="papershapesubscribe"
            name="subscribe"
            onClick={trackNewsletter(galabel)}
            className="btn btn-primary"
          >
            <Lang de="absenden" en="send" />
          </button>
        </div>
      </form>
    </div>
  );
}

const Connected = connect()(Newsletter);

export default reduxForm({
  form: newsletterFormName,
  destroyOnUnmount: false,
})(Connected);
