import React from 'react';
import ColorSelect from './ColorSelect';
import PsLabel from './PsLabel';

export default ({ label, meta, ...rest }) => {
  return (
    <PsLabel
      label={label}
      meta={meta}
      classNameLabel="col-sm-4"
      classNameField="col-sm-8">
      <ColorSelect className="form-control" {...rest} />
    </PsLabel>
  );
};
