import materials from './materials';

export default {
  url: '3d-ahornblatt-papier',
  countermark: 'vg07.met.vgwort.de/na/0a7e8c1bd65b4ed9b5aa05b40e360c65',
  imageFolder: 'ahornblatt',

  name: {
    de: 'Ahornblatt',
    en: 'Maple leaf',
  },
  id: '3-29-blog',
  pdf: {
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'content/ahornblatt/3d-ahornblatt-papier.jpg',
        en: 'content/ahornblatt/3d-ahornblatt-papier.jpg',
      },
      alt_text: {
        de: 'Wanddeko aus Papier: Ahornblatt ',
        en: 'Wall decoration from paper: maple leaf',
      },
    },
  },

  meta_title: {
    de: '3D-Ahornblatt basteln als Wanddeko',
    en: 'Deep blue wall decor: 3D maple leaf from paper ',
  },
  meta_description_google: {
    de: 'Als Herbstdeko darf es mal etwas anderes als ein Türkranz oder Naturmaterialien sein? Wie wäre es mit diesem 3d-Ahornblatt als Wanddeko in Schieferblau? Mit der kostenlosen Vorlage von papershape gelingt sie dir im handumdrehen.',
    en: 'If you wanna try something new for your fall decoration you have to print out the template for this adorabel 3d maple leaf and start immediately paper crafting. The result will look amazing as  wall decor. ',
  },
  meta_description_social: {
    de: 'Herbstdeko für die Wand basteln: modernes 3d-Ahornblatt im low-poly-Look ganz einfach mit der Vorlage von papershape gestalten #papershape  ',
    en: 'fall craft: 3d maple leaf as cool wall decor - template #papershape  ',
  },
  meta_images_social: [
    {
      de: '3d-ahornblatt-papier.jpg',
      en: '3d-maple-leaf-paper.jpg',
    },
  ],
  image_main: {
    filename: {
      de: '3d-ahornblatt-papier.jpg',
      en: '3d-maple-leaf-paper.jpg',
    },
    alt_text: {
      de: 'Herbstdeko für die Wand basteln: modernes 3d-Ahornblatt im low-poly-Look ganz einfach mit der kostenlosen Vorlage von papershape gestalten #papershape ',
      en: 'fall craft: 3d maple leaf as cool wall decor -  template #papershape  ',
    },
  },
  h1: {
    de: '3D-Ahornblatt basteln mit Papier als Wanddeko',
    en: 'Deep blue wall decor: 3D maple leaf from paper  ',
  },
  description: {
    de: `
      <p>
      Als Herbstdeko darf es mal etwas anderes als ein herbstlicher Türkranz oder Naturmaterialien sein?
      </p>
      Wie wäre es mit diesem 3d-Ahornblatt als Wanddeko in Schieferblau? Ihr könnt dieses Ahornblatt in verschiedenen Farben basteln und in schwarz zum Beispiel erinnert es nicht an Herbst, sondern an eine monochrome Wanddeko. Dieses Ahornblatt wird dem einen oder anderen bekannt vorkommen. Denn ich habe ihn letztes Jahr beim ARD-Buffet vorgestellt. Unten verlinke ich euch das Video zur Anleitung, das auch gleichzeitig meinen Gastauftritt
      dokumentiert.
          
      
      <p>
    Passend zum tiefen Blau des Ahornblattes passen diese <a href="/de/blog/eicheln-papier-herbstdeko/">mocca-farbenen Papier Eicheln</a>, die ihr in einer Schale auffüllen und super schön unter dem Ahornbild aufstellen könnt.
      </p>
    `,
    en: `
      <p>
      If you wanna try something new for your fall decoration you have to print out the template for this adorabel 3d maple leaf and start immediately paper crafting. The result will look amazing as wall decor. Personally, I love trying
      new crafts for fall and this one is definitely different to the common decor with acorns or pumpkins.
      </p>
    
      <p>
      And if you want to spice up your fall decor you should try these lovely <a href="/en/blog/eicheln-papier-herbstdeko/">cappuccino-colored paper acorns</a>.
        </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '3d-ahornblatt-papier-herbstdeko.jpg',
          en: '3d-maple-leaf-fall-craft.jpg',
        },
        alt_text: {
          de: 'Herbstdeko für die Wand basteln: modernes 3d-Ahornblatt im low-poly-Look ganz einfach mit der kostenlosen Vorlage von papershape gestalten #papershape ',
          en: 'fall craft: 3d maple leaf as cool wall decor  ',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: '3d-ahornblatt-basteln.jpg',
          en: '3d-maple-leaf-fall-paper.jpg',
        },
        alt_text: {
          de: 'Herbstdeko für die Wand basteln: modernes 3d-Ahornblatt im low-poly-Look ganz einfach mit der kostenlosen Vorlage von papershape gestalten #papershape ',
          en: 'fall craft: 3d maple leaf as cool wall decor  ',
        },
      },
    },
  ],

  stepsTitle: {
    de: 'Wie bastel ich ein 3d-Ahornblatt aus Papier als Wanddeko',
    en: 'How do I make a 3d maple leaf from paper as wall decor',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_ausschneiden.jpg',
          en: '01_ausschneiden.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ausschneiden',
          en: 'Step: Cutting',
        },
      },
      text: {
        de: `Drucke die Vorlagen S. 1-3 aus und schneide die abgedruckten Papierteile entlang jeder durchgezogenen Linien aus. Drucke die Vorlage für das Ahornblatt auch aus. Entweder auf einem DIN A3 (S. 4) oder auf 2 Blättern DIN A4 (S. 6+7), die du miteinander verklebst. `,
        en: `Print out the template on paper of your choice. Cut out the paper parts (A, B, C, ...) by cutting along the solid lines.  `,
      },
    },
    {
      image: {
        filename: {
          de: '02_falten.jpg',
          en: '02_falten.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Falzen',
          en: 'Step: Folding',
        },
      },
      text: {
        de: `Legt nun ein Lineal an alle gestrichelten Linien an und fahrt mit einem stumpfen dünnen Gegenstand die Linien mit Druck
        ab. Legt ein beliebiges Papierteil so vor euch hin, dass ihr die Zahlen und
        Buchstaben seht. Knickt die kurz gestrichelten Linien nach innen,
        d.h. zu euch hin (Talfalte). Diesmal gibt es nur eine Art an gestrichelten Linien.`,
        en: 'Place a ruler on the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines. Start with any paper part and place it in front of you. The printed side should be facing you. Subsequently fold all the dotted lines inwards, which means to fold up (short dotted lines)',
      },
    },

    {
      image: {
        filename: {
          de: '03_kleben.jpg',
          en: '03_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      text: {
        de: `Jetzt wird jedes der Drei- bzw. Vielecke separat verklebt. Dazu findest du je eine Kante pro Vieleck vor, die keine Lasche hat. Diese Kante verklebst du mit der
         angrenzenden Lasche, sodass das 3D Vieleck entsteht. Klebe auf diese Lasche ein Klebepad. Du kannst bei Papier bis zu 160g auch normalen Klebstoff verwenden. `,
        en: `Glue each polygon separately. `,
      },
    },

    {
      image: {
        filename: {
          de: '04_vorlageaufkleben.jpg',
          en: '04_vorlageaufkleben.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together ',
        },
      },
      text: {
        de: `Die Vielecke werden nun in nummerischer Reihenfolge auf die Vorlage aufgeklebt. Verwende dazu entweder Buchbindeleim, Sekundenkleber oder normalen Kleber – je nach Papierstärke. 
        `,
        en: `Use double-sided tape or any strong glue to stick the polygons in numerical oder on the maple leaf template.`,
      },
    },
  ],
  video: {
    title: {
      de: 'Hier bei meinem ARD-Buffett Auftritt seht ihr wie das 3d-Ahornblatt entsteht',
      en: 'Learn how to craft the 3d maple leaf (German TV-Show but the instructions are easy to follow visually)',
    },
    description: {
      de: ``,
      en: ``,
    },
    caption: {
      de: `3d-Ahornblatt aus Papier`,
      en: `3d maple leaf from paper`,
    },
    videoid: 'flJnu7lFHeo',
  },
  downloadName: 'ahornblatt-maple-leaf-wall-decor_papershape.pdf',
  materials: [
    {
      de: '3 DINA4 Papier uni oder gemustert (160-300g/m²)',
      en: '3 cardstock paper uni or with pattern (8.5in x 11in) (weight: 65lb-110lb / 176gsm-300gsm)',
    },
    {
      de: '1 DINA3 Papier für das Ahornblatt als Vorlage oder 2 DINA4, die ihr zusammenklebt (160-300g/m²)',
      en: '1 cardstock paper DINA3 for the maple leaf background (weight: 65lb-110lb / 176gsm-300gsm)',
    },
    materials.glue,
    materials.scissors,
    materials.ruler,
    materials.awl,
    materials.mat,
    {
      de: 'starken Flüssigkleber',
      en: 'strong glue',
    },
  ],
};
