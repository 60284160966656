export default {
  id: '1-18-sfk',
  category: ['animal_sculpture'],
  name: {
    de: 'Steinbock',
    en: 'Capricorn',
  },
  subtitle: {
    de: 'B33 x H57 x T30 cm',
    en: 'W33 x H57 x D30 cm',
  },
  meta_title: {
    de: '3D Steinbock aus Papier - Papierkunst als Wanddeko',
    en: 'Papercraft capricorn: make your own wall art ',
  },
  meta_description_google: {
    de: 'Bastel deinen eigenen 3D Steinbock aus Papier. Egal, ob als Sternzeichen, Wanddeko oder für Okkultismus-Fans, mit diesem DIY Kit verzauberst du deine Wände.',
    en: 'Make your own 3D capricorn from paper with our DIY kit.',
  },
  meta_description_social: {
    de: '3D Steinbock aus Papier zum Selberbasteln #papercraft #okkultismus #bastelnmitpapier #steinbocksternzeichen #papershape ',
    en: '3d #papercraft capricorn animal head - #diy #capricorn # #papershape #occultism',
  },
  description: {
    de: `  Was ist drin im DIY Kit?    
    - 15 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -15 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },

  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 15 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 15 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 15 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 15 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfksteinbock/tierskulptur-steinbock-papershape-papercraft.jpg',
        en: 'products/sfksteinbock/tierskulptur-steinbock-papershape-papercraft.jpg',
      },
      alt_text: {
        de: 'DIY 3d Steinbock aus Papier als Wanddeko',
        en: 'Papercraft capricorn for wall decor ',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },

  colorinspirations: [
    {
      caption: {
        de: 'hellgrau',
        en: 'light grey',
      },
      image: {
        filename: {
          de: 'products/sfksteinbock/tierskulptur-steinbock-papershape-papercraft.jpg',
          en: 'products/sfksteinbock/tierskulptur-steinbock-papershape-papercraft.jpg',
        },
        alt_text: {
          de: '3D Einhorn Kopf aus Papier zum basteln - DIY Papierskulptur als Wanddeko #papershape',
          en: '3d unicorn trophy from paper - diy papersculpture as unique wall decor #papershape ',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfksteinbock/tierskulptur-steinbock-papershape-papercraft.jpg',
        en: 'products/sfksteinbock/tierskulptur-steinbock-papershape-papercraft.jpg',
      },
      alt_text: {
        de: '3d Papierskulptur Steinbock als Wanddeko -  #diy #bastelnmitpapier #steinbock #papershape',
        en: 'Papercraft capricorn animal head - #aries #papercraft #capricorn #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Kopf & Ohren',
        en: 'Head & ears',
      },
      color2: {
        de: 'Hörner',
        en: 'Horns',
      },
    },
    disableColors: {
      color2: ['schwarz'],
      color1: ['schwarz'],
    },
    price: 29.9,
    priceThirdCountry: 28.9,
  },
};
