import React from 'react';
import { connect } from 'react-redux';
import Currency from './Currency';
import { getOrder, getOrderSumGross } from '../state/order/orderSelectors';

function OrderSumGross({ orderSum }) {
  return <Currency value={orderSum} />;
}

const mapStateToProps = (state, props) => {
  const backendOrder = getOrder(state);
  return {
    orderSum: props.preorder
      ? getOrderSumGross(state)
      : backendOrder ? backendOrder.orderSumGross : 0,
  };
};

export default connect(mapStateToProps)(OrderSumGross);
