export default {
  id: '1-17-sfk',
  category: ['home_decor'],
  name: {
    de: 'Vogelhaus mit Vögeln',
    en: 'Bird house with birds',
  },
  subtitle: {
    de: 'B12 x H17 x T12 cm',
    en: 'W12 x H17 x D12 cm',
  },
  meta_title: {
    de: 'Vogelhaus basteln aus Papier als Wanddeko oder Hochzeitsgeschenk',
    en: '3D Papercraft bird house with low poly birds ',
  },
  meta_description_google: {
    de: 'Bastel ein 3D Vogelhaus aus Papier als Wanddeko oder als Geschenkbox für eine Hochzeit oder zum Valentinstag. Die Vorlage kannst du direk downloaden. ',
    en: 'Make this lovely 3D Papercraft bird house with cute birds',
  },
  meta_description_social: {
    de: 'Bastel ein 3D Vogelhaus aus Papier als Wanddeko oder als Geschenkbox für eine Hochzeit oder zum Valentinstag. Die Vorlage kannst du direk downloaden. ',
    en: 'Make this lovely 3D Papercraft bird house with cute birds',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?    
    - 7 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität (darin sind 3 Vögel und die Hochzeitselemente enthalten)
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -7 printed DINA4 cardboard sheets for the perfect stability (including 3 birds and wedding elements)
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 5 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 5 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 5 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 5 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkvogelhaus/vogelhaus-basteln-papier.jpg',
        en: 'products/sfkvogelhaus/vogelhaus-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Vogelhaus basteln aus Papier #papercraft #3dpapermodel #origami #bastelnmitpapier',
        en: 'Papercraft bird house from paper for wall decor #diypapercraft #origami #3dpapermodel #origami',
      },
    },
    price: 9.9,
    priceThirdCountry: 9.9,
  },

  colorinspirations: [
    {
      caption: {
        de: 'Weißes Haus mit weißen Vögeln',
        en: 'White House with white birds',
      },
      image: {
        filename: {
          de: 'products/sfkvogelhaus/vogelhaus-basteln-papier.jpg',
          en: 'products/sfkvogelhaus/vogelhaus-basteln-papier.jpg',
        },
        alt_text: {
          de: 'Vogelhaus basteln aus Papier #papercraft #3dpapermodel #origami #bastelnmitpapier',
          en: 'Papercraft bird house from paper for wall decor #diypapercraft #origami #3dpapermodel #origami',
        },
      },
    },
    {
      caption: {
        de: 'Hochzeits-Edition (im DIY-Set: die Schleife, das Herz & die Blume haben die gleiche Farbe wie das Haus)',
        en: 'Wedding-Edition (in the DIY-kit: the bow, heart and flower have the same color as the house)',
      },
      image: {
        filename: {
          de: 'products/sfkvogelhaus/vogelhaus-hochzeit-basteln.jpg',
          en: 'products/sfkvogelhaus/vogelhaus-hochzeit-basteln.jpg',
        },
        alt_text: {
          de: 'Vogelhaus basteln aus Papier #papercraft #3dpapermodel #origami #bastelnmitpapier',
          en: 'Papercraft bird house from paper for wall decor #diypapercraft #origami #3dpapermodel #origami',
        },
      },
    },
  ],

  choose: {
    image: {
      filename: {
        de: 'products/sfkvogelhaus/vogelhaus-basteln-papier.jpg',
        en: 'products/sfkvogelhaus/vogelhaus-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Vogelhaus basteln aus Papier #papercraft #3dpapermodel #origami #bastelnmitpapier',
        en: 'Papercraft bird house from paper for wall decor #diypapercraft #origami #3dpapermodel #origami',
      },
    },
    colors: {
      color1: {
        de: 'Haus',
        en: 'House',
      },
      color2: {
        de: 'Vögel',
        en: 'Birds',
      },
    },
    disableColors: {
      color2: ['schwarz'],
      color1: ['schwarz'],
    },
    price: 19.9,
    priceThirdCountry: 19.9,
  },
};
