import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { navigateTo } from '../data/lang';
import { PsLink } from './PsLink';
import { Navbar } from 'react-bootstrap';
import Lang from './Lang';
import {
  AboutMeLink,
  BlogLink,
  CartLink,
  ContactLink,
  FaqLink,
  PressLink,
} from './Links';
import cartIcon from '../styles/images/icons/cart-icon.png';

export function Navigation() {
    const location = useLocation();
    const [expanded, setExpanded] = useState(false);
    const toggle = () => setExpanded(false);

    return (
      <Navbar
        fixedTop
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}>
        <Navbar.Header>
          <PsLink to={'/'} className="logo" style={{ verticalAlign: 'middle' }}>
            papershape<span style={{ fontSize: 14 }}>®</span>
          </PsLink>
          <Navbar.Toggle />
          <SwitchLanguage
            location={location}
            className="pull-right visible-xs"
            style={{ width: '30px', margin: '15px' }}
          />
          <CartLink className="pull-right visible-xs">
            <img
              src={cartIcon}
              alt="cart"
              style={{ width: '30px', margin: '15px' }}
            />
          </CartLink>
        </Navbar.Header>
        <Navbar.Collapse>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <PsLink to={'/'} onClick={toggle}>
                <Lang de="shop" en="shop" />
              </PsLink>
            </li>
            <li>
              <BlogLink onClick={toggle}>
                <Lang de="blog" en="blog" />
              </BlogLink>
            </li>
            <li>
              <AboutMeLink onClick={toggle}>
                <Lang de={`Über mich`} en={`about`} />
              </AboutMeLink>
            </li>

            <li>
              <ContactLink onClick={toggle}>
                <Lang de="kontakt" en="contact" />
              </ContactLink>
            </li>
            <li>
              <PressLink onClick={toggle}>
                <Lang de="presse" en="press" />
              </PressLink>
            </li>
            <li>
              <FaqLink onClick={toggle}>FAQ</FaqLink>
            </li>
            <li className="hidden-xs">
              <CartLink>
                <img src={cartIcon} alt="cart" style={{ width: '15px' }} />
              </CartLink>
            </li>
            <li className="hidden-xs">
              <SwitchLanguage location={location} />
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    );
}

function SwitchLanguage({ location, className, style }) {
  const switchLang = navigateTo();
  const pathname = location.pathname;
  return (
    <a
      className={className}
      style={style}
      href={
        switchLang === 'de' && pathname === '/en/'
          ? '/'
          : '/' +
            switchLang +
            (pathname.length > 4 ? '/' + pathname.substring(4) : '/')
      }>
      <span className="lang-sm" lang={switchLang} />
    </a>
  );
}
