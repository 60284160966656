const getSelectedLang = () => {
  if (window.location.pathname.indexOf('/en') === 0) {
    return 'en';
  }
  return 'de';
};
const selectedLang = getSelectedLang();

const navigateTo = () => {
  if (selectedLang === 'en') {
    return 'de';
  }
  return 'en';
};

const getTextBySelectedLang = (textDe, textEn) => {
  if (selectedLang === 'en') {
    return textEn;
  }
  return textDe;
};

export { navigateTo, selectedLang, getTextBySelectedLang };
