import React from 'react';
import knownAsEntries from '../../data/known';
import { getTextBySelectedLang, selectedLang } from '../../data/lang';

export default function KnownSelect({
  input: { onChange, name, value },
  className,
}) {
  return (
    <select name={name} onChange={onChange} value={value} className={className}>
      <option value="">
        {getTextBySelectedLang('Keine Auswahl', 'no choice')}
      </option>
      {Object.keys(knownAsEntries).map((key) => (
        <option value={key} key={key}>
          {knownAsEntries[key].bezeichnung[selectedLang]}
        </option>
      ))}
    </select>
  );
}
