import React from 'react';
import PsFieldError from './PsFieldError';

export default function PsCheckbox({ input, label, meta, placeholder, disabled }) {
  return (
    <div>
      <PsFieldError meta={meta} />
      <div className="checkbox">
        <label>
          <input
            {...input}
            type="checkbox"
            value={true}
            checked={input.value}
            disabled={disabled}
          />
          {label}
        </label>
      </div>
    </div>
  );
}
