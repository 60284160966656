export default {
  id: '1-03-sfk',
  category: ['animal_sculpture'],
  name: {
    de: 'Pferd',
    en: 'Horse',
  },
  subtitle: {
    de: 'B12 x H33 x T30 cm',
    en: 'W12 x H33 x D30 cm',
  },
  meta_title: {
    de: 'Pferd Papiertier',
    en: 'horse Paper Animal',
  },
  meta_description_google: {
    de: 'Der 3d Pferde Kopf aus Papier zum Aufhängen als Wanddeko verzaubert als Wanddeko jeden Raum. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The 3d paper horse head enchants each room with lots of space for creativity.  100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: '3d Pferd Trophäe aus Papier als Wanddeko- DIY Papierskulptur #papershape',
    en: '3d paper horse as wall decor - diy paper sculpture #papershape',
  },
  description: {
    de: `Durch die innovative Falttechnik entsteht das Origami Pferd fast von alleine. Besonders Kinder sind immer wieder fasziniert von der realitätsnahen Abbildung des 3D Pferdes. Das 3D Origami Pferd ist also nicht nur was für begeisterte Reitsportler, sondern auch ein Hingucker im Kinderzimmer. Außerdem eignet es sich wunderbar als Dekoration im Flur oder im Wohnzimmer.
    Kurz und knapp und live. Im Videotutorial zeige ich euch, wie ihr das Origami Pferd ganz einfach zu eurer einzigartigen Wandtrophäe machen könnt. Praktische Tipps sind inklusive.
    
    ANLEITUNG
    
    Schneide alle Papierteile entlang der durchgezogenen Linien mit einer Schere aus.
    Um das qualitativ hochwertige Papier einfach falten zu können, muss es vorerst vorgefalzt werden. Am einfachsten gelingt dies mit einem Buttermesser, einer Ahle oder Stricknadel und einem Lineal. Das Lineal an die gestrichelten Linien anlegen und mit dem Buttermesser und viel Druck am Lineal auf den Linien entlang fahren. 
    Anschließend werden die gestrichelten Linien je nach Markierung nach innen oder außen geknickt.
    Die Verklebung der Papierteile ist dank der Klebepads eine einfache und saubere Lösung. Die Pads einfach auf die Außenseite jeder Lasche kleben und anschließend in nummerischer Reihenfolge(1,2,3 etc.) verkleben. 
    Achtet darauf die Klebepads mit einem Abstand von ca. 2 mm zur Kante aufzukleben, damit sie später von außen nicht sichtbar sind. Schaue beim Verkleben immer von außen auf das Modell.
    
    DETAILS: Altersempfehlung ab 14 Jahren
    
    KOMPLETTES SET BEINHALTET
    
    7 DIN A4 Seiten Fotokarton Origami Pferd
    Doppelseitige Klebepads
    Detaillierte schrittweise Anleitung
    
    
    BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser

    `,
    en: `   
      The 3D origami horse is our bestseller! Using the innovative folding technique, the origami horse almost assembles on its own. Especially children are always fascinated by the realistic look of the 3D horse. The 3D origami horse is designed not only for avid equestrian athletes, but is also an eye-catcher in the children’s room. Moreover, it is a wonderful decoration in the hallway or living room. In a nutshell and live. In the video tutorial I will show you how you can easily make your unique wall trophy origami horse. Practical tips are included.
    
    ♥ STEP BY STEP
    
    To easily fold the high-quality paper, it must first be pre-folded. The easiest way to do so is with a butter knife, an awl or knitting needle and a ruler. Set the ruler to the dotted line and press with the butter knife along. Subsequently, bend the paper parts inward or outward following the instructions.
    
    The gluing of the paper parts is a simple and clean task thanks to the adhesive pads. Set the pads to the outer side of each tab and glue them in numerical order (1,2,3, etc.). Make sure you stick the pads at about 2 mm from the edge so that they are not visible from the outside later. While gluing watch the model from the outside.
    
    ♥ DETAILS
    
    Size (WxHxD) 12 x 33 x 30 cm
    Age recommendation from 14 years
    
    ♥ COMPLETE KIT INCLUDES
    
    9 DIN A4 cardboard sheets Origami Horse
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    ♥ REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife`,
  },

  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 7 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 7 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 7 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 7 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkpferd/3d-pferd-papier-tierkopf.jpg',
        en: 'products/sfkpferd/3d-pferd-papier-tierkopf.jpg',
      },
      alt_text: {
        de: 'DIY 3d Pferd aus Papier als Wanddeko',
        en: 'Papercraft horse for wall decor ',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },

  colorinspirations: [
    {
      caption: {
        de: 'schokobraun-chamois',
        en: 'blue-yellow',
      },
      image: {
        filename: {
          de: 'products/sfkpferd/3d-pferd-papier-tierkopf.jpg',
          en: 'products/sfkpferd/pferd-himmelblau-gelb.jpg',
        },
        alt_text: {
          de: '3d Pferd Trophäe aus Papier als Wanddeko- DIY Papierskulptur #papershape',
          en: '3d paper horse as wall decor - diy paper sculpture #papershape',
        },
      },
    },
    {
      caption: {
        de: 'himmelblau-gelb',
        en: 'schokobraun-teracotta',
      },
      image: {
        filename: {
          de: 'products/sfkpferd/pferd-himmelblau-gelb.jpg',
          en: 'products/sfkpferd/pferd-schokobraun-teracotta.jpg',
        },
        alt_text: {
          de: '3d Pferd Trophäe aus Papier als Wanddeko- DIY Papierskulptur #papershape',
          en: '3d paper horse as wall decor - diy paper sculpture #papershape',
        },
      },
    },
    {
      caption: {
        de: 'rot-weiss',
        en: 'rot-weiss',
      },
      image: {
        filename: {
          de: 'products/sfkpferd/pferd-rot-weiss.jpg',
          en: 'products/sfkpferd/pferd-rot-weiss.jpg',
        },
        alt_text: {
          de: '3d Pferd Trophäe aus Papier als Wanddeko- DIY Papierskulptur #papershape',
          en: '3d paper horse as wall decor - diy paper sculpture #papershape',
        },
      },
    },
    {
      caption: {
        de: 'grau-schwarz',
        en: 'grau-schwarz',
      },
      image: {
        filename: {
          de: 'products/sfkpferd/pferd-grau-schwarz.jpg',
          en: 'products/sfkpferd/pferd-grau-schwarz.jpg',
        },
        alt_text: {
          de: '3d Pferd Trophäe aus Papier als Wanddeko- DIY Papierskulptur #papershape',
          en: '3d paper horse as wall decor - diy paper sculpture #papershape',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkpferd/pferd-weiss.jpg',
        en: 'products/sfkpferd/pferd-weiss.jpg',
      },
      alt_text: {
        de: '3d Pferd Trophäe aus Papier als Wanddeko- DIY Papierskulptur #papershape',
        en: '3d paper horse as wall decor - diy paper sculpture #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Kopf & Ohren',
        en: 'Head & ears',
      },
      color2: {
        de: 'Mähne',
        en: 'mane',
      },
    },
    disableColors: {
      color2: ['bunt', 'schwarz'],
      color1: ['schwarz'],
    },
    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
