export default {
  paper: (amount) => ({
    type: 'supply',
    name: {
      de: amount + ' DIN A4 Papier (160-300g/m²)',
      en:
        amount +
        ' cardstock paper (8.5in x 11in) (weight: 65lb-110lb / 176gsm-300gsm)',
    },
  }),
  glue: {
    type: 'supply',
    name: {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
  },
  scissors: {
    type: 'tool',
    name: {
      de: 'Schere',
      en: 'Scissors',
    },
  },
  ruler: {
    type: 'tool',
    name: {
      de: 'Lineal',
      en: 'Ruler',
    },
  },
  awl: {
    type: 'tool',
    name: {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
  },
  mat: {
    type: 'tool',
    name: {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  },
};
