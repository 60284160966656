import React from 'react';
import Email from '../../common/Email';

export default () => (
  <div>
    § 1 Application
    <br />
    <br />
    These terms and conditions govern the contractual relations between
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG, Guttknechtstr. 23, 90547
    Stein (vendor) and customers who make a purchase on the website
    www.paper-shape.com.
    <br />
    <br />
    <br />
    § 2 Subject of the contract
    <br />
    <br />
    The subject of the contract consists of the sale of handicraft kits, digital
    templates and vouchers.
    <br />
    <br />
    <br />
    § 3 Conclusion of Contract
    <br />
    <br />
    The offers on the internet site are non-binding, but an invitation to the
    customer to make a binding order himself.
    <br />
    Automatically sent emails that confirm the receiving of an order do not
    constitute the acceptance of the order.
    <br />
    Contracts are binding when goods are delivered by email (digital
    instructions and vouchers) or by mail (handicraft kits). <br />
    <br />
    <br />
    § 4 Prices, Payment
    <br />
    <br />
    Displayed prices include VAT.
    <br />
    If applicable additional shipping costs are displayed separately. <br />
    Shipping to destinations outside of Germany may entail higher shipping
    costs. <br />
    <br />
    The eligible methods of payment are displayed on the internet site.
    <br />
    These currently are the following: bank transfer and PayPal.
    <br />
    <br />
    <br />
    § 5 Delivery
    <br />
    <br />
    Delivery time depends on the customer´s country of residence and shipping
    address.
    <br />
    <br />
    Digital content and vouchers will be delivered immediately after payment
    receipt via email and download respectively.
    <br />
    <br />
    <br />
    § 6 Rights of use
    <br />
    <br />
    Customers is granted a non-exclusive right to use copyrighted material such
    as design and digital instructions for private purposes only. Customers may
    not publish copyrighted material publicly, e.g. via the internet or
    otherwise. <br />
    <br />
    <br />
    § 7 Retention of Title
    <br />
    <br />
    The vendor retains title to the goods until receipt of all payments in full.
    <br />
    <br />
    <br />
    § 8 Liability
    <br />
    <br />
    The vendor is liable according to the statutory provisions, if the customer
    makes a claim for damages based on intent or gross negligence, including
    intent or gross negligence of the vendor´s representatives or agents. In
    case of a non-intentional breach of contract the liability for damages is
    limited to foreseeable, typically occurring damage.
    <br />
    <br />
    The vendor is liable according to the legal provisions if he culpably
    infringes any essential contractual obligation; in this case the liability
    for damages is limited to foreseeable, typically occurring damage.
    <br />
    <br />
    Insofar as the customer is entitled to a claim for the non-intentional
    infringement of obligations, liability is limited to foreseeable, typically
    occurring damage.
    <br />
    <br />
    The liability for culpable injury to life, body or health remains
    unaffected; this also applies to mandatory liability under the Product
    Liability Act.
    <br />
    <br />
    Unless stipulated otherwise above, liability is excluded.
    <br />
    <br />
    <br />
    § 9 Applicable law, Jurisdiction
    <br />
    <br />
    This contract shall be governed by the laws of the Federal Republic of
    Germany (excluding the Convention on Contracts for the International Sale of
    Goods). For customers, who are consumers, nevertheless mandatory consumer
    rights provisions of the country of origin or residence are applicable.{' '}
    <br />
    <br />
    If the Customer is a corporation, limited liability company or commercial
    partnership or otherwise operates a commercial business or is a legal entity
    or special fund organized under public law, the courts in Germany shall have
    exclusive jurisdiction in respect of all disputes arising out of or in
    connection with the relevant contract. The competent court of jurisdiction
    in this case is the court at the vendors registered seat. In all other
    cases, the vendor or the customer may file suit before any court of
    competent jurisdiction under applicable law.
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>B. Consumer Information</h1>
    <br />
    1. Company identity
    <br />
    <br />
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG
    <br />
    Guttknechtstr. 23
    <br />
    90547 Stein
    <br />
    E-Mail: <Email />
    <br />
    Telephone: +49 911 36069244
    <br />
    Ust-IdNr.: DE305074829
    <br />
    <br />
    <br />
    2. Contract language, storage of the contract
    <br />
    <br />
    For international customers the language of the contract is English. <br />
    Apart from these Terms and Conditions, after a purchase no documents with
    respect to the contract are being stored.
    <br />
    <br />
    <br />
    3. Description of goods
    <br />
    A thorough description of the sales goods can be found on the vendor’s
    internet site.
    <br />
    <br />
    <br />
    <br />
    4. Conclusion of contract
    <br />
    <br />
    Purchases are made in the Websites Shop.
    <br />
    <br />
    In order to make a purchase, the customer clicks the desired product. On the
    next page he clicks the button “Order”. On the following page he chooses
    color and number and then clicks the button “enter shipping address”, where
    he enters name, email and shipping / invoice address. The order is sent when
    clicking the “Buy now” button.
    <br />
    <br />
    Before an order is sent, the customer can check the specifications of his
    order. By clicking the “back” button he can return to the previous screen
    and change the order.
    <br />
    <br />
    <br />
    <br />
    5. Corrections
    <br />
    <br />
    Before placing a binding order the customer can change the order
    specifications by means of the usual keyboard and mouse functions. To change
    an order (e.g. number, size, color) the customer clicks the “back” button of
    the website or his browser.
    <br />
    <br />
    <br />
    <br />
    6. Warranty, Customer Service
    <br />
    <br />
    Legal rights on warranty are stipulated in §§ 434 et seq. BGB.
    <br />
    <br />
    Customer Service can be contacted Mondays to Fridays between 09:00 a.m.–
    6:00 p.m. CET
    <br />
    <br />
    E-Mail: <Email />
    <br />
    Phone number: +49 911 36069244
    <br />
    <br />
    <br />
    7. Right of withdrawal
    <br />
    <br />
    Consumers have a right to withdraw from the contract in accordance with the
    information given before an order is placed and contained in the order
    confirmation email.
    <br />
    <br />
    The right of withdrawal shall not exist in case of contracts for the supply
    of goods that are not pre-fabricated and the production of which is governed
    by an individual choice of or decision by the consumer, or that are clearly
    tailored to personal needs of the consumer and contracts for the supply of
    goods which are highly perishable, or which may quickly pass their
    expiration date.
    <br />
    <br />
    In the case of a contract for the supply of digital content that is not
    contained in a tangible medium, the right of withdrawal expires also if the
    trader began with the performance of the contract after the consumer had
    expressly consented to the trader beginning with the performance of the
    contract prior to expiry of the withdrawal period, and had acknowledged that
    by his consent, he would lose the right to withdraw from the contract upon
    the performance of the contract having commenced.
    <br />
    <br />
    <br />
    <br />
    8. Delivery time
    <br />
    <br />
    Delivery time depends on the customer´s country of residence and shipping
    address. <br />
    <br />
    Digital content and vouchers will be delivered immediately after payment
    receipt via email and download respectively.
    <br />
    <br />
    <br />
    <br />
    <br />
    Right of withdrawal
    <br />
    <br />
    - handicraft kits -<br />
    <br />
    You have the right to withdraw from this contract within 14 days without
    giving any reason.
    <br />
    The withdrawal period will expire after 14 days from the day of the
    conclusion of the contract.
    <br />
    To exercise the right of withdrawal, you must inform us <br />
    <br />
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG
    <br />
    Guttknechtstr. 23
    <br />
    90547 Stein
    <br />
    E-Mail-Adresse: <Email />
    <br />
    Phone number: +49 911 36069244
    <br />
    <br />
    of your decision to withdraw from this contract by an unequivocal statement
    (e.g. a letter sent by post, fax or e-mail). You may use the attached model
    withdrawal form, but it is not obligatory.
    <br />
    <br />
    To meet the withdrawal deadline, it is sufficient for you to send your
    communication concerning your exercise of the right of withdrawal before the
    withdrawal period has expired.
    <br />
    <br />
    Effects of withdrawal
    <br />
    <br />
    We will reimburse all payments received from you for the goods purchased and
    will also reimburse delivery charges for the least expensive type of
    delivery offered by us, no later than 14 days from the day on which we
    received the above communication. We will use the same means of payment as
    you used for the initial transaction, unless you have expressly agreed
    otherwise. We may withhold reimbursement until we have received the goods
    back or you have supplied evidence of having sent back the goods, whichever
    is the earliest. If reimbursement occurs after the maximum time period
    mentioned above, the amount due to you will as of right be increased.
    <br />
    <br />
    Note that you must send back the goods by following the instructions
    available on our return centre no later than 14 days from the day on which
    us you communicate your cancellation. You will have to bear the direct cost
    of returning these goods. You may be liable if the value of the goods
    returned diminishes due to the handling of the goods (except when it was
    necessary to establish the nature, characteristics and functioning of the
    goods).
    <br />
    <br />
    <br />
    Right of withdrawal
    <br />
    <br />
    - Digital templates and Vouchers - <br />
    <br />
    You have the right to withdraw from this contract within 14 days without
    giving any reason.
    <br />
    The withdrawal period will expire after 14 days from the day of the
    conclusion of the contract.
    <br />
    To exercise the right of withdrawal, you must inform us <br />
    <br />
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG
    <br />
    Guttknechtstr. 23
    <br />
    90547 Stein
    <br />
    E-Mail-Adresse: <Email />
    <br />
    Phone number: +49 911 36069244
    <br />
    <br />
    of your decision to withdraw from this contract by an unequivocal statement
    (e.g. a letter sent by post, fax or e-mail). You may use the attached model
    withdrawal form, but it is not obligatory.
    <br />
    <br />
    To meet the withdrawal deadline, it is sufficient for you to send your
    communication concerning your exercise of the right of withdrawal before the
    withdrawal period has expired.
    <br />
    <br />
    Effects of withdrawal
    <br />
    <br />
    We will reimburse all payments received from you for the goods purchased and
    will also reimburse delivery charges for the least expensive type of
    delivery offered by us, no later than 14 days from the day on which we
    received the above communication. We will use the same means of payment as
    you used for the initial transaction, unless you have expressly agreed
    otherwise. <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    Model withdrawal form
    <br />
    <br />
    (complete and return this form only if you wish to withdraw from the
    contract)
    <br />
    <br />
    - To
    <br />
    PaperShape UG (haftungsbeschränkt) &amp; Co. KG
    <br />
    Guttknechtstr. 23
    <br />
    90547 Stein
    <br />
    E-Mail-Adresse: <Email />
    <br />
    <br />
    I/We (*) hereby give notice that I/We (*) withdraw from my/our (*) contract
    of sale of the following goods (*)/for the provision of the following
    service (*),
    <br />
    <br />
    - Ordered on (*)/received on (*),
    <br />
    <br />
    - Name of consumer(s),
    <br />
    <br />
    - Address of consumer(s),
    <br />
    <br />
    - Signature of consumer(s) (only if this form is notified on paper),
    <br />
    <br />
    - Date
    <br />
    <br />
    ___________
    <br />
    (*) Delete as appropriate.
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
);
