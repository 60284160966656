import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOrder } from '../../state/order/orderSelectors';
import { PaymentstartLink } from '../../common/Links';
import PsBankTransferHint from '../../common/PsBankTransferHint';
import PsBankAccount from '../../common/PsBankAccount';
import { getTextBySelectedLang } from '../../data/lang';
import Lang from '../../common/Lang';
import PsMeta from '../../common/PsMeta';
import { getIsRehydrated } from '../../state/fetch/fetchSelectors';

class OrderSuccessful extends Component {
  render() {
    if (!this.props.rehydrated) {
      return null;
    }
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang(
            'Bestellung erfolgreich',
            'Order successfull'
          )}
          noindex
        />

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-header">
                {<Lang de="Bestellung erfolgreich" en="Order successful" />}!
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <p style={{ margin: '20px 0' }}>
                <Lang
                  de={
                    <span>
                      Vielen Dank, {this.props.order.billing.name.firstname}!
                      Deine Bestellung ist eingegangen. Du erhältst in Kürze die
                      Auftragsbestätigung mit Rechnung per E-Mail an{' '}
                      <strong>{this.props.order.customer.email}</strong>.
                      Solltest du keine E-Mail erhalten haben, schaue bitte in
                      deinem <strong>Spam-Ordner</strong> nach.
                    </span>
                  }
                  en={
                    <span>
                      Thank you, {this.props.order.billing.name.firstname}! Your
                      order has been received. You will shortly receive an
                      e-mail to{' '}
                      <strong>{this.props.order.customer.email}</strong>
                      with the confirmation of your order and the invoice. If
                      you can't find the message, please check your{' '}
                      <strong>Spam folder</strong> too.
                    </span>
                  }
                />
              </p>

              <p className={'text-center'}>
                <PaymentstartLink className="btn btn-primary btn-lg">
                  {
                    <Lang
                      de="weiter zum Bezahlvorgang"
                      en="pay your order now"
                    />
                  }
                </PaymentstartLink>
              </p>

              <PsBankTransferHint />
              <PsBankAccount />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: getOrder(state),
    rehydrated: getIsRehydrated(state),
  };
};

export default connect(mapStateToProps)(OrderSuccessful);
