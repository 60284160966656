import React from 'react';
import Lang from './Lang';

function OnlyEuOrder() {
  return (
    <div>
      <div className="field-validation-error">
        <a
          href="https://www.etsy.com/de/shop/PaperShapeDesign?ref=simple-shop-header-name&listing_id=280120500&section_id=24847550"
          target="_blank"
          style={{ color: 'white' }}>
          <Lang
            de="Die Bestellung von digitalen Vorlagen außerhalb der EU ist leider nur über meinen Etsy Shop möglich: "
            en="The order of digital products is only possible within the EU."
          />
        </a>
      </div>
      <a
        className="btn btn-primary"
        style={{ margin: '10px 0' }}
        href="https://www.etsy.com/de/shop/PaperShapeDesign?ref=simple-shop-header-name&listing_id=280120500&section_id=24847550"
        target="_blank">
        <Lang de="zum Etsy Shop" en="Etsy Shop" />
      </a>
    </div>
  );
}

export default OnlyEuOrder;
