export default {
  id: '2-31-sfk',
  category: ['gift_wrapping'],

  name: {
    de: 'Herzschachtel',
    en: 'Heart shaped gift box',
  },
  subtitle: {
    de: 'B14 x H5 x T14 cm',
    en: 'W14 x H5 x D14 cm',
  },
  meta_title: {
    de: 'Herzschachtel basteln Vorlage',
    en: 'How to papercraft a heart shaped gift box',
  },
  meta_description_google: {
    de: 'Mit dieser digitalen Vorlage für eine große Herzschachtel kannst du deinen Liebsten eine süße Freude bereiten. Wie wäre es die Herzschachtel zum Valentinstag oder zur Hochzeit zu verschenken?',
    en: 'With this digital template for a large heart gift box you can give your loved ones a sweet treat. How about giving the heart box as a Valentines Day or wedding gift?',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  pdf: {
    description: {
      de: `Mit dieser digitalen Vorlage für eine große Herzschachtel kannst du deinen Liebsten eine süße Freude bereiten. 
      
      Damit die Herzschachtel gelingt, benötigst du einen Drucker, 4 Bögen Tonkarton (mind. 230g/m²), eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nachdem die Zahlung auf unserem Konto eingegangen ist, erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this digital template for a big heart shaped gift box you can give your loved ones a sweet treat..
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend to use 230g/m² paper.
      To make the heart shaped gift box succeed, you'll need a printer, 4 sheets of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'products/sfkherzschachtel/herzschachtel-basteln-tonkarton.jpg',
        en: 'products/sfkherzschachtel/herzschachtel-basteln-tonkarton.jpg',
      },
      alt_text: {
        de: 'Rote Herzschachtel aus Papier mit Pralinen gefüllt',
        en: 'Red heart shaped gift box filled with sweets',
      },
    },
  },
};
