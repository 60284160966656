export default {
  id: '2-16-sfk',
  category: ['animal_sculpture'],
  name: {
    de: 'Gorilla',
    en: 'Gorilla',
  },
  subtitle: {
    de: 'B23 x H40 x T33 cm',
    en: 'W23 x H40 x D33 cm',
  },
  meta_title: {
    de: 'Gorilla Papiertier',
    en: 'Gorilla Paper Animal',
  },
  meta_description_google: {
    de: 'Der Gorilla als Papierteil ist das ideal Geschenk für alle männlichen Freunde. Super einfacher Zusammenbau - alles im Set enthalten. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: "The gorilla paper animal is a great gift for male friends or children. It's very easy to assemble and is a lot of fun! 100% Success Guarantee ✓ More than 10.000 happy customers ✓.",
  },
  meta_description_social: {
    de: '3D Gorilla aus Papier - DIY Papiertier ',
    en: '3D gorilla from paper - diy paper sculpture',
  },
  description: {
    de: `KOMPLETTES SET BEINHALTET
    
    12 DIN A4 Seiten Fotokarton
    Doppelseitige Klebepads
    Detaillierte schrittweise Anleitung
    
  ANLEITUNG 
    
    In einem kurzen Video erkläre ich euch live, wie ihr in 3 Schritten zu eurem einzigartigen 3D Origami Gorilla gelangt. Praktische Tipps inklusive.
    Schneide alle Papierteile mit einer Schere oder einem Cutter aus. Alle durchgezogenen Linien müssen geschnitten werden. Schneide auch dort ein, wo eine kleine Schere angegeben ist.
    Um die Papierteile zu falten, musst du sie vorerst mit einem Lineal und einem Buttermesser oder einer Ahle vorfalzen. Lege dazu das Lineal an die gestrichelten Markierungen an und fahre mit dem Buttermesser und viel Druck am Lineal auf den Linien entlang. Anschließend knickst du die Linien wie in der Anleitung angegeben entweder nach außen oder innen.
    Jetzt gilt es alle Papierteile des 3D Origami miteinander zu verkleben. Verwende dazu die Klebepads, die im Set mitgeliefert sind. Das Verkleben erfolgt in nummerischer Reihenfolge bei 1, 2, 3, etc. Klebe die Pads von außen auf die Lasche und halte einen Sicherheitsabstand von ca. 2mm ein. Beim Verkleben unbedingt auf das Modell von außen schauen. BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser
    ab 14 Jahren empfohlen`,

    en: `    Age recommendation 14+
    
    ♥ COMPLETE KIT INCLUDES
    
    12 DIN A4 cardboard sheets
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    ♥ REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife
    
    ♥ STEP BY STEP
    
    Cut the paper parts.
    To fold the paper parts, you need to pre-fold them first with a ruler and a bread knife or an awl. Set the ruler to the dashed line and press with the butter knife along. Then fold the parts as specified in the instructions: either outwardly or inwardly.
    Now glue all the paper parts of the 3D origami together. Use the pads, which are included in the set. The folding takes place in numerical order in 1, 2, 3, etc. Set the adhesive pads on the outer sides of the tabs and keep a safe distance of about 2mm. When gluing necessarily watch the model from the outside.`,
  },

  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 12 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 12 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 12 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 12 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkgorilla/gorilla.jpg',
        en: 'products/sfkgorilla/gorilla.jpg',
      },
      alt_text: {
        de: 'DIY 3d Gorilla aus Papier als Wanddeko',
        en: 'Papercraft Gorilla for wall decor ',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },

  choose: {
    image: {
      filename: {
        de: 'products/sfkgorilla/gorilla.jpg',
        en: 'products/sfkgorilla/gorilla-papiertier.jpg',
      },
      alt_text: {
        de: '3d Gorilla aus Papier - DIY Papierskulptur #papershape ',
        en: 'DIY: 3d paper gorilla #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Gorilla',
        en: 'Gorilla',
      },
    },
    disableColors: {
      color1: ['schwarz', 'anthrazit'],
    },
    price: 29.9,
    priceThirdCountry: 20.9,
  },
};
