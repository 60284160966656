import React from 'react';
import cn from 'classnames';
import PsFieldError from './PsFieldError';

export default function PsLabel({
  label,
  classNameLabel,
  classNameField,
  children,
  meta,
}) {
  return (
    <div className="form-group">
      <label className={cn('control-label', classNameLabel)}>{label}</label>
      <div className={cn(classNameField)}>{children}</div>
      <PsFieldError meta={meta} />
    </div>
  );
}
