export default {
  url: 'windlicht-basteln-herz',
  countermark: 'vg07.met.vgwort.de/na/069ce545c7784f0989c0732bc9c24ca6',
  imageFolder: 'windlichtherz',
  landingPage: ['diy-geschenke'],

  name: {
    de: 'Windlicht Herz',
    en: 'Paper Heart Lantern',
  },
  id: '3-02-blog',
  pdf: {
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'content/windlichtherz/windlicht-basteln-herz.jpg',
        en: 'content/windlichtherz/windlicht-basteln-herz.jpg',
      },
      alt_text: {
        de: 'Windlicht basteln aus Transparentpapier als 3D Herz #bastelnmitpapier #papercraft #diy',
        en: 'Learn how to make a paper heart lantern #papercraft #diy',
      },
    },
  },

  meta_title: {
    de: 'Romantisches Windlicht basteln als 3D Herz',
    en: 'How to make paper heart lanterns in 3D (with template)',
  },
  meta_description_google: {
    de: 'Dieses DIY Windlicht aus Transparentpapier ist einfach selber zu machen und eine schöne Deko für den Hochzeitstisch, als Muttertagsgeschenk oder als süße Deko im Kinderzimmer. ',
    en: 'Learn how to make a georgous a paper heart lantern for your wedding decor or for Mothersday  ',
  },
  meta_description_social: {
    de: 'Windlicht basteln als Herz aus Transparentpapier. Diese schöne Deko eignet sich für sommerliche Balkonabende, für das gemütliche Ambiente beim Candle-Light-Dinner oder als DIY Geschenk für Muttertag und Valentinstag. ',
    en: 'How to make paper heart lantern (easy step by step tutorial)',
  },
  meta_images_social: [
    {
      de: 'windlicht-basteln-herz.jpg',
      en: 'windlicht-basteln-herz.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'windlicht-basteln-herz.jpg',
      en: 'windlicht-basteln-herz.jpg',
    },
    alt_text: {
      de: 'Windlicht basteln als 3D Herz für ein LED-Teelicht #bastelnmitpapier #windlicht #diy #papercraft',
      en: 'Make this romantic heart lantern for your wedding decor. Hop over to the digitale template and create the romance. #papercraft #paperdiy #weddinglantern  ',
    },
  },
  h1: {
    de: 'Romantisches Windlicht basteln als Herz',
    en: 'How to make romantic paper heart lanterns',
  },
  description: {
    de: `
      <p>Liebt ihr es auch an lauen Sommernächten noch lange draußen zu sitzen? </p>
      <p>Besonders schön wird das Ambiente meist durch dezenten Kerzenschein, der auf dem Tisch oder vom Boden her flackert.   </p>
      <p>Im heutigen DIY Post zeige ich euch, wie ihr ein schönes Windlicht basteln könnt. Es hat die Form eines 3d Herzens, sodass die Möglichkeiten der Anwendung schier unendlich sind.
      <p> Bei Nacht leuchtet euch ein schönes Licht und bei Tag steht eine wunderschöne Deko in eurer Wohnung. Drei weitere DIY Ideen, was ihr mit diesem Papierherz machen könnt, zeige ich euch 
      im nächsten Abschnitt. </p>
      
      <p>Bist du auf der Suche nach einem Geschenk für den Muttertag, dann solltest du unbedingt dieses wunderschöne und einfache <a href="/de/blog/rosen-basteln/">Rosenbouquet aus Papier</a> selber machen. 
       </p>

    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: '4 liebevolle DIY Geschenkideen von Herzen',
    en: '',
  },

  inspirationDescription: {
    de: `
   
    <p><h3>Windlichter basteln zur Hochzeit</h3></p>
    DIY Hochzeiten liegen total im Trend und sind zudem eine günstige Alternative schöne und authentische Deko zu gestalten. Diese Papierherzen könnt ihr auf den Tisch als Windlicht stellen oder als Deko
    beim Hochzeitsbuffet arrangieren.

    <p><h3>Verschenke ein 3D Herz zum Muttertag an deine Mama</h3></p>
Wenn ihr wie ich in einer Fernbeziehung mit eurer Ma lebt, dann ist dieses Herz zum Muttertag ein schönes Symbol. Von der Ferne brennt ein Licht, das an dich denkt.


<p><h3>Süßes Geschenk zur Geburt eines Babys</h3></p>
Wenn die Kleinen ihre Äuglein nach der Geburt kaum öffnen, sie fast rund um die Uhr schlafen und man sie nicht mit hartem Licht erschrecken möchte, sorgt ihr mit dieser süßen kleinen Lampe 
am Nachttisch für kuschelige Geborgenheit. Ihr müsst nicht unbedingt Transparentpapier verwenden, sondern könnt buntes Tonpapier mit max. 130g/m² nutzen. Dabei ist es ganz wichtig, dass ihr immer eine
LED Teelicht verwendet, damit das Papier nicht zu brennen beginnt.


<p><h3>Romantisches Valentinstag-Herz verschenken</h3></p>
Dieses Herz passt perfekt für euer Candle-Light-Dinner am Valentinstag. Stellt es auf und verbringt einen romantischen Abend zu zweit.
Eine schöne Erinnerung bleibt so mit diesem Herzen für immer verbunden.
    
    `,
    en: '',
  },

  stepsTitle: {
    de: 'Anleitung: DIY Windlicht aus Transparentpapier selber machen',
    en: 'Step by step: Learn how to make heart-shaped lanterns ',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'windlicht-vorlage-ausdrucken.jpg',
          en: 'windlicht-vorlage-ausdrucken.jpg',
        },
        alt_text: {
          de: 'Windlicht Vorlage ausdrucken',
          en: '',
        },
      },

      text: {
        de: `Ihr benötigt pro Herz 3 DINA4 Seiten Transparentpapier. Meines hat 150g/m². Druckt euch die Vorlage aus und achtet darauf, dass die schwarze Tinte nicht verschmiert. 
       
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'windlicht-ausschneiden.jpg',
          en: 'windlicht-ausschneiden.jpg',
        },
        alt_text: {
          de: 'Herz-Laterne ausschneiden aus Transparentpapier',
          en: '',
        },
      },

      text: {
        de: `Schneidet alle durchgezogenen Linien mit einer Schere aus. Ganz wichtig ist hier, dass ihr die schwarzen Linien wirklich mitabschneidet, damit sie nachher nicht durchscheinen.
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'windlicht-falten.jpg',
          en: 'windlicht-falten.jpg',
        },
        alt_text: {
          de: 'Transparentpapier für das Windlicht falten',
          en: '',
        },
      },

      text: {
        de: `Knickt die lang gestrichelten Linien von euch weg, d.h. nach unten. Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin. Wenn ihr Tonpapier oder Fotokarton verwendet, empfehle ich euch unbedingt die Linien vorher mit einer Ahle und einem Lineal nachzufahren, damit ihr die Knicke schöner falten könnt und diese nicht brechen.
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'windlicht-papier-kleben.jpg',
          en: 'windlicht-papier-kleben.jpg',
        },
        alt_text: {
          de: 'Windlicht mit Kleber zusammenkleben',
          en: '',
        },
      },

      text: {
        de: 'Tragt auf die Laschen der einzelnen Papierteile Kleber auf. Ich empfehle euch hier trockenen Kleber zu verwenden, weil er am wenigsten durchscheint, wenn ihr das Herz als Lampe verwendet. In dieser Vorlage sind keine Nummern angegeben, weil es sehr einfach im Zusammenbau ist. Zudem erscheinen dann keine unschönen aufgedruckten Zahlen auf dem Herz, wenn es leuchtet. Wichtig ist, dass ihr alles einheitlich knickt und die gedruckte Seite nach innen zeigt.',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'windlicht-herz-kleben.jpg',
          en: 'windlicht-herz-kleben.jpg',
        },
        alt_text: {
          de: 'Herz als Windlicht selber machen',
          en: '',
        },
      },

      text: {
        de: 'So arbeitet ihr euch nun Papierteil für Papierteil vor. Das Herz ist symmetrisch, d.h. achtet ein wenig wie beim Puzzle, wo was passt und voila, seid ihr schneller fertig als ihr denkt.',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'windlicht-led-teelicht.jpg',
          en: 'windlicht-led-teelicht.jpg',
        },
        alt_text: {
          de: 'LED-Teelicht unter das selbstgemachte Windlicht stellen',
          en: '',
        },
      },

      text: {
        de: 'Stellt unter das fertige Herz ein LED-Teelicht, das per Knopfbatterie betrieben wird. Bitte unbedingt beachten: kein Teelicht mit echter Flamme verwenden. Es wird brennen! ',
        en: ``,
      },
    },
  ],

  downloadName: 'vorlage-herz-papershape.pdf',
  materials: [
    {
      de: '3 DINA4 Transparentpapier oder als Deko auch Tonkarton',
      en: '3 translucent papers (8.5in x 11in)',
    },

    {
      de: 'trockenen Bastelkleber oder doppelseitiges durchsichtiges Klebeband',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },

    {
      de: 'LED-Teelicht',
      en: 'LED-tealight',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'windlicht-basteln-transparentpapier-hochzeit.jpg',
          en: 'how-to-make-lanterns-paper-diy-heart.jpg',
        },
        alt_text: {
          de: 'DIY Windlicht basteln als Herz aus Transparentpapier für die Hochzeit, zum Muttertag oder als Dekoration #papercraft #diy #bastelnmitpapier #windlicht #papershape',
          en: 'DIY heart lantern as a centerpiece for the wedding table #papercraft #weddinglantern #diy  ',
        },
      },
    ],
  },
};
