export default {
  id: '1-06-sfk',
  category: ['animal_sculpture'],
  name: {
    de: 'Giraffe',
    en: 'Giraffe',
  },
  subtitle: {
    de: 'B24 x H36 x T26 cm',
    en: 'W24 x H36 x D26 cm',
  },
  meta_title: {
    de: 'Giraffe Papiertier',
    en: 'Giraffe Paper Animal',
  },
  meta_description_google: {
    de: 'Der 3d Giraffen Kopf aus Papier zum Aufhängen verzaubert als Wanddeko jeden Raum. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The 3d giraffe head trophy from paper enchants each room with lots of space for creativity.  100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: '3d Papier Giraffe als Wanddeko - diy Papierskulptur #bastelmitpapier #papiergiraffe #papierskulptur #papershape ',
    en: '3d papercraft giraffe animal head from paper - #diy  #papercraft #papershape ',
  },
  description: {
    de: `  
Mit der 3D Origami Giraffe kommt ihr ganz hoch hinaus. Wir haben das höchste landlebende Tier der Welt etwas verkleinert, so dass es ohne Pflege in den eigenen vier Wänden Platz hat. Ihre realitätsnahen Formen lassen die 3D Origami Giraffe zu einem wunderschönen Designobjekt werden. Holt euch jetzt tropisches Klima ins Wohnzimmer.

ANLEITUNG 

Schneide die Papierelemente der Origami Giraffe entlang der durchgezogenen Linien aus.
Alle Papierelemente nun falten. Am besten gelingt dies, indem du an die gestrichelten Markierungen ein Lineal anlegst und mit einem Buttermesser entlang des Lineals die Markierung mit viel Druck vorfalzt. Anschließend lässt sich das Papier an den Markierungen ganz einfach nach innen bzw. außen (s. Anleitung) knicken.

Jetzt werden die Klebepads an die Außenseite der Lasche geklebt und anschließend mit der zugehörigen Nummer verklebt. Dabei gehst du in nummerischer Reihenfolge vor und beginnst mit dem Verkleben bei 1,2,3, etc.

DETAILS: Altersempfehlung ab 14 Jahren

KOMPLETTES SET BEINHALTET

7 DIN A4 Seiten Fotokarton Origami Kuh
Doppelseitige Klebepads
Detaillierte schrittweise Anleitung

BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser

    `,
    en: `     With the 3D origami giraffe you get it all from the top. We have made the highest terrestrial animal a little smaller, so that it fits in your four walls. Its realistic shape makes the 3D origami giraffe a beautiful design object. Now get a tropical climate in your living room.
    
    STEP BY STEP
    
    Cut out the paper elements of the Giraffe origami along the drawn lines.
    Now fold all the paper elements. This works best when you put a ruler on the dotted line and press along with a butter knife. Then the paper can easily be bent inside and outside (see instructions).
    Now the adhesive pads are set on the outer side of the tabs and then glued to the corresponding number. Follow the numerical order and start bonding with 1,2,3, etc.
    
    DETAILS
  
    Age recommendation from 14 years
    
    COMPLETE KIT INCLUDES
    
    7 DIN A4 cardboard sheets Origami Giraffe
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife`,
  },

  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 7 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 7 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 7 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 7 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkgiraffe/3d-giraffe-paper-trophy.jpg',
        en: 'products/sfkgiraffe/3d-giraffe-paper-trophy.jpg',
      },
      alt_text: {
        de: 'DIY 3d Giraffe aus Papier als Wanddeko',
        en: 'Papercraft giraffe for wall decor ',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },

  colorinspirations: [
    {
      caption: {
        de: 'gelb-orange',
        en: 'königsblau-pink',
      },
      image: {
        filename: {
          de: 'products/sfkgiraffe/3d-giraffe-paper-trophy.jpg',
          en: 'products/sfkgiraffe/giraffe-koenigsblau-rosa.jpg',
        },
        alt_text: {
          de: '3d Papier Giraffe als Wanddeko - diy Papierskulptur #papershape ',
          en: '3d Giraffe animal head from paper - diy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'steingrau-pink',
        en: 'steingrau-pink',
      },
      image: {
        filename: {
          de: 'products/sfkgiraffe/giraffe-steingrau-pink.jpg',
          en: 'products/sfkgiraffe/giraffe-steingrau-pink.jpg',
        },
        alt_text: {
          de: '3d Papier Giraffe als Wanddeko - diy Papierskulptur #papershape ',
          en: '3d Giraffe animal head from paper - diy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'königsblau-rosa',
        en: 'orange-bananengelb',
      },
      image: {
        filename: {
          de: 'products/sfkgiraffe/giraffe-koenigsblau-rosa.jpg',
          en: 'products/sfkgiraffe/giraffe-orange-bananegelb.jpg',
        },
        alt_text: {
          de: '3d Papier Giraffe als Wanddeko - diy Papierskulptur #papershape',
          en: '3d Giraffe animal head from paper - diy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'schokobraun-terracotta',
        en: 'schokobraun-terracotta',
      },
      image: {
        filename: {
          de: 'products/sfkgiraffe/giraffe-schokobraun-terracotta.jpg',
          en: 'products/sfkgiraffe/giraffe-schokobraun-terracotta.jpg',
        },
        alt_text: {
          de: '3d Papier Giraffe als Wanddeko - diy Papierskulptur #papershape',
          en: '3d Giraffe animal head from paper - diy #papershape',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkgiraffe/giraffe-weiss.jpg',
        en: 'products/sfkgiraffe/giraffe-weiss.jpg',
      },
      alt_text: {
        de: '3d Papier Giraffe als Wanddeko - diy Papierskulptur #papershape',
        en: '3d Giraffe animal head from paper - diy #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Kopf & Hörner',
        en: 'Head & horns',
      },
      color2: {
        de: 'Ohren',
        en: 'Ears',
      },
    },
    disableColors: {
      color2: ['bunt', 'schwarz'],
      color1: ['schwarz'],
    },
    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
