export default {
  url: 'lustige-ostergruesse',
  countermark: 'vg07.met.vgwort.de/na/477e6cef6f3e4c0583227dbefffe88e7',
  imageFolder: 'osterkarte',
  meta_title: {
    de: 'Lustige Ostergrüße für ein entspanntes Ostern (kostenlose Vorlage)',
    en: 'Funny easter greetings with free printable',
  },
  meta_description_google: {
    de:
      'Ostern entspannt feiern. Mit diesen lustigen Ostergrüßen gelingt das bestimmt. Einfach die Vorlage kostenlos herunterladen und in 5 Minuten eure Osterkarte basteln.',
    en:
      'You want to have a relaxed Easter? Then this diy is for you. Alert your familiy with this funny Easter greetings about your plan of sleeping in and just doing nothing during Easter.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  meta_images_social: [
    {
      de: 'lustige-ostergruesse-osterkarte-basteln.jpg',
      en: 'funny-easter-greetings-paper-card.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'lustige-ostergruesse.jpg',
      en: 'funny-easter-greetings-paper-card.jpg',
    },
    alt_text: {
      de:
        'Lustige Ostergrüsse: kostenlose Vorlage herunterladen und Ostergrußkarte verschenkekn / PaperShape #osterfest #ostern #diy #papershape',
      en:
        'Funny Easter greetings with free printable. DIY your Easter card in 5 minutes / PaperShape #easter #papercraft #diy #papershape',
    },
  },
  h1: {
    de: 'Lustige Ostergrüße: Last Minute DIY für ein entspanntes Ostern ',
    en: 'Funny Easter card: Last minute diy',
  },
  description: {
    de: `
      <p>Frohe Ostern? </p>
      <p>Sicher, aber dieses Jahr nutze ich die Zeit um auszuschlafen, in den Seilen zu hängen und so richtig zu entspannen. Damit die Family auch gleich vorgewarnt ist,
      verschicke ich Osterkarten mit lustigen Ostergrüßen, darunter diese hier.
      </p>
      <p> Die Vorlage könnt ihr wie gewohnt einfach ausdrucken. Sie ist in 5 Minuten fertig zum Verschenken. Wer der Karte noch eine Kleinigkeit beilegen möchte,
      dem seien diese lustigen <a href="/de/blog/ostereier-basteln/">Häschen-Ostereier</a> empfohlen. Nutzt sie gleich als Osterverpackung. 
      </p>
    
        `,
    en: `
      <p>
      Happy Easter? 
      </p>
      <p>
      Sure, but this year I am going to sleep in and just relax the entire weekend. Just to make sure my family is aware of my plan, I'll send them this funny Easter greetings. 
      If you think this might be a good idea for you too, then donwnload the free printable. And if you want to add a small gift you may paper craft these
       <a href="/en/blog/wimpern-hase/"> funny bunny paper eggs</a>.
      </p>
     
    `,
  },
  inspirationTitle: {
    de: 'Schlafende Augen und lustige Ostergrüße',
    en: 'Funny Easter Card',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'lustige-ostergruesse-osterkarte-basteln.jpg',
          en: 'funny-easter-card.jpg',
        },
        alt_text: {
          de:
            'Easter greetings cards / PaperShape  #osterdeko #ostereier #ostern #diy #papercraft',
          en:
            'Funny Easter card with free template./ PaperShape #eastergreetingcards #easter #papercraft #diy #papershape',
        },
      },
    },
  ],
  stepsTitle: {
    de: 'In 5 Minuten zur DIY Osterkarte mit lustigem Ostergruß',
    en: 'Last minute: Easter printable for a funny Easter card',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'lustige-ostergruesse-kostenlos.jpg',
          en: 'lustige-ostergruesse-kostenlos.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Osterkarte Vorlage herunterladen',
          en: 'Step: Download Easter Egg template',
        },
      },
      text: {
        de: `Die Vorlage für die lustige Osterkarte auf ein DIN A4 Fotokarton oder stärkeres Papier in weiß ausdrucken.`,
        en: `Print the free template (US letter) on white paper.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitung-schneiden.jpg',
          en: 'anleitung-schneiden.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ausschneiden',
          en: 'Step: Cutting',
        },
      },
      text: {
        de: `Die Ränder der Vorlage könnt ihr entweder mit einer Schneidemaschine abschneiden, oder einfach mit einer Schere oder einem Cutter. Die Mittellinie ist mit zwei kleinen Strichen oben und unten gekennzeichnet, sodass
        ihr wisst, wo ihr knicken müsst. Hier würde ich wieder mit einer Ahle und Lineal die Linie mit Druck abfahren und dann falten.`,
        en: `Cut off the borders with a scissor, a cutter knife or a trimmer. On the left side of the quote you'll see a line indicating the middle line. I recommend to mark the fold of 
        the middle line by placing a ruler next to the line and pressing down hard with a bonefolder.`,
      },
    },
    {
      image: {
        filename: {
          de: 'anleitungsschritt-beschriften.jpg',
          en: 'anleitungsschritt-beschriften.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Bescchriften',
          en: 'Step: writing',
        },
      },
      text: {
        de: `Jetzt die Karte nach Belieben beschriften und sehr wahrscheinlich habt ihr weitere lustige Ostergrüße auf Lager. Weitere Inspirationen für lüstige Ostergrüße findet
        ihr <a href="https://www.pinterest.de/papershape_/diy-ostern/lustige-osterspr%C3%BCche/" target="_blank">hier</a>.`,
        en: `That's it! For more funny inspirations check out my <a href="https://www.pinterest.de/papershape_/diy-ostern/lustige-osterspr%C3%BCche/" target="_blank">pinterest board here.</a> `,
      },
    },
  ],
  downloadName: 'funny-easter-card.pdf',
  materials: [
    {
      de: '1 DINA4 Papier in weiß (160-300g/m²)',
      en:
        '1 cardstock paper in white (8.5in x 11in) (weight: 65lb-110lb / 176gsm-300gsm)',
    },

    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert die DIY Idee für euer nächstes Projekt auf Pinterest',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'lustige-ostergruesse-kostenlos-osterkarte.jpg',
          en: 'funny-easter-greetings-card.jpg',
        },
        alt_text: {
          de:
            'Easter greetings cards / PaperShape  #osterdeko #ostereier #ostern #diy #papercraft',
          en:
            'Handmade funny Easter card with free template./ PaperShape #eastergreetingcards #easter #papercraft #diy #papershape',
        },
      },
    ],
  },
};
