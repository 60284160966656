export default {
  url: '3d-papierskulptur-challenge',
  countermark: 'vg07.met.vgwort.de/na/7b9f4bea54bd41f48270f67e67c68850',
  imageFolder: 'augustchallenge',
  meta_title: {
    de: 'Papercraft Challenge: Wie ich jeden Tag eine 3D Papierskulptur erfunden habe',
    en: 'Papercraft Challenge: Create every day a sculpture',
  },
  meta_description_google: {
    de: 'Ich nehme euch mit auf meine kreative Papercraft Challenge und zeige euch täglich die Ergebnisse: jeden Tag gibt es eine neue Papierskulptur zu entdecken. ',
    en: 'I take you with me on my creative journey: every day I create one new 3D papercraft model and show you the result. Hop over and discover my newest papercraft sculpture.',
  },
  meta_description_social: {
    de: 'Ich nehme euch mit auf meine kreative Papercraft Challenge und zeige euch täglich die Ergebnisse: jeden Tag gibt es eine neue Papierskulptur zu entdecken.',
    en: 'I take you with me on my creative journey: every day I create one new 3D papercraft model and show you the result. Hop over and discover my newest papercraft sculpture.',
  },

  image_main: {
    filename: {
      de: '4-papier-anker-3d.jpg',
      en: '4-papier-anker-3d.jpg',
    },
    alt_text: {
      de: 'DIY Anker aus Papier in 3D - Deko #papercraft #papershape #diy',
      en: 'How to make a paper anchor #papercraft #diy #hochzeitsdeko',
    },
  },
  h1: {
    de: 'Entdecke jeden Tag eine neue Papierskulptur (meine August-Challenge 2019) ',
    en: 'Discover a new papercraft sculpture every day (my August challenge 2019)',
  },
  description: {
    de: `
      <p>Jeden Tag kreiere ich eine neue Papierskulptur, um mich in den Flow zu bringen  kreativ zu sein.</p>
      <p>Über <a href="https://www.instagram.com/papershape_/" target="_blank">Instagram</a>, <a href="https://www.facebook.com/pg/papershape/posts/" target="_blank">Facebook</a> und <a href=" https://www.pinterest.de/papershape_/basteln-mit-papier-papershape-vorlagen/"target="_blank">Pinterest</a>  könnt ihr über Likes, Teilen und Kommentare mitstimmen, welche Modelle ich dann final umsetze.  </p>
     

      
      <p>Wer nicht so lange warten mag, der findet hier bereits schöne DIY-Ideen aus Papier</p>
      <u>
      <li><a href="/de/blog/papier-apfel-basteln/">DIY Äpfel basteln</a></li>
      <li><a href="/de/blog/libelle-basteln-papier/">DIY Libellen für den Garten</a></li>
      <li><a href="/de/blog/bilderrahmen-basteln-papier/">DIY Bilderrahmen für die Urlaubsfotos </a></li>
      
    
  </u>
      
    
    `,
    en: `
      <p>Every day in August I am going to create one new papercraft sculpture.  
      
      </p>Discover them on <a href="https://www.instagram.com/papershape_/" target="_blank">Instagram</a>, <a href="https://www.facebook.com/pg/papershape/posts/" target="_blank">Facebook</a> and <a href=" https://www.pinterest.de/papershape_/basteln-mit-papier-papershape-vorlagen/" target="_blank">Pinterest</a> and vote what paper models I should realize.
      Vote via likes, shares on social media or commments.
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: 'Meine täglichen Papierskulpturen Ergebnisse',
    en: 'My daily Papercraft results',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '1_papierkunst-kreis-loop.jpg',
          en: '1_papierkunst-kreis-loop.jpg',
        },
        alt_text: {
          de: '3d Papierkreis aus Rechtecken Wanddeko #papercraft #bastelnmitpapier',
          en: 'papercraft loop from 3d rectangles #papercraft #walldecor #diy',
        },
      },
    },
    {
      text: {
        de: `TAG 1: Papierloop aus 3D Rechtecken. Er kann entweder⁣
        um einen Spiegel, eine PaperShape Skulptur oder einfach als kubistische Kunst im Raum gehängt werden. `,
        en: `Paperart loop`,
      },
    },
    {
      image: {
        filename: {
          de: '2_dala-pferd-papier.jpg',
          en: '2_dala-pferd-papier.jpg',
        },
        alt_text: {
          de: 'Papier Dala Pferd Deko aus Papier #papercraft #bastelnmitpapier #diy',
          en: 'paper dala horse decor #papercraft #bastelnmitpapier #diy',
        },
      },
    },
    {
      text: {
        de: `TAG 2: Dalapferd. Typischerweise werden diese Pferde in Schweden eben aus Holz gemacht und in den langen Wintermonaten in Schwedenrot mit Folklore-Mustern bemalt.
          ⁣
        ⁣
        Ich wollte es ein wenig simpler, etwas mehr mit einfachen Formen. ⁣Als Wanddeko kann ich euch <a href="/de/product/1-03-sfk/">diesen Pferdekopf empfehlen</a> oder
         <a href="/de/product/1-10-sfk/">diese Pferdeskulptur mit Beinen (als Download oder Kit).</a>
        ⁣`,
        en: `Swedish dala horse`,
      },
    },
    {
      image: {
        filename: {
          de: '3_papier-3d-diamanten.jpg',
          en: '3_papier-3d-diamanten.jpg',
        },
        alt_text: {
          de: 'DIY Papier Diamanten als Deko #papercraft #diy #bastelnmitpapier',
          en: 'diy paper gems and diamands #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: ` TAG 3: Papierdiamanten. Sie sind so ein cooles DIY-Projekt, 
        weil sie einfach für alles zu gebrauchen sind: gegen Langeweile, als Tischdeko, Geschenkverpackung , Geschenkeanhänger, Mobile...  
        <a href="/de/blog/diamant-basteln/">Hier könnt ihr die Vorlage herunterladen</a>⁣`,
        en: `Paper gems. <a href="/en/blog/diamant-basteln/">Download the template here.</a>⁣`,
      },
    },

    {
      image: {
        filename: {
          de: '4-papier-anker-3d.jpg',
          en: '4-papier-anker-3d.jpg',
        },
        alt_text: {
          de: 'DIY Papier Anker basteln #papercraft #diy #bastelnmitpapier',
          en: 'How to make a papercraft anchor #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 4: Papieranker. Er steht so auf dem Boden, als ob ein Teil schon ins Wasser eingelassen wurde.⁣
        ⁣
        Ich persönlich finde es so dynamischer. 
        <a href="/de/product/2-20-sfk/">Hier könnt ihr die Vorlage herunterladen oder das komplette Set bestellen.</a>
  `,
        en: `Paper anchor. <a href="/en/product/2-20-sfk/">Follow the link to get to the template.</a>`,
      },
    },

    {
      image: {
        filename: {
          de: '5_papier-wandteller.jpg',
          en: '5_papier-wandteller.jpg',
        },
        alt_text: {
          de: '3D Papierteller selbermachen #papercraft #diy #bastelnmitpapier',
          en: 'How to make 3d paper wall plates #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 5: Low poly Wandteller aus Papier als Partydeko für Schlemmereien gemäß eurem Motto oder wie immer, einfach mal aus Jux basteln könnt. Sie sind super als Start für Kreativitätsübungen!⁣
  `,
        en: `Low poly wall plates`,
      },
    },

    {
      image: {
        filename: {
          de: '6_papercraft-wal.jpg',
          en: '6_papercraft-wal.jpg',
        },
        alt_text: {
          de: 'Papercraft Wal aus Papier basteln #papercraft #diy #bastelnmitpapier',
          en: 'How to make a papercraft whale #papercraft #diy  ',
        },
      },
    },
    {
      text: {
        de: `TAG 6: Papierwal. In meiner August-Challenge habe ich mich an den Wunsch von Stefan Birn gemacht. Es ist ein Wal, der als Deko auf dem Schreibtisch echt cool aussieht. Man könnte noch das weiße Papier mit schwarzen Längsstreifen versehen, um die Optik eines Pottwals zu unterstreichen.⁣
  Wer lieber Delfine mag, findet sowohl <a href="/de/product/2-10-sfk/">die Delfin Vorlage als auch das DIY Kit hier.</a> 

        `,
        en: `Papercraft whale`,
      },
    },

    {
      image: {
        filename: {
          de: '7_bremer-stadtmusikanten-papier-basteln.jpg',
          en: '7_bremer-stadtmusikanten-papier-basteln.jpg',
        },
        alt_text: {
          de: 'DIY: Bremer Stadtmusikanten basteln aus Papier #papercraft #diy #bastelnmitpapier',
          en: 'How to make paper Bremen Town Musicians #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `Tag 7: Bremer Stadtmusikanten. Jemand aus Bremen hier? Heute habe ich die Bremer Stadtmusikanten auf dem Plan.⁣
        ⁣
        Die Figuren sind sehr low poly gehalten, damit sie als Deko nicht zu riesig werden und trotzdem noch einfach zum Basteln sind. Der Hahn steht nicht auf der Katze, weil er sonst zu klein und damit eine Friemelarbeit geworden wäre. Deswegen steht er nebendran - künstlerische Freiheit.
  `,
        en: `Bremen Town Musicians`,
      },
    },

    {
      image: {
        filename: {
          de: '8_ballontiere-hund-papier-diy.jpg',
          en: '8_ballontiere-hund-papier-diy.jpg',
        },
        alt_text: {
          de: 'Ballontiere aus Papier basteln: Papierhund #papercraft #diy #bastelnmitpapier',
          en: 'How to make a 3d balloon dog from paper #papercraft #diy #balloondog',
        },
      },
    },
    {
      text: {
        de: `TAG 8: Ballonhund. Dieser Wunsch stammt von @cookr7 und ich habe ihn in knalliger Farbe + schwarz-weiß Muster umgesetzt. <a href="/de/product/2-27-sfk/">Die Vorlage und das Bastelset findet ihr hier.</a> 
  `,
        en: `Paper balloon dog`,
      },
    },

    {
      image: {
        filename: {
          de: '9_papier-kristall-basteln-3d.jpg',
          en: '9_papier-kristall-basteln-3d.jpg',
        },
        alt_text: {
          de: 'DIY Kristalle aus Papier selbermachen #papercraft #diy #bastelnmitpapier',
          en: 'How to make a paper crystal cluster #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 9: Kristall-Cluster - sie sind unten komplett offen, sodass man auch eine LED-Leuchte darunter stellen kann. Wenn man jetzt noch Farbverlauf-Papier nähme....yammi...⁣
  `,
        en: `Paper crystal cluster`,
      },
    },

    {
      image: {
        filename: {
          de: '10_3d-buchstaben-basteln.jpg',
          en: '10_3d-buchstaben-basteln.jpg',
        },
        alt_text: {
          de: '3D Buchstaben basteln aus Papier und Karton #papercraft #diy #bastelnmitpapier',
          en: 'How to make 3d letters from paper #papercraft #diy #letters',
        },
      },
    },
    {
      text: {
        de: `TAG 10: Good Vibes Buchstaben zum Start in den Samstagabend!
         Im Vergleich zu meinen <a href="/de/blog/love-schriftzug-basteln/">LOVE Buchstaben hier</a>, die ihr auf dem Blog downloaden könnt, sind diese hier zweifarbig gestaltet.⁣
        ⁣

  
        Ideal für den Hauseingang oder ins Kinderzimmer`,
        en: `3d letters`,
      },
    },

    {
      image: {
        filename: {
          de: '11_wcmaennchen-papier-diy-deko.jpg',
          en: '11_wcmaennchen-papier-diy-deko.jpg',
        },
        alt_text: {
          de: 'DIY WC Männchen aus Papier selbermachen in 3D und low poly #papercraft #diy #bastelnmitpapier',
          en: 'How to make 3d toilette figures #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 11: WC-Männchen aus Papier. Wie oft stand ich schon vor einer Toilette und habe mich gefragt: links oder rechts? Mit den heutigen Frau-Mann-Zeichen hätte ich auf jeden Fall keine Probleme 
  `,
        en: `Toilet figures (male and female)`,
      },
    },

    {
      image: {
        filename: {
          de: '12_koi-fisch-papercraft.jpg',
          en: '12_koi-fisch-papercraft.jpg',
        },
        alt_text: {
          de: 'Papercraft koi Fische selbermachen #papercraft #diy #bastelnmitpapier',
          en: 'How to make paper koi fishes #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 12: Koi-Fische. Man sieht es am Bild nicht so gut, aber ich habe sie so entwickelt,
        dass sie flach an der Wand aufgehängt werden und der Kopf und Schwanz "kurvig" nach außen zeigen.⁣
        <a href="/de/product/1-15-sfk/">Hier geht es zum Download der Koi Fisch Vorlage und zum DIY-Kit.</a>
  `,
        en: `Day 12: Papercraft koi fishes. <a href="/en/product/1-15-sfk/">Download the template for the koi fishes here.</a>`,
      },
    },

    {
      image: {
        filename: {
          de: '13_pralinen-papier-basteln-geschenkbox.jpg',
          en: '13_pralinen-papier-basteln-geschenkbox.jpg',
        },
        alt_text: {
          de: 'DIY Geschenkboxen als Papier Pralinen basteln #papercraft #diy #bastelnmitpapier #geschenkverpackung',
          en: 'How to make giant sweets as treatboxes from paper #papercraft #diy #treatbox',
        },
      },
    },
    {
      text: {
        de: ` TAG 13: Süße XXL Pralinenboxen. Diese könnt ihr zum nächsten Geburtstag, Valentinstag, Hochzeitstag eure Kleinigkeiten stilvoll verpacken. 
        Dabei dient die Praline quasi als Deckel, der hochgehoben werden kann.
        <a href="/de/blog/diy-geschenkbox-praline">Hier geht es zum Download der Pralinen-Vorlage.</a> 
  `,
        en: `Giant praline box. <a href="/en/blog/diy-geschenkbox-praline">You will find the downloadable template here.</a>`,
      },
    },

    {
      image: {
        filename: {
          de: '14_3d-zahlen-selber-machen.jpg',
          en: '14_3d-zahlen-selber-machen.jpg',
        },
        alt_text: {
          de: 'DIY 3D Zahlen basteln #papercraft #diy #bastelnmitpapier',
          en: 'How to make 3d numbers from paper #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 14: 3D Zahlen. Wenn es das Alphabet gibt, dann müssen natürlich auch Zahlen her.
        ⁣
        Heute in schwarz-weiß mit Kuhflecken  `,
        en: `3d numbers`,
      },
    },

    {
      image: {
        filename: {
          de: '15_lebkuchen-herz-diy-schachtel.jpg',
          en: '15_lebkuchen-herz-diy-schachtel.jpg',
        },
        alt_text: {
          de: 'Lebkuchenherz Wiesn Oktoberfest Geschenkschachtel #papercraft #diy #bastelnmitpapier #geschenkverpackung',
          en: 'How to make a ginger bread heart from paper as treatbox #papercraft #diy #treatbox',
        },
      },
    },
    {
      text: {
        de: `TAG 15: Lebkuchenherzen. Heute mal mit einem typisch bayerischen Spruch.⁣
        ⁣
        Wer mag kann das Herz als Box basteln oder nur den Deckel mit Band versehen und verschenken. Zum Valentinstag, JGA, Geburtstag, Gutschein...
        man kann schließlich alles mögliche draufschreiben, wenn man die Schrift nicht plotten mag.
        
        Alternativ findet ihr schon <a href="/de/blog/herzschachtel-basteln/"> hier schöne Herzboxen zum Basteln.⁣</a>⁠ `,
        en: `Ginger bread heart box`,
      },
    },

    {
      image: {
        filename: {
          de: '16_papier-3d-eicheln-herbstdeko.jpg',
          en: '16_papier-3d-eicheln-herbstdeko.jpg',
        },
        alt_text: {
          de: 'DIY Herbstdeko: 3d Eicheln aus Papier #papercraft #diy #bastelnmitpapier #herbstdeko ',
          en: 'How to make acorns from paper #papercraft #diy #acorns #falldecor',
        },
      },
    },
    {
      text: {
        de: `TAG 16: Herbstliche 3D Eicheln. Die Vorlage zum Download <a href=" /de/blog/3d-eicheln-basteln">findet ihr hier.</a>⁠ `,
        en: `Papercraft acorns. You'll find the template <a href=" /en/blog/3d-eicheln-basteln">here.</a>⁠ `,
      },
    },

    {
      image: {
        filename: {
          de: '17_mandala-wanddeko.jpg',
          en: '17_mandala-wanddeko.jpg',
        },
        alt_text: {
          de: 'DIY 3D Mandala: Sonnenblume aus Papier #papercraft #diy #bastelnmitpapier ',
          en: 'How to make a 3d mandala from paper #papercraft #diy  ',
        },
      },
    },
    {
      text: {
        de: `TAG 17: In gelb eine Sonnenblume und in creme als Stuck-Rosette für die Wand oder sogar Decke? ⁠ `,
        en: `Paper Mandala`,
      },
    },

    {
      image: {
        filename: {
          de: '18_papercraft-rhino.jpg',
          en: '18_papercraft-rhino.jpg',
        },
        alt_text: {
          de: 'Papercraft Nashorn als Wanddeko #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make 3d rhino from paper #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 18: Nashorn⁠ als Wanddeko
        ⁠ ⁠ `,
        en: `Papercraft rhino`,
      },
    },

    {
      image: {
        filename: {
          de: '19_babyshower-babyflasche-basteln.jpg',
          en: '19_babyshower-babyflasche-basteln.jpg',
        },
        alt_text: {
          de: 'DIY Babyshowergeschenk: 3D Babyflasche aus Papier basteln #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make a baby bottle from paper as babyshower gift #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 19: Wenn es zur nächsten Babyshower-Party geht, dann bitte mit dieser XXL-Babyflasche aus Papier (Höhe ca. 20-25cm) - befüllbar und verzierbar. 
        ⁠ ⁠ `,
        en: `Papercraft baby bottle`,
      },
    },

    {
      image: {
        filename: {
          de: '20_vogelhaus-papier-hochzeitsgeschenk.jpg',
          en: '20_vogelhaus-papier-hochzeitsgeschenk.jpg',
        },
        alt_text: {
          de: 'DIY Hochzeitsgeschenk: 3D Vogelhaus aus Papier mit Spardose und Vögeln #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make a paper bird house as wedding gift #papercraft #diy #weddinggift ',
        },
      },
    },
    {
      text: {
        de: `TAG 20: ein Vogelhaus mit Vögeln und versteckter Funktion:⁠        ⁠
        Das kleinere Häuschen mit dem Loch ist eine "eigenständige Box" und kann als Spardose verwendet werden - das Geld kommt vorne rein und wenn es voll ist, dann einfach herausziehen und das Dach öffnen. Das äußere Häuschen ist die Haltebox und kann in einer anderen Farbe gestaltet werden.⁠ 
        ⁠ ⁠ `,
        en: `Papercraft bird house with small birds`,
      },
    },

    {
      image: {
        filename: {
          de: '21_seepferdchen-basteln-papercraft.jpg',
          en: '21_seepferdchen-basteln-papercraft.jpg',
        },
        alt_text: {
          de: 'DIY Seepferdchen aus Papier basteln. D #papercraft #diy #bastelnmitpapier  ',
          en: 'Papersculpture seahorses as wall decor - how to #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 21: Seepferdchen.    ⁠
        In der Höhe habe ich sie bei ca. 60cm gestaltet und aufgehängt werden sie auch wieder wie die anderen Wanddekos einfach an einem Nagel. ⁠Das DIY Kit und die Vorlage zum Download <a href="/de/product/1-12-sfk/">findet ihr hier.</a>⁠ `,
        en: `Papercraft seahorse  <a href="/en/product/1-12-sfk/">You'll find the template here.</a>`,
      },
    },

    {
      image: {
        filename: {
          de: '22_esel-papaier-3d-wanddeko.jpg',
          en: '22_esel-papaier-3d-wanddeko.jpg',
        },
        alt_text: {
          de: 'DIY Papier Esel als Idee für die Wandgestaltung #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make a paper donkey #papercraft #diy #donkey',
        },
      },
    },
    {
      text: {
        de: `TAG 22: Ein süßer Esel. Diesmal ist er nicht symmetrisch gestaltet, sondern, wenn man ihn von vorne ansieht (und so hängt er dann auch an der Wand), schaut es aus, als ob er leicht um die Ecke guckt - total süß. 
        ⁠ ⁠ <a href="/de/product/1-11-sfk/">Hier geht es zum Download der Eselvorlage und zum DIY-Kit.</a> `,
        en: `Papercraft donkey. <a href="/en/product/1-11-sfk/">You'll find the template here.</a> `,
      },
    },

    {
      image: {
        filename: {
          de: '23_papercraft-penguin.jpg',
          en: '23_papercraft-penguin.jpg',
        },
        alt_text: {
          de: 'DIY Papier Pinguin zum Hinstellen als Deko #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make a papercraft penguin  #papercraft #diy #penguin',
        },
      },
    },
    {
      text: {
        de: `TAG 23: Ein süßer Pinguin. Dieser Königspinguin ist ganz schön stattlich in seiner Höhe und leicht nach links geneigt, als wollte er mal schauen, was da so los ist... `,
        en: `Papercraft penguin`,
      },
    },

    {
      image: {
        filename: {
          de: '24_papercraft-steinbock.jpg',
          en: '24_papercraft-steinbock.jpg',
        },
        alt_text: {
          de: 'DIY Papier Steinbock als Idee für die Wandgestaltung #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make a papercraft capricorn #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 24: Steinbock aus Papier für alle im Dezember und Januar geborenen. 
        ⁠ ⁠ `,
        en: `Papercraft capricorn`,
      },
    },

    {
      image: {
        filename: {
          de: '25_papier-regenbogen-basteln.jpg',
          en: '25_papier-regenbogen-basteln.jpg',
        },
        alt_text: {
          de: 'DIY Papier Regenbogen als Idee für die Wandgestaltung #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make a paper rainbow for your walls in 3d #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 25: 3D Regenbogen`,
        en: `Papercraft rainbow`,
      },
    },

    {
      image: {
        filename: {
          de: '26_papercraft-bear.jpg',
          en: '26_papercraft-bear.jpg',
        },
        alt_text: {
          de: 'DIY Papierskulptur Bär als Idee für die Wandgestaltung #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make a papercraft bear #papercraft #diy #bear',
        },
      },
    },
    {
      text: {
        de: `TAG 26: Braun- oder lieber Eisbär? Die Farbwahl bleibt bei euch um diesen süßen Origami Bären zu gestalten.⁠ `,
        en: `Papercraft bear`,
      },
    },

    {
      image: {
        filename: {
          de: '27_papierblumen-monstera.jpg',
          en: '27_papierblumen-monstera.jpg',
        },
        alt_text: {
          de: 'DIY Papierblume Monstera als Idee für die Wandgestaltung #papercraft #diy #bastelnmitpapier #monstera ',
          en: 'How to make a paper monstera #papercraft #diy #monstera',
        },
      },
    },
    {
      text: {
        de: `Tag 27: die heiß geliebte Monstera als 3D Objekt für die Wand umgesetzt. Sie ist leicht nach vorne geneigt, damit ein schöner Lichteffekt entsteht und kann easy peasy aufgehängt werden.`,
        en: `Papercraft monstera`,
      },
    },

    {
      image: {
        filename: {
          de: '28_papercraft-musik-noten.jpg',
          en: '28_papercraft-musik-noten.jpg',
        },
        alt_text: {
          de: 'DIY Papier Musik Noten als Idee für die Wandgestaltung #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make a paper music notes in 3d #papercraft #diy ',
        },
      },
    },
    {
      text: {
        de: `TAG 28: Musiknoten aus Papier zum freien Aufhängen über dem Klavier.`,
        en: `Papercraft music notes`,
      },
    },

    {
      image: {
        filename: {
          de: '29_papier-tannenbaum-basteln.jpg',
          en: '29_papier-tannenbaum-basteln.jpg',
        },
        alt_text: {
          de: 'DIY Papier Tannenbaum basteln #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make a 3d paper christmas tree #papercraft #diy #christmastree',
        },
      },
    },
    {
      text: {
        de: `TAG 29: Ihr könnt diesen Baum als Windlicht basteln mit einer LED-Leuchte darunter (wie bei meinem Herzen) oder wenn man ihn in groß (halber Meter Durchmesser) baut, kann man darunter die Geschenke verstecken `,
        en: `Papercraft christmas tree`,
      },
    },

    {
      image: {
        filename: {
          de: '30_papier-mond-wanddeko.jpg',
          en: '30_papier-mond-wanddeko.jpg',
        },
        alt_text: {
          de: 'DIY Papier Mond als Idee für die Wandgestaltung #papercraft #diy #bastelnmitpapier  ',
          en: 'How to make a paper moon #papercraft #diy #moon #nurserydecor',
        },
      },
    },
    {
      text: {
        de: `TAG 30: Heute ein schöner Halbmond, der als Wanddeko im Schlafzimmer an der Wand oder Decke und auch im Kinderzimmer super schön aussieht. Die Sterne sind nicht 3D, sondern einfach in 2D ausgeschnitten. ⁠⁠ ⁠ `,
        en: `Papercraft moon `,
      },
    },

    {
      image: {
        filename: {
          de: '31_papier-pokal-basteln.jpg',
          en: '31_papier-pokal-basteln.jpg',
        },
        alt_text: {
          de: 'DIY Papier Pokal für die kleinen Sieger #papercraft #diy #bastelnmitpapier #pokal #siegertrophäe ',
          en: 'How to make a paper cup trophy #papercraft #diy #papertrophy #papercup',
        },
      },
    },
    {
      text: {
        de: `TAG 31: Nach 31 Tagen und 31 Modellen überreiche ich euch und mir diesen Siegerpokal! Den oberen Teil kann man abnehmen und so etwas in den Pokal legen. Die runde Scheibe kann man beschriften. Sie wird aufgesteckt.⁠ `,
        en: `Papercraft paper cup trophy`,
      },
    },
  ],

  materials: [
    {
      de: 'tägliche Inspiration und Disziplin',
      en: 'daily inspiration and discipline',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: '.jpg',
          en: '.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    ],
  },
};
