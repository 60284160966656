export default {
  id: '3-10-blog',
  category: ['gift_wrapping'],

  name: {
    de: 'Geldgeschenk zur Geburt: Babyflasche',
    en: 'Birth money gift: baby bottle',
  },
  subtitle: {
    de: 'B9 x H20 x T9 cm',
    en: 'W9 x H20 x D9 cm',
  },
  meta_title: {
    de: 'DIY Geldgeschenk zur  Geburt: Babyflasche aus Papier ',
    en: 'DIY money gift for birth: papercraft baby bottle ',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für eine Babyflasche kannst du etliche kreative Ideen umsetzen. Wie wäre es als Geldgeschenk zur Geburt?',
    en: 'With this template for a baby bottle you can implement quite a few creative ideas. How about as a gift of money for the birth?',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  pdf: {
    description: {
      de: `Mit dieser digitalen Vorlage für eine Babyflasche kannst du etliche kreative Ideen umsetzen.  Wie wäre es als Geldgeschenk zur Geburt? <a href="/de/blog/babyflasche-basteln/">In diesem Blogpost</a> zeige ich dir, wie einfach das geht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² Tonpapier zu verwenden.
      Damit die Babyflasche gelingt, benötigst du einen Drucker, 4 Bögen Tonpapier, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this digital template for our paper baby bottle you can realize a lot of creative ideas. <a href="/en/blog/babyflasche-basteln/">In this blogpost</a> I show you how easy it is.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use 130g/m² paper.
      To make the baby bottle succeed, you'll need a printer, 4 sheets of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'products/sfkbabyflascheshop/geldgeschenk-geburt-basteln-babyflasche-papier.jpg',
        en: 'products/sfkbabyflascheshop/geldgeschenk-geburt-basteln-babyflasche-papier.jpg',
      },
      alt_text: {
        de: 'Babyflasche aus Papier mit offenem Deckel und einer Idee als Geldgeschenk',
        en: 'Paper baby bottle with open lid and money gift idea',
      },
    },
  },
};
