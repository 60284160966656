export default {
  url: 'nikolaus-knallbonbon-basteln',
  countermark: 'vg07.met.vgwort.de/na/070541e6810c420bb940397866c55e23',
  imageFolder: 'nikolauskracker',
  landingPage: ['diy-geschenke'],
  meta_title: {
    de: 'DIY Knallbonbon als lachenden Nikolaus selber basteln (mit Vorlage)',
    en:
      'How to make a DIY Christmas cracker as laughing Santa Clause (free template)  ',
  },
  meta_description_google: {
    de:
      'Zum Nikolaustag darf es mal etwas anderes als ein Nikolausstiefel oder Nikolausschuh sein? Ich hätte hier einen lachenden Nikolaus als Knallbonbon oder Geschenkverpackung verpackt. Mit der Vorlage von PaperShape kannst du ihn ganz einfach selbst basteln.',
    en:
      "In search for something special for St. Nicholaus' Day? How about filling a laughing Santa Clause with sweets instead of Nicholaus boots. Grab our free PaperShape template and enjoy paper crafting this Nicholaus cracker. ",
  },
  meta_description_social: {
    de:
      'DIY Nikolaus Knallbonbon aus Papier zum selber basteln mit Vorlage #papershape #nikolaus #bastelnmitpapier ',
    en: 'DIY St. Nicholaus Day cracker #giftwrapping',
  },
  meta_images_social: [
    {
      de: 'knallbonbon-nikolaus-selber-basteln.jpg',
      en: 'christmas-cracker-st-nicholaus-paper.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'knallbonbon-nikolaus-selber-basteln.jpg',
      en: 'christmas-cracker-st-nicholaus-paper.jpg',
    },
    alt_text: {
      de:
        'DIY Knallbonbon als lachender Nikolaus aus Papier selber machen. Ums Knallen geht es weniger als vielmehr um die süßen Kleinigkeiten, die ihr darin versteckt. #bastelnmitpapier #papershape #geschenkeverpacken ',
      en:
        "How to make DIY christmas crackers for St. Nicholaus' Day. Your kids will love it! Free template from #papershape ",
    },
  },
  h1: {
    de: 'DIY Knallbonbon als lachenden Nikolaus selber basteln (mit Vorlage)',
    en:
      'How to make a DIY Christmas cracker as laughing Santa Clause (free template)',
  },
  description: {
    de: `
    <p> Ich könnte mich bekringeln vor Lachen, wenn ich diesen Nikolaus sehe.</p>

    <p>Und das tun hoffentlich auch eure Beschenkten. Mit diesem Nikolaus Knallbonbon (oder auch als Christmas Cracker geläufig) zaubert ihr euch ein
    Nikolausgeschenk für Erwachsene und Kinder, das nicht als Nikolausstiefel oder - schuh daherkommt. Allerdings knallt er nicht, wie ein Knallbonbon ursprünglich sollte.</p>
    
    <p>Ich habe für euch auf meinem Pinterest-Board noch weitere <a href="https://www.pinterest.de/papershape_/christmas-cracker/" target="_blank">Inspirationen für Knallbonbons </a> gespeichert.
     Somit könnt ihr meine Vorlage verwenden und diese anders gestalten. Folgt meinen Boards, wenn sie euch gefallen.</p>
    
     
      <p>
      Und wer nicht genug von DIY an Weihnachten bekommen kann, der versüßt sich die Abende mit diesen <a href="/de/blog/herzschachtel-basteln/">Herz-Geschenkboxen aus Papier</a> oder backt schon mal 
      <a href="/de/blog/papier-lebkuchenmann-basteln/">Papier-Lebkuchen</a> mit mir.
      <p>
      
    
    `,
    en: `
    <p>
    How about paper crafting a Christmas cracker for St. Nicholaus' Day that doesn't pop but creates a huge smile on the presentee's face?
    You can fill it with sweets or sayings or small toys. 
    </p>
      
    <p>
    On my pinterest-board I saved <a href="https://www.pinterest.de/papershape_/christmas-cracker/" target="_blank">inspirations for trendy, modern and just cute Christmas crackers</a>. So head over and don't forget to follow may pinterest profile if you like it.
    </p>

      <p>
     For more Christmas DIYs you should check out this <a href="/en/blog/papier-lebkuchenmann-basteln/">lovely gingerbread figures from paper</a>.</p>
  
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'knallbonbon-basteln-papier-nikolaus.jpg',
          en: 'diy-christmas-cracker-santa-clause-paper.jpg',
        },
        alt_text: {
          de:
            'DIY Knallbonbon als lachender Nikolaus aus Papier selber machen - mal kein Nikolausstiefel oder Nikolausschuh zu Weihnachten - kostenlose Anleitung von #papershape',
          en:
            "How to make a DIY Christmas crackers for St. Nicholaus' Day. Your kids will love it! Free template from #papershape",
        },
      },
    },
  ],

  stepsTitle: {
    de: 'Anleitung: Wie bastel ich einen Knallbonbon als Nikolaus',
    en: 'How to make a diy Christmas cracker as laughing Santa Clause',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_falten.jpg',
          en: '01_falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '01_falten.mp4',
      text: {
        de: `Druckt die kostenlose Nikolaus Vorlage auf 300g/m² DIN A4-Papier in roter Farbe aus. Als Erstes falzt ihr die lang gestrichelte Linie zwischen den Rauten mit einer komplett durchgezogenen Falzlinie vor. Dazu am besten ein langes Lineal
        an die gestrichelte Linie anlegen und mit der Ahle mit Druck einmal komplett von links nach rechts nachfahren. Das hilft euch in Schritt 2 die Rauten einfach auszuschneiden. 
        
.`,
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: '02_schneiden.jpg',
          en: '02_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_schneiden.mp4',
      text: {
        de: ` Schneidet mit einer Schere die Nikolaus-Verpackung entlang der durchgezogenen Linien aus. Spart die Rauten aus. 
        
        `,
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: '04_rautenschneiden.jpg',
          en: '04_rautenschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '04_rautenschneiden.mp4',
      text: {
        de: `Die Rauten schneidet ihr so aus: legt die Vorlage so vor hin, dass ihr die kurz gestrichelten Linien seht. Knickt den oberen Teil des Nikolauses entlang der lang 
        gestrichelten Linie nach hinten um. Jetzt könnt ihr ganz einfach die entstandenen Dreiecke ausschneiden und benötigt keinen Cutter. 
       Das Gleiche macht ihr bei den Rauten im unteren Teil des Nikolauses. 
        
        
       `,
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: '05_gesicht.jpg',
          en: '05_gesicht.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '05_gesicht.mp4',
      text: {
        de: `Nun das lachende Nikolaus-Gesicht: auf Seite 2 der Vorlage findet ihr die einzelnen Elemente für das Gesicht: Augen, Wangen, Schnurbart, Bart, Nase, Mund,
       Zunge und die Haare.
        Druckt sie auf 90g/m² bis 120g/m² Papier aus - ihr könnt sie auch einfach auf farbiges Papier abpausen und dann ausschneiden. Verwendet nicht dickeres Papier, weil 
        durch die anschließende Krümmung des Krackers die einzelnen Teile wieder abfallen bzw. abstehen würden. `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '09_gesichtaufkleben.jpg',
          en: '09_gesichtaufkleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '09_gesichtaufkleben.mp4',
      text: {
        de: `Klebt in die Mitte des Nikolaus-Knallbonbons die Augen auf. Bei der SVG-Datei sehr ihr wo sie hingehören und ihr solltet mit den Augen starten. Achtet darauf, dass
        ihr das Gesicht auf der schönen und damit unbedruckten Seite aufklebt. Verwendet hierzu am besten keinen flüssigen Kleber, weil dieser bei dünnem Papier durchscheint und
        unregelmäßige Klebeflächen hinterlässt.        
        Für alle mit Schneideplotter: die Augen werden ausgeschnitten und ihr könnt einfach weißes Papier schwarz anmalen (oder gleich schwarzes Papier nehmen) und von hinten gegenkleben. Anschließend in dieser Reihenfolge die Elemente ankleben: Nase, Bart, Wangen oberhalb des Bartes, Mund, Zunge, Bart und zum Schluss die Haare. 
        Das ganze Gesicht nun gut trocknen lassen - am besten eine Stunde.
           Denn sind die Elemente nicht genug festgeklebt, lösen sie sich im nächsten Schritt durch das Falten wieder ab. 
           Tipp: Wer keine Lust auf das Ausschneiden des Gesichtes hat, der kann das Gesicht auch einfach aufmalen.
    
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '06_innenfalten.jpg',
          en: '06_innenfalten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '06_innenfalten.mp4',
      text: {
        de: `Falzt jetzt erst die kurz gestrichelten Linien mit einem Lineal und einer Ahle vor - wie aus Schritt 1 bekannt. Dabei werdet ihr auch das Gesicht auf der Rückseite 
        mitfalzen und das ist auch gewollt. Knickt anschließend diese Linien vorsichtig zu euch hin und achtet darauf, dass sich das Gesicht nicht löst. Sonst nochmal nachkleben. `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '07_punkte.jpg',
          en: '07_punkte.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '07_punkte.mp4',
      text: {
        de: `Die weißen Punkte an den Enden des Nikolaus-Knallbonbons werden euch entweder von eurem Schneideplotter ganz easy ausgeschnitten oder ihr verwendet eine Papierlochzange oder
        ihr malt sie mit einem weißen Stift auf. Falls ihr sie ausschneidet, könnt ihr von hinten dann einen Streifen von einem weißen Blatt Kopierpapier gegenkleben. 
   `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '08_schleife.jpg',
          en: '08_schleife.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '08_schleife.mp4',
      text: {
        de: `Nun nur noch den Nikolaus-Kracker mit Kleinigkeiten füllen und mit zwei schönen Schleife an den Enden zubinden. Die Lasche in der Mitte der Geschenkverpackung würde
        ich nicht zukleben. Sie ist dazu gedacht den Kracker schön abzuschließen. Et voilá und viel Spaß beim Verschenken dieses liebevoll gestalteten Nikolausgeschenkes. Schickt mir gerne Fotos
        oder nutzt #3dpaperdiy beim Teilen. Denn darüber freue ich mich riesigggg.  
   `,
        en: ``,
      },
    },
  ],

  downloadName: 'knallbonbon-cracker-nicholaus_papershape.pdf',
  materials: [
    {
      de: '1 DINA4 Papier in rot (300g/m²)',
      en:
        '1 cardstock paper in red (8.5in x 11in) (weight: 65lb-110lb / 176gsm-300gsm)',
    },
    {
      de:
        'Unterschiedlich farbige Papiere für das Gesicht (hier könnt ihr Papierreste nutzen) in 90g/m² bis 120g/m²',
      en:
        'different colored papers for the face (reuse paper leftovers 90sgm-120gsm)',
    },
    {
      de: '2 Bänder für die Schleifen (ca. 10mm Breite)',
      en: '2 ribbons for the loops (apprx. 10cm broad)',
    },
    {
      de: 'Bastelkleber',
      en: 'Glue',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'knallbonbon-selber-basteln-papier-nikolaus.jpg',
          en: 'diy-christmas-cracker-paper-nicholaus.jpg',
        },
        alt_text: {
          de:
            'DIY Knallbonbon als lachender Nikolaus aus Papier selber machen - mal kein Nikolausstiefel oder Nikolausschuh zu Weihnachten - kostenlose Anleitung von #papershape',
          en:
            "How to make a diy christmas crackers for St. Nicholaus' Day. Your kids will love it! Free template from #papershape",
        },
      },
    ],
  },
};
