export default {
  id: '2-22-sfk',
  category: ['easter'],
  name: {
    de: 'Küken (2 Stk)',
    en: 'Chick (2x)',
  },
  subtitle: {
    de: 'B11 x H18 x T19 cm',
    en: 'W11 x H18 x D19 cm',
  },
  meta_title: {
    de: 'Niedliche Küken aus Papier basteln in 3D',
    en: '3d Papercraft Chicks from paper ',
  },
  meta_description_google: {
    de: 'Niedliche gelbe Osterküken sind neben bunten Eiern und hüpfenden Osterhasen eine schöne Osterdeko. Bastelt sie aus Papier mit unserer Vorlage. ',
    en: 'Yellow ducks are a beautiful Easter decoration next to colourful eggs and jumping Easter bunnies. Make them from paper with our pattern. ',
  },
  meta_description_social: {
    de: '3D Osterküken basteln aus Papier zu Ostern #papercraft #bastelnmitpapier #osterdeko #diy',
    en: 'Make this lovely 3d chick for Eastern #papercraft #paper #easterdecor #diy',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?    
    - 7 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität (darin sind 2 Enten enthalten wie auf dem Bild)
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -7 printed DINA4 cardboard sheets for the perfect stability (including 2 ducks as shown on the product image)
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 8 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 7 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 7 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 8 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkkueken/osterkueken-basteln.jpg',
        en: 'products/sfkkueken/osterkueken-basteln.jpg',
      },
      alt_text: {
        de: '3D Osterküken basteln mit Papier zu Ostern #papercraft #bastelnmitpapier #osterdeko #diy #origami',
        en: 'Make this lovely 3d chick for Eastern #papercraft #paper #easterdecor #diy',
      },
    },
    price: 6.9,
    priceThirdCountry: 6.9,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkkueken/osterkueken-basteln.jpg',
        en: 'products/sfkkueken/osterkueken-basteln.jpg',
      },
      alt_text: {
        de: '3D Osterküken basteln aus Papier #papercraft #bastelnmitpapier #osterdeko #diy',
        en: 'Make this lovely 3d chick for Eastern #papercraft #paper #easterdecor #diy',
      },
    },
    colors: {
      color1: {
        de: 'Großes Küken',
        en: 'Big chick',
      },
      color2: {
        de: 'Kleines Küken',
        en: 'Small chick',
      },
    },
    disableColors: {
      color2: ['schwarz'],
      color1: ['schwarz'],
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },
};
