export default {
  url: 'herzschachtel-basteln',
  countermark: 'vg07.met.vgwort.de/na/c47eafb883394e968cb3fd146e8e8da6',
  imageFolder: 'herzboxen',
  meta_title: {
    de: 'Herzschachtel selber basteln mit Papier (kostenlose Vorlage)',
    en: '3d gemoetric heart gift box with template from #papershape',
  },
  meta_description_google: {
    de:
      'Süße Herzschachtel zum Verschenken am Valentinstag, Muttertag, Vatertag oder als Geschenkverpackung zu Weihnachten, zur Hochezit oder zum Geburtstag für kleine Überraschungen. Die kostenlose Anleitung und Vorlage von PaperShape zeigt dir wie es geht.',
    en:
      "Cute gemoetric heart gift box for sweets and other lovely presents. Use it for Valentine's Day or for Christmas. With the template from PaperShape it is super easy.",
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: '3d-herzschachtel-selber-basteln.jpg',
      en: 'heart-gift-box-papercraft.jpg',
    },
    alt_text: {
      de: 'DIY Herzschachtel basteln mit Vorlage und Anleitung von #papershape',
      en: '3d gemoetric heart gift box with template from #papershape',
    },
  },
  h1: {
    de: 'Herzschachtel selber basteln mit Papier (kostenlose Vorlage)',
    en: 'How to make a geometric heart gift box from paper (free printable)',
  },
  description: {
    de: `
     <p>Die meisten von uns schenken gerne und weil es von Herzen kommt, findet ihr in diesem Post eine Herzschachtel aus Papier, die genau dem Ausdruck verleiht. </p>
     <p>Ihr könnt diese Herzbox als Weihnachtsgeschenk
     genauso gut einsetzen wie zum Valentinstag oder als Hochzeitsgastgeschenk. Weil sie nicht so groß ist, sind die Herzschachteln schnell gebastelt und bieten trotzdem 
     genügend Platz für eine Kleinigkeit von Herzen.</p>
     
      Zum Nikolaustag findet ihr hier auf dem Blog auch eine coole Verpackung in Form eines  <a href="/de/blog/nikolaus-knallbonbon-basteln/">lachenden Nikolaus-Knallbonbons</a>. 
      Den müsst ihr einfach ausprobieren, weil allein schon das Basteln einen Schmunzeln lässt.
      
    `,
    en: `
      <p>
      Most of us love making presents.
      </p>
       <p>
       In this post I'll show you how to make lovely heart gift boxes that come from heart. You can use them for any occasion - birthday, Valentine's Day, 
       Christmas or even for your wedding as guest presents.
      </p>
      
      <p>
      If you are searching for a gift box for Nicholaus' Day you must try this  <a href="/en/blog/nikolaus-knallbonbon-basteln/">Christmas cracker</a> that has a St. Clause shape.
      </p>
    
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'herzbox-basteln-vorlage.jpg',
          en: 'heartbox-papercraft-template.jpg',
        },
        alt_text: {
          de:
            'DIY Herzbox selber basteln mit Vorlage und Anleitung von #papershape',
          en:
            'diy paper craft tutorial: heart gift box with free printable from #papershape',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: '3d-herzschachtel-basteln-papier.jpg',
          en: 'heart-gift-box-wrapping.jpg',
        },
        alt_text: {
          de:
            "DIY Herzschachtel selber machen mit Vorlage und Anleitung von #papershape'",
          en:
            'diy paper craft tutorial: heart gift box with free template from #papershape',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },

    {
      image: {
        filename: {
          de: 'herzboxen-selbermachen.jpg',
          en: 'heart-gift-box-template-wrapping.jpg',
        },
        alt_text: {
          de:
            "DIY Herzschachtel selber machen mit Vorlage und Anleitung von #papershape'",
          en:
            'diy paper craft tutorial: heart gift box with free template from #papershape',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
  ],
  stepsBackgroundColor: 'rgb(243, 243, 243)',
  stepsTitle: {
    de: 'Wie bastel ich eine Herzschachtel aus Papier - Anleitung',
    en: 'How to make a heart gift box from paper',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '01_schneiden.mp4',
      text: {
        de: ` Druckt euch die Vorlage auf Papier eurer Wahl aus. Ich würde hier stabiles Papier mit 230-300g/m² empfehlen. Dann geht es mit dem Ausschneiden der durchgezogenen Linien los oder ihr nutzt die SVG und schneidet die 
        Herzschachtel mit eurem Schneideplotter aus. Dann werden auch die filigranen Herzen auf dem Deckel ausgeschnitten.
        
        `,
        en: `.`,
      },
    },
    {
      image: {
        filename: {
          de: '02_falzen.jpg',
          en: '02_falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_falzen.mp4',
      text: {
        de: `Jetzt legt ihr ein Lineal an die gestrichelten Linien an und fahrt diese mit einer Ahle oder einem Falzbein mit Druck ab.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '03_falten.jpg',
          en: '03_falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '03_falten.mp4',
      text: {
        de:
          'Legt die Herzschachtel so vor hin, dass ihr die gestrichelten Linien seht. Knickt nun alle kurz gestrichelten Linien nach oben, d.h. zu euch hin und die eine lang gestrichelte Linie nach unten, d.h. von euch weg.',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '04_kleben.jpg',
          en: '04_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '04_kleben-1.mp4',
      text: {
        de:
          'Die Zahlen für die Reihenfolge habe ich bewusst weggelassen, weil sie sich nicht so schön in einer Verpackung machen. Ihr klebt auf die Laschen nun je einen Klebepad (lasst die zwei Laschen an dem Herzen mit den 3 kleineren Herzen aus). Beginnt anschließend mit dem Verkleben der Laschen.',
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '05_einlage.jpg',
          en: '05_einlage.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      video: '05_einlage.mp4',
      text: {
        de: `Für den Boden - damit man die verklebten Laschen nicht sieht - legt ihr das ausgeschnittene Herz rein.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '06_stecken.jpg',
          en: '06_stecken.jpg',
        },
        alt_text: {
          de: '',
          en: ' ',
        },
      },
      video: '06_stecken.mp4',
      text: {
        de: `Die Herzbox wird verschlossen, indem ihr die Laschen in die Schlitze reinsteckt.`,
        en: ``,
      },
    },
  ],

  downloadName: 'herzschachtel-heart-box_papershape.pdf',
  materials: [
    {
      de: '1 DINA4 Fotokarton (270-300g/m²)',
      en: '1 sheet of strong paper (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'herzschachtel-basteln-vorlage.jpg',
          en: 'heart-gift-box-printable.jpg',
        },
        alt_text: {
          de:
            'DIY Herzschachtel basteln mit Vorlage und Anleitung von #papershape',
          en:
            'diy paper craft tutorial: heart gift box with free printable from #papershape',
        },
      },
    ],
  },
};
