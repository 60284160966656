import React from 'react';
import { connect } from 'react-redux';
import Lang from './Lang';
import { getDeliveryTime } from '../state/order/orderSelectors';

function DeliveryTime({ deliveryTime }) {
  return (<>
    <Lang
      de={`Lieferzeit ca. ${deliveryTime} Werktage`}
      en={`Delivery time approx. ${deliveryTime} working days`}
    />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    deliveryTime: getDeliveryTime(state),
  };
};

export default connect(mapStateToProps)(DeliveryTime);
