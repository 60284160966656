export default {
  id: '1-05-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Stier',
    en: 'Bull',
  },
  subtitle: {
    de: 'B23 x H26 x T27 cm',
    en: 'W23 x H26 x D27 cm',
  },
  meta_title: {
    de: 'Stier Papiertier',
    en: 'Bull Paper Animal',
  },
  meta_description_google: {
    de: 'Der Stier Kopf zum Aufhängen verzaubert als Wanddeko jeden Raum. Die Stier-Trophäe lässt sich einfach falten und stecken. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The bull head enchants each room with lots of space for creativity.  100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: '3D Stier aus Papier Wanddeko DIY Papierskulptur #papershape',
    en: '3d paper bull head walldecor diy #papershape ',
  },
  description: {
    de: `    Kuhl, was aus clever durchdachten Papierfaltungen entstehen kann! Die 3D Origami Kuh vereint den perfekten Kontrast aus geometrischen Formen und natürlichem Material. Durch die innovative Klebetechnik der Papierformen entstehen fließende Übergänge, die die 3D Origami Kuh einfach echt wirken lassen. Holt euch eine Portion frische Landluft in eure Wohnung!
    
    ANLEITUNG 
    
    In einem kurzen Video erkläre ich euch live, wie ihr in 3 Schritten zu eurer einzigartigen 3D Origami Kuh gelangt. Praktische Tipps inklusive.
    
    Schneide alle Papierteile mit einer Schere oder einem Cutter aus. Alle durchgezogenen Linien müssen geschnitten werden. Schneide auch dort ein, wo eine kleine Schere angegeben ist.
    
    Um die Papierteile zu falten, musst du sie vorerst mit einem Lineal und einem Buttermesser oder einer Ahle vorfalzen. Lege dazu das Lineal an die gestrichelten Markierungen an und fahre mit dem Buttermesser und viel Druck am Lineal auf den Linien entlang. Anschließend knickst du die Linien wie in der Anleitung angegeben entweder nach außen oder innen.
    
    Jetzt gilt es alle Papierteile des 3D Origami miteinander zu verkleben. Verwende dazu die Klebepads, die im Set mitgeliefert sind. Das Verkleben erfolgt in nummerischer Reihenfolge bei 1, 2, 3, etc. Klebe die Pads von außen auf die Lasche und halte einen Sicherheitsabstand von ca. 2mm ein. Beim Verkleben unbedingt auf das Modell von außen schauen.
    
    DETAILS
    
    ab 14 Jahren empfohlen
    
    KOMPLETTES SET BEINHALTET
    
    8 DIN A4 Seiten Fotokarton Origami Kuh
    Doppelseitige Klebepads
    Detaillierte schrittweise Anleitung
    
    BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser

    `,
    en: `    Cool what can be made out of witty thoughtful paper folds! The 3D origami cow combines the perfect contrast of geometric shapes and natural materials. By innovative gluing technique the paper forms allow smooth transitions that make the 3D origami cow look so real. Get yourself a dose of fresh country air in the house!
    And so it goes! In a short video I explain you live, how you craft your unique 3D origami cow in 3 steps. Practical tips are included.
    
    STEP BY STEP
    
    Cut the paper parts.
    To fold the paper parts, you need to pre-fold them first with a ruler and a bread knife or an awl. Set the ruler to the dashed line and press with the butter knife along. Then fold the parts as specified in the instructions: either outwardly or inwardly.
    Now glue all the paper parts of the 3D origami together. Use the pads, which are included in the set. The folding takes place in numerical order in 1, 2, 3, etc. Set the adhesive pads on the outer sides of the tabs and keep a safe distance of about 2mm. When gluing necessarily watch the model from the outside.
    
    DETAILS
    
     Age recommendation 14+
  
    COMPLETE KIT INCLUDES
    
    8 DIN A4 cardboard sheets Origami Cow
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife`,
  },
  colorinspirations: [
    {
      caption: {
        de: 'schokobraun-weiß',
        en: 'blue-yellow',
      },
      image: {
        filename: {
          de: 'products/sfkkuh/papier-stier-3d-tierkopf.jpg',
          en: 'products/sfkkuh/papier-stier-3d-tierkopf.jpg',
        },
        alt_text: {
          de: '3D Stier aus Papier Wanddeko DIY Papierskulptur #papershape',
          en: '3d paper bull head walldecor diy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'rot-weiß',
        en: 'rot-weiß',
      },
      image: {
        filename: {
          de: 'products/sfkkuh/kuh-rot-weiss.jpg',
          en: 'products/sfkkuh/kuh-rot-weiss.jpg',
        },
        alt_text: {
          de: '3D Stier aus Papier Wanddeko DIY Papierskulptur #papershape',
          en: '3d paper bull head walldecor diy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'türkis-weiß',
        en: 'türkis-weiß',
      },
      image: {
        filename: {
          de: 'products/sfkkuh/kuh-tuerkis-weiss.jpg',
          en: 'products/sfkkuh/kuh-tuerkis-weiss.jpg',
        },
        alt_text: {
          de: '3D Stier aus Papier Wanddeko DIY Papierskulptur #papershape',
          en: '3d paper bull head walldecor diy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'steingrau-weiß',
        en: 'steingrau-weiß',
      },
      image: {
        filename: {
          de: 'products/sfkkuh/kuh-steingrau-weiss.jpg',
          en: 'products/sfkkuh/kuh-steingrau-weiss.jpg',
        },
        alt_text: {
          de: '3D Stier aus Papier Wanddeko DIY Papierskulptur #papershape',
          en: '3d paper bull head walldecor diy #papershape',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkkuh/kuh-weiss.jpg',
        en: 'products/sfkkuh/kuh-weiss.jpg',
      },
      alt_text: {
        de: '3D Stier aus Papier Wanddeko DIY Papierskulptur #papershape ',
        en: '3d paper bull head walldecor diy #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Kopf & Ohren',
        en: 'Head & ears',
      },
      color2: {
        de: 'Hörner',
        en: 'Horns',
      },
    },
    disableColors: {
      color2: ['bunt', 'schwarz'],
      color1: ['schwarz'],
    },

    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
