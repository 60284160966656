export default {
  url: 'bienenwaben-adventskalender-basteln',
  countermark: 'vg07.met.vgwort.de/na/055cfda4709244bcb5abe4630f510aad',
  imageFolder: 'adventskalender-waben',

  landingPage: ['diy-geschenke'],
  meta_title: {
    de: '3D Honigwaben Adventskalender für fleißige Bienchen ohne Kleben',
    en: '3d honeycomb Advent calendar from paper with no glue (free templates)',
  },
  meta_description_google: {
    de:
      'DIY Adventskalender mit Papier basteln als Dankeschön für die fleißigen Bienchen. Einfach die 3d Honigwaben füllen und verschenken.',
    en:
      'Try this colorful christmas wreath for your christmas decoration. The wreath is so easy to make. All you need are some loops from paper.',
  },
  meta_description_social: {
    de:
      'DIY Adventskalender aus Papier basteln als Dankeschön für die fleißigen Bienchen. Einfach die 3d Honigwaben füllen und verschenken. #papershape #adventskalenderbasteln #bastelnmitpapier',
    en:
      'Try this colorful christmas wreath for your christmas decoration. The wreath is so easy to make. All you need are some loops from paper. #papercraft #adventcalendar',
  },
  meta_images_social: [
    {
      de: '',
      en: '',
    },
  ],
  image_main: {
    filename: {
      de: 'diy-adventskalender-basteln-bienenwaben-papier.jpg',
      en: 'diy-advent-calendar-honeycomb.jpg',
    },
    alt_text: {
      de:
        'DIY Adventskalender basteln aus Papier als 3D Bienenwaben für eure fleißigen Bienchen #papershape #bastelnmitpapier #adventskalenderbasteln',
      en:
        'DIY Advent calendar idea from paper: 3d honeycombs for filling #papershape ',
    },
  },
  h1: {
    de: '3D Honigwaben Adventskalender für fleißige Bienchen ohne Kleben',
    en: '3d honeycomb Advent calendar from paper with no glue (free templates)',
  },
  description: {
    de: `
    
     <p>Sich die Wartezeit bis Heilig Abend zu versüßen, gehört schon fast als Tradition jedes Jahr dazu. Egal, ob Kinder, der Freund oder Partner, irgendwie ist da dieser Überraschungsei-Effekt, der uns jeden Tag auf das neue beglückt. Damit ihr aber nicht jedes Jahr auf Schachteln oder die Säckchen zurückgreifen müsst, möchte ich euch dieses Jahr einen etwas besonderen Adventskalender vorstellen.
     Denn einen Adventskalender selber basteln ist nicht wirklich schwer.  </p>
     <p>Fleißige Bienchen helfen uns das ganze Jahr über, damit wir unsere Ideen, Visionen und Ziele erreichen. Seien es Freunde, Eltern oder Kollegen, die Aufgaben übernehmen, mental unterstützen oder zuhören. Mit diesen 3D Honigwaben 
      könnt ihr nun ganz einfach diesen Menschen Danke sagen und ein Schmunzeln auf ihr Gesicht zaubern.  
      </p>
      Wer eher nach einem Adventskalender für Männer sucht, für den ist dieser <a href="/de/blog/billard-adventskalender-basteln/">Billard-Adventskalender</a> auf jeden Fall das Richtige - gleich mal eine Runde Fun verschenken und gefüllte Billiard-Kugeln. 
       
         
    `,
    en: `
      <p>
      During the year there are so many people around us helping us to achieve our goals. If you want to say thank you to these hardworking bees you have to try this 3d honeycomb Advent calendar.
      It ís super easy to make and stores tiny sweets. 
      </p>
          <p>
      If you are searching for an Advent calender for men you must check out this <a href="/en/blog/billard-adventskalender-basteln/">billiard Advent calendar from paper</a>.
      </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'honigwaben-adventskalender-basteln.jpg',
          en: 'honeycomb-advent-calendar-paper.jpg',
        },
        alt_text: {
          de:
            'DIY Adventskalender basteln aus Papier als 3D Bienenwaben für eure fleißigen Bienchen #papershape',
          en:
            'DIY Advent calendar idea from paper: 3d honeycombs for filling #papershape ',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'papier-adventskalender-basteln-bienen.jpg',
          en: '3d-advent-calender-paper-honeycomb.jpg',
        },
        alt_text: {
          de:
            'DIY Adventskalender basteln aus Papier als 3D Bienenwaben für eure fleißigen Bienchen #papershape',
          en:
            'DIY Advent calendar idea from paper: 3d honeycombs for filling #papershape ',
        },
      },
    },
  ],
  stepsBackgroundColor: 'rgb(243, 243, 243)',
  stepsTitle: {
    de: 'Wie bastel ich einen 3d Honigwaben Adventskalender aus Papier',
    en: 'How do I make a 3d honeycomb Advent calendar from paper',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '01_schneiden.mp4',
      text: {
        de: `Ihr benötigt drei verschiedene Gelbtöne und insgesamt acht DINA4 Seiten, denn auf der Vorlage findet ihr drei Honigwaben aufgedruckt.
        Verwendet am besten Papier mit einer Grammatur von 220g/m² - schließlich soll es stabil genug für eure kleinen Geschenke sein.
        Los geht es mit dem Ausschneiden der Honigwaben - immer schön entlang der durchgezogenen Linien.
      
        
        `,
        en: `You'll need 3 different colored papers and 8 pages in total. Print out the template and start cutting along the solid lines. The template results in 3 honeycombs. `,
      },
    },
    {
      image: {
        filename: {
          de: '02_falzen.jpg',
          en: '02_falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_falzen.mp4',
      text: {
        de: `Fahrt alle gestrichelten Linien mit einer Ahle oder Buttermesser und einem Lineal nach, damit eine schöne Kante entsteht.`,
        en: `Place a ruler on all the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines.`,
      },
    },
    {
      image: {
        filename: {
          de: '03_falten.jpg',
          en: '03_falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '03_falten.mp4',
      text: {
        de: `Faltet alle kurz gestrichelten Linien zu euch hin und alle lang gestrichelten Linien von euch weg. Dadurch entsteht die 3d Form.`,
        en: `Fold all the short dotted lines inwards, which means to fold up. Fold all the long dotted lines outwards, which means to fold down.`,
      },
    },

    {
      image: {
        filename: {
          de: '04_stecken.jpg',
          en: '04_stecken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '04_stecken.mp4',
      text: {
        de: `Nun den oberen Teil mit dem unteren zusammenstecken. Öffne es nun wieder und befülle deine Bienenwaben. Jetzt kannst du auch die Adventskalender-Nummern ausschneiden und aufkleben.
  `,
        en: `Now plug the top part of the honeycomb into the bottom part. Reopen it again and fill your honeycomb with you favorite sweets. Finally stick the numbers on the top of the honeycomb.`,
      },
    },
  ],

  downloadName: 'honigwaben-adventskalender_papershape.pdf',
  materials: [
    {
      de: '8 DINA4 Papier in 3 unterschiedlichen Gelbtönen (220g/m²)',
      en: '8 differently colored papers (8.5in x 11in) (weight: 65lb-110lb)',
    },
    {
      de: 'Kleber für die Adventstage-Labels',
      en: 'Glue ',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'diy-adventskalender-basteln-papier-waben.jpg',
          en: 'diy-advent-calender-paper-honeycomb.jpg',
        },
        alt_text: {
          de:
            'DIY Adventskalender basteln aus Papier als 3D Bienenwaben für eure fleißigen Bienchen #papershape',
          en:
            'DIY Advent calendar idea from paper: 3d honeycombs for filling #papershape ',
        },
      },
    ],
  },
};
