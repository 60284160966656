import React, { Component } from 'react';
import { connect } from 'react-redux';
import PsBlogPostView from '../../common/PsBlogPostView';
import { getTextBySelectedLang } from '../../data/lang';
import Lang from '../../common/Lang';
import { Grid, Row, Col } from 'react-bootstrap';
import PsMeta from '../../common/PsMeta';
import { getGeschenkeSelbermachenPosts } from '../../state/blog/blogSelectors';

class Diygeschenke extends Component {
  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang(
            'DIY Geschenke mit Papier',
            'DIY paper crafts'
          )}
          description={getTextBySelectedLang(
            'In meinem Blog findest du trendige Bastelideen aus Papier mit kostenlosen Vorlagen Lass dich von moderner Deko aus Origami bis zu 3d Papercraft inspirieren.',
            'In my blog you will find amazing diy paper crafts.'
          )}
          socialdescription={null}
          socialimages={null}
        />

        <Grid>
          <Row style={{ marginTop: '30px' }}>
            <h1 className="text-center" style={{ marginBottom: '30px' }}>
              <Lang de="DIY Geschenke mit Papier" en="DIY paper crafts" />
            </h1>
            <p>
              <Lang
                de="DIY Geschenke aus einem einfachen Stück Papier moderne Bastelideen umsetzen - das möchte ich dir mit PaperShape zeigen. Egal, ob du ein originelles DIY Geschenk suchst,
              deine Wohnung mit selbstgemachter Deko verschönern möchtest oder einfach aus Langeweile zu Papier, Pappe oder Karton greifst, hier findest du genau die richtigen Vorlagen und Bastelanleitungen
              für deine Kreativität.
              
            "
                en=""
              />
            </p>
            {this.props.blogPosts.map((blogPost, index) => (
              <div key={blogPost.url}>
                <Col xs={6} sm={4}>
                  <PsBlogPostView blogPost={blogPost} withDescription />
                </Col>
                {++index % 2 === 0 && <div className="clearfix visible-xs" />}
                {index % 3 === 0 && (
                  <div className="clearfix visible-sm visible-md visible-lg" />
                )}
              </div>
            ))}
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blogPosts: getGeschenkeSelbermachenPosts(state),
  };
};

export default connect(mapStateToProps)(Diygeschenke);
