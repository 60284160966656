export default {
  id: '2-06-sfk',
  category: [ 'home_decor'],
  name: {
    de: 'Sparschwein',
    en: 'Piggy Bank',
  },
  subtitle: {
    de: 'B13 x H16 x T17 cm',
    en: 'W13 x H16 x D17 cm',
  },
  meta_title: {
    de: '3D Papier Sparschwein im Low Poly Design',
    en: '3d Piggybank from paper ',
  },
  meta_description_google: {
    de: 'Das 3D Papier Sparschwein ist eine süße Idee für alle, die Geldgeschenke verschenken möchten. Ob zur Hochzeit oder Geburtstag.  100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'With this 3d paper piggy bank you have a cute idea of how to make gifts of money - either for a wedding or a birthday party.',
  },
  meta_description_social: {
    de: '3d Papier Sparschwein als Geldgeschenk zur Hochzeit oder Geburtstag #papershape',
    en: '3d paper piggy bank - gift of money for a wedding or birthday party #papershape',
  },
  description: {
    de: `KOMPLETTES SET BEINHALTET
    
    5 DIN A4 Seiten Fotokarton
    Doppelseitige Klebepads
    Detaillierte schrittweise Anleitung
    
  ANLEITUNG 
    
    In einem kurzen Video erkläre ich euch live, wie ihr in 3 Schritten zu eurem einzigartigen 3D Origami Sparschwein gelangt. Praktische Tipps inklusive.
    Schneide alle Papierteile mit einer Schere oder einem Cutter aus. Alle durchgezogenen Linien müssen geschnitten werden. Schneide auch dort ein, wo eine kleine Schere angegeben ist.
    Um die Papierteile zu falten, musst du sie vorerst mit einem Lineal und einem Buttermesser oder einer Ahle vorfalzen. Lege dazu das Lineal an die gestrichelten Markierungen an und fahre mit dem Buttermesser und viel Druck am Lineal auf den Linien entlang. Anschließend knickst du die Linien wie in der Anleitung angegeben entweder nach außen oder innen.
    Jetzt gilt es alle Papierteile des 3D Origami miteinander zu verkleben. Verwende dazu die Klebepads, die im Set mitgeliefert sind. Das Verkleben erfolgt in nummerischer Reihenfolge bei 1, 2, 3, etc. Klebe die Pads von außen auf die Lasche und halte einen Sicherheitsabstand von ca. 2mm ein. Beim Verkleben unbedingt auf das Modell von außen schauen. BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser
    ab 14 Jahren empfohlen`,

    en: `
        COMPLETE KIT INCLUDES
    
    5 DIN A4 cardboard sheets
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife

    Age recommendation 14+
    
    STEP BY STEP
    
    Cut the paper parts.
    To fold the paper parts, you need to pre-fold them first with a ruler and a bread knife or an awl. Set the ruler to the dashed line and press with the butter knife along. Then fold the parts as specified in the instructions: either outwardly or inwardly.
    Now glue all the paper parts of the 3D origami together. Use the pads, which are included in the set. The folding takes place in numerical order in 1, 2, 3, etc. Set the adhesive pads on the outer sides of the tabs and keep a safe distance of about 2mm. When gluing necessarily watch the model from the outside.`,
  },

  pdf: {
    description: {
      de: `Egal, ob du das Schweinchen als Glücksschwein verschenken oder als Sparschwein basteln möchtest. Mit diesem Dekoobjekt dürfte keinem deine kreative Ader entgehen.
    Das Sparschwein hat hinten eine Öffnung, sodass du ganz einfach Münzen und Scheine hineinwerfen kannst. Und wenn das Papierschweinchen gut gemästet ist, dann bitte nicht
    schlachten, sondern einfach den Boden unten öffnen und die Fütterung von Neuem ansetzen. Mit der digitalen Vorlage könnt ihr euch ein Sparschwein für jedes Ziel basteln:
    eines für die nächste Reise, ein anderes für das nächste Konzert, oder eines für die nächste PaperShape Vorlage ;)

♥DETAILS: ab 14 Jahren

♥ KOMPLETTSET ENTHÄLT

Digitale Vorlage als PDF zum Herunterladen
Detaillierte Schritt-für-Schritt-Anleitung

♥ BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : 5 DIN A4 Seiten Fotokarton oder Tonkarton (mind. 160g/m), Schere, Lineal, Buttermesser, Kleber oder Klebepads`,

      en: `Egal, ob du das Schweinchen als Glücksschwein verschenken oder als Sparschwein basteln möchtest. Mit diesem Dekoobjekt dürfte keinem deine kreative Ader entgehen.
    Das Sparschwein hat hinten eine Öffnung, sodass du ganz einfach Münzen und Scheine hineinwerfen kannst. Und wenn das Papierschweinchen gut gemästet ist, dann bitte nicht
    schlachten, sondern einfach den Boden unten öffnen und die Fütterung von Neuem ansetzen. Mit der digitalen Vorlage könnt ihr euch ein Sparschwein für jedes Ziel basteln:
    eines für die nächste Reise, ein anderes für das nächste Konzert, oder eines für die nächste PaperShape Vorlage ;)

♥DETAILS: ab 14 Jahren

♥ KOMPLETTSET ENTHÄLT

Digitale Vorlage als PDF zum Herunterladen
Detaillierte Schritt-für-Schritt-Anleitung

♥ BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : 5 DIN A4 Seiten Fotokarton oder Tonkarton (mind. 160g/m), Schere, Lineal, Buttermesser, Kleber oder Klebepads`,
    },
    image: {
      filename: {
        de: 'products/sfksparschwein/papier-sparschwein-papierfigur-3d.jpg',
        en: 'products/sfksparschwein/sparschwein.jpg',
      },
      alt_text: {
        de: '3d Papier Sparschwein als Geldgeschenk zur Hochzeit oder Geburtstag #papershape',
        en: '3d paper piggy bank - gift of money for a wedding or birthday party #papershape',
      },
    },
    price: 6.9,
    priceThirdCountry: 6.5,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfksparschwein/sparschwein-basteln-papier.jpg',
        en: 'products/sfksparschwein/sparschwein-basteln-papier.jpg',
      },
      alt_text: {
        de: '3d Papier Sparschwein als Geldgeschenk zur Hochzeit oder Geburtstag #papershape',
        en: '3d paper piggy bank - gift of money for a wedding or birthday party #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Sparschwein',
        en: 'Piggy Bank',
      },
    },
    disableColors: {
      color1: ['schwarz'],
    },
    price: 14.9,
    priceThirdCountry: 12.5,
  },
};
