export default {
  url: 'baustellen-geburtstag-deko-basteln',
  countermark: 'vg07.met.vgwort.de/na/ac7c886a9d634f51895a6cc0e86c0095',
  imageFolder: 'baustelle',

  name: {
    de: 'Bagger',
    en: 'Execavator ',
  },
  id: '3-24-blog',
  pdf: {
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'content/baustelle/bagger-basteln-papier.jpg',
        en: 'content/baustelle/bagger-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Bagger basteln aus Papier  ',
        en: 'How to make a paper execavator ',
      },
    },
  },

  meta_title: {
    de: 'Baustellen Geburtstag: DIY Deko mit Bagger und Co.',
    en: '',
  },
  meta_description_google: {
    de: '',
    en: '',
  },
  image_main: {
    filename: {
      de: 'bagger-basteln-papier.jpg',
      en: 'bagger-basteln-papier.jpg',
    },
    alt_text: {
      de: '',
      en: '',
    },
  },
  h1: {
    de: 'Baustellen Geburtstag: DIY Deko mit Bagger & Co.  ',
    en: '',
  },
  description: {
    de: `
      <p>Wie oft haben mich Mütter in den letzen sechs Jahren um Bastelideen für Jungen gebeten.
      Doch ich konnte mich schwer hineinfühlen, in das, was Jungs so toll finden. Als Mädchen brannte ich für Dekoideen rund um das Wohnen. 
      </p> 
      <p>Doch mit der Geburt meines Sohnes vor 18 Monaten habe ich nun das Glück mit Haut und Haar zu erleben, welche Begeisterung
      er bei Baustellen empfindet. </p>
      <p>In diesem Post zeige ich euch DIY Deko-Ideen, die ihr ganz einfach und relativ schnell 
      für euren Baustellen Kindergeburtstag basteln könnt.
      Natürlich dürfen hier Bagger, Hammer, Helme & Co. nicht fehlen.
        </p>
    
     
      <p>Hinweis: Dieser Post ist Teil meiner Papercraft Challenge und befindet sich daher "in der Bauphase".
      Die Papiermodelle kommen nach und nach hinzu und können am Ende der Challenge als Bastelset heruntergeladen werden.</p>
      
      <p>  <a href="/de/blog/3d-papierskulptur-challenge/">Meine Papercraft Challenge</a> aus 2019 findet ihr unter diesem Link. </p>
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: 'Die Baustelle ist eröffnet',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'bagger-basteln-papier.jpg',
          en: 'bagger-basteln-papier.jpg',
        },
        alt_text: {
          de: '3D Bagger basteln mit Tonpapier  ',
          en: '',
        },
      },
    },
    {
      text: {
        de: `Bagger: Du kannst mich entweder bereits in bunt ausdrucken oder in weiß und mich nach deiner Fantasie anmalen. Auf meinem Dach habe ich Platz für den Namen deiner Geburtstagsgäste und kann daher super als Sitzplatzkärtchen verwendet werden. Meine Baggerschaufel kannst du freudig hin- und herbewegen.`,
        en: ``,
      },
    },
  ],

  materials: [
    {
      de: 'DINA4 Papier  (bis zu 130g/m²)',
      en: '1 cardstock paper',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
};
