export default {
  url: 'retro-bus-geldgeschenk',
  countermark: 'vg07.met.vgwort.de/na/c2e938519417470990beb185c5d06eab',
  imageFolder: 'retro-bus-hochzeit',

  name: {
    de: 'Retro-Bus',
    en: 'Papercraft van',
  },
  id: '3-04-blog',
  pdf: {
    price: 9.9,
    priceThirdCountry: 9.9,
    image: {
      filename: {
        de: 'content/retro-bus-hochzeit/diy-retro-bus-geldgeschenk-papier.jpg',
        en: 'content/retro-bus-hochzeit/diy-retro-bus-geldgeschenk-papier.jpg',
      },
      alt_text: {
        de: 'Geldgeschenk basteln zur Hochzeit für reiselustige Brautpaare ',
        en: 'Make this modern paper van as a wedding gift for adventorous couples ',
      },
    },
  },

  meta_title: {
    de: 'DIY old-school Bus als Geldgeschenk basteln zur Hochzeit (für reiselustige Brautpaare)',
    en: 'Money gift: How to make a papercraft van for a wedding',
  },
  meta_description_google: {
    de: 'Es soll ein Geldgeschenk sein, aber Pinterest bietet nicht die richtige Idee? Mit diesem DIY Bus aus Papier landest du einen Volltreffer. Vorlage downloaden und loslegen.',
    en: 'Make this cool papercraft van as your next wedding gift. You can put the money inside the suitcases.  ',
  },
  meta_description_social: {
    de: 'Geldgeschenk zur Hochzeit basteln: Mit diesem Retro-Bus verpackst du genau das nötige Kleingeld für reiselustige Brautpaare',
    en: 'Wedding cash gift: make this papercraft van for adventurous bridal pairs ',
  },
  meta_images_social: [
    {
      de: 'diy-retro-bus-geldgeschenk-papier.jpg',
      en: 'diy-retro-bus-geldgeschenk-papier.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'diy-retro-bus-geldgeschenk-papier.jpg',
      en: 'diy-retro-bus-geldgeschenk-papier.jpg',
    },
    alt_text: {
      de: 'DIY Geldgeschenk zur Hochzeit: schicker retro Bus mit alten Koffern zum Geldverstauen #geldgeschenk #hochzeitsgeschenk #diy# papercraft',
      en: 'How to make a cool wedding gift for bride and groom: DIY papercraft van with old-school suitcases for all the money #weddinggift #diy #papercraft #cashgift ',
    },
  },
  h1: {
    de: 'Old-school Bus als Geldgeschenk basteln zur Hochzeit (für reiselustige Brautpaare)',
    en: 'How to make an old-school papercraft van for the bride and groom as a wedding gift',
  },
  description: {
    de: `
      <p>Geldgeschenke zur Hochzeit sind super beliebt. Meistens werden sie sich vom Brautpaar direkt bei der Einladung gewünscht (für die Flitterwochen oder das Reisebudget generell) und 
      vereinfachen so die Geschenksuche erheblich. </p>
      
      <p>Wäre da nur nicht die immer wiederkehrende Frage: Wie aber verpacke ich das Geldgeschenk schön? </p>
   Auf Pinterest gibt es dazu leider nicht sehr viele Geschenkideen zur Hochzeit, sodass die Geschenktische immer häufiger gleich aussehen - wem will mans verübeln.  </p>
      <p>In diesem DIY-Post zeige ich euch eine kreative und witzige Verpackungsidee für reisefreudige, abenteuerlustige und naturverbundene Brautpaare:
      retro-chicen Van, der natürlich mit zwei old-school Koffern bepackt verreist. In den Koffern befinden sich euer Geldgeschenk und die süße
      Mini-Hochzeitskarte.</p>
      <p>Und eine zusätzliche Idee: Auf diesem old-school Bus können alle Gäste unterschreiben (als kleine Ergänzung zum Hochzeitsbuch quasi) und so festhalten, 
      dass sie Teil der abenteurlichen Reise namens Hochzeit waren. 
            
</p>
      
    
    `,
    en: `
    <p> Money gifts for a wedding are very popular - at least in Germany. In most of the times they are explicitly requested by the bridal couple at the invitation (for the honeymoon or the travel budget in general) </ P>
    
    <p> If only there was not the recurring question: how do I wrap the money gift nicely? </ P>
 On Pinterest, unfortunately, there are not many gift ideas for the wedding, so that the gift tables look more and more the same - who do you want to blame. </ P>
    <p> In this DIY post I show you a creative and funny packaging idea for adventurous and nature-loving bridal couples:
    a retro-chic van which of course travels with two old-school suitcases. You put into the suitcases your money and use them directly as your wedding card.
    </ P>
    <p> Think about this additional idea: Let all guests sign the van and make your wedding gift a unique memento for the couple. 
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },

  stepsTitle: {
    de: 'Geldgeschenk basteln zur Hochzeit: Papier Bus Anleitung',
    en: 'How to wrap a wedding money gift as a paper van: step by step instructions',
  },
  steps: [
    {
      text: {
        de: 'Ihr benötigt für das Hochzeits-Geldgeschenk insgesamt 6 DINA4 Seiten in den Farben eurer Wahl. Ich habe die S.1+2 in weiß, die S. 3+4 in gelb, die Seite 5 in grau und Seite 6 in rosa ausgedruckt. Verwendet am besten Tonpapier mit einer Grammatur von bis zu 230g/m². Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer Schere aus.',
        en: `You need a total of 6 DIN A4 pages in the colors of your choice. I have printed the pages 1 + 2 in white, the pages 3 + 4 in yellow, the page 5 in gray and page 6 in pink. I recommend to use paper with up to 230g/m². Print out the template and cut out all solid lines with scissors.`,
      },
    },

    {
      text: {
        de: `Falzt mit einer Ahle und einem Lineal alle gestrichelten Linien vor. Dazu legt ihr das Lineal an die Linie an und fahrt mit der Ahle
        nach, gerne zweimal je nachdem, welchen Druck ihr aufwendet.`,
        en: `
        Fold all dashed lines with an awl and a ruler. Put the ruler next to the line and ride the awl after.`,
      },
    },

    {
      text: {
        de: ` Faltet die lang gestrichelten Linien von euch weg, d.h. nach unten. Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin. Die lang
        gestrichelten Linien findet ihr lediglich auf den Teilen J-M und bei den Herzen.
        `,
        en: `Fold the long dashed lines away from you, i.e. downward. Fold the short dashed lines up, i.e. up to you. The long
  dashed lines can only be found on the paper parts J-M and the hearts.`,
      },
    },

    {
      text: {
        de: `Wir starten mit dem Bus, der mit dem Teil A beginnt und dem Papierteil R endet. Das Verkleben ist ganz einfach, weil ich für euch 
        schon alles in der richtigen Reihenfolge durchnummeriert habe. Jede Zahl existiert zweimal, sodass jedes Zahlenpaar zusammengehört. 
        Ihr startet mit der Nr. 1 auf dem Papierteil A und verklebt diese mit der Nr. 1 auf dem Teil B (daher 1-B bzw. 1-A). Anschließend verklebt ihr
        die Nr. 2, 3, 4, etc. Diese Reihenfolge ist die einzige, an die ihr euch halten müsst.
  `,
        en: `We start with building the bus: IT starts with the paper part A and ends with the paper part R. 
        The glueing part is pretty simple because I numbered all the paper parts in the right order for you.
        Each number exists twice, so each pair of numbers belongs together.
        You start with the no. 1 on the paper part A which you glue to the no. 1 on the part B (therefore 1-B or 1-A). Then you stick
        no. 2, 3, 4, etc. This order is the only pattern you have to stick to.`,
      },
    },

    {
      text: {
        de: `Für das Verkleben könnt ihr doppelseitige Klebepads verwenden oder normalen (trockenen/ flüssigen) Kleber. 
        Bei 230g/m² ist eigentlich jeder Kleber geeignet. Lediglich die Trockenzeit ist unterschiedlich, d.h. wie lange ihr die Papierteile
        zusammendrücken müsst. Bringt den Kleber auf die Laschenseite auf, auf der nichts bedruckt ist.  `,
        en: `For gluing the parts together you can use double-sided adhesive pads or normal (dry / liquid) glue.
        At 230g/m² every glue is suitable. Only the dry time is different, hence how long you have to press the paper parts
        together. Apply the adhesive to the tab side with nothing printed on it.`,
      },
    },

    {
      text: {
        de: `Die Papierteile N-Q sind die Räder und werden nicht direkt mit dem Bus verklebt, sondern erst einzeln als Räder
        zusammenklebt. Hier kommen noch zwei Dekoelemente von S. 2 und 5 dazu, die ihr jetzt auch aufkleben solltet. Zuerst klebt ihr das Papierteil "Räder A"
        auf die schöne Seite des Autoreifens. Anschließend positioniert ihr in die Mitte von Rad A das Papierteil "Räder B".
        Wie genau ich die Räder von beiden Seiten verklebe und dekoriere seht ihr im Video.  `,
        en: `
        The paper parts N-Q are the wheels and are not glued directly to the bus. First you make them individually as wheels and afterwards you
        stick them to the bus. Here are two more decorative elements from page 2 and 5, which you should stick on now. 
       In the video you'll see how I stick the three wheel modules (wheels, wheels A + B) together.`,
      },
    },

    {
      text: {
        de: `Nun bringen wir das letzte Teil R an den Bus an (es ist das Autodach). Dazu die Schnüre für das Autodach ausschneiden, an der vorgesehenen Stelle knicken und auf die Markierungen am Autodach aufkleben.
        Diesen Schritt zeige ich im Video ebenfalls. 
         `,
        en: `
        Now we attach the last paper part R to the bus (it is the car roof). To do this, cut out the cords for the car roof, 
        bend them at the intended location and stick them on the markings on the car roof.
                I show this step in the video as well.`,
      },
    },

    {
      text: {
        de: `Jetzt nur noch die Koffer falten und zusammenstecken. Ihr benötigt für den Koffer keinerlei Kleber. Der Koffer dient damit als Glückwunschkarte zur Hochzeit (hier könnt ihr euren individuellen Text reinschreiben) und bietet Platz für euer Geldgeschenk.  `,
        en: `
        Now fold the suitcases and put them together. You do not need any glue for the suitcases. They serve as a greeting card (here you can write your individual text) and offers space for your money gift.`,
      },
    },

    {
      text: {
        de: `Nun kommt ein weitere spaßiger Teil: die Deko. Es gibt Lichter, Herzen, Just-Married-Schilder, Autonummernschilder (mit der Möglichkeit
        euren Text handschriftlich hineinzschreiben), Fenster und natürlich Trinkdosen, die hinter dem Retro-Bus hergezogen werden wollen. Es ist eurer
        kreativen Laune und Zeit überlassen, was ihr davon anbringen möchtet.   `,
        en: `
        Now let's come to the final fun part: the decoration. There are lights, hearts, just-married signs, car number plates, windows and, of course, drinking cans to be pulled up behind the old-school bus.
        It is up to your creative whims how much time you want to spend with it.
               `,
      },
    },

    {
      text: {
        de: `Für die Trinkdosen schneidet die 3 Rechtecke auf der S. 5 aus und rollt sie von der kurzen Seite her auf, sodass eine enge Spirale entsteht.
        Das ist eure erst Trinkdose. Diesen Schritt wiederholt ihr mit den anderen zwei Rechtecken ebenfalls. Die dazugehörigen Bänder schneidet ihr ebenfalls von S. 5 aus
        und zwirbelt sie leicht auf. Sie haben unterschiedliche Längen, nicht wundern. Jede dieser Bänder verklebt ihr nun mit je einer Trinkdose. Ich habe
        dazu die Trinkdose einfach wieder entrollt, das Band aufgeklebt und wieder eingerollt. Zum Schluss nehmt ihr alle drei Schnüre zusammen und 
        klebt sie von unten an den Retrobus fest.`,
        en: `
        For the drinking cans, cut the 3 rectangles on page 5 and roll them up from the short side, creating a tight spiral.
                This is your first drinking can. Repeat this step with the other two rectangles as well. 
                Cut also the corresponding bands from page 5 and twirl them up slightly. 
                They have different lengths, do not be surprised. Glue each of these tapes to a drinking box. I have
                simply unrolled the drinking can, taped the string on it and rolled it up again.
                Finally, you take all three strings together and stick them to the back of the bus.`,
      },
    },

    {
      text: {
        de: `Die Herzen findet ihr in zwei unterschiedlichen Größen vor. Faltet die beiden ausgeschnittenen Rechtecke von S. 6 jeweils in der Mitte, sodass ihr
        lediglich die Hälfte des Herzens seht. Schneidet nun die Herzen aus. Es ist euch überlassen, wo ihr diese aufklebt. 
        Ich habe sie auf das Mittelteil des Busses angeklebt und auf das Autodach in jede Ecke jeweils eines der größeren Herzen .
          `,
        en: `The hearts are available in two different sizes. Cut and fold the two rectangles from p. 6 in the middle so that you have to cut only
       half of the hearts. It's up to you where you put these on.
    `,
      },
    },

    {
      text: {
        de: `Wie die weitere Deko am Hochzeitsbus angebracht wird, zeige ich euch im Video. Es ist relativ simple und würde hier einfach zu viel Text  verschlingen, den keiner lesen mag.
            `,
        en: `In the video I'll show you how the further decoration is attached to the bus (like the lights etc.).`,
      },
    },
  ],

  video: {
    title: {
      de: 'Geldgeschenk als old-school Bus für reiselustige Brautpaare verpacken ',
      en: 'Learn how to make an old-school van for adventurous bride couples ',
    },
    description: {
      de: ``,
      en: ``,
    },
    caption: {
      de: `Geldgeschenk zur Hochzeit: Retro-Bus für abenteuerlustige Brautleute`,
      en: `Wedding gift: Retro van for adventorous couples `,
    },
    videoid: 'tfla1k2gHC4',
  },
  downloadName: 'van-template_papershape.pdf',
  materials: [
    {
      de: 'Vorlage herunterladen',
      en: 'Download the template',
    },
    {
      de: '6 DINA4 Papier in vier unterschiedlichen Farben (bis zu 230g/m²)',
      en: '6 colored papers in four different colors (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
    {
      de: 'Stift zum Schreiben',
      en: 'Pencil',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'diy-hochzeit-geldgeschenk-retro-van-abenteuer.jpg',
          en: 'diy-wedding-money-gift-van-bus-adventure.jpg',
        },
        alt_text: {
          de: 'Geldgeschenk zur Hochzeit verpacken: Old school Bus aus Papier für Brautpaare, die gerne reisen #geldgeschenk #hochzeitsgeschenk #papercraft #bastelnmitpapier #diy',
          en: 'Wedding gift for travel-loving couples: an old-school papercraft van with suitcases to put money inside #diygift #weddinggift #papercraft #moneygift',
        },
      },
    ],
  },
};
