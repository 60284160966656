export default {
  id: '1-07-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Löwe',
    en: 'Lion',
  },
  subtitle: {
    de: 'B26 x H33 x T15 cm',
    en: 'W26 x H33 x D15 cm',
  },
  meta_title: {
    de: 'Löwe Papiertier',
    en: 'Lion Paper Animal',
  },
  meta_description_google: {
    de: 'Der Löwen Kopf zum Aufhängen verzaubert als Wanddeko jeden Raum. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The lion head enchants each room with lots of space for creativity. 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: '3d Löwe aus Papier als Wanddeko - DIY Papierskulptur #papershape',
    en: '3d lion from paper as wall decor - cool lion papertrophy #papershape',
  },
  description: {
    de: `   Prachtvolle Mähne, majestätisches Erscheinungsbild, puristisches Design - so präsentiert sich der neue 3D Origami Löwe von PaperShape. Durch individuelle Farbkombinationen können nun auch Jungen ihr Zimmer zur Safari gestalten. An die Schere, fertig los!
    
    ANLEITUNG 
    
    Zuerst werden alle Papierteile entlang der durchgezogenen Linien ausgeschnitten.
    Das Falten der Papierteile gelingt euch ganz einfach, wenn ihr die gestrichelten Linien vorher vorfalzt. Das könnt ihr ganz einfach mit einem Lineal und einem Buttermesser, einer Ahle oder einer Stricknadel tun. Dazu das Lineal an die gestrichelte Linie anlegen und mit dem Buttermesser die Linie mit viel Druck nachfahren. Anschließend gut knicken.
    
    Im letzten Schritt werden die geknickten Papierteile verklebt. Dazu klebt ihr auf jede Lasche von außen so viele Klebepads auf, wie angegeben. Das Verkleben erfolgt in nummerischer Reihenfolge 1,2,3 etc.. Achtet beim Verkleben, dass ihr die Klebepads gut andrückt.
    
    DETAILS: Altersempfehlung ab 14 Jahren
    
    KOMPLETTES SET BEINHALTET
    
    8 DIN A4 Fotokarton Seiten
    Doppelseitige Klebepads
    Detaillierte schrittweise Anleitung
    
   BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser

    `,
    en: `   STEP BY STEP
    
    Cut the paper parts.
    To fold the paper parts, you need to pre-fold them first with a ruler and a bread knife or an awl. Set the ruler to the dashed line and press with the butter knife along. Then fold the parts as specified in the instructions: either outwardly or inwardly.
    Now glue all the paper parts of the 3D origami together. Use the pads, which are included in the set. The folding takes place in numerical order in 1, 2, 3, etc. Set the adhesive pads on the outer sides of the tabs and keep a safe distance of about 2mm. When gluing necessarily watch the model from the outside.
    
    DETAILS: Age recommendation 14+
    
    COMPLETE KIT INCLUDES
    
    8 DIN A4 cardboard sheets Origami Cow
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
  REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife`,
  },
  colorinspirations: [
    {
      caption: {
        de: 'schokobraun-gold',
        en: 'koenigsblau-perlweiß',
      },
      image: {
        filename: {
          de: 'products/sfkloewe/loewe-papier-tierkopf-3d.jpg',
          en: 'products/sfkloewe/loewe-koenigsblau-perlweiss.jpg',
        },
        alt_text: {
          de: '3d Löwe aus Papier als Wanddeko - DIY Papierskulptur #papershape',
          en: '3d lion from paper as wall decor - cool lion papertrophy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'schokobraun-terracotta',
        en: 'schokobraun-terracotta',
      },
      image: {
        filename: {
          de: 'products/sfkloewe/loewe-schokobraun-terracotta.jpg',
          en: 'products/sfkloewe/loewe-schokobraun-terracotta.jpg',
        },
        alt_text: {
          de: '3d Löwe aus Papier als Wanddeko - DIY Papierskulptur #papershape',
          en: '3d lion from paper as wall decor - cool lion papertrophy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'türkis-perlweiß',
        en: 'türkis-perlweiß',
      },
      image: {
        filename: {
          de: 'products/sfkloewe/loewe-tuerkis-perlweiss.jpg',
          en: 'products/sfkloewe/loewe-tuerkis-perlweiss.jpg',
        },
        alt_text: {
          de: '3d Löwe aus Papier als Wanddeko - DIY Papierskulptur #papershape',
          en: '3d lion from paper as wall decor - cool lion papertrophy #papershape',
        },
      },
    },
    {
      caption: {
        de: 'terracotta-perlweiß',
        en: 'terracotta-perlweiß',
      },
      image: {
        filename: {
          de: 'products/sfkloewe/loewe-terracotta-perlweiss.jpg',
          en: 'products/sfkloewe/loewe-terracotta-perlweiss.jpg',
        },
        alt_text: {
          de: '3d Löwe aus Papier als Wanddeko - DIY Papierskulptur #papershape',
          en: '3d lion from paper as wall decor - cool lion papertrophy #papershape',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkloewe/loewe-weiss.jpg',
        en: 'products/sfkloewe/loewe-weiss.jpg',
      },
      alt_text: {
        de: '3d Löwe aus Papier als Wanddeko - DIY Papierskulptur #papershape',
        en: '3d lion from paper as wall decor - cool lion papertrophy #papershape',
      },
    },
    colors: {
      color1: {
        de: 'Gesicht',
        en: 'Face',
      },
      color2: {
        de: 'Mähne',
        en: 'Mane',
      },
    },
    disableColors: {
      color2: ['bunt', 'schwarz'],
      color1: ['schwarz'],
    },

    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
