export default {
  url: 'libelle-basteln-papier',
  countermark: 'vg07.met.vgwort.de/na/14aeb412687041669444a1d5494cf077',
  imageFolder: 'libelle',

  name: {
    de: 'Libellen',
    en: 'Libelle',
  },
  id: '3-11-blog',
  pdf: {
    price: 3.9,
    priceThirdCountry: 3.9,
    image: {
      filename: {
        de: 'content/libelle/libelle-basteln-papier.jpg',
        en: 'content/libelle/libelle-basteln-papier.jpg',
      },
      alt_text: {
        de: '3D Libellen basteln aus Papier und mit Kindern gestalten. Ganz einfach selber machen, falten und aufhängen. #papershape #wanddeko #diy',
        en: '3D Dragonfly diy - cool paper craft even with kids #papershape #papercraft #diy',
      },
    },
  },

  meta_title: {
    de: '3D Libellen basteln aus Papier (mit Bastelvorlage)',
    en: 'How to make a 3d paper dragonfly (with template)',
  },
  meta_description_google: {
    de: 'Libellen basteln mit Papier in 3d mit dieser Bastelvorlage: ausschneiden, falten und kleben.',
    en: 'Download the template for these cute 3d dragonflies from paper now. Super easy to make and fun.',
  },
  meta_description_social: {
    de: '3D Libellen basteln aus Papier und mit Kindern gestalten. Ganz einfach selber machen, falten und aufhängen. #papershape #wanddeko #diy',
    en: '3D Dragonfly diy - cool paper craft even with kids #papershape #papercraft #diy',
  },
  meta_images_social: [
    {
      de: 'libelle-basteln-papier.jpg',
      en: 'dragonfly-paper-craft.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'libelle-basteln-papier.jpg',
      en: 'dragonfly-paper-craft.jpg',
    },
    alt_text: {
      de: '3D Libellen basteln mit Papier und mit Kindern gestalten. Ganz einfach selber machen, falten und aufhängen. #papershape #wanddeko #diy',
      en: '3D Dragonfly diy - cool paper craft even with kids #papershape #papercraft #diy',
    },
  },
  h1: {
    de: 'Frühlingshafte Libellen basteln mit Papier (mit Bastelvorlage)',
    en: 'How to make a 3d paper dragonfly (with template)',
  },
  description: {
    de: `
   <p> Was haltet ihr von einem schnellen Sommer-DIY:</p>
     <p>
     Wenn euch der <a href="/de/blog/schmetterling-basteln/">3D Schmetterling aus Papier</a> bislang zu aufwendig war, dann solltet ihr unbedingt diese coolen 3d Libellen basteln. Ihr könnt eigentlich fast jedes Papier verwenden, weil sie keine besondere
     Stabilität erfordern. </p>
     
     <p> Mit diesen Libellen könnt ihr Geschenke für die beste Freundin oder Mama verzieren, ihr könnt sie als Lesezeichen verwenden, an die Wand als Wanddeko ankleben und sogar ein Baby-Mobile 
     daraus machen. </p>
     <p>Erwähnte ich bereits die Möglichkeit eine Girlande aus diesen Libellen zu kreiren. Oder ihr erschafft euch ein ganzes Reich von Schmetterlingen und Insekten, die von der Decke schweben 
     - stelle ich mir auch cool vor.</p>
<p>Diese 3D Libellen zum Basteln habe ich im Juli beim ARD-Buffet vorgestellt und den Live-Ausschnitt könnt ihr euch <a href="https://www.youtube.com/watch?v=JVCdPlx73bQ">hier</a> ansehen.</p>
    `,
    en: `
      <p>
      What do you think about a quick summer diy tutorial?
      </p>
     
      <p>  In case you haven't started to paper craft these <a href="/en/blog/schmetterling-basteln/">beautiful 3d butterflies</a> because you didn't want to spend too much time, you should definitely try these cute and super fast 
      paper dragonflies.     
      </p>
          <p>
     You can use them as wonderful gift tags, as a beautiful wall decor or for a baby mobile.  
      </p>
    
    `,
  },
  inspirationTitle: {
    de: 'Ideen für Libellen - DIYs',
    en: 'Get inspirations how to use 3d dragonflies',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'libelle-papier-basteln.jpg',
          en: 'dragonflies-paper-craft.jpg',
        },
        alt_text: {
          de: '3D Libellen basteln aus Papier und mit Kindern gestalten. Ganz einfach selber machen, falten und aufhängen. #papershape #wanddeko #diy',
          en: '3D Dragonfly diy - cool paper craft even with kids #papershape #papercraft #diy',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'Libelle-basteln-kinder-papier.jpg',
          en: '3d-dragonfly-paper.jpg',
        },
        alt_text: {
          de: '3D Libellen basteln aus Papier und mit Kindern gestalten. Ganz einfach selber machen, falten und aufhängen. #papershape #wanddeko #diy',
          en: '3D Dragonfly diy - cool paper craft even with kids #papershape #papercraft #diy',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'libelle-papier-lesezeichen.jpg',
          en: 'libelle-papier-lesezeichen.jpg',
        },
        alt_text: {
          de: '3D Libellen basteln aus Papier und mit Kindern gestalten. Ganz einfach selber machen, falten und aufhängen. #papershape #wanddeko #diy',
          en: '3D Dragonfly diy - cool paper craft even with kids #papershape #papercraft #diy',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'girlande-diy-libellen-basteln.jpg',
          en: 'girlande-basteln-libelle.jpg',
        },
        alt_text: {
          de: '3D Libellen basteln aus Papier und mit Kindern gestalten. Ganz einfach selber machen, falten und aufhängen. #papershape #wanddeko #diy',
          en: '3D Dragonfly diy - cool paper craft even with kids #papershape #papercraft #diy',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
  ],
  stepsBackgroundColor: 'rgb(255, 255, 255)',
  stepsTitle: {
    de: 'Anleitung zur 3D Libelle',
    en: 'Instructions: how to paper craft a 3d dragonfly',
  },
  steps: [
    {
      image: {
        filename: {
          de: '02_schneiden.jpg',
          en: '02_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_schneiden.mp4',
      text: {
        de: `Druckt die Libellen Bastelvorlage auf DIN A4 Foto- oder Tonkarton in eurer Wunschfarbe / Muster aus. Ihr erhaltet 2 Libellen aus einem DINA4. Schneide mit einer Schere alle Teile entlang der durchgezogenen Linie aus. `,
        en: `Print the dragonfly template (US letter) on paper of your choice. On one sheet of paper are two dragonflies. Cut out the paper parts by cutting along the solid lines.`,
      },
    },
    {
      image: {
        filename: {
          de: '06_zweitenflugelknicken.jpg',
          en: '06_zweitenflugelknicken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '06_zweitenflugelknicken.mp4',
      text: {
        de: `Nehmt euch die Libelle mit Körper zur Hand und legt das Lineal auf den Flügel, sodass der Flügel ungefähr halbiert wird. Jetzt fahrt ihr mir einer Ahle an dem Lineal entlang, sodass eine Falzlinie entsteht. 
        Dies macht ihr bei beiden Flügeln. Die nun entstandene Falzlinie auf den Libellen-Flügel knickt ihr entweder beide nach oben oder unten. Diesen Schritt wiederholt 
        ihr für das zweite Papierteil der Libelle.
       `,
        en: `Take the first part of the dragonfly (the one with the body) and place a ruler on the wing in a way that it splits the wing. 
        Press down hard the bonefolder along the ruler. Fold the marked lines now in a direction of your choice. Repeat these two steps with the other paper part of the
        dragonfly. `,
      },
    },

    {
      image: {
        filename: {
          de: '07_kleben.jpg',
          en: '07_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '07_kleben.mp4',
      text: {
        de: `Klebt ein kleines Stück Klebepad auf den Rumpf bzw. zwischen die Flügel auf dem zweiten Teil der Libelle. Das Klebepad sollte von der anderen Seite nicht sichtbar sein. Ihr könnt
        auch Kleber verwenden. Klebt nun die zweiten Flügel auf den Rumpf der Libelle und drückt sie fest zusammen       `,
        en: `Place some glue between the wings of the second paper part of the dragonfly. Stick the other wings on the hull of the dragonfly.
       `,
      },
    },

    {
      image: {
        filename: {
          de: '09_informbiegen.jpg',
          en: '09_informbiegen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '09_informbiegen.mp4',
      text: {
        de: `Biegt die Flügel nun nach oben, sodass ein schöner 3D Effekt entsteht. Auch den Schwanz könnt ihr in Form biegen et voilà! 
         `,
        en: `Bend the wings up now in order to get the 3d effect. You can also bend the tail using a scissor. `,
      },
    },
  ],

  video: {
    title: {
      de: 'So entsteht die 3D Libelle - Video am Stück',
      en: 'Learn how to make paper dragonflies',
    },
    description: {
      de: ``,
      en: ``,
    },
    caption: {
      de: `3D Libellen basteln aus Papier (mit Vorlage)`,
      en: `3D dragon fly from paper (with template)`,
    },
    videoid: 'Zj8rnp3UYi4',
  },

  downloadName: 'libelle-dragonfly.pdf',
  materials: [
    {
      de: '1 DINA4 Papier (für 2 Libelle) uni oder gemustert (ab 120g/m²)',
      en: '1 cardstock paper (results in 2 dragonflies) uni or with pattern (weight: 65lb-110lb / 176gsm-300gsm)',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'libelle-basteln-papier-kinder.jpg',
          en: 'dragonfly-paper-craft-kids.jpg',
        },
        alt_text: {
          de: '3D Libellen basteln aus Papier und mit Kindern gestalten. Ganz einfach selber machen, falten und aufhängen. #papershape #wanddeko #diy',
          en: '3D Dragonfly diy - cool paper craft even with kids #papershape #papercraft #diy',
        },
      },
    ],
  },
};
