import React from 'react';
import Email from '../../common/Email';
import PsDisableGa from '../../common/PsDisableGa';

export default () => (
  <div>
    <b>
      1. Was sind personenbezogene Daten?
      <br />
    </b>
    <br />
    Personenbezogene Daten sind nach dem Gesetz Einzelangaben über persönliche
    oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen
    Person. Darunter fällt z.B. die E-Mail-Adresse oder auch die IP-Adresse.
    <br />
    <br />
    Verantwortliche für die Datenverarbeitung auf dieser Website im Sinne der
    Datenschutz-Grundverordnung (DSGVO) ist
    <br />
    <br />
    PaperShape UG (haftungsbeschränkt) & Co. KG
    <br />
    Anstasia Baron
    <br /> Guttknechtstr. 23, 90547 Stein, Deutschland
    <br />
    Tel.: 0911 36069244
    <br />
    Email: <Email />
    <br />
    <br />
    Die Website paper-shape.com nutzt aus Sicherheitsgründen und zum Schutz der
    Übertragung personenbezogene Daten und anderer vertraulicher Inhalte (z.B.
    Bestellungen oder Anfragen an den Verantwortlichen) eine
    SSL-Verschlüsselung. Sie erkennen eine verschlüsselte Verbindung daran, dass
    die Zeichenfolge „https://“ in der URL steht und dem Schloss-Symbol in Ihrer
    Browserzeile.
    <br />
    <br />
    <b>
      2. Verwendung der Daten
      <br />
    </b>
    <br />
    <b>2.1. Datenerhebung beim Zugriff auf unsere Website</b>
    <br />
    <br />
    Bei jedem Zugriff auf unsere Seite werden folgende Daten gespeichert:
    <br />
    <br />
    - Dateiname der angeforderten Datei
    <br />
    - verwendetes Endgerät (Mobilgerät oder PC/Laptop)
    <br />
    - Betriebssystem
    <br />
    - Browsertyp/-version
    <br />
    - Javascriptaktivierung
    <br />
    - Cookieaktivierung
    <br />
    - verweisende URL
    <br />
    - IP-Adresse
    <br />
    - Dauer des Zugriffs
    <br />
    - Anzahl der aufgerufenen Seiten
    <br />
    - Klickpfad <br />
    <br />
    Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres
    berechtigten Interesses zur Sicherstellung der Systemsicherheit und der
    Verbesserung unseres Angebots. Diese Daten werden in anonymisierter bzw.
    pseudonymer Form gespeichert. <br />
    <br />
    Der Nutzer kann dieser Verwendung widersprechen. Der Widerspruch kann
    schriftlich oder per Email an unsere Kontaktadresse erfolgen. Wir weisen
    darauf hin, dass das Angebot auf unserer Seite in dem Fall unter Umständen
    nicht uneingeschränkt nutzbar ist.
    <br />
    <br />
    <b>2.2. Datenverarbeitung zur Vertragsabwicklung</b>
    <br />
    <br />
    Gemäß Art. 6 Abs. 1 lit. b DSGVO werden personenbezogene Daten erhoben und
    verarbeitet, wenn Sie uns diese zur Durchführung eines Vertrages mitteilen.
    Bei der Absendung einer Bestellung werden u.a. Name, Email-Adresse, Straße
    sowie weitere Daten wie aus dem Bestellformular ersichtlich, gespeichert.
    Diese Daten werden zur Ausführung des Vertrages gespeichert und verwendet.
    <br />
    <br />
    Die Email-Adresse wird insbesondere nicht an Dritte weitergegeben oder für
    unverlangt zugesandte Nachrichten oder Werbung durch uns verwendet. Eine
    Weitergabe der Daten erfolgt nicht. Hiervon ausgenommen sind unsere
    Dienstleistungspartner, die wir zur Abwicklung des Vertragsverhältnisses
    benötigen bzw. zur Auftragsverarbeitung nutzen.
    <br />
    <br />
    <b>2.3. Datenverarbeitung zur Bestellabwicklung</b>
    <br />
    <br />
    Zur Abwicklung Ihrer Bestellung arbeiten wir mit den nachstehenden
    Dienstleistungspartnern zusammen, die uns bei der Durchführung geschlossener
    Verträge unterstützen. <br /> <br />
    2.3.1. Wir versenden unsere Ware mit externen Versandpartnern (DHL, Deutsche
    Post) zur Erfüllung unserer vertraglichen Pflichten. Wir geben Ihren Namen
    sowie Ihre Lieferadresse ausschließlich zu Zwecken der Warenlieferung an die
    von uns ausgewählten Versandpartner weiter.
    <br /> <br />
    2.3.2. Bei Zahlung via PayPal, Kreditkarte via PayPal oder Lastschrift via
    PayPal geben wir Ihre Zahlungsdaten im Rahmen der Zahlungsabwicklung an die
    PayPal (Europe) S.a.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449
    Luxembourg (nachfolgend "PayPal"), weiter. Die Weitergabe erfolgt gemäß Art.
    6 Abs. 1 lit. b DSGVO und nur insoweit, als dies für die Zahlungsabwicklung
    erforderlich ist. Alle PayPal-Transaktionen unterliegen der
    PayPal-Datenschutzerklärung. Diese finden Sie{' '}
    <a
      href="
    https://www.paypal.com/de/webapps/mpp/ua/privacy-prev?locale.x=de_DE"
    >
      hier.
    </a>{' '}
    <br />
    <br />
    2.3.3. Wir verwenden zur Vertragsabwicklung ein Warenwirtschaftssystem. Dazu
    übermitteln wir Ihre erhobenen personenbezogenen Daten an Billbee GmbH,
    Paulinenstrasse 54, 32756 Detmold, Deutschland.
    <br />
    <br />
    <b id="kontaktaufnahme">3. Kontaktaufnahme </b>
    <br />
    <br />
    Für die Kontaktaufnahme mit uns per Kontaktformular oder E-Mail werden
    personenbezogene Daten erhoben (Nachrichtentext, E-Mail). Diese Daten werden
    ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die
    Kontaktaufnahme und die damit verbundene technische Administration
    gespeichert und verwendet. Die Verarbeitung erfolgt auf Grundlage des Art. 6
    (1) lit. a DSGVO mit Ihrer Einwilligung.
    <br />
    <br />
    <b>4. Informationen zu Cookies</b>
    <br />
    <br />
    Unsere Seite verwendet Cookies. Bei Cookies handelt es sich um kleine
    Textdateien, die von einem Webserver generiert werden und mittels des
    genutzten Webbrowsers während des Online-Besuchs auf Ihrem Computer
    gespeichert werden. Bei jeder erneuten Anfrage des Browsers an den Server
    wird die Information des Cookies wieder an den Server gesandt.
    <br />
    <br />
    Es handelt sich dabei entweder um Session-Cookies, die beim Verlassen
    unserer Website automatisch gelöscht werden oder um dauerhafte Cookies, die
    erst bei Ablauf des festgelegten Zeitraums erlöschen.
    <br />
    <br />
    Cookies enthalten anonymisierte Daten über den verwendeten Computer. Es
    werden jedoch keine Daten über die Besucher gespeichert. Sie können Ihren
    Browser so einstellen, dass Sie vor der Aktivierung eines Cookies gefragt
    werden oder die Aktivierung von Cookies generell unterbinden. Ebenso können
    mithilfe des Browsers bereits gespeicherte Cookies jederzeit gelöscht
    werden. Mehr zur Vorgehensweise entnehmen Sie bitte der Benutzeranleitung zu
    Ihrem verwendeten Browser.
    <br />
    <br />
    <b>4.1. Erklärung zu Google Analytics</b>
    <br />
    <br />
    Wir nutzen Google Analytics, einen Dienst der Google Incorporated, 1600
    Amphitheatre Parkway, Mountain View, CA 94043, USA.
    <br />
    <br />
    Die dabei in Cookies gespeicherten Nutzungsdaten werden an einen Server von
    Google in den USA übertragen und dort anonymisiert gespeichert. Google ist
    unter dem Privacy-Shield-Abkommen zertifiziert und bietet eine Garantie, das
    europäische Datenschutzrecht einzuhalten (
    <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">
      https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
    </a>
    ).
    <br />
    <br />
    Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. D.h.,
    dass die IP-Adresse der Nutzer von Google innerhalb von Mitgliedstaaten der
    EU oder in anderen Vertragsstaaten des Abkommens über den Europäischen
    Wirtschaftsraum gekürzt wird.
    <br />
    <br />
    Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse
    wird nicht mit anderen Daten von Google zusammengeführt. Sie können die
    Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
    Browser-Software verhindern. Sie können darüber hinaus die Erfassung der
    durch das Cookie erzeugten und auf ihre Nutzung der Website bezogenen Daten
    (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
    Google verhindern, indem Sie das unter dem folgenden Link verfügbare
    Browser-Plugin herunterladen und installieren:
    http://tools.google.com/dlpage/gaoptout?hl=de oder <PsDisableGa />.
    <br />
    <br />
    <b>4.2. Erklärung zu Google Adwords Remarketing</b>
    <br />
    <br />
    Wir nutzen Google Remarketing. Durch Google Remarketing wird dem Nutzer, der
    bereits Interesse für unsere Angebot gezeigt hat, innerhalb des Google
    Suchnetzwerkes gezielt weitere Werbung von uns angezeigt. Dies geschieht
    mittels eines Cookies auf dem Gerät des Nutzers. Die Verarbeitung erfolgt
    auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse,
    die Besucher der Website zielgerichtet mit Werbung anzusprechen, indem für
    Besucher der Website des Anbieters personalisierte, interessenbezogene
    Werbung-Anzeigen geschaltet werden, wenn sie andere Websiten im Google
    Display-Netzwerk besuchen.{' '}
    <b>
      Sie haben das Recht jederzeit gegen diese auf Art. 6 (1) f DSGVO beruhende
      Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.
    </b>
    <br />
    Sie können die Einstellungen für Google Remarkting
    <a href="https://www.google.com/settings/u/0/ads/authenticated"> hier </a>
    ändern. Informationen zu Google Remarketing und die dazugehörige
    Datenschutzerklärung finden Sie unter: https://www.google.com/privacy/ads/
    <br />
    <br />
    <b> 5. Informationen zu Social Plugins</b>
    <br />
    <br />
    5.1.1. Auf unserer Website werden Social Plugins der sozialen Netzwerke
    Facebook (Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA)
    und Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA)
    verwendet.
    <br />
    <br />
    Wenn Sie bei einem der sozialen Netzwerke eingeloggt sind, wird eine
    Verbindung zwischen Ihrem Account und dem Netzwerk hergestellt, durch die
    die Information des Besuches unserer Seite an das jeweilige Netzwerk, in das
    Sie eingeloggt sind, übertragen werden können. <br />
    <br />
    Betätigen Sie den Empfehlen- oder Teilen-Button des jeweiligen Netzwerkes
    können andere Nutzer dieser Netzwerke sehen, welche Seiten Sie empfehlen.
    <br />
    <br />
    Um zu verhindern, dass die sozialen Netzwerke Ihre Daten erhalten und
    sammeln, sollten Sie sich aus den Netzwerken ausloggen.
    <br />
    Facebook Inc. mit Sitz in den USA ist für das us-europäische
    Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die
    Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet.
    <br />
    Weitere Informationen zu der Erhebung und Verwendung von Daten finden Sie
    auf
    <br />
    https://www.facebook.com/policy.php/
    <br />
    https://policy.pinterest.com/de/privacy-policy
    <br />
    <br />
    5.1.2. Diese Website nutzt die Youtube-Einbettungsfunktion zur Anzeige und
    Wiedergabe von Videos des Anbieters „Youtube“, der zu der Google LLC., 1600
    Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“) gehört.
    Hierbei ist der erweiterte Datenschutzmodus aktiviert, der nach
    Anbieterangaben eine Speicherung von Nutzerinformationen erst bei Wiedergabe
    des/der Videos in Gang setzt.
    <br />
    <br />
    Google LLC mit Sitz in den USA ist für das us-europäische
    Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die
    Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet. Weitere
    Informationen zum Datenschutz bei „YouTube“ finden Sie in der
    Datenschutzerklärung des Anbieters unter:
    https://www.google.de/intl/de/policies/privacy
    <br />
    <br />
    <b> 6. Facebook Custom Audience</b>
    <br />
    <br />
    Auf unserer Website nutzen wir Facebook Custom Audience (Facebook Inc., 1601
    S. California Ave, Palo Alto, CA 94304, USA). Durch einen Tracking-Pixel auf
    unserer Website wird dem Benutzer bei einem späteren Besuch auf Facebook
    Werbung für unsere Produkte angezeigt. Nutzer können die Einstellung für
    Facebook Custom Audience hier ändern:
    https://www.facebook.com/ads/website_custom_audiences/
    <br />
    <br />
    <b id="newsletter"> 7. Newsletteranmeldung</b>
    <br />
    <br />
    Melden Sie sich zu unserem E-Mail Newsletter an, senden wir Ihnen regelmäßig
    Informationen zu unseren kostenlosen DIY-Ideen rund um Papier und zu unseren
    werblichen Produkten und Angeboten. Um sich für den Newsletter anzumelden,
    müssen Sie lediglich verpflichtend Ihre E-Mail Adresse hinterlassen. Weitere
    Daten wie der Vorname sind optional und dienen lediglich dazu Sie persönlich
    anzusprechen zu können.
    <br />
    <br />
    Für den Versand des Newsletters verwenden wir das Double Opt-in Verfahren.
    Das heißt, dass wir Ihnen vor dem regelmäßigen Versand unseres Newsletters
    eine Bestätigungsemail zusenden, die Sie per Linkklick bestätigen müssen und
    somit dem Erhalt weiterer Emails zustimmen.
    <br />
    <br />
    Mit der Aktivierung des Bestätigungslinks erteilen Sie uns Ihre Einwilligung
    für die Nutzung Ihrer personenbezogenen Daten gemäß Art. 6 Abs. 1 lit. a
    DSGVO.
    <br />
    <br />
    Bei der Anmeldung zum Newsletter wird Ihre vom Internet Service-Provider
    (ISP) eingetragene IP-Adresse sowie der Anmelde- und Bestätigungszeitpukt
    gespeichert, um einen möglichen Missbrauch Ihrer E-Mail- Adresse zu einem
    späteren Zeitpunkt nachvollziehen zu können. Sie können den Newsletter
    jederzeit abbestellen, in dem Sie auf den dafür vorgesehenen Link im
    Newsletter klicken.
    <br />
    <br />
    7.1.1. Newsletterversand mit Brevo
    <br />
    <br />
    Wir nutzen Brevo für den Versand von Newslettern. Anbieter ist die Brevo
    GmbH, Köpenicker Straße 126, 10179 Berlin, Deutschland. Brevo ist ein
    Dienst, mit dem unter anderem der Versand von Newslettern analysiert und
    organisiert werden kann.
    <br />
    <br />
    Die E-Mail-Adressen unserer Newsletterempfänger werden auf den Servern von
    Brevo GmbH in Deutschland gespeichert. Die Weitergabe Ihrer Daten an Brevo
    GmbH erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO und dient unserem berechtigten
    Interesse an der Verwendung eines werbewirksamen, sicheren und
    nutzerfreundlichen Newslettersystems.
    <br />
    <br />
    Die Newsletter enthalten eine pixelgroße Datei, die beim Öffnen des
    Newsletters von dem Server von Brevo GmbH abgerufen wird. Dadurch kann
    festgestellt werden, ob der Newsletter geöffnet und welche Links angeklickt
    wurden. Es werden darüber hinaus technische Informationen erfasst (z.B.
    OP-Adresse, Zeitpunkt des Abrufs, Informationen zum Browser und
    Betriebssystem). Diese Daten dienen ausschließlich der statistischen Analyse
    von Newsletterkampagnen. Die Ergebnisse dieser Analysen werden genutzt, um
    künftige Newsletter besser an die Interessen der Empfänger auszurichten.
    Weitere Informationen entnehmen Sie den Datenschutzbestimmungen von Brevo
    unter: https://de.Brevo .com/legal/privacypolicy/.
    <br />
    <br />
    Wir haben mit Brevo einen entsprechenden Vertrag abgeschlossen, in dem wir
    Brevo verpflichten, die Daten unserer Kunden zu schützen und sie nicht an
    Dritte weiterzugeben.
    <br />
    <br />
    {/*<b>7. Livechat</b>
    <br />
    <br />
    Zopim ist ein Live-Chat-Programm, das Besuchern ermöglicht, mit den
    Betreibern der Website unmittelbar zu interagieren. Die Eingaben des Users
    werden an Zopim übertragen und dort gespeichert. Der Service erfasst auch
    die Besucher IP Adresse , Betriebssystem und Browser . Die Daten werden nur
    für die Bearbeitung der Anfragen des Users genutzt. Eine individualisierende
    Analyse des Nutzungsverhaltens des Seitenbesuchers findet nicht statt.<br />*/}
    <b>8. Links zu anderen Webseiten</b>
    <br />
    <br />
    Unsere Webseite enthält Links zu Webseiten Dritter. Für das Angebot Dritter,
    insbesondere für den dortigen Umgang mit Daten, tragen wir keine
    Verantwortung.
    <br />
    <br />
    <b>9. Datenaufbewahrung</b>
    <br />
    <br />
    Die nach Maßgabe der vorstehenden Abschnitte gesammelten Daten werden
    solange aufbewahrt, wie dies für die Erbringung unseres Services bzw. zur
    Erfüllung gesetzlicher Vorschriften (z.B. handels- und steuerrechtliche
    Aufbewahrungsfristen) notwendig ist.
    <br />
    <br />
    {/*<b>10. Keine Weitergabe an Dritte</b> <br />
    <br />
    Eine Übermittlung Ihrer Daten an Dritte erfolgt nicht, es sei denn, dies
    dient der Erfüllung des Vertrages oder es besteht hierzu im Einzelfall eine
    gesetzliche Verpflichtung.<br />
    <br />*/}
    <b>10. Rechte der Betroffenen</b>
    <br />
    <br />
    Art. 15 DSGVO: Sie haben jederzeit das Recht, Auskunft darüber zu erhalten,
    ob und welche Daten über Sie gespeichert sind bzw. verarbeitet werden.
    <br />
    <br />
    Art. 16 DSGVO: Sie haben ein Recht auf unverzügliche Berichtigung Sie
    betreffender unrichtiger Daten und/oder die Vervollständigung Ihrer bei uns
    gespeicherten unvollständigen Daten.
    <br />
    <br />
    Sie haben ein Recht auf unverzügliche Löschung der betreffenden persönlichen
    Daten gemäß Art. 17 DSGVO.
    <br />
    <br />
    Art. 20 DSGVO: Sie haben das Recht auf Datenübertragbarkeit.
    <br />
    <br />
    Gemäß Art. 77 DSGVO haben Sie das Recht eine Beschwerde bei der zuständigen
    Aufsichtsbehörde einzureichen.
    <br />
    <br />
    Gemäß Art. 7 Abs. 3 DSGVO haben Sie das Recht, eine einst erteilte
    Einwilligung in die Verarbeitung von Daten jederzeit zu widerrufen.
    <br />
    <br />
    <b>
      Wir weisen Sie ferner darauf hin, dass Sie der künftigen Verarbeitung
      Ihrer personenbezogenen Daten entsprechend den gesetzlichen Vorgaben gem.
      Art. 21 DSGVO jederzeit widersprechen können. Der Widerspruch kann
      insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.
    </b>
    <br />
    <br />
    25-05-2018
  </div>
);
