import React, { Component } from 'react';
import cn from 'classnames';
import Lang from './Lang';

export default class PsListMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
    };
  }
  render() {
    return (
      <div>
        {this.props.items.map((item, index) => (
          <div key={index}>
            <div
              style={{ marginBottom: '20px' }}
              className={cn('col-sm-3', 'col-xs-6', 'text-center', {
                'hidden-xs': !this.state.open && index > 1,
                'hidden-sm': !this.state.open && index > 3,
                'hidden-md': !this.state.open && index > 3,
                'hidden-lg': !this.state.open && index > 3,
              })}>
              {this.props.renderItem(item)}
            </div>
            {++index % 2 === 0 && <div className="clearfix visible-xs" />}
            {index % 4 === 0 && (
              <div className="clearfix visible-sm visible-md visible-lg" />
            )}
          </div>
        ))}
        {!this.state.open && (
          <div
            className={cn('text-center', {
              'hidden-xs': this.props.items.length <= 2,
              'hidden-sm': this.props.items.length <= 4,
              'hidden-md': this.props.items.length <= 4,
              'hidden-lg': this.props.items.length <= 4,
            })}>
            <a onClick={() => this.setState({ open: true })} className="">
              <span className="caret" />{' '}
              {this.props.moreText || (
                <Lang de="weitere Modelle" en="more trophies" />
              )}
            </a>
          </div>
        )}
      </div>
    );
  }
}
