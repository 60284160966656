import React from 'react';
import Lang from './Lang';

export default function PaymentAlreadyDone() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1 className="page-header">
            <Lang
              de={`Deine Bestellung ist bereits bezahlt`}
              en={`Your order has already been paid`}
            />
          </h1>
        </div>
      </div>

      <p style={{ marginTop: 20 }}>
        <Lang
          de={`Vielen Dank. Deine Bestellung ist bereits bezahlt.`}
          en={`Thank you. Your order has already been paid.`}
        />
      </p>
    </div>
  );
}
