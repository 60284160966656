export default {
  url: 'rosen-basteln',
  countermark: 'vg07.met.vgwort.de/na/bec58c1da8f5430f91270a99961ac86b',
  imageFolder: 'rose',

  name: {
    de: ' Rose',
    en: ' Rose',
  },
  id: '3-17-blog',
  pdf: {
    price: 3.9,
    priceThirdCountry: 3.9,
    image: {
      filename: {
        de: 'content/rose/rosen-selber-machen-papier.jpg',
        en: 'content/rose/rosen-selber-machen-papier.jpg',
      },
      alt_text: {
        de: 'DIY Rosen basteln aus Papier ',
        en: 'Learn how to make paper roses',
      },
    },
  },

  meta_title: {
    de: 'Rosen basteln als nachhaltige Geschenkidee ',
    en: 'How to make paper roses',
  },
  meta_description_google: {
    de: 'Schöne Rosen mit Papier basteln geht mit der PaperShape Vorlage ganz einfach.',
    en: 'Learn how to make paper roses with this easy template for download ',
  },
  meta_description_social: {
    de: 'DIY Rosen basteln: Anleitung für besondere Anlässe wie Muttertag oder Hochzeit #bastelnmitpapier #paperflowers #papercraft',
    en: 'How to make paper roses #paperflowers #papercraft',
  },
  meta_images_social: [
    {
      de: 'rosen-selber-machen-papier.jpg',
      en: 'rosen-selber-machen-papier.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'rosen-basteln-papier.jpg',
      en: 'rosen-basteln-papier.jpg',
    },
    alt_text: {
      de: 'Rosen basteln mit Papier zum Muttertag #papierrosen #muttertag #papierblumen #bastelnmitpapier',
      en: 'Learn how to make paper roses #paperflowers #papercraft #mothersday',
    },
  },
  h1: {
    de: 'Rosen basteln als nachhaltige Geschenkidee ',
    en: 'How to make paper roses',
  },
  description: {
    de: `
      <p>Es gibt DIY Ideen, die eignen sich einfach für alle Gelegenheiten - wie diese Rosen zum Beispiel. </p>
      <p>Papierrosen selber machen ist nicht nur eine coole Freizeitbeschäftigung, sie vermitteln auch eine schöne Symbolik, die eben von Herzen kommt. </p>
      <p>Sie sind das Zeichen der Liebe und werden daher nicht umsonst am liebsten am Valentinstag, Muttertag, Geburtstagen oder Hochzeiten verschenkt.  </p>
     
      <p>Leider welken diese duftenden Blümchen aber viel zu schnell und haben meist eine sehr weite Anreise. 
      Wie wäre es daher mit dieser nachhaltigen DIY Idee: Rosen basteln aus Papier.</p>
      
      <p>Weitere schöne Papierblumen könnt ihr hier basteln: <a href="/de/blog/hyazinthen-basteln/">Hyazinthen aus Papier</a> oder <a href="/de/blog/papier-tulpen-basteln/">einfache Tulpen</a>.</p>
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: '2 DIY Geschenkideen mit Papierrosen ',
    en: '2 fun ideas with paper roses',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'papier-rose-muttertag-geschenk-verpacken.jpg',
          en: 'papier-rose-muttertag-geschenk-verpacken.jpg',
        },
        alt_text: {
          de: 'Zum Muttertag Geschenke schön verpacken mit einer selbstgemachten Papierrose #papierblumen #bastelnmitpapier #diy #paperrose ',
          en: 'Make this lovely paper rose topper for your Mothersday Gift #paperflowers #paper #papercraft #packaging #paperrose #diy',
        },
      },
    },
    {
      text: {
        de: `So wie hier bei diesen <a href="/de/blog/gaensebluemchen-basteln/">Papier-Gänseblümchen</a>  könnt ihr die Rosen aus Tonpapier ebenfalls als Deko für eure Geschenkboxen 
        verwenden. Sie werten eure Geschenkverpackung einfach einen Ticken auf und steigern so die Vorfreude beim Beschenkten.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'rosen-basteln-papierblumen.jpg',
          en: 'rosen-basteln-papierblumen.jpg',
        },
        alt_text: {
          de: 'DIY Papierblumen basteln: schöne Rosen falten für eine dekorative Vase #bastelnmitpapier #papierblumen #papierrosen #diy',
          en: 'DIY paper rose tutorial: lovely paper flowers for every occasion #papercraft #paperflower #paperdiy #paperrose',
        },
      },
    },
    {
      text: {
        de: `Ein Blumenstrauß geht einfach immer. Egal, ob ihr ihn verschenken möchtet, als Add-on für ein Hochzeitsgeschenk nutzen möchtet oder sie in einer 
        Vase hübsch angerichtet. Sie sind dekorativ. Meine Mom z.B. liebt Blumen über alles. Wenn das bei eurer Mutter auch so ist, dann werdet ihr mit diesen Rosen eine schöne Idee
        für den Muttertag gestalten können. 
         `,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'Wie macht man Rosen aus Papier - Anleitung',
    en: 'Step by step tutorial for paper roses',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'rosen-vorlage-ausschneiden.jpg',
          en: 'rosen-vorlage-ausschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Ihr benötigt pro Rose 1 DINA4 Tonpapier mit max. 130g/m². Druckt euch die Vorlage aus und schneidet vorerst nur die Quadrate mit einer Schere aus. Anstelle von farbigem
        Tonpapier könnt ihr diese Papierrosen auch aus Butterbrotpapier oder Zeitungspapier selber machen. Dazu die Vorlage einfach übertragen und den weieren Schritten folgen.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'rosen-blatt-falten.jpg',
          en: 'rosen-blatt-falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Knickt alle vier Quadrate je zweimal, sodass ein kleines Quadrat entsteht und ihr die aufgedruckte Blüte seht.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'rosenbluete-ausschneiden.jpg',
          en: 'rosenbluete-ausschneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Schneidet jedes der vier Quadrate entlang der Rosenblüte aus.
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'rosenblueten-teilen.jpg',
          en: 'rosenblueten-teilen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Öffnet das erste Quadrat und schneidet nun entlang der durchgezogenen Linie einzelne Blüten aus. Auf jeder Blüte ist die Linie anders.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'acht-rosenblaetter.jpg',
          en: 'acht-rosenblaetter.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Wenn ihr den dritten Schritt richtig ausgeführt habt, solltet ihr nun 7 Blüten haben. Eine Hälfte der Blüte, die ihr in der Hälfte durchgeschnitten habt, fehlt hier (und das 
          gehört auch so.)
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'rose-holzspiess-wickeln.jpg',
          en: 'rose-holzspiess-wickeln.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Wickelt die einzelnen Blütenblätter mit einem Holzspieß (Schaschlikspieß) leicht auf, sodass sie nach außen zeigen. Achtet dabei darauf in welche Richtung eure Knicke gehen und
        wickelt in die entgegensetzte Richtung. Das vereinfacht nachher das Verkleben.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '7.jpg',
          en: '7.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Die kleinste Blüte (ein Blatt) wickelt ihr ungefähr wie auf dem Bild zu sehen auf einem Holzspieß zu einer kleinen Rosette `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '8.jpg',
          en: '8.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Mit dem Zweiblüter macht ihr das Gleiche. Ich empfehle diesen Schritt, damit ihr es gleich beim Verkleben einfacher habt.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'rose-papier-kleben.jpg',
          en: 'rose-papier-kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Setzt einen Kleckser Kleber auf die obere Hälfte der Rosenblüte. Diesen Schritt wiederholt ihr für alle Rosenblüten.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'rose-zusammengeklebt.jpg',
          en: 'rose-zusammengeklebt.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Nun klebt ihr die einzelnen Blüten zusammen, sodass sich die äußeren Rosenblätter leicht überlappen.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '11.jpg',
          en: '11.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Am Ende solltet ihr 7 geschlossene Rosenblüten haben wie im Bild zu sehen.
  `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'rosen-ineinanderstecken.jpg',
          en: 'rosen-ineinanderstecken.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Der Größe nach werden nun in absteigender Reihenfolge die Rosenblüten ineinander gesteckt. Wenn ihr sie lediglich als Blüte ohne Stiel basteln wollt, dann könnt ihr
        nun mit einer Heißklebepistole in die Mitte der Blüte Kleber auftragen und die nächstkleinere Blüte aufkleben. Dies wiederholt ihr mit allen Blüten. Falls ihr aber einen Stiel verwenden
        möchtet, dann stecht am besten mit einer Ahle kleine Löcher vor oder schneidet ein kleines Dreieck von der Spitze der Blüten ab. Anschließend könnt ihr einen Draht oder Holzspieß durch
      stecken.
  `,
        en: ``,
      },
    },
  ],

  downloadName: 'vorlage-rose_papershape.pdf',
  materials: [
    {
      de: 'Vorlage herunterladen (siehe unten)',
      en: 'Download the template (see below)',
    },
    {
      de: '1 DINA4 Tonpapier in rot (bis zu 130g/m²)',
      en: '1 colored paper in red (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder Heißkleberpistole',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },

    {
      de: 'Holzspieß (Schaschlikspieß)',
      en: 'wooden stick',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },

    {
      de: 'ggf. Steckdraht & Floristenkrepp',
      en: 'wire',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'rosen-basteln-anleitung.jpg',
          en: 'rosen-basteln-anleitung.jpg',
        },
        alt_text: {
          de: 'Rosen basteln aus Papier mit Anleitung #bastelnmitpapier #papierblumen #papierrose',
          en: 'how to make paper roses step by step #paperflowers #papercrafting #papercraft #paperrose',
        },
      },
    ],
  },
};
