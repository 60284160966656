export default {
  id: '2-21-sfk',
  category: ['christmas'],
  name: {
    de: 'Weihnachtskugeln (12 Stück)',
    en: 'Christmas Paper Ornaments (12 pc)',
  },
  subtitle: {
    de: 'B8 x H9 x T8 cm',
    en: 'W8 x H9 x D8 cm',
  },
  meta_title: {
    de: '3D Weihnachtskugeln aus Papier (DIY Set und Download)  ',
    en: '3D paper ornaments for Christmas',
  },
  meta_description_google: {
    de: 'Auf der Suche nach individuellen Weihnachtskugeln für den Tannenbaum? Dann sind diese Papierkugeln mit der einfachen DIY Vorlage genau das Richtige für dich.  ',
    en: 'How to make simple Christmas paper ornaments for the Christmas tree or home decor? Try this simple papercraft tutorial with a link directly to the template. ',
  },
  meta_description_social: {
    de: 'Auf der Suche nach individuellen Weihnachtskugeln für den Tannenbaum? Dann sind diese Papierkugeln mit der einfachen DIY Vorlage genau das Richtige für dich.',
    en: 'How to make simple Christmas paper ornaments for the Christmas tree or home decor? Try this simple papercraft tutorial with a link directly to the template. ',
  },
  description: {
    de: `
    Was ist drin im DIY Kit?
     
    - Set für 12 Weihnachtskugeln: 13 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -Set of 12 paper ornaments: 13 printed DINA4 cardboard sheets for the perfect stability
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 2 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 1-2 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 2 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 1-2 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkweihnachtskugel/weihnachtskugel-papier-papercraft.jpg',
        en: 'products/sfkweihnachtskugel/weihnachtskugel-papier-papercraft.jpg',
      },
      alt_text: {
        de: 'DIY Papier Hängeschmuck als Weihnachtskugeln für den Tannenbaum oder als Deko #papercraft #bastelnmitpapier #weihnachtsdeko #christbaumschmuck',
        en: 'Paper Christmas Ornaments with digital template #papercraft #paperornament #diychristmas #diy #christmas',
      },
    },
    price: 6.9,
    priceThirdCountry: 6.9,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkweihnachtskugel/weihnachtskugel-papier-papercraft.jpg',
        en: 'products/sfkweihnachtskugel/weihnachtskugel-papier-papercraft.jpg',
      },
      alt_text: {
        de: 'DIY Weihnachtskugeln aus Papier basteln mit Vorlage zum Download #papercraft #bastelnmitpapier #origami #papershape',
        en: 'Christmas Paper Ornaments #papercraft #origami #christmasdecor #paperornaments',
      },
    },

    colors: {
      color1: {
        de: 'Kugel',
        en: 'Ball',
      },
      color2: {
        de: 'Steckkopf',
        en: 'Head',
      },
    },
    disableColors: {
      color2: ['goldmatt', 'tuerkis', 'bunt'],
      color1: ['goldmatt', 'tuerkis'],
    },
    price: 16.9,
    priceThirdCountry: 15.9,
  },
};
