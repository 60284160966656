export default {
  id: '2-29-sfk',
  category: ['home_decor'],

  name: {
    de: 'Banane',
    en: 'Banana',
  },
  subtitle: {
    de: 'B29 x H15 x T6 cm',
    en: 'W29 x H15 x D1 cm',
  },
  meta_title: {
    de: '3D Banane aus Papier basteln',
    en: 'How to papercraft a 3d banana ',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für eine große Papercraft Banane dekorierst du dein Zuhause ganz im Flair der Pop-Art Kunst. Wie wäre es, diese stattliche Banane von 29cm auf einem Regal als Einzelstück zu inszenieren?',
    en: 'Add a piece of pop-art to your home with this large papercraft banana template. How about staging it on a shelf as a standalone piece',
  },
  meta_description_social: {
    de: 'Eine Banane basteln aus Papier ist mit unserer Vorlage ganz einfach. Dieses süße 3D Obst hält sich als Sommer-Dekoidee für deine Wohnung sehr lange, denn die low poly Banane wird nie braun. Lade jetzt die Vorlage in unserem Shop herunter.',
    en: 'Making a papercraft banana is very easy with our template. This cute 3D fruit will last a long time as a summer decoration idea for your home, because the low poly banana never turns brown. Download the template in our store now.',
  },

  pdf: {
    description: {
      de: `Eine Banane basteln aus Papier ist mit unserer Vorlage ganz einfach. Dieses süße 3D Obst hält sich als Sommer-Dekoidee für deine Wohnung sehr lange, denn die low poly Banane wird nie braun. 

      Mit unserer großen 3D Papercraft Banane dekorierst du dein Zuhause ganz im Flair der Pop-Art Kunst. Wie wäre es, diese stattliche Banane von 29cm auf einem Regal als Einzelstück zu inszenieren? 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² - 230g/m² Tonpapier zu verwenden. Damit die Banane gelingt, benötigst du einen Drucker, 3 Bögen Tonpapier, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `Making a papercraft banana is very easy with our template. This cute 3D fruit will last a long time as a summer decoration idea for your home, because the low poly banana never turns brown. 

      Decorate your home with the flair of pop art with our large 3D banana. How about displaying this stately 29 cm banana on a shelf as a unique piece? 
      
      You can get started straight away with the PDF template in A4 format that you can print out yourself. I recommend that you use 130g/m² - 230g/m² paper.
      To make the banana a success, you will need a printer, 3 sheets of construction paper, scissors and glue.

Translated with www.DeepL.com/Translator (free version)`,
    },
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'products/sfkbanane/obst-basteln-banane-papier.jpg',
        en: 'products/sfkbanane/obst-basteln-banane-papier.jpg',
      },
      alt_text: {
        de: 'Selbst gebastelte 3D Banane aus Papier in gelb auf einem Podest ',
        en: 'Papercrafted 3D banana in yellow on a pedestal',
      },
    },
  },
};
