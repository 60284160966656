import React from 'react';
import PsYoutube from '../../common/PsYoutube';
import { getTextBySelectedLang } from '../../data/lang';

export default ({ videoid }) => {
  return (
    <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-offset-2 col-md-8 text-center">
            <PsYoutube
              title={getTextBySelectedLang(
                'Anleitungsvideo',
                'My tutorial videos'
              )}
              videoid={videoid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
