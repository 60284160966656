import { round } from 'lodash/math';

const gtag = window.gtag;
const fbq = window.fbq;


export function trackAddToCart(event, orderProductEnhanced) {
  if (gtag) {
    gtag("event", "add_to_cart", {
      currency: "EUR",
      value: orderProductEnhanced.pricePerUnit,
      items: [{
        item_id: orderProductEnhanced.id,
        item_name: orderProductEnhanced.name.en,
        // variant: orderProductEnhanced.variantKey,
        price: orderProductEnhanced.pricePerUnit,
        quantity: orderProductEnhanced.quantity,
      }]
    });
  }
  if (fbq && event === 'add') {
    fbq('track', 'AddToCart', {
      content_ids: [orderProductEnhanced.id],
      content_type: 'product',
      value: orderProductEnhanced.pricePerUnit,
      currency: 'EUR',
    });
  }
}

export function trackViewProduct(productId, name, variantKey, price) {
  if (gtag) {
    gtag("event", "view_item", {
      currency: "EUR",
      value: price,
      items: [{
        item_id: productId,
        item_name: name.en
      }]
    });
  }

  if (fbq) {
    fbq('track', 'ViewContent', {
      content_type: 'product',
      content_name: name.en,
      content_ids: [productId],
      value: price,
      currency: 'EUR',
    });
  }
}

export function trackCheckout(orderProductsEnhanced) {
  if (gtag) {
    gtag("event", "begin_checkout", {
      currency: "EUR",
      value: orderProductsEnhanced.reduce(
        (accu, orderProductEnhanced) =>
          round(accu + orderProductEnhanced.subTotal, 2),
        0
      ),
      items: orderProductsEnhanced.map(product => ({
        item_id: product.id,
        item_name: product.name.en,
        // variant: orderProductEnhanced.variantKey,
        price: product.pricePerUnit,
        quantity: product.quantity,
      }))
    });
  }
  if (fbq) {
    fbq('track', 'InitiateCheckout', {
      content_type: 'product',
      content_ids: orderProductsEnhanced.map((orderProduct) => orderProduct.id),
      num_items: orderProductsEnhanced.reduce(
        (quantitySum, orderProduct) =>
          round(quantitySum + orderProduct.quantity, 2),
        0
      ),
      value: orderProductsEnhanced.reduce(
        (sum, orderProduct) =>
          round(sum + orderProduct.pricePerUnit * orderProduct.quantity, 2),
        0
      ),
      currency: 'EUR',
    });
  }
}

export function trackPurchase(
  orderProductsEnhanced,
  orderNumber,
  revenue,
  tax,
  shipping,
  voucher
) {
  if (gtag) {

    gtag("event", "purchase", {
      currency: "EUR",
      transaction_id: orderNumber,
      value: revenue,
      shipping,
      tax,
      coupon: voucher,
      items: orderProductsEnhanced.map(product => ({
        item_id: product.id,
        item_name: product.name.en,
        // variant: orderProductEnhanced.variantKey,
        price: product.pricePerUnit,
        quantity: product.quantity,
      }))
    });
  }
}

export function trackNewsletter(label) {
  return () => {
    if (gtag) {
      // gtag('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
      gtag('evend', 'newsletter', 'newsletter', 'click', label);
    }
  };
}

export function trackDownload(name, type = 'download') {
  return () => {
    if (gtag) {
      gtag('event', 'send_pdf', 'pdf', type, name);
    }
  };
}

export function trackSocialButton(type) {
  return () => {
    if (gtag) {
      gtag('event', 'social-button', 'social-button', 'share', type);
    }
  };
}
