export default {
  url: 'love-schriftzug-basteln',
  countermark: 'vg07.met.vgwort.de/na/bb4113880e7345b2a0cf481e29db124e',
  imageFolder: 'love-schriftzug',
  meta_title: {
    de: '3d LOVE Schriftzug basteln mit Papier (Vorlage)',
    en: 'How to make a 3d love lettering from paper (template)',
  },

  name: {
    de: ' Buchstaben-Set LOVE',
    en: ' Letter set - LOVE',
  },
  id: '3-20-blog',
  pdf: {
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'content/love-schriftzug/3d-love-schriftzug-basteln-mit-papier.jpg',
        en: 'content/love-schriftzug/3d-love-schriftzug-basteln-mit-papier.jpg',
      },
      alt_text: {
        de: 'DIY Buchstaben LOVE basteln aus Papier ',
        en: 'Learn how to make a paper letters: LOVE',
      },
    },
  },

  meta_description_google: {
    de: 'In diesem Post zeige ich dir, wie du einen LOVE Schriftzug aus 3D Buchstaben mit Papier bastelst. Die Vorlage gibt es gratis.',
    en: ' In this post you will learn how to papercraft your own love lettering from paper.',
  },
  meta_description_social: {
    de: 'Bastel aus diesen 3d Buchstaben einen coolen LOVE Schriftzug. Zum Valentinstag ist das ein super cooles Last-Minute DIY Geschenk. Hop rüber zum gratis Download der Vorlage.',
    en: 'Make your own super cool 3d love lettering. Jump over to download the template',
  },
  meta_images_social: [
    {
      de: '3d-love-schriftzug-basteln-mit-papier.jpg',
      en: '3d-love-schriftzug-basteln-mit-papier.jpg',
    },
  ],
  image_main: {
    filename: {
      de: '3d-love-schriftzug-basteln-mit-papier.jpg',
      en: '3d-love-schriftzug-basteln-mit-papier.jpg',
    },
    alt_text: {
      de: '3d Schriftzug aus Papier selber basteln zum Valentinstag',
      en: '3d papercraft letters',
    },
  },
  h1: {
    de: '3d LOVE Schriftzug basteln mit Papier (Vorlage)',
    en: 'How to make a 3d love lettering from paper (template)',
  },
  description: {
    de: `
    <p>Ein Liebes-Schriftzug ist ein so bedeutungschwangeres Symbol und kann zu so vielen
     Gelegenheiten verschenkt und dekoriert werden. Das ist auch der Grund, warum ich dir
      in diesem Post zeigen möchte, wie du einen modernen Love Schriftzug aus Papierbuchstaben
       ganz einfach selber bastelst. Tatsächlich sind diese 3D Buchtaben ziemlich einfach selber
        zu machen und deine Kinder helfen dir sicher super gerne dabei. Du kannst diesen Love 
        Schriftzug auf eine Leinwand kleben, auf ein Holzbrett anbringen, an einem Fenster 
        herunterhängen lassen oder einfach auf Pappe kleben und zum Valentinstag, Muttertag, 
        Vatertag oder zur Hochzeit verschenken oder einfach als Deko für deine Wohnung aufhängen.</p>
     
      <p>Als Ergänzung machen sich <a href="/de/blog/herzschachtel-basteln/"> diese Herzschachteln als Geschenkverpackung</a> oder einfach nur
      als Deko super  .</p>
      
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '3d-love-buchstaben-basteln-mit-papier.jpg',
          en: '3d-love-buchstaben-basteln-mit-papier.jpg',
        },
        alt_text: {
          de: '3d Buchstaben als Schriftzug zu Love basteln',
          en: '3d letters from paper',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '3d-buchstaben-basteln-love.jpg',
          en: '3d-buchstaben-basteln-love.jpg',
        },
        alt_text: {
          de: 'Zum Muttertag einen 3d Schriftzug LOVE selber basteln und verschenken.',
          en: 'Papercraft this modern 3d lettering love for mothers day',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'Buchstaben-Liebe-Valentinstag-basteln.jpg',
          en: 'Buchstaben-Liebe-Valentinstag-basteln.jpg',
        },
        alt_text: {
          de: 'Zum Muttertag einen 3d Schriftzug LOVE selber basteln und verschenken.',
          en: 'Papercraft this modern 3d lettering love for mothers day',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'Wie mache ich einen Schriftzug aus 3d Buchstaben selber?',
    en: '',
  },
  steps: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Ihr benötigt pro Buchstaben 1-2 DIN A4 Seiten in einem satten Rotton.
        Verwendet am besten Tonkarton mit einer Grammatur von bis zu 220g/m. Dann habt ihr 
        nämlich eine gute Stabilität.
       Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer
        Schere aus. Beim Buchstaben O shneidet ihr das kleinere O 
        besser mit einem Cutter aus.
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Falzt mit einer Ahle und einem Lineal alle gestrichelten Linien 
        (egal ob lang oder kurz gestrichelt) vor. Dazu das Lineal an die Linie
        anlegen und mit der Ahle mit Druck nachfahren.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Faltet die lang gestrichelten Linien von euch weg, 
        d.h. nach unten. Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin.
        `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Klebt auf die Laschen der einzelnen Papierteile Klebepads. 
        Wichtig ist, dass ihr das Pad auf die schöne Seite und damit die unbedruckte Seite 
        der Lasche klebt. Die Reihenfolge ist bei diesen sehr einfachen Vorlagen egal. 
        Hauptsache ihr verklebt die Zahlenpaare miteinander, die zueinander gehören, 
        d.h. 1 und 1 zum Beispiel.
        
  `,
        en: ``,
      },
    },
  ],
  video: {
    title: {
      de: 'Im Video zeige ich dir, wie du den Love-Schriftzug ganz einfach selber machst.',
      en: '',
    },
    description: {
      de: `  `,
      en: ``,
    },
    caption: {
      de: `3D LOVE-Schriftzug basteln`,
      en: `3d Love lettering`,
    },
    videoid: 'AkuVPSgW_yQ',
  },
  downloadName: 'lettering-love_papershape.pdf',
  materials: [
    {
      de: 'je nach Buchstabe 1-2 DIN A4 Tonkarton in rot (bis zu 220g/m²)',
      en: '1-2 colored papers in red per letter (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: '3d-schriftzug-diy-love-papier.jpg',
          en: '3d-schriftzug-diy-love-papier.jpg',
        },
        alt_text: {
          de: 'DIY Geschenkidee zur Hochzeit oder Valentinstag: 3d Buchstaben aus Papier zum selber machen #bastelnmitpapier #valentinstag #papercraft #love #diygeschenke',
          en: 'DIY 3d lettering LOVE',
        },
      },
    ],
  },
};
