export default {
  url: 'geschenktuete-basteln',
  countermark: 'vg07.met.vgwort.de/na/3e5fe3530f45476fb54c870d37e12f60',
  imageFolder: 'geschenktuete-regenbogen',

  landingPage: ['diy-geschenke'],

  meta_title: {
    de: 'Lustige Geschenktüte basteln zum Kindergeburstag (mit Vorlage)',
    en: 'How to make funny gift paper bags (with template) ',
  },
  meta_description_google: {
    de:
      'An Kindergeburstagen braucht es etwas besonderes: diese Geschenktüten im Wolken-Regenbogen-Look sind das perfekte Mitgebsel für die Kids. ',
    en:
      'Learn how to make these funny paper gift bags for children. Is is super easy and fun! ',
  },
  meta_description_social: {
    de:
      'An Kindergeburstagen braucht es etwas besonderes: diese Geschenktüten im Wolken-Regenbogen-Look sind das perfekte Mitgebsel für die Kids. #bastelnmitpapier #papershape',
    en:
      'Learn how to make these funny paper gift bags for children. Is is super easy and fun!',
  },
  meta_images_social: [
    {
      de: 'geschenktuete-basteln-kindergeburtstag.jpg.jpg',
      en: 'geschenktuete-basteln-kindergeburtstag.jpg.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'geschenktuete-basteln.jpg',
      en: 'geschenktuete-basteln.jpg',
    },
    alt_text: {
      de:
        'Papiertüten basteln (Anleitung) zum Verschenken #papercraft #bastelnmitpapier',
      en: 'How to make paper gift bags - rainbow and clouds #papercraft',
    },
  },
  h1: {
    de: 'Lustige Geschenktüte basteln im Wolken-Look',
    en: 'Funny paper gift bags with clouds and rainbow',
  },
  description: {
    de: `
      <p>Im siebten Himmel schwelgen - oh yes.</p>
      <p>In diesem Post zeige ich euch, wie ihr ganz einfach eine Papiertüte basteln könnt, die aber bitte nicht als schlichtes Rechteck. </p>
      <p>Wir designen aus ihr eine Wolkentüte und der Henkel wird zum bunten Regenbogen. </p>
      <p>Papiertüten lassen sich nicht nur für kleine Geschenke verwenden (z.B. an Kindergeburtstagen), sondern auch super als alternative Osternester.   </p>
     
      <p>Hier zeige ich euch noch eine weitere <a href="/de/blog/osterkoerbchen-basteln/">Idee für ein Osterkörbchen</a>. Ihr könnt es auch einfach als coole Schale verwenden.</p>
      
    
    `,
    en: `
      <p>
      
      </p>
          <p>
     
      </p>
    `,
  },
  inspirationTitle: {
    de: 'Papiertüten basteln in unterschiedlichen Größen',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'geschenktuete-basteln-klein-gross.jpg',
          en: 'geschenktuete-basteln-klein-gross.jpg',
        },
        alt_text: {
          de:
            'Geschenktüten basteln zum Kindergeburstag mit Anleitung #papercraft #bastelnmitpapier #geschenktüten #diy',
          en: 'How to make paper gift bags - rainbow and clouds #papercraft',
        },
      },
    },
    {
      text: {
        de: `Die Geschenktüten könnt ihr je nach Inhalt in unterschiedlichen Größen gestalten. Alle drei Größen findet ihr
        in der Vorlage. `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: 'geschenktuete-basteln-regenbogen-wolke.jpg',
          en: 'geschenktuete-basteln-regenbogen-wolke.jpg',
        },
        alt_text: {
          de:
            'Geschenktüten für den Kindergeburtstag - Regenbogen und Wolken und jede Menge Leckereien #papercraft #diy #bastelnmitkindern',
          en: 'How to make paper gift bags - rainbow and clouds #papercraft',
        },
      },
    },
    {
      text: {
        de: `Überreicht diese süßen Geschenktüten an eure Freunde und ein Lächeln ist euch sicher.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'geschenktuete-basteln-ostergeschenk.jpg',
          en: 'geschenktuete-basteln-ostergeschenk.jpg',
        },
        alt_text: {
          de:
            'Ostertüten basteln mit Kindern in Regenbogen-Wolke Motiv #papercraft #ostern #easterdiy #bastelnmitpapier',
          en: 'How to make paper gift bags - rainbow and clouds #papercraft',
        },
      },
    },
    {
      text: {
        de: `Ihr könnt in diese Geschenktüten passend zu Ostern und zum Frühling auch Ostereier reinlegen. Es muss
        nicht immer der Osterhase sein als Motiv sein.`,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: 'geschenktuete-basteln-kinder-regenbogen.jpg',
          en: 'geschenktuete-basteln-kinder-regenbogen.jpg',
        },
        alt_text: {
          de:
            'Lustige DIY Geschenktüte basteln für Kindergeburtstag oder Osterbrunch (mit Anleitung und Vorlage) #papercraft #bastelnmitpapier #geschenktüte ',
          en: 'How to make paper gift bags - rainbow and clouds #papercraft',
        },
      },
    },
    {
      text: {
        de: `Mit Backpapier oder Packpapier ausgestopft, wertet ihr das Weiß der Papiertüte schön auf. Und jetzt noch ein Muffin
        oder Stück Torte hinein, bitte.`,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: 'Geschenktüten basteln - Vorlagen und Anleitung',
    en: 'Learn how to make paper gift bags (template and tutorial)',
  },
  steps: [
    {
      text: {
        de: `Ihr benötigt für eine Papiertüte ein DIN A4 Papier. Am besten in einer Grammatur von 120-130g.
        Ich habe es mit den unterschiedlichsten Papieren ausprobiert: Kopierpapier ist auch möglich, 
        allerdings kann man dann eher nichts Schweres reinlegen. Fotokarton ist viel zu dick. Da werdet ihr 
        die Laschen nicht umknicken können. Druckt euch die Vorlage für eure Wunschgröße aus und weiter geht es
        mit Schritt 2.
       
        `,
        en: ``,
      },
    },
    {
      text: {
        de: `Die gestrichelten Linien knickt ihr nun in beide Richtungen. Einmal nach vorne und
        einmal nach hinten. Die Mittelfalze habe ich lediglich angedeutet, damit sie von außen nachher nicht so sichtbar ist. `,
        en: ``,
      },
    },
    {
      text: {
        de: `Klebt die Laschen in der Mitte mit Kleber zusammen, sodass sie sich überlappen.
        `,
        en: ``,
      },
    },

    {
      text: {
        de: `Falte nun die jeweils linke und rechte Ecke zu einem Dreieck nach oben zur bereits gefalzten Linie. Das wiederholst
        du auf der anderen Seite auch. So lässt sich die Papiertüte später deutlich besser aufstellen.
  `,
        en: ``,
      },
    },

    {
      text: {
        de: `Schneide nun die Wolken aus.
  `,
        en: ``,
      },
    },

    {
      text: {
        de: `Dieser Schritt ist optional: Je nachdem, ob du die Tüte gerne rechteckig stehend oder lieber unten breit und
          oben flach hättest. Um Sie rechtecktig zu gestalten, faltest du die linke und rechte Kante der Tüte nach innen und zwar so, 
          wie im Video gezeigt. Auch diesen Vorgang in beide Richtungen wiederholen.
  `,
        en: ``,
      },
    },

    {
      text: {
        de: `Im letzten Schritt schneidest du dir drei Recktecke aus dem pinken, gelben und blauen Papier. Eines der Rechtecke sollte
        ca. 2,5cm breit sein, die anderen zwei 0,5cm. Diese zwei kleineren kleben wir nämlich auf das große Rechteck auf. 
  `,
        en: ``,
      },
    },

    {
      text: {
        de: `Jetzt nur noch den Regenbogen als Henkel an die Tasche ankleben. Ich verwende dafür gerne Klebepads, aber man 
        kann auch Kleber nehmen. Für die Deko lege ich gerne noch Packpapier in die Tüten hinein. Wer keines zur Hand hat, kann auch
        einfach Backpapier reinstecken. Das habe ich auf den Bildern zum Beispiel auch gemacht. 
  `,
        en: ``,
      },
    },
  ],

  video: {
    title: {
      de: 'DIY Geschenktüten selber machen (Video Anleitung)',
      en: 'How to make paper gift bags (video tutorial)',
    },
    description: {
      de: `In diesem Video zeige ich euch, wie ihr ganz einfach Papiertüten aus Papier basteln könnt - im lustigen Wolken-Look..  `,
      en: `In this video you'll learn how to make funny paper gift bags`,
    },
    caption: {
      de: `Papiertüten basteln Video`,
      en: `How to make paper gift bags (video tutorial)`,
    },
    videoid: 'ERq-ZrXEnXo',
  },
  downloadName: 'geschenktueten-regenbogen-wolke_papershape.pdf',
  materials: [
    {
      de: 'Vorlage downloaden (siehe unten)',
      en: 'Download template (see below)',
    },

    {
      de: '1 DINA4 Papier in weiß (bis zu 130g/m²)',
      en: '1 colored paper in white (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: '3 Papierstreifen in den Farben pink, blau, gelb (bis zu 130g/m²)',
      en:
        '3 colored paper stripes in pink, blue and yellow (8.5in x 11in) (weight: 65lb-110lb)',
    },
    {
      de: 'Bastelkleber oder Tesafilm',
      en: 'Glue or tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },

    {
      de: 'Stift zum Aufmalen der Augen',
      en: 'Pen for drawing the eyes',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'geschenktuete-basteln-kindergeburtstag.jpg',
          en: 'geschenktuete-basteln-kindergeburtstag.jpg',
        },
        alt_text: {
          de:
            'Geschenktüte basteln als Wolke - auch genial als Osternester #geschenktüte #papiertüte #bastelnmitpapier #diy #papercraft',
          en: 'how to make paper gift bags - rainbow and clouds #papercraft',
        },
      },
    ],
  },
};
