import React, { Component } from 'react';
import { connect } from 'react-redux';
import Lang from '../../common/Lang';
import PaymentAlreadyDone from '../../common/PaymentAlreadyDone';
import PaymentError from '../../common/PaymentError';
import { getTextBySelectedLang, selectedLang } from '../../data/lang';
import {
  getApprovalUrl,
  getIsPaymentDone,
  getIsPaymentError,
} from '../../state/payment/paymentSelectors';
import { createPayment } from '../../state/payment/paymentActions';
import PsMeta from '../../common/PsMeta';
import env from '../../env';
import { getOrder } from '../../state/order/orderSelectors';

class PaymentStart extends Component {
  constructor() {
    super();
    this.state = {
      scriptLoaded: false,
      paypalInitialized: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(createPayment(this.props.match.params.orderNumber));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const approvalUrl = nextProps.approvalUrl || this.props.approvalUrl;
    const order = nextProps.order || this.props.order;
    const allValuesSet = approvalUrl && order;
    if (this.props.isPaymentDone || nextProps.isPaymentDone) {
      console.log('Do not initialize paypal. The payment is already done.');
    } else if (this.props.isPaymentError) {
      console.log("Do not initialize paypal. The payment couldn't be created.");
    } else if (
      allValuesSet &&
      this.state.scriptLoaded &&
      !this.state.paypalInitialized
    ) {
      this.initializePayPal(approvalUrl, order);
    } else {
      console.log(
        `Do not initialize paypal. approvalUrl (equal, next, current): [${nextProps.approvalUrl ===
          this.props.approvalUrl},${nextProps.approvalUrl},${this.props
          .approvalUrl}], scriptLoaded: [${this.state
          .scriptLoaded}], paypalInitialized: [${this.state.paypalInitialized}]`
      );
    }
  }

  initializePayPal(approvalUrl, order) {
    console.log('initialize paypal with ' + approvalUrl);
    if (!window.PAYPAL) {
      console.error('ppplus has not been loaded');
      return;
    }

    window.PAYPAL.apps.PPP({
      approvalUrl: approvalUrl,
      placeholder: 'ppplus',
      mode: env.PAYPAL_MODE, // sandbox or live
      country: order.billing.address.country, // The country code must be taken from the billing address a buyer entered during checkout.
      language: getTextBySelectedLang('de_DE', 'en_US'),
      // "buttonLocation" : "outside",
      preselection: 'paypal',
      //"onContinue" : function () { window.document.forms[0].submit(); },
      // "enableContinue" : "formsubmitbtn",
      // "disableContinue" : "formsubmitbtn",
      // useraction: 'commit',
      showLoadingIndicator: true,
      thirdPartyPaymentMethods: [
        {
          redirectUrl: `${env.CLIENT_HOST}/${selectedLang}/prepayment`,
          methodName: getTextBySelectedLang('Überweisung', 'Money transfer'),
          // imageUrl: 'https://paper-shape.com/Content/Images/Überweisung-Icon.png',
          description: getTextBySelectedLang(
            'Per Überweisung auf mein Konto.',
            'Money transfer on my bank account.'
          ),
        },
      ],
      onThirdPartyPaymentMethodSelected: function(data) {
        console.log('onThirdPartyPaymentMethodSelected', data);
        // $('#Zahlungsweise').val('Vorkasse');
      },
      onThirdPartyPaymentMethodDeselected: function(data) {
        console.log('onThirdPartyPaymentMethodDeselected', data);
        // $('#Zahlungsweise').val('PayPal');
      },
      styles: {
        psp: {
          'font-family': 'Arial',
          color: 'black',
          'font-size': '14px',
          'font-style': 'normal',
        },
      },
    });
    this.setState({ paypalInitialized: true });
  }

  UNSAFE_componentWillMount() {
    const script = document.createElement('script');
    script.src = 'https://www.paypalobjects.com/webstatic/ppplus/ppplus.min.js';
    script.async = true;
    script.onload = () => {
      this.setState({ scriptLoaded: true });
    };
    document.body.appendChild(script);
  }

  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang(
            'Wähle eine Zahlungsmethode',
            'Choose a payment method'
          )}
          noindex
        />

        {this.props.isPaymentError ? (
          <PaymentError />
        ) : this.props.isPaymentDone ? (
          <PaymentAlreadyDone />
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="page-header">
                  <Lang
                    de={`Bitte wähle eine Zahlungsmethode`}
                    en={`Please choose a payment method`}
                  />
                </h1>
              </div>
            </div>

            <div id="ppplus" />

            {!this.state.paypalInitialized && (
              <h2 className="text-center" style={{ margin: '100px' }}>
                <Lang de="Bitte warten..." en="Please wait..." />
              </h2>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    approvalUrl: getApprovalUrl(state),
    isPaymentDone: getIsPaymentDone(state),
    isPaymentError: getIsPaymentError(state),
    order: getOrder(state),
  };
};

export default connect(mapStateToProps)(PaymentStart);
