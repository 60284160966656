export default {
  id: '2-13-sfk',
  category: ['animal_sculpture'],
  name: {
    de: 'Flamingo',
    en: 'Flamingo',
  },
  subtitle: {
    de: 'B19 x H45 x T25 cm',
    en: 'W19 x H45 x D25 cm',
  },
  meta_title: {
    de: 'Flamingo',
    en: 'Flamingo',
  },
  meta_description_google: {
    de: 'Der Flamingo bringt jede Kinderherzen zum Schlagen und verbreitet einfach gute Laune. Der Flamingo lässt sich einfach falten und kleben. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'The flamingo enchants each room with lots of space for creativity. This panda is very easy to assemble thanks to the new no-glue technique. 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: '3D Flamingo aus Papier zum Falten. DIY Papierskulptur als coole Wanddeko #papershape',
    en: '3d flamingo from paper, diy paper sculpture as cool wall decor #papershape',
  },
  description: {
    de: `  Was ist drin im DIY Kit?    
    - 9 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität (Schnabel ist schwarz und Gesicht weiß)
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -9 printed DINA4 cardboard sheets for the perfect stability (the beak is black and the face white)
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },

  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 9 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 9 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m², max. 230g/m²)
- Drucker 
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 9 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 9 DIN A4 or US letter colored paper (min. 160g/m², max. 230g/m²)
- printer
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkflamingo/flamingo-weiss.jpg',
        en: 'products/sfkflamingo/flamingo-weiss.jpg',
      },
      alt_text: {
        de: 'DIY 3d Flamingo aus Papier als Wanddeko #papercraft #3dpapermodel #origami',
        en: 'Papercraft flamingo for wall decor #diy #papercraft #origami #3dpapermodel #origami',
      },
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },
  colorinspirations: [
    {
      caption: {
        de: 'Rosa und pinke Flamingos',
        en: 'Rose and pink flamingos',
      },
      image: {
        filename: {
          de: 'products/sfkflamingo/flamingo-papercraft-papershape.jpg',
          en: 'products/sfkflamingo/flamingo-papercraft-papershape.jpg',
        },
        alt_text: {
          de: '3D Flamingo aus Papier zum Falten. DIY Papierskulptur als coole Wanddeko #papershape',
          en: '3d flamingo from paper, diy paper sculpture as cool wall decor #papershape',
        },
      },
    },
    {
      caption: {
        de: 'Rückansicht - Wandaufhängung',
        en: 'rose',
      },
      image: {
        filename: {
          de: 'products/sfkflamingo/flamingo-back.jpg',
          en: 'products/sfkflamingo/flamingo-back.jpg',
        },
        alt_text: {
          de: '3D Flamingo aus Papier zum Falten. DIY Papierskulptur als coole Wanddeko #papershape',
          en: '3d flamingo from paper, diy paper sculpture as cool wall decor #papershape',
        },
      },
    },
    {
      caption: {
        de: 'Unterschiedliche Ansichten und Größe',
        en: 'pink',
      },
      image: {
        filename: {
          de: 'products/sfkflamingo/flamingo-size.jpg',
          en: 'products/sfkflamingo/flamingo-size.jpg',
        },
        alt_text: {
          de: '3D Flamingo aus Papier zum Falten. DIY Papierskulptur als coole Wanddeko #papershape',
          en: '3d flamingo from paper, diy paper sculpture as cool wall decor #papershape',
        },
      },
    },
  ],
  choose: {
    image: {
      filename: {
        de: 'products/sfkflamingo/flamingo-weiss.jpg',
        en: 'products/sfkflamingo/flamingo-weiss.jpg',
      },
      alt_text: {
        de: '',
        en: '',
      },
    },
    colors: {
      color1: {
        de: 'Körper',
        en: 'Body',
      },
    },
    disableColors: {
      color2: ['schwarz'],
      color1: ['schwarz'],
    },
    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
